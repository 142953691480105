import React from 'react'
import { FullChecklist, QualityCheck } from '../../api/interfaces'
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
} from '@chakra-ui/react'
import { QualityTree } from './QualityTree'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  buttonClose: {
    id: 'UI.button_close',
    defaultMessage: 'Close'
  },

})

type QualityTreeModalProps = {
  isOpen: boolean
  onClose: () => void
  checklist: FullChecklist
  qualityCheck: QualityCheck
}

export const QualityTreeModal: React.FC<QualityTreeModalProps> = (props) => {
  const { qualityCheck, checklist, isOpen, onClose } = props

  const intl = useIntl()
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={'xl'}>{qualityCheck.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {qualityCheck.equation !== null ? (
            <QualityTree checklist={checklist} equation={qualityCheck.equation} />
          ) : (
            'no-equation'
          )}
        </ModalBody>
        <ModalFooter borderTopWidth={1} >
          <Button colorScheme='brand' onClick={onClose}>
            {intl.formatMessage(messages.buttonClose)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
