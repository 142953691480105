import { BoxProps, Box } from "@chakra-ui/react";
import React from "react";
import { ActiveVisitHistory, FullHistory } from "../../api/interfaces";
import { PastVisits, SurveysSection } from "../History";
import {
  ManualProblems,
  ManualMedications,
  Allergies,
  Diet,
  Development,
  Social,
  Family,
} from "../History/HistorySections";

type PatientHistoryProps = BoxProps & {
  patientId: string
  visitId: string
  history: FullHistory
  currentHistory: ActiveVisitHistory
}

export const PatientHistory: React.FC<PatientHistoryProps> = props => {
  const { history, currentHistory, visitId, patientId, ...boxProps } = props

  const sectionProps = {
    defaultIsOpen: true,
    mb: 6,
    p: 4,
    borderWidth: 1,
    borderRadius: 'lg'
  }

  return <Box {...boxProps}>
    <PastVisits
      items={history.pastVisits ?? []}
      {...sectionProps}
    />
    <ManualProblems
      patientId={patientId}
      visitId={visitId}
      items={currentHistory.manualProblems ?? []}
      readOnlyItems={history.manualProblems ?? []}
      {...sectionProps}
    />
    <SurveysSection
      patientId={patientId}
      visitId={visitId}
      items={currentHistory.surveys ?? []}
      readOnlyItems={history.surveys ?? []}
      {...sectionProps}
    />
    <ManualMedications
      patientId={patientId}
      visitId={visitId}
      items={currentHistory.manualMedications ?? []}
      readOnlyItems={history.manualMedications ?? []}
      {...sectionProps}
    />
    <Allergies
      patientId={patientId}
      visitId={visitId}
      items={currentHistory.allergies ?? []}
      readOnlyItems={history.allergies ?? []}
      {...sectionProps}
    />
    <Diet
      patientId={patientId}
      visitId={visitId}
      items={currentHistory.diet ?? []}
      readOnlyItems={history.dietHistory ?? []}
      {...sectionProps}
    />
    <Development
      patientId={patientId}
      visitId={visitId}
      items={currentHistory.development ?? []}
      readOnlyItems={history.developmentHistory ?? []}
      {...sectionProps}
    />
    <Social
      patientId={patientId}
      visitId={visitId}
      items={currentHistory.social ?? []}
      readOnlyItems={history.socialHistory ?? []}
      {...sectionProps}
    />
    <Family
      patientId={patientId}
      visitId={visitId}
      items={currentHistory.family ?? []}
      readOnlyItems={history.familyHistory ?? []}
      {...sectionProps}
    />
  </Box>
}

