import { API, BlockTool, BlockToolData } from '@editorjs/editorjs'
import { InputIcon } from './icons'

enum InputType {
  DATE = 'date',
  NUMBER = 'number',
  TEXT = 'text',
}
interface Config {
  isFillable: boolean
  onInputChange: (blockId: string, value: string) => void
}

const isValidType = (type: unknown): boolean => {
  if (typeof type !== 'string') {
    return false
  }

  return (Object.values(InputType) as Array<string>).includes(type)
}
interface Data {
  value: string
  type: InputType
  label: string
}

interface Settings {
  type: InputType
  name: string
}

const DEFAULT_VALUE: Data = {
  value: '',
  type: InputType.TEXT,
  label: 'Label',
}

class Input implements BlockTool {
  private data: Data
  private api: API
  private config: Config
  private settings: Array<Settings>
  private input: HTMLInputElement
  private uniqueId: string

  constructor(params) {
    this.api = params.api
    this.config = params.config
    this.data = {
      label: params?.data?.label ?? DEFAULT_VALUE.label,
      type: params?.data?.type ?? DEFAULT_VALUE.type,
      value: params?.data?.value ?? DEFAULT_VALUE.value,
    }

    this.uniqueId = params.block.id
    this.input = document.createElement('input')

    /**
     * This work outside the editor js api
     * because the read only flag does not
     * allow us to save data.
     */
    if (this.config.isFillable) {
      this.input.addEventListener('change', (event: Event) => {
        const value = (<HTMLInputElement>event.target).value

        this.config.onInputChange(this.uniqueId, value)
      })
    }

    this.settings = [
      {
        type: InputType.DATE,
        name: 'Date',
      },
      {
        type: InputType.NUMBER,
        name: 'Number',
      },
      {
        type: InputType.TEXT,
        name: 'Text',
      },
    ]
  }

  render(): HTMLElement {
    const view = document.createElement('div')
    const label = document.createElement('div')

    label.contentEditable = this.api.readOnly.isEnabled ? 'false' : 'true'
    label.innerHTML = this.data.label

    this.input.type = this.data.type
    this.input.value = this.data.value

    this.input.style.background = 'inherit'
    this.input.style.border = '1px solid'
    this.input.style.width = '100%'
    this.input.style.borderRadius = '0.375rem'
    this.input.style.padding = '.5rem'
    this.input.style.fontSize = '1rem'
    this.input.style.height = '2.5rem'

    this.input.disabled = !this.config.isFillable

    view.style.marginBottom = '10px'
    view.style.marginTop = '10px'
    view.appendChild(label)
    view.appendChild(this.input)

    return view
  }

  validate?(blockData: BlockToolData): boolean {
    return blockData.label && isValidType(blockData.type)
  }

  save(blockContent: HTMLElement): BlockToolData {
    const label = blockContent.querySelector('[contenteditable]')

    return {
      value: this.input.value,
      type: this.data.type,
      label: label?.innerHTML,
    }
  }

  renderSettings() {
    const wrapper = document.createElement('div')

    this.settings.forEach((tune) => {
      const option = document.createElement('div')
      const optionInput = document.createElement('input')
      const label = document.createElement('label')

      option.classList.add(this.api.styles.settingsButton)
      option.style.display = 'flex'
      option.style.alignItems = 'center'
      option.style.justifyContent = 'flex-start'
      option.style.width = '100%'

      label.htmlFor = tune.type
      label.innerHTML = tune.name
      label.style.margin = '0 0 0 4px'
      label.style.width = '100%'
      label.style.textAlign = 'start'
      label.style.cursor = 'pointer'

      optionInput.type = 'radio'
      optionInput.name = 'type'
      optionInput.id = tune.type
      optionInput.checked = tune.type === this.data.type
      optionInput.style.marginLeft = '3px'

      optionInput.addEventListener('click', () => {
        this.updateInputType(tune.type)
      })

      option.appendChild(optionInput)
      option.appendChild(label)

      wrapper.appendChild(option)
    })

    return wrapper
  }

  updateInputType(type: InputType) {
    this.data.type = type
    this.input.type = type
  }

  /**
   * Displaying at the toolbox
   */
  static get toolbox() {
    return {
      title: 'Input',
      icon: InputIcon,
    }
  }

  /**
   * Notify core that the read-only mode is supported
   *
   * @returns {boolean}
   * @public
   */
  static get isReadOnlySupported() {
    return true
  }
}

export default Input
