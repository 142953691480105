import { API, BlockTool, BlockToolData } from '@editorjs/editorjs'
import { CheckboxIcon } from './icons'

interface Data {
  checked: boolean
  label: string
}

interface Config {
  isFillable: boolean
  onCheckboxChange: (blockId: string, checked: boolean) => void
}

const DEFAULT_VALUE: Data = {
  label: 'Double click to edit this label',
  checked: false,
}

class Checkbox implements BlockTool {
  private api: API
  private data: Data
  private config: Config
  private input: HTMLInputElement
  private uniqueId: string

  constructor(params) {
    this.api = params.api
    this.config = params.config as Config
    this.data = {
      label: params?.data?.label ?? DEFAULT_VALUE.label,
      checked: params?.data?.checked ?? DEFAULT_VALUE.checked,
    }

    this.uniqueId = params.block.id
    this.input = document.createElement('input')

    /**
     * This work outside the editor js api
     * because the read only flag does not
     * allow us to save data.
     */
    if (this.config.isFillable) {
      this.input.addEventListener('change', (event: Event) => {
        const isChecked = (<HTMLInputElement>event.target).checked

        this.config.onCheckboxChange(this.uniqueId, isChecked)
      })
    }
  }

  render(): HTMLElement {
    const view = document.createElement('div')
    const label = document.createElement('label')

    this.input.type = 'checkbox'
    this.input.id = this.uniqueId
    this.input.checked = this.data.checked
    this.input.disabled = !this.config.isFillable
    this.input.style.opacity = this.data.checked ? '1' : '.3'

    label.contentEditable = this.api.readOnly.isEnabled ? 'false' : 'true'
    label.innerHTML = this.data.label
    label.htmlFor = this.uniqueId
    label.style.marginBottom = '0'
    label.style.marginLeft = '.5rem'

    view.style.display = 'flex'
    view.style.alignItems = 'center'
    view.style.justifyContent = 'flex-start'
    view.style.marginBottom = '10px'
    view.style.marginTop = '10px'

    view.appendChild(this.input)
    view.appendChild(label)

    return view
  }

  validate?(blockData: BlockToolData): boolean {
    return blockData.label
  }

  save(blockContent: HTMLElement): BlockToolData {
    const label = blockContent.querySelector('[contenteditable]')

    return {
      label: label?.innerHTML,
      checked: this.input.checked,
    }
  }

  /**
   * Displaying at the toolbox
   */
  static get toolbox() {
    return {
      title: 'Checkbox',
      icon: CheckboxIcon,
    }
  }

  /**
   * Notify core that the read-only mode is supported
   *
   * @returns {boolean}
   * @public
   */
  static get isReadOnlySupported() {
    return true
  }
}

export default Checkbox
