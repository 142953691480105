import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import ReactMarkdown from 'react-markdown'
import { Box, BoxProps, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'

const messages = defineMessages({
  authors: {
    id: 'Checklist.label_authors',
    defaultMessage: 'Authors'
  },
  closeButton: {
    id: 'UI.button_close',
    defaultMessage: 'Close'
  }
})

type Props = BoxProps & {
  authors: string
}

export const Authors: React.FC<Props> = (props) => {
  const { authors, ...boxProps } = props

  const { isOpen, onOpen, onClose } = useDisclosure()
  const intl = useIntl()

  return <Box {...boxProps}>
    <Button onClick={onOpen} size={'sm'} variant={'ghost'}>
      {intl.formatMessage(messages.authors)}
    </Button>

    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {intl.formatMessage(messages.authors)}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ReactMarkdown >
            {authors}
          </ReactMarkdown>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='brand' mr={3} onClick={onClose}>
            {intl.formatMessage(messages.closeButton)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </Box>
}

