import React, { useState } from 'react'
import { useSession } from '../Auth'
import { Box, Flex, HStack, Image, Text, useRadio, useRadioGroup, UseRadioProps, VStack } from '@chakra-ui/react'
import { Locales, SUPPORTED_LOCALES, TranslationStats, useTranslationsStats } from '.'
import { Nullable } from '../../utils'
import { Loading } from '..'
import { LocaleSelector } from '../Selector'


interface Props {
  isDisabled?: boolean
}

export const SimpleLocaleSwitcher: React.FC<Props> = (props) => {
  const { isDisabled } = props

  const { getUserLocale, updateUserLocale } = useSession()
  const userLocale = getUserLocale()


  const [localeUpdating, setLocaleUpdating] = useState<boolean>(false)

  const onChangeLocale = async (locale: Locales) => {
    setLocaleUpdating(true)
    await updateUserLocale({ locale })
    setLocaleUpdating(false)
  }

  return <LocaleSelector
    value={userLocale}
    onChange={onChangeLocale}
    isLoading={localeUpdating}
    isDisabled={isDisabled}
  />
}


export const LocaleSwitcher: React.FC<Props> = (props) => {
  const { isDisabled } = props

  const { getUserLocale, updateUserLocale } = useSession()
  const userLocale = getUserLocale()


  // this will fill the cache to be used by each button
  const { isLoading } = useTranslationsStats()
  const [localeUpdating, setLocaleUpdating] = useState<Nullable<Locales>>(null)

  const onChangeLocale = async (locale: Locales) => {
    setLocaleUpdating(locale)
    await updateUserLocale({ locale })
    setLocaleUpdating(null)
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'locales',
    value: userLocale,
    onChange: onChangeLocale,
  })

  const group = getRootProps()

  return <VStack {...group} alignItems={'flex-start'}>
    {
      Object.keys(SUPPORTED_LOCALES).map(locale => {
        const radio = getRadioProps({ value: locale })

        return <RadioCard key={locale} {...radio} isDisabled={isDisabled} >
          <LocaleButton
            locale={locale as Locales}
            isLoadingStats={isLoading}
            isUpdating={locale === localeUpdating}
          />
        </RadioCard>
      })
    }
  </VStack >
}


interface LocaleButtonProps {
  locale: Locales
  isLoadingStats?: boolean
  isUpdating?: boolean
}

const LocaleButton: React.FC<LocaleButtonProps> = props => {
  const { locale, isLoadingStats, isUpdating } = props


  const { language, country, flag, lang } = SUPPORTED_LOCALES[locale]

  const { getStatsByLocale, getTotal } = useTranslationsStats()
  const stats = getStatsByLocale(locale)

  return <Flex w='100%'>
    <Image src={flag} alt={lang} objectFit={'cover'} />
    <HStack ml='2' my={2} justifyContent={'space-between'} alignItems={'center'} w='100%'>
      <VStack alignItems={'start'}>
        <HStack>
          <Text fontWeight='bold' display={'flex'}>
            {language}
          </Text>
          {
            isUpdating &&
            <Loading size='sm' />
          }
        </HStack>
        <Text size='sm'>
          {country}
        </Text>
      </VStack>
      {
        stats !== null &&
        <TranslationStats
          isLoading={isLoadingStats}
          total={getTotal() ?? 0}
          translated={stats.count} />
      }
    </HStack>
  </Flex>


}

const RadioCard: React.FC<UseRadioProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box
      w='100%'
      as='label'
    >
      <input {...input} />
      <Box
        {...checkbox}
        cursor={props.isDisabled ? 'not-allowed' : 'pointer'}
        borderWidth='1px'
        borderRadius='md'
        _checked={{
          bg: 'brand.600',
          color: 'white',
        }}
        px={4}
      >
        {props.children}
      </Box>
    </Box>
  )
}