import React from 'react'
import { Box, BoxProps, Center, Icon, Image, ImageProps } from '@chakra-ui/react'
import { MdBrokenImage as ErrorIcon } from 'react-icons/md'
import { Loading } from '.'
import { Nullable } from '../utils'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  notFound: {
    id: 'AsyncImage.not_found',
    defaultMessage: 'Image not found'
  },

})

// 30s of timeout
const TIMEOUT_MS = 30000

interface AsyncImageProps extends ImageProps {
  src: string
  alt: string
  boxProps?: BoxProps
}
export const AsyncImage: React.FC<AsyncImageProps> = (props) => {
  const { alt, src, boxProps, ...imageProps } = props

  const intl = useIntl()

  const ref = React.useRef<Nullable<NodeJS.Timeout>>(null)

  const [isLoading, setIsLoading] = React.useState(!!src)
  const [error, setError] = React.useState(!src)

  const onLoad = () => {
    setIsLoading(false)

    if (ref.current) {
      clearTimeout(ref.current)
    }
  }

  const onError = () => {
    setError(true)
    setIsLoading(false)

    if (ref.current) {
      clearTimeout(ref.current)
    }
  }

  React.useEffect(() => {
    ref.current = setTimeout(() => {
      onError()
    }, TIMEOUT_MS)

    return () => {
      if (ref.current) {
        clearTimeout(ref.current)
      }
    }
  }, [])

  return (
    <Box {...boxProps}>
      <Image
        src={src}
        alt={alt}
        onLoad={onLoad}
        onError={onError}
        display={isLoading || error ? 'none' : 'block'}
        {...imageProps}
      />
      {
        isLoading &&
        <Loading my={6} />
      }
      {
        error &&
        <Center>
          <Icon as={ErrorIcon} fontSize='xl' mx={2} />
          {intl.formatMessage(messages.notFound)}
        </Center>
      }
    </Box>
  )
}

