import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  getNormalVitalsByAge,
  isInRange,
  calculatePercentileAndZScore,
  getBMIStandards,
} from 'wd-common/src/logic/triage/utils'
import { isValidSex } from '../../utils'
import { Weight as WeightInterface, Height as HeightInterface } from '../../api/interfaces'
import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagProps,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { PatientAgeCategory } from '../Patients'
import { getAge } from '../DateAndTime'
import { PdfPercentileWarning, PercentileWarning } from './PercentileWarning'
import { RiAlertLine as WarningIcon } from 'react-icons/ri'

const messages = defineMessages({
  title: {
    id: 'Vitals.bmi_title',
    defaultMessage: 'Body Mass Index',
  },
  shortTitle: {
    id: 'Vitals.bmi_abbreviation',
    defaultMessage: 'BMI',
  },
  reference: {
    id: 'Vitals.reference_title',
    defaultMessage: 'Reference',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
})

type BMIProps = Omit<ButtonGroupProps, 'height'> & {
  birthdate: string
  sex: string
  height: HeightInterface
  weight: WeightInterface
  isDisabled?: boolean
}

export const BMI: React.FC<BMIProps> = (props) => {
  const { height, weight, birthdate, sex, isDisabled, ...buttonGroupProps } = props

  const { onToggle, isOpen } = useDisclosure()

  const ageInYears = getAge(new Date(birthdate))
  if (ageInYears === null || ageInYears < 2) {
    return null
  }

  const bmi = parseFloat((((weight || {}).kg / height?.cm / height?.cm) * 10000).toFixed(2))
  if (isNaN(bmi) || bmi <= 0) {
    return null
  }

  return (
    <>
      <ButtonGroup size="sm" isAttached isDisabled={isDisabled} {...buttonGroupProps}>
        <Button mr="-px" onClick={onToggle}>
          <ValueWithWarning bmi={bmi} birthdate={birthdate} sex={sex} />
        </Button>
      </ButtonGroup>

      <BMIModal bmi={bmi} isOpen={isOpen} onToggle={onToggle} birthdate={birthdate} sex={sex} />
    </>
  )
}

type BMIModalProps = {
  birthdate: string
  sex: string
  bmi: number
  isOpen: boolean
  onToggle: () => void
  isDisabled?: boolean
}

const BMIModal: React.FC<BMIModalProps> = (props) => {
  const { bmi, birthdate, sex, isOpen, onToggle } = props

  const intl = useIntl()

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  const { percentile } = checkWarnings({ birthdate, sex, bmi })
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize={'lg'} mb={3}>
            {`${intl.formatMessage(messages.shortTitle)}: ${bmi}`}
            <PercentileWarning percentile={percentile} as="span" ml={1} />
          </Text>
          <VitalReference birthdate={birthdate} bmi={bmi} sex={sex} />
        </ModalBody>

        <ModalFooter>
          <Button isFullWidth={isFullWidth} onClick={onToggle}>
            {intl.formatMessage(messages.cancelButton)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface CheckWarningsParams {
  birthdate: string
  sex: string
  bmi?: number
}

const checkWarnings = (params: CheckWarningsParams) => {
  const { bmi, birthdate, sex } = params
  const calculation = calculatePercentileAndZScore(bmi, getBMIStandards({ sex, birthdate }))
  const percentile = calculation?.percentile

  const reference = getNormalVitalsByAge({ birthdate })

  const bmiReference = isValidSex(sex) && reference?.bmi ? reference.bmi[sex.toLowerCase()] : null

  /**
   * For patients < 19 years old we use Percentile
   * For patients >= 19 years old we use actual BMI value
   */
  const ageInYears = getAge(new Date(birthdate))
  const usePercentile = ageInYears !== null && ageInYears < 19

  const comparisonValue = usePercentile ? percentile : bmi

  const inRange =
    !bmiReference || isInRange((bmiReference || {}).min, (bmiReference || {}).max, comparisonValue)

  return {
    inRange,
    percentile,
    reference: bmiReference,
    usePercentile,
  }
}

type ValueWithWarningProps = {
  bmi: number
  birthdate: string
  sex: string
}

const ValueWithWarning: React.FC<ValueWithWarningProps> = (props) => {
  const { bmi, sex, birthdate } = props

  const intl = useIntl()

  const { usePercentile, inRange, percentile } = checkWarnings({ birthdate, sex, bmi })
  return (
    <HStack alignItems={'center'}>
      {!inRange && <Icon as={WarningIcon} color="red.400" />}
      <Text mx={1}>{intl.formatMessage(messages.shortTitle)}:</Text>
      <Text fontWeight={'bold'} color={!inRange ? 'red.400' : undefined}>
        {bmi}
      </Text>
      {usePercentile && <PercentileWarning percentile={percentile} />}
    </HStack>
  )
}

const PdfValueWithWarning: React.FC<ValueWithWarningProps> = (props) => {
  const { bmi, sex, birthdate } = props

  const intl = useIntl()

  const { usePercentile, inRange, percentile } = checkWarnings({ birthdate, sex, bmi })

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ margin: '0 4px' }}>{intl.formatMessage(messages.shortTitle)}:</span>
      <span style={{ fontWeight: 'bold', color: !inRange ? 'red.400' : undefined }}>{bmi}</span>
      {usePercentile && <PdfPercentileWarning percentile={percentile} />}
    </div>
  )
}

type VitalReferenceProps = {
  bmi: number
  birthdate: string
  sex: string
}

const VitalReference: React.FC<VitalReferenceProps> = (props) => {
  const { bmi, birthdate, sex } = props

  const intl = useIntl()

  const { inRange, reference, usePercentile } = checkWarnings({ birthdate, sex, bmi })

  if (!reference) {
    return null
  }

  return (
    <Box p={4} borderWidth={1} rounded={'lg'}>
      <PatientAgeCategory birthdate={birthdate} mb={1} fontWeight={'bold'} />

      <HStack>
        <Text>{intl.formatMessage(messages.reference)}</Text>
        <Text fontWeight={'bold'} ml={2}>
          {`${reference.min} - ${reference.max} ${usePercentile && '%'}`}
        </Text>
        {!inRange && <Icon as={WarningIcon} ml={2} color="red.400" />}
      </HStack>
    </Box>
  )
}

type PrintableBMIProps = TagProps & {
  height: HeightInterface
  weight: WeightInterface
  birthdate: string
  sex: string
}

export const PrintableBMI: React.FC<PrintableBMIProps> = (props) => {
  const { height, weight, birthdate, sex, ...tagProps } = props
  const ageInYears = getAge(new Date(birthdate))

  if (ageInYears === null || ageInYears < 2) {
    return null
  }

  const bmi = parseFloat((((weight || {}).kg / height?.cm / height?.cm) * 10000).toFixed(2))
  if (isNaN(bmi) || bmi <= 0) {
    return null
  }

  return (
    <Tag {...tagProps}>
      <ValueWithWarning bmi={bmi} birthdate={birthdate} sex={sex} />
    </Tag>
  )
}

type PdfBMIProps = {
  height: HeightInterface
  weight: WeightInterface
  birthdate: string
  sex: string
}

export const PdfBMI: React.FC<PdfBMIProps> = (props) => {
  const { height, weight, birthdate, sex } = props
  const ageInYears = getAge(new Date(birthdate))

  if (ageInYears === null || ageInYears < 2) {
    return null
  }

  const bmi = parseFloat((((weight || {}).kg / height?.cm / height?.cm) * 10000).toFixed(2))
  if (isNaN(bmi) || bmi <= 0) {
    return null
  }

  return <PdfValueWithWarning bmi={bmi} birthdate={birthdate} sex={sex} />
}
