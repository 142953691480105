import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { SELECT_GENERAL_REVIEW_OF_SYSTEMS_ACTIVE_VISIT } from '../../api/requests/activeVisit'

const messages = defineMessages({
  patientNotFound: {
    id: 'PhysicalExam.patient_not_found',
    defaultMessage: 'Patient not found',
  },
  errorGetGeneralReviewOfSystemListDefault: {
    id: 'GeneralReviewOfSystem.error_get_general_review_of_system_list_default',
    defaultMessage:
      'Something went wrong while trying to perform an action in general review of system, try again later.',
  },
})

interface UpdateGeneralReviewOfSystemVariables {
  patientId: string
  visitId: string
  sectionKey: number
  groupKey: number
  choiceKey: number
  selected: boolean
}

interface UpdateGeneralReviewOfSystemData {
  selectExamChoiceInActiveVisit: FullPatientWithActiveVisit
}

type UseSelectChoiceParams = MutationHookOptions<
  UpdateGeneralReviewOfSystemData,
  UpdateGeneralReviewOfSystemVariables
> & {
  patientId: string
  visitId: string
  sectionKey: number
  groupKey: number
}

export const useUpdateGeneralReviewOfSystem = (params: UseSelectChoiceParams) => {
  const { patientId, visitId, sectionKey, groupKey, ...rest } = params
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      default:
        return setError(intl.formatMessage(messages.errorGetGeneralReviewOfSystemListDefault))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [updateGeneralReviewOfSystem, { loading: isLoading }] = useMutation<
    UpdateGeneralReviewOfSystemData,
    UpdateGeneralReviewOfSystemVariables
  >(SELECT_GENERAL_REVIEW_OF_SYSTEMS_ACTIVE_VISIT, {
    ...rest,
    onError,
  })

  const onSubmit = (choiceKey: number, selected: boolean) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      sectionKey,
      groupKey,
      choiceKey,
      selected,
    }

    updateGeneralReviewOfSystem({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    onErrorClose,
  }
}
