import checkPhone from 'phone'
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common'

export const isSecure = async (value: string, userInputs?: Array<string>) => {
  const options = {
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      userInputs: userInputs ?? [],
    },
  }

  zxcvbnOptions.setOptions(options)

  const result = await zxcvbn(value)
  switch (result.score) {
    case 4:
    case 3:
      return true

    case 2:
    case 1:
    case 0:
    default:
      return false
  }
}

export const isValidEmail = (value: string) => {
  const EXP =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return EXP.test(value)
}

interface CheckPhoneOptions {
  country?: string
  validateMobilePrefix?: boolean
  strictDetection?: boolean
}

export const sanitizePhone = (value: string, options?: CheckPhoneOptions) => {
  const hasPlus = value.indexOf('+') > -1
  const shouldAddPlus = !options?.country && !hasPlus

  return shouldAddPlus ? `+${value}` : value
}

export const isValidPhoneNumber = (value: string, options?: CheckPhoneOptions) => {
  const result = checkPhone(sanitizePhone(value), options)

  return result.isValid
}

export const isNonEmptyArray = (value: unknown): boolean => {
  if (!Array.isArray(value)) {
    return false
  }

  return value.length > 0
}
