import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_SYSTEMS } from '../../api/requests/systems'
import { HealthSystem, PaginatedResult, Location, Room } from '../../api/interfaces'
import { Nullable, validateNotNil } from '../../utils'
import { Loading } from '..'
import { Button, Center, Icon, Text, VStack } from '@chakra-ui/react'
import { useSession } from '../Auth'
import { RiEmotionUnhappyLine as SadIcon } from 'react-icons/ri'

interface SystemsQueryData {
  systems: PaginatedResult<HealthSystem>
}

export interface SystemContextData {
  isLoading: boolean
  systems: Array<HealthSystem>
  getSystem: (systemId: string) => Nullable<HealthSystem>
  getLocation: (systemId: string, locationId: string) => Nullable<Location>
  getRoom: (systemId: string, locationId: string, roomId: string) => Nullable<Room>
  getMySystem: () => HealthSystem
  getMyLocation: () => Location
}

export const SystemsContext = React.createContext<Nullable<SystemContextData>>(null)
export const SystemsProvider: React.FC = (props) => {
  const { getUserLocation, getUserSystem, logOut } = useSession()

  const [systems, setSystems] = React.useState<Array<HealthSystem>>([])
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (errorResponse) => {
    const { networkError } = errorResponse

    if (networkError !== null) {
      if ((networkError as any)?.statusCode === 401) {
        return
      }
    }

    setError('Something went wrong while trying to get settings from our servers. Please, reload')
  }

  const onCompleted = (data) => {
    if (Array.isArray(data?.systems?.docs)) {
      setSystems(data.systems.docs)
    }
  }

  const { loading } = useQuery<SystemsQueryData, void>(GET_SYSTEMS, {
    onCompleted,
    onError,
  })

  const getSystem = React.useCallback(
    (systemId: string): Nullable<HealthSystem> => {
      const found = systems.find((system) => system.id === systemId)

      return found ?? null
    },
    [systems]
  )

  const getLocation = React.useCallback(
    (systemId: string, locationId: string): Nullable<Location> => {
      const system = getSystem(systemId)
      if (system !== null && Array.isArray(system?.locations)) {
        const location = system.locations.find((location) => location.id === locationId)

        return location ?? null
      }

      return null
    },
    [getSystem]
  )

  const getRoom = React.useCallback(
    (systemId: string, locationId: string, roomId: string): Nullable<Room> => {
      const location = getLocation(systemId, locationId)

      if (location !== null && Array.isArray(location?.rooms)) {
        const room = location.rooms.find((room) => room.id === roomId)

        return room ?? null
      }

      return null
    },
    [getLocation]
  )

  const getMyLocation = React.useCallback((): Location => {
    const { id: systemId } = getUserSystem()
    const { id: locationId } = getUserLocation()

    const location = getLocation(systemId, locationId)

    if (location === null) {
      setError('Customer location not found')
    }

    // if error, all the children will unmounted
    return location ?? ({} as Location)
  }, [getLocation, getUserLocation, getUserSystem])

  const getMySystem = React.useCallback((): HealthSystem => {
    const { id: systemId } = getUserSystem()

    const system = getSystem(systemId)

    if (system === null) {
      setError('Customer system not found')
    }

    // if error, all the children will be unmounted
    return system ?? ({} as HealthSystem)
  }, [getSystem, getUserSystem])

  const value = {
    isLoading: loading,
    systems,
    getRoom,
    getLocation,
    getMyLocation,
    getSystem,
    getMySystem,
  }

  if (loading && systems.length === 0) {
    return <Loading my={6} />
  }

  return (
    <SystemsContext.Provider value={value}>
      {error ? (
        <Center my={10}>
          <VStack spacing={5}>
            <Icon as={SadIcon} fontSize="6xl" />
            <Text>{error}</Text>

            <Button onClick={() => logOut()}>Logout</Button>
          </VStack>
        </Center>
      ) : (
        props.children
      )}
    </SystemsContext.Provider>
  )
}

export const useSystems = (): SystemContextData => {
  const systems = React.useContext(SystemsContext)
  validateNotNil(systems)

  return systems
}
