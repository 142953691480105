import { FormLabel } from '@chakra-ui/react'
import React from 'react'
import { useField } from 'react-final-form'
import { EventRecurrenceSelector, EventRecurrenceSelectorProps } from '../../components/Scheduler'
import { Control, Error } from './utils'

type RecurrenceFieldProps = Omit<
  EventRecurrenceSelectorProps,
  'defaultValue' | 'value' | 'onChange' | 'isMulti'
> & {
  name: string
  label?: string
}

export const RecurrenceField: React.FC<RecurrenceFieldProps> = (props) => {
  const { name, label, ...selectorProps } = props
  const { input, meta } = useField(name)

  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <EventRecurrenceSelector
        isInvalid={meta.error && meta.touched}
        placeholder={label ?? undefined}
        {...input}
        {...selectorProps}
      />
      <Error name={name} />
    </Control>
  )
}
