import { useContext } from 'react'
import { Nullable } from '../../utils'
import { CalendarContext, CalendarContextValue } from './CalendarContext'

export const useCalendarContext = () => {
  const value = useContext(CalendarContext)
  validateContextValue(value)

  return value
}

export function validateContextValue(value: Nullable<CalendarContextValue>): asserts value is CalendarContextValue {
  if (value === null) {
    throw new TypeError('Trying to use calendar context out of the provider')
  }
}
