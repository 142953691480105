export const ITEM_TYPE = Object.freeze({
  CITATION: 'citation',
  DIVIDER: 'divider',
  DRUG: 'drug',
  PROCEDURE: 'procedure',
  STUDY: 'study',
  TEXT: 'text'
})

export const CHECKLIST_SECTION = Object.freeze({
  DIFFERENTIAL_DIAGNOSIS: 'differential_diagnosis',
  HISTORY: 'history',
  PHYSICAL_EXAM: 'physical_exam',
  STUDIES: 'studies',
  TREATMENT: 'treatment',
  EDUCATION: 'education'
})

/**
 * This is to unify the quality check actions.
 * The right value is just as it is in the database
 */
export const QUALITY_CHECK_ACTION_TYPE = Object.freeze({
  UPDATE_CLINICAL_STATUS: 'clinical_status'
})
