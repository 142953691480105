import { TranslatableText, Signature } from './common'

export enum BlockType {
  HEADER = 'header',
  PARAGRAPH = 'paragraph',
  INPUT = 'input',
  RANGE = 'range',
  CHECKBOX = 'checkbox',
  SELECTOR = 'selector',
}

/**
 * Data
 */
type HeaderBlockData = {
  text: TranslatableText
  level: number
}

type ParagraphBlockData = {
  text: TranslatableText
}

enum InputType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
}

type InputBlockData = {
  type: InputType
  value: string
  label: TranslatableText
}

type RangeBlockData = {
  value: string
  label: TranslatableText
  min: string
  max: string
  step: string
}

type CheckboxBlockData = {
  checked: boolean
  label: TranslatableText
}

type SelectorBlockData = {
  value: string
  label: TranslatableText
  options: Array<TranslatableText>
}

/**
 * Blocks
 */
type HeaderBlock = {
  id?: string
  type: BlockType.HEADER
  data: HeaderBlockData
}

type ParagraphBlock = {
  id?: string
  type: BlockType.PARAGRAPH
  data: ParagraphBlockData
}

type InputBlock = {
  id?: string
  type: BlockType.INPUT
  data: InputBlockData
}

type RangeBlock = {
  id?: string
  type: BlockType.RANGE
  data: RangeBlockData
}

type CheckboxBlock = {
  id?: string
  type: BlockType.CHECKBOX
  data: CheckboxBlockData
}

export type SelectorBlock = {
  id?: string
  type: BlockType.SELECTOR
  data: SelectorBlockData
}

export type SurveyBlock = HeaderBlock | ParagraphBlock | InputBlock | RangeBlock | CheckboxBlock | SelectorBlock

export interface SurveyBody {
  version: string
  blocks: Array<SurveyBlock>
}

export interface Survey {
  id: string
  name: TranslatableText
  description?: TranslatableText
  body?: SurveyBody
  updated?: Signature
  created: Signature
}
