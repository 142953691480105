import React from 'react'
import { SessionProvider } from '../components/Auth'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { CustomApolloProvider } from '../api/client'
import { TranslationProvider } from '../components/i18n/provider'
import { RouterContainer } from '../components/Routing/Router'
import { ThemeProvider } from '../components/Theme'

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <SessionProvider>
        <TranslationProvider>
          <ThemeProvider>
            <CustomApolloProvider>
              <RouterContainer />
            </CustomApolloProvider>
          </ThemeProvider>
        </TranslationProvider>
      </SessionProvider>
    </ErrorBoundary>
  )
}

export default App
