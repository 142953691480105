import { gql } from '@apollo/client'
import { UserSignature } from './common'

export const FullDrugGuideline = gql`
  fragment FullDrugGuideline on SystemDrugGuideline {
    type
    pre_text {
      type
      text
    }
    name {
      type
      text
    }
    dose_units
    dose_range_min
    dose_range_max
    doses_per_day
    max_dose
    max_doses
    max_dose_units
    route {
      text
    }
    post_text {
      text
    }
    frequency_show
    as_needed
    history_codes
    physical_exam_codes
    key
    checked
    days
    billing_tags
    quality_codes
  }
`

export const BasicDrug = gql`
  fragment BasicDrug on Drug {
    id
    name
    unit
    class
    category
    stockInfo {
      stock
    }
  }
`

export const FullDrug = gql`
  fragment FullDrug on Drug {
    ...BasicDrug
    retail
    dosing
    interactions
    comments
    recommended_dosing
    wholesale
    stock_unit
    minimum_units_to_dispense
  }
  ${BasicDrug}
`

export const FullRXDetails = gql`
  fragment FullRXDetails on RXDetails {
    dose_number
    dose_units
    drug_dose_number
    drug_dose_units
    rounded_dose
    rounded_dose_percent_difference
    units_per_dose
    units_per_dose_raw
    route
    frequency_show
    doses_per_day
    as_needed
    days
    dispense
    refills_allowed
    reviewed_medications
    reviewed_side_effects
    addressed_patient_questions
    notes
  }
`

export const FullRX = gql`
  fragment FullRX on RX {
    guideline {
      ...FullDrugGuideline
    }
    drug {
      ...FullDrug
    }
    details {
      ...FullRXDetails
    }
    created {
      ...UserSignature
    }
    mixId
    mixNotes
  }
  ${FullDrugGuideline}
  ${FullDrug}
  ${FullRXDetails}
  ${UserSignature}
`

// this one doesn't contain the patient
export const BasicStoredRx = gql`
  fragment BasicStoredRx on StoredRx {
    id
    visitId
    location
    origin
    canceled
    filled
    rx {
      ...FullRX
    }
  }
  ${FullRX}
`

export const FullStoredRx = gql`
  fragment FullStoredRx on StoredRx {
    ...BasicStoredRx
  }
  ${BasicStoredRx}
`
