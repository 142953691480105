import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { ADD_BLOOD_PRESSURE_MEASUREMENT, UPDATE_BLOOD_PRESSURE_MEASUREMENT } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ValidateFn, ValidationError } from '../Form'

const messages = defineMessages({
  patientNotFound: {
    id: 'RXActions.patient_not_found',
    defaultMessage: 'The patient was not found.',
  },
  visitNotFound: {
    id: 'Checklist.visit_not_found',
    defaultMessage: 'Could not find the current visit. Please, reload the page',
  },
  errorCreatingMeasurement: {
    id: 'Vitals.error_adding_measurement',
    defaultMessage: 'We could not add the measurement, try again later.',
  },
  invalidMeasurementId: {
    id: 'Vitals.invalid_measurement_id',
    defaultMessage: 'You must use a valid measurement id.',
  },
  errorRemovingMeasurement: {
    id: 'Vitals.error_removing_measurement',
    defaultMessage: 'We could not remove the measurement, try again later.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  minValueError: {
    id: 'form_validation.min_value',
    defaultMessage: 'This field must be greater than: {value}',
  },
  maxValueError: {
    id: 'form_validation.max_value',
    defaultMessage: 'This field must be lower than: {value}',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
})

export interface NewBloodPressure {
  systolic: number
  diastolic: number
}

interface CreateBloodPressureVariables {
  patientId: string
  visitId: string
  measurement: NewBloodPressure
}

interface CreateBloodPressureData {
  addBloodPressureMeasurement: FullPatientWithActiveVisit
}

type CreateBloodPressureParams = MutationHookOptions<CreateBloodPressureData, CreateBloodPressureVariables> & {
  patientId: string
  visitId: string
}

export const useCreateBloodPressure = (params: CreateBloodPressureParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'invalid_measurement_id':
        return setError(intl.formatMessage(messages.invalidMeasurementId))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFound))

      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_measurement':
        return setError(intl.formatMessage(messages.errorCreatingMeasurement))

      case 'error_removing_measurement':
        return setError(intl.formatMessage(messages.errorRemovingMeasurement))

      case 'invalid_signature':
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [addBloodPressureMeasurement, { loading: isLoading }] = useMutation(ADD_BLOOD_PRESSURE_MEASUREMENT, {
    onError,
    ...rest,
  })

  const validate: ValidateFn<NewBloodPressure> = async (values) => {
    const errors: ValidationError<NewBloodPressure> = {}

    if (!values.systolic) {
      errors.systolic = intl.formatMessage(messages.required)
    }

    if (!values.diastolic) {
      errors.diastolic = intl.formatMessage(messages.required)
    }

    if (values.diastolic <= 0) {
      errors.diastolic = intl.formatMessage(messages.minValueError, { value: 0 })
    }

    if (values.systolic <= values.diastolic) {
      errors.diastolic = intl.formatMessage(messages.maxValueError, { value: values.systolic })
    }

    return errors
  }

  const onCreate = (bloodPressure: NewBloodPressure) => {
    const variables = {
      patientId,
      visitId,
      measurement: bloodPressure,
    }

    addBloodPressureMeasurement({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onCreate,
    validate,
    isLoading,
    error,
    onErrorClose,
  }
}

interface UpdateBloodPressureVariables {
  patientId: string
  visitId: string
  measurementId: string
  measurement: NewBloodPressure
}
interface UpdateBloodPressureData {
  updateBloodPressureMeasurement: FullPatientWithActiveVisit
}

type UpdateBloodPressureParams = MutationHookOptions<UpdateBloodPressureData, UpdateBloodPressureVariables> & {
  patientId: string
  visitId: string
}

export const useUpdateBloodPressure = (params: UpdateBloodPressureParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'invalid_measurement_id':
        return setError(intl.formatMessage(messages.invalidMeasurementId))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFound))

      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_measurement':
        return setError(intl.formatMessage(messages.errorCreatingMeasurement))

      case 'error_removing_measurement':
        return setError(intl.formatMessage(messages.errorRemovingMeasurement))

      case 'invalid_signature':
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [updateBloodPressureMeasurement, { loading: isLoading }] = useMutation(UPDATE_BLOOD_PRESSURE_MEASUREMENT, {
    onError,
    ...rest,
  })

  const validate: ValidateFn<NewBloodPressure> = async (values) => {
    const errors: ValidationError<NewBloodPressure> = {}

    if (!values.systolic) {
      errors.systolic = intl.formatMessage(messages.required)
    }

    if (!values.diastolic) {
      errors.diastolic = intl.formatMessage(messages.required)
    }

    if (values.diastolic <= 0) {
      errors.diastolic = intl.formatMessage(messages.minValueError, { value: 0 })
    }

    if (values.systolic <= values.diastolic) {
      errors.diastolic = intl.formatMessage(messages.maxValueError, { value: values.systolic })
    }

    return errors
  }

  const onUpdate = (measurementId: string, bloodPressure: NewBloodPressure) => {
    const variables = {
      patientId,
      visitId,
      measurementId,
      measurement: bloodPressure,
    }

    updateBloodPressureMeasurement({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onUpdate,
    isLoading,
    validate,
    error,
    onErrorClose,
  }
}
