import { gql } from '@apollo/client'
import { FullUser, FullUserSubscription } from '../fragments/staff'

export const GET_STAFF = gql`
  query ($q: String, $limit: Int, $offset: Int, $system: [String], $location: [String]) {
    staff(q: $q, limit: $limit, offset: $offset, system: $system, location: $location) {
      docs {
        ...FullUser
      }
      count
    }
  }
  ${FullUser}
`

/**
 * own subscription
 */
export const GET_USER_SUBSCRIPTION = gql`
  query userSubscription {
    userSubscription {
      ...FullUserSubscription
    }
  }
  ${FullUserSubscription}
`

export const CANCEL_USER_SUBSCRIPTION = gql`
  mutation cancelSubscription {
    cancelSubscription {
      done
    }
  }
`

/**
 * for admin users
 */
export const GET_STAFF_SUBSCRIPTION = gql`
  query staffSubscription($customerId: String!) {
    staffSubscription(customerId: $customerId) {
      ...FullUserSubscription
    }
  }
  ${FullUserSubscription}
`

export const CANCEL_STAFF_SUBSCRIPTION = gql`
  mutation cancelStaffSubscription($customerId: String!) {
    cancelStaffSubscription(customerId: $customerId) {
      done
    }
  }
`

export const REFRESH_INDEX = gql`
  mutation refreshIndex {
    refreshIndex {
      done
    }
  }
`
/**
 * Account status
 */
export const ENABLE_USER_ACCOUNT = gql`
  mutation enableUserAccount($username: String!) {
    enableUserAccount(username: $username) {
      ...FullUser
    }
  }
  ${FullUser}
`

export const DISABLE_USER_ACCOUNT = gql`
  mutation disableUserAccount($username: String!) {
    disableUserAccount(username: $username) {
      ...FullUser
    }
  }
  ${FullUser}
`

/**
 * Admin permissions
 */
export const ADD_ADMIN_PERMISSIONS = gql`
  mutation addAdminPermissions($username: String!) {
    addAdminPermissions(username: $username) {
      ...FullUser
    }
  }
  ${FullUser}
`

export const REMOVE_ADMIN_PERMISSIONS = gql`
  mutation removeAdminPermissions($username: String!) {
    removeAdminPermissions(username: $username) {
      ...FullUser
    }
  }
  ${FullUser}
`

/**
 * User payment method checkout
 */
export const GET_UPDATE_PAYMENT_METHOD_CHECKOUT_URI = gql`
  query updatePaymentMethodCheckoutURI {
    updatePaymentMethodCheckoutURI
  }
`
