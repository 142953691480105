import React from 'react'
import { DateTime } from 'luxon'
import type { Nullable } from '../utils'
import { HStack, StackProps, Tag, TagLabel, TagProps, Text, TextProps } from '@chakra-ui/react'
import type { DateTimeFormatOptions } from 'luxon'
import { Signature } from '../api/interfaces'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  lastModified: {
    id: 'UI.last_modified_label',
    defaultMessage: 'Last Modified',
  },
})

/**
 * Signature typed as nullable because
 * the db completelly is unreliable
 */
type Props = TagProps & {
  signature: Nullable<Signature>
  dateFormat?: DateTimeFormatOptions
}

export const SignatureTag: React.FC<Props> = (props) => {
  const { signature, dateFormat = DateTime.DATE_SHORT, ...tagProps } = props

  if (signature === null || signature.timestamp === null) {
    return null
  }

  const date = new Date(signature.timestamp)
  const formattedDate = DateTime.fromJSDate(date).toLocaleString(dateFormat)

  return (
    <Tag borderRadius="full" {...tagProps}>
      <TagLabel
        sx={{
          '@media print': {
            color: 'black',
          },
        }}
      >
        {formattedDate}
      </TagLabel>
    </Tag>
  )
}

type PrintableSignatureTagProps = TextProps & {
  signature: Nullable<Signature>
  dateFormat?: DateTimeFormatOptions
}

export const PrintableSignatureTag: React.FC<PrintableSignatureTagProps> = (props) => {
  const { signature, dateFormat = DateTime.DATE_SHORT, ...textProps } = props

  if (signature === null || signature.timestamp === null) {
    return null
  }

  const date = new Date(signature.timestamp)
  const formattedDate = DateTime.fromJSDate(date).toLocaleString(dateFormat)

  return <Text {...textProps}>{formattedDate}</Text>
}

type LastModifiedProps = StackProps & {
  signature: Nullable<Signature>
  dateFormat?: DateTimeFormatOptions
}

export const LastModified: React.FC<LastModifiedProps> = (props) => {
  const { signature, dateFormat = DateTime.DATETIME_MED, ...stackProps } = props

  const intl = useIntl()

  if (signature === null || signature.timestamp === null) {
    return null
  }

  const date = new Date(signature.timestamp)
  const datetime = DateTime.fromJSDate(date)

  return (
    <HStack {...stackProps}>
      <Text>{intl.formatMessage(messages.lastModified)}:</Text>
      <Text>{datetime.isValid ? datetime.toLocaleString(dateFormat) : '-'}</Text>
    </HStack>
  )
}
