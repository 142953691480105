// Child growth standards
// Head Circumference reference values for GIRLS between 0 - 60 months
// Data taken from World Health Organization and then parsed to JSON
// Links:
// https://www.who.int/childgrowth/standards/second_set/tab_hcfa_girls_p_0_13.txt (0 - 13 weeks)
// https://www.who.int/childgrowth/standards/second_set/tab_hcfa_girls_p_0_5.txt  (0 - 60 months)

const hcValues = [
  {
    week: 0,
    l: 1,
    m: 33.8787,
    s: 0.03496,
    sd: 1.1844,
    p01: 30.2,
    p1: 31.1,
    p3: 31.7,
    p5: 31.9,
    p10: 32.4,
    p15: 32.7,
    p25: 33.1,
    p50: 33.9,
    p75: 34.7,
    p85: 35.1,
    p90: 35.4,
    p95: 35.8,
    p97: 36.1,
    p99: 36.6,
    p999: 37.5
  },
  {
    week: 1,
    l: 1,
    m: 34.5529,
    s: 0.03374,
    sd: 1.16581,
    p01: 31,
    p1: 31.8,
    p3: 32.4,
    p5: 32.6,
    p10: 33.1,
    p15: 33.3,
    p25: 33.8,
    p50: 34.6,
    p75: 35.3,
    p85: 35.8,
    p90: 36,
    p95: 36.5,
    p97: 36.7,
    p99: 37.3,
    p999: 38.2
  },
  {
    week: 2,
    l: 1,
    m: 35.2272,
    s: 0.03251,
    sd: 1.14524,
    p01: 31.7,
    p1: 32.6,
    p3: 33.1,
    p5: 33.3,
    p10: 33.8,
    p15: 34,
    p25: 34.5,
    p50: 35.2,
    p75: 36,
    p85: 36.4,
    p90: 36.7,
    p95: 37.1,
    p97: 37.4,
    p99: 37.9,
    p999: 38.8
  },
  {
    week: 3,
    l: 1,
    m: 35.843,
    s: 0.03231,
    sd: 1.15809,
    p01: 32.3,
    p1: 33.1,
    p3: 33.7,
    p5: 33.9,
    p10: 34.4,
    p15: 34.6,
    p25: 35.1,
    p50: 35.8,
    p75: 36.6,
    p85: 37,
    p90: 37.3,
    p95: 37.7,
    p97: 38,
    p99: 38.5,
    p999: 39.4
  },
  {
    week: 4,
    l: 1,
    m: 36.3761,
    s: 0.03215,
    sd: 1.16949,
    p01: 32.8,
    p1: 33.7,
    p3: 34.2,
    p5: 34.5,
    p10: 34.9,
    p15: 35.2,
    p25: 35.6,
    p50: 36.4,
    p75: 37.2,
    p85: 37.6,
    p90: 37.9,
    p95: 38.3,
    p97: 38.6,
    p99: 39.1,
    p999: 40
  },
  {
    week: 5,
    l: 1,
    m: 36.8472,
    s: 0.03202,
    sd: 1.17985,
    p01: 33.2,
    p1: 34.1,
    p3: 34.6,
    p5: 34.9,
    p10: 35.3,
    p15: 35.6,
    p25: 36.1,
    p50: 36.8,
    p75: 37.6,
    p85: 38.1,
    p90: 38.4,
    p95: 38.8,
    p97: 39.1,
    p99: 39.6,
    p999: 40.5
  },
  {
    week: 6,
    l: 1,
    m: 37.2711,
    s: 0.03191,
    sd: 1.18932,
    p01: 33.6,
    p1: 34.5,
    p3: 35,
    p5: 35.3,
    p10: 35.7,
    p15: 36,
    p25: 36.5,
    p50: 37.3,
    p75: 38.1,
    p85: 38.5,
    p90: 38.8,
    p95: 39.2,
    p97: 39.5,
    p99: 40,
    p999: 40.9
  },
  {
    week: 7,
    l: 1,
    m: 37.6584,
    s: 0.03182,
    sd: 1.19829,
    p01: 34,
    p1: 34.9,
    p3: 35.4,
    p5: 35.7,
    p10: 36.1,
    p15: 36.4,
    p25: 36.9,
    p50: 37.7,
    p75: 38.5,
    p85: 38.9,
    p90: 39.2,
    p95: 39.6,
    p97: 39.9,
    p99: 40.4,
    p999: 41.4
  },
  {
    week: 8,
    l: 1,
    m: 38.0167,
    s: 0.03173,
    sd: 1.20627,
    p01: 34.3,
    p1: 35.2,
    p3: 35.7,
    p5: 36,
    p10: 36.5,
    p15: 36.8,
    p25: 37.2,
    p50: 38,
    p75: 38.8,
    p85: 39.3,
    p90: 39.6,
    p95: 40,
    p97: 40.3,
    p99: 40.8,
    p999: 41.7
  },
  {
    week: 9,
    l: 1,
    m: 38.3516,
    s: 0.03166,
    sd: 1.21421,
    p01: 34.6,
    p1: 35.5,
    p3: 36.1,
    p5: 36.4,
    p10: 36.8,
    p15: 37.1,
    p25: 37.5,
    p50: 38.4,
    p75: 39.2,
    p85: 39.6,
    p90: 39.9,
    p95: 40.3,
    p97: 40.6,
    p99: 41.2,
    p999: 42.1
  },
  {
    week: 10,
    l: 1,
    m: 38.6673,
    s: 0.03158,
    sd: 1.22111,
    p01: 34.9,
    p1: 35.8,
    p3: 36.4,
    p5: 36.7,
    p10: 37.1,
    p15: 37.4,
    p25: 37.8,
    p50: 38.7,
    p75: 39.5,
    p85: 39.9,
    p90: 40.2,
    p95: 40.7,
    p97: 41,
    p99: 41.5,
    p999: 42.4
  },
  {
    week: 11,
    l: 1,
    m: 38.9661,
    s: 0.03152,
    sd: 1.22821,
    p01: 35.2,
    p1: 36.1,
    p3: 36.7,
    p5: 36.9,
    p10: 37.4,
    p15: 37.7,
    p25: 38.1,
    p50: 39,
    p75: 39.8,
    p85: 40.2,
    p90: 40.5,
    p95: 41,
    p97: 41.3,
    p99: 41.8,
    p999: 42.8
  },
  {
    week: 12,
    l: 1,
    m: 39.2501,
    s: 0.03146,
    sd: 1.23481,
    p01: 35.4,
    p1: 36.4,
    p3: 36.9,
    p5: 37.2,
    p10: 37.7,
    p15: 38,
    p25: 38.4,
    p50: 39.3,
    p75: 40.1,
    p85: 40.5,
    p90: 40.8,
    p95: 41.3,
    p97: 41.6,
    p99: 42.1,
    p999: 43.1
  },
  {
    week: 13,
    l: 1,
    m: 39.521,
    s: 0.0314,
    sd: 1.24096,
    p01: 35.7,
    p1: 36.6,
    p3: 37.2,
    p5: 37.5,
    p10: 37.9,
    p15: 38.2,
    p25: 38.7,
    p50: 39.5,
    p75: 40.4,
    p85: 40.8,
    p90: 41.1,
    p95: 41.6,
    p97: 41.9,
    p99: 42.4,
    p999: 43.4
  },
  {
    month: 0,
    l: 1,
    m: 33.8787,
    s: 0.03496,
    sd: 1.1844,
    p01: 30.2,
    p1: 31.1,
    p3: 31.7,
    p5: 31.9,
    p10: 32.4,
    p15: 32.7,
    p25: 33.1,
    p50: 33.9,
    p75: 34.7,
    p85: 35.1,
    p90: 35.4,
    p95: 35.8,
    p97: 36.1,
    p99: 36.6,
    p999: 37.5
  },
  {
    month: 1,
    l: 1,
    m: 36.5463,
    s: 0.0321,
    sd: 1.17314,
    p01: 32.9,
    p1: 33.8,
    p3: 34.3,
    p5: 34.6,
    p10: 35,
    p15: 35.3,
    p25: 35.8,
    p50: 36.5,
    p75: 37.3,
    p85: 37.8,
    p90: 38,
    p95: 38.5,
    p97: 38.8,
    p99: 39.3,
    p999: 40.2
  },
  {
    month: 2,
    l: 1,
    m: 38.2521,
    s: 0.03168,
    sd: 1.21183,
    p01: 34.5,
    p1: 35.4,
    p3: 36,
    p5: 36.3,
    p10: 36.7,
    p15: 37,
    p25: 37.4,
    p50: 38.3,
    p75: 39.1,
    p85: 39.5,
    p90: 39.8,
    p95: 40.2,
    p97: 40.5,
    p99: 41.1,
    p999: 42
  },
  {
    month: 3,
    l: 1,
    m: 39.5328,
    s: 0.0314,
    sd: 1.24133,
    p01: 35.7,
    p1: 36.6,
    p3: 37.2,
    p5: 37.5,
    p10: 37.9,
    p15: 38.2,
    p25: 38.7,
    p50: 39.5,
    p75: 40.4,
    p85: 40.8,
    p90: 41.1,
    p95: 41.6,
    p97: 41.9,
    p99: 42.4,
    p999: 43.4
  },
  {
    month: 4,
    l: 1,
    m: 40.5817,
    s: 0.03119,
    sd: 1.26574,
    p01: 36.7,
    p1: 37.6,
    p3: 38.2,
    p5: 38.5,
    p10: 39,
    p15: 39.3,
    p25: 39.7,
    p50: 40.6,
    p75: 41.4,
    p85: 41.9,
    p90: 42.2,
    p95: 42.7,
    p97: 43,
    p99: 43.5,
    p999: 44.5
  },
  {
    month: 5,
    l: 1,
    m: 41.459,
    s: 0.03102,
    sd: 1.28606,
    p01: 37.5,
    p1: 38.5,
    p3: 39,
    p5: 39.3,
    p10: 39.8,
    p15: 40.1,
    p25: 40.6,
    p50: 41.5,
    p75: 42.3,
    p85: 42.8,
    p90: 43.1,
    p95: 43.6,
    p97: 43.9,
    p99: 44.5,
    p999: 45.4
  },
  {
    month: 6,
    l: 1,
    m: 42.1995,
    s: 0.03087,
    sd: 1.3027,
    p01: 38.2,
    p1: 39.2,
    p3: 39.7,
    p5: 40.1,
    p10: 40.5,
    p15: 40.8,
    p25: 41.3,
    p50: 42.2,
    p75: 43.1,
    p85: 43.5,
    p90: 43.9,
    p95: 44.3,
    p97: 44.6,
    p99: 45.2,
    p999: 46.2
  },
  {
    month: 7,
    l: 1,
    m: 42.829,
    s: 0.03075,
    sd: 1.31699,
    p01: 38.8,
    p1: 39.8,
    p3: 40.4,
    p5: 40.7,
    p10: 41.1,
    p15: 41.5,
    p25: 41.9,
    p50: 42.8,
    p75: 43.7,
    p85: 44.2,
    p90: 44.5,
    p95: 45,
    p97: 45.3,
    p99: 45.9,
    p999: 46.9
  },
  {
    month: 8,
    l: 1,
    m: 43.3671,
    s: 0.03063,
    sd: 1.32833,
    p01: 39.3,
    p1: 40.3,
    p3: 40.9,
    p5: 41.2,
    p10: 41.7,
    p15: 42,
    p25: 42.5,
    p50: 43.4,
    p75: 44.3,
    p85: 44.7,
    p90: 45.1,
    p95: 45.6,
    p97: 45.9,
    p99: 46.5,
    p999: 47.5
  },
  {
    month: 9,
    l: 1,
    m: 43.83,
    s: 0.03053,
    sd: 1.33813,
    p01: 39.7,
    p1: 40.7,
    p3: 41.3,
    p5: 41.6,
    p10: 42.1,
    p15: 42.4,
    p25: 42.9,
    p50: 43.8,
    p75: 44.7,
    p85: 45.2,
    p90: 45.5,
    p95: 46,
    p97: 46.3,
    p99: 46.9,
    p999: 48
  },
  {
    month: 10,
    l: 1,
    m: 44.2319,
    s: 0.03044,
    sd: 1.34642,
    p01: 40.1,
    p1: 41.1,
    p3: 41.7,
    p5: 42,
    p10: 42.5,
    p15: 42.8,
    p25: 43.3,
    p50: 44.2,
    p75: 45.1,
    p85: 45.6,
    p90: 46,
    p95: 46.4,
    p97: 46.8,
    p99: 47.4,
    p999: 48.4
  },
  {
    month: 11,
    l: 1,
    m: 44.5844,
    s: 0.03035,
    sd: 1.35314,
    p01: 40.4,
    p1: 41.4,
    p3: 42,
    p5: 42.4,
    p10: 42.9,
    p15: 43.2,
    p25: 43.7,
    p50: 44.6,
    p75: 45.5,
    p85: 46,
    p90: 46.3,
    p95: 46.8,
    p97: 47.1,
    p99: 47.7,
    p999: 48.8
  },
  {
    month: 12,
    l: 1,
    m: 44.8965,
    s: 0.03027,
    sd: 1.35902,
    p01: 40.7,
    p1: 41.7,
    p3: 42.3,
    p5: 42.7,
    p10: 43.2,
    p15: 43.5,
    p25: 44,
    p50: 44.9,
    p75: 45.8,
    p85: 46.3,
    p90: 46.6,
    p95: 47.1,
    p97: 47.5,
    p99: 48.1,
    p999: 49.1
  },
  {
    month: 13,
    l: 1,
    m: 45.1752,
    s: 0.03019,
    sd: 1.36384,
    p01: 41,
    p1: 42,
    p3: 42.6,
    p5: 42.9,
    p10: 43.4,
    p15: 43.8,
    p25: 44.3,
    p50: 45.2,
    p75: 46.1,
    p85: 46.6,
    p90: 46.9,
    p95: 47.4,
    p97: 47.7,
    p99: 48.3,
    p999: 49.4
  },
  {
    month: 14,
    l: 1,
    m: 45.4265,
    s: 0.03012,
    sd: 1.36825,
    p01: 41.2,
    p1: 42.2,
    p3: 42.9,
    p5: 43.2,
    p10: 43.7,
    p15: 44,
    p25: 44.5,
    p50: 45.4,
    p75: 46.3,
    p85: 46.8,
    p90: 47.2,
    p95: 47.7,
    p97: 48,
    p99: 48.6,
    p999: 49.7
  },
  {
    month: 15,
    l: 1,
    m: 45.6551,
    s: 0.03006,
    sd: 1.37239,
    p01: 41.4,
    p1: 42.5,
    p3: 43.1,
    p5: 43.4,
    p10: 43.9,
    p15: 44.2,
    p25: 44.7,
    p50: 45.7,
    p75: 46.6,
    p85: 47.1,
    p90: 47.4,
    p95: 47.9,
    p97: 48.2,
    p99: 48.8,
    p999: 49.9
  },
  {
    month: 16,
    l: 1,
    m: 45.865,
    s: 0.02999,
    sd: 1.37549,
    p01: 41.6,
    p1: 42.7,
    p3: 43.3,
    p5: 43.6,
    p10: 44.1,
    p15: 44.4,
    p25: 44.9,
    p50: 45.9,
    p75: 46.8,
    p85: 47.3,
    p90: 47.6,
    p95: 48.1,
    p97: 48.5,
    p99: 49.1,
    p999: 50.1
  },
  {
    month: 17,
    l: 1,
    m: 46.0598,
    s: 0.02993,
    sd: 1.37857,
    p01: 41.8,
    p1: 42.9,
    p3: 43.5,
    p5: 43.8,
    p10: 44.3,
    p15: 44.6,
    p25: 45.1,
    p50: 46.1,
    p75: 47,
    p85: 47.5,
    p90: 47.8,
    p95: 48.3,
    p97: 48.7,
    p99: 49.3,
    p999: 50.3
  },
  {
    month: 18,
    l: 1,
    m: 46.2424,
    s: 0.02987,
    sd: 1.38126,
    p01: 42,
    p1: 43,
    p3: 43.6,
    p5: 44,
    p10: 44.5,
    p15: 44.8,
    p25: 45.3,
    p50: 46.2,
    p75: 47.2,
    p85: 47.7,
    p90: 48,
    p95: 48.5,
    p97: 48.8,
    p99: 49.5,
    p999: 50.5
  },
  {
    month: 19,
    l: 1,
    m: 46.4152,
    s: 0.02982,
    sd: 1.3841,
    p01: 42.1,
    p1: 43.2,
    p3: 43.8,
    p5: 44.1,
    p10: 44.6,
    p15: 45,
    p25: 45.5,
    p50: 46.4,
    p75: 47.3,
    p85: 47.8,
    p90: 48.2,
    p95: 48.7,
    p97: 49,
    p99: 49.6,
    p999: 50.7
  },
  {
    month: 20,
    l: 1,
    m: 46.5801,
    s: 0.02977,
    sd: 1.38669,
    p01: 42.3,
    p1: 43.4,
    p3: 44,
    p5: 44.3,
    p10: 44.8,
    p15: 45.1,
    p25: 45.6,
    p50: 46.6,
    p75: 47.5,
    p85: 48,
    p90: 48.4,
    p95: 48.9,
    p97: 49.2,
    p99: 49.8,
    p999: 50.9
  },
  {
    month: 21,
    l: 1,
    m: 46.7384,
    s: 0.02972,
    sd: 1.38907,
    p01: 42.4,
    p1: 43.5,
    p3: 44.1,
    p5: 44.5,
    p10: 45,
    p15: 45.3,
    p25: 45.8,
    p50: 46.7,
    p75: 47.7,
    p85: 48.2,
    p90: 48.5,
    p95: 49,
    p97: 49.4,
    p99: 50,
    p999: 51
  },
  {
    month: 22,
    l: 1,
    m: 46.8913,
    s: 0.02967,
    sd: 1.39126,
    p01: 42.6,
    p1: 43.7,
    p3: 44.3,
    p5: 44.6,
    p10: 45.1,
    p15: 45.4,
    p25: 46,
    p50: 46.9,
    p75: 47.8,
    p85: 48.3,
    p90: 48.7,
    p95: 49.2,
    p97: 49.5,
    p99: 50.1,
    p999: 51.2
  },
  {
    month: 23,
    l: 1,
    m: 47.0391,
    s: 0.02962,
    sd: 1.3933,
    p01: 42.7,
    p1: 43.8,
    p3: 44.4,
    p5: 44.7,
    p10: 45.3,
    p15: 45.6,
    p25: 46.1,
    p50: 47,
    p75: 48,
    p85: 48.5,
    p90: 48.8,
    p95: 49.3,
    p97: 49.7,
    p99: 50.3,
    p999: 51.3
  },
  {
    month: 24,
    l: 1,
    m: 47.1822,
    s: 0.02957,
    sd: 1.39518,
    p01: 42.9,
    p1: 43.9,
    p3: 44.6,
    p5: 44.9,
    p10: 45.4,
    p15: 45.7,
    p25: 46.2,
    p50: 47.2,
    p75: 48.1,
    p85: 48.6,
    p90: 49,
    p95: 49.5,
    p97: 49.8,
    p99: 50.4,
    p999: 51.5
  },
  {
    month: 25,
    l: 1,
    m: 47.3204,
    s: 0.02953,
    sd: 1.39737,
    p01: 43,
    p1: 44.1,
    p3: 44.7,
    p5: 45,
    p10: 45.5,
    p15: 45.9,
    p25: 46.4,
    p50: 47.3,
    p75: 48.3,
    p85: 48.8,
    p90: 49.1,
    p95: 49.6,
    p97: 49.9,
    p99: 50.6,
    p999: 51.6
  },
  {
    month: 26,
    l: 1,
    m: 47.4536,
    s: 0.02949,
    sd: 1.39941,
    p01: 43.1,
    p1: 44.2,
    p3: 44.8,
    p5: 45.2,
    p10: 45.7,
    p15: 46,
    p25: 46.5,
    p50: 47.5,
    p75: 48.4,
    p85: 48.9,
    p90: 49.2,
    p95: 49.8,
    p97: 50.1,
    p99: 50.7,
    p999: 51.8
  },
  {
    month: 27,
    l: 1,
    m: 47.5817,
    s: 0.02945,
    sd: 1.40128,
    p01: 43.3,
    p1: 44.3,
    p3: 44.9,
    p5: 45.3,
    p10: 45.8,
    p15: 46.1,
    p25: 46.6,
    p50: 47.6,
    p75: 48.5,
    p85: 49,
    p90: 49.4,
    p95: 49.9,
    p97: 50.2,
    p99: 50.8,
    p999: 51.9
  },
  {
    month: 28,
    l: 1,
    m: 47.7045,
    s: 0.02941,
    sd: 1.40299,
    p01: 43.4,
    p1: 44.4,
    p3: 45.1,
    p5: 45.4,
    p10: 45.9,
    p15: 46.3,
    p25: 46.8,
    p50: 47.7,
    p75: 48.7,
    p85: 49.2,
    p90: 49.5,
    p95: 50,
    p97: 50.3,
    p99: 51,
    p999: 52
  },
  {
    month: 29,
    l: 1,
    m: 47.8219,
    s: 0.02937,
    sd: 1.40453,
    p01: 43.5,
    p1: 44.6,
    p3: 45.2,
    p5: 45.5,
    p10: 46,
    p15: 46.4,
    p25: 46.9,
    p50: 47.8,
    p75: 48.8,
    p85: 49.3,
    p90: 49.6,
    p95: 50.1,
    p97: 50.5,
    p99: 51.1,
    p999: 52.2
  },
  {
    month: 30,
    l: 1,
    m: 47.934,
    s: 0.02933,
    sd: 1.4059,
    p01: 43.6,
    p1: 44.7,
    p3: 45.3,
    p5: 45.6,
    p10: 46.1,
    p15: 46.5,
    p25: 47,
    p50: 47.9,
    p75: 48.9,
    p85: 49.4,
    p90: 49.7,
    p95: 50.2,
    p97: 50.6,
    p99: 51.2,
    p999: 52.3
  },
  {
    month: 31,
    l: 1,
    m: 48.041,
    s: 0.02929,
    sd: 1.40712,
    p01: 43.7,
    p1: 44.8,
    p3: 45.4,
    p5: 45.7,
    p10: 46.2,
    p15: 46.6,
    p25: 47.1,
    p50: 48,
    p75: 49,
    p85: 49.5,
    p90: 49.8,
    p95: 50.4,
    p97: 50.7,
    p99: 51.3,
    p999: 52.4
  },
  {
    month: 32,
    l: 1,
    m: 48.1432,
    s: 0.02926,
    sd: 1.40867,
    p01: 43.8,
    p1: 44.9,
    p3: 45.5,
    p5: 45.8,
    p10: 46.3,
    p15: 46.7,
    p25: 47.2,
    p50: 48.1,
    p75: 49.1,
    p85: 49.6,
    p90: 49.9,
    p95: 50.5,
    p97: 50.8,
    p99: 51.4,
    p999: 52.5
  },
  {
    month: 33,
    l: 1,
    m: 48.2408,
    s: 0.02922,
    sd: 1.4096,
    p01: 43.9,
    p1: 45,
    p3: 45.6,
    p5: 45.9,
    p10: 46.4,
    p15: 46.8,
    p25: 47.3,
    p50: 48.2,
    p75: 49.2,
    p85: 49.7,
    p90: 50,
    p95: 50.6,
    p97: 50.9,
    p99: 51.5,
    p999: 52.6
  },
  {
    month: 34,
    l: 1,
    m: 48.3343,
    s: 0.02919,
    sd: 1.41088,
    p01: 44,
    p1: 45.1,
    p3: 45.7,
    p5: 46,
    p10: 46.5,
    p15: 46.9,
    p25: 47.4,
    p50: 48.3,
    p75: 49.3,
    p85: 49.8,
    p90: 50.1,
    p95: 50.7,
    p97: 51,
    p99: 51.6,
    p999: 52.7
  },
  {
    month: 35,
    l: 1,
    m: 48.4239,
    s: 0.02915,
    sd: 1.41156,
    p01: 44.1,
    p1: 45.1,
    p3: 45.8,
    p5: 46.1,
    p10: 46.6,
    p15: 47,
    p25: 47.5,
    p50: 48.4,
    p75: 49.4,
    p85: 49.9,
    p90: 50.2,
    p95: 50.7,
    p97: 51.1,
    p99: 51.7,
    p999: 52.8
  },
  {
    month: 36,
    l: 1,
    m: 48.5099,
    s: 0.02912,
    sd: 1.41261,
    p01: 44.1,
    p1: 45.2,
    p3: 45.9,
    p5: 46.2,
    p10: 46.7,
    p15: 47,
    p25: 47.6,
    p50: 48.5,
    p75: 49.5,
    p85: 50,
    p90: 50.3,
    p95: 50.8,
    p97: 51.2,
    p99: 51.8,
    p999: 52.9
  },
  {
    month: 37,
    l: 1,
    m: 48.5926,
    s: 0.02909,
    sd: 1.41356,
    p01: 44.2,
    p1: 45.3,
    p3: 45.9,
    p5: 46.3,
    p10: 46.8,
    p15: 47.1,
    p25: 47.6,
    p50: 48.6,
    p75: 49.5,
    p85: 50.1,
    p90: 50.4,
    p95: 50.9,
    p97: 51.3,
    p99: 51.9,
    p999: 53
  },
  {
    month: 38,
    l: 1,
    m: 48.6722,
    s: 0.02906,
    sd: 1.41441,
    p01: 44.3,
    p1: 45.4,
    p3: 46,
    p5: 46.3,
    p10: 46.9,
    p15: 47.2,
    p25: 47.7,
    p50: 48.7,
    p75: 49.6,
    p85: 50.1,
    p90: 50.5,
    p95: 51,
    p97: 51.3,
    p99: 52,
    p999: 53
  },
  {
    month: 39,
    l: 1,
    m: 48.7489,
    s: 0.02903,
    sd: 1.41518,
    p01: 44.4,
    p1: 45.5,
    p3: 46.1,
    p5: 46.4,
    p10: 46.9,
    p15: 47.3,
    p25: 47.8,
    p50: 48.7,
    p75: 49.7,
    p85: 50.2,
    p90: 50.6,
    p95: 51.1,
    p97: 51.4,
    p99: 52,
    p999: 53.1
  },
  {
    month: 40,
    l: 1,
    m: 48.8228,
    s: 0.029,
    sd: 1.41586,
    p01: 44.4,
    p1: 45.5,
    p3: 46.2,
    p5: 46.5,
    p10: 47,
    p15: 47.4,
    p25: 47.9,
    p50: 48.8,
    p75: 49.8,
    p85: 50.3,
    p90: 50.6,
    p95: 51.2,
    p97: 51.5,
    p99: 52.1,
    p999: 53.2
  },
  {
    month: 41,
    l: 1,
    m: 48.8941,
    s: 0.02897,
    sd: 1.41646,
    p01: 44.5,
    p1: 45.6,
    p3: 46.2,
    p5: 46.6,
    p10: 47.1,
    p15: 47.4,
    p25: 47.9,
    p50: 48.9,
    p75: 49.8,
    p85: 50.4,
    p90: 50.7,
    p95: 51.2,
    p97: 51.6,
    p99: 52.2,
    p999: 53.3
  },
  {
    month: 42,
    l: 1,
    m: 48.9629,
    s: 0.02894,
    sd: 1.41699,
    p01: 44.6,
    p1: 45.7,
    p3: 46.3,
    p5: 46.6,
    p10: 47.1,
    p15: 47.5,
    p25: 48,
    p50: 49,
    p75: 49.9,
    p85: 50.4,
    p90: 50.8,
    p95: 51.3,
    p97: 51.6,
    p99: 52.3,
    p999: 53.3
  },
  {
    month: 43,
    l: 1,
    m: 49.0294,
    s: 0.02891,
    sd: 1.41744,
    p01: 44.6,
    p1: 45.7,
    p3: 46.4,
    p5: 46.7,
    p10: 47.2,
    p15: 47.6,
    p25: 48.1,
    p50: 49,
    p75: 50,
    p85: 50.5,
    p90: 50.8,
    p95: 51.4,
    p97: 51.7,
    p99: 52.3,
    p999: 53.4
  },
  {
    month: 44,
    l: 1,
    m: 49.0937,
    s: 0.02888,
    sd: 1.41783,
    p01: 44.7,
    p1: 45.8,
    p3: 46.4,
    p5: 46.8,
    p10: 47.3,
    p15: 47.6,
    p25: 48.1,
    p50: 49.1,
    p75: 50.1,
    p85: 50.6,
    p90: 50.9,
    p95: 51.4,
    p97: 51.8,
    p99: 52.4,
    p999: 53.5
  },
  {
    month: 45,
    l: 1,
    m: 49.156,
    s: 0.02886,
    sd: 1.41864,
    p01: 44.8,
    p1: 45.9,
    p3: 46.5,
    p5: 46.8,
    p10: 47.3,
    p15: 47.7,
    p25: 48.2,
    p50: 49.2,
    p75: 50.1,
    p85: 50.6,
    p90: 51,
    p95: 51.5,
    p97: 51.8,
    p99: 52.5,
    p999: 53.5
  },
  {
    month: 46,
    l: 1,
    m: 49.2164,
    s: 0.02883,
    sd: 1.41891,
    p01: 44.8,
    p1: 45.9,
    p3: 46.5,
    p5: 46.9,
    p10: 47.4,
    p15: 47.7,
    p25: 48.3,
    p50: 49.2,
    p75: 50.2,
    p85: 50.7,
    p90: 51,
    p95: 51.6,
    p97: 51.9,
    p99: 52.5,
    p999: 53.6
  },
  {
    month: 47,
    l: 1,
    m: 49.2751,
    s: 0.0288,
    sd: 1.41912,
    p01: 44.9,
    p1: 46,
    p3: 46.6,
    p5: 46.9,
    p10: 47.5,
    p15: 47.8,
    p25: 48.3,
    p50: 49.3,
    p75: 50.2,
    p85: 50.7,
    p90: 51.1,
    p95: 51.6,
    p97: 51.9,
    p99: 52.6,
    p999: 53.7
  },
  {
    month: 48,
    l: 1,
    m: 49.3321,
    s: 0.02878,
    sd: 1.41978,
    p01: 44.9,
    p1: 46,
    p3: 46.7,
    p5: 47,
    p10: 47.5,
    p15: 47.9,
    p25: 48.4,
    p50: 49.3,
    p75: 50.3,
    p85: 50.8,
    p90: 51.2,
    p95: 51.7,
    p97: 52,
    p99: 52.6,
    p999: 53.7
  },
  {
    month: 49,
    l: 1,
    m: 49.3877,
    s: 0.02875,
    sd: 1.4199,
    p01: 45,
    p1: 46.1,
    p3: 46.7,
    p5: 47.1,
    p10: 47.6,
    p15: 47.9,
    p25: 48.4,
    p50: 49.4,
    p75: 50.3,
    p85: 50.9,
    p90: 51.2,
    p95: 51.7,
    p97: 52.1,
    p99: 52.7,
    p999: 53.8
  },
  {
    month: 50,
    l: 1,
    m: 49.4419,
    s: 0.02873,
    sd: 1.42047,
    p01: 45.1,
    p1: 46.1,
    p3: 46.8,
    p5: 47.1,
    p10: 47.6,
    p15: 48,
    p25: 48.5,
    p50: 49.4,
    p75: 50.4,
    p85: 50.9,
    p90: 51.3,
    p95: 51.8,
    p97: 52.1,
    p99: 52.7,
    p999: 53.8
  },
  {
    month: 51,
    l: 1,
    m: 49.4947,
    s: 0.0287,
    sd: 1.4205,
    p01: 45.1,
    p1: 46.2,
    p3: 46.8,
    p5: 47.2,
    p10: 47.7,
    p15: 48,
    p25: 48.5,
    p50: 49.5,
    p75: 50.5,
    p85: 51,
    p90: 51.3,
    p95: 51.8,
    p97: 52.2,
    p99: 52.8,
    p999: 53.9
  },
  {
    month: 52,
    l: 1,
    m: 49.5464,
    s: 0.02868,
    sd: 1.42099,
    p01: 45.2,
    p1: 46.2,
    p3: 46.9,
    p5: 47.2,
    p10: 47.7,
    p15: 48.1,
    p25: 48.6,
    p50: 49.5,
    p75: 50.5,
    p85: 51,
    p90: 51.4,
    p95: 51.9,
    p97: 52.2,
    p99: 52.9,
    p999: 53.9
  },
  {
    month: 53,
    l: 1,
    m: 49.5969,
    s: 0.02865,
    sd: 1.42095,
    p01: 45.2,
    p1: 46.3,
    p3: 46.9,
    p5: 47.3,
    p10: 47.8,
    p15: 48.1,
    p25: 48.6,
    p50: 49.6,
    p75: 50.6,
    p85: 51.1,
    p90: 51.4,
    p95: 51.9,
    p97: 52.3,
    p99: 52.9,
    p999: 54
  },
  {
    month: 54,
    l: 1,
    m: 49.6464,
    s: 0.02863,
    sd: 1.42138,
    p01: 45.3,
    p1: 46.3,
    p3: 47,
    p5: 47.3,
    p10: 47.8,
    p15: 48.2,
    p25: 48.7,
    p50: 49.6,
    p75: 50.6,
    p85: 51.1,
    p90: 51.5,
    p95: 52,
    p97: 52.3,
    p99: 53,
    p999: 54
  },
  {
    month: 55,
    l: 1,
    m: 49.6947,
    s: 0.02861,
    sd: 1.42177,
    p01: 45.3,
    p1: 46.4,
    p3: 47,
    p5: 47.4,
    p10: 47.9,
    p15: 48.2,
    p25: 48.7,
    p50: 49.7,
    p75: 50.7,
    p85: 51.2,
    p90: 51.5,
    p95: 52,
    p97: 52.4,
    p99: 53,
    p999: 54.1
  },
  {
    month: 56,
    l: 1,
    m: 49.7421,
    s: 0.02859,
    sd: 1.42213,
    p01: 45.3,
    p1: 46.4,
    p3: 47.1,
    p5: 47.4,
    p10: 47.9,
    p15: 48.3,
    p25: 48.8,
    p50: 49.7,
    p75: 50.7,
    p85: 51.2,
    p90: 51.6,
    p95: 52.1,
    p97: 52.4,
    p99: 53.1,
    p999: 54.1
  },
  {
    month: 57,
    l: 1,
    m: 49.7885,
    s: 0.02856,
    sd: 1.42196,
    p01: 45.4,
    p1: 46.5,
    p3: 47.1,
    p5: 47.4,
    p10: 48,
    p15: 48.3,
    p25: 48.8,
    p50: 49.8,
    p75: 50.7,
    p85: 51.3,
    p90: 51.6,
    p95: 52.1,
    p97: 52.5,
    p99: 53.1,
    p999: 54.2
  },
  {
    month: 58,
    l: 1,
    m: 49.8341,
    s: 0.02854,
    sd: 1.42227,
    p01: 45.4,
    p1: 46.5,
    p3: 47.2,
    p5: 47.5,
    p10: 48,
    p15: 48.4,
    p25: 48.9,
    p50: 49.8,
    p75: 50.8,
    p85: 51.3,
    p90: 51.7,
    p95: 52.2,
    p97: 52.5,
    p99: 53.1,
    p999: 54.2
  },
  {
    month: 59,
    l: 1,
    m: 49.8789,
    s: 0.02852,
    sd: 1.42255,
    p01: 45.5,
    p1: 46.6,
    p3: 47.2,
    p5: 47.5,
    p10: 48.1,
    p15: 48.4,
    p25: 48.9,
    p50: 49.9,
    p75: 50.8,
    p85: 51.4,
    p90: 51.7,
    p95: 52.2,
    p97: 52.6,
    p99: 53.2,
    p999: 54.3
  },
  {
    month: 60,
    l: 1,
    m: 49.9229,
    s: 0.0285,
    sd: 1.4228,
    p01: 45.5,
    p1: 46.6,
    p3: 47.2,
    p5: 47.6,
    p10: 48.1,
    p15: 48.4,
    p25: 49,
    p50: 49.9,
    p75: 50.9,
    p85: 51.4,
    p90: 51.7,
    p95: 52.3,
    p97: 52.6,
    p99: 53.2,
    p999: 54.3
  }
]

export default hcValues
