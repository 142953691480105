import { Nullable } from '../../utils'
import { Signature } from './common'
import { BasePatient } from './patients'
import { NewChiefComplaint } from './visits'

export enum EventRecurrence {
  UNIQUE = 'unique',
  DAILY = 'daily',
  WEEKLY = 'weekly',
}

export enum CalendarSlotUnit {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
}

export interface NewRoomEvent {
  name: string
  description?: string
  recurrence: EventRecurrence
  start: string
  end: string
}
export interface RoomEvent {
  id: string
  recurrence: EventRecurrence
  name: string
  description: Nullable<string>
  start: string
  end: string
  updated: Signature
}
export interface NewRegistrationData {
  type: string
  location: string
  room: string
  start: string
  end: string
  patient: BasePatient
  telehealth?: boolean
  chiefComplaint?: NewChiefComplaint
}

export interface RegistrationData {
  type: string
  location: string
  room: string
  start: string
  end: string
  patient: BasePatient
  telehealth: Nullable<boolean>
  chiefComplaint: Nullable<NewChiefComplaint>
}
export interface ScheduledVisit {
  id: string
  registrationData: RegistrationData
  created: Nullable<Signature>
  updated: Nullable<Signature>
}
