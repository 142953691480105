import { isValidObjectId, validateArray, validateNonEmptyObject, validateString } from '../../utils'

/**
 * Walking Doctors Role / Permission definitions
 *
 * Introduction: this system allows you to store role-based permissions as a
 * JSON blob and run various checks on them.
 *
 * Three types of roles are supported:
 *
 * 1) Global roles: These are in effect for the whole app, not just an
 * individual health system or location For example, you can have a role that
 * makes the user an Admin for the whole WD system, or a role that marks a
 * user as inactive for all health systems.
 *
 * 2) System roles: These roles apply to an entire health system and all the
 * locations inside it. For example, if a user has the Doctor role for a
 * health system, they are a doctor for every location in that system. The
 * special role SystemAdmin controls administrative access to an entire
 * system. Any system role also counts as a local role in all the locations in
 * a system.
 *
 * 3) Local roles: These roles apply only to a specific location inside a
 * health system. For example a Doctor at Locaiton X can only access patients
 * at Location X, unless they also have permission at Location Y, or if they
 * have a system permission. The special role LocalAdmin provides
 * administrative access to a location.
 *
 * Format:
 *
 * The internal permissions format is designed to be compact, rather than
 * readable. The Permissions class provides full API access to this format.
 *
 * Example
 * const permissions: PermissionsObject = {
 *  roles: [
 *    GlobalRole.ADMIN, // This is the list of global roles.
 *    GlobalRole.TRANSLATOR, // Values in here apply across all locations.
 *  ]
 *  '59c477a3e7eec54535164c29': {
 *    // Entry for a health system
 *    roles: [CustomerRole.SYSTEM_ADMIN],
 *    '59c477a3e7eec54535164c26': [
 *      // Entry for a specific location
 *      CustomerRole.DOCTOR,
 *      CustomerRole.LOCAL_ADMIN
 *    ]
 *  },
 *  '59cedfba9ae80d05757f54e9': {
 *    // Another system example, users can
 *    roles: [
 *      // be members of more than one system
 *      CustomerRole.DOCTOR,
 *      CustomerRole.NURSE,
 *      CustomerRole.PHARMACIST,
 *   ],
 *   '59cedfba9ae80d05757f54e7': [
 *     // Another location
 *     CustomerRole.PHARMACIST, // Specific local role
 *   ]
 *  }
 * }
 */

export enum GlobalRole {
  ADMIN = 'Admin',
  TRANSLATOR = 'Translator',
}

export enum CustomerRole {
  SYSTEM_ADMIN = 'SystemAdmin',
  LOCAL_ADMIN = 'LocalAdmin',
  DOCTOR = 'Doctor',
  NURSE = 'Nurse',
  PHARMACIST = 'Pharmacist',
  PHARMACIST_ADMIN = 'PharmacyAdmin',
  REGISTRAR = 'Registrar',
  BILLING = 'Billing',
  BILLING_ADMIN = 'BillingAdmin',
  LAB = 'Lab',
}

export type SystemRole = Exclude<CustomerRole, CustomerRole.LOCAL_ADMIN>
export type LocalRole = Exclude<CustomerRole, CustomerRole.SYSTEM_ADMIN>

type SpecificLocationPermissions = {
  [key: Exclude<string, 'roles'>]: Array<LocalRole>
}

type SystemPermissions = SpecificLocationPermissions | { roles: Array<SystemRole> }

type SpecificSystemPermissions = {
  [key: Exclude<string, 'roles'>]: SystemPermissions
}

type PermissionsObject = SpecificSystemPermissions | { roles: Array<GlobalRole> }

const validateGlobalRole = (value: unknown): asserts value is GlobalRole => {
  validateString(value)

  const globalRoles = Object.values(GlobalRole) as Array<string>
  if (!globalRoles.includes(value)) {
    throw new Error(`${value} is not a global role`)
  }
}

const validateSystemRole = (value: unknown): asserts value is SystemRole => {
  validateString(value)

  const systemRoles = Object.values(CustomerRole).filter(
    (role) => role !== CustomerRole.LOCAL_ADMIN
  )
  if (!(systemRoles as Array<string>).includes(value)) {
    throw new Error(`${value} is not a system role`)
  }
}

const validateLocalRole = (value: unknown): asserts value is LocalRole => {
  validateString(value)

  const localRoles = Object.values(CustomerRole).filter(
    (role) => role !== CustomerRole.SYSTEM_ADMIN
  )
  if (!(localRoles as Array<string>).includes(value)) {
    throw new Error(`${value} is not a local role`)
  }
}

/**
 * Validates a permissions object and provides functions to check and modify permissions.
 */
export class Permissions {
  private permissions: PermissionsObject

  constructor(rawPermissions: string) {
    const obj = JSON.parse(rawPermissions)
    Permissions.validatePermissions(obj)

    this.permissions = obj
  }

  // /**
  //  * Returns a list of system ids.
  //  */
  // getSystemIDs() {
  //   validatePermissions(this.permissions)
  //   return Object.keys(this.permissions).filter((system) => system !== 'roles')
  // }

  // /**
  //  * Returns a list of location ids.
  //  */
  // getLocationIDs() {
  //   validatePermissions(this.permissions)
  //   const locationIDs = []
  //   this.getSystemIDs().forEach((id) =>
  //     locationIDs.push(...Object.keys(this.permissions[id]).filter((location) => location !== 'roles'))
  //   )
  //   return locationIDs
  // }

  // /**
  //  * Serializes the permissions object to a string. Throws an Error if the permissions
  //  * object is invalid.
  //  */
  // toString() {
  //   validatePermissions(this.permissions)
  //   return JSON.stringify(this.permissions)
  // }

  /**
   * Returns true if the user has the given global role.
   */
  hasGlobalRole(role: GlobalRole) {
    return Array.isArray(this.permissions.roles) && this.permissions.roles.includes(role)
  }

  /**
   * Validates a permissions object. Returns true on success and throws an Error
   * if permissions fails check.
   */
  static validatePermissions(value: unknown): asserts value is PermissionsObject {
    validateNonEmptyObject(value)

    Object.keys(value).forEach((globalKey) => {
      if (globalKey !== 'roles' && !isValidObjectId(globalKey)) {
        throw new Error(`Invalid global key ${globalKey}`)
      }
    })

    // check global roles if exist
    if (value?.roles) {
      validateArray(value.roles)
      value.roles.forEach(validateGlobalRole)
    }

    // check system permissions
    Object.keys(value).forEach((globalLevelKey) => {
      if (globalLevelKey !== 'roles') {
        Permissions.validateSystemPermissions(value[globalLevelKey])
      }
    })
  }

  static validateSystemPermissions(value: unknown): asserts value is SystemPermissions {
    validateNonEmptyObject(value)

    Object.keys(value).forEach((systemLevelKey) => {
      if (systemLevelKey !== 'roles' && !isValidObjectId(systemLevelKey)) {
        throw new Error(`Invalid system key ${systemLevelKey}`)
      }
    })

    // Check system roles
    if (value?.roles) {
      validateArray(value?.roles)
      value.roles.forEach(validateSystemRole)
    }

    // Check local permissions
    Object.keys(value).forEach((systemLevelKey) => {
      if (systemLevelKey !== 'roles') {
        Permissions.validateLocalPermissions(value[systemLevelKey])
      }
    })
  }

  static validateLocalPermissions(value: unknown): asserts value is SpecificLocationPermissions {
    validateArray(value)

    value.forEach(validateLocalRole)
  }
}
