import { useQuery } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullList, PaginatedResult, PaginatedVariables } from '../../api/interfaces'
import { GET_LISTS } from '../../api/requests/exams'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  errorGetListsDefault: {
    id: 'useList.error_get_lists_default',
    defaultMessage: 'Something went wrong while trying to get lists. Please, try again',
  },
})

interface Data {
  lists: PaginatedResult<FullList>
}

export interface ListContextValue {
  lists: Array<FullList>
  getList: (id: string) => Nullable<FullList>
  isLoading: boolean
  error: Nullable<string>
  onErrorClose?: () => void
}

export const ListContext = React.createContext<Nullable<ListContextValue>>(null)
export const ListProvider: React.FC = (props) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (errorCode) => {
    switch (errorCode) {
      case 'error_getting_lists':
      default:
        return setError(intl.formatMessage(messages.errorGetListsDefault))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const onCompleted = () => {
    setError(null)
  }

  const { loading, data } = useQuery<Data, PaginatedVariables>(GET_LISTS, {
    onCompleted,
    onError,
    fetchPolicy: 'cache-first',
  })

  const getList = (id: string): Nullable<FullList> => {
    if (!data || !Array.isArray(data?.lists?.docs)) {
      return null
    }

    const found = data.lists.docs.find((list) => list.id === id)

    return found ?? null
  }

  const onErrorClose = () => {
    setError(null)
  }

  const value: ListContextValue = {
    lists: data?.lists?.docs ?? [],
    getList,
    error,
    onErrorClose,
    isLoading: !!loading,
  }

  return <ListContext.Provider value={value}>{props.children}</ListContext.Provider>
}
