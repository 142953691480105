import { gql } from '@apollo/client'

import { UserSignature } from '../fragments'
import { FullBillableItem, FullPrice } from '../fragments/pricing'

// get all
export const GET_PRICELISTS = gql`
  ${UserSignature}
  query pricelists($q: String, $offset: Int, $limit: Int) {
    pricelists(q: $q, offset: $offset, limit: $limit) {
      docs {
        id
        name
        description
        currency
        updated {
          ...UserSignature
        }
      }
      count
    }
  }
`

// get billing tag list
export const GET_BILLABLE_ITEMS = gql`
  ${FullBillableItem}
  query ($category: String) {
    billableItems(category: $category) {
      docs {
        ...FullBillableItem
      }
      count
    }
  }
`

// create a new pricelist
export const CREATE_PRICELIST = gql`
  ${UserSignature}
  mutation ($pricelist: NewPricelist!) {
    createPricelist(pricelist: $pricelist) {
      id
      name
      description
      currency
      updated {
        ...UserSignature
      }
    }
  }
`
// update the whole pricelist
export const UPDATE_PRICELIST = gql`
  ${UserSignature}
  mutation ($id: ID!, $pricelist: NewPricelist!) {
    updatePricelist(id: $id, pricelist: $pricelist) {
      id
      name
      description
      currency
      updated {
        ...UserSignature
      }
    }
  }
`
// delete a pricelist
export const DELETE_PRICELIST = gql`
  mutation ($id: ID!) {
    deletePricelist(id: $id) {
      done
    }
  }
`

// get billing tag list
export const GET_PRICES = gql`
  ${FullPrice}
  query ($category: [String], $pricelistId: [String], $billingCode: [String]) {
    prices(category: $category, pricelistId: $pricelistId, billingCode: $billingCode) {
      docs {
        ...FullPrice
      }
      count
    }
  }
`

// update single price
export const UPDATE_PRICE = gql`
  ${FullPrice}
  mutation ($price: NewPrice!) {
    updatePrice(price: $price) {
      ...FullPrice
    }
  }
`
