import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { COMPLETE_ACTIVE_VISIT } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { getPatientName } from '../Patients'
import { sitemap } from '../Routing'

const messages = defineMessages({
  patientNotFound: {
    id: 'CompleteVisitButton.patient_not_found',
    defaultMessage: 'We could not find this patient.',
  },
  visitNotFound: {
    id: 'CompleteVisitButton.visit_not_found',
    defaultMessage: 'Someone already complete this visit.',
  },
  errorCompleteActionDefault: {
    id: 'CompleteVisitButton.error_complete_action_default',
    defaultMessage: 'We could not complete this visit, try again later.',
  },
})

interface CompleteVisitVariables {
  patientId: string
  visitId: string
}

interface ReturnWithWarning {
  patient: FullPatientWithActiveVisit
  warning: string
}
interface CompleteVisitMutationData {
  completeActiveVisitForDomesticUsers: ReturnWithWarning
}

type Params = MutationHookOptions<CompleteVisitMutationData, CompleteVisitVariables> & {
  patientId: string
  visitId: string
}

export const useCompleteVisit = (params: Params) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()
  const navigate = useNavigate()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (errors) => {
    const code = extractFirstErrorCode(errors)
    switch (code) {
      case 'patient_not_found':
        setError(intl.formatMessage(messages.patientNotFound))
        break

      case 'visit_not_found':
        setError(intl.formatMessage(messages.visitNotFound))
        break

      default:
        setError(intl.formatMessage(messages.errorCompleteActionDefault))
        break
    }
  }

  const onCompleted = (data) => {
    const { patient } = data.completeActiveVisitForDomesticUsers

    const patientName = getPatientName(patient)
    const queryString = encodeURIComponent(patientName)

    const uri = `${sitemap.visits.routes.past.absolutePath}?q=${queryString}`

    navigate(uri)
    return
  }

  const [startExam, { loading: isLoading }] = useMutation(COMPLETE_ACTIVE_VISIT, {
    ...restParams,
    onError,
    onCompleted,
  })

  const onSubmit = () => {
    setError(null)

    const variables = {
      patientId,
      visitId,
    }

    startExam({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    onErrorClose,
  }
}
