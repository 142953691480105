import React from 'react'
import { Box, BoxProps, Container } from '@chakra-ui/react'
import { Header } from './Header'
import { Outlet } from 'react-router'
import { Footer, FOOTER_HEIGHT } from './Footer'

interface AuthTemplateProps extends BoxProps {
  maxW?: string | number
}

export const AuthTemplate: React.FC<AuthTemplateProps> = (props) => {
  const { maxW } = props

  return (
    <Box
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
      minHeight="100vh"
    >
      <Header
        style={{
          position: 'sticky',
          width: '100%',
          zIndex: 4,
          top: 0,
          left: 0,
        }}
      />
      <Container maxW={maxW ?? '48em'} mt={2} paddingBottom={`${FOOTER_HEIGHT}px`}>
        <Outlet />
      </Container>
      <Footer
        style={{
          position: 'absolute',
          width: '100%',
          bottom: 0,
          left: 0,
          zIndex: 2,
        }}
      />
    </Box>
  )
}

export const UnauthTemplate: React.FC = () => {
  return (
    <Box position="relative">
      <Container maxW={500} mx="auto" pt={4}>
        <Outlet />
      </Container>
      <Footer
        style={{
          height: FOOTER_HEIGHT,
          position: 'fixed',
          width: '100%',
          bottom: 0,
          left: 0,
        }}
      />
    </Box>
  )
}
