import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ButtonProps } from '@chakra-ui/react'
import { ConfirmButton, ConfirmButtonProps } from '..'
import { useCancelSubscription } from '../Staff'
import { useSession } from '../Auth'

const messages = defineMessages({
  enableAccount: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel subscription'
  },
  confirmationMessage: {
    id: 'UserPreferences.cancel_subscription_warning',
    defaultMessage: 'You are going to cancel your subscription. You won\'t be charged again and your access will be blocked. Do you want to continue? You can get a new subscription in the future.'
  },
})

export const CancelSubscriptionButton: React.FC<ButtonProps> = props => {
  const { ...buttonProps } = props

  const intl = useIntl()
  const { refresh } = useSession()


  const {
    cancelSubscription,
    isLoading,
    error,
    onErrorClose
  } = useCancelSubscription({ onCompleted: refresh })

  const onConfirm = () => {
    cancelSubscription()
  }


  const buttonStyles = {
    borderRadius: 'md',
    isLoading,
    ...buttonProps
  }

  const cleanVisitProps: ConfirmButtonProps = {
    onConfirm,
    message: intl.formatMessage(messages.confirmationMessage),
    buttonProps: {
      label: intl.formatMessage(messages.enableAccount),
      colorScheme: 'red',
      ...buttonStyles
    },
    confirmButtonProps: {
      isLoading,
      colorScheme: 'red',
      label: intl.formatMessage(messages.enableAccount)
    },
    error,
    onErrorClose
  }

  return <ConfirmButton {...cleanVisitProps} />
}

