// Child growth standards
// Weight reference values for GIRLS between 0 - 120 months (10 years)
// Data taken from World Health Organization and then parsed to JSON
// Links:
// https://www.who.int/childgrowth/standards/tab_wfa_girls_p_0_13.txt (0 - 13 Weeks)
// https://www.who.int/childgrowth/standards/tab_wfa_girls_p_0_5.txt  (0 - 60 Months)
// https://www.who.int/growthref/wfa_girls_perc_WHO2007_exp.txt       (61 - 120 Months)

const weightValues = [
  {
    week: 0,
    l: 0.3809,
    m: 3.2322,
    s: 0.14171,
    p01: 2,
    p1: 2.3,
    p3: 2.4,
    p5: 2.5,
    p10: 2.7,
    p15: 2.8,
    p25: 2.9,
    p50: 3.2,
    p75: 3.6,
    p85: 3.7,
    p90: 3.9,
    p95: 4,
    p97: 4.2,
    p99: 4.4,
    p999: 4.8
  },
  {
    week: 1,
    l: 0.2671,
    m: 3.3388,
    s: 0.146,
    p01: 2.1,
    p1: 2.3,
    p3: 2.5,
    p5: 2.6,
    p10: 2.8,
    p15: 2.9,
    p25: 3,
    p50: 3.3,
    p75: 3.7,
    p85: 3.9,
    p90: 4,
    p95: 4.2,
    p97: 4.4,
    p99: 4.6,
    p999: 5.1
  },
  {
    week: 2,
    l: 0.2304,
    m: 3.5693,
    s: 0.14339,
    p01: 2.2,
    p1: 2.5,
    p3: 2.7,
    p5: 2.8,
    p10: 3,
    p15: 3.1,
    p25: 3.2,
    p50: 3.6,
    p75: 3.9,
    p85: 4.1,
    p90: 4.3,
    p95: 4.5,
    p97: 4.6,
    p99: 4.9,
    p999: 5.4
  },
  {
    week: 3,
    l: 0.2024,
    m: 3.8352,
    s: 0.1406,
    p01: 2.4,
    p1: 2.7,
    p3: 2.9,
    p5: 3,
    p10: 3.2,
    p15: 3.3,
    p25: 3.5,
    p50: 3.8,
    p75: 4.2,
    p85: 4.4,
    p90: 4.6,
    p95: 4.8,
    p97: 5,
    p99: 5.3,
    p999: 5.8
  },
  {
    week: 4,
    l: 0.1789,
    m: 4.0987,
    s: 0.13805,
    p01: 2.6,
    p1: 2.9,
    p3: 3.1,
    p5: 3.3,
    p10: 3.4,
    p15: 3.5,
    p25: 3.7,
    p50: 4.1,
    p75: 4.5,
    p85: 4.7,
    p90: 4.9,
    p95: 5.1,
    p97: 5.3,
    p99: 5.6,
    p999: 6.2
  },
  {
    week: 5,
    l: 0.1582,
    m: 4.3476,
    s: 0.13583,
    p01: 2.8,
    p1: 3.1,
    p3: 3.3,
    p5: 3.5,
    p10: 3.6,
    p15: 3.8,
    p25: 4,
    p50: 4.3,
    p75: 4.8,
    p85: 5,
    p90: 5.2,
    p95: 5.4,
    p97: 5.6,
    p99: 5.9,
    p999: 6.5
  },
  {
    week: 6,
    l: 0.1395,
    m: 4.5793,
    s: 0.13392,
    p01: 3,
    p1: 3.3,
    p3: 3.5,
    p5: 3.7,
    p10: 3.8,
    p15: 4,
    p25: 4.2,
    p50: 4.6,
    p75: 5,
    p85: 5.3,
    p90: 5.4,
    p95: 5.7,
    p97: 5.9,
    p99: 6.2,
    p999: 6.8
  },
  {
    week: 7,
    l: 0.1224,
    m: 4.795,
    s: 0.13228,
    p01: 3.2,
    p1: 3.5,
    p3: 3.7,
    p5: 3.8,
    p10: 4,
    p15: 4.2,
    p25: 4.4,
    p50: 4.8,
    p75: 5.2,
    p85: 5.5,
    p90: 5.7,
    p95: 5.9,
    p97: 6.1,
    p99: 6.5,
    p999: 7.1
  },
  {
    week: 8,
    l: 0.1065,
    m: 4.9959,
    s: 0.13087,
    p01: 3.3,
    p1: 3.7,
    p3: 3.9,
    p5: 4,
    p10: 4.2,
    p15: 4.4,
    p25: 4.6,
    p50: 5,
    p75: 5.5,
    p85: 5.7,
    p90: 5.9,
    p95: 6.2,
    p97: 6.4,
    p99: 6.7,
    p999: 7.4
  },
  {
    week: 9,
    l: 0.0918,
    m: 5.1842,
    s: 0.12966,
    p01: 3.4,
    p1: 3.8,
    p3: 4.1,
    p5: 4.2,
    p10: 4.4,
    p15: 4.5,
    p25: 4.7,
    p50: 5.2,
    p75: 5.7,
    p85: 5.9,
    p90: 6.1,
    p95: 6.4,
    p97: 6.6,
    p99: 7,
    p999: 7.7
  },
  {
    week: 10,
    l: 0.0779,
    m: 5.3618,
    s: 0.12861,
    p01: 3.6,
    p1: 4,
    p3: 4.2,
    p5: 4.3,
    p10: 4.5,
    p15: 4.7,
    p25: 4.9,
    p50: 5.4,
    p75: 5.8,
    p85: 6.1,
    p90: 6.3,
    p95: 6.6,
    p97: 6.8,
    p99: 7.2,
    p999: 7.9
  },
  {
    week: 11,
    l: 0.0648,
    m: 5.5295,
    s: 0.1277,
    p01: 3.7,
    p1: 4.1,
    p3: 4.3,
    p5: 4.5,
    p10: 4.7,
    p15: 4.8,
    p25: 5.1,
    p50: 5.5,
    p75: 6,
    p85: 6.3,
    p90: 6.5,
    p95: 6.8,
    p97: 7,
    p99: 7.4,
    p999: 8.2
  },
  {
    week: 12,
    l: 0.0525,
    m: 5.6883,
    s: 0.12691,
    p01: 3.8,
    p1: 4.2,
    p3: 4.5,
    p5: 4.6,
    p10: 4.8,
    p15: 5,
    p25: 5.2,
    p50: 5.7,
    p75: 6.2,
    p85: 6.5,
    p90: 6.7,
    p95: 7,
    p97: 7.2,
    p99: 7.6,
    p999: 8.4
  },
  {
    week: 13,
    l: 0.0407,
    m: 5.8393,
    s: 0.12622,
    p01: 3.9,
    p1: 4.3,
    p3: 4.6,
    p5: 4.7,
    p10: 5,
    p15: 5.1,
    p25: 5.4,
    p50: 5.8,
    p75: 6.4,
    p85: 6.7,
    p90: 6.9,
    p95: 7.2,
    p97: 7.4,
    p99: 7.8,
    p999: 8.6
  },
  {
    month: 0,
    l: 0.3809,
    m: 3.2322,
    s: 0.14171,
    p01: 2,
    p1: 2.3,
    p3: 2.4,
    p5: 2.5,
    p10: 2.7,
    p15: 2.8,
    p25: 2.9,
    p50: 3.2,
    p75: 3.6,
    p85: 3.7,
    p90: 3.9,
    p95: 4,
    p97: 4.2,
    p99: 4.4,
    p999: 4.8
  },
  {
    month: 1,
    l: 0.1714,
    m: 4.1873,
    s: 0.13724,
    p01: 2.7,
    p1: 3,
    p3: 3.2,
    p5: 3.3,
    p10: 3.5,
    p15: 3.6,
    p25: 3.8,
    p50: 4.2,
    p75: 4.6,
    p85: 4.8,
    p90: 5,
    p95: 5.2,
    p97: 5.4,
    p99: 5.7,
    p999: 6.3
  },
  {
    month: 2,
    l: 0.0962,
    m: 5.1282,
    s: 0.13,
    p01: 3.4,
    p1: 3.8,
    p3: 4,
    p5: 4.1,
    p10: 4.3,
    p15: 4.5,
    p25: 4.7,
    p50: 5.1,
    p75: 5.6,
    p85: 5.9,
    p90: 6,
    p95: 6.3,
    p97: 6.5,
    p99: 6.9,
    p999: 7.6
  },
  {
    month: 3,
    l: 0.0402,
    m: 5.8458,
    s: 0.12619,
    p01: 3.9,
    p1: 4.4,
    p3: 4.6,
    p5: 4.7,
    p10: 5,
    p15: 5.1,
    p25: 5.4,
    p50: 5.8,
    p75: 6.4,
    p85: 6.7,
    p90: 6.9,
    p95: 7.2,
    p97: 7.4,
    p99: 7.8,
    p999: 8.6
  },
  {
    month: 4,
    l: -0.005,
    m: 6.4237,
    s: 0.12402,
    p01: 4.4,
    p1: 4.8,
    p3: 5.1,
    p5: 5.2,
    p10: 5.5,
    p15: 5.6,
    p25: 5.9,
    p50: 6.4,
    p75: 7,
    p85: 7.3,
    p90: 7.5,
    p95: 7.9,
    p97: 8.1,
    p99: 8.6,
    p999: 9.4
  },
  {
    month: 5,
    l: -0.043,
    m: 6.8985,
    s: 0.12274,
    p01: 4.7,
    p1: 5.2,
    p3: 5.5,
    p5: 5.6,
    p10: 5.9,
    p15: 6.1,
    p25: 6.4,
    p50: 6.9,
    p75: 7.5,
    p85: 7.8,
    p90: 8.1,
    p95: 8.4,
    p97: 8.7,
    p99: 9.2,
    p999: 10.1
  },
  {
    month: 6,
    l: -0.0756,
    m: 7.297,
    s: 0.12204,
    p01: 5,
    p1: 5.5,
    p3: 5.8,
    p5: 6,
    p10: 6.2,
    p15: 6.4,
    p25: 6.7,
    p50: 7.3,
    p75: 7.9,
    p85: 8.3,
    p90: 8.5,
    p95: 8.9,
    p97: 9.2,
    p99: 9.7,
    p999: 10.7
  },
  {
    month: 7,
    l: -0.1039,
    m: 7.6422,
    s: 0.12178,
    p01: 5.3,
    p1: 5.8,
    p3: 6.1,
    p5: 6.3,
    p10: 6.5,
    p15: 6.7,
    p25: 7,
    p50: 7.6,
    p75: 8.3,
    p85: 8.7,
    p90: 8.9,
    p95: 9.4,
    p97: 9.6,
    p99: 10.2,
    p999: 11.2
  },
  {
    month: 8,
    l: -0.1288,
    m: 7.9487,
    s: 0.12181,
    p01: 5.5,
    p1: 6,
    p3: 6.3,
    p5: 6.5,
    p10: 6.8,
    p15: 7,
    p25: 7.3,
    p50: 7.9,
    p75: 8.6,
    p85: 9,
    p90: 9.3,
    p95: 9.7,
    p97: 10,
    p99: 10.6,
    p999: 11.7
  },
  {
    month: 9,
    l: -0.1507,
    m: 8.2254,
    s: 0.12199,
    p01: 5.7,
    p1: 6.2,
    p3: 6.6,
    p5: 6.8,
    p10: 7,
    p15: 7.3,
    p25: 7.6,
    p50: 8.2,
    p75: 8.9,
    p85: 9.3,
    p90: 9.6,
    p95: 10.1,
    p97: 10.4,
    p99: 11,
    p999: 12.1
  },
  {
    month: 10,
    l: -0.17,
    m: 8.48,
    s: 0.12223,
    p01: 5.9,
    p1: 6.4,
    p3: 6.8,
    p5: 7,
    p10: 7.3,
    p15: 7.5,
    p25: 7.8,
    p50: 8.5,
    p75: 9.2,
    p85: 9.6,
    p90: 9.9,
    p95: 10.4,
    p97: 10.7,
    p99: 11.3,
    p999: 12.5
  },
  {
    month: 11,
    l: -0.1872,
    m: 8.7192,
    s: 0.12247,
    p01: 6,
    p1: 6.6,
    p3: 7,
    p5: 7.2,
    p10: 7.5,
    p15: 7.7,
    p25: 8,
    p50: 8.7,
    p75: 9.5,
    p85: 9.9,
    p90: 10.2,
    p95: 10.7,
    p97: 11,
    p99: 11.7,
    p999: 12.9
  },
  {
    month: 12,
    l: -0.2024,
    m: 8.9481,
    s: 0.12268,
    p01: 6.2,
    p1: 6.8,
    p3: 7.1,
    p5: 7.3,
    p10: 7.7,
    p15: 7.9,
    p25: 8.2,
    p50: 8.9,
    p75: 9.7,
    p85: 10.2,
    p90: 10.5,
    p95: 11,
    p97: 11.3,
    p99: 12,
    p999: 13.3
  },
  {
    month: 13,
    l: -0.2158,
    m: 9.1699,
    s: 0.12283,
    p01: 6.4,
    p1: 6.9,
    p3: 7.3,
    p5: 7.5,
    p10: 7.9,
    p15: 8.1,
    p25: 8.4,
    p50: 9.2,
    p75: 10,
    p85: 10.4,
    p90: 10.8,
    p95: 11.3,
    p97: 11.6,
    p99: 12.3,
    p999: 13.6
  },
  {
    month: 14,
    l: -0.2278,
    m: 9.387,
    s: 0.12294,
    p01: 6.5,
    p1: 7.1,
    p3: 7.5,
    p5: 7.7,
    p10: 8,
    p15: 8.3,
    p25: 8.6,
    p50: 9.4,
    p75: 10.2,
    p85: 10.7,
    p90: 11,
    p95: 11.5,
    p97: 11.9,
    p99: 12.6,
    p999: 14
  },
  {
    month: 15,
    l: -0.2384,
    m: 9.6008,
    s: 0.12299,
    p01: 6.7,
    p1: 7.3,
    p3: 7.7,
    p5: 7.9,
    p10: 8.2,
    p15: 8.5,
    p25: 8.8,
    p50: 9.6,
    p75: 10.4,
    p85: 10.9,
    p90: 11.3,
    p95: 11.8,
    p97: 12.2,
    p99: 12.9,
    p999: 14.3
  },
  {
    month: 16,
    l: -0.2478,
    m: 9.8124,
    s: 0.12303,
    p01: 6.8,
    p1: 7.4,
    p3: 7.8,
    p5: 8.1,
    p10: 8.4,
    p15: 8.7,
    p25: 9,
    p50: 9.8,
    p75: 10.7,
    p85: 11.2,
    p90: 11.5,
    p95: 12.1,
    p97: 12.5,
    p99: 13.2,
    p999: 14.6
  },
  {
    month: 17,
    l: -0.2562,
    m: 10.0226,
    s: 0.12306,
    p01: 7,
    p1: 7.6,
    p3: 8,
    p5: 8.2,
    p10: 8.6,
    p15: 8.8,
    p25: 9.2,
    p50: 10,
    p75: 10.9,
    p85: 11.4,
    p90: 11.8,
    p95: 12.3,
    p97: 12.7,
    p99: 13.5,
    p999: 15
  },
  {
    month: 18,
    l: -0.2637,
    m: 10.2315,
    s: 0.12309,
    p01: 7.1,
    p1: 7.8,
    p3: 8.2,
    p5: 8.4,
    p10: 8.8,
    p15: 9,
    p25: 9.4,
    p50: 10.2,
    p75: 11.1,
    p85: 11.6,
    p90: 12,
    p95: 12.6,
    p97: 13,
    p99: 13.8,
    p999: 15.3
  },
  {
    month: 19,
    l: -0.2703,
    m: 10.4393,
    s: 0.12315,
    p01: 7.3,
    p1: 7.9,
    p3: 8.3,
    p5: 8.6,
    p10: 8.9,
    p15: 9.2,
    p25: 9.6,
    p50: 10.4,
    p75: 11.4,
    p85: 11.9,
    p90: 12.3,
    p95: 12.9,
    p97: 13.3,
    p99: 14.1,
    p999: 15.6
  },
  {
    month: 20,
    l: -0.2762,
    m: 10.6464,
    s: 0.12323,
    p01: 7.4,
    p1: 8.1,
    p3: 8.5,
    p5: 8.7,
    p10: 9.1,
    p15: 9.4,
    p25: 9.8,
    p50: 10.6,
    p75: 11.6,
    p85: 12.1,
    p90: 12.5,
    p95: 13.1,
    p97: 13.5,
    p99: 14.4,
    p999: 15.9
  },
  {
    month: 21,
    l: -0.2815,
    m: 10.8534,
    s: 0.12335,
    p01: 7.6,
    p1: 8.2,
    p3: 8.7,
    p5: 8.9,
    p10: 9.3,
    p15: 9.6,
    p25: 10,
    p50: 10.9,
    p75: 11.8,
    p85: 12.4,
    p90: 12.8,
    p95: 13.4,
    p97: 13.8,
    p99: 14.6,
    p999: 16.2
  },
  {
    month: 22,
    l: -0.2862,
    m: 11.0608,
    s: 0.1235,
    p01: 7.7,
    p1: 8.4,
    p3: 8.8,
    p5: 9.1,
    p10: 9.5,
    p15: 9.8,
    p25: 10.2,
    p50: 11.1,
    p75: 12,
    p85: 12.6,
    p90: 13,
    p95: 13.6,
    p97: 14.1,
    p99: 14.9,
    p999: 16.6
  },
  {
    month: 23,
    l: -0.2903,
    m: 11.2688,
    s: 0.12369,
    p01: 7.8,
    p1: 8.5,
    p3: 9,
    p5: 9.2,
    p10: 9.7,
    p15: 9.9,
    p25: 10.4,
    p50: 11.3,
    p75: 12.3,
    p85: 12.8,
    p90: 13.3,
    p95: 13.9,
    p97: 14.3,
    p99: 15.2,
    p999: 16.9
  },
  {
    month: 24,
    l: -0.2941,
    m: 11.4775,
    s: 0.1239,
    p01: 8,
    p1: 8.7,
    p3: 9.2,
    p5: 9.4,
    p10: 9.8,
    p15: 10.1,
    p25: 10.6,
    p50: 11.5,
    p75: 12.5,
    p85: 13.1,
    p90: 13.5,
    p95: 14.2,
    p97: 14.6,
    p99: 15.5,
    p999: 17.2
  },
  {
    month: 25,
    l: -0.2975,
    m: 11.6864,
    s: 0.12414,
    p01: 8.1,
    p1: 8.9,
    p3: 9.3,
    p5: 9.6,
    p10: 10,
    p15: 10.3,
    p25: 10.8,
    p50: 11.7,
    p75: 12.7,
    p85: 13.3,
    p90: 13.8,
    p95: 14.4,
    p97: 14.9,
    p99: 15.8,
    p999: 17.6
  },
  {
    month: 26,
    l: -0.3005,
    m: 11.8947,
    s: 0.12441,
    p01: 8.3,
    p1: 9,
    p3: 9.5,
    p5: 9.8,
    p10: 10.2,
    p15: 10.5,
    p25: 10.9,
    p50: 11.9,
    p75: 12.9,
    p85: 13.6,
    p90: 14,
    p95: 14.7,
    p97: 15.2,
    p99: 16.1,
    p999: 17.9
  },
  {
    month: 27,
    l: -0.3032,
    m: 12.1015,
    s: 0.12472,
    p01: 8.4,
    p1: 9.2,
    p3: 9.6,
    p5: 9.9,
    p10: 10.4,
    p15: 10.7,
    p25: 11.1,
    p50: 12.1,
    p75: 13.2,
    p85: 13.8,
    p90: 14.3,
    p95: 15,
    p97: 15.4,
    p99: 16.4,
    p999: 18.2
  },
  {
    month: 28,
    l: -0.3057,
    m: 12.3059,
    s: 0.12506,
    p01: 8.5,
    p1: 9.3,
    p3: 9.8,
    p5: 10.1,
    p10: 10.5,
    p15: 10.8,
    p25: 11.3,
    p50: 12.3,
    p75: 13.4,
    p85: 14,
    p90: 14.5,
    p95: 15.2,
    p97: 15.7,
    p99: 16.7,
    p999: 18.6
  },
  {
    month: 29,
    l: -0.308,
    m: 12.5073,
    s: 0.12545,
    p01: 8.7,
    p1: 9.5,
    p3: 10,
    p5: 10.2,
    p10: 10.7,
    p15: 11,
    p25: 11.5,
    p50: 12.5,
    p75: 13.6,
    p85: 14.3,
    p90: 14.7,
    p95: 15.5,
    p97: 16,
    p99: 17,
    p999: 18.9
  },
  {
    month: 30,
    l: -0.3101,
    m: 12.7055,
    s: 0.12587,
    p01: 8.8,
    p1: 9.6,
    p3: 10.1,
    p5: 10.4,
    p10: 10.9,
    p15: 11.2,
    p25: 11.7,
    p50: 12.7,
    p75: 13.8,
    p85: 14.5,
    p90: 15,
    p95: 15.7,
    p97: 16.2,
    p99: 17.3,
    p999: 19.2
  },
  {
    month: 31,
    l: -0.312,
    m: 12.9006,
    s: 0.12633,
    p01: 8.9,
    p1: 9.7,
    p3: 10.3,
    p5: 10.5,
    p10: 11,
    p15: 11.3,
    p25: 11.9,
    p50: 12.9,
    p75: 14.1,
    p85: 14.7,
    p90: 15.2,
    p95: 16,
    p97: 16.5,
    p99: 17.6,
    p999: 19.6
  },
  {
    month: 32,
    l: -0.3138,
    m: 13.093,
    s: 0.12683,
    p01: 9,
    p1: 9.9,
    p3: 10.4,
    p5: 10.7,
    p10: 11.2,
    p15: 11.5,
    p25: 12,
    p50: 13.1,
    p75: 14.3,
    p85: 15,
    p90: 15.5,
    p95: 16.2,
    p97: 16.8,
    p99: 17.8,
    p999: 19.9
  },
  {
    month: 33,
    l: -0.3155,
    m: 13.2837,
    s: 0.12737,
    p01: 9.2,
    p1: 10,
    p3: 10.5,
    p5: 10.8,
    p10: 11.3,
    p15: 11.7,
    p25: 12.2,
    p50: 13.3,
    p75: 14.5,
    p85: 15.2,
    p90: 15.7,
    p95: 16.5,
    p97: 17,
    p99: 18.1,
    p999: 20.2
  },
  {
    month: 34,
    l: -0.3171,
    m: 13.4731,
    s: 0.12794,
    p01: 9.3,
    p1: 10.1,
    p3: 10.7,
    p5: 11,
    p10: 11.5,
    p15: 11.8,
    p25: 12.4,
    p50: 13.5,
    p75: 14.7,
    p85: 15.4,
    p90: 15.9,
    p95: 16.8,
    p97: 17.3,
    p99: 18.4,
    p999: 20.6
  },
  {
    month: 35,
    l: -0.3186,
    m: 13.6618,
    s: 0.12855,
    p01: 9.4,
    p1: 10.3,
    p3: 10.8,
    p5: 11.1,
    p10: 11.6,
    p15: 12,
    p25: 12.5,
    p50: 13.7,
    p75: 14.9,
    p85: 15.7,
    p90: 16.2,
    p95: 17,
    p97: 17.6,
    p99: 18.7,
    p999: 20.9
  },
  {
    month: 36,
    l: -0.3201,
    m: 13.8503,
    s: 0.12919,
    p01: 9.5,
    p1: 10.4,
    p3: 11,
    p5: 11.3,
    p10: 11.8,
    p15: 12.1,
    p25: 12.7,
    p50: 13.9,
    p75: 15.1,
    p85: 15.9,
    p90: 16.4,
    p95: 17.3,
    p97: 17.8,
    p99: 19,
    p999: 21.2
  },
  {
    month: 37,
    l: -0.3216,
    m: 14.0385,
    s: 0.12988,
    p01: 9.6,
    p1: 10.5,
    p3: 11.1,
    p5: 11.4,
    p10: 11.9,
    p15: 12.3,
    p25: 12.9,
    p50: 14,
    p75: 15.3,
    p85: 16.1,
    p90: 16.7,
    p95: 17.5,
    p97: 18.1,
    p99: 19.3,
    p999: 21.6
  },
  {
    month: 38,
    l: -0.323,
    m: 14.2265,
    s: 0.13059,
    p01: 9.7,
    p1: 10.6,
    p3: 11.2,
    p5: 11.6,
    p10: 12.1,
    p15: 12.5,
    p25: 13,
    p50: 14.2,
    p75: 15.6,
    p85: 16.3,
    p90: 16.9,
    p95: 17.8,
    p97: 18.4,
    p99: 19.6,
    p999: 21.9
  },
  {
    month: 39,
    l: -0.3243,
    m: 14.414,
    s: 0.13135,
    p01: 9.8,
    p1: 10.8,
    p3: 11.4,
    p5: 11.7,
    p10: 12.2,
    p15: 12.6,
    p25: 13.2,
    p50: 14.4,
    p75: 15.8,
    p85: 16.6,
    p90: 17.1,
    p95: 18,
    p97: 18.6,
    p99: 19.9,
    p999: 22.3
  },
  {
    month: 40,
    l: -0.3257,
    m: 14.601,
    s: 0.13213,
    p01: 10,
    p1: 10.9,
    p3: 11.5,
    p5: 11.8,
    p10: 12.4,
    p15: 12.8,
    p25: 13.4,
    p50: 14.6,
    p75: 16,
    p85: 16.8,
    p90: 17.4,
    p95: 18.3,
    p97: 18.9,
    p99: 20.2,
    p999: 22.6
  },
  {
    month: 41,
    l: -0.327,
    m: 14.7873,
    s: 0.13293,
    p01: 10.1,
    p1: 11,
    p3: 11.6,
    p5: 12,
    p10: 12.5,
    p15: 12.9,
    p25: 13.5,
    p50: 14.8,
    p75: 16.2,
    p85: 17,
    p90: 17.6,
    p95: 18.6,
    p97: 19.2,
    p99: 20.5,
    p999: 23
  },
  {
    month: 42,
    l: -0.3283,
    m: 14.9727,
    s: 0.13376,
    p01: 10.2,
    p1: 11.1,
    p3: 11.8,
    p5: 12.1,
    p10: 12.7,
    p15: 13.1,
    p25: 13.7,
    p50: 15,
    p75: 16.4,
    p85: 17.3,
    p90: 17.9,
    p95: 18.8,
    p97: 19.5,
    p99: 20.8,
    p999: 23.3
  },
  {
    month: 43,
    l: -0.3296,
    m: 15.1573,
    s: 0.1346,
    p01: 10.3,
    p1: 11.3,
    p3: 11.9,
    p5: 12.2,
    p10: 12.8,
    p15: 13.2,
    p25: 13.9,
    p50: 15.2,
    p75: 16.6,
    p85: 17.5,
    p90: 18.1,
    p95: 19.1,
    p97: 19.7,
    p99: 21.1,
    p999: 23.7
  },
  {
    month: 44,
    l: -0.3309,
    m: 15.341,
    s: 0.13545,
    p01: 10.4,
    p1: 11.4,
    p3: 12,
    p5: 12.4,
    p10: 13,
    p15: 13.4,
    p25: 14,
    p50: 15.3,
    p75: 16.8,
    p85: 17.7,
    p90: 18.3,
    p95: 19.3,
    p97: 20,
    p99: 21.4,
    p999: 24.1
  },
  {
    month: 45,
    l: -0.3322,
    m: 15.524,
    s: 0.1363,
    p01: 10.5,
    p1: 11.5,
    p3: 12.1,
    p5: 12.5,
    p10: 13.1,
    p15: 13.5,
    p25: 14.2,
    p50: 15.5,
    p75: 17,
    p85: 17.9,
    p90: 18.6,
    p95: 19.6,
    p97: 20.3,
    p99: 21.7,
    p999: 24.4
  },
  {
    month: 46,
    l: -0.3335,
    m: 15.7064,
    s: 0.13716,
    p01: 10.6,
    p1: 11.6,
    p3: 12.3,
    p5: 12.6,
    p10: 13.2,
    p15: 13.7,
    p25: 14.3,
    p50: 15.7,
    p75: 17.3,
    p85: 18.2,
    p90: 18.8,
    p95: 19.9,
    p97: 20.6,
    p99: 22,
    p999: 24.8
  },
  {
    month: 47,
    l: -0.3348,
    m: 15.8882,
    s: 0.138,
    p01: 10.7,
    p1: 11.7,
    p3: 12.4,
    p5: 12.8,
    p10: 13.4,
    p15: 13.8,
    p25: 14.5,
    p50: 15.9,
    p75: 17.5,
    p85: 18.4,
    p90: 19.1,
    p95: 20.1,
    p97: 20.8,
    p99: 22.3,
    p999: 25.2
  },
  {
    month: 48,
    l: -0.3361,
    m: 16.0697,
    s: 0.13884,
    p01: 10.8,
    p1: 11.8,
    p3: 12.5,
    p5: 12.9,
    p10: 13.5,
    p15: 14,
    p25: 14.7,
    p50: 16.1,
    p75: 17.7,
    p85: 18.6,
    p90: 19.3,
    p95: 20.4,
    p97: 21.1,
    p99: 22.6,
    p999: 25.5
  },
  {
    month: 49,
    l: -0.3374,
    m: 16.2511,
    s: 0.13968,
    p01: 10.9,
    p1: 11.9,
    p3: 12.6,
    p5: 13,
    p10: 13.7,
    p15: 14.1,
    p25: 14.8,
    p50: 16.3,
    p75: 17.9,
    p85: 18.9,
    p90: 19.5,
    p95: 20.6,
    p97: 21.4,
    p99: 22.9,
    p999: 25.9
  },
  {
    month: 50,
    l: -0.3387,
    m: 16.4322,
    s: 0.14051,
    p01: 11,
    p1: 12.1,
    p3: 12.8,
    p5: 13.2,
    p10: 13.8,
    p15: 14.3,
    p25: 15,
    p50: 16.4,
    p75: 18.1,
    p85: 19.1,
    p90: 19.8,
    p95: 20.9,
    p97: 21.7,
    p99: 23.2,
    p999: 26.3
  },
  {
    month: 51,
    l: -0.34,
    m: 16.6133,
    s: 0.14132,
    p01: 11.1,
    p1: 12.2,
    p3: 12.9,
    p5: 13.3,
    p10: 13.9,
    p15: 14.4,
    p25: 15.1,
    p50: 16.6,
    p75: 18.3,
    p85: 19.3,
    p90: 20,
    p95: 21.2,
    p97: 22,
    p99: 23.5,
    p999: 26.7
  },
  {
    month: 52,
    l: -0.3414,
    m: 16.7942,
    s: 0.14213,
    p01: 11.2,
    p1: 12.3,
    p3: 13,
    p5: 13.4,
    p10: 14.1,
    p15: 14.5,
    p25: 15.3,
    p50: 16.8,
    p75: 18.5,
    p85: 19.5,
    p90: 20.3,
    p95: 21.4,
    p97: 22.2,
    p99: 23.9,
    p999: 27
  },
  {
    month: 53,
    l: -0.3427,
    m: 16.9748,
    s: 0.14293,
    p01: 11.3,
    p1: 12.4,
    p3: 13.1,
    p5: 13.5,
    p10: 14.2,
    p15: 14.7,
    p25: 15.4,
    p50: 17,
    p75: 18.7,
    p85: 19.8,
    p90: 20.5,
    p95: 21.7,
    p97: 22.5,
    p99: 24.2,
    p999: 27.4
  },
  {
    month: 54,
    l: -0.344,
    m: 17.1551,
    s: 0.14371,
    p01: 11.3,
    p1: 12.5,
    p3: 13.2,
    p5: 13.7,
    p10: 14.3,
    p15: 14.8,
    p25: 15.6,
    p50: 17.2,
    p75: 18.9,
    p85: 20,
    p90: 20.8,
    p95: 22,
    p97: 22.8,
    p99: 24.5,
    p999: 27.8
  },
  {
    month: 55,
    l: -0.3453,
    m: 17.3347,
    s: 0.14448,
    p01: 11.4,
    p1: 12.6,
    p3: 13.4,
    p5: 13.8,
    p10: 14.5,
    p15: 15,
    p25: 15.8,
    p50: 17.3,
    p75: 19.1,
    p85: 20.2,
    p90: 21,
    p95: 22.2,
    p97: 23.1,
    p99: 24.8,
    p999: 28.2
  },
  {
    month: 56,
    l: -0.3466,
    m: 17.5136,
    s: 0.14525,
    p01: 11.5,
    p1: 12.7,
    p3: 13.5,
    p5: 13.9,
    p10: 14.6,
    p15: 15.1,
    p25: 15.9,
    p50: 17.5,
    p75: 19.3,
    p85: 20.4,
    p90: 21.2,
    p95: 22.5,
    p97: 23.3,
    p99: 25.1,
    p999: 28.5
  },
  {
    month: 57,
    l: -0.3479,
    m: 17.6916,
    s: 0.146,
    p01: 11.6,
    p1: 12.8,
    p3: 13.6,
    p5: 14,
    p10: 14.8,
    p15: 15.3,
    p25: 16.1,
    p50: 17.7,
    p75: 19.6,
    p85: 20.7,
    p90: 21.5,
    p95: 22.7,
    p97: 23.6,
    p99: 25.4,
    p999: 28.9
  },
  {
    month: 58,
    l: -0.3492,
    m: 17.8686,
    s: 0.14675,
    p01: 11.7,
    p1: 12.9,
    p3: 13.7,
    p5: 14.2,
    p10: 14.9,
    p15: 15.4,
    p25: 16.2,
    p50: 17.9,
    p75: 19.8,
    p85: 20.9,
    p90: 21.7,
    p95: 23,
    p97: 23.9,
    p99: 25.7,
    p999: 29.3
  },
  {
    month: 59,
    l: -0.3505,
    m: 18.0445,
    s: 0.14748,
    p01: 11.8,
    p1: 13.1,
    p3: 13.8,
    p5: 14.3,
    p10: 15,
    p15: 15.5,
    p25: 16.4,
    p50: 18,
    p75: 20,
    p85: 21.1,
    p90: 21.9,
    p95: 23.3,
    p97: 24.2,
    p99: 26,
    p999: 29.6
  },
  {
    month: 60,
    l: -0.3518,
    m: 18.2193,
    s: 0.14821,
    p01: 11.9,
    p1: 13.2,
    p3: 14,
    p5: 14.4,
    p10: 15.2,
    p15: 15.7,
    p25: 16.5,
    p50: 18.2,
    p75: 20.2,
    p85: 21.3,
    p90: 22.2,
    p95: 23.5,
    p97: 24.4,
    p99: 26.3,
    p999: 30
  },
  {
    month: 61,
    l: -0.4681,
    m: 18.2579,
    s: 0.14295,
    p01: 12.22,
    p1: 13.404,
    p3: 14.173,
    p5: 14.607,
    p10: 15.315,
    p15: 15.821,
    p25: 16.615,
    p50: 18.258,
    p75: 20.151,
    p85: 21.288,
    p90: 22.112,
    p95: 23.423,
    p97: 24.336,
    p99: 26.208,
    p999: 29.949
  },
  {
    month: 62,
    l: -0.4711,
    m: 18.4329,
    s: 0.1435,
    p01: 12.322,
    p1: 13.519,
    p3: 14.297,
    p5: 14.736,
    p10: 15.452,
    p15: 15.965,
    p25: 16.768,
    p50: 18.433,
    p75: 20.352,
    p85: 21.506,
    p90: 22.343,
    p95: 23.674,
    p97: 24.601,
    p99: 26.505,
    p999: 30.313
  },
  {
    month: 63,
    l: -0.4742,
    m: 18.6073,
    s: 0.14404,
    p01: 12.425,
    p1: 13.634,
    p3: 14.421,
    p5: 14.865,
    p10: 15.589,
    p15: 16.108,
    p25: 16.921,
    p50: 18.607,
    p75: 20.553,
    p85: 21.723,
    p90: 22.573,
    p95: 23.924,
    p97: 24.866,
    p99: 26.802,
    p999: 30.677
  },
  {
    month: 64,
    l: -0.4773,
    m: 18.7811,
    s: 0.14459,
    p01: 12.526,
    p1: 13.748,
    p3: 14.544,
    p5: 14.993,
    p10: 15.726,
    p15: 16.25,
    p25: 17.074,
    p50: 18.781,
    p75: 20.754,
    p85: 21.941,
    p90: 22.802,
    p95: 24.174,
    p97: 25.132,
    p99: 27.099,
    p999: 31.044
  },
  {
    month: 65,
    l: -0.4803,
    m: 18.9545,
    s: 0.14514,
    p01: 12.627,
    p1: 13.862,
    p3: 14.666,
    p5: 15.12,
    p10: 15.861,
    p15: 16.392,
    p25: 17.225,
    p50: 18.954,
    p75: 20.954,
    p85: 22.158,
    p90: 23.032,
    p95: 24.425,
    p97: 25.398,
    p99: 27.397,
    p999: 31.411
  },
  {
    month: 66,
    l: -0.4834,
    m: 19.1276,
    s: 0.14569,
    p01: 12.728,
    p1: 13.975,
    p3: 14.788,
    p5: 15.247,
    p10: 15.997,
    p15: 16.534,
    p25: 17.377,
    p50: 19.128,
    p75: 21.154,
    p85: 22.375,
    p90: 23.262,
    p95: 24.676,
    p97: 25.664,
    p99: 27.696,
    p999: 31.781
  },
  {
    month: 67,
    l: -0.4864,
    m: 19.3004,
    s: 0.14624,
    p01: 12.828,
    p1: 14.088,
    p3: 14.909,
    p5: 15.373,
    p10: 16.131,
    p15: 16.675,
    p25: 17.528,
    p50: 19.3,
    p75: 21.353,
    p85: 22.591,
    p90: 23.492,
    p95: 24.927,
    p97: 25.93,
    p99: 27.995,
    p999: 32.151
  },
  {
    month: 68,
    l: -0.4894,
    m: 19.473,
    s: 0.14679,
    p01: 12.928,
    p1: 14.2,
    p3: 15.03,
    p5: 15.5,
    p10: 16.266,
    p15: 16.815,
    p25: 17.678,
    p50: 19.473,
    p75: 21.553,
    p85: 22.808,
    p90: 23.721,
    p95: 25.178,
    p97: 26.197,
    p99: 28.295,
    p999: 32.523
  },
  {
    month: 69,
    l: -0.4924,
    m: 19.6455,
    s: 0.14735,
    p01: 13.027,
    p1: 14.312,
    p3: 15.151,
    p5: 15.625,
    p10: 16.4,
    p15: 16.956,
    p25: 17.829,
    p50: 19.646,
    p75: 21.753,
    p85: 23.026,
    p90: 23.952,
    p95: 25.43,
    p97: 26.465,
    p99: 28.597,
    p999: 32.899
  },
  {
    month: 70,
    l: -0.4954,
    m: 19.818,
    s: 0.1479,
    p01: 13.126,
    p1: 14.424,
    p3: 15.272,
    p5: 15.751,
    p10: 16.534,
    p15: 17.096,
    p25: 17.979,
    p50: 19.818,
    p75: 21.953,
    p85: 23.243,
    p90: 24.183,
    p95: 25.683,
    p97: 26.733,
    p99: 28.899,
    p999: 33.275
  },
  {
    month: 71,
    l: -0.4984,
    m: 19.9908,
    s: 0.14845,
    p01: 13.225,
    p1: 14.536,
    p3: 15.392,
    p5: 15.877,
    p10: 16.668,
    p15: 17.236,
    p25: 18.13,
    p50: 19.991,
    p75: 22.153,
    p85: 23.461,
    p90: 24.414,
    p95: 25.936,
    p97: 27.002,
    p99: 29.203,
    p999: 33.654
  },
  {
    month: 72,
    l: -0.5013,
    m: 20.1639,
    s: 0.149,
    p01: 13.325,
    p1: 14.648,
    p3: 15.513,
    p5: 16.002,
    p10: 16.803,
    p15: 17.377,
    p25: 18.281,
    p50: 20.164,
    p75: 22.354,
    p85: 23.68,
    p90: 24.646,
    p95: 26.19,
    p97: 27.273,
    p99: 29.508,
    p999: 34.035
  },
  {
    month: 73,
    l: -0.5043,
    m: 20.3377,
    s: 0.14955,
    p01: 13.424,
    p1: 14.76,
    p3: 15.634,
    p5: 16.129,
    p10: 16.937,
    p15: 17.518,
    p25: 18.432,
    p50: 20.338,
    p75: 22.556,
    p85: 23.9,
    p90: 24.879,
    p95: 26.446,
    p97: 27.545,
    p99: 29.815,
    p999: 34.42
  },
  {
    month: 74,
    l: -0.5072,
    m: 20.5124,
    s: 0.1501,
    p01: 13.524,
    p1: 14.873,
    p3: 15.756,
    p5: 16.255,
    p10: 17.073,
    p15: 17.66,
    p25: 18.584,
    p50: 20.512,
    p75: 22.759,
    p85: 24.121,
    p90: 25.114,
    p95: 26.704,
    p97: 27.819,
    p99: 30.125,
    p999: 34.808
  },
  {
    month: 75,
    l: -0.51,
    m: 20.6885,
    s: 0.15065,
    p01: 13.624,
    p1: 14.986,
    p3: 15.878,
    p5: 16.383,
    p10: 17.209,
    p15: 17.803,
    p25: 18.737,
    p50: 20.688,
    p75: 22.964,
    p85: 24.344,
    p90: 25.351,
    p95: 26.964,
    p97: 28.095,
    p99: 30.438,
    p999: 35.201
  },
  {
    month: 76,
    l: -0.5129,
    m: 20.8661,
    s: 0.1512,
    p01: 13.726,
    p1: 15.101,
    p3: 16.001,
    p5: 16.511,
    p10: 17.346,
    p15: 17.947,
    p25: 18.892,
    p50: 20.866,
    p75: 23.17,
    p85: 24.569,
    p90: 25.59,
    p95: 27.226,
    p97: 28.375,
    p99: 30.754,
    p999: 35.598
  },
  {
    month: 77,
    l: -0.5157,
    m: 21.0457,
    s: 0.15175,
    p01: 13.828,
    p1: 15.216,
    p3: 16.126,
    p5: 16.641,
    p10: 17.485,
    p15: 18.092,
    p25: 19.048,
    p50: 21.046,
    p75: 23.379,
    p85: 24.797,
    p90: 25.832,
    p95: 27.492,
    p97: 28.658,
    p99: 31.074,
    p999: 36.001
  },
  {
    month: 78,
    l: -0.5185,
    m: 21.2274,
    s: 0.1523,
    p01: 13.932,
    p1: 15.333,
    p3: 16.252,
    p5: 16.773,
    p10: 17.626,
    p15: 18.239,
    p25: 19.206,
    p50: 21.227,
    p75: 23.591,
    p85: 25.028,
    p90: 26.077,
    p95: 27.761,
    p97: 28.945,
    p99: 31.399,
    p999: 36.41
  },
  {
    month: 79,
    l: -0.5213,
    m: 21.4113,
    s: 0.15284,
    p01: 14.037,
    p1: 15.452,
    p3: 16.38,
    p5: 16.906,
    p10: 17.768,
    p15: 18.388,
    p25: 19.366,
    p50: 21.411,
    p75: 23.805,
    p85: 25.261,
    p90: 26.325,
    p95: 28.033,
    p97: 29.234,
    p99: 31.727,
    p999: 36.823
  },
  {
    month: 80,
    l: -0.524,
    m: 21.5979,
    s: 0.15339,
    p01: 14.143,
    p1: 15.572,
    p3: 16.509,
    p5: 17.041,
    p10: 17.912,
    p15: 18.539,
    p25: 19.528,
    p50: 21.598,
    p75: 24.022,
    p85: 25.498,
    p90: 26.577,
    p95: 28.31,
    p97: 29.529,
    p99: 32.061,
    p999: 37.244
  },
  {
    month: 81,
    l: -0.5268,
    m: 21.7872,
    s: 0.15393,
    p01: 14.251,
    p1: 15.694,
    p3: 16.641,
    p5: 17.178,
    p10: 18.059,
    p15: 18.693,
    p25: 19.693,
    p50: 21.787,
    p75: 24.242,
    p85: 25.738,
    p90: 26.832,
    p95: 28.59,
    p97: 29.828,
    p99: 32.4,
    p999: 37.672
  },
  {
    month: 82,
    l: -0.5294,
    m: 21.9795,
    s: 0.15448,
    p01: 14.361,
    p1: 15.818,
    p3: 16.774,
    p5: 17.317,
    p10: 18.208,
    p15: 18.848,
    p25: 19.86,
    p50: 21.98,
    p75: 24.466,
    p85: 25.982,
    p90: 27.092,
    p95: 28.876,
    p97: 30.132,
    p99: 32.745,
    p999: 38.107
  },
  {
    month: 83,
    l: -0.5321,
    m: 22.1751,
    s: 0.15502,
    p01: 14.473,
    p1: 15.944,
    p3: 16.91,
    p5: 17.459,
    p10: 18.359,
    p15: 19.007,
    p25: 20.03,
    p50: 22.175,
    p75: 24.694,
    p85: 26.231,
    p90: 27.356,
    p95: 29.166,
    p97: 30.441,
    p99: 33.095,
    p999: 38.55
  },
  {
    month: 84,
    l: -0.5347,
    m: 22.374,
    s: 0.15556,
    p01: 14.586,
    p1: 16.072,
    p3: 17.049,
    p5: 17.603,
    p10: 18.513,
    p15: 19.168,
    p25: 20.203,
    p50: 22.374,
    p75: 24.925,
    p85: 26.483,
    p90: 27.625,
    p95: 29.461,
    p97: 30.755,
    p99: 33.451,
    p999: 39
  },
  {
    month: 85,
    l: -0.5372,
    m: 22.5762,
    s: 0.1561,
    p01: 14.702,
    p1: 16.202,
    p3: 17.189,
    p5: 17.75,
    p10: 18.669,
    p15: 19.332,
    p25: 20.378,
    p50: 22.576,
    p75: 25.161,
    p85: 26.74,
    p90: 27.897,
    p95: 29.761,
    p97: 31.075,
    p99: 33.814,
    p999: 39.458
  },
  {
    month: 86,
    l: -0.5398,
    m: 22.7816,
    s: 0.15663,
    p01: 14.819,
    p1: 16.335,
    p3: 17.332,
    p5: 17.899,
    p10: 18.829,
    p15: 19.499,
    p25: 20.557,
    p50: 22.782,
    p75: 25.4,
    p85: 27,
    p90: 28.174,
    p95: 30.065,
    p97: 31.399,
    p99: 34.182,
    p999: 39.923
  },
  {
    month: 87,
    l: -0.5423,
    m: 22.9904,
    s: 0.15717,
    p01: 14.939,
    p1: 16.47,
    p3: 17.477,
    p5: 18.05,
    p10: 18.99,
    p15: 19.668,
    p25: 20.739,
    p50: 22.99,
    p75: 25.643,
    p85: 27.265,
    p90: 28.456,
    p95: 30.375,
    p97: 31.73,
    p99: 34.557,
    p999: 40.398
  },
  {
    month: 88,
    l: -0.5447,
    m: 23.2025,
    s: 0.1577,
    p01: 15.06,
    p1: 16.607,
    p3: 17.625,
    p5: 18.204,
    p10: 19.154,
    p15: 19.84,
    p25: 20.923,
    p50: 23.202,
    p75: 25.889,
    p85: 27.535,
    p90: 28.742,
    p95: 30.689,
    p97: 32.065,
    p99: 34.937,
    p999: 40.879
  },
  {
    month: 89,
    l: -0.5471,
    m: 23.418,
    s: 0.15823,
    p01: 15.183,
    p1: 16.746,
    p3: 17.775,
    p5: 18.36,
    p10: 19.321,
    p15: 20.014,
    p25: 21.111,
    p50: 23.418,
    p75: 26.14,
    p85: 27.808,
    p90: 29.033,
    p95: 31.009,
    p97: 32.406,
    p99: 35.323,
    p999: 41.369
  },
  {
    month: 90,
    l: -0.5495,
    m: 23.6369,
    s: 0.15876,
    p01: 15.309,
    p1: 16.887,
    p3: 17.927,
    p5: 18.519,
    p10: 19.491,
    p15: 20.192,
    p25: 21.301,
    p50: 23.637,
    p75: 26.395,
    p85: 28.086,
    p90: 29.329,
    p95: 31.334,
    p97: 32.752,
    p99: 35.716,
    p999: 41.867
  },
  {
    month: 91,
    l: -0.5518,
    m: 23.8593,
    s: 0.15928,
    p01: 15.436,
    p1: 17.031,
    p3: 18.082,
    p5: 18.68,
    p10: 19.663,
    p15: 20.372,
    p25: 21.495,
    p50: 23.859,
    p75: 26.654,
    p85: 28.368,
    p90: 29.629,
    p95: 31.663,
    p97: 33.103,
    p99: 36.115,
    p999: 42.372
  },
  {
    month: 92,
    l: -0.5541,
    m: 24.0853,
    s: 0.1598,
    p01: 15.566,
    p1: 17.177,
    p3: 18.24,
    p5: 18.844,
    p10: 19.838,
    p15: 20.556,
    p25: 21.691,
    p50: 24.085,
    p75: 26.916,
    p85: 28.655,
    p90: 29.933,
    p95: 31.998,
    p97: 33.46,
    p99: 36.52,
    p999: 42.886
  },
  {
    month: 93,
    l: -0.5563,
    m: 24.3149,
    s: 0.16032,
    p01: 15.698,
    p1: 17.326,
    p3: 18.4,
    p5: 19.011,
    p10: 20.016,
    p15: 20.742,
    p25: 21.891,
    p50: 24.315,
    p75: 27.184,
    p85: 28.946,
    p90: 30.243,
    p95: 32.339,
    p97: 33.823,
    p99: 36.932,
    p999: 43.409
  },
  {
    month: 94,
    l: -0.5585,
    m: 24.5482,
    s: 0.16084,
    p01: 15.832,
    p1: 17.476,
    p3: 18.562,
    p5: 19.18,
    p10: 20.197,
    p15: 20.931,
    p25: 22.094,
    p50: 24.548,
    p75: 27.455,
    p85: 29.242,
    p90: 30.558,
    p95: 32.685,
    p97: 34.192,
    p99: 37.352,
    p999: 43.941
  },
  {
    month: 95,
    l: -0.5606,
    m: 24.7853,
    s: 0.16135,
    p01: 15.968,
    p1: 17.63,
    p3: 18.728,
    p5: 19.353,
    p10: 20.381,
    p15: 21.124,
    p25: 22.301,
    p50: 24.785,
    p75: 27.731,
    p85: 29.543,
    p90: 30.878,
    p95: 33.036,
    p97: 34.567,
    p99: 37.777,
    p999: 44.48
  },
  {
    month: 96,
    l: -0.5627,
    m: 25.0262,
    s: 0.16186,
    p01: 16.106,
    p1: 17.786,
    p3: 18.896,
    p5: 19.528,
    p10: 20.568,
    p15: 21.319,
    p25: 22.51,
    p50: 25.026,
    p75: 28.011,
    p85: 29.849,
    p90: 31.202,
    p95: 33.393,
    p97: 34.947,
    p99: 38.209,
    p999: 45.029
  },
  {
    month: 97,
    l: -0.5647,
    m: 25.271,
    s: 0.16237,
    p01: 16.247,
    p1: 17.944,
    p3: 19.066,
    p5: 19.706,
    p10: 20.758,
    p15: 21.518,
    p25: 22.723,
    p50: 25.271,
    p75: 28.296,
    p85: 30.159,
    p90: 31.533,
    p95: 33.756,
    p97: 35.334,
    p99: 38.648,
    p999: 45.588
  },
  {
    month: 98,
    l: -0.5667,
    m: 25.5197,
    s: 0.16287,
    p01: 16.39,
    p1: 18.105,
    p3: 19.24,
    p5: 19.886,
    p10: 20.951,
    p15: 21.72,
    p25: 22.94,
    p50: 25.52,
    p75: 28.585,
    p85: 30.474,
    p90: 31.868,
    p95: 34.124,
    p97: 35.727,
    p99: 39.094,
    p999: 46.154
  },
  {
    month: 99,
    l: -0.5686,
    m: 25.7721,
    s: 0.16337,
    p01: 16.535,
    p1: 18.269,
    p3: 19.416,
    p5: 20.07,
    p10: 21.147,
    p15: 21.925,
    p25: 23.16,
    p50: 25.772,
    p75: 28.878,
    p85: 30.794,
    p90: 32.208,
    p95: 34.498,
    p97: 36.126,
    p99: 39.547,
    p999: 46.729
  },
  {
    month: 100,
    l: -0.5704,
    m: 26.0284,
    s: 0.16386,
    p01: 16.682,
    p1: 18.435,
    p3: 19.595,
    p5: 20.256,
    p10: 21.346,
    p15: 22.133,
    p25: 23.383,
    p50: 26.028,
    p75: 29.176,
    p85: 31.119,
    p90: 32.553,
    p95: 34.877,
    p97: 36.53,
    p99: 40.006,
    p999: 47.311
  },
  {
    month: 101,
    l: -0.5722,
    m: 26.2883,
    s: 0.16435,
    p01: 16.832,
    p1: 18.604,
    p3: 19.777,
    p5: 20.446,
    p10: 21.547,
    p15: 22.345,
    p25: 23.609,
    p50: 26.288,
    p75: 29.478,
    p85: 31.448,
    p90: 32.903,
    p95: 35.262,
    p97: 36.94,
    p99: 40.471,
    p999: 47.903
  },
  {
    month: 102,
    l: -0.574,
    m: 26.5519,
    s: 0.16483,
    p01: 16.984,
    p1: 18.775,
    p3: 19.961,
    p5: 20.638,
    p10: 21.752,
    p15: 22.559,
    p25: 23.839,
    p50: 26.552,
    p75: 29.784,
    p85: 31.782,
    p90: 33.258,
    p95: 35.652,
    p97: 37.355,
    p99: 40.943,
    p999: 48.502
  },
  {
    month: 103,
    l: -0.5757,
    m: 26.819,
    s: 0.16532,
    p01: 17.137,
    p1: 18.948,
    p3: 20.147,
    p5: 20.832,
    p10: 21.96,
    p15: 22.776,
    p25: 24.072,
    p50: 26.819,
    p75: 30.095,
    p85: 32.121,
    p90: 33.618,
    p95: 36.048,
    p97: 37.778,
    p99: 41.423,
    p999: 49.112
  },
  {
    month: 104,
    l: -0.5773,
    m: 27.0896,
    s: 0.16579,
    p01: 17.293,
    p1: 19.124,
    p3: 20.337,
    p5: 21.029,
    p10: 22.17,
    p15: 22.996,
    p25: 24.308,
    p50: 27.09,
    p75: 30.409,
    p85: 32.463,
    p90: 33.982,
    p95: 36.448,
    p97: 38.204,
    p99: 41.907,
    p999: 49.727
  },
  {
    month: 105,
    l: -0.5789,
    m: 27.3635,
    s: 0.16626,
    p01: 17.451,
    p1: 19.302,
    p3: 20.528,
    p5: 21.228,
    p10: 22.383,
    p15: 23.219,
    p25: 24.546,
    p50: 27.364,
    p75: 30.727,
    p85: 32.81,
    p90: 34.35,
    p95: 36.853,
    p97: 38.636,
    p99: 42.397,
    p999: 50.35
  },
  {
    month: 106,
    l: -0.5804,
    m: 27.6406,
    s: 0.16673,
    p01: 17.611,
    p1: 19.481,
    p3: 20.722,
    p5: 21.43,
    p10: 22.598,
    p15: 23.444,
    p25: 24.788,
    p50: 27.641,
    p75: 31.049,
    p85: 33.161,
    p90: 34.724,
    p95: 37.263,
    p97: 39.073,
    p99: 42.894,
    p999: 50.981
  },
  {
    month: 107,
    l: -0.5819,
    m: 27.9208,
    s: 0.16719,
    p01: 17.772,
    p1: 19.663,
    p3: 20.918,
    p5: 21.634,
    p10: 22.816,
    p15: 23.672,
    p25: 25.032,
    p50: 27.921,
    p75: 31.375,
    p85: 33.516,
    p90: 35.101,
    p95: 37.677,
    p97: 39.514,
    p99: 43.395,
    p999: 51.619
  },
  {
    month: 108,
    l: -0.5833,
    m: 28.204,
    s: 0.16764,
    p01: 17.936,
    p1: 19.847,
    p3: 21.116,
    p5: 21.841,
    p10: 23.036,
    p15: 23.902,
    p25: 25.279,
    p50: 28.204,
    p75: 31.704,
    p85: 33.874,
    p90: 35.481,
    p95: 38.095,
    p97: 39.96,
    p99: 43.901,
    p999: 52.263
  },
  {
    month: 109,
    l: -0.5847,
    m: 28.4901,
    s: 0.16809,
    p01: 18.101,
    p1: 20.033,
    p3: 21.316,
    p5: 22.049,
    p10: 23.258,
    p15: 24.135,
    p25: 25.528,
    p50: 28.49,
    p75: 32.036,
    p85: 34.237,
    p90: 35.866,
    p95: 38.518,
    p97: 40.411,
    p99: 44.413,
    p999: 52.914
  },
  {
    month: 110,
    l: -0.5859,
    m: 28.7791,
    s: 0.16854,
    p01: 18.267,
    p1: 20.221,
    p3: 21.518,
    p5: 22.259,
    p10: 23.483,
    p15: 24.37,
    p25: 25.78,
    p50: 28.779,
    p75: 32.372,
    p85: 34.603,
    p90: 36.255,
    p95: 38.946,
    p97: 40.867,
    p99: 44.931,
    p999: 53.572
  },
  {
    month: 111,
    l: -0.5872,
    m: 29.0711,
    s: 0.16897,
    p01: 18.436,
    p1: 20.411,
    p3: 21.723,
    p5: 22.473,
    p10: 23.71,
    p15: 24.607,
    p25: 26.035,
    p50: 29.071,
    p75: 32.711,
    p85: 34.972,
    p90: 36.648,
    p95: 39.377,
    p97: 41.326,
    p99: 45.452,
    p999: 54.235
  },
  {
    month: 112,
    l: -0.5883,
    m: 29.3663,
    s: 0.16941,
    p01: 18.606,
    p1: 20.602,
    p3: 21.929,
    p5: 22.687,
    p10: 23.939,
    p15: 24.847,
    p25: 26.292,
    p50: 29.366,
    p75: 33.054,
    p85: 35.346,
    p90: 37.045,
    p95: 39.813,
    p97: 41.791,
    p99: 45.98,
    p999: 54.906
  },
  {
    month: 113,
    l: -0.5895,
    m: 29.6646,
    s: 0.16983,
    p01: 18.778,
    p1: 20.797,
    p3: 22.138,
    p5: 22.905,
    p10: 24.172,
    p15: 25.09,
    p25: 26.552,
    p50: 29.665,
    p75: 33.4,
    p85: 35.723,
    p90: 37.445,
    p95: 40.253,
    p97: 42.26,
    p99: 46.513,
    p999: 55.583
  },
  {
    month: 114,
    l: -0.5905,
    m: 29.9663,
    s: 0.17025,
    p01: 18.952,
    p1: 20.993,
    p3: 22.349,
    p5: 23.125,
    p10: 24.406,
    p15: 25.336,
    p25: 26.815,
    p50: 29.966,
    p75: 33.75,
    p85: 36.104,
    p90: 37.851,
    p95: 40.698,
    p97: 42.734,
    p99: 47.051,
    p999: 56.267
  },
  {
    month: 115,
    l: -0.5915,
    m: 30.2715,
    s: 0.17066,
    p01: 19.129,
    p1: 21.191,
    p3: 22.563,
    p5: 23.348,
    p10: 24.644,
    p15: 25.584,
    p25: 27.081,
    p50: 30.272,
    p75: 34.104,
    p85: 36.49,
    p90: 38.26,
    p95: 41.148,
    p97: 43.214,
    p99: 47.595,
    p999: 56.957
  },
  {
    month: 116,
    l: -0.5925,
    m: 30.5805,
    s: 0.17107,
    p01: 19.307,
    p1: 21.392,
    p3: 22.78,
    p5: 23.573,
    p10: 24.884,
    p15: 25.836,
    p25: 27.351,
    p50: 30.58,
    p75: 34.463,
    p85: 36.881,
    p90: 38.675,
    p95: 41.604,
    p97: 43.699,
    p99: 48.146,
    p999: 57.658
  },
  {
    month: 117,
    l: -0.5934,
    m: 30.8934,
    s: 0.17146,
    p01: 19.488,
    p1: 21.597,
    p3: 22.999,
    p5: 23.802,
    p10: 25.128,
    p15: 26.091,
    p25: 27.624,
    p50: 30.893,
    p75: 34.826,
    p85: 37.275,
    p90: 39.095,
    p95: 42.064,
    p97: 44.189,
    p99: 48.701,
    p999: 58.363
  },
  {
    month: 118,
    l: -0.5942,
    m: 31.2105,
    s: 0.17186,
    p01: 19.672,
    p1: 21.803,
    p3: 23.222,
    p5: 24.033,
    p10: 25.375,
    p15: 26.349,
    p25: 27.901,
    p50: 31.21,
    p75: 35.193,
    p85: 37.676,
    p90: 39.52,
    p95: 42.531,
    p97: 44.687,
    p99: 49.266,
    p999: 59.079
  },
  {
    month: 119,
    l: -0.595,
    m: 31.5319,
    s: 0.17224,
    p01: 19.858,
    p1: 22.013,
    p3: 23.447,
    p5: 24.269,
    p10: 25.626,
    p15: 26.611,
    p25: 28.181,
    p50: 31.532,
    p75: 35.566,
    p85: 38.081,
    p90: 39.95,
    p95: 43.003,
    p97: 45.19,
    p99: 49.835,
    p999: 59.801
  },
  {
    month: 120,
    l: -0.5958,
    m: 31.8578,
    s: 0.17262,
    p01: 20.047,
    p1: 22.226,
    p3: 23.676,
    p5: 24.507,
    p10: 25.88,
    p15: 26.877,
    p25: 28.466,
    p50: 31.858,
    p75: 35.943,
    p85: 38.493,
    p90: 40.387,
    p95: 43.482,
    p97: 45.7,
    p99: 50.414,
    p999: 60.535
  }
]

export default weightValues
