import { FormLabel } from '@chakra-ui/react'
import React from 'react'
import { useField } from 'react-final-form'
import { PaymentTypeSelector, PaymentTypeSelectorProps } from '../Selector'
import { Control, Error } from './utils'

// allow all selector props apart from the ones related to the state
type PaymentTypeFieldProps = Omit<PaymentTypeSelectorProps, 'defaultValue' | 'value' | 'onChange'> & {
  name: string
  label?: string
}

export const PaymentTypeField: React.FC<PaymentTypeFieldProps> = (props) => {
  const { name, label, ...selectorProps } = props
  const { input, meta } = useField(name)

  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <PaymentTypeSelector
        isInvalid={meta.error && meta.touched}
        placeholder={label ?? undefined}
        {...input}
        {...selectorProps}
      />
      <Error name={name} />
    </Control>
  )
}
