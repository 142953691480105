import { Nullable } from '../../utils'
import { TranslatableText, FullReference } from './common'
import { DrugGuideline } from './pharmacy'

export enum ChecklistEntryType {
  CITATION = 'citation',
  DIVIDER = 'divider',
  DRUG = 'drug',
  PROCEDURE = 'procedure',
  STUDY = 'study',
  TEXT = 'text',
  DIFFERENTIAL_DIAGNOSIS = 'differential_diagnosis',
}

export interface FullChecklistEntry {
  type: ChecklistEntryType
  name: Nullable<TranslatableText>
  reference: Nullable<FullReference>
  general_tags: Nullable<Array<string>>
  billing_tags: Nullable<Array<string>>
  history_codes: Nullable<Array<string>>
  physical_exam_codes: Nullable<Array<string>>
  quality_codes: Nullable<Array<string>>
  checked: Nullable<boolean>
  key: Nullable<number>
}

export interface QualityChecksActions {
  clinical_status: Nullable<string>
}

export interface QualityChecksColors {
  error: Nullable<string>
  true: Nullable<string>
}

export interface QualityChecksEquationItems {
  type: Nullable<string>
  codes: Nullable<Array<string>>
  items: Nullable<Array<QualityChecksEquationItems>>
}

export interface QualityChecksEquation {
  type: Nullable<string>
  items: Nullable<Array<QualityChecksEquationItems>>
}

export interface QualityCheck {
  name: Nullable<string>
  message: Nullable<string>
  show_alert: Nullable<boolean>
  actions: Nullable<QualityChecksActions>
  colors: Nullable<QualityChecksColors>
  equation: Nullable<QualityChecksEquation>
}

export interface TypedName {
  type: string
  name: TranslatableText
}

export interface QualityEquationValue {
  val: Nullable<boolean>
  missing: Nullable<Array<string>>
}

export interface QualityEquationResult {
  name: string
  value: Nullable<QualityEquationValue>
}

export interface BasicChecklist {
  id: string
  name: TranslatableText
  background: Nullable<TranslatableText>
  general_tags: Nullable<Array<string>>
  category: Nullable<TranslatableText>
  differential_diagnosis: Nullable<Array<TypedName>>
}

export interface FullChecklist {
  id: string
  name: TranslatableText
  background: Nullable<TranslatableText>
  general_tags: Nullable<Array<string>>
  category: Nullable<TranslatableText>
  differential_diagnosis: Nullable<Array<TypedName>>

  history: Nullable<Array<FullChecklistEntry>>
  physical_exam: Nullable<Array<FullChecklistEntry>>
  studies: Nullable<Array<FullChecklistEntry>>
  education: Nullable<Array<FullChecklistEntry>>
  treatment: Nullable<Array<FullChecklistEntry | DrugGuideline>>

  note: Nullable<string>
  authors: Nullable<string>
  quality_checks: [QualityCheck]
  quality_equation_results: [QualityEquationResult]
}
