import { gql } from '@apollo/client'
import { ActiveVisit, BasicPatient } from '../fragments'

export const GET_VISITS = gql`
  query ($q: String, $offset: Int, $limit: Int, $start: String, $end: String, $patientId: String, $location: String) {
    visits(
      q: $q
      offset: $offset
      limit: $limit
      start: $start
      end: $end
      patientId: $patientId
      location: $location
    ) {
      docs {
        ...ActiveVisit
        patient {
          ...BasicPatient
        }
      }
      count
    }
  }
  ${ActiveVisit}
  ${BasicPatient}
`

export const GET_VISIT_BY_ID = gql`
  query ($id: ID!) {
    visit(id: $id) {
      ...ActiveVisit
      patient {
        ...BasicPatient
      }
    }
  }
  ${ActiveVisit}
  ${BasicPatient}
`
