import { gql } from '@apollo/client'
import { FullSurvey } from '../fragments'

export const GET_SURVEY = gql`
  ${FullSurvey}
  query survey($id: ID!) {
    survey(id: $id) {
      ...FullSurvey
    }
  }
`

export const GET_SURVEYS = gql`
  ${FullSurvey}
  query surveys($q: String, $offset: Int, $limit: Int) {
    surveys(q: $q, offset: $offset, limit: $limit) {
      docs {
        ...FullSurvey
      }
      count
    }
  }
`

export const CREATE_SURVEY = gql`
  ${FullSurvey}
  mutation createSurvey($newSurvey: NewSurvey!) {
    createSurvey(newSurvey: $newSurvey) {
      ...FullSurvey
    }
  }
`

export const DELETE_SURVEY = gql`
  mutation deleteSurvey($id: ID!) {
    deleteSurvey(id: $id) {
      done
    }
  }
`

export const UPDATE_SURVEY_BODY = gql`
  ${FullSurvey}
  mutation updateSurveyBody($id: ID!, $body: NewSurveyBody!) {
    updateSurveyBody(id: $id, body: $body) {
      ...FullSurvey
    }
  }
`
