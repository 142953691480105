import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import type { DrugGuideline as DrugGuidelineInterface } from '../../api/interfaces/pharmacy'
import { DynamicTranslation } from '../i18n'
import { Box, BoxProps, Text, TextProps } from '@chakra-ui/react'
import { DrugRoute } from './DrugRoute'

const messages = defineMessages({
  max: {
    id: 'DrugGuideline.max',
    defaultMessage: 'Max'
  }
})

type DrugGuidelineProps = BoxProps & {
  guideline: DrugGuidelineInterface
}

export const DrugGuideline: React.FC<DrugGuidelineProps> = (props) => {
  const { guideline, ...boxProps } = props

  const {
    max_dose,
    max_dose_units,
    dose_range_min,
    dose_range_max,
    dose_units
  } = guideline



  return <Box {...boxProps}>
    <DynamicTranslation message={guideline.name} fontWeight={'bold'} as={'span'} />

    {
      dose_range_min && dose_range_max &&
      <Range
        doseRangeMin={dose_range_min}
        doseRangeMax={dose_range_max}
        doseUnits={dose_units ?? ''}
        ml={1}
      />
    }

    {max_dose !== null &&
      <MaxDose
        maxDose={max_dose}
        maxDoseUnits={max_dose_units ?? ''}
        as={'span'}
        ml={1}
      />
    }
    {
      guideline.route &&
      <DrugRoute as={'span'} route={guideline.route} mx={1} />
    }
  </Box>
}

interface RangeProps extends BoxProps {
  doseRangeMin: number
  doseRangeMax: number
  doseUnits: string
}

const Range: React.FC<RangeProps> = props => {
  const { doseRangeMin, doseRangeMax, doseUnits, ...boxProps } = props

  const range = (Math.abs(doseRangeMin - doseRangeMax) < 0.00001)
    ? doseRangeMin
    : [doseRangeMin, doseRangeMax].join('-')

  return <Box as='span' {...boxProps}>
    <Text as='span'>
      {range}
    </Text>
    {doseUnits &&
      <Text as={'span'} >{doseUnits}</Text>
    }
  </Box>
}

interface MaxDoseProps extends TextProps {
  maxDose: number
  maxDoseUnits: string
}

const MaxDose: React.FC<MaxDoseProps> = props => {
  const { maxDose, maxDoseUnits = '', ...textProps } = props

  const intl = useIntl()
  const text = `${maxDose}${maxDoseUnits}`
  return <Text {...textProps}>
    {text} {intl.formatMessage(messages.max)}
  </Text>
}


