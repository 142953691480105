import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { User } from '../../api/interfaces'
import { ADD_ADMIN_PERMISSIONS, REMOVE_ADMIN_PERMISSIONS } from '../../api/requests/staff'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  genericErrorMessage: {
    id: 'UseOrderActions.generic_error_message',
    defaultMessage: 'Something went wrong, try again later.',
  },
})

type UpdateAdminPermissionsVariables = {
  username: string
}

type AddAdminPermissionsData = {
  addAdminPermissions: User
}

export type UseAddAdminPermissions = MutationHookOptions<
  AddAdminPermissionsData,
  UpdateAdminPermissionsVariables
>

export const useAddAdminPermissions = (params?: UseAddAdminPermissions) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.genericErrorMessage))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)

    translateError(firstError)
  }

  const onErrorClose = () => {
    setError(null)
  }

  const [addAdminPermissions, { loading: isLoading }] = useMutation<
    AddAdminPermissionsData,
    UpdateAdminPermissionsVariables
  >(ADD_ADMIN_PERMISSIONS, {
    onError,
    onCompleted: onErrorClose,
    ...params,
  })

  return {
    addAdminPermissions,
    isLoading,
    error,
    onErrorClose,
  }
}

type RemoveAdminPermissions = {
  RemoveAdminPermissionsData: User
}

export type UseRemoveAdminPermissions = MutationHookOptions<
  RemoveAdminPermissions,
  UpdateAdminPermissionsVariables
>

export const useRemoveAdminPermissions = (params?: UseRemoveAdminPermissions) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.genericErrorMessage))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)

    translateError(firstError)
  }

  const onErrorClose = () => {
    setError(null)
  }

  const [removeAdminPermissions, { loading: isLoading }] = useMutation<
    RemoveAdminPermissions,
    UpdateAdminPermissionsVariables
  >(REMOVE_ADMIN_PERMISSIONS, {
    onError,
    onCompleted: onErrorClose,
    ...params,
  })

  return {
    removeAdminPermissions,
    isLoading,
    error,
    onErrorClose,
  }
}
