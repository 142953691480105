import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  useBreakpointValue,
  ButtonProps,
  useDisclosure,
  Stack,
  Icon,
  FormLabel,
} from '@chakra-ui/react'
import { ErrorViewer } from '..'
import { TextField, TextListField } from '../Form'
import {
  CustomField,
  CustomFieldType,
  NonPrimitiveCustomFieldType,
  PrimitiveCustomField,
  PrimitiveCustomFieldType,
  TextListCustomField,
} from '../../api/interfaces'
import { useAddPrimitiveCustomField, useAddTextListCustomField, useUpdatePrimitiveCustomField, useUpdateTextListCustomField } from './useCustomFieldActions'
import { CustomFieldTypeSelector } from '../Selector'
import { isPrimitiveCustomField, isTextListCustomField, isValidPrimitiveCustomFieldType } from './utils'
import { CheckboxField } from '../Form/CheckboxField'
import {
  RiPencilLine as EditIcon,
} from 'react-icons/ri'
import arrayMutators from 'final-form-arrays'

const messages = defineMessages({
  title: {
    id: 'CustomFieldModal.create_custom_field',
    defaultMessage: 'Create custom field',
  },
  nameLabel: {
    id: 'CustomFieldsTab.name',
    defaultMessage: 'Name',
  },
  requiredLabel: {
    id: 'CustomFieldsTab.required',
    defaultMessage: 'Required',
  },
  typeLabel: {
    id: 'CustomFieldsTab.type',
    defaultMessage: 'Type',
  },
  options: {
    id: 'CustomFieldsTab.options',
    defaultMessage: 'Options'
  },
  saveButton: {
    id: 'UI.save_button',
    defaultMessage: 'Save',
  },
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close',
  },
  addButton: {
    id: 'UI.button_add',
    defaultMessage: 'Add',
  },
  editButton: {
    id: 'UI.button_edit',
    defaultMessage: 'Edit',
  }
})

type CreateCustomFieldProps = ButtonProps & {
  onComplete?: () => void
}

export const CreateCustomFieldButton: React.FC<CreateCustomFieldProps> = (props) => {
  const { onComplete, ...buttonProps } = props

  const intl = useIntl()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [type, setType] = React.useState<CustomFieldType>(PrimitiveCustomFieldType.TEXT)

  const onChange = (newType: CustomFieldType) => {
    setType(newType)
  }

  const onCreate = () => {
    if (typeof onComplete === 'function') {
      onComplete()
    }

    onClose()
  }
  return (
    <>
      <Button colorScheme={'brand'} onClick={onOpen} {...buttonProps}>
        {intl.formatMessage(messages.addButton)}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb={0}>{intl.formatMessage(messages.title)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormLabel >{intl.formatMessage(messages.typeLabel)}</FormLabel>
            <CustomFieldTypeSelector value={type} onChange={onChange} />

            {isValidPrimitiveCustomFieldType(type) && (
              <CreatePrimitiveCustomFieldForm
                type={type as PrimitiveCustomFieldType}
                onClose={onClose}
                onComplete={onCreate}
              />
            )}

            {
              type === NonPrimitiveCustomFieldType.TEXT_LIST &&
              <CreateTextListForm onClose={onClose} onComplete={onCreate} />
            }
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

interface CreateCustomFieldForm {
  onClose: () => void
  onComplete: () => void
}

interface CreatePrimitiveCustomFieldForm extends CreateCustomFieldForm {
  type: PrimitiveCustomFieldType
}

const CreatePrimitiveCustomFieldForm: React.FC<CreatePrimitiveCustomFieldForm> = (props) => {
  const { onClose, onComplete, type } = props
  const intl = useIntl()

  const params = {
    type,
    onCompleted: () => {
      onComplete()
      onClose()
    },
  }

  const { onSubmit, isLoading, error, onErrorClose, initialValues, validate } =
    useAddPrimitiveCustomField(params)

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TextField name="name" label={intl.formatMessage(messages.nameLabel)} />
            <CheckboxField name="required" label={intl.formatMessage(messages.requiredLabel)} />

            {error && <ErrorViewer title={error} onClose={onErrorClose} />}

            <Stack isInline={!isFullWidth} justifyContent={'end'}>
              <Button onClick={onClose} isFullWidth={isFullWidth}>
                {intl.formatMessage(messages.closeButton)}
              </Button>
              <Button
                colorScheme={'brand'}
                isLoading={isLoading}
                type="submit"
                isFullWidth={isFullWidth}
                isDisabled={pristine}
              >
                {intl.formatMessage(messages.saveButton)}
              </Button>
            </Stack>
          </form>
        )
      }}
    />
  )
}


const CreateTextListForm: React.FC<CreateCustomFieldForm> = (props) => {
  const { onClose, onComplete } = props
  const intl = useIntl()

  const params = {
    onCompleted: () => {
      onComplete()
      onClose()
    },
  }

  const {
    onSubmit,
    isLoading,
    error,
    onErrorClose,
    initialValues,
    validate
  } = useAddTextListCustomField(params)

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators
      }}
      keepDirtyOnReinitialize
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TextField name="name" label={intl.formatMessage(messages.nameLabel)} />
            <TextListField name='options' label={intl.formatMessage(messages.options)} />
            <CheckboxField name="required" label={intl.formatMessage(messages.requiredLabel)} />

            {error && <ErrorViewer title={error} onClose={onErrorClose} />}

            <Stack isInline={!isFullWidth} justifyContent={'end'}>
              <Button onClick={onClose} isFullWidth={isFullWidth}>
                {intl.formatMessage(messages.closeButton)}
              </Button>
              <Button
                colorScheme={'brand'}
                isLoading={isLoading}
                type="submit"
                isFullWidth={isFullWidth}
                isDisabled={pristine}
              >
                {intl.formatMessage(messages.saveButton)}
              </Button>
            </Stack>
          </form>
        )
      }}
    />
  )
}


type UpdateCustomFieldProps = ButtonProps & {
  onComplete?: () => void
  customField: CustomField
}

export const UpdateCustomFieldButton: React.FC<UpdateCustomFieldProps> = (props) => {
  const { onComplete, customField, ...buttonProps } = props

  const intl = useIntl()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const onUpdate = () => {
    if (typeof onComplete === 'function') {
      onComplete()
    }
    onClose()
  }
  return (
    <>
      <Button onClick={onOpen} {...buttonProps}>
        <Icon as={EditIcon} mr={2} />
        {intl.formatMessage(messages.editButton)}
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader pb={0}>{intl.formatMessage(messages.title)}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {isPrimitiveCustomField(customField) && (
                <UpdatePrimitiveCustomFieldForm
                  onClose={onClose}
                  onComplete={onUpdate}
                  customField={customField}
                />
              )}

              {
                isTextListCustomField(customField) &&
                <UpdateTextListForm onClose={onClose}
                  onComplete={onUpdate}
                  customField={customField} />
              }
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

interface UpdatePrimitiveCustomFieldForm {
  onClose: () => void
  onComplete: () => void
  customField: PrimitiveCustomField
}

const UpdatePrimitiveCustomFieldForm: React.FC<UpdatePrimitiveCustomFieldForm> = (props) => {
  const { customField, onClose } = props
  const intl = useIntl()

  const params = {
    onCompleted: onClose,
    id: customField.id,
  }

  const { onSubmit, isLoading, error, onErrorClose, validate } = useUpdatePrimitiveCustomField(params)

  const initialValues = {
    name: customField.name,
    required: customField.required,
    type: customField.type,
  }

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TextField autoFocus name="name" label={intl.formatMessage(messages.nameLabel)} />
            <CheckboxField name="required" label={intl.formatMessage(messages.requiredLabel)} />

            {error && <ErrorViewer title={error} onClose={onErrorClose} />}

            <Stack isInline={!isFullWidth} justifyContent={'end'}>
              <Button onClick={onClose} isFullWidth={isFullWidth}>
                {intl.formatMessage(messages.closeButton)}
              </Button>
              <Button
                colorScheme={'brand'}
                isLoading={isLoading}
                type="submit"
                isFullWidth={isFullWidth}
                isDisabled={pristine}
              >
                {intl.formatMessage(messages.saveButton)}
              </Button>
            </Stack>
          </form>
        )
      }}
    />
  )
}


interface UpdateTextListForm {
  onClose: () => void
  onComplete: () => void
  customField: TextListCustomField
}

const UpdateTextListForm: React.FC<UpdateTextListForm> = (props) => {
  const { customField, onClose } = props
  const intl = useIntl()

  const params = {
    onCompleted: onClose,
    id: customField.id,
  }

  const { onSubmit, isLoading, error, onErrorClose, validate } = useUpdateTextListCustomField(params)

  const initialValues = {
    name: customField.name,
    required: customField.required,
    options: customField.options,
  }

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators
      }}
      keepDirtyOnReinitialize
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TextField autoFocus name="name" label={intl.formatMessage(messages.nameLabel)} />
            <TextListField name='options' label={intl.formatMessage(messages.options)} />
            <CheckboxField name="required" label={intl.formatMessage(messages.requiredLabel)} />

            {error && <ErrorViewer title={error} onClose={onErrorClose} />}

            <Stack isInline={!isFullWidth} justifyContent={'end'}>
              <Button onClick={onClose} isFullWidth={isFullWidth}>
                {intl.formatMessage(messages.closeButton)}
              </Button>
              <Button
                colorScheme={'brand'}
                isLoading={isLoading}
                type="submit"
                isFullWidth={isFullWidth}
                isDisabled={pristine}
              >
                {intl.formatMessage(messages.saveButton)}
              </Button>
            </Stack>
          </form>
        )
      }}
    />
  )
}