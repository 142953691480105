import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import QRCode from 'react-qr-code'
import { generateTOTPAuthURI } from 'wd-common/src/logic/staff/utils'
import { useSession } from '../Auth'
import {
  AlertTitle,
  AlertDescription,
  Alert,
  Box,
  Button,
  Center,
  HStack,
  Link,
  Text,
  VStack,
  useClipboard,
} from '@chakra-ui/react'

const messages = defineMessages({
  copyButton: {
    id: 'NonStaffUI.button_copy',
    defaultMessage: 'Copy'
  },
  openAppButton: {
    id: 'NonStaffUI.button_open_app',
    defaultMessage: 'Open App'
  },
  scan: {
    id: 'MFAConfiguration.scan_code',
    defaultMessage: 'Scan the following QR code from your virtual MFA application.'
  },
  noScanWarning: {
    id: 'MFAConfiguration.no_scan_warning',
    defaultMessage: 'In case your application does not support QR scanning, you can type the secret configuration key below.'
  },
  secretConfigurationKey: {
    id: 'MFAConfiguration.secret_configuration_key',
    defaultMessage: 'Secret Configuration Key'
  },
})

interface SecretProps {
  secret: string
}

export const MFASecret: React.FC<SecretProps> = props => {
  const { secret } = props
  const intl = useIntl()

  const { getUsername } = useSession()
  const username = getUsername()

  const { onCopy } = useClipboard(secret)
  const authenticatorURI = generateTOTPAuthURI(secret, username)

  const copyToClipboard = () => {
    onCopy()
  }

  return <Box >
    {
      authenticatorURI &&
      <Box >
        <Text>
          {intl.formatMessage(messages.scan)}
        </Text>
        <Center p={2}>
          <QRCode value={authenticatorURI} size={200} />
        </Center>
      </Box>
    }

    {
      secret &&

      <Alert status={'info'} borderRadius={'lg'} my={3}>
        <Box flex='1'>
          <AlertTitle>
            {intl.formatMessage(messages.secretConfigurationKey)}
          </AlertTitle>
          <VStack>
            <AlertDescription display='block'>
              {intl.formatMessage(messages.noScanWarning)}
            </AlertDescription>
            <HStack>

              <Button as={Link} href={authenticatorURI} isExternal size={'sm'}>
                {intl.formatMessage(messages.openAppButton)}
              </Button>
              <Button onClick={copyToClipboard} size={'sm'}>
                {intl.formatMessage(messages.copyButton)}
              </Button>
            </HStack>
          </VStack>
        </Box>
      </Alert>
    }
  </Box >
}