import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  ChecklistReference,
  ChecklistTextEntry,
  ChecklistDivider,
  ChecklistStudy,
} from '../'

import { ChecklistEntryType, FullChecklistEntry } from '../../../api/interfaces'
import {
  Box,
  BoxProps,
  Heading,
  HStack,
  List,
  ListItem
} from '@chakra-ui/react'
// import { FullStudyOrder } from '../../../api/interfaces/studies'

const messages = defineMessages({
  titlePhysicalExam: {
    id: 'Checklist.title_physical_exam',
    defaultMessage: 'Physical Exam'
  },
})

interface OnItemChangeParams {
  key: number
  checked: boolean
}




/**
 * Physical exam
 */
type PhysicalExamProps = Omit<BoxProps, 'onChange'> & {
  items: Array<FullChecklistEntry>
  onChange: (params: OnItemChangeParams) => void
  // patientId: string
  // visitId: string
  // studyOrders: Array<FullStudyOrder>
  isDisabled?: boolean
}

export const PhysicalExam: React.FC<PhysicalExamProps> = props => {
  const { items, isDisabled, onChange, ...boxProps } = props

  const intl = useIntl()
  const citations = items.filter(({ type }) => type === ChecklistEntryType.CITATION)

  const onCheckChange = index => checked => {
    const key = items[index].key
    if (key !== null) {
      onChange({ key, checked })
    }
  }

  return <Box {...boxProps}>
    <HStack alignItems={'center'} >
      <Heading size={'md'} >
        {intl.formatMessage(messages.titlePhysicalExam)}
      </Heading>
      <ChecklistReference items={citations} />
    </HStack>
    <List ml={4} my={2}>
      {
        items.map((item, index) => {
          if (item.type === ChecklistEntryType.DIVIDER && item.name) {
            return (
              <ListItem key={`${ChecklistEntryType.DIVIDER}-${index}`}>
                <ChecklistDivider
                  isDisabled={isDisabled}
                  name={item.name}
                  reference={item.reference} />
              </ListItem>
            )
          }

          if (item.type === ChecklistEntryType.TEXT) {
            return (
              <ListItem key={`${ChecklistEntryType.TEXT}-${index}`}>
                <ChecklistTextEntry entry={item} isDisabled={isDisabled} onCheckChange={onCheckChange(index)} />
              </ListItem>
            )
          }

          if (item.type === ChecklistEntryType.STUDY) {
            return (
              <ListItem key={`${ChecklistEntryType.STUDY}-${index}`}>
                <ChecklistStudy
                  entry={item}
                  isDisabled={isDisabled}
                  onCheckChange={onCheckChange(index)}
                />
              </ListItem>
            )
          }

          return null
        })
      }
    </List>
  </Box>
}
