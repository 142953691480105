import React from 'react'
import { Button, Icon, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { defineMessages, useIntl } from 'react-intl'
import {
  RiMoonFill as DarkIcon,
  RiSunLine as LightIcon
} from 'react-icons/ri'

const messages = defineMessages({
  darkTheme: {
    id: 'Theme.label_dark_theme',
    defaultMessage: 'Dark theme'
  },
  ligthTheme: {
    id: 'Theme.label_light_theme',
    defaultMessage: 'Light theme'
  }
})
export const ToggleThemeButton: React.FC = () => {
  const intl = useIntl()


  const { label, CustomIcon } = useColorModeValue({
    label: intl.formatMessage(messages.ligthTheme),
    CustomIcon: LightIcon
  }, {
    label: intl.formatMessage(messages.darkTheme),
    CustomIcon: DarkIcon
  })

  const { toggleColorMode } = useColorMode()

  return <Button
    aria-label={label}
    onClick={toggleColorMode}
  >
    <Icon as={CustomIcon} mr={2} />
    {label}
  </Button>
}
