import React from 'react'
import Select from 'react-select'
import { useChakraThemeParser } from '.'
import { BasePatient } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { getPatientName, usePatients } from '../Patients'
import { ReactSelectInternalValue, ReactSelectInternalSingleValue, SelectorProps, isMultiSelector } from './utils'

export type PatientSelectorProps = SelectorProps<string>

const formatOption = (patient: BasePatient): ReactSelectInternalSingleValue => {
  return {
    label: getPatientName(patient),
    value: patient.id,
  }
}

export const PatientSelector: React.FC<PatientSelectorProps> = (props) => {
  const { value } = props

  const theme = useChakraThemeParser()

  const { patients, isLoading, getPatient } = usePatients({
    fetchPolicy: 'cache-first',
  })

  const options = React.useMemo(() => {
    if (Array.isArray(patients)) {
      return patients.map((value) => formatOption(value as BasePatient))
    }

    return []
  }, [patients])

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (isMultiSelector(props)) {
      if (!Array.isArray(value)) {
        return null
      }

      const formattedValues = value.reduce((prev: Array<ReactSelectInternalSingleValue>, patientId: string) => {
        const patient = getPatient(patientId)

        if (patient) {
          return [...prev, formatOption(patient)]
        }

        return prev
      }, [])

      return formattedValues
    } else {
      const patient = getPatient(value as string)

      if (patient) {
        return formatOption(patient)
      }

      return null
    }
  }

  const _onChange = (internalValue): void => {
    if (isMultiSelector(props)) {
      if (!Array.isArray(internalValue)) {
        return props.onChange([])
      }

      const selectedValues = internalValue
      const newValues = selectedValues.map((item) => item.value)

      props.onChange(newValues)
    } else {
      if (!internalValue) {
        return props.onChange('')
      }

      const selectedValue = internalValue

      props.onChange(selectedValue.value)
    }
  }

  return (
    <Select
      options={options}
      value={parseValue(value)}
      onChange={_onChange}
      isLoading={isLoading}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      aria-invalid={props.isInvalid}
      placeholder={props.placeholder}
      blurInputOnSelect={props.blurInputOnSelect}
      theme={theme}
    />
  )
}
