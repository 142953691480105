import moment from 'moment-timezone'
import { NORMAL_VITALS } from './definitions'
import { isAValidSex } from '../patient/validations'
import { SEX } from '../patient/definitions'

import {
  boysHCValues,
  girlsHCValues,
  boysLengthValues as boysHeightValues,
  girlsLengthValues as girlsHeightValues,
  boysWeightValues,
  girlsWeightValues,
  boysBMIValues,
  girlsBMIValues
} from '../childGrowth'

/**
 *
 * @param {Patient} patient
 * @returns Vitals OBJ based in the patient age or null in case the patient.birthdate is undefined
 */
export const getNormalVitalsByAge = (patient) => {
  if (!(patient || {}).birthdate) return null

  return Object.values(NORMAL_VITALS).find(vital => {
    const { age } = vital

    const { max, min } = age
    const endDate = moment().startOf('day').subtract(min, 'days')
    const startDate = moment().startOf('day').subtract(max, 'days')

    // For each age range we're using the formula min <= age < max or: [min, max)
    // In Example: Preeschooler (3-5 y) starts at 3 years and ends at 6 years,
    // a child with age of 6 will be classified as School-aged child (6-9 y).
    return moment(patient.birthdate).isBetween(startDate, endDate, undefined, '(]')
  })
}

/**
 *
 * @param {number} min min value
 * @param {number} max max value
 * @param {number} value value to be compared
 * @returns a boolean that indicates if the value is between the range
 * Edges: [)
 */
export const isInRange = (min, max, value) => {
  if (typeof min === 'number' && typeof max === 'number' && typeof value === 'number') {
    return min <= value && value <= max
  }

  return false
}

export const getHeadCircumferenceStandards = patient => {
  if (typeof patient !== 'object') return null

  const birthdate = moment(patient.birthdate)
  if (!birthdate.isValid() || !isAValidSex(patient.sex)) return null

  const ageInWeeks = moment().diff(birthdate, 'weeks')
  const ageInMonths = moment().diff(birthdate, 'months')
  const match = val => ageInWeeks <= 13 ? val.week === ageInWeeks : val.month === ageInMonths

  return patient.sex === SEX.FEMALE
    ? girlsHCValues.find(match)
    : boysHCValues.find(match)
}

export const getHeightStandards = patient => {
  if (typeof patient !== 'object') return null

  const birthdate = moment(patient.birthdate)
  if (!birthdate.isValid() || !isAValidSex(patient.sex)) return null

  const ageInWeeks = moment().diff(birthdate, 'weeks')
  const ageInMonths = moment().diff(birthdate, 'months')
  const match = val => ageInWeeks <= 13 ? val.week === ageInWeeks : val.month === ageInMonths

  return patient.sex === SEX.FEMALE
    ? girlsHeightValues.find(match)
    : boysHeightValues.find(match)
}

export const getWeightStandards = patient => {
  if (typeof patient !== 'object') return null

  const birthdate = moment(patient.birthdate)
  if (!birthdate.isValid() || !isAValidSex(patient.sex)) return null

  const ageInWeeks = moment().diff(birthdate, 'weeks')
  const ageInMonths = moment().diff(birthdate, 'months')
  const match = val => ageInWeeks <= 13 ? val.week === ageInWeeks : val.month === ageInMonths

  return patient.sex === SEX.FEMALE
    ? girlsWeightValues.find(match)
    : boysWeightValues.find(match)
}

export const getBMIStandards = patient => {
  if (typeof patient !== 'object') return null

  const birthdate = moment(patient.birthdate)
  if (!birthdate.isValid() || !isAValidSex(patient.sex)) return null

  const ageInWeeks = moment().diff(birthdate, 'weeks')
  const ageInMonths = moment().diff(birthdate, 'months')
  const match = val => ageInWeeks <= 13 ? val.week === ageInWeeks : val.month === ageInMonths

  return patient.sex === SEX.FEMALE
    ? girlsBMIValues.find(match)
    : boysBMIValues.find(match)
}

export const calculatePercentileAndZScore = (value, standards) => {
  if (!value || !standards) return null

  const { l, m, s } = standards
  const y = value

  // Computation of z-Score using LMS method.
  // More info at: https://www.who.int/growthref/computation.pdf?ua=1
  const above = Math.pow((y / m), l) - 1
  const below = l * s

  const z = above / below

  // Calculation of the Percentile (using z-Score)
  const p = 100 * (1 / (1 + Math.exp(-0.07056 * Math.pow(z, 3) - (1.5976 * z))))
  const percentile = Math.round(Math.round(p * 10) / 10)

  return {
    percentile,
    zScore: Number(z).toFixed(3)
  }
}

/**
 *
 * @param {Patient} patient
 * @returns The age category of the normal vital which matches with the patient age
 * or null if the patient.birthdate is undefined
 */
export const getAgeCategory = patient => {
  if (!(patient || {}).birthdate) return null

  const found = Object.entries(NORMAL_VITALS).find(([key, vital]) => {
    const { age } = vital

    const { max, min } = age
    const endDate = moment().startOf('day').subtract(min, 'days')
    const startDate = moment().startOf('day').subtract(max, 'days')

    return moment(patient.birthdate).isBetween(startDate, endDate, undefined, '(]')
  })

  return Array.isArray(found) ? found[0] : null
}
