import React from 'react'
import FullCalendar, { DateSelectArg, EventChangeArg, EventClickArg, EventInput } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import luxonPlugin from '@fullcalendar/luxon'
import { CalendarViewType } from '../utils'
import { Nullable } from '../../../utils'
import { Box, BoxProps, useColorMode, useTheme } from '@chakra-ui/react'
import { useCalendarContext } from '../'
import { ToolBar } from './Toolbar'
import { EventColor } from './utils'
import { EventFilter } from './EventFilter'
import './styles.css'

interface CalendarProps {
  isDisabled?: boolean
}

/**
 * THIS NEED TO BE INSIDE OF THE CALENDAR CONTEXT PROVIDER
 */
export const CustomCalendar: React.FC<CalendarProps> = (props) => {
  const { isDisabled } = props

  const ref = React.useRef<Nullable<FullCalendar>>(null)

  const { roomId, slotDuration, locale, onEventSelect, onPeriodChange, onCurrentDateChange } = useCalendarContext()
  const styledEvents = useEvents()

  const onSelect = (info: DateSelectArg) => {
    onPeriodChange(info.start, info.end)
  }

  const onEventClick = (arg: EventClickArg) => {
    onEventSelect(arg.event.id)
  }

  const onEventChange = (arg: EventChangeArg) => {
    onEventSelect(arg.event.id)
    if (arg.event.start && arg.event.end) {
      onPeriodChange(arg.event.start, arg.event.end)
    }
  }

  const onNavLinkDayClick = (date: Date, event: FullCalendarVDom.VUIEvent) => {
    event.stopPropagation()

    const api = ref.current?.getApi()
    if (!api) {
      return
    }

    api.gotoDate(date)
    api.changeView(CalendarViewType.DAY)
  }

  const boxProps: BoxProps =
    isDisabled || !roomId
      ? {
          style: {
            pointerEvents: 'none',
            cursor: '',
            opacity: '.5',
          },
          tabIndex: -1,
        }
      : {}

  return (
    <Box {...boxProps}>
      {ref?.current && <ToolBar api={ref.current?.getApi()} onCurrentDateChange={onCurrentDateChange} />}
      <EventFilter />
      <FullCalendar
        ref={ref}
        locale={locale}
        slotDuration={slotDuration}
        height="auto"
        initialView={CalendarViewType.WEEK}
        select={onSelect}
        eventClick={onEventClick}
        eventChange={onEventChange}
        editable={!isDisabled}
        selectable={true}
        selectOverlap={true}
        selectMirror={true}
        navLinks={true}
        headerToolbar={{
          start: 'title',
          end: '',
        }}
        plugins={[luxonPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        events={styledEvents}
        navLinkDayClick={onNavLinkDayClick}
      />
    </Box>
  )
}

const useEvents = (): Array<EventInput> => {
  const { events } = useCalendarContext()
  const { colorMode } = useColorMode()
  const { colors } = useTheme()

  const telehealthScheduledVisitStyles =
    colorMode === 'dark'
      ? {
          backgroundColor: colors[EventColor.telehealth]['500'],
          borderColor: colors[EventColor.telehealth]['500'],
        }
      : {
          backgroundColor: colors[EventColor.telehealth]['400'],
          borderColor: colors[EventColor.telehealth]['400'],
        }

  const inPersonScheduledVisitStyles =
    colorMode === 'dark'
      ? {
          backgroundColor: colors[EventColor.inPerson]['500'],
          borderColor: colors[EventColor.inPerson]['500'],
        }
      : {
          backgroundColor: colors[EventColor.inPerson]['400'],
          borderColor: colors[EventColor.inPerson]['400'],
        }

  const personalAppointmentStyles =
    colorMode === 'dark'
      ? {
          backgroundColor: colors[EventColor.personalAppointment]['500'],
          borderColor: colors[EventColor.personalAppointment]['500'],
        }
      : {
          backgroundColor: colors[EventColor.personalAppointment]['400'],
          borderColor: colors[EventColor.personalAppointment]['400'],
        }

  const styledEvents = events.map((event) => {
    if (event.extendedProps?.isScheduledVisit) {
      if (event?.extendedProps?.scheduledVisit?.registrationData?.telehealth) {
        return {
          ...event,
          ...telehealthScheduledVisitStyles,
        }
      }

      return {
        ...event,
        ...inPersonScheduledVisitStyles,
      }
    }

    return {
      ...event,
      ...personalAppointmentStyles,
    }
  })

  return styledEvents
}
