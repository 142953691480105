export enum EventType {
  IN_PERSON = 'inPerson',
  TELEHEALTH = 'telehealth',
  PERSONAL_APPOINTMENT = 'personalAppointment',
}

/**
 * This is horrible but I didn't find a better way
 * Choose colors from here
 * https://chakra-ui.com/docs/styled-system/theme#colors
 */
export const EventColor: Record<EventType, string> = {
  [EventType.IN_PERSON]: 'purple',
  [EventType.TELEHEALTH]: 'blue',
  [EventType.PERSONAL_APPOINTMENT]: 'gray',
}
