import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
  BoxProps
} from '@chakra-ui/react'
import { NestedButtonProps, ConfirmButton, ErrorViewer } from '..'
import { MFATokenAssociation, useMFAActions } from '.'
import { Nullable } from '../../utils'
import { useMFASettings } from './mfaSettings'

const messages = defineMessages({
  multiFactorLabel: {
    id: 'MFAConfiguration.mfa_label',
    defaultMessage: 'Multi-factor Authentication'
  },
  virtualMFAAlreadySet: {
    id: 'MFAConfiguration.virtual_mfa_already_set',
    defaultMessage: 'Virtual MFA is already set'
  },
  deviceAssociatedDescription: {
    id: 'MFAConfiguration.device_associated_description',
    defaultMessage: 'Your device was successfully associated with your account. Your session will expire immediately after enabling the MFA.'
  },
  enableButton: {
    id: 'MFAConfiguration.button_enable_mfa',
    defaultMessage: 'Enable MFA'
  },
  disableButton: {
    id: 'MFAConfiguration.button_disable_mfa',
    defaultMessage: 'Disable MFA'
  },
  cancelButton: {
    id: 'NonStaffUI.button_cancel',
    defaultMessage: 'Cancel',
  },
  disablingWarning: {
    id: 'MFAConfiguration.disable_mfa_warning',
    defaultMessage: 'You are disabling the multi-factor authentication for this account. Do you want to continue? You will be logged out after performing this action.'
  },
  configureButton: {
    id: 'MFAConfiguration.button_configure',
    defaultMessage: 'Configure'
  },
  virtualMFADescription: {
    id: 'MFAConfiguration.virtual_mfa_description',
    defaultMessage: 'Configure your virtual MFA'
  },
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close'
  },
})



export const MFASettings: React.FC<BoxProps> = props => {
  const { isMFAEnabled, isLoading, error, onErrorClose } = useMFASettings()

  return <Box {...props}>
    {
      isMFAEnabled
        ? <DisableMFA isLoading={isLoading} />
        : <EnableMFA isLoading={isLoading} />
    }
    {
      error &&
      <ErrorViewer title={error} onClose={onErrorClose} />
    }
  </Box>
}

interface MFAActionProps {
  isLoading: boolean
}

const DisableMFA: React.FC<MFAActionProps> = props => {
  const { isLoading } = props
  const intl = useIntl()

  const {
    isDisabling,
    onDisable,
    error,
    onErrorClose
  } = useMFAActions()

  const buttonProps: NestedButtonProps = {
    label: intl.formatMessage(messages.disableButton),
    colorScheme: 'red',
    variant: 'ghost',
    isLoading
  }

  const confirmButtonProps: NestedButtonProps = {
    label: intl.formatMessage(messages.disableButton),
    isLoading: isDisabling
  }
  return <>
    <HStack justifyContent={'space-between'} alignItems={'center'} p={2}>
      <VStack alignItems={'start'} justifyContent={'start'} spacing={0}>
        <Heading size='sm'>
          {intl.formatMessage(messages.multiFactorLabel)}
        </Heading>
        <Text as={'span'} fontSize='sm'>
          {intl.formatMessage(messages.virtualMFAAlreadySet)}
        </Text>
      </VStack>

      <ConfirmButton
        onConfirm={onDisable}
        message={intl.formatMessage(messages.disablingWarning)}
        buttonProps={buttonProps}
        confirmButtonProps={confirmButtonProps}
        skipClosing
      />

    </HStack>
    {error && <ErrorViewer title={error} onClose={onErrorClose} />}
  </>

}



const EnableMFA: React.FC<MFAActionProps> = props => {
  const { isLoading } = props
  const intl = useIntl()

  const {
    isDeviceAssociated,
    isAssociating,
    isEnabling,
    onCancel,
    onVerify,
    onEnable,
    onAssiciate,
    secret,
    error,
    onErrorClose
  } = useMFAActions()


  const isAssociateButtonDisabled = !!secret
  return < >
    <HStack justifyContent={'space-between'} p={2}>
      <VStack alignItems={'start'} justifyContent={'start'} spacing={0}>
        <Heading size='sm'>
          {intl.formatMessage(messages.multiFactorLabel)}
        </Heading>
        <Text as={'span'} fontSize='sm'>
          {intl.formatMessage(messages.virtualMFADescription)}
        </Text>
      </VStack>

      <Button
        colorScheme={'brand'}
        onClick={onAssiciate}
        variant='ghost'
        isLoading={isAssociating || isLoading}
        isDisabled={isAssociateButtonDisabled}
      >
        {intl.formatMessage(messages.configureButton)}
      </Button>
    </HStack>

    {
      (error || secret) &&
      <MFASetupModal
        secret={secret}
        isDeviceAssociated={isDeviceAssociated}
        isLoading={isEnabling}
        onVerify={onVerify}
        onCancel={onCancel}
        onEnable={onEnable}
        error={error}
        onErrorClose={onErrorClose}
        onClose={onCancel}
      />
    }


    {
      error &&
      <ErrorViewer title={error} onClose={onErrorClose} />
    }
  </ >
}

interface MFASetupModalProps {
  secret: Nullable<string>
  isDeviceAssociated: boolean
  isLoading: boolean
  onVerify: () => void
  onCancel: () => void
  onEnable: () => void
  onClose: () => void
  error: Nullable<string>
  onErrorClose: () => void
}

const MFASetupModal: React.FC<MFASetupModalProps> = props => {
  const { onClose, isDeviceAssociated, secret, isLoading, } = props

  const intl = useIntl()


  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return <Modal isOpen onClose={onClose}>
    <ModalOverlay />

    <ModalContent>
      <ModalHeader>
        {intl.formatMessage(messages.virtualMFADescription)}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {
          isDeviceAssociated
            ? <Text py={3}>
              {intl.formatMessage(messages.deviceAssociatedDescription)}
            </Text>
            : <MFATokenAssociation
              secret={secret}
              onVerify={props.onVerify}
              onCancel={props.onCancel}
            />
        }
      </ModalBody>

      <ModalFooter>
        <Button mr={3} onClick={onClose} isFullWidth={isFullWidth}>
          {intl.formatMessage(messages.cancelButton)}
        </Button>
        <Button
          onClick={props.onEnable}
          colorScheme='brand'
          isDisabled={!isDeviceAssociated}
          isLoading={isLoading}
          isFullWidth={isFullWidth}
        >
          {intl.formatMessage(messages.enableButton)}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

