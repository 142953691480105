import React from 'react'
import {
  Box,
  Heading,
  HStack,
  IconButton,
  Collapse,
  useDisclosure,
  BoxProps,
  Center,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  ButtonProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { defineMessages, useIntl } from 'react-intl'
import { FullSurveyHistoryItem } from '../../api/interfaces'
import { BodyViewer } from '../Surveys'
import { SurveySelector } from '../Selector'
import { useAddSurveyToHistory, useRemoveSurveyFromHistory, useUpdateSurveyInHistory } from '.'
import { ConfirmButton, ErrorViewer, Loading } from '..'
import { DynamicTranslation } from '../i18n'
import { RiArrowRightSLine as ExpandButton, RiDeleteBin2Line as DeleteIcon } from 'react-icons/ri'
import { SignatureTag } from '../SignatureTag'
import { HEADER_HEIGHT } from '../Layout/Header'

const messages = defineMessages({
  title: {
    id: 'SurveyList.title',
    defaultMessage: 'Surveys',
  },
  closeButton: {
    id: 'UI.button_close',
    defaultMessage: 'Close',
  },
  fillButton: {
    id: 'UI.button_fill',
    defaultMessage: 'Fill',
  },
  openButton: {
    id: 'UI.button_open',
    defaultMessage: 'Open',
  },
  delete: {
    id: 'UI.button_delete',
    defaultMessage: 'Delete',
  },
  confirmationMessage: {
    id: 'PatientActionCard.message_deleting_warning',
    defaultMessage: 'This will permanently delete this patient. Are you sure?',
  },
  emptySurvey: {
    id: 'SurveyModal.empty_survey',
    defaultMessage: 'This survey has no items to fill',
  },
})

/**
 * This is for the history tab in the
 * active visit scene which allow users to
 * add/remove entries.
 *
 * read only items belog to old visits so they cannot be
 * deleted.
 */
type EditableSurveysSectionProps = BoxProps & {
  patientId: string
  visitId: string
  items: Array<FullSurveyHistoryItem>
  readOnlyItems?: Array<FullSurveyHistoryItem>
  defaultIsOpen?: boolean
}

export const SurveysSection: React.FC<EditableSurveysSectionProps> = (props) => {
  const { patientId, visitId, items, readOnlyItems, defaultIsOpen, ...boxProps } = props

  const intl = useIntl()

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  const params = {
    patientId,
    visitId,
  }

  const { onSubmit, onErrorClose, error, isLoading } = useAddSurveyToHistory(params)

  const showExpandButton = items.length > 0
  return (
    <Box {...boxProps}>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Heading size={'md'} mb={1} onClick={onToggle} cursor="pointer">
          {intl.formatMessage(messages.title)}
        </Heading>
        {showExpandButton && (
          <IconButton
            size="sm"
            aria-label={'expand all'}
            colorScheme={'brand'}
            variant="ghost"
            onClick={onToggle}
            icon={<ExpandButton />}
            sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}
          />
        )}
      </HStack>

      <Collapse startingHeight={0} in={isOpen} animateOpacity>
        <Accordion allowToggle mt={3}>
          {Array.isArray(readOnlyItems) &&
            readOnlyItems.map((survey) => {
              const key = `read-only-survey-${survey.historyId}`

              return <ReadOnlySurveyItem key={key} survey={survey} />
            })}
        </Accordion>
        <Accordion allowToggle>
          {items.map((survey) => {
            const key = `fillable-survey-${survey.historyId}`

            return <SurveyItem patientId={patientId} visitId={visitId} key={key} survey={survey} />
          })}
        </Accordion>
      </Collapse>

      <Box mt={4}>
        <SurveySelector
          value={''}
          onChange={onSubmit}
          placeholder="Add a survey"
          isLoading={isLoading}
        />
      </Box>

      {error && <ErrorViewer title={error} onClose={onErrorClose} />}
    </Box>
  )
}

interface ItemProps {
  patientId: string
  visitId: string
  survey: FullSurveyHistoryItem
}

const SurveyItem: React.FC<ItemProps> = (props) => {
  const { patientId, visitId, survey } = props
  const { headerBg } = useColorModeValue({ headerBg: 'white' }, { headerBg: 'gray.800' })

  return (
    <AccordionItem px={0}>
      {({ isExpanded }) => {
        const styles = {
          justifyContent: 'space-between',
          position: isExpanded ? 'sticky' : 'relative',
          top: isExpanded ? `${HEADER_HEIGHT}px` : 0,
          bg: isExpanded ? headerBg : 'inherit',
          py: 4,
          px: 1,
          zIndex: 2,
          '&:hover': {
            bg: isExpanded ? headerBg : 'inherit',
          },
          borderBottomWidth: isExpanded ? 1 : 0,
        }
        return (
          <>
            <AccordionButton sx={styles}>
              <HStack>
                <SignatureTag minWidth={'fit-content'} signature={survey.signature} />
                <DynamicTranslation
                  noOfLines={1}
                  message={survey.name}
                  fontSize={'md'}
                  fontWeight="bold"
                />
              </HStack>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel px={1}>
              {isExpanded && (
                <FillableSurvey
                  w={'100%'}
                  patientId={patientId}
                  visitId={visitId}
                  survey={survey}
                />
              )}
            </AccordionPanel>
          </>
        )
      }}
    </AccordionItem>
  )
}

interface DeleteSurveyButtonProps extends ButtonProps {
  patientId: string
  visitId: string
  historyId: string
}

const DeleteSurveyButton: React.FC<DeleteSurveyButtonProps> = (props) => {
  const { patientId, visitId, historyId, ...buttonProps } = props

  const intl = useIntl()

  const params = {
    patientId,
    visitId,
  }

  const { onDelete, isLoading } = useRemoveSurveyFromHistory(params)

  const buttonStyles = {
    variant: 'ghost',
    justifyContent: 'start',
    borderRadius: 'md',
    my: 2,
    ...buttonProps,
  }

  const deleteSurveyProps = {
    onConfirm: () => onDelete(historyId),
    message: intl.formatMessage(messages.confirmationMessage),
    buttonProps: {
      label: (
        <>
          <Icon as={DeleteIcon} mr={2} />
          {intl.formatMessage(messages.delete)}
        </>
      ),
      colorScheme: 'red',
      ...buttonStyles,
    },
    confirmButtonProps: {
      label: intl.formatMessage(messages.delete),
      isLoading,
    },
    skipClosing: true,
  }

  return <ConfirmButton {...deleteSurveyProps} />
}

interface FillableSurveyProps extends BoxProps {
  survey: FullSurveyHistoryItem
  patientId: string
  visitId: string
}

const FillableSurvey: React.FC<FillableSurveyProps> = (props) => {
  const { patientId, visitId, survey, ...boxProps } = props

  const intl = useIntl()

  const params = {
    patientId,
    visitId,
    historyId: survey.historyId,
  }

  const { onInputChange, onCheckboxChange, error, isLoading, onErrorClose } =
    useUpdateSurveyInHistory(params)

  return (
    <Box {...boxProps}>
      <HStack justifyContent={'space-between'}>
        <HStack>
          <Heading size={'lg'}>
            <DynamicTranslation message={survey.name} />
          </Heading>
          {isLoading && <Loading size="sm" />}
        </HStack>
        <DeleteSurveyButton patientId={patientId} visitId={visitId} historyId={survey.historyId} />
      </HStack>
      {survey.description && <DynamicTranslation message={survey.description} />}
      {survey.body ? (
        <BodyViewer
          holderId="fillable-holder"
          value={survey.body}
          onInputChange={onInputChange}
          onCheckboxChange={onCheckboxChange}
          isFillable
        />
      ) : (
        <Center>
          <Heading size={'dm'} my={4}>
            {intl.formatMessage(messages.emptySurvey)}
          </Heading>
        </Center>
      )}

      {error && <ErrorViewer title={error} onClose={onErrorClose} />}
    </Box>
  )
}

/**
 * This is for the read only version of the
 * history in the patient scene
 */
export type ReadOnlySurveysSectionProps = BoxProps & {
  items: Array<FullSurveyHistoryItem>
  defaultIsOpen?: boolean
}

export const ReadOnlySurveysSection: React.FC<ReadOnlySurveysSectionProps> = (props) => {
  const { items, defaultIsOpen, ...boxProps } = props

  const intl = useIntl()

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  const showExpandButton = items.length > 0
  return (
    <Box {...boxProps}>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Heading size={'md'} mb={1} onClick={onToggle} cursor="pointer">
          {intl.formatMessage(messages.title)}
        </Heading>
        {showExpandButton && (
          <IconButton
            size="sm"
            aria-label={'expand all'}
            colorScheme={'brand'}
            variant="ghost"
            onClick={onToggle}
            icon={<ExpandButton />}
            sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}
          />
        )}
      </HStack>

      <Collapse startingHeight={0} in={isOpen} animateOpacity>
        <Accordion allowToggle>
          {items.map((survey) => {
            const key = `survey-${survey.historyId}`

            return <ReadOnlySurveyItem key={key} survey={survey} />
          })}
        </Accordion>
      </Collapse>
    </Box>
  )
}

interface ReadOnlySurveyItemProps {
  survey: FullSurveyHistoryItem
}

const ReadOnlySurveyItem: React.FC<ReadOnlySurveyItemProps> = (props) => {
  const { survey } = props
  const { headerBg } = useColorModeValue({ headerBg: 'white' }, { headerBg: 'gray.800' })

  return (
    <AccordionItem px={0} borderBottomWidth={0}>
      {({ isExpanded }) => {
        const styles = {
          justifyContent: 'space-between',
          position: isExpanded ? 'sticky' : 'relative',
          top: 0,
          bg: isExpanded ? headerBg : 'inherit',
          py: 4,
          px: 1,
          zIndex: 2,
          '&:hover': {
            bg: isExpanded ? headerBg : 'inherit',
          },
          borderBottomWidth: isExpanded ? 1 : 0,
          borderTopWidth: isExpanded ? 1 : 0,
        }

        return (
          <>
            <AccordionButton sx={styles}>
              <HStack>
                <SignatureTag minWidth={'fit-content'} signature={survey.signature} />
                <DynamicTranslation
                  noOfLines={1}
                  textAlign={'start'}
                  message={survey.name}
                  fontSize={'md'}
                  fontWeight="bold"
                />
              </HStack>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel px={1}>
              {isExpanded && <ReadOnlySurvey w={'100%'} survey={survey} />}
            </AccordionPanel>
          </>
        )
      }}
    </AccordionItem>
  )
}

interface ReadOnlySurveyProps extends BoxProps {
  survey: FullSurveyHistoryItem
}

const ReadOnlySurvey: React.FC<ReadOnlySurveyProps> = (props) => {
  const { survey, ...boxProps } = props

  const intl = useIntl()

  return (
    <Box {...boxProps}>
      <HStack>
        <Heading size={'lg'}>
          <DynamicTranslation message={survey.name} />
        </Heading>
      </HStack>
      {survey.description && <DynamicTranslation message={survey.description} />}
      {survey.body ? (
        <BodyViewer holderId="read-only-holder" value={survey.body} isFillable={false} />
      ) : (
        <Center>
          <Heading size={'sm'} my={4}>
            {intl.formatMessage(messages.emptySurvey)}
          </Heading>
        </Center>
      )}
    </Box>
  )
}
