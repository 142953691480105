import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { NewProcedureOrder } from '.'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { ADD_PROCEDURE_ORDER_TO_ACTIVE_VISIT } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  patientNotFound: {
    id: 'UseOrderActions.patient_not_found',
    defaultMessage: 'The patient was not found.',
  },
  invalidProcedureId: {
    id: 'UseOrderActions.invalid_procedure_id',
    defaultMessage: 'You must select a valid procedure.',
  },
  genericErrorMessage: {
    id: 'UseOrderActions.generic_error_message',
    defaultMessage: 'Something went wrong, try again later.',
  },
})

interface CreateProcedureOrderVariables {
  patientId: string
  visitId: string
  procedureOrder: NewProcedureOrder
}

interface CreateProcedureOrderData {
  addProcedureOrderToActiveVisit: FullPatientWithActiveVisit
}

export type UseCreateProcedureOrderDataParams = MutationHookOptions<
  CreateProcedureOrderData,
  CreateProcedureOrderVariables
>

export const useCreateProcedureOrder = (params: UseCreateProcedureOrderDataParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'invalid_procedure_id':
        return setError(intl.formatMessage(messages.invalidProcedureId))

      default:
        return setError(intl.formatMessage(messages.genericErrorMessage))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)

    translateError(firstError)
  }

  const [createProcedureOrder, { loading: isLoading }] = useMutation(
    ADD_PROCEDURE_ORDER_TO_ACTIVE_VISIT,
    {
      onError,
      ...params,
    }
  )

  const onErrorClose = () => {
    setError(null)
  }

  return {
    createProcedureOrder,
    isLoading,
    error,
    onErrorClose,
  }
}
