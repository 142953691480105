import { ButtonProps } from '@chakra-ui/react'
import React from 'react'
import {
  PrintableBloodPressure,
  PrintableWeight,
  PrintableHeight,
  PrintableHeadCircumference,
  PrintableTemperature,
} from '.'
import {
  BloodPressure,
  Weight,
  Height,
  HeadCircumference,
  Temperature,
  Measurement,
  VitalType
} from '../../api/interfaces'

type MeasurementProps = Omit<ButtonProps, 'type'> & {
  birthdate: string
  sex: string
  type: VitalType
  values: Array<Measurement>
}

export const ReadOnlyMeasurement: React.FC<MeasurementProps> = props => {
  const { type, values, sex, birthdate, ...buttonProps } = props

  if (values.length === 0) {
    return null
  }

  const value = values[0]
  switch (type) {
    case VitalType.BLOOD_PRESSURE:
      return <PrintableBloodPressure
        {...buttonProps}
        value={value as BloodPressure}
        birthdate={birthdate}
      />


    case VitalType.HEIGHT:
      return <PrintableHeight
        {...buttonProps}
        value={value as Height}
        birthdate={birthdate}
        sex={sex}
      />

    case VitalType.TEMPERATURE:
      return <PrintableTemperature
        {...buttonProps}
        value={value as Temperature}
        birthdate={birthdate}
      />

    case VitalType.WEIGHT:
      return <PrintableWeight
        {...buttonProps}
        value={value as Weight}
        birthdate={birthdate}
        sex={sex}
      />

    case VitalType.HEAD_CIRCUMFERENCE:
      return <PrintableHeadCircumference
        {...buttonProps}
        value={value as HeadCircumference}
        birthdate={birthdate}
        sex={sex}
      />



    /**
     * The components for theese types still need to be
     * updated to work with several measurements
     */
    case VitalType.MID_UPPER_ARM_CIRCUMFERENCE:
    case VitalType.HEART_RATE:
    case VitalType.RESPIRATORY_RATE:
    case VitalType.O2_SATURATION:
    default:
      return null
  }
}

