import React from 'react'
import { ConfirmRegistrationParams, useSession } from '.'
import { ValidateFn, ValidationError } from '../Form'
import { useNavigate } from 'react-router-dom'
import { defineMessages, useIntl } from 'react-intl'
import { sitemap } from '../Routing/sitemap'
import { Nullable } from '../../utils'

const messages = defineMessages({
  required: {
    id: 'StaffRegisterConfirm.this_field_is_required',
    defaultMessage: 'This field is required',
  },
  codeExpired: {
    id: 'StaffRegisterConfirm.invalid_code_provided',
    defaultMessage: 'Code expired, please request a new code',
  },
  invalidCodeError: {
    id: 'StaffRegisterConfirm.invalid_code_provided',
    defaultMessage: 'Invalid code provided, please request a code again',
  },
  limitExceededException: {
    id: 'StaffRegisterConfirm.attempt_limit_exceeded',
    defaultMessage: 'Attempt limit exceeded, please try after some time.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export const useSignupConfirm = () => {
  const [error, setError] = React.useState<Nullable<string>>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isReSendingCode, setIsReSendingCode] = React.useState<boolean>(false)

  const intl = useIntl()
  const navigate = useNavigate()
  const { confirmRegistration, getUsername, resendConfirmationCode } = useSession()
  const username = getUsername()

  const translateError = (code) => {
    switch (code) {
      case 'ExpiredCodeException':
        setError(intl.formatMessage(messages.codeExpired))
        break

      case 'CodeMismatchException':
        setError(intl.formatMessage(messages.invalidCodeError))
        break

      case 'LimitExceededException':
        setError(intl.formatMessage(messages.limitExceededException))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
    setIsLoading(false)
  }

  const validate: ValidateFn<ConfirmRegistrationParams> = (values) => {
    const errors: ValidationError<ConfirmRegistrationParams> = {}

    if (!values.code) {
      errors.code = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onSubmit = async (values: ConfirmRegistrationParams): Promise<void> => {
    setIsLoading(true)
    try {
      await confirmRegistration(values)

      navigate(sitemap.unauth.routes.login.absolutePath)
    } catch (error: any) {
      setIsLoading(false)

      if (error?.code === 'UserNotConfirmedException') {
        navigate(sitemap.unauth.routes.signUpConfirm.absolutePath)
      } else {
        translateError(translateError)
      }
    }
  }

  const onResend = async () => {
    setIsReSendingCode(true)
    try {
      await resendConfirmationCode()
    } catch (error) {
      setIsReSendingCode(false)
      translateError(translateError)
    }
    setIsReSendingCode(false)
  }

  const onCancel = () => {
    navigate(sitemap.unauth.routes.login.absolutePath)
  }

  React.useEffect(() => {
    if (username === null) {
      navigate(sitemap.unauth.routes.signup.absolutePath)
    }
  }, [username, navigate])

  return {
    onCancel,
    onSubmit,
    isLoading,
    error,
    validate,
    onResend,
    isReSendingCode,
  }
}
