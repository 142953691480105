import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ChecklistReference, ChecklistTextEntry, ChecklistDivider } from '../'

import { QualityEquationResultTag } from '../../QualityChecks'
import {
  ChecklistEntryType,
  FullChecklistEntry,
  QualityEquationResult as QualityEquationResultInterface,
} from '../../../api/interfaces'
import { Box, BoxProps, Heading, HStack, List, ListItem } from '@chakra-ui/react'
import { Nullable } from '../../../utils'

const messages = defineMessages({
  titleHistory: {
    id: 'Checklist.title_history',
    defaultMessage: 'History',
  },
})

interface OnItemChangeParams {
  key: number
  checked: boolean
}

/**
 * History section
 */
type HistoryProps = Omit<BoxProps, 'onChange'> & {
  items: Array<FullChecklistEntry>
  onChange: (params: OnItemChangeParams) => void
  qualityCheck: Nullable<QualityEquationResultInterface>
  isDisabled?: boolean
}

export const History: React.FC<HistoryProps> = (props) => {
  const { items, isDisabled, onChange, qualityCheck, ...boxProps } = props

  const intl = useIntl()
  const citations = items.filter(({ type }) => type === ChecklistEntryType.CITATION)

  const onCheckChange = (index) => (checked) => {
    const key = items[index].key
    if (key !== null) {
      onChange({ key, checked })
    }
  }

  return (
    <Box {...boxProps}>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <HStack alignItems={'center'}>
          <Heading size={'md'}>{intl.formatMessage(messages.titleHistory)}</Heading>
          <ChecklistReference items={citations} />
        </HStack>

        {qualityCheck?.value && (
          <QualityEquationResultTag key={`icon-qcheck-history`} name={qualityCheck.name} result={qualityCheck.value} />
        )}
      </HStack>
      <List ml={4} my={2}>
        {items.map((item, index) => {
          if (item.type === ChecklistEntryType.TEXT) {
            return (
              <ListItem key={`${ChecklistEntryType.TEXT}-${index}`}>
                <ChecklistTextEntry entry={item} isDisabled={isDisabled} onCheckChange={onCheckChange(index)} />
              </ListItem>
            )
          }

          if (item.type === ChecklistEntryType.DIVIDER && item.name !== null) {
            return (
              <ListItem key={`${ChecklistEntryType.DIVIDER}-${index}`}>
                <ChecklistDivider name={item.name} reference={item.reference} />
              </ListItem>
            )
          }

          return null
        })}
      </List>
    </Box>
  )
}
