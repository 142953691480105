export interface CognitoConfig {
  UserPoolId?: string
  ClientId?: string
}

export interface ServerConfig {
  cognito: CognitoConfig
  region: string
  releaseType: string
  release: string
  role: string
  sentryDsn: string
  [x: string | number | symbol]: unknown
}

export interface WindowWithEnvs extends Window {
  serverConfig?: ServerConfig
}

export const getEnvs = (): ServerConfig => {
  return (window as WindowWithEnvs).serverConfig as ServerConfig
}
