import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  ModalFooter,
  Box,
  Icon,
  Heading,
  Text,
  Input,
  HStack,
  VStack,
  ButtonProps,
  BoxProps,
  useBreakpointValue,
} from '@chakra-ui/react'
import { RiCheckLine as CheckIcon } from 'react-icons/ri'
import { FullChecklistEntry, FullProcedure } from '../../api/interfaces'
import { useCreateProcedureOrder } from '.'
import { usePagination } from '../Searches/usePagination'
import { PaginatedList } from '../Searches/PaginatedList'
import useProcedureSubscriptions from './useProcedureSubscriptions'
import { Nullable } from '../../utils'
import { ErrorViewer } from '..'
const messages = defineMessages({
  orderNewProcedure: {
    id: 'ProcedureOrderModal.order_new_procedure',
    defaultMessage: 'Order new intervention',
  },
  searchProcedures: {
    id: 'ProcedureOrderModal.search_procedures',
    defaultMessage: 'Search interventions',
  },
  orderButton: {
    id: 'ProcedureOrderModal.order',
    defaultMessage: 'Order',
  },
  comment: {
    id: 'ProcedureOrderModal.comment',
    defaultMessage: 'Comment',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
})

interface ProcedureOrderModalProps {
  patientId: string
  visitId: string
  guideline?: FullChecklistEntry
  isOpen: boolean
  preventModalFromClosing?: boolean
  onClose: () => void
  onOrder?: () => void
}

interface SelectedItem {
  id: string
  name: string
}
export const ProcedureOrderModal: React.FC<ProcedureOrderModalProps> = (props) => {
  const { patientId, visitId, guideline, isOpen, preventModalFromClosing, onClose } = props
  const intl = useIntl()

  const [selectedProcedure, setSelectedProcedure] = React.useState<Nullable<SelectedItem>>(null)
  const [comment, setComment] = React.useState<string>('')

  /**
   * This is the easiest way to uncheck the checklist item
   * if the order is canceled that doesn't require rewriting
   * all the involved components. It is probably worth doing
   * so at a later time in order to have more maintainable code.
   * For now:
   * Callback should trigger the item update in case the order is added.
   * If callback doesn't exist, just toggle the modal.
   */
  const onCompleted = () => {
    if (preventModalFromClosing) return
    if (typeof props.onOrder === 'function') {
      props.onOrder()
    } else {
      onClose()
    }
  }

  const { createProcedureOrder, isLoading, error, onErrorClose } = useCreateProcedureOrder({
    onCompleted,
  })

  const onSelect = (item: Nullable<SelectedItem>) => {
    setSelectedProcedure(item)
  }

  const onCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setComment(value)
  }

  const shouldBeDisabled = selectedProcedure === null || !visitId
  const onSubmit = () => {
    if (shouldBeDisabled) {
      return
    }

    const variables = {
      patientId,
      visitId,
      procedureOrder: {
        procedureId: selectedProcedure.id,
        orderTitle: selectedProcedure.name,
        guideline,
        comment,
      },
    }

    createProcedureOrder({ variables })
  }

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{intl.formatMessage(messages.orderNewProcedure)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProcedureList
            initialQuery={guideline?.name?.text ?? undefined}
            onSelect={onSelect}
            selectedItem={selectedProcedure}
            mb={2}
            p={2}
          />

          <Input
            value={comment}
            onChange={onCommentChange}
            placeholder={intl.formatMessage(messages.comment)}
            maxLength={120}
          />
          {error && <ErrorViewer title={error} onClose={onErrorClose} />}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={props.onClose} isFullWidth={isFullWidth}>
            {intl.formatMessage(messages.cancelButton)}
          </Button>
          <Button
            colorScheme={'brand'}
            isLoading={isLoading}
            onClick={onSubmit}
            isDisabled={shouldBeDisabled}
            isFullWidth={isFullWidth}
          >
            {intl.formatMessage(messages.orderButton)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type ProcedureListProps = Omit<BoxProps, 'onSelect'> & {
  initialQuery?: string
  onSelect: (selected: Nullable<SelectedItem>) => void
  selectedItem: Nullable<SelectedItem>
}

const ProcedureList: React.FC<ProcedureListProps> = (props) => {
  const { initialQuery, onSelect, selectedItem, ...boxProps } = props

  const intl = useIntl()

  const params = {
    defaultQ: initialQuery,
  }
  const { filter, page, onSearch, onPageChange } = usePagination(params)
  const { procedures, count, isLoading, error, onErrorClose } = useProcedureSubscriptions()

  const items = procedures.slice(filter.offset, filter.offset + filter.limit)

  return (
    <Box borderRadius="md" borderWidth="1px" {...boxProps}>
      <PaginatedList
        placeholder={intl.formatMessage(messages.searchProcedures)}
        items={items}
        total={count}
        itemsPerPage={filter.limit}
        defaultSearch={initialQuery}
        page={page}
        onSearch={onSearch}
        onPageChange={onPageChange}
        isLoading={isLoading}
        initialFocus
        renderItem={(procedure) => (
          <Item
            key={procedure.id}
            procedure={procedure}
            onSelect={onSelect}
            isSelected={selectedItem?.id === procedure.id}
            px={4}
            isFullWidth
          />
        )}
      />
      {error && <ErrorViewer title={error} onClose={onErrorClose} />}
    </Box>
  )
}

interface TranslatedProcedures extends FullProcedure {
  translatedName: string
  translatedDescription: string
}

type ItemProps = Omit<ButtonProps, 'onClick' | 'onSelect'> & {
  procedure: TranslatedProcedures
  isSelected?: boolean
  onSelect: (selected: Nullable<SelectedItem>) => void
}

const Item: React.FC<ItemProps> = (props) => {
  const { procedure, isSelected, onSelect, ...buttonProps } = props

  const { id, translatedName, translatedDescription } = procedure

  const onClick = () => {
    onSelect(isSelected ? null : { id, name: translatedName })
  }

  return (
    <Button onClick={onClick} {...buttonProps} isActive={isSelected} my={1}>
      <HStack>
        <VStack>
          <Heading size="sm" fontWeight={'bolder'}>
            {translatedName}
          </Heading>
          {translatedDescription && <Text fontSize={'sm'}>{translatedDescription}</Text>}
        </VStack>
        {isSelected && <Icon as={CheckIcon} />}
      </HStack>
    </Button>
  )
}
