import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { PaginatedResult } from '../../api/interfaces'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { BasicVisitWithBill } from '../../api/interfaces/billing'
import { GET_BILLABLE_VISITS } from '../../api/requests/billing'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface BillsVariables {
  q?: string
  offset?: number
  limit?: number
  location: string
}

interface BillsData {
  bills: PaginatedResult<BasicVisitWithBill>
}

export type UseBillsParams = QueryHookOptions<BillsData, BillsVariables>

export const useBills = (params?: UseBillsParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery<BillsData, BillsVariables>(GET_BILLABLE_VISITS, {
    onError,
    fetchPolicy: 'cache-and-network',
    ...params,
  })

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading,
    bills: data?.bills.docs ?? [],
    count: data?.bills.count ?? 0,
    error,
    refetch,
    onErrorClose,
  }
}
