import { gql } from '@apollo/client'
import { FullStoredRx } from './rxs'
import { FullChecklist, FullChecklistEntry } from './checklists'
import { FullComment, FullList, FullListItem, UserSignature } from './common'
import { FullStudyOrder } from './studies'
import { FullExamWithoutId } from './exams'
import { FullSurveyBody } from './surveys'

export const FullComplaintLists = gql`
  fragment FullComplaintLists on ComplaintLists {
    siteSpecificComplaintList {
      ...FullList
    }
    globalComplaintList {
      ...FullList
    }
    positionList {
      ...FullList
    }
    bodyPartList {
      ...FullList
    }
    degreeList {
      ...FullList
    }
    timingList {
      ...FullList
    }
    characteristicList {
      ...FullList
    }
  }
  ${FullList}
`

export const FullVitals = gql`
  fragment FullVitals on Vitals {
    measurements {
      ... on BloodPressure {
        id
        type
        systolic
        diastolic
        updated {
          ...UserSignature
        }
      }
      ... on Weight {
        id
        type
        kg
        updated {
          ...UserSignature
        }
      }
      ... on Height {
        id
        type
        cm
        updated {
          ...UserSignature
        }
      }
      ... on Bmi {
        id
        type
        bmi
        updated {
          ...UserSignature
        }
      }
      ... on HeadCircumference {
        id
        type
        cm
        updated {
          ...UserSignature
        }
      }
      ... on MidUpperArmCircumference {
        id
        type
        cm
        updated {
          ...UserSignature
        }
      }
      ... on Temperature {
        id
        type
        degrees_c
        location
        method
        updated {
          ...UserSignature
        }
      }
      ... on HeartRate {
        id
        type
        bpm
        updated {
          ...UserSignature
        }
      }
      ... on RespiratoryRate {
        id
        type
        breaths_per_minute
        updated {
          ...UserSignature
        }
      }
      ... on O2Saturation {
        id
        type
        percent
        updated {
          ...UserSignature
        }
      }
    }
  }
  ${UserSignature}
`

export const FullHistoryItem = gql`
  fragment FullHistoryItem on HistoryItem {
    name
    signature {
      ...UserSignature
    }
    start
    end
    historyId
  }
  ${UserSignature}
`

export const FullSurveyHistoryItem = gql`
  fragment FullSurveyHistoryItem on SurveyHistoryItem {
    historyId
    surveyId
    name {
      text
    }
    description {
      text
    }
    signature {
      ...UserSignature
    }
    body {
      ...FullSurveyBody
    }
  }
  ${FullSurveyBody}
  ${UserSignature}
`

export const FullComplaints = gql`
  fragment FullComplaints on Complaints {
    id
    key
    type
    name {
      text
      type
    }
    note
    degree {
      ...FullListItem
    }
    characteristics {
      id
      list {
        ...FullListItem
      }
      values {
        ...FullListItem
      }
    }
    timings {
      ...FullListItem
    }
    bodyParts {
      ...FullListItem
    }
    positions {
      ...FullListItem
    }
  }
  ${FullListItem}
`

export const FullHistory = gql`
  fragment FullHistory on History {
    manualMedications {
      ...FullHistoryItem
    }
    manualProblems {
      ...FullHistoryItem
    }
    allergies {
      ...FullHistoryItem
    }
    family {
      ...FullHistoryItem
    }
    diet {
      ...FullHistoryItem
    }
    development {
      ...FullHistoryItem
    }
    social {
      ...FullHistoryItem
    }
    generalReviewOfSystems {
      ...FullExamWithoutId
    }
    complaints {
      ...FullComplaints
    }
    comments {
      ...FullComment
    }
    surveys {
      ...FullSurveyHistoryItem
    }
  }
  ${FullComplaints}
  ${FullHistoryItem}
  ${FullExamWithoutId}
  ${FullComment}
  ${FullSurveyHistoryItem}
`

export const FullProcedureOrder = gql`
  fragment FullProcedureOrder on ProcedureOrder {
    id
    type
    comments {
      ...FullComment
    }
    guideline {
      ...FullChecklistEntry
    }
    order {
      text
      created {
        ...UserSignature
      }
    }
    canceled
    acknowledged
    procedureId
  }
  ${FullComment}
  ${FullChecklistEntry}
  ${UserSignature}
`

export const FullDrNurseOrder = gql`
  fragment FullDrNurseOrder on DrNurseOrder {
    id
    type
    comments {
      ...FullComment
    }
    order {
      text
      created {
        ...UserSignature
      }
    }
    canceled
    acknowledged
  }
  ${FullComment}
  ${FullChecklistEntry}
  ${UserSignature}
`

export const FullMedicineOrder = gql`
  fragment FullMedicineOrder on MedicineOrder {
    id
    type
    comments {
      ...FullComment
    }
    order {
      text
      created {
        ...UserSignature
      }
    }
    canceled
    acknowledged
    rxId
  }
  ${FullComment}
  ${FullChecklistEntry}
  ${UserSignature}
`

export const BasicVisit = gql`
  fragment BasicVisit on Visit {
    id
    location
    start
    backdated
    type
    history {
      ...FullHistory
    }
    clinical_status {
      priority
    }
    chief_complaint {
      how_long
      complaint
    }
    status_assesment
    disposition
    new_visit_type
    external_referral_location
    external_referral_reason
    followup
  }
  ${FullHistory}
`

export const ActiveVisit = gql`
  fragment ActiveVisit on Visit {
    ...BasicVisit
    status_assesment
    disposition
    exam_started_by {
      ...UserSignature
    }
    exam {
      ...FullExamWithoutId
    }
    checklists {
      ...FullChecklist
    }
    rx {
      prescriptions
      orders
    }
    medicine_orders {
      ...FullMedicineOrder
    }
    study_orders {
      ...FullStudyOrder
    }
    procedure_orders {
      ...FullProcedureOrder
    }
    dr_nurse_orders {
      ...FullDrNurseOrder
    }
    vitals {
      ...FullVitals
    }
    assessments {
      type
      description
      created {
        ...UserSignature
      }
      id
    }
    treatments {
      type
      treatment
      created {
        ...UserSignature
      }
      id
    }
    progress_notes {
      ...FullComment
    }
    room
    telehealth
  }
  ${FullMedicineOrder}
  ${FullDrNurseOrder}
  ${FullStudyOrder}
  ${FullProcedureOrder}
  ${FullComment}
  ${BasicVisit}
  ${FullExamWithoutId}
  ${FullChecklist}
  ${FullChecklistEntry}
  ${FullVitals}
  ${UserSignature}
`

export const FullPastVisit = gql`
  fragment FullPastVisit on PastVisits {
    id
    start
    end
    type
    chief_complaint {
      complaint
      how_long
    }
    assessments {
      type
      description
    }
    treatments {
      type
      treatment
    }
    diagnoses {
      ...FullChecklist
    }
  }
  ${FullChecklist}
`

export const FullDentalHistory = gql`
  fragment FullDentalHistory on DentalHistory {
    end
    start
    exam {
      ...FullExamWithoutId
    }
  }
  ${FullExamWithoutId}
`

export const FullVitalsHistory = gql`
  fragment FullVitalsHistory on VitalsHistory {
    end
    start
    vitals {
      ...FullVitals
    }
  }
  ${FullVitals}
`

export const FullMedicationsHistory = gql`
  fragment FullMedicationsHistory on MedicationsHistory {
    start
    end
    medications {
      ...FullStoredRx
    }
  }
  ${FullStoredRx}
`

export const FullVisitHistory = gql`
  fragment FullVisitHistory on VisitHistory {
    medications {
      ...FullMedicationsHistory
    }
    vitals {
      ...FullVitalsHistory
    }
    dental {
      ...FullDentalHistory
    }
    pastVisits {
      ...FullPastVisit
    }
    manualMedications {
      ...FullHistoryItem
    }
    manualProblems {
      ...FullHistoryItem
    }
    allergies {
      ...FullHistoryItem
    }
    familyHistory {
      ...FullHistoryItem
    }
    dietHistory {
      ...FullHistoryItem
    }
    developmentHistory {
      ...FullHistoryItem
    }
    socialHistory {
      ...FullHistoryItem
    }
    surveys {
      ...FullSurveyHistoryItem
    }
  }
  ${FullMedicationsHistory}
  ${FullVitalsHistory}
  ${FullDentalHistory}
  ${FullPastVisit}
  ${FullHistoryItem}
  ${FullSurveyHistoryItem}
`
