import React from 'react'

export const AsyncActiveVisit = React.lazy(() => import('../../scenes/ActiveVisit/Index'))
export const AsyncAppContent = React.lazy(() => import('../../scenes/AppContent/Index'))
export const AsyncAuth = React.lazy(() => import('../../scenes/Auth/Index'))
export const AsyncBilling = React.lazy(() => import('../../scenes/Billing/Index'))
export const AsyncMetrics = React.lazy(() => import('../../scenes/Metrics/Index'))
export const AsyncPatients = React.lazy(() => import('../../scenes/Patients/Index'))
export const AsyncPricing = React.lazy(() => import('../../scenes/Pricing/Index'))
export const AsyncPreferences = React.lazy(() => import('../../scenes/Preferences/Index'))
export const AsyncScheduler = React.lazy(() => import('../../scenes/Scheduler/Index'))
export const AsyncSetup = React.lazy(() => import('../../scenes/Setup/Index'))
export const AsyncStaff = React.lazy(() => import('../../scenes/Staff/Index'))
export const AsyncUnauth = React.lazy(() => import('../../scenes/Unauth/Index'))
export const AsyncVisits = React.lazy(() => import('../../scenes/Visits/Index'))
