import { DateTime, Interval } from 'luxon'
import { defineMessages, useIntl } from 'react-intl'

// translations re-used from reporst
const messages = defineMessages({
  years: {
    id: 'DiseasesReport.age_unit_years',
    defaultMessage: 'y',
  },
  months: {
    id: 'DiseasesReport.age_unit_months',
    defaultMessage: 'mo',
  },
  days: {
    id: 'DiseasesReport.age_unit_days',
    defaultMessage: 'd',
  },
})

export const useAgeTranslator = () => {
  const intl = useIntl()

  const translateAge = (birthdate: Date): string => {
    const date = DateTime.fromJSDate(new Date(birthdate))
    if (!date.isValid) {
      return '--'
    }

    const yearLabel = intl.formatMessage(messages.years)
    const monthLabel = intl.formatMessage(messages.months)
    const dayLabel = intl.formatMessage(messages.days)

    const age = Interval.fromDateTimes(date, DateTime.now())
    const ageObject = age.toDuration(['years', 'months', 'days', 'hours']).toObject()

    const years = ageObject?.years ?? 0
    if (years > 4) {
      return `${Math.trunc(years)}${yearLabel}`
    }

    if (years >= 1) {
      const restMonths = ageObject?.months ?? 0

      if (restMonths > 0) {
        return `${Math.trunc(years)}${yearLabel} ${Math.trunc(restMonths)}${monthLabel}`
      }

      return `${Math.trunc(years)}${yearLabel}`
    }

    const months = ageObject?.months ?? 0
    if (months > 0) {
      const restInDays = ageObject?.days ?? 0

      if (restInDays > 0) {
        return `${Math.trunc(months)}${monthLabel} ${Math.trunc(restInDays)}${dayLabel}`
      }

      return `${Math.trunc(months)}${monthLabel}`
    }

    const days = ageObject?.days ?? 0
    return `${Math.trunc(days)}${dayLabel}`
  }

  return {
    translateAge,
  }
}
