import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullChecklist, FullExamWithoutId } from '../../api/interfaces'
import { getHistoryClearingCodesFromChecklists, Nullable } from '../../utils'
import {
  Box,
  BoxProps,
  Collapse,
  Flex,
  Heading,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {
  classifyExamSections,
  GeneralReviewOfSystemSectionModal,
  PrintableSection,
  Section,
} from '../Exam'
import { IconButton } from '@chakra-ui/button'
import { RiArrowRightSLine as ExpandButton } from 'react-icons/ri'

const messages = defineMessages({
  title: {
    id: 'GeneralReviewOfSystem.title',
    defaultMessage: 'General Review of System',
  },
  errorGetGeneralReviewOfSystemListDefault: {
    id: 'GeneralReviewOfSystem.error_get_general_review_of_system_list_default',
    defaultMessage:
      'Something went wrong while trying to perform an action in general review of system, try again later.',
  },
  additionalSystems: {
    id: 'GeneralReviewOfSystem.additional_systems',
    defaultMessage: 'Additional Systems',
  },
  moreSections: {
    id: 'UI.label_more_sections',
    defaultMessage: '... {count} hidden section/s',
  },
})

type GeneralReviewOfSystemProps = BoxProps & {
  patientId: string
  visitId: string
  generalReviewOfSystems: Nullable<FullExamWithoutId>
  isDisabled?: boolean
  checklists: Array<FullChecklist>
}

export const GeneralReviewOfSystem: React.FC<GeneralReviewOfSystemProps> = (props) => {
  const { patientId, visitId, generalReviewOfSystems, isDisabled, checklists, ...boxProps } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure()

  const [selectedSectionKey, setSelectedSectionKey] = React.useState<Nullable<number>>(null)

  const sections = generalReviewOfSystems?.sections ?? []

  const clearingCodes = getHistoryClearingCodesFromChecklists(checklists)
  const { usedSections, unusedSections } = classifyExamSections(sections, clearingCodes)

  const selectedSection = sections.find((section) => selectedSectionKey === section.key) ?? null

  const showCollapsedOptions = unusedSections.length > 0
  return (
    <Box {...boxProps}>
      <HStack justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <Heading size="md" onClick={onToggle} cursor="pointer">
          {intl.formatMessage(messages.title)}
        </Heading>

        {showCollapsedOptions && (
          <IconButton
            size="sm"
            aria-label={'expand all'}
            colorScheme={'brand'}
            variant="ghost"
            onClick={onToggle}
            icon={<ExpandButton />}
            sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}
          />
        )}
      </HStack>

      {usedSections.map((section) => (
        <Section
          key={section.key}
          section={section}
          onSelect={(section) => setSelectedSectionKey(section.key)}
          isDisabled={isDisabled}
          clearingCodes={clearingCodes}
        />
      ))}

      {showCollapsedOptions && (
        <Collapse startingHeight={0} in={isOpen} animateOpacity>
          {unusedSections.map((section) => (
            <Section
              key={section.key}
              section={section}
              onSelect={(section) => setSelectedSectionKey(section.key)}
              clearingCodes={clearingCodes}
            />
          ))}
        </Collapse>
      )}

      {showCollapsedOptions && (
        <Flex justifyContent={'center'}>
          {isOpen ? (
            <IconButton
              size="sm"
              aria-label={'expand all'}
              colorScheme={'brand'}
              variant="ghost"
              onClick={onToggle}
              icon={<ExpandButton />}
              sx={{ transform: 'rotateZ(-90deg)' }}
            />
          ) : (
            <Text
              fontSize={'xs'}
              onClick={onToggle}
              cursor={'pointer'}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {intl.formatMessage(messages.moreSections, { count: unusedSections.length })}
            </Text>
          )}
        </Flex>
      )}

      {selectedSectionKey && (
        <GeneralReviewOfSystemSectionModal
          patientId={patientId}
          visitId={visitId}
          selectedSection={selectedSection}
          clearingCodes={clearingCodes}
          onClose={() => setSelectedSectionKey(null)}
        />
      )}
    </Box>
  )
}

type PrintableGeneralReviewOfSystemProps = BoxProps & {
  generalReviewOfSystems: Nullable<FullExamWithoutId>
  checklists: Array<FullChecklist>
}

export const PrintableGeneralReviewOfSystem: React.FC<PrintableGeneralReviewOfSystemProps> = (
  props
) => {
  const { generalReviewOfSystems, checklists, ...boxProps } = props

  const intl = useIntl()
  const sections = generalReviewOfSystems?.sections ?? []
  const clearingCodes = getHistoryClearingCodesFromChecklists(checklists)
  const { usedSections } = classifyExamSections(sections, clearingCodes)

  if (usedSections.length === 0) {
    return null
  }

  return (
    <Box w={'100%'} {...boxProps}>
      <HStack
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={1}
        borderBottomWidth={1}
        sx={{
          '@media print': {
            margin: '0 0 .5rem 0',
            borderBottom: '1px solid black',
          },
        }}
      >
        <Heading size="md">{intl.formatMessage(messages.title)}</Heading>
      </HStack>

      {usedSections.map((section) => (
        <PrintableSection
          key={section.key}
          section={section}
          py={1}
          px={2}
          my={1}
          className="pdfGeneralReviewOfSystem-section"
          clearingCodes={clearingCodes}
        />
      ))}
    </Box>
  )
}
