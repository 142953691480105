import React from 'react'
import Select from 'react-select'
import { Nullable } from '../../utils'
import { useIntl } from 'react-intl'
import { ReactSelectInternalSingleValue, ReactSelectInternalValue, SingleSelectorProps, useChakraThemeParser } from '.'
import { isValidDate } from '../DateAndTime'

/**
 * Quick styles customization to make it work with the
 * brand color set in the chakra context in light & dark mode.
 */
// import './styles.css'

export type DateTimeSelectorProps = SingleSelectorProps<string> & {
  availableSlots: Array<Date>
  label: string
  isDisabled?: boolean
  isClearable?: boolean
}

export const DateTimeSelector: React.FC<DateTimeSelectorProps> = (props) => {
  const { value, availableSlots, label } = props
  const intl = useIntl()
  const theme = useChakraThemeParser()

  const formatOption = (datetime: Date): ReactSelectInternalSingleValue => {
    const date = intl.formatDate(datetime)
    const time = intl.formatTime(datetime)

    return {
      value: datetime.toISOString(),
      label: `${date} ${time}`,
    }
  }

  const options = availableSlots.map(formatOption)
  const parseValue = (value: string): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (isValidDate(value)) {
      return formatOption(new Date(value))
    }

    return null
  }

  const _onChange = (value) => {
    if (!value) {
      return
    }

    props.onChange(value.value)
  }
  return (
    <Select
      options={options}
      value={parseValue(value)}
      onChange={_onChange}
      isLoading={props.isLoading}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      aria-invalid={props.isInvalid}
      placeholder={label}
      blurInputOnSelect={props.blurInputOnSelect}
      theme={theme}
    />
  )
}
