import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { calculatePercentileAndZScore, getBMIStandards } from 'wd-common/src/logic/triage/utils'
import { Weight as WeightInterface, Height as HeightInterface } from '../../api/interfaces'
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagProps,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { getAge } from '../DateAndTime'

const messages = defineMessages({
  title: {
    id: 'Vitals.bmi_title',
    defaultMessage: 'Z-Score',
  },
  shortTitle: {
    id: 'Vitals.bmi_abbreviation',
    defaultMessage: 'Z-Score',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
})

type ZScoreProps = Omit<ButtonGroupProps, 'height'> & {
  birthdate: string
  sex: string
  height: HeightInterface
  weight: WeightInterface
  isDisabled?: boolean
}

export const ZScore: React.FC<ZScoreProps> = (props) => {
  const { height, weight, birthdate, sex, isDisabled, ...buttonGroupProps } = props

  const intl = useIntl()

  const { onToggle, isOpen } = useDisclosure()

  const ageInYears = getAge(new Date(birthdate))
  if (ageInYears === null || ageInYears < 2 || ageInYears > 19) {
    return null
  }

  const bmi = parseFloat((((weight || {}).kg / height?.cm / height?.cm) * 10000).toFixed(2))
  if (isNaN(bmi) || bmi <= 0) {
    return null
  }

  const calculation = calculatePercentileAndZScore(bmi, getBMIStandards({ birthdate, sex }))
  const zScore = calculation?.zScore

  return (
    <>
      <ButtonGroup size="sm" isAttached isDisabled={isDisabled} {...buttonGroupProps}>
        <Button mr="-px" onClick={onToggle}>
          <Text>{`${intl.formatMessage(messages.shortTitle)}: ${zScore}`}</Text>
        </Button>
      </ButtonGroup>

      <ZScoreModal zScore={zScore} isOpen={isOpen} onToggle={onToggle} />
    </>
  )
}

type ZScoreModalProps = {
  zScore: number
  isOpen: boolean
  onToggle: () => void
  isDisabled?: boolean
}

const ZScoreModal: React.FC<ZScoreModalProps> = (props) => {
  const { zScore, isOpen, onToggle } = props

  const intl = useIntl()

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize={'lg'} mb={3}>
            {`${intl.formatMessage(messages.shortTitle)}: ${zScore}`}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button isFullWidth={isFullWidth} onClick={onToggle}>
            {intl.formatMessage(messages.cancelButton)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type PrintableZScoreProps = TagProps & {
  height: HeightInterface
  weight: WeightInterface
  birthdate: string
  sex: string
}

export const PrintableZScore: React.FC<PrintableZScoreProps> = (props) => {
  const { height, weight, birthdate, sex, ...tagProps } = props

  const intl = useIntl()

  const ageInYears = getAge(new Date(birthdate))
  if (ageInYears === null || ageInYears < 2 || ageInYears > 19) {
    return null
  }

  const bmi = parseFloat((((weight || {}).kg / height?.cm / height?.cm) * 10000).toFixed(2))
  if (isNaN(bmi) || bmi <= 0) {
    return null
  }
  const calculation = calculatePercentileAndZScore(bmi, getBMIStandards({ birthdate, sex }))
  const zScore = calculation?.zScore

  return <Tag {...tagProps}>{`${intl.formatMessage(messages.shortTitle)}: ${zScore}`}</Tag>
}

type PdfZScore = {
  height: HeightInterface
  weight: WeightInterface
  birthdate: string
  sex: string
}

export const PdfZScore: React.FC<PdfZScore> = (props) => {
  const { height, weight, birthdate, sex } = props

  const intl = useIntl()

  const ageInYears = getAge(new Date(birthdate))
  if (ageInYears === null || ageInYears < 2 || ageInYears > 19) {
    return null
  }

  const bmi = parseFloat((((weight || {}).kg / height?.cm / height?.cm) * 10000).toFixed(2))
  if (isNaN(bmi) || bmi <= 0) {
    return null
  }
  const calculation = calculatePercentileAndZScore(bmi, getBMIStandards({ birthdate, sex }))
  const zScore = calculation?.zScore

  return <span>{`${intl.formatMessage(messages.shortTitle)}: ${zScore}`}</span>
}
