import { useQuery } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullExam, PaginatedResult, PaginatedVariables } from '../../api/interfaces'
import { GET_EXAMS } from '../../api/requests/exams'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  errorGetListsDefault: {
    id: 'useList.error_get_lists_default',
    defaultMessage: 'Something went wrong while trying to get lists. Please, try again',
  },
})

interface Data {
  exams: PaginatedResult<FullExam>
}

export interface ExamContextValue {
  exams: Array<FullExam>
  getExam: (id: string) => Nullable<FullExam>
  isLoading: boolean
  error: Nullable<string>
  onErrorClose?: () => void
}

export const ExamContext = React.createContext<Nullable<ExamContextValue>>(null)
export const ExamProvider: React.FC = (props) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (errorCode) => {
    switch (errorCode) {
      case 'error_getting_lists':
      default:
        return setError(intl.formatMessage(messages.errorGetListsDefault))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const onCompleted = () => {
    setError(null)
  }

  const { loading, data } = useQuery<Data, PaginatedVariables>(GET_EXAMS, {
    onCompleted,
    onError,
    fetchPolicy: 'cache-first',
  })

  const getExam = (id: string): Nullable<FullExam> => {
    if (!data || !Array.isArray(data?.exams?.docs)) {
      return null
    }

    const found = data.exams.docs.find((list) => list.id === id)

    return found ?? null
  }

  const onErrorClose = () => {
    setError(null)
  }

  const value: ExamContextValue = {
    exams: data?.exams?.docs ?? [],
    getExam,
    error,
    onErrorClose,
    isLoading: !!loading,
  }

  return <ExamContext.Provider value={value}>{props.children}</ExamContext.Provider>
}
