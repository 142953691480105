// Child growth standards
// Length/height reference values for BOYS between 0 - 228 months (19 years)
// Data taken from World Health Organization and then parsed to JSON
// Links:
// https://www.who.int/childgrowth/standards/second_set/tab_hcfa_boys_p_0_13.txt (0  13 weeks)
// https://www.who.int/childgrowth/standards/tab_lhfa_boys_p_0_2.txt             (0 - 24 months)
// https://www.who.int/childgrowth/standards/tab_lhfa_boys_p_2_5.txt             (25 - 60 months)
// https://www.who.int/growthref/hfa_boys_perc_WHO2007_exp.txt                   (61 - 228 months)

const lengthValues = [
  {
    week: 0,
    l: 1,
    m: 49.8842,
    s: 0.03795,
    sd: 1.8931,
    p01: 44,
    p1: 45.5,
    p3: 46.3,
    p5: 46.8,
    p10: 47.5,
    p15: 47.9,
    p25: 48.6,
    p50: 49.9,
    p75: 51.2,
    p85: 51.8,
    p90: 52.3,
    p95: 53,
    p97: 53.4,
    p99: 54.3,
    p999: 55.7
  },
  {
    week: 1,
    l: 1,
    m: 51.1152,
    s: 0.03723,
    sd: 1.903,
    p01: 45.2,
    p1: 46.7,
    p3: 47.5,
    p5: 48,
    p10: 48.7,
    p15: 49.1,
    p25: 49.8,
    p50: 51.1,
    p75: 52.4,
    p85: 53.1,
    p90: 53.6,
    p95: 54.2,
    p97: 54.7,
    p99: 55.5,
    p999: 57
  },
  {
    week: 2,
    l: 1,
    m: 52.3461,
    s: 0.03652,
    sd: 1.9117,
    p01: 46.4,
    p1: 47.9,
    p3: 48.8,
    p5: 49.2,
    p10: 49.9,
    p15: 50.4,
    p25: 51.1,
    p50: 52.3,
    p75: 53.6,
    p85: 54.3,
    p90: 54.8,
    p95: 55.5,
    p97: 55.9,
    p99: 56.8,
    p999: 58.3
  },
  {
    week: 3,
    l: 1,
    m: 53.3905,
    s: 0.03609,
    sd: 1.9269,
    p01: 47.4,
    p1: 48.9,
    p3: 49.8,
    p5: 50.2,
    p10: 50.9,
    p15: 51.4,
    p25: 52.1,
    p50: 53.4,
    p75: 54.7,
    p85: 55.4,
    p90: 55.9,
    p95: 56.6,
    p97: 57,
    p99: 57.9,
    p999: 59.3
  },
  {
    week: 4,
    l: 1,
    m: 54.3881,
    s: 0.0357,
    sd: 1.9417,
    p01: 48.4,
    p1: 49.9,
    p3: 50.7,
    p5: 51.2,
    p10: 51.9,
    p15: 52.4,
    p25: 53.1,
    p50: 54.4,
    p75: 55.7,
    p85: 56.4,
    p90: 56.9,
    p95: 57.6,
    p97: 58,
    p99: 58.9,
    p999: 60.4
  },
  {
    week: 5,
    l: 1,
    m: 55.3374,
    s: 0.03534,
    sd: 1.9556,
    p01: 49.3,
    p1: 50.8,
    p3: 51.7,
    p5: 52.1,
    p10: 52.8,
    p15: 53.3,
    p25: 54,
    p50: 55.3,
    p75: 56.7,
    p85: 57.4,
    p90: 57.8,
    p95: 58.6,
    p97: 59,
    p99: 59.9,
    p999: 61.4
  },
  {
    week: 6,
    l: 1,
    m: 56.2357,
    s: 0.03501,
    sd: 1.9688,
    p01: 50.2,
    p1: 51.7,
    p3: 52.5,
    p5: 53,
    p10: 53.7,
    p15: 54.2,
    p25: 54.9,
    p50: 56.2,
    p75: 57.6,
    p85: 58.3,
    p90: 58.8,
    p95: 59.5,
    p97: 59.9,
    p99: 60.8,
    p999: 62.3
  },
  {
    week: 7,
    l: 1,
    m: 57.0851,
    s: 0.0347,
    sd: 1.9809,
    p01: 51,
    p1: 52.5,
    p3: 53.4,
    p5: 53.8,
    p10: 54.5,
    p15: 55,
    p25: 55.7,
    p50: 57.1,
    p75: 58.4,
    p85: 59.1,
    p90: 59.6,
    p95: 60.3,
    p97: 60.8,
    p99: 61.7,
    p999: 63.2
  },
  {
    week: 8,
    l: 1,
    m: 57.8889,
    s: 0.03442,
    sd: 1.9925,
    p01: 51.7,
    p1: 53.3,
    p3: 54.1,
    p5: 54.6,
    p10: 55.3,
    p15: 55.8,
    p25: 56.5,
    p50: 57.9,
    p75: 59.2,
    p85: 60,
    p90: 60.4,
    p95: 61.2,
    p97: 61.6,
    p99: 62.5,
    p999: 64
  },
  {
    week: 9,
    l: 1,
    m: 58.6536,
    s: 0.03416,
    sd: 2.0036,
    p01: 52.5,
    p1: 54,
    p3: 54.9,
    p5: 55.4,
    p10: 56.1,
    p15: 56.6,
    p25: 57.3,
    p50: 58.7,
    p75: 60,
    p85: 60.7,
    p90: 61.2,
    p95: 61.9,
    p97: 62.4,
    p99: 63.3,
    p999: 64.8
  },
  {
    week: 10,
    l: 1,
    m: 59.3872,
    s: 0.03392,
    sd: 2.0144,
    p01: 53.2,
    p1: 54.7,
    p3: 55.6,
    p5: 56.1,
    p10: 56.8,
    p15: 57.3,
    p25: 58,
    p50: 59.4,
    p75: 60.7,
    p85: 61.5,
    p90: 62,
    p95: 62.7,
    p97: 63.2,
    p99: 64.1,
    p999: 65.6
  },
  {
    week: 11,
    l: 1,
    m: 60.0894,
    s: 0.03369,
    sd: 2.0244,
    p01: 53.8,
    p1: 55.4,
    p3: 56.3,
    p5: 56.8,
    p10: 57.5,
    p15: 58,
    p25: 58.7,
    p50: 60.1,
    p75: 61.5,
    p85: 62.2,
    p90: 62.7,
    p95: 63.4,
    p97: 63.9,
    p99: 64.8,
    p999: 66.3
  },
  {
    week: 12,
    l: 1,
    m: 60.7605,
    s: 0.03348,
    sd: 2.0343,
    p01: 54.5,
    p1: 56,
    p3: 56.9,
    p5: 57.4,
    p10: 58.2,
    p15: 58.7,
    p25: 59.4,
    p50: 60.8,
    p75: 62.1,
    p85: 62.9,
    p90: 63.4,
    p95: 64.1,
    p97: 64.6,
    p99: 65.5,
    p999: 67
  },
  {
    week: 13,
    l: 1,
    m: 61.4013,
    s: 0.03329,
    sd: 2.044,
    p01: 55.1,
    p1: 56.6,
    p3: 57.6,
    p5: 58,
    p10: 58.8,
    p15: 59.3,
    p25: 60,
    p50: 61.4,
    p75: 62.8,
    p85: 63.5,
    p90: 64,
    p95: 64.8,
    p97: 65.2,
    p99: 66.2,
    p999: 67.7
  },
  {
    month: 0,
    l: 1,
    m: 49.8842,
    s: 0.03795,
    sd: 1.8931,
    p01: 44,
    p1: 45.5,
    p3: 46.3,
    p5: 46.8,
    p10: 47.5,
    p15: 47.9,
    p25: 48.6,
    p50: 49.9,
    p75: 51.2,
    p85: 51.8,
    p90: 52.3,
    p95: 53,
    p97: 53.4,
    p99: 54.3,
    p999: 55.7
  },
  {
    month: 1,
    l: 1,
    m: 54.7244,
    s: 0.03557,
    sd: 1.9465,
    p01: 48.7,
    p1: 50.2,
    p3: 51.1,
    p5: 51.5,
    p10: 52.2,
    p15: 52.7,
    p25: 53.4,
    p50: 54.7,
    p75: 56,
    p85: 56.7,
    p90: 57.2,
    p95: 57.9,
    p97: 58.4,
    p99: 59.3,
    p999: 60.7
  },
  {
    month: 2,
    l: 1,
    m: 58.4249,
    s: 0.03424,
    sd: 2.0005,
    p01: 52.2,
    p1: 53.8,
    p3: 54.7,
    p5: 55.1,
    p10: 55.9,
    p15: 56.4,
    p25: 57.1,
    p50: 58.4,
    p75: 59.8,
    p85: 60.5,
    p90: 61,
    p95: 61.7,
    p97: 62.2,
    p99: 63.1,
    p999: 64.6
  },
  {
    month: 3,
    l: 1,
    m: 61.4292,
    s: 0.03328,
    sd: 2.0444,
    p01: 55.1,
    p1: 56.7,
    p3: 57.6,
    p5: 58.1,
    p10: 58.8,
    p15: 59.3,
    p25: 60.1,
    p50: 61.4,
    p75: 62.8,
    p85: 63.5,
    p90: 64,
    p95: 64.8,
    p97: 65.3,
    p99: 66.2,
    p999: 67.7
  },
  {
    month: 4,
    l: 1,
    m: 63.886,
    s: 0.03257,
    sd: 2.0808,
    p01: 57.5,
    p1: 59,
    p3: 60,
    p5: 60.5,
    p10: 61.2,
    p15: 61.7,
    p25: 62.5,
    p50: 63.9,
    p75: 65.3,
    p85: 66,
    p90: 66.6,
    p95: 67.3,
    p97: 67.8,
    p99: 68.7,
    p999: 70.3
  },
  {
    month: 5,
    l: 1,
    m: 65.9026,
    s: 0.03204,
    sd: 2.1115,
    p01: 59.4,
    p1: 61,
    p3: 61.9,
    p5: 62.4,
    p10: 63.2,
    p15: 63.7,
    p25: 64.5,
    p50: 65.9,
    p75: 67.3,
    p85: 68.1,
    p90: 68.6,
    p95: 69.4,
    p97: 69.9,
    p99: 70.8,
    p999: 72.4
  },
  {
    month: 6,
    l: 1,
    m: 67.6236,
    s: 0.03165,
    sd: 2.1403,
    p01: 61,
    p1: 62.6,
    p3: 63.6,
    p5: 64.1,
    p10: 64.9,
    p15: 65.4,
    p25: 66.2,
    p50: 67.6,
    p75: 69.1,
    p85: 69.8,
    p90: 70.4,
    p95: 71.1,
    p97: 71.6,
    p99: 72.6,
    p999: 74.2
  },
  {
    month: 7,
    l: 1,
    m: 69.1645,
    s: 0.03139,
    sd: 2.1711,
    p01: 62.5,
    p1: 64.1,
    p3: 65.1,
    p5: 65.6,
    p10: 66.4,
    p15: 66.9,
    p25: 67.7,
    p50: 69.2,
    p75: 70.6,
    p85: 71.4,
    p90: 71.9,
    p95: 72.7,
    p97: 73.2,
    p99: 74.2,
    p999: 75.9
  },
  {
    month: 8,
    l: 1,
    m: 70.5994,
    s: 0.03124,
    sd: 2.2055,
    p01: 63.8,
    p1: 65.5,
    p3: 66.5,
    p5: 67,
    p10: 67.8,
    p15: 68.3,
    p25: 69.1,
    p50: 70.6,
    p75: 72.1,
    p85: 72.9,
    p90: 73.4,
    p95: 74.2,
    p97: 74.7,
    p99: 75.7,
    p999: 77.4
  },
  {
    month: 9,
    l: 1,
    m: 71.9687,
    s: 0.03117,
    sd: 2.2433,
    p01: 65,
    p1: 66.8,
    p3: 67.7,
    p5: 68.3,
    p10: 69.1,
    p15: 69.6,
    p25: 70.5,
    p50: 72,
    p75: 73.5,
    p85: 74.3,
    p90: 74.8,
    p95: 75.7,
    p97: 76.2,
    p99: 77.2,
    p999: 78.9
  },
  {
    month: 10,
    l: 1,
    m: 73.2812,
    s: 0.03118,
    sd: 2.2849,
    p01: 66.2,
    p1: 68,
    p3: 69,
    p5: 69.5,
    p10: 70.4,
    p15: 70.9,
    p25: 71.7,
    p50: 73.3,
    p75: 74.8,
    p85: 75.6,
    p90: 76.2,
    p95: 77,
    p97: 77.6,
    p99: 78.6,
    p999: 80.3
  },
  {
    month: 11,
    l: 1,
    m: 74.5388,
    s: 0.03125,
    sd: 2.3293,
    p01: 67.3,
    p1: 69.1,
    p3: 70.2,
    p5: 70.7,
    p10: 71.6,
    p15: 72.1,
    p25: 73,
    p50: 74.5,
    p75: 76.1,
    p85: 77,
    p90: 77.5,
    p95: 78.4,
    p97: 78.9,
    p99: 80,
    p999: 81.7
  },
  {
    month: 12,
    l: 1,
    m: 75.7488,
    s: 0.03137,
    sd: 2.3762,
    p01: 68.4,
    p1: 70.2,
    p3: 71.3,
    p5: 71.8,
    p10: 72.7,
    p15: 73.3,
    p25: 74.1,
    p50: 75.7,
    p75: 77.4,
    p85: 78.2,
    p90: 78.8,
    p95: 79.7,
    p97: 80.2,
    p99: 81.3,
    p999: 83.1
  },
  {
    month: 13,
    l: 1,
    m: 76.9186,
    s: 0.03154,
    sd: 2.426,
    p01: 69.4,
    p1: 71.3,
    p3: 72.4,
    p5: 72.9,
    p10: 73.8,
    p15: 74.4,
    p25: 75.3,
    p50: 76.9,
    p75: 78.6,
    p85: 79.4,
    p90: 80,
    p95: 80.9,
    p97: 81.5,
    p99: 82.6,
    p999: 84.4
  },
  {
    month: 14,
    l: 1,
    m: 78.0497,
    s: 0.03174,
    sd: 2.4773,
    p01: 70.4,
    p1: 72.3,
    p3: 73.4,
    p5: 74,
    p10: 74.9,
    p15: 75.5,
    p25: 76.4,
    p50: 78,
    p75: 79.7,
    p85: 80.6,
    p90: 81.2,
    p95: 82.1,
    p97: 82.7,
    p99: 83.8,
    p999: 85.7
  },
  {
    month: 15,
    l: 1,
    m: 79.1458,
    s: 0.03197,
    sd: 2.5303,
    p01: 71.3,
    p1: 73.3,
    p3: 74.4,
    p5: 75,
    p10: 75.9,
    p15: 76.5,
    p25: 77.4,
    p50: 79.1,
    p75: 80.9,
    p85: 81.8,
    p90: 82.4,
    p95: 83.3,
    p97: 83.9,
    p99: 85,
    p999: 87
  },
  {
    month: 16,
    l: 1,
    m: 80.2113,
    s: 0.03222,
    sd: 2.5844,
    p01: 72.2,
    p1: 74.2,
    p3: 75.4,
    p5: 76,
    p10: 76.9,
    p15: 77.5,
    p25: 78.5,
    p50: 80.2,
    p75: 82,
    p85: 82.9,
    p90: 83.5,
    p95: 84.5,
    p97: 85.1,
    p99: 86.2,
    p999: 88.2
  },
  {
    month: 17,
    l: 1,
    m: 81.2487,
    s: 0.0325,
    sd: 2.6406,
    p01: 73.1,
    p1: 75.1,
    p3: 76.3,
    p5: 76.9,
    p10: 77.9,
    p15: 78.5,
    p25: 79.5,
    p50: 81.2,
    p75: 83,
    p85: 84,
    p90: 84.6,
    p95: 85.6,
    p97: 86.2,
    p99: 87.4,
    p999: 89.4
  },
  {
    month: 18,
    l: 1,
    m: 82.2587,
    s: 0.03279,
    sd: 2.6973,
    p01: 73.9,
    p1: 76,
    p3: 77.2,
    p5: 77.8,
    p10: 78.8,
    p15: 79.5,
    p25: 80.4,
    p50: 82.3,
    p75: 84.1,
    p85: 85.1,
    p90: 85.7,
    p95: 86.7,
    p97: 87.3,
    p99: 88.5,
    p999: 90.6
  },
  {
    month: 19,
    l: 1,
    m: 83.2418,
    s: 0.0331,
    sd: 2.7553,
    p01: 74.7,
    p1: 76.8,
    p3: 78.1,
    p5: 78.7,
    p10: 79.7,
    p15: 80.4,
    p25: 81.4,
    p50: 83.2,
    p75: 85.1,
    p85: 86.1,
    p90: 86.8,
    p95: 87.8,
    p97: 88.4,
    p99: 89.7,
    p999: 91.8
  },
  {
    month: 20,
    l: 1,
    m: 84.1996,
    s: 0.03342,
    sd: 2.814,
    p01: 75.5,
    p1: 77.7,
    p3: 78.9,
    p5: 79.6,
    p10: 80.6,
    p15: 81.3,
    p25: 82.3,
    p50: 84.2,
    p75: 86.1,
    p85: 87.1,
    p90: 87.8,
    p95: 88.8,
    p97: 89.5,
    p99: 90.7,
    p999: 92.9
  },
  {
    month: 21,
    l: 1,
    m: 85.1348,
    s: 0.03376,
    sd: 2.8742,
    p01: 76.3,
    p1: 78.4,
    p3: 79.7,
    p5: 80.4,
    p10: 81.5,
    p15: 82.2,
    p25: 83.2,
    p50: 85.1,
    p75: 87.1,
    p85: 88.1,
    p90: 88.8,
    p95: 89.9,
    p97: 90.5,
    p99: 91.8,
    p999: 94
  },
  {
    month: 22,
    l: 1,
    m: 86.0477,
    s: 0.0341,
    sd: 2.9342,
    p01: 77,
    p1: 79.2,
    p3: 80.5,
    p5: 81.2,
    p10: 82.3,
    p15: 83,
    p25: 84.1,
    p50: 86,
    p75: 88,
    p85: 89.1,
    p90: 89.8,
    p95: 90.9,
    p97: 91.6,
    p99: 92.9,
    p999: 95.1
  },
  {
    month: 23,
    l: 1,
    m: 86.941,
    s: 0.03445,
    sd: 2.9951,
    p01: 77.7,
    p1: 80,
    p3: 81.3,
    p5: 82,
    p10: 83.1,
    p15: 83.8,
    p25: 84.9,
    p50: 86.9,
    p75: 89,
    p85: 90,
    p90: 90.8,
    p95: 91.9,
    p97: 92.6,
    p99: 93.9,
    p999: 96.2
  },
  {
    month: 24,
    l: 1,
    m: 87.8161,
    s: 0.03479,
    sd: 3.0551,
    p01: 78.4,
    p1: 80.7,
    p3: 82.1,
    p5: 82.8,
    p10: 83.9,
    p15: 84.6,
    p25: 85.8,
    p50: 87.8,
    p75: 89.9,
    p85: 91,
    p90: 91.7,
    p95: 92.8,
    p97: 93.6,
    p99: 94.9,
    p999: 97.3
  },
  {
    month: 25,
    l: 1,
    m: 87.972,
    s: 0.03542,
    sd: 3.116,
    p01: 78.3,
    p1: 80.7,
    p3: 82.1,
    p5: 82.8,
    p10: 84,
    p15: 84.7,
    p25: 85.9,
    p50: 88,
    p75: 90.1,
    p85: 91.2,
    p90: 92,
    p95: 93.1,
    p97: 93.8,
    p99: 95.2,
    p999: 97.6
  },
  {
    month: 26,
    l: 1,
    m: 88.8065,
    s: 0.03576,
    sd: 3.1757,
    p01: 79,
    p1: 81.4,
    p3: 82.8,
    p5: 83.6,
    p10: 84.7,
    p15: 85.5,
    p25: 86.7,
    p50: 88.8,
    p75: 90.9,
    p85: 92.1,
    p90: 92.9,
    p95: 94,
    p97: 94.8,
    p99: 96.2,
    p999: 98.6
  },
  {
    month: 27,
    l: 1,
    m: 89.6197,
    s: 0.0361,
    sd: 3.2353,
    p01: 79.6,
    p1: 82.1,
    p3: 83.5,
    p5: 84.3,
    p10: 85.5,
    p15: 86.3,
    p25: 87.4,
    p50: 89.6,
    p75: 91.8,
    p85: 93,
    p90: 93.8,
    p95: 94.9,
    p97: 95.7,
    p99: 97.1,
    p999: 99.6
  },
  {
    month: 28,
    l: 1,
    m: 90.412,
    s: 0.03642,
    sd: 3.2928,
    p01: 80.2,
    p1: 82.8,
    p3: 84.2,
    p5: 85,
    p10: 86.2,
    p15: 87,
    p25: 88.2,
    p50: 90.4,
    p75: 92.6,
    p85: 93.8,
    p90: 94.6,
    p95: 95.8,
    p97: 96.6,
    p99: 98.1,
    p999: 100.6
  },
  {
    month: 29,
    l: 1,
    m: 91.1828,
    s: 0.03674,
    sd: 3.3501,
    p01: 80.8,
    p1: 83.4,
    p3: 84.9,
    p5: 85.7,
    p10: 86.9,
    p15: 87.7,
    p25: 88.9,
    p50: 91.2,
    p75: 93.4,
    p85: 94.7,
    p90: 95.5,
    p95: 96.7,
    p97: 97.5,
    p99: 99,
    p999: 101.5
  },
  {
    month: 30,
    l: 1,
    m: 91.9327,
    s: 0.03704,
    sd: 3.4052,
    p01: 81.4,
    p1: 84,
    p3: 85.5,
    p5: 86.3,
    p10: 87.6,
    p15: 88.4,
    p25: 89.6,
    p50: 91.9,
    p75: 94.2,
    p85: 95.5,
    p90: 96.3,
    p95: 97.5,
    p97: 98.3,
    p99: 99.9,
    p999: 102.5
  },
  {
    month: 31,
    l: 1,
    m: 92.6631,
    s: 0.03733,
    sd: 3.4591,
    p01: 82,
    p1: 84.6,
    p3: 86.2,
    p5: 87,
    p10: 88.2,
    p15: 89.1,
    p25: 90.3,
    p50: 92.7,
    p75: 95,
    p85: 96.2,
    p90: 97.1,
    p95: 98.4,
    p97: 99.2,
    p99: 100.7,
    p999: 103.4
  },
  {
    month: 32,
    l: 1,
    m: 93.3753,
    s: 0.03761,
    sd: 3.5118,
    p01: 82.5,
    p1: 85.2,
    p3: 86.8,
    p5: 87.6,
    p10: 88.9,
    p15: 89.7,
    p25: 91,
    p50: 93.4,
    p75: 95.7,
    p85: 97,
    p90: 97.9,
    p95: 99.2,
    p97: 100,
    p99: 101.5,
    p999: 104.2
  },
  {
    month: 33,
    l: 1,
    m: 94.0711,
    s: 0.03787,
    sd: 3.5625,
    p01: 83.1,
    p1: 85.8,
    p3: 87.4,
    p5: 88.2,
    p10: 89.5,
    p15: 90.4,
    p25: 91.7,
    p50: 94.1,
    p75: 96.5,
    p85: 97.8,
    p90: 98.6,
    p95: 99.9,
    p97: 100.8,
    p99: 102.4,
    p999: 105.1
  },
  {
    month: 34,
    l: 1,
    m: 94.7532,
    s: 0.03812,
    sd: 3.612,
    p01: 83.6,
    p1: 86.4,
    p3: 88,
    p5: 88.8,
    p10: 90.1,
    p15: 91,
    p25: 92.3,
    p50: 94.8,
    p75: 97.2,
    p85: 98.5,
    p90: 99.4,
    p95: 100.7,
    p97: 101.5,
    p99: 103.2,
    p999: 105.9
  },
  {
    month: 35,
    l: 1,
    m: 95.4236,
    s: 0.03836,
    sd: 3.6604,
    p01: 84.1,
    p1: 86.9,
    p3: 88.5,
    p5: 89.4,
    p10: 90.7,
    p15: 91.6,
    p25: 93,
    p50: 95.4,
    p75: 97.9,
    p85: 99.2,
    p90: 100.1,
    p95: 101.4,
    p97: 102.3,
    p99: 103.9,
    p999: 106.7
  },
  {
    month: 36,
    l: 1,
    m: 96.0835,
    s: 0.03858,
    sd: 3.7069,
    p01: 84.6,
    p1: 87.5,
    p3: 89.1,
    p5: 90,
    p10: 91.3,
    p15: 92.2,
    p25: 93.6,
    p50: 96.1,
    p75: 98.6,
    p85: 99.9,
    p90: 100.8,
    p95: 102.2,
    p97: 103.1,
    p99: 104.7,
    p999: 107.5
  },
  {
    month: 37,
    l: 1,
    m: 96.7337,
    s: 0.03879,
    sd: 3.7523,
    p01: 85.1,
    p1: 88,
    p3: 89.7,
    p5: 90.6,
    p10: 91.9,
    p15: 92.8,
    p25: 94.2,
    p50: 96.7,
    p75: 99.3,
    p85: 100.6,
    p90: 101.5,
    p95: 102.9,
    p97: 103.8,
    p99: 105.5,
    p999: 108.3
  },
  {
    month: 38,
    l: 1,
    m: 97.3749,
    s: 0.039,
    sd: 3.7976,
    p01: 85.6,
    p1: 88.5,
    p3: 90.2,
    p5: 91.1,
    p10: 92.5,
    p15: 93.4,
    p25: 94.8,
    p50: 97.4,
    p75: 99.9,
    p85: 101.3,
    p90: 102.2,
    p95: 103.6,
    p97: 104.5,
    p99: 106.2,
    p999: 109.1
  },
  {
    month: 39,
    l: 1,
    m: 98.0073,
    s: 0.03919,
    sd: 3.8409,
    p01: 86.1,
    p1: 89.1,
    p3: 90.8,
    p5: 91.7,
    p10: 93.1,
    p15: 94,
    p25: 95.4,
    p50: 98,
    p75: 100.6,
    p85: 102,
    p90: 102.9,
    p95: 104.3,
    p97: 105.2,
    p99: 106.9,
    p999: 109.9
  },
  {
    month: 40,
    l: 1,
    m: 98.631,
    s: 0.03937,
    sd: 3.8831,
    p01: 86.6,
    p1: 89.6,
    p3: 91.3,
    p5: 92.2,
    p10: 93.7,
    p15: 94.6,
    p25: 96,
    p50: 98.6,
    p75: 101.3,
    p85: 102.7,
    p90: 103.6,
    p95: 105,
    p97: 105.9,
    p99: 107.7,
    p999: 110.6
  },
  {
    month: 41,
    l: 1,
    m: 99.2459,
    s: 0.03954,
    sd: 3.9242,
    p01: 87.1,
    p1: 90.1,
    p3: 91.9,
    p5: 92.8,
    p10: 94.2,
    p15: 95.2,
    p25: 96.6,
    p50: 99.2,
    p75: 101.9,
    p85: 103.3,
    p90: 104.3,
    p95: 105.7,
    p97: 106.6,
    p99: 108.4,
    p999: 111.4
  },
  {
    month: 42,
    l: 1,
    m: 99.8515,
    s: 0.03971,
    sd: 3.9651,
    p01: 87.6,
    p1: 90.6,
    p3: 92.4,
    p5: 93.3,
    p10: 94.8,
    p15: 95.7,
    p25: 97.2,
    p50: 99.9,
    p75: 102.5,
    p85: 104,
    p90: 104.9,
    p95: 106.4,
    p97: 107.3,
    p99: 109.1,
    p999: 112.1
  },
  {
    month: 43,
    l: 1,
    m: 100.4485,
    s: 0.03986,
    sd: 4.0039,
    p01: 88.1,
    p1: 91.1,
    p3: 92.9,
    p5: 93.9,
    p10: 95.3,
    p15: 96.3,
    p25: 97.7,
    p50: 100.4,
    p75: 103.1,
    p85: 104.6,
    p90: 105.6,
    p95: 107,
    p97: 108,
    p99: 109.8,
    p999: 112.8
  },
  {
    month: 44,
    l: 1,
    m: 101.0374,
    s: 0.04002,
    sd: 4.0435,
    p01: 88.5,
    p1: 91.6,
    p3: 93.4,
    p5: 94.4,
    p10: 95.9,
    p15: 96.8,
    p25: 98.3,
    p50: 101,
    p75: 103.8,
    p85: 105.2,
    p90: 106.2,
    p95: 107.7,
    p97: 108.6,
    p99: 110.4,
    p999: 113.5
  },
  {
    month: 45,
    l: 1,
    m: 101.6186,
    s: 0.04016,
    sd: 4.081,
    p01: 89,
    p1: 92.1,
    p3: 93.9,
    p5: 94.9,
    p10: 96.4,
    p15: 97.4,
    p25: 98.9,
    p50: 101.6,
    p75: 104.4,
    p85: 105.8,
    p90: 106.8,
    p95: 108.3,
    p97: 109.3,
    p99: 111.1,
    p999: 114.2
  },
  {
    month: 46,
    l: 1,
    m: 102.1933,
    s: 0.04031,
    sd: 4.1194,
    p01: 89.5,
    p1: 92.6,
    p3: 94.4,
    p5: 95.4,
    p10: 96.9,
    p15: 97.9,
    p25: 99.4,
    p50: 102.2,
    p75: 105,
    p85: 106.5,
    p90: 107.5,
    p95: 109,
    p97: 109.9,
    p99: 111.8,
    p999: 114.9
  },
  {
    month: 47,
    l: 1,
    m: 102.7625,
    s: 0.04045,
    sd: 4.1567,
    p01: 89.9,
    p1: 93.1,
    p3: 94.9,
    p5: 95.9,
    p10: 97.4,
    p15: 98.5,
    p25: 100,
    p50: 102.8,
    p75: 105.6,
    p85: 107.1,
    p90: 108.1,
    p95: 109.6,
    p97: 110.6,
    p99: 112.4,
    p999: 115.6
  },
  {
    month: 48,
    l: 1,
    m: 103.3273,
    s: 0.04059,
    sd: 4.1941,
    p01: 90.4,
    p1: 93.6,
    p3: 95.4,
    p5: 96.4,
    p10: 98,
    p15: 99,
    p25: 100.5,
    p50: 103.3,
    p75: 106.2,
    p85: 107.7,
    p90: 108.7,
    p95: 110.2,
    p97: 111.2,
    p99: 113.1,
    p999: 116.3
  },
  {
    month: 49,
    l: 1,
    m: 103.8886,
    s: 0.04073,
    sd: 4.2314,
    p01: 90.8,
    p1: 94,
    p3: 95.9,
    p5: 96.9,
    p10: 98.5,
    p15: 99.5,
    p25: 101,
    p50: 103.9,
    p75: 106.7,
    p85: 108.3,
    p90: 109.3,
    p95: 110.8,
    p97: 111.8,
    p99: 113.7,
    p999: 117
  },
  {
    month: 50,
    l: 1,
    m: 104.4473,
    s: 0.04086,
    sd: 4.2677,
    p01: 91.3,
    p1: 94.5,
    p3: 96.4,
    p5: 97.4,
    p10: 99,
    p15: 100,
    p25: 101.6,
    p50: 104.4,
    p75: 107.3,
    p85: 108.9,
    p90: 109.9,
    p95: 111.5,
    p97: 112.5,
    p99: 114.4,
    p999: 117.6
  },
  {
    month: 51,
    l: 1,
    m: 105.0041,
    s: 0.041,
    sd: 4.3052,
    p01: 91.7,
    p1: 95,
    p3: 96.9,
    p5: 97.9,
    p10: 99.5,
    p15: 100.5,
    p25: 102.1,
    p50: 105,
    p75: 107.9,
    p85: 109.5,
    p90: 110.5,
    p95: 112.1,
    p97: 113.1,
    p99: 115,
    p999: 118.3
  },
  {
    month: 52,
    l: 1,
    m: 105.5596,
    s: 0.04113,
    sd: 4.3417,
    p01: 92.1,
    p1: 95.5,
    p3: 97.4,
    p5: 98.4,
    p10: 100,
    p15: 101.1,
    p25: 102.6,
    p50: 105.6,
    p75: 108.5,
    p85: 110.1,
    p90: 111.1,
    p95: 112.7,
    p97: 113.7,
    p99: 115.7,
    p999: 119
  },
  {
    month: 53,
    l: 1,
    m: 106.1138,
    s: 0.04126,
    sd: 4.3783,
    p01: 92.6,
    p1: 95.9,
    p3: 97.9,
    p5: 98.9,
    p10: 100.5,
    p15: 101.6,
    p25: 103.2,
    p50: 106.1,
    p75: 109.1,
    p85: 110.7,
    p90: 111.7,
    p95: 113.3,
    p97: 114.3,
    p99: 116.3,
    p999: 119.6
  },
  {
    month: 54,
    l: 1,
    m: 106.6668,
    s: 0.04139,
    sd: 4.4149,
    p01: 93,
    p1: 96.4,
    p3: 98.4,
    p5: 99.4,
    p10: 101,
    p15: 102.1,
    p25: 103.7,
    p50: 106.7,
    p75: 109.6,
    p85: 111.2,
    p90: 112.3,
    p95: 113.9,
    p97: 115,
    p99: 116.9,
    p999: 120.3
  },
  {
    month: 55,
    l: 1,
    m: 107.2188,
    s: 0.04152,
    sd: 4.4517,
    p01: 93.5,
    p1: 96.9,
    p3: 98.8,
    p5: 99.9,
    p10: 101.5,
    p15: 102.6,
    p25: 104.2,
    p50: 107.2,
    p75: 110.2,
    p85: 111.8,
    p90: 112.9,
    p95: 114.5,
    p97: 115.6,
    p99: 117.6,
    p999: 121
  },
  {
    month: 56,
    l: 1,
    m: 107.7697,
    s: 0.04165,
    sd: 4.4886,
    p01: 93.9,
    p1: 97.3,
    p3: 99.3,
    p5: 100.4,
    p10: 102,
    p15: 103.1,
    p25: 104.7,
    p50: 107.8,
    p75: 110.8,
    p85: 112.4,
    p90: 113.5,
    p95: 115.2,
    p97: 116.2,
    p99: 118.2,
    p999: 121.6
  },
  {
    month: 57,
    l: 1,
    m: 108.3198,
    s: 0.04177,
    sd: 4.5245,
    p01: 94.3,
    p1: 97.8,
    p3: 99.8,
    p5: 100.9,
    p10: 102.5,
    p15: 103.6,
    p25: 105.3,
    p50: 108.3,
    p75: 111.4,
    p85: 113,
    p90: 114.1,
    p95: 115.8,
    p97: 116.8,
    p99: 118.8,
    p999: 122.3
  },
  {
    month: 58,
    l: 1,
    m: 108.8689,
    s: 0.0419,
    sd: 4.5616,
    p01: 94.8,
    p1: 98.3,
    p3: 100.3,
    p5: 101.4,
    p10: 103,
    p15: 104.1,
    p25: 105.8,
    p50: 108.9,
    p75: 111.9,
    p85: 113.6,
    p90: 114.7,
    p95: 116.4,
    p97: 117.4,
    p99: 119.5,
    p999: 123
  },
  {
    month: 59,
    l: 1,
    m: 109.417,
    s: 0.04202,
    sd: 4.5977,
    p01: 95.2,
    p1: 98.7,
    p3: 100.8,
    p5: 101.9,
    p10: 103.5,
    p15: 104.7,
    p25: 106.3,
    p50: 109.4,
    p75: 112.5,
    p85: 114.2,
    p90: 115.3,
    p95: 117,
    p97: 118.1,
    p99: 120.1,
    p999: 123.6
  },
  {
    month: 60,
    l: 1,
    m: 109.9638,
    s: 0.04214,
    sd: 4.6339,
    p01: 95.6,
    p1: 99.2,
    p3: 101.2,
    p5: 102.3,
    p10: 104,
    p15: 105.2,
    p25: 106.8,
    p50: 110,
    p75: 113.1,
    p85: 114.8,
    p90: 115.9,
    p95: 117.6,
    p97: 118.7,
    p99: 120.7,
    p999: 124.3
  },
  {
    month: 61,
    l: 1,
    m: 110.2647,
    s: 0.04164,
    sd: 4.5914,
    p01: 96.076,
    p1: 99.583,
    p3: 101.629,
    p5: 102.712,
    p10: 104.381,
    p15: 105.506,
    p25: 107.168,
    p50: 110.265,
    p75: 113.362,
    p85: 115.023,
    p90: 116.149,
    p95: 117.817,
    p97: 118.9,
    p99: 120.946,
    p999: 124.453
  },
  {
    month: 62,
    l: 1,
    m: 110.8006,
    s: 0.04172,
    sd: 4.6226,
    p01: 96.516,
    p1: 100.047,
    p3: 102.106,
    p5: 103.197,
    p10: 104.876,
    p15: 106.01,
    p25: 107.683,
    p50: 110.801,
    p75: 113.918,
    p85: 115.592,
    p90: 116.725,
    p95: 118.404,
    p97: 119.495,
    p99: 121.554,
    p999: 125.086
  },
  {
    month: 63,
    l: 1,
    m: 111.3338,
    s: 0.0418,
    sd: 4.6538,
    p01: 96.953,
    p1: 100.508,
    p3: 102.581,
    p5: 103.679,
    p10: 105.37,
    p15: 106.51,
    p25: 108.195,
    p50: 111.334,
    p75: 114.473,
    p85: 116.157,
    p90: 117.298,
    p95: 118.989,
    p97: 120.087,
    p99: 122.16,
    p999: 125.715
  },
  {
    month: 64,
    l: 1,
    m: 111.8636,
    s: 0.04187,
    sd: 4.6837,
    p01: 97.39,
    p1: 100.968,
    p3: 103.054,
    p5: 104.16,
    p10: 105.861,
    p15: 107.009,
    p25: 108.704,
    p50: 111.864,
    p75: 115.023,
    p85: 116.718,
    p90: 117.866,
    p95: 119.568,
    p97: 120.673,
    p99: 122.76,
    p999: 126.337
  },
  {
    month: 65,
    l: 1,
    m: 112.3895,
    s: 0.04195,
    sd: 4.7147,
    p01: 97.82,
    p1: 101.421,
    p3: 103.522,
    p5: 104.634,
    p10: 106.347,
    p15: 107.503,
    p25: 109.209,
    p50: 112.39,
    p75: 115.57,
    p85: 117.276,
    p90: 118.432,
    p95: 120.145,
    p97: 121.257,
    p99: 123.358,
    p999: 126.959
  },
  {
    month: 66,
    l: 1,
    m: 112.911,
    s: 0.04203,
    sd: 4.7456,
    p01: 98.246,
    p1: 101.871,
    p3: 103.985,
    p5: 105.105,
    p10: 106.829,
    p15: 107.992,
    p25: 109.71,
    p50: 112.911,
    p75: 116.112,
    p85: 117.83,
    p90: 118.993,
    p95: 120.717,
    p97: 121.837,
    p99: 123.951,
    p999: 127.576
  },
  {
    month: 67,
    l: 1,
    m: 113.428,
    s: 0.04211,
    sd: 4.7765,
    p01: 98.668,
    p1: 102.316,
    p3: 104.444,
    p5: 105.571,
    p10: 107.307,
    p15: 108.478,
    p25: 110.206,
    p50: 113.428,
    p75: 116.65,
    p85: 118.378,
    p90: 119.549,
    p95: 121.285,
    p97: 122.412,
    p99: 124.54,
    p999: 128.188
  },
  {
    month: 68,
    l: 1,
    m: 113.941,
    s: 0.04218,
    sd: 4.806,
    p01: 99.089,
    p1: 102.76,
    p3: 104.902,
    p5: 106.036,
    p10: 107.782,
    p15: 108.96,
    p25: 110.699,
    p50: 113.941,
    p75: 117.183,
    p85: 118.922,
    p90: 120.1,
    p95: 121.846,
    p97: 122.98,
    p99: 125.122,
    p999: 128.793
  },
  {
    month: 69,
    l: 1,
    m: 114.45,
    s: 0.04226,
    sd: 4.8367,
    p01: 99.504,
    p1: 103.198,
    p3: 105.353,
    p5: 106.494,
    p10: 108.252,
    p15: 109.437,
    p25: 111.188,
    p50: 114.45,
    p75: 117.712,
    p85: 119.463,
    p90: 120.648,
    p95: 122.406,
    p97: 123.547,
    p99: 125.702,
    p999: 129.396
  },
  {
    month: 70,
    l: 1,
    m: 114.9547,
    s: 0.04234,
    sd: 4.8672,
    p01: 99.914,
    p1: 103.632,
    p3: 105.801,
    p5: 106.949,
    p10: 108.717,
    p15: 109.91,
    p25: 111.672,
    p50: 114.955,
    p75: 118.238,
    p85: 119.999,
    p90: 121.192,
    p95: 122.961,
    p97: 124.109,
    p99: 126.277,
    p999: 129.995
  },
  {
    month: 71,
    l: 1,
    m: 115.4549,
    s: 0.04241,
    sd: 4.8964,
    p01: 100.324,
    p1: 104.064,
    p3: 106.246,
    p5: 107.401,
    p10: 109.18,
    p15: 110.38,
    p25: 112.152,
    p50: 115.455,
    p75: 118.758,
    p85: 120.53,
    p90: 121.73,
    p95: 123.509,
    p97: 124.664,
    p99: 126.846,
    p999: 130.586
  },
  {
    month: 72,
    l: 1,
    m: 115.9509,
    s: 0.04249,
    sd: 4.9268,
    p01: 100.726,
    p1: 104.49,
    p3: 106.685,
    p5: 107.847,
    p10: 109.637,
    p15: 110.845,
    p25: 112.628,
    p50: 115.951,
    p75: 119.274,
    p85: 121.057,
    p90: 122.265,
    p95: 124.055,
    p97: 125.217,
    p99: 127.412,
    p999: 131.176
  },
  {
    month: 73,
    l: 1,
    m: 116.4432,
    s: 0.04257,
    sd: 4.957,
    p01: 101.125,
    p1: 104.912,
    p3: 107.12,
    p5: 108.29,
    p10: 110.091,
    p15: 111.306,
    p25: 113.1,
    p50: 116.443,
    p75: 119.787,
    p85: 121.581,
    p90: 122.796,
    p95: 124.597,
    p97: 125.766,
    p99: 127.975,
    p999: 131.761
  },
  {
    month: 74,
    l: 1,
    m: 116.9325,
    s: 0.04264,
    sd: 4.986,
    p01: 101.525,
    p1: 105.333,
    p3: 107.555,
    p5: 108.731,
    p10: 110.543,
    p15: 111.765,
    p25: 113.569,
    p50: 116.932,
    p75: 120.296,
    p85: 122.1,
    p90: 123.322,
    p95: 125.134,
    p97: 126.31,
    p99: 128.532,
    p999: 132.34
  },
  {
    month: 75,
    l: 1,
    m: 117.4196,
    s: 0.04272,
    sd: 5.0162,
    p01: 101.918,
    p1: 105.75,
    p3: 107.985,
    p5: 109.169,
    p10: 110.991,
    p15: 112.221,
    p25: 114.036,
    p50: 117.42,
    p75: 120.803,
    p85: 122.619,
    p90: 123.848,
    p95: 125.67,
    p97: 126.854,
    p99: 129.089,
    p999: 132.921
  },
  {
    month: 76,
    l: 1,
    m: 117.9046,
    s: 0.0428,
    sd: 5.0463,
    p01: 102.31,
    p1: 106.165,
    p3: 108.414,
    p5: 109.604,
    p10: 111.437,
    p15: 112.674,
    p25: 114.501,
    p50: 117.905,
    p75: 121.308,
    p85: 123.135,
    p90: 124.372,
    p95: 126.205,
    p97: 127.396,
    p99: 129.644,
    p999: 133.499
  },
  {
    month: 77,
    l: 1,
    m: 118.388,
    s: 0.04287,
    sd: 5.0753,
    p01: 102.704,
    p1: 106.581,
    p3: 108.842,
    p5: 110.04,
    p10: 111.884,
    p15: 113.128,
    p25: 114.965,
    p50: 118.388,
    p75: 121.811,
    p85: 123.648,
    p90: 124.892,
    p95: 126.736,
    p97: 127.934,
    p99: 130.195,
    p999: 134.072
  },
  {
    month: 78,
    l: 1,
    m: 118.87,
    s: 0.04295,
    sd: 5.1055,
    p01: 103.093,
    p1: 106.993,
    p3: 109.268,
    p5: 110.472,
    p10: 112.327,
    p15: 113.579,
    p25: 115.426,
    p50: 118.87,
    p75: 122.314,
    p85: 124.161,
    p90: 125.413,
    p95: 127.268,
    p97: 128.472,
    p99: 130.747,
    p999: 134.647
  },
  {
    month: 79,
    l: 1,
    m: 119.3508,
    s: 0.04303,
    sd: 5.1357,
    p01: 103.48,
    p1: 107.403,
    p3: 109.692,
    p5: 110.903,
    p10: 112.769,
    p15: 114.028,
    p25: 115.887,
    p50: 119.351,
    p75: 122.815,
    p85: 124.674,
    p90: 125.932,
    p95: 127.798,
    p97: 129.01,
    p99: 131.298,
    p999: 135.221
  },
  {
    month: 80,
    l: 1,
    m: 119.8303,
    s: 0.04311,
    sd: 5.1659,
    p01: 103.867,
    p1: 107.813,
    p3: 110.114,
    p5: 111.333,
    p10: 113.21,
    p15: 114.476,
    p25: 116.346,
    p50: 119.83,
    p75: 123.315,
    p85: 125.184,
    p90: 126.451,
    p95: 128.327,
    p97: 129.546,
    p99: 131.848,
    p999: 135.794
  },
  {
    month: 81,
    l: 1,
    m: 120.3085,
    s: 0.04318,
    sd: 5.1949,
    p01: 104.255,
    p1: 108.223,
    p3: 110.538,
    p5: 111.764,
    p10: 113.651,
    p15: 114.924,
    p25: 116.805,
    p50: 120.308,
    p75: 123.812,
    p85: 125.693,
    p90: 126.966,
    p95: 128.853,
    p97: 130.079,
    p99: 132.394,
    p999: 136.362
  },
  {
    month: 82,
    l: 1,
    m: 120.7853,
    s: 0.04326,
    sd: 5.2252,
    p01: 104.638,
    p1: 108.63,
    p3: 110.958,
    p5: 112.191,
    p10: 114.089,
    p15: 115.37,
    p25: 117.261,
    p50: 120.785,
    p75: 124.31,
    p85: 126.201,
    p90: 127.482,
    p95: 129.38,
    p97: 130.613,
    p99: 132.941,
    p999: 136.932
  },
  {
    month: 83,
    l: 1,
    m: 121.2604,
    s: 0.04334,
    sd: 5.2554,
    p01: 105.02,
    p1: 109.034,
    p3: 111.376,
    p5: 112.616,
    p10: 114.525,
    p15: 115.814,
    p25: 117.716,
    p50: 121.26,
    p75: 124.805,
    p85: 126.707,
    p90: 127.995,
    p95: 129.905,
    p97: 131.145,
    p99: 133.486,
    p999: 137.501
  },
  {
    month: 84,
    l: 1,
    m: 121.7338,
    s: 0.04342,
    sd: 5.2857,
    p01: 105.4,
    p1: 109.437,
    p3: 111.793,
    p5: 113.04,
    p10: 114.96,
    p15: 116.256,
    p25: 118.169,
    p50: 121.734,
    p75: 125.299,
    p85: 127.212,
    p90: 128.508,
    p95: 130.428,
    p97: 131.675,
    p99: 134.03,
    p999: 138.068
  },
  {
    month: 85,
    l: 1,
    m: 122.2053,
    s: 0.0435,
    sd: 5.3159,
    p01: 105.778,
    p1: 109.839,
    p3: 112.207,
    p5: 113.461,
    p10: 115.393,
    p15: 116.696,
    p25: 118.62,
    p50: 122.205,
    p75: 125.791,
    p85: 127.715,
    p90: 129.018,
    p95: 130.949,
    p97: 132.203,
    p99: 134.572,
    p999: 138.633
  },
  {
    month: 86,
    l: 1,
    m: 122.675,
    s: 0.04358,
    sd: 5.3462,
    p01: 106.154,
    p1: 110.238,
    p3: 112.62,
    p5: 113.881,
    p10: 115.824,
    p15: 117.134,
    p25: 119.069,
    p50: 122.675,
    p75: 126.281,
    p85: 128.216,
    p90: 129.526,
    p95: 131.469,
    p97: 132.73,
    p99: 135.112,
    p999: 139.196
  },
  {
    month: 87,
    l: 1,
    m: 123.1429,
    s: 0.04366,
    sd: 5.3764,
    p01: 106.529,
    p1: 110.635,
    p3: 113.031,
    p5: 114.299,
    p10: 116.253,
    p15: 117.571,
    p25: 119.517,
    p50: 123.143,
    p75: 126.769,
    p85: 128.715,
    p90: 130.033,
    p95: 131.986,
    p97: 133.255,
    p99: 135.65,
    p999: 139.757
  },
  {
    month: 88,
    l: 1,
    m: 123.6092,
    s: 0.04374,
    sd: 5.4067,
    p01: 106.901,
    p1: 111.031,
    p3: 113.44,
    p5: 114.716,
    p10: 116.68,
    p15: 118.006,
    p25: 119.962,
    p50: 123.609,
    p75: 127.256,
    p85: 129.213,
    p90: 130.538,
    p95: 132.502,
    p97: 133.778,
    p99: 136.187,
    p999: 140.317
  },
  {
    month: 89,
    l: 1,
    m: 124.0736,
    s: 0.04382,
    sd: 5.4369,
    p01: 107.272,
    p1: 111.425,
    p3: 113.848,
    p5: 115.131,
    p10: 117.106,
    p15: 118.439,
    p25: 120.406,
    p50: 124.074,
    p75: 127.741,
    p85: 129.709,
    p90: 131.041,
    p95: 133.017,
    p97: 134.299,
    p99: 136.722,
    p999: 140.875
  },
  {
    month: 90,
    l: 1,
    m: 124.5361,
    s: 0.0439,
    sd: 5.4671,
    p01: 107.641,
    p1: 111.818,
    p3: 114.254,
    p5: 115.543,
    p10: 117.53,
    p15: 118.87,
    p25: 120.849,
    p50: 124.536,
    p75: 128.224,
    p85: 130.202,
    p90: 131.543,
    p95: 133.529,
    p97: 134.819,
    p99: 137.255,
    p999: 141.431
  },
  {
    month: 91,
    l: 1,
    m: 124.9964,
    s: 0.04398,
    sd: 5.4973,
    p01: 108.008,
    p1: 112.208,
    p3: 114.657,
    p5: 115.954,
    p10: 117.951,
    p15: 119.299,
    p25: 121.288,
    p50: 124.996,
    p75: 128.704,
    p85: 130.694,
    p90: 132.042,
    p95: 134.039,
    p97: 135.336,
    p99: 137.785,
    p999: 141.984
  },
  {
    month: 92,
    l: 1,
    m: 125.4545,
    s: 0.04406,
    sd: 5.5275,
    p01: 108.373,
    p1: 112.596,
    p3: 115.058,
    p5: 116.363,
    p10: 118.371,
    p15: 119.726,
    p25: 121.726,
    p50: 125.454,
    p75: 129.183,
    p85: 131.183,
    p90: 132.538,
    p95: 134.546,
    p97: 135.851,
    p99: 138.313,
    p999: 142.536
  },
  {
    month: 93,
    l: 1,
    m: 125.9104,
    s: 0.04414,
    sd: 5.5577,
    p01: 108.736,
    p1: 112.981,
    p3: 115.458,
    p5: 116.769,
    p10: 118.788,
    p15: 120.15,
    p25: 122.162,
    p50: 125.91,
    p75: 129.659,
    p85: 131.671,
    p90: 133.033,
    p95: 135.052,
    p97: 136.363,
    p99: 138.84,
    p999: 143.085
  },
  {
    month: 94,
    l: 1,
    m: 126.364,
    s: 0.04422,
    sd: 5.5878,
    p01: 109.096,
    p1: 113.365,
    p3: 115.854,
    p5: 117.173,
    p10: 119.203,
    p15: 120.573,
    p25: 122.595,
    p50: 126.364,
    p75: 130.133,
    p85: 132.155,
    p90: 133.525,
    p95: 135.555,
    p97: 136.874,
    p99: 139.363,
    p999: 143.632
  },
  {
    month: 95,
    l: 1,
    m: 126.8156,
    s: 0.0443,
    sd: 5.6179,
    p01: 109.455,
    p1: 113.746,
    p3: 116.249,
    p5: 117.575,
    p10: 119.616,
    p15: 120.993,
    p25: 123.026,
    p50: 126.816,
    p75: 130.605,
    p85: 132.638,
    p90: 134.015,
    p95: 136.056,
    p97: 137.382,
    p99: 139.885,
    p999: 144.176
  },
  {
    month: 96,
    l: 1,
    m: 127.2651,
    s: 0.04438,
    sd: 5.648,
    p01: 109.811,
    p1: 114.126,
    p3: 116.642,
    p5: 117.975,
    p10: 120.027,
    p15: 121.411,
    p25: 123.456,
    p50: 127.265,
    p75: 131.075,
    p85: 133.119,
    p90: 134.503,
    p95: 136.555,
    p97: 137.888,
    p99: 140.404,
    p999: 144.719
  },
  {
    month: 97,
    l: 1,
    m: 127.7129,
    s: 0.04446,
    sd: 5.6781,
    p01: 110.166,
    p1: 114.504,
    p3: 117.034,
    p5: 118.373,
    p10: 120.436,
    p15: 121.828,
    p25: 123.883,
    p50: 127.713,
    p75: 131.543,
    p85: 133.598,
    p90: 134.99,
    p95: 137.053,
    p97: 138.392,
    p99: 140.922,
    p999: 145.26
  },
  {
    month: 98,
    l: 1,
    m: 128.159,
    s: 0.04454,
    sd: 5.7082,
    p01: 110.519,
    p1: 114.88,
    p3: 117.423,
    p5: 118.77,
    p10: 120.844,
    p15: 122.243,
    p25: 124.309,
    p50: 128.159,
    p75: 132.009,
    p85: 134.075,
    p90: 135.474,
    p95: 137.548,
    p97: 138.895,
    p99: 141.438,
    p999: 145.799
  },
  {
    month: 99,
    l: 1,
    m: 128.6034,
    s: 0.04462,
    sd: 5.7383,
    p01: 110.871,
    p1: 115.254,
    p3: 117.811,
    p5: 119.165,
    p10: 121.249,
    p15: 122.656,
    p25: 124.733,
    p50: 128.603,
    p75: 132.474,
    p85: 134.551,
    p90: 135.957,
    p95: 138.042,
    p97: 139.396,
    p99: 141.953,
    p999: 146.336
  },
  {
    month: 100,
    l: 1,
    m: 129.0466,
    s: 0.0447,
    sd: 5.7684,
    p01: 111.221,
    p1: 115.627,
    p3: 118.197,
    p5: 119.558,
    p10: 121.654,
    p15: 123.068,
    p25: 125.156,
    p50: 129.047,
    p75: 132.937,
    p85: 135.025,
    p90: 136.439,
    p95: 138.535,
    p97: 139.896,
    p99: 142.466,
    p999: 146.872
  },
  {
    month: 101,
    l: 1,
    m: 129.4887,
    s: 0.04478,
    sd: 5.7985,
    p01: 111.57,
    p1: 115.999,
    p3: 118.583,
    p5: 119.951,
    p10: 122.058,
    p15: 123.479,
    p25: 125.578,
    p50: 129.489,
    p75: 133.4,
    p85: 135.498,
    p90: 136.92,
    p95: 139.026,
    p97: 140.394,
    p99: 142.978,
    p999: 147.407
  },
  {
    month: 102,
    l: 1,
    m: 129.93,
    s: 0.04487,
    sd: 5.83,
    p01: 111.914,
    p1: 116.367,
    p3: 118.965,
    p5: 120.341,
    p10: 122.459,
    p15: 123.888,
    p25: 125.998,
    p50: 129.93,
    p75: 133.862,
    p85: 135.972,
    p90: 137.401,
    p95: 139.519,
    p97: 140.895,
    p99: 143.493,
    p999: 147.946
  },
  {
    month: 103,
    l: 1,
    m: 130.3705,
    s: 0.04495,
    sd: 5.8602,
    p01: 112.261,
    p1: 116.738,
    p3: 119.349,
    p5: 120.731,
    p10: 122.86,
    p15: 124.297,
    p25: 126.418,
    p50: 130.37,
    p75: 134.323,
    p85: 136.444,
    p90: 137.881,
    p95: 140.01,
    p97: 141.392,
    p99: 144.003,
    p999: 148.48
  },
  {
    month: 104,
    l: 1,
    m: 130.8103,
    s: 0.04503,
    sd: 5.8904,
    p01: 112.608,
    p1: 117.107,
    p3: 119.732,
    p5: 121.121,
    p10: 123.261,
    p15: 124.705,
    p25: 126.837,
    p50: 130.81,
    p75: 134.783,
    p85: 136.915,
    p90: 138.359,
    p95: 140.499,
    p97: 141.889,
    p99: 144.513,
    p999: 149.013
  },
  {
    month: 105,
    l: 1,
    m: 131.2495,
    s: 0.04511,
    sd: 5.9207,
    p01: 112.953,
    p1: 117.476,
    p3: 120.114,
    p5: 121.511,
    p10: 123.662,
    p15: 125.113,
    p25: 127.256,
    p50: 131.25,
    p75: 135.243,
    p85: 137.386,
    p90: 138.837,
    p95: 140.988,
    p97: 142.385,
    p99: 145.023,
    p999: 149.546
  },
  {
    month: 106,
    l: 1,
    m: 131.6884,
    s: 0.04519,
    sd: 5.951,
    p01: 113.298,
    p1: 117.844,
    p3: 120.496,
    p5: 121.9,
    p10: 124.062,
    p15: 125.521,
    p25: 127.675,
    p50: 131.688,
    p75: 135.702,
    p85: 137.856,
    p90: 139.315,
    p95: 141.477,
    p97: 142.881,
    p99: 145.532,
    p999: 150.078
  },
  {
    month: 107,
    l: 1,
    m: 132.1269,
    s: 0.04527,
    sd: 5.9814,
    p01: 113.643,
    p1: 118.212,
    p3: 120.877,
    p5: 122.288,
    p10: 124.461,
    p15: 125.928,
    p25: 128.093,
    p50: 132.127,
    p75: 136.161,
    p85: 138.326,
    p90: 139.792,
    p95: 141.965,
    p97: 143.377,
    p99: 146.042,
    p999: 150.611
  },
  {
    month: 108,
    l: 1,
    m: 132.5652,
    s: 0.04535,
    sd: 6.0118,
    p01: 113.987,
    p1: 118.58,
    p3: 121.258,
    p5: 122.677,
    p10: 124.861,
    p15: 126.334,
    p25: 128.51,
    p50: 132.565,
    p75: 136.62,
    p85: 138.796,
    p90: 140.27,
    p95: 142.454,
    p97: 143.872,
    p99: 146.551,
    p999: 151.143
  },
  {
    month: 109,
    l: 1,
    m: 133.0031,
    s: 0.04543,
    sd: 6.0423,
    p01: 114.331,
    p1: 118.947,
    p3: 121.639,
    p5: 123.064,
    p10: 125.26,
    p15: 126.741,
    p25: 128.928,
    p50: 133.003,
    p75: 137.079,
    p85: 139.266,
    p90: 140.747,
    p95: 142.942,
    p97: 144.367,
    p99: 147.06,
    p999: 151.675
  },
  {
    month: 110,
    l: 1,
    m: 133.4404,
    s: 0.04551,
    sd: 6.0729,
    p01: 114.674,
    p1: 119.313,
    p3: 122.019,
    p5: 123.451,
    p10: 125.658,
    p15: 127.146,
    p25: 129.344,
    p50: 133.44,
    p75: 137.536,
    p85: 139.735,
    p90: 141.223,
    p95: 143.429,
    p97: 144.862,
    p99: 147.568,
    p999: 152.207
  },
  {
    month: 111,
    l: 1,
    m: 133.877,
    s: 0.04559,
    sd: 6.1035,
    p01: 115.016,
    p1: 119.678,
    p3: 122.398,
    p5: 123.838,
    p10: 126.055,
    p15: 127.551,
    p25: 129.76,
    p50: 133.877,
    p75: 137.994,
    p85: 140.203,
    p90: 141.699,
    p95: 143.916,
    p97: 145.356,
    p99: 148.076,
    p999: 152.738
  },
  {
    month: 112,
    l: 1,
    m: 134.313,
    s: 0.04566,
    sd: 6.1327,
    p01: 115.361,
    p1: 120.046,
    p3: 122.779,
    p5: 124.226,
    p10: 126.454,
    p15: 127.957,
    p25: 130.177,
    p50: 134.313,
    p75: 138.449,
    p85: 140.669,
    p90: 142.172,
    p95: 144.4,
    p97: 145.847,
    p99: 148.58,
    p999: 153.265
  },
  {
    month: 113,
    l: 1,
    m: 134.7483,
    s: 0.04574,
    sd: 6.1634,
    p01: 115.702,
    p1: 120.41,
    p3: 123.156,
    p5: 124.61,
    p10: 126.85,
    p15: 128.36,
    p25: 130.591,
    p50: 134.748,
    p75: 138.905,
    p85: 141.136,
    p90: 142.647,
    p95: 144.886,
    p97: 146.34,
    p99: 149.086,
    p999: 153.795
  },
  {
    month: 114,
    l: 1,
    m: 135.1829,
    s: 0.04582,
    sd: 6.1941,
    p01: 116.042,
    p1: 120.773,
    p3: 123.533,
    p5: 124.995,
    p10: 127.245,
    p15: 128.763,
    p25: 131.005,
    p50: 135.183,
    p75: 139.361,
    p85: 141.603,
    p90: 143.121,
    p95: 145.371,
    p97: 146.833,
    p99: 149.592,
    p999: 154.324
  },
  {
    month: 115,
    l: 1,
    m: 135.6168,
    s: 0.04589,
    sd: 6.2235,
    p01: 116.385,
    p1: 121.139,
    p3: 123.912,
    p5: 125.38,
    p10: 127.641,
    p15: 129.167,
    p25: 131.419,
    p50: 135.617,
    p75: 139.814,
    p85: 142.067,
    p90: 143.592,
    p95: 145.853,
    p97: 147.322,
    p99: 150.095,
    p999: 154.849
  },
  {
    month: 116,
    l: 1,
    m: 136.0501,
    s: 0.04597,
    sd: 6.2542,
    p01: 116.723,
    p1: 121.501,
    p3: 124.287,
    p5: 125.763,
    p10: 128.035,
    p15: 129.568,
    p25: 131.832,
    p50: 136.05,
    p75: 140.269,
    p85: 142.532,
    p90: 144.065,
    p95: 146.337,
    p97: 147.813,
    p99: 150.6,
    p999: 155.377
  },
  {
    month: 117,
    l: 1,
    m: 136.4829,
    s: 0.04604,
    sd: 6.2837,
    p01: 117.065,
    p1: 121.865,
    p3: 124.665,
    p5: 126.147,
    p10: 128.43,
    p15: 129.97,
    p25: 132.245,
    p50: 136.483,
    p75: 140.721,
    p85: 142.996,
    p90: 144.536,
    p95: 146.819,
    p97: 148.301,
    p99: 151.101,
    p999: 155.901
  },
  {
    month: 118,
    l: 1,
    m: 136.9153,
    s: 0.04612,
    sd: 6.3145,
    p01: 117.402,
    p1: 122.225,
    p3: 125.039,
    p5: 126.529,
    p10: 128.823,
    p15: 130.371,
    p25: 132.656,
    p50: 136.915,
    p75: 141.174,
    p85: 143.46,
    p90: 145.008,
    p95: 147.302,
    p97: 148.792,
    p99: 151.605,
    p999: 156.429
  },
  {
    month: 119,
    l: 1,
    m: 137.3474,
    s: 0.04619,
    sd: 6.3441,
    p01: 117.743,
    p1: 122.589,
    p3: 125.416,
    p5: 126.912,
    p10: 129.217,
    p15: 130.772,
    p25: 133.068,
    p50: 137.347,
    p75: 141.626,
    p85: 143.923,
    p90: 145.478,
    p95: 147.782,
    p97: 149.279,
    p99: 152.106,
    p999: 156.952
  },
  {
    month: 120,
    l: 1,
    m: 137.7795,
    s: 0.04626,
    sd: 6.3737,
    p01: 118.083,
    p1: 122.952,
    p3: 125.792,
    p5: 127.296,
    p10: 129.611,
    p15: 131.174,
    p25: 133.481,
    p50: 137.78,
    p75: 142.078,
    p85: 144.385,
    p90: 145.948,
    p95: 148.263,
    p97: 149.767,
    p99: 152.607,
    p999: 157.476
  },
  {
    month: 121,
    l: 1,
    m: 138.2119,
    s: 0.04633,
    sd: 6.4034,
    p01: 118.424,
    p1: 123.315,
    p3: 126.169,
    p5: 127.679,
    p10: 130.006,
    p15: 131.575,
    p25: 133.893,
    p50: 138.212,
    p75: 142.531,
    p85: 144.849,
    p90: 146.418,
    p95: 148.744,
    p97: 150.255,
    p99: 153.108,
    p999: 158
  },
  {
    month: 122,
    l: 1,
    m: 138.6452,
    s: 0.0464,
    sd: 6.4331,
    p01: 118.765,
    p1: 123.679,
    p3: 126.546,
    p5: 128.064,
    p10: 130.401,
    p15: 131.978,
    p25: 134.306,
    p50: 138.645,
    p75: 142.984,
    p85: 145.313,
    p90: 146.89,
    p95: 149.227,
    p97: 150.745,
    p99: 153.611,
    p999: 158.525
  },
  {
    month: 123,
    l: 1,
    m: 139.0797,
    s: 0.04647,
    sd: 6.463,
    p01: 119.107,
    p1: 124.044,
    p3: 126.924,
    p5: 128.449,
    p10: 130.797,
    p15: 132.381,
    p25: 134.72,
    p50: 139.08,
    p75: 143.439,
    p85: 145.778,
    p90: 147.362,
    p95: 149.71,
    p97: 151.235,
    p99: 154.115,
    p999: 159.052
  },
  {
    month: 124,
    l: 1,
    m: 139.5158,
    s: 0.04654,
    sd: 6.4931,
    p01: 119.451,
    p1: 124.411,
    p3: 127.304,
    p5: 128.836,
    p10: 131.195,
    p15: 132.786,
    p25: 135.136,
    p50: 139.516,
    p75: 143.895,
    p85: 146.245,
    p90: 147.837,
    p95: 150.196,
    p97: 151.728,
    p99: 154.621,
    p999: 159.581
  },
  {
    month: 125,
    l: 1,
    m: 139.954,
    s: 0.04661,
    sd: 6.5233,
    p01: 119.796,
    p1: 124.779,
    p3: 127.685,
    p5: 129.224,
    p10: 131.594,
    p15: 133.193,
    p25: 135.554,
    p50: 139.954,
    p75: 144.354,
    p85: 146.715,
    p90: 148.314,
    p95: 150.684,
    p97: 152.223,
    p99: 155.129,
    p999: 160.112
  },
  {
    month: 126,
    l: 1,
    m: 140.3948,
    s: 0.04667,
    sd: 6.5522,
    p01: 120.147,
    p1: 125.152,
    p3: 128.071,
    p5: 129.617,
    p10: 131.998,
    p15: 133.604,
    p25: 135.975,
    p50: 140.395,
    p75: 144.814,
    p85: 147.186,
    p90: 148.792,
    p95: 151.172,
    p97: 152.718,
    p99: 155.638,
    p999: 160.643
  },
  {
    month: 127,
    l: 1,
    m: 140.8387,
    s: 0.04674,
    sd: 6.5828,
    p01: 120.496,
    p1: 125.525,
    p3: 128.458,
    p5: 130.011,
    p10: 132.403,
    p15: 134.016,
    p25: 136.399,
    p50: 140.839,
    p75: 145.279,
    p85: 147.661,
    p90: 149.275,
    p95: 151.666,
    p97: 153.22,
    p99: 156.153,
    p999: 161.181
  },
  {
    month: 128,
    l: 1,
    m: 141.2859,
    s: 0.0468,
    sd: 6.6122,
    p01: 120.853,
    p1: 125.904,
    p3: 128.85,
    p5: 130.41,
    p10: 132.812,
    p15: 134.433,
    p25: 136.826,
    p50: 141.286,
    p75: 145.746,
    p85: 148.139,
    p90: 149.76,
    p95: 152.162,
    p97: 153.722,
    p99: 156.668,
    p999: 161.719
  },
  {
    month: 129,
    l: 1,
    m: 141.7368,
    s: 0.04686,
    sd: 6.6418,
    p01: 121.212,
    p1: 126.286,
    p3: 129.245,
    p5: 130.812,
    p10: 133.225,
    p15: 134.853,
    p25: 137.257,
    p50: 141.737,
    p75: 146.217,
    p85: 148.621,
    p90: 150.249,
    p95: 152.662,
    p97: 154.229,
    p99: 157.188,
    p999: 162.261
  },
  {
    month: 130,
    l: 1,
    m: 142.1916,
    s: 0.04692,
    sd: 6.6716,
    p01: 121.575,
    p1: 126.671,
    p3: 129.644,
    p5: 131.218,
    p10: 133.642,
    p15: 135.277,
    p25: 137.692,
    p50: 142.192,
    p75: 146.692,
    p85: 149.106,
    p90: 150.742,
    p95: 153.165,
    p97: 154.74,
    p99: 157.712,
    p999: 162.808
  },
  {
    month: 131,
    l: 1,
    m: 142.6501,
    s: 0.04698,
    sd: 6.7017,
    p01: 121.94,
    p1: 127.06,
    p3: 130.046,
    p5: 131.627,
    p10: 134.062,
    p15: 135.704,
    p25: 138.13,
    p50: 142.65,
    p75: 147.17,
    p85: 149.596,
    p90: 151.239,
    p95: 153.673,
    p97: 155.255,
    p99: 158.241,
    p999: 163.36
  },
  {
    month: 132,
    l: 1,
    m: 143.1126,
    s: 0.04703,
    sd: 6.7306,
    p01: 122.314,
    p1: 127.455,
    p3: 130.454,
    p5: 132.042,
    p10: 134.487,
    p15: 136.137,
    p25: 138.573,
    p50: 143.113,
    p75: 147.652,
    p85: 150.088,
    p90: 151.738,
    p95: 154.183,
    p97: 155.771,
    p99: 158.77,
    p999: 163.912
  },
  {
    month: 133,
    l: 1,
    m: 143.5795,
    s: 0.04709,
    sd: 6.7612,
    p01: 122.686,
    p1: 127.851,
    p3: 130.863,
    p5: 132.458,
    p10: 134.915,
    p15: 136.572,
    p25: 139.019,
    p50: 143.58,
    p75: 148.14,
    p85: 150.587,
    p90: 152.244,
    p95: 154.701,
    p97: 156.296,
    p99: 159.308,
    p999: 164.473
  },
  {
    month: 134,
    l: 1,
    m: 144.0511,
    s: 0.04714,
    sd: 6.7906,
    p01: 123.067,
    p1: 128.254,
    p3: 131.279,
    p5: 132.882,
    p10: 135.349,
    p15: 137.013,
    p25: 139.471,
    p50: 144.051,
    p75: 148.631,
    p85: 151.089,
    p90: 152.754,
    p95: 155.221,
    p97: 156.823,
    p99: 159.848,
    p999: 165.036
  },
  {
    month: 135,
    l: 1,
    m: 144.5276,
    s: 0.04719,
    sd: 6.8203,
    p01: 123.451,
    p1: 128.661,
    p3: 131.7,
    p5: 133.309,
    p10: 135.787,
    p15: 137.459,
    p25: 139.927,
    p50: 144.528,
    p75: 149.128,
    p85: 151.596,
    p90: 153.268,
    p95: 155.746,
    p97: 157.355,
    p99: 160.394,
    p999: 165.604
  },
  {
    month: 136,
    l: 1,
    m: 145.0093,
    s: 0.04723,
    sd: 6.8488,
    p01: 123.845,
    p1: 129.077,
    p3: 132.128,
    p5: 133.744,
    p10: 136.232,
    p15: 137.911,
    p25: 140.39,
    p50: 145.009,
    p75: 149.629,
    p85: 152.108,
    p90: 153.786,
    p95: 156.275,
    p97: 157.89,
    p99: 160.942,
    p999: 166.174
  },
  {
    month: 137,
    l: 1,
    m: 145.4964,
    s: 0.04728,
    sd: 6.8791,
    p01: 124.238,
    p1: 129.493,
    p3: 132.558,
    p5: 134.181,
    p10: 136.681,
    p15: 138.367,
    p25: 140.857,
    p50: 145.496,
    p75: 150.136,
    p85: 152.626,
    p90: 154.312,
    p95: 156.811,
    p97: 158.435,
    p99: 161.5,
    p999: 166.754
  },
  {
    month: 138,
    l: 1,
    m: 145.9891,
    s: 0.04732,
    sd: 6.9082,
    p01: 124.641,
    p1: 129.918,
    p3: 132.996,
    p5: 134.626,
    p10: 137.136,
    p15: 138.829,
    p25: 141.33,
    p50: 145.989,
    p75: 150.649,
    p85: 153.149,
    p90: 154.842,
    p95: 157.352,
    p97: 158.982,
    p99: 162.06,
    p999: 167.337
  },
  {
    month: 139,
    l: 1,
    m: 146.4878,
    s: 0.04736,
    sd: 6.9377,
    p01: 125.049,
    p1: 130.348,
    p3: 133.439,
    p5: 135.076,
    p10: 137.597,
    p15: 139.297,
    p25: 141.808,
    p50: 146.488,
    p75: 151.167,
    p85: 153.678,
    p90: 155.379,
    p95: 157.899,
    p97: 159.536,
    p99: 162.627,
    p999: 167.927
  },
  {
    month: 140,
    l: 1,
    m: 146.9927,
    s: 0.0474,
    sd: 6.9675,
    p01: 125.462,
    p1: 130.784,
    p3: 133.888,
    p5: 135.532,
    p10: 138.064,
    p15: 139.771,
    p25: 142.293,
    p50: 146.993,
    p75: 151.692,
    p85: 154.214,
    p90: 155.922,
    p95: 158.453,
    p97: 160.097,
    p99: 163.201,
    p999: 168.524
  },
  {
    month: 141,
    l: 1,
    m: 147.5041,
    s: 0.04744,
    sd: 6.9976,
    p01: 125.88,
    p1: 131.225,
    p3: 134.343,
    p5: 135.994,
    p10: 138.536,
    p15: 140.252,
    p25: 142.784,
    p50: 147.504,
    p75: 152.224,
    p85: 154.757,
    p90: 156.472,
    p95: 159.014,
    p97: 160.665,
    p99: 163.783,
    p999: 169.128
  },
  {
    month: 142,
    l: 1,
    m: 148.0224,
    s: 0.04747,
    sd: 7.0266,
    p01: 126.309,
    p1: 131.676,
    p3: 134.807,
    p5: 136.465,
    p10: 139.017,
    p15: 140.74,
    p25: 143.283,
    p50: 148.022,
    p75: 152.762,
    p85: 155.305,
    p90: 157.027,
    p95: 159.58,
    p97: 161.238,
    p99: 164.369,
    p999: 169.736
  },
  {
    month: 143,
    l: 1,
    m: 148.5478,
    s: 0.0475,
    sd: 7.056,
    p01: 126.743,
    p1: 132.133,
    p3: 135.277,
    p5: 136.942,
    p10: 139.505,
    p15: 141.235,
    p25: 143.789,
    p50: 148.548,
    p75: 153.307,
    p85: 155.861,
    p90: 157.59,
    p95: 160.154,
    p97: 161.819,
    p99: 164.963,
    p999: 170.353
  },
  {
    month: 144,
    l: 1,
    m: 149.0807,
    s: 0.04753,
    sd: 7.0858,
    p01: 127.184,
    p1: 132.597,
    p3: 135.754,
    p5: 137.426,
    p10: 140,
    p15: 141.737,
    p25: 144.301,
    p50: 149.081,
    p75: 153.86,
    p85: 156.425,
    p90: 158.162,
    p95: 160.736,
    p97: 162.408,
    p99: 165.565,
    p999: 170.977
  },
  {
    month: 145,
    l: 1,
    m: 149.6212,
    s: 0.04755,
    sd: 7.1145,
    p01: 127.636,
    p1: 133.07,
    p3: 136.24,
    p5: 137.919,
    p10: 140.504,
    p15: 142.248,
    p25: 144.823,
    p50: 149.621,
    p75: 154.42,
    p85: 156.995,
    p90: 158.739,
    p95: 161.323,
    p97: 163.002,
    p99: 166.172,
    p999: 171.607
  },
  {
    month: 146,
    l: 1,
    m: 150.1694,
    s: 0.04758,
    sd: 7.1451,
    p01: 128.09,
    p1: 133.548,
    p3: 136.731,
    p5: 138.417,
    p10: 141.013,
    p15: 142.764,
    p25: 145.35,
    p50: 150.169,
    p75: 154.989,
    p85: 157.575,
    p90: 159.326,
    p95: 161.922,
    p97: 163.608,
    p99: 166.791,
    p999: 172.249
  },
  {
    month: 147,
    l: 1,
    m: 150.7256,
    s: 0.04759,
    sd: 7.173,
    p01: 128.559,
    p1: 134.039,
    p3: 137.235,
    p5: 138.927,
    p10: 141.533,
    p15: 143.291,
    p25: 145.887,
    p50: 150.726,
    p75: 155.564,
    p85: 158.16,
    p90: 159.918,
    p95: 162.524,
    p97: 164.217,
    p99: 167.413,
    p999: 172.892
  },
  {
    month: 148,
    l: 1,
    m: 151.2899,
    s: 0.04761,
    sd: 7.2029,
    p01: 129.031,
    p1: 134.533,
    p3: 137.743,
    p5: 139.442,
    p10: 142.059,
    p15: 143.825,
    p25: 146.432,
    p50: 151.29,
    p75: 156.148,
    p85: 158.755,
    p90: 160.521,
    p95: 163.138,
    p97: 164.837,
    p99: 168.046,
    p999: 173.549
  },
  {
    month: 149,
    l: 1,
    m: 151.8623,
    s: 0.04762,
    sd: 7.2317,
    p01: 129.515,
    p1: 135.039,
    p3: 138.261,
    p5: 139.967,
    p10: 142.595,
    p15: 144.367,
    p25: 146.985,
    p50: 151.862,
    p75: 156.74,
    p85: 159.357,
    p90: 161.13,
    p95: 163.757,
    p97: 165.464,
    p99: 168.686,
    p999: 174.21
  },
  {
    month: 150,
    l: 1,
    m: 152.4425,
    s: 0.04763,
    sd: 7.2608,
    p01: 130.005,
    p1: 135.551,
    p3: 138.786,
    p5: 140.499,
    p10: 143.137,
    p15: 144.917,
    p25: 147.545,
    p50: 152.442,
    p75: 157.34,
    p85: 159.968,
    p90: 161.748,
    p95: 164.386,
    p97: 166.099,
    p99: 169.334,
    p999: 174.88
  },
  {
    month: 151,
    l: 1,
    m: 153.0298,
    s: 0.04763,
    sd: 7.2888,
    p01: 130.506,
    p1: 136.073,
    p3: 139.321,
    p5: 141.041,
    p10: 143.689,
    p15: 145.475,
    p25: 148.114,
    p50: 153.03,
    p75: 157.946,
    p85: 160.584,
    p90: 162.371,
    p95: 165.019,
    p97: 166.739,
    p99: 169.986,
    p999: 175.554
  },
  {
    month: 152,
    l: 1,
    m: 153.6234,
    s: 0.04764,
    sd: 7.3186,
    p01: 131.007,
    p1: 136.598,
    p3: 139.859,
    p5: 141.585,
    p10: 144.244,
    p15: 146.038,
    p25: 148.687,
    p50: 153.623,
    p75: 158.56,
    p85: 161.209,
    p90: 163.003,
    p95: 165.661,
    p97: 167.388,
    p99: 170.649,
    p999: 176.24
  },
  {
    month: 153,
    l: 1,
    m: 154.2223,
    s: 0.04763,
    sd: 7.3456,
    p01: 131.523,
    p1: 137.134,
    p3: 140.407,
    p5: 142.14,
    p10: 144.809,
    p15: 146.609,
    p25: 149.268,
    p50: 154.222,
    p75: 159.177,
    p85: 161.836,
    p90: 163.636,
    p95: 166.305,
    p97: 168.038,
    p99: 171.311,
    p999: 176.922
  },
  {
    month: 154,
    l: 1,
    m: 154.8258,
    s: 0.04763,
    sd: 7.3744,
    p01: 132.037,
    p1: 137.67,
    p3: 140.956,
    p5: 142.696,
    p10: 145.375,
    p15: 147.183,
    p25: 149.852,
    p50: 154.826,
    p75: 159.8,
    p85: 162.469,
    p90: 164.276,
    p95: 166.956,
    p97: 168.695,
    p99: 171.981,
    p999: 177.614
  },
  {
    month: 155,
    l: 1,
    m: 155.4329,
    s: 0.04762,
    sd: 7.4017,
    p01: 132.56,
    p1: 138.214,
    p3: 141.512,
    p5: 143.258,
    p10: 145.947,
    p15: 147.762,
    p25: 150.441,
    p50: 155.433,
    p75: 160.425,
    p85: 163.104,
    p90: 164.919,
    p95: 167.608,
    p97: 169.354,
    p99: 172.652,
    p999: 178.306
  },
  {
    month: 156,
    l: 1,
    m: 156.0426,
    s: 0.0476,
    sd: 7.4276,
    p01: 133.09,
    p1: 138.763,
    p3: 142.073,
    p5: 143.825,
    p10: 146.524,
    p15: 148.344,
    p25: 151.033,
    p50: 156.043,
    p75: 161.052,
    p85: 163.741,
    p90: 165.561,
    p95: 168.26,
    p97: 170.012,
    p99: 173.322,
    p999: 178.996
  },
  {
    month: 157,
    l: 1,
    m: 156.6539,
    s: 0.04758,
    sd: 7.4536,
    p01: 133.621,
    p1: 139.314,
    p3: 142.635,
    p5: 144.394,
    p10: 147.102,
    p15: 148.929,
    p25: 151.627,
    p50: 156.654,
    p75: 161.681,
    p85: 164.379,
    p90: 166.206,
    p95: 168.914,
    p97: 170.673,
    p99: 173.994,
    p999: 179.687
  },
  {
    month: 158,
    l: 1,
    m: 157.266,
    s: 0.04756,
    sd: 7.4796,
    p01: 134.152,
    p1: 139.866,
    p3: 143.198,
    p5: 144.963,
    p10: 147.681,
    p15: 149.514,
    p25: 152.221,
    p50: 157.266,
    p75: 162.311,
    p85: 165.018,
    p90: 166.851,
    p95: 169.569,
    p97: 171.334,
    p99: 174.666,
    p999: 180.38
  },
  {
    month: 159,
    l: 1,
    m: 157.8775,
    s: 0.04754,
    sd: 7.5055,
    p01: 134.684,
    p1: 140.417,
    p3: 143.761,
    p5: 145.532,
    p10: 148.259,
    p15: 150.099,
    p25: 152.815,
    p50: 157.878,
    p75: 162.94,
    p85: 165.656,
    p90: 167.496,
    p95: 170.223,
    p97: 171.994,
    p99: 175.338,
    p999: 181.071
  },
  {
    month: 160,
    l: 1,
    m: 158.4871,
    s: 0.04751,
    sd: 7.5297,
    p01: 135.219,
    p1: 140.97,
    p3: 144.325,
    p5: 146.102,
    p10: 148.837,
    p15: 150.683,
    p25: 153.408,
    p50: 158.487,
    p75: 163.566,
    p85: 166.291,
    p90: 168.137,
    p95: 170.872,
    p97: 172.649,
    p99: 176.004,
    p999: 181.756
  },
  {
    month: 161,
    l: 1,
    m: 159.0937,
    s: 0.04747,
    sd: 7.5522,
    p01: 135.756,
    p1: 141.525,
    p3: 144.89,
    p5: 146.671,
    p10: 149.415,
    p15: 151.266,
    p25: 154,
    p50: 159.094,
    p75: 164.188,
    p85: 166.921,
    p90: 168.772,
    p95: 171.516,
    p97: 173.298,
    p99: 176.663,
    p999: 182.432
  },
  {
    month: 162,
    l: 1,
    m: 159.6962,
    s: 0.04744,
    sd: 7.576,
    p01: 136.285,
    p1: 142.072,
    p3: 145.447,
    p5: 147.235,
    p10: 149.987,
    p15: 151.844,
    p25: 154.586,
    p50: 159.696,
    p75: 164.806,
    p85: 167.548,
    p90: 169.405,
    p95: 172.158,
    p97: 173.945,
    p99: 177.321,
    p999: 183.108
  },
  {
    month: 163,
    l: 1,
    m: 160.2939,
    s: 0.0474,
    sd: 7.5979,
    p01: 136.815,
    p1: 142.618,
    p3: 146.004,
    p5: 147.796,
    p10: 150.557,
    p15: 152.419,
    p25: 155.169,
    p50: 160.294,
    p75: 165.419,
    p85: 168.169,
    p90: 170.031,
    p95: 172.791,
    p97: 174.584,
    p99: 177.969,
    p999: 183.773
  },
  {
    month: 164,
    l: 1,
    m: 160.8861,
    s: 0.04735,
    sd: 7.618,
    p01: 137.345,
    p1: 143.164,
    p3: 146.558,
    p5: 148.356,
    p10: 151.123,
    p15: 152.991,
    p25: 155.748,
    p50: 160.886,
    p75: 166.024,
    p85: 168.782,
    p90: 170.649,
    p95: 173.417,
    p97: 175.214,
    p99: 178.608,
    p999: 184.427
  },
  {
    month: 165,
    l: 1,
    m: 161.472,
    s: 0.0473,
    sd: 7.6376,
    p01: 137.87,
    p1: 143.704,
    p3: 147.107,
    p5: 148.909,
    p10: 151.684,
    p15: 153.556,
    p25: 156.32,
    p50: 161.472,
    p75: 166.624,
    p85: 169.388,
    p90: 171.26,
    p95: 174.035,
    p97: 175.837,
    p99: 179.24,
    p999: 185.074
  },
  {
    month: 166,
    l: 1,
    m: 162.0505,
    s: 0.04725,
    sd: 7.6569,
    p01: 138.389,
    p1: 144.238,
    p3: 147.649,
    p5: 149.456,
    p10: 152.238,
    p15: 154.115,
    p25: 156.886,
    p50: 162.05,
    p75: 167.215,
    p85: 169.986,
    p90: 171.863,
    p95: 174.645,
    p97: 176.452,
    p99: 179.863,
    p999: 185.712
  },
  {
    month: 167,
    l: 1,
    m: 162.6207,
    s: 0.0472,
    sd: 7.6757,
    p01: 138.901,
    p1: 144.764,
    p3: 148.184,
    p5: 149.995,
    p10: 152.784,
    p15: 154.665,
    p25: 157.444,
    p50: 162.621,
    p75: 167.798,
    p85: 170.576,
    p90: 172.458,
    p95: 175.246,
    p97: 177.057,
    p99: 180.477,
    p999: 186.34
  },
  {
    month: 168,
    l: 1,
    m: 163.1816,
    s: 0.04714,
    sd: 7.6924,
    p01: 139.41,
    p1: 145.286,
    p3: 148.714,
    p5: 150.529,
    p10: 153.323,
    p15: 155.209,
    p25: 157.993,
    p50: 163.182,
    p75: 168.37,
    p85: 171.154,
    p90: 173.04,
    p95: 175.834,
    p97: 177.649,
    p99: 181.077,
    p999: 186.953
  },
  {
    month: 169,
    l: 1,
    m: 163.7321,
    s: 0.04707,
    sd: 7.7069,
    p01: 139.916,
    p1: 145.803,
    p3: 149.237,
    p5: 151.055,
    p10: 153.855,
    p15: 155.744,
    p25: 158.534,
    p50: 163.732,
    p75: 168.93,
    p85: 171.72,
    p90: 173.609,
    p95: 176.409,
    p97: 178.227,
    p99: 181.661,
    p999: 187.548
  },
  {
    month: 170,
    l: 1,
    m: 164.2717,
    s: 0.04701,
    sd: 7.7224,
    p01: 140.408,
    p1: 146.307,
    p3: 149.747,
    p5: 151.569,
    p10: 154.375,
    p15: 156.268,
    p25: 159.063,
    p50: 164.272,
    p75: 169.48,
    p85: 172.275,
    p90: 174.168,
    p95: 176.974,
    p97: 178.796,
    p99: 182.237,
    p999: 188.136
  },
  {
    month: 171,
    l: 1,
    m: 164.7994,
    s: 0.04694,
    sd: 7.7357,
    p01: 140.894,
    p1: 146.804,
    p3: 150.25,
    p5: 152.075,
    p10: 154.886,
    p15: 156.782,
    p25: 159.582,
    p50: 164.799,
    p75: 170.017,
    p85: 172.817,
    p90: 174.713,
    p95: 177.523,
    p97: 179.349,
    p99: 182.795,
    p999: 188.704
  },
  {
    month: 172,
    l: 1,
    m: 165.3145,
    s: 0.04687,
    sd: 7.7483,
    p01: 141.37,
    p1: 147.289,
    p3: 150.742,
    p5: 152.57,
    p10: 155.385,
    p15: 157.284,
    p25: 160.088,
    p50: 165.314,
    p75: 170.541,
    p85: 173.345,
    p90: 175.244,
    p95: 178.059,
    p97: 179.887,
    p99: 183.34,
    p999: 189.259
  },
  {
    month: 173,
    l: 1,
    m: 165.8165,
    s: 0.04679,
    sd: 7.7586,
    p01: 141.841,
    p1: 147.767,
    p3: 151.224,
    p5: 153.055,
    p10: 155.874,
    p15: 157.775,
    p25: 160.583,
    p50: 165.816,
    p75: 171.05,
    p85: 173.858,
    p90: 175.759,
    p95: 178.578,
    p97: 180.409,
    p99: 183.866,
    p999: 189.792
  },
  {
    month: 174,
    l: 1,
    m: 166.305,
    s: 0.04671,
    sd: 7.7681,
    p01: 142.3,
    p1: 148.234,
    p3: 151.695,
    p5: 153.528,
    p10: 156.35,
    p15: 158.254,
    p25: 161.065,
    p50: 166.305,
    p75: 171.545,
    p85: 174.356,
    p90: 176.26,
    p95: 179.082,
    p97: 180.915,
    p99: 184.376,
    p999: 190.31
  },
  {
    month: 175,
    l: 1,
    m: 166.7799,
    s: 0.04663,
    sd: 7.7769,
    p01: 142.747,
    p1: 148.688,
    p3: 152.153,
    p5: 153.988,
    p10: 156.813,
    p15: 158.72,
    p25: 161.534,
    p50: 166.78,
    p75: 172.025,
    p85: 174.84,
    p90: 176.746,
    p95: 179.572,
    p97: 181.407,
    p99: 184.872,
    p999: 190.812
  },
  {
    month: 176,
    l: 1,
    m: 167.2415,
    s: 0.04655,
    sd: 7.7851,
    p01: 143.184,
    p1: 149.131,
    p3: 152.599,
    p5: 154.436,
    p10: 157.265,
    p15: 159.173,
    p25: 161.991,
    p50: 167.242,
    p75: 172.492,
    p85: 175.31,
    p90: 177.218,
    p95: 180.047,
    p97: 181.884,
    p99: 185.352,
    p999: 191.299
  },
  {
    month: 177,
    l: 1,
    m: 167.6899,
    s: 0.04646,
    sd: 7.7909,
    p01: 143.614,
    p1: 149.566,
    p3: 153.037,
    p5: 154.875,
    p10: 157.705,
    p15: 159.615,
    p25: 162.435,
    p50: 167.69,
    p75: 172.945,
    p85: 175.765,
    p90: 177.674,
    p95: 180.505,
    p97: 182.343,
    p99: 185.814,
    p999: 191.766
  },
  {
    month: 178,
    l: 1,
    m: 168.1255,
    s: 0.04637,
    sd: 7.796,
    p01: 144.034,
    p1: 149.989,
    p3: 153.463,
    p5: 155.302,
    p10: 158.135,
    p15: 160.045,
    p25: 162.867,
    p50: 168.126,
    p75: 173.384,
    p85: 176.206,
    p90: 178.116,
    p95: 180.949,
    p97: 182.788,
    p99: 186.262,
    p999: 192.217
  },
  {
    month: 179,
    l: 1,
    m: 168.5482,
    s: 0.04628,
    sd: 7.8004,
    p01: 144.443,
    p1: 150.402,
    p3: 153.877,
    p5: 155.718,
    p10: 158.552,
    p15: 160.464,
    p25: 163.287,
    p50: 168.548,
    p75: 173.809,
    p85: 176.633,
    p90: 178.545,
    p95: 181.379,
    p97: 183.219,
    p99: 186.695,
    p999: 192.653
  },
  {
    month: 180,
    l: 1,
    m: 168.958,
    s: 0.04619,
    sd: 7.8042,
    p01: 144.841,
    p1: 150.803,
    p3: 154.28,
    p5: 156.121,
    p10: 158.957,
    p15: 160.869,
    p25: 163.694,
    p50: 168.958,
    p75: 174.222,
    p85: 177.047,
    p90: 178.959,
    p95: 181.795,
    p97: 183.636,
    p99: 187.113,
    p999: 193.075
  },
  {
    month: 181,
    l: 1,
    m: 169.3549,
    s: 0.04609,
    sd: 7.8056,
    p01: 145.234,
    p1: 151.196,
    p3: 154.674,
    p5: 156.516,
    p10: 159.352,
    p15: 161.265,
    p25: 164.09,
    p50: 169.355,
    p75: 174.62,
    p85: 177.445,
    p90: 179.358,
    p95: 182.194,
    p97: 184.036,
    p99: 187.513,
    p999: 193.476
  },
  {
    month: 182,
    l: 1,
    m: 169.7389,
    s: 0.04599,
    sd: 7.8063,
    p01: 145.616,
    p1: 151.579,
    p3: 155.057,
    p5: 156.899,
    p10: 159.735,
    p15: 161.648,
    p25: 164.474,
    p50: 169.739,
    p75: 175.004,
    p85: 177.83,
    p90: 179.743,
    p95: 182.579,
    p97: 184.421,
    p99: 187.899,
    p999: 193.862
  },
  {
    month: 183,
    l: 1,
    m: 170.1099,
    s: 0.04589,
    sd: 7.8063,
    p01: 145.986,
    p1: 151.95,
    p3: 155.428,
    p5: 157.27,
    p10: 160.106,
    p15: 162.019,
    p25: 164.845,
    p50: 170.11,
    p75: 175.375,
    p85: 178.201,
    p90: 180.114,
    p95: 182.95,
    p97: 184.792,
    p99: 188.27,
    p999: 194.233
  },
  {
    month: 184,
    l: 1,
    m: 170.468,
    s: 0.04579,
    sd: 7.8057,
    p01: 146.346,
    p1: 152.309,
    p3: 155.787,
    p5: 157.629,
    p10: 160.465,
    p15: 162.378,
    p25: 165.203,
    p50: 170.468,
    p75: 175.733,
    p85: 178.558,
    p90: 180.471,
    p95: 183.307,
    p97: 185.149,
    p99: 188.627,
    p999: 194.59
  },
  {
    month: 185,
    l: 1,
    m: 170.8136,
    s: 0.04569,
    sd: 7.8045,
    p01: 146.696,
    p1: 152.658,
    p3: 156.135,
    p5: 157.976,
    p10: 160.812,
    p15: 162.725,
    p25: 165.55,
    p50: 170.814,
    p75: 176.078,
    p85: 178.902,
    p90: 180.815,
    p95: 183.651,
    p97: 185.492,
    p99: 188.97,
    p999: 194.931
  },
  {
    month: 186,
    l: 1,
    m: 171.1468,
    s: 0.04559,
    sd: 7.8026,
    p01: 147.035,
    p1: 152.995,
    p3: 156.472,
    p5: 158.313,
    p10: 161.147,
    p15: 163.06,
    p25: 165.884,
    p50: 171.147,
    p75: 176.41,
    p85: 179.234,
    p90: 181.146,
    p95: 183.981,
    p97: 185.822,
    p99: 189.298,
    p999: 195.259
  },
  {
    month: 187,
    l: 1,
    m: 171.468,
    s: 0.04548,
    sd: 7.7984,
    p01: 147.369,
    p1: 153.326,
    p3: 156.801,
    p5: 158.641,
    p10: 161.474,
    p15: 163.386,
    p25: 166.208,
    p50: 171.468,
    p75: 176.728,
    p85: 179.55,
    p90: 181.462,
    p95: 184.295,
    p97: 186.135,
    p99: 189.61,
    p999: 195.567
  },
  {
    month: 188,
    l: 1,
    m: 171.7773,
    s: 0.04538,
    sd: 7.7953,
    p01: 147.688,
    p1: 153.643,
    p3: 157.116,
    p5: 158.955,
    p10: 161.787,
    p15: 163.698,
    p25: 166.519,
    p50: 171.777,
    p75: 177.035,
    p85: 179.857,
    p90: 181.767,
    p95: 184.599,
    p97: 186.439,
    p99: 189.912,
    p999: 195.866
  },
  {
    month: 189,
    l: 1,
    m: 172.0748,
    s: 0.04527,
    sd: 7.7898,
    p01: 148.002,
    p1: 153.953,
    p3: 157.424,
    p5: 159.262,
    p10: 162.092,
    p15: 164.001,
    p25: 166.821,
    p50: 172.075,
    p75: 177.329,
    p85: 180.148,
    p90: 182.058,
    p95: 184.888,
    p97: 186.726,
    p99: 190.197,
    p999: 196.147
  },
  {
    month: 190,
    l: 1,
    m: 172.3606,
    s: 0.04516,
    sd: 7.7838,
    p01: 148.307,
    p1: 154.253,
    p3: 157.721,
    p5: 159.557,
    p10: 162.385,
    p15: 164.293,
    p25: 167.111,
    p50: 172.361,
    p75: 177.611,
    p85: 180.428,
    p90: 182.336,
    p95: 185.164,
    p97: 187,
    p99: 190.468,
    p999: 196.414
  },
  {
    month: 191,
    l: 1,
    m: 172.6345,
    s: 0.04506,
    sd: 7.7789,
    p01: 148.596,
    p1: 154.538,
    p3: 158.004,
    p5: 159.839,
    p10: 162.665,
    p15: 164.572,
    p25: 167.388,
    p50: 172.634,
    p75: 177.881,
    p85: 180.697,
    p90: 182.604,
    p95: 185.43,
    p97: 187.265,
    p99: 190.731,
    p999: 196.673
  },
  {
    month: 192,
    l: 1,
    m: 172.8967,
    s: 0.04495,
    sd: 7.7717,
    p01: 148.88,
    p1: 154.817,
    p3: 158.28,
    p5: 160.113,
    p10: 162.937,
    p15: 164.842,
    p25: 167.655,
    p50: 172.897,
    p75: 178.139,
    p85: 180.952,
    p90: 182.857,
    p95: 185.68,
    p97: 187.514,
    p99: 190.976,
    p999: 196.913
  },
  {
    month: 193,
    l: 1,
    m: 173.147,
    s: 0.04484,
    sd: 7.7639,
    p01: 149.155,
    p1: 155.085,
    p3: 158.545,
    p5: 160.377,
    p10: 163.197,
    p15: 165.1,
    p25: 167.91,
    p50: 173.147,
    p75: 178.384,
    p85: 181.194,
    p90: 183.097,
    p95: 185.917,
    p97: 187.749,
    p99: 191.209,
    p999: 197.139
  },
  {
    month: 194,
    l: 1,
    m: 173.3856,
    s: 0.04473,
    sd: 7.7555,
    p01: 149.419,
    p1: 155.344,
    p3: 158.799,
    p5: 160.629,
    p10: 163.446,
    p15: 165.348,
    p25: 168.155,
    p50: 173.386,
    p75: 178.617,
    p85: 181.424,
    p90: 183.325,
    p95: 186.142,
    p97: 187.972,
    p99: 191.428,
    p999: 197.352
  },
  {
    month: 195,
    l: 1,
    m: 173.6126,
    s: 0.04462,
    sd: 7.7466,
    p01: 149.674,
    p1: 155.591,
    p3: 159.043,
    p5: 160.871,
    p10: 163.685,
    p15: 165.584,
    p25: 168.388,
    p50: 173.613,
    p75: 178.838,
    p85: 181.641,
    p90: 183.54,
    p95: 186.355,
    p97: 188.182,
    p99: 191.634,
    p999: 197.551
  },
  {
    month: 196,
    l: 1,
    m: 173.828,
    s: 0.04451,
    sd: 7.7371,
    p01: 149.919,
    p1: 155.829,
    p3: 159.276,
    p5: 161.102,
    p10: 163.913,
    p15: 165.809,
    p25: 168.609,
    p50: 173.828,
    p75: 179.047,
    p85: 181.847,
    p90: 183.743,
    p95: 186.554,
    p97: 188.38,
    p99: 191.827,
    p999: 197.737
  },
  {
    month: 197,
    l: 1,
    m: 174.0321,
    s: 0.0444,
    sd: 7.727,
    p01: 150.154,
    p1: 156.056,
    p3: 159.499,
    p5: 161.322,
    p10: 164.13,
    p15: 166.024,
    p25: 168.82,
    p50: 174.032,
    p75: 179.244,
    p85: 182.041,
    p90: 183.935,
    p95: 186.742,
    p97: 188.565,
    p99: 192.008,
    p999: 197.91
  },
  {
    month: 198,
    l: 1,
    m: 174.2251,
    s: 0.04429,
    sd: 7.7164,
    p01: 150.38,
    p1: 156.274,
    p3: 159.712,
    p5: 161.533,
    p10: 164.336,
    p15: 166.228,
    p25: 169.02,
    p50: 174.225,
    p75: 179.43,
    p85: 182.223,
    p90: 184.114,
    p95: 186.917,
    p97: 188.738,
    p99: 192.176,
    p999: 198.071
  },
  {
    month: 199,
    l: 1,
    m: 174.4071,
    s: 0.04418,
    sd: 7.7053,
    p01: 150.596,
    p1: 156.482,
    p3: 159.915,
    p5: 161.733,
    p10: 164.532,
    p15: 166.421,
    p25: 169.21,
    p50: 174.407,
    p75: 179.604,
    p85: 182.393,
    p90: 184.282,
    p95: 187.081,
    p97: 188.899,
    p99: 192.332,
    p999: 198.218
  },
  {
    month: 200,
    l: 1,
    m: 174.5784,
    s: 0.04407,
    sd: 7.6937,
    p01: 150.803,
    p1: 156.68,
    p3: 160.108,
    p5: 161.923,
    p10: 164.719,
    p15: 166.604,
    p25: 169.389,
    p50: 174.578,
    p75: 179.768,
    p85: 182.552,
    p90: 184.438,
    p95: 187.233,
    p97: 189.049,
    p99: 192.477,
    p999: 198.354
  },
  {
    month: 201,
    l: 1,
    m: 174.7392,
    s: 0.04396,
    sd: 7.6815,
    p01: 151.001,
    p1: 156.869,
    p3: 160.292,
    p5: 162.104,
    p10: 164.895,
    p15: 166.778,
    p25: 169.558,
    p50: 174.739,
    p75: 179.92,
    p85: 182.701,
    p90: 184.583,
    p95: 187.374,
    p97: 189.187,
    p99: 192.609,
    p999: 198.477
  },
  {
    month: 202,
    l: 1,
    m: 174.8896,
    s: 0.04385,
    sd: 7.6689,
    p01: 151.191,
    p1: 157.049,
    p3: 160.466,
    p5: 162.275,
    p10: 165.061,
    p15: 166.941,
    p25: 169.717,
    p50: 174.89,
    p75: 180.062,
    p85: 182.838,
    p90: 184.718,
    p95: 187.504,
    p97: 189.313,
    p99: 192.73,
    p999: 198.588
  },
  {
    month: 203,
    l: 1,
    m: 175.0301,
    s: 0.04375,
    sd: 7.6576,
    p01: 151.366,
    p1: 157.216,
    p3: 160.628,
    p5: 162.435,
    p10: 165.217,
    p15: 167.094,
    p25: 169.865,
    p50: 175.03,
    p75: 180.195,
    p85: 182.967,
    p90: 184.844,
    p95: 187.626,
    p97: 189.432,
    p99: 192.844,
    p999: 198.694
  },
  {
    month: 204,
    l: 1,
    m: 175.1609,
    s: 0.04364,
    sd: 7.644,
    p01: 151.539,
    p1: 157.378,
    p3: 160.784,
    p5: 162.588,
    p10: 165.365,
    p15: 167.238,
    p25: 170.005,
    p50: 175.161,
    p75: 180.317,
    p85: 183.083,
    p90: 184.957,
    p95: 187.734,
    p97: 189.538,
    p99: 192.944,
    p999: 198.783
  },
  {
    month: 205,
    l: 1,
    m: 175.2824,
    s: 0.04353,
    sd: 7.63,
    p01: 151.704,
    p1: 157.532,
    p3: 160.932,
    p5: 162.732,
    p10: 165.504,
    p15: 167.374,
    p25: 170.136,
    p50: 175.282,
    p75: 180.429,
    p85: 183.19,
    p90: 185.061,
    p95: 187.833,
    p97: 189.633,
    p99: 193.033,
    p999: 198.861
  },
  {
    month: 206,
    l: 1,
    m: 175.3951,
    s: 0.04343,
    sd: 7.6174,
    p01: 151.856,
    p1: 157.674,
    p3: 161.068,
    p5: 162.866,
    p10: 165.633,
    p15: 167.5,
    p25: 170.257,
    p50: 175.395,
    p75: 180.533,
    p85: 183.29,
    p90: 185.157,
    p95: 187.925,
    p97: 189.722,
    p99: 193.116,
    p999: 198.935
  },
  {
    month: 207,
    l: 1,
    m: 175.4995,
    s: 0.04332,
    sd: 7.6026,
    p01: 152.006,
    p1: 157.813,
    p3: 161.201,
    p5: 162.994,
    p10: 165.756,
    p15: 167.62,
    p25: 170.372,
    p50: 175.5,
    p75: 180.627,
    p85: 183.379,
    p90: 185.243,
    p95: 188.005,
    p97: 189.798,
    p99: 193.186,
    p999: 198.993
  },
  {
    month: 208,
    l: 1,
    m: 175.5959,
    s: 0.04322,
    sd: 7.5893,
    p01: 152.143,
    p1: 157.941,
    p3: 161.322,
    p5: 163.113,
    p10: 165.87,
    p15: 167.73,
    p25: 170.477,
    p50: 175.596,
    p75: 180.715,
    p85: 183.462,
    p90: 185.322,
    p95: 188.079,
    p97: 189.87,
    p99: 193.251,
    p999: 199.048
  },
  {
    month: 209,
    l: 1,
    m: 175.685,
    s: 0.04311,
    sd: 7.5738,
    p01: 152.28,
    p1: 158.066,
    p3: 161.44,
    p5: 163.227,
    p10: 165.979,
    p15: 167.835,
    p25: 170.577,
    p50: 175.685,
    p75: 180.793,
    p85: 183.535,
    p90: 185.391,
    p95: 188.143,
    p97: 189.93,
    p99: 193.304,
    p999: 199.09
  },
  {
    month: 210,
    l: 1,
    m: 175.7672,
    s: 0.04301,
    sd: 7.5597,
    p01: 152.406,
    p1: 158.181,
    p3: 161.549,
    p5: 163.333,
    p10: 166.079,
    p15: 167.932,
    p25: 170.668,
    p50: 175.767,
    p75: 180.866,
    p85: 183.602,
    p90: 185.455,
    p95: 188.202,
    p97: 189.986,
    p99: 193.354,
    p999: 199.129
  },
  {
    month: 211,
    l: 1,
    m: 175.8432,
    s: 0.04291,
    sd: 7.5454,
    p01: 152.526,
    p1: 158.29,
    p3: 161.652,
    p5: 163.432,
    p10: 166.173,
    p15: 168.023,
    p25: 170.754,
    p50: 175.843,
    p75: 180.933,
    p85: 183.664,
    p90: 185.513,
    p95: 188.254,
    p97: 190.035,
    p99: 193.396,
    p999: 199.16
  },
  {
    month: 212,
    l: 1,
    m: 175.9133,
    s: 0.04281,
    sd: 7.5308,
    p01: 152.641,
    p1: 158.394,
    p3: 161.749,
    p5: 163.526,
    p10: 166.262,
    p15: 168.108,
    p25: 170.834,
    p50: 175.913,
    p75: 180.993,
    p85: 183.719,
    p90: 185.564,
    p95: 188.3,
    p97: 190.077,
    p99: 193.433,
    p999: 199.185
  },
  {
    month: 213,
    l: 1,
    m: 175.9781,
    s: 0.04271,
    sd: 7.516,
    p01: 152.752,
    p1: 158.493,
    p3: 161.842,
    p5: 163.615,
    p10: 166.346,
    p15: 168.188,
    p25: 170.909,
    p50: 175.978,
    p75: 181.048,
    p85: 183.768,
    p90: 185.61,
    p95: 188.341,
    p97: 190.114,
    p99: 193.463,
    p999: 199.204
  },
  {
    month: 214,
    l: 1,
    m: 176.038,
    s: 0.04261,
    sd: 7.501,
    p01: 152.858,
    p1: 158.588,
    p3: 161.93,
    p5: 163.7,
    p10: 166.425,
    p15: 168.264,
    p25: 170.979,
    p50: 176.038,
    p75: 181.097,
    p85: 183.812,
    p90: 185.651,
    p95: 188.376,
    p97: 190.146,
    p99: 193.488,
    p999: 199.218
  },
  {
    month: 215,
    l: 1,
    m: 176.0935,
    s: 0.04251,
    sd: 7.4857,
    p01: 152.961,
    p1: 158.679,
    p3: 162.014,
    p5: 163.781,
    p10: 166.5,
    p15: 168.335,
    p25: 171.044,
    p50: 176.094,
    p75: 181.143,
    p85: 183.852,
    p90: 185.687,
    p95: 188.406,
    p97: 190.173,
    p99: 193.508,
    p999: 199.226
  },
  {
    month: 216,
    l: 1,
    m: 176.1449,
    s: 0.04241,
    sd: 7.4703,
    p01: 153.06,
    p1: 158.766,
    p3: 162.095,
    p5: 163.857,
    p10: 166.571,
    p15: 168.402,
    p25: 171.106,
    p50: 176.145,
    p75: 181.184,
    p85: 183.887,
    p90: 185.718,
    p95: 188.432,
    p97: 190.195,
    p99: 193.523,
    p999: 199.23
  },
  {
    month: 217,
    l: 1,
    m: 176.1925,
    s: 0.04232,
    sd: 7.4565,
    p01: 153.15,
    p1: 158.846,
    p3: 162.168,
    p5: 163.928,
    p10: 166.637,
    p15: 168.464,
    p25: 171.163,
    p50: 176.192,
    p75: 181.222,
    p85: 183.921,
    p90: 185.748,
    p95: 188.457,
    p97: 190.217,
    p99: 193.539,
    p999: 199.235
  },
  {
    month: 218,
    l: 1,
    m: 176.2368,
    s: 0.04222,
    sd: 7.4407,
    p01: 153.243,
    p1: 158.927,
    p3: 162.242,
    p5: 163.998,
    p10: 166.701,
    p15: 168.525,
    p25: 171.218,
    p50: 176.237,
    p75: 181.255,
    p85: 183.949,
    p90: 185.772,
    p95: 188.476,
    p97: 190.231,
    p99: 193.546,
    p999: 199.23
  },
  {
    month: 219,
    l: 1,
    m: 176.2779,
    s: 0.04213,
    sd: 7.4266,
    p01: 153.328,
    p1: 159.001,
    p3: 162.31,
    p5: 164.062,
    p10: 166.76,
    p15: 168.581,
    p25: 171.269,
    p50: 176.278,
    p75: 181.287,
    p85: 183.975,
    p90: 185.795,
    p95: 188.494,
    p97: 190.246,
    p99: 193.555,
    p999: 199.228
  },
  {
    month: 220,
    l: 1,
    m: 176.3162,
    s: 0.04204,
    sd: 7.4123,
    p01: 153.41,
    p1: 159.073,
    p3: 162.375,
    p5: 164.124,
    p10: 166.817,
    p15: 168.634,
    p25: 171.317,
    p50: 176.316,
    p75: 181.316,
    p85: 183.999,
    p90: 185.815,
    p95: 188.508,
    p97: 190.257,
    p99: 193.56,
    p999: 199.222
  },
  {
    month: 221,
    l: 1,
    m: 176.3518,
    s: 0.04195,
    sd: 7.398,
    p01: 153.49,
    p1: 159.142,
    p3: 162.438,
    p5: 164.183,
    p10: 166.871,
    p15: 168.684,
    p25: 171.362,
    p50: 176.352,
    p75: 181.342,
    p85: 184.019,
    p90: 185.833,
    p95: 188.52,
    p97: 190.266,
    p99: 193.562,
    p999: 199.213
  },
  {
    month: 222,
    l: 1,
    m: 176.3851,
    s: 0.04185,
    sd: 7.3817,
    p01: 153.574,
    p1: 159.213,
    p3: 162.502,
    p5: 164.243,
    p10: 166.925,
    p15: 168.734,
    p25: 171.406,
    p50: 176.385,
    p75: 181.364,
    p85: 184.036,
    p90: 185.845,
    p95: 188.527,
    p97: 190.269,
    p99: 193.558,
    p999: 199.196
  },
  {
    month: 223,
    l: 1,
    m: 176.4162,
    s: 0.04177,
    sd: 7.3689,
    p01: 153.645,
    p1: 159.274,
    p3: 162.557,
    p5: 164.295,
    p10: 166.973,
    p15: 168.779,
    p25: 171.446,
    p50: 176.416,
    p75: 181.386,
    p85: 184.054,
    p90: 185.86,
    p95: 188.537,
    p97: 190.276,
    p99: 193.559,
    p999: 199.188
  },
  {
    month: 224,
    l: 1,
    m: 176.4453,
    s: 0.04168,
    sd: 7.3542,
    p01: 153.719,
    p1: 159.337,
    p3: 162.613,
    p5: 164.349,
    p10: 167.02,
    p15: 168.823,
    p25: 171.485,
    p50: 176.445,
    p75: 181.406,
    p85: 184.067,
    p90: 185.87,
    p95: 188.542,
    p97: 190.277,
    p99: 193.554,
    p999: 199.172
  },
  {
    month: 225,
    l: 1,
    m: 176.4724,
    s: 0.04159,
    sd: 7.3395,
    p01: 153.792,
    p1: 159.398,
    p3: 162.668,
    p5: 164.4,
    p10: 167.066,
    p15: 168.866,
    p25: 171.522,
    p50: 176.472,
    p75: 181.423,
    p85: 184.079,
    p90: 185.878,
    p95: 188.545,
    p97: 190.276,
    p99: 193.547,
    p999: 199.153
  },
  {
    month: 226,
    l: 1,
    m: 176.4976,
    s: 0.0415,
    sd: 7.3247,
    p01: 153.863,
    p1: 159.458,
    p3: 162.721,
    p5: 164.45,
    p10: 167.111,
    p15: 168.906,
    p25: 171.557,
    p50: 176.498,
    p75: 181.438,
    p85: 184.089,
    p90: 185.885,
    p95: 188.546,
    p97: 190.274,
    p99: 193.537,
    p999: 199.132
  },
  {
    month: 227,
    l: 1,
    m: 176.5211,
    s: 0.04142,
    sd: 7.3115,
    p01: 153.927,
    p1: 159.512,
    p3: 162.77,
    p5: 164.495,
    p10: 167.151,
    p15: 168.943,
    p25: 171.59,
    p50: 176.521,
    p75: 181.453,
    p85: 184.099,
    p90: 185.891,
    p95: 188.547,
    p97: 190.273,
    p99: 193.53,
    p999: 199.115
  },
  {
    month: 228,
    l: 1,
    m: 176.5432,
    s: 0.04134,
    sd: 7.2983,
    p01: 153.99,
    p1: 159.565,
    p3: 162.817,
    p5: 164.539,
    p10: 167.19,
    p15: 168.979,
    p25: 171.621,
    p50: 176.543,
    p75: 181.466,
    p85: 184.107,
    p90: 185.896,
    p95: 188.548,
    p97: 190.27,
    p99: 193.522,
    p999: 199.097
  }
]

export default lengthValues
