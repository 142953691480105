import React from 'react'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'
import Select from 'react-select'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SelectorProps,
  useChakraThemeParser,
} from '.'
import { Disposition } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { isValidDisposition } from '../ActiveVisit'

const messages = defineMessages({
  disposition: {
    id: 'DispositionSelector.disposition',
    defaultMessage: 'Disposition',
  },
})

export const dispositionTranslation: Record<Disposition, MessageDescriptor> = {
  [Disposition.DISCHARGE_HOME]: {
    id: 'DispositionSelector.discharge_home',
    defaultMessage: 'Discharge home',
  },
  [Disposition.OBSERVE]: {
    id: 'DispositionSelector.observe',
    defaultMessage: 'Observe',
  },
  [Disposition.INTERNAL_REFERRAL]: {
    id: 'DispositionSelector.internal_referral',
    defaultMessage: 'Internal Referral',
  },
  [Disposition.EXTERNAL_REFERRAL]: {
    id: 'DispositionSelector.external_referral',
    defaultMessage: 'Referral',
  },
  [Disposition.TRANSFER]: {
    id: 'DispositionSelector.transfer',
    defaultMessage: 'Transfer',
  },
  [Disposition.ADMIT]: {
    id: 'DispositionSelector.admit',
    defaultMessage: 'Admit',
  },
}

const dispositionMessages = defineMessages(dispositionTranslation)

export type DispositionSelectorProps = SelectorProps<Disposition> & {
  isMulti: false
}

export const DispositionSelector: React.FC<DispositionSelectorProps> = (props) => {
  const { value } = props

  const intl = useIntl()
  const theme = useChakraThemeParser()

  const formatOption = (statusAssesment: Disposition): ReactSelectInternalSingleValue => {
    return {
      label: intl.formatMessage(dispositionMessages[statusAssesment]),
      value: statusAssesment,
    }
  }
  /**
   * Filter statuses for lactation use
   */
  const options = Object.values(Disposition)
    .filter(
      (disposition) =>
        disposition === Disposition.DISCHARGE_HOME || disposition === Disposition.EXTERNAL_REFERRAL
    )
    .map(formatOption)

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (isValidDisposition(value as string)) {
      return formatOption(value as Disposition)
    }

    return null
  }

  const _onChange = (value) => {
    if (!value) {
      props.onChange('' as Disposition)
      return
    }

    const selectedValue = value

    props.onChange(selectedValue.value as Disposition)
  }

  return (
    <Select
      options={options}
      value={parseValue(value)}
      onChange={_onChange}
      isLoading={props.isLoading}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      aria-invalid={props.isInvalid}
      placeholder={intl.formatMessage(messages.disposition)}
      blurInputOnSelect={props.blurInputOnSelect}
      theme={theme}
    />
  )
}
