import { VitalType } from '../../api/interfaces'

export interface Range {
  min: number
  max: number
}

interface BPRange {
  systolic: Range
  diastolic: Range
}
export interface VitalsReference {
  age: Range
  t: Range
  wt: Range
  ht: Range
  hcm: Range
  hr: Range
  rr: Range
  bp: BPRange
  o2: Range
  bmi?: Range
  muac?: Range
}

export const VITALS_ORDER = [
  VitalType.TEMPERATURE,
  VitalType.HEART_RATE,
  VitalType.RESPIRATORY_RATE,
  VitalType.BLOOD_PRESSURE,
  VitalType.O2_SATURATION,
  VitalType.WEIGHT,
  VitalType.HEIGHT,
  VitalType.HEAD_CIRCUMFERENCE,
  VitalType.MID_UPPER_ARM_CIRCUMFERENCE,
]

/**
 * At this point, this app doesn't need all the vitals
 */
export const domesticFilter = (vital: VitalType) => {
  switch (vital) {
    case VitalType.WEIGHT:
    case VitalType.HEIGHT:
    case VitalType.HEAD_CIRCUMFERENCE:
    case VitalType.TEMPERATURE:
      return true

    /**
     * The components for theese types still need to be
     * updated to work with several measurements
     */
    case VitalType.HEART_RATE:
    case VitalType.RESPIRATORY_RATE:
    case VitalType.BLOOD_PRESSURE:
    case VitalType.O2_SATURATION:
    case VitalType.MID_UPPER_ARM_CIRCUMFERENCE:
    default:
      return false
  }
}

/**
 * We want to show first the items which contain a measurement
 * Then following this order:
 * T - HR - RR - BP - O2 - WT - HT - HCM - MUAC
 *
 * We'll do it in 2 steps to keep it simple
 */
export const sortVitals = (vitals) => {
  if (!Array.isArray(vitals)) return vitals

  const withMeasurement = vitals.filter((vital) => !!vital.measurements)
  const withoutMeasurement = vitals.filter((vital) => !vital.measurements)

  const customSorting = (a, b) => {
    const positionA = VITALS_ORDER.indexOf(a.type)
    const positionB = VITALS_ORDER.indexOf(b.type)

    /**
     * This shound never happen
     * but if the type is not found
     * keep them as they are
     */
    if (a === -1 || b === -1) return 0

    return positionA >= positionB ? 1 : -1
  }
  return [...withMeasurement.sort(customSorting), ...withoutMeasurement.sort(customSorting)]
}
