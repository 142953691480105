import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { ADD_MANUAL_TREATMENT, REMOVE_TREATMENT } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ValidateFn, ValidationError } from '../Form'

const messages = defineMessages({
  requiredError: {
    id: 'UI.required_error',
    defaultMessage: 'You must enter a value for this field.',
  },
  patientNotFoundError: {
    id: 'AddManualTreatment.patient_not_found_error',
    defaultMessage: 'This patient was not found, refresh the page and try again.',
  },
  visitNotFoundError: {
    id: 'AddManualTreatment.visit_not_found_error',
    defaultMessage: 'This visit was not found, refresh the page and try again.',
  },
  removeTreatmentError: {
    id: 'AddManualTreatment.remove_treatment__error',
    defaultMessage: 'Something went wrong while trying to remove the treatment, try again later.',
  },
  addTreatmentError: {
    id: 'AddManualTreatment.add_treatment__error',
    defaultMessage: 'Something went wrong while trying to add a treatment, try again later.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface ManualDiagnoseFormValues {
  treatment: string
}

interface AddManualTreatmentVariables {
  patientId: string
  visitId: string
  treatment: string
}

interface AddManualTreatmentData {
  addNewManualDiagnoseToActiveVisit: FullPatientWithActiveVisit
}

type AddManualTreatmentParams = MutationHookOptions<
  AddManualTreatmentData,
  AddManualTreatmentVariables
> & {
  patientId: string
  visitId: string
}

export const useAddManualTreatment = (params: AddManualTreatmentParams) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFoundError))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFoundError))

      case 'error_adding_new_treatment':
        return setError(intl.formatMessage(messages.addTreatmentError))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [addManualTreatment, { loading: isLoading }] = useMutation(ADD_MANUAL_TREATMENT, {
    onError,
    ...restParams,
  })

  const validate: ValidateFn<ManualDiagnoseFormValues> = async (values) => {
    const errors: ValidationError<ManualDiagnoseFormValues> = {}

    if (!values.treatment) {
      errors.treatment = intl.formatMessage(messages.requiredError)
    }

    return errors
  }

  const onSubmit = (values: ManualDiagnoseFormValues) => {
    setError(null)

    const { treatment } = values
    const variables = {
      patientId,
      visitId,
      treatment,
    }

    addManualTreatment({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    validate,
    error,
    onErrorClose,
  }
}

interface RemoveManualTreatmentVariables {
  patientId: string
  visitId: string
  treatmentId: string
}

interface RemoveManualTreatmentData {
  removeTreatmentFromActiveVisit: FullPatientWithActiveVisit
}

type RemoveManualTreatmentParams = MutationHookOptions<
  RemoveManualTreatmentData,
  RemoveManualTreatmentVariables
> & {
  patientId: string
  visitId: string
}

export const useRemoveManualTreatment = (params: RemoveManualTreatmentParams) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFoundError))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFoundError))

      case 'error_removing_treatment':
        return setError(intl.formatMessage(messages.removeTreatmentError))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [removeManualTreatment, { loading: isLoading }] = useMutation(REMOVE_TREATMENT, {
    onError,
    ...restParams,
  })

  const onDelete = (treatmentId: string) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      treatmentId,
    }

    removeManualTreatment({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}
