import React from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Fade,
  VStack
} from "@chakra-ui/react"
interface Props {
  title: string
  description?: string
  onClose?: () => void
}

export const ErrorViewer: React.FC<Props> = props => {
  const { title, description } = props

  return <Fade in>
    <Alert status="error" variant="top-accent" my='2'>
      <AlertIcon />
      <VStack>
        <AlertTitle mr={2}>{title}</AlertTitle>
        {
          description &&
          <AlertDescription>{description}</AlertDescription>
        }
      </VStack>
      {
        typeof props?.onClose === 'function' &&
        <CloseButton position="absolute" right={1} top={1} onClick={props.onClose} />
      }
    </Alert>
  </Fade>
}