import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { BasePatientFormValues, PatientRegistrationParams } from '.'
import { extractFirstErrorCode, isValidSex, Nullable } from '../../utils'
import { isValidEmail, isValidPhoneNumber, sanitizePhone, ValidateFn, ValidationError } from '../Form'
import { REGISTER_PATIENT } from '../../api/requests/patients'
import phone from 'phone'
import { sitemap } from '../Routing'
import { BasePatient } from '../../api/interfaces'
import { isValidDate } from '../DateAndTime'

const messages = defineMessages({
  invalidLocation: {
    id: 'PersonalInfoCard.invalid_location',
    defaultMessage: 'Invalid location',
  },
  errorSavingPatient: {
    id: 'PersonalInfoCard.error_saving_patient_info',
    defaultMessage: 'Could not save the patient info. Please, try again',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  country: {
    id: 'PersonalInfoCard.country',
    defaultMessage: 'Country',
  },
  invalidDate: {
    id: 'PersonalInfoCard.invalid_date',
    defaultMessage: 'Invalid date',
  },
  invalidMail: {
    id: 'PersonalInfoCard.invalid_email_address',
    defaultMessage: 'Invalid e-mail address',
  },
  invalidPhone: {
    id: 'PersonalInfoCard.invalid_phone_number',
    defaultMessage: 'Invalid phone number',
  },
})

interface PatientRegistrationHookVariables {
  newPatient: PatientRegistrationParams
}

interface RegisterPatientMutationData {
  registerPatient: BasePatient
}

export type UsePatientRegistrationParams = MutationHookOptions<
  RegisterPatientMutationData,
  PatientRegistrationHookVariables
> & {
  isCreating?: boolean
}
export const usePatientRegistration = (params?: UsePatientRegistrationParams) => {
  const navigate = useNavigate()
  const intl = useIntl()

  const [registrationStart, setRegistrationStart] = React.useState<string>()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<BasePatientFormValues> = async (values) => {
    const errors: ValidationError<BasePatientFormValues> = {}

    if (!values.first_name) {
      errors.first_name = intl.formatMessage(messages.required)
    }

    if (!values.last_name) {
      errors.last_name = intl.formatMessage(messages.required)
    }

    if (!values.birthdate) {
      errors.birthdate = intl.formatMessage(messages.required)
    } else if (!isValidDate(values.birthdate)) {
      errors.birthdate = intl.formatMessage(messages.invalidDate)
    }

    if (!values.sex || !isValidSex(values.sex)) {
      errors.sex = intl.formatMessage(messages.required)
    }

    if (values.email && !isValidEmail(values.email)) {
      errors.email = intl.formatMessage(messages.invalidMail)
    }

    const phoneValidationOptions = { country: values.country }
    if (values.phone_number && !isValidPhoneNumber(values.phone_number, phoneValidationOptions)) {
      errors.phone_number = intl.formatMessage(messages.invalidPhone)
    }

    if (!values.country) {
      errors.country = intl.formatMessage(messages.required)
    }

    if (params?.isCreating && !values.consent) {
      errors.consent = intl.formatMessage(messages.required)
    }
    return errors
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'error_saving_patient_info':
        return setError(intl.formatMessage(messages.errorSavingPatient))

      case 'invalid_location':
      case 'invalid_signature':
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onCompleted = (data) => {
    setError(null)
    const patientId = data.registerPatient.id
    const path = sitemap.patients.routes.profile.pathBuilder(patientId)
    navigate(path)
  }

  const [registerPatient, { loading: isLoading }] = useMutation<
    RegisterPatientMutationData,
    PatientRegistrationHookVariables
  >(REGISTER_PATIENT, { onError, onCompleted, ...params })

  const onSubmit = (values: BasePatientFormValues) => {
    const {
      address,
      locality,
      country,
      postal_code,
      birthdate,
      first_name,
      last_name,
      sex,
      email,
      phone_number,
      phone_number_e164,
    } = values

    const [line_1, line_2, line_3] = (address || '').split('\n')

    const newPatient = {
      first_name,
      last_name,
      sex,
      email,
      phone_number,
      phone_number_e164,
      registration_start: registrationStart,
      birthdate: birthdate?.toISOString(),
      address: {
        line_1,
        line_2,
        line_3,
        locality,
        postal_code,
        country,
      },
    }

    if (values?.phone_number) {
      const phoneValidationOptions = { country: values.country }
      const sanitized = sanitizePhone(values.phone_number, phoneValidationOptions)
      const parsed = phone(sanitized)

      if (parsed.isValid) {
        newPatient.phone_number_e164 = parsed.phoneNumber
      }
    }

    const variables: PatientRegistrationHookVariables = {
      newPatient: newPatient as PatientRegistrationParams,
    }

    registerPatient({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  React.useEffect(() => {
    setRegistrationStart(new Date().toISOString())
  }, [])

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}
