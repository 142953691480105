import React from 'react'
import {
  Measurement as MeasurementInterface,
  BloodPressure as BloodPressureInterface,
  HeadCircumference as HeadCircumferenceInterface,
  Height as HeightInterface,
  Temperature as TemperatureInterface,
  Weight as WeightInterface,
  VitalType
} from '../../api/interfaces'
import {
  BloodPressure,
  HeadCircumference,
  Height,
  Temperature,
  Weight
} from '.'
import { ButtonProps } from '@chakra-ui/react'

type MeasurementProps = Omit<ButtonProps, 'type'> & {
  patientId: string
  visitId: string
  birthdate: string
  sex: string
  type: VitalType
  values: Array<MeasurementInterface>
}

export const Measurement: React.FC<MeasurementProps> = props => {
  const { type, values, patientId, visitId, birthdate, ...buttonProps } = props

  switch (type) {
    case VitalType.BLOOD_PRESSURE:
      return <BloodPressure
        {...buttonProps}
        values={values as Array<BloodPressureInterface>}
        patientId={patientId}
        visitId={visitId}
        birthdate={birthdate}
      />


    case VitalType.HEIGHT:
      return <Height
        {...buttonProps}
        values={values as Array<HeightInterface>}
        patientId={patientId}
        visitId={visitId}
        birthdate={birthdate}
      />

    case VitalType.TEMPERATURE:
      return <Temperature
        {...buttonProps}
        values={values as Array<TemperatureInterface>}
        patientId={patientId}
        visitId={visitId}
        birthdate={birthdate}
      />

    case VitalType.WEIGHT:
      return <Weight
        {...buttonProps}
        values={values as Array<WeightInterface>}
        patientId={patientId}
        visitId={visitId}
        birthdate={birthdate}
      />

    case VitalType.HEAD_CIRCUMFERENCE:
      return <HeadCircumference
        {...buttonProps}
        values={values as Array<HeadCircumferenceInterface>}
        patientId={patientId}
        visitId={visitId}
        birthdate={birthdate}
      />



    /**
     * The components for theese types still need to be
     * updated to work with several measurements
     */
    case VitalType.MID_UPPER_ARM_CIRCUMFERENCE:
    case VitalType.HEART_RATE:
    case VitalType.RESPIRATORY_RATE:
    case VitalType.O2_SATURATION:
    default:
      return null
  }
}
