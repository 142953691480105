export const DOSE_UNITS = Object.freeze({
  MG_KG: 'mg/kg',
  G_KG: 'g/kg',
  ML_KG: 'ml/kg',
  MCG_KG: 'mcg/kg',
  UNITS_KG: 'units/kg',
  UNITS_KG_HR: 'units/kg/hr',
  MCG_KG_MIN: 'mcg/kg/min',
  MCG_MIN: 'mcg/min',
  MG_MIN: 'mg/min',
  MG_HR: 'mg/hr',
  MG_KG_HR: 'mg/kg/hr',
  MG: 'mg',
  ML: 'ml',
  L: 'L',
  MEQ: 'meq',
  MEQ_KG: 'meq/kg',
  JOULES_KG: 'joules/kg',
  JOULES: 'joules',
  MCG: 'mcg',
  G: 'g',
  GRAMS: 'gram',
  DROPS: 'drops',
  PERCENT: '%',
  TABLETS: 'tablets',
  SPRAYS: 'sprays',
  DAB: 'dab',
  APPLICATION: 'application',
  OTHER: 'other',
  UNITS: 'units'
})

export const COMMON_ROUTE_TYPES = Object.freeze({
  PO: 'po',
  IM: 'im',
  IV: 'iv'
})

export const FREQUENCY_SHOW = Object.freeze({
  FREQUENCY: 'frequency',
  DOSES: 'doses',
  DOSES_PER_DAY: 'doses_per_day'
})

/**
 * Common prescriptions
 */
export const DEFAULT_RX_DETAILS = {
  frequencyShow: FREQUENCY_SHOW.DOSES_PER_DAY,
  dosesPerDay: 3,
  days: 3,
  route: COMMON_ROUTE_TYPES.PO,
  dispense: 1,
  refillsAllowed: 0,
  notes: ''
}

export const RX_VALIDATION_ERROR = Object.freeze({
  INVALID_DOSE: 'invalid_dose_value',
  INVALID_DOSE_UNIT: 'invalid_dose_unit',
  INVALID_UNITS_PER_DOSE: 'invalid_units_per_dose',
  INVALID_ROUTE: 'invalid_route',
  INVALID_FREUQUECY_SHOW: 'invalid_frequency_show',
  INVALID_DOSES_PER_DAY: 'invalid_doses_per_day',
  INVALID_DAYS: 'invalid_days',
  INVALID_DISPENSE: 'invalid_dispense'
})
