import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { PaginatedResult } from '../../api/interfaces'
import { GET_LAB_SUBSCRIPTIONS } from '../../api/requests/studies'
import { Nullable } from '../../utils'
import { BasicStudyTemplate } from '../../api/interfaces/studies'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface LabSubscriptionsQueryData {
  labSubscriptions: PaginatedResult<BasicStudyTemplate>
}

export type UseLabSubscriptionsParams = QueryHookOptions<LabSubscriptionsQueryData, void>

export const useLabSubscriptions = (params?: UseLabSubscriptionsParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)
  const onError = () => {
    setError(intl.formatMessage(messages.internalServerError))
  }

  const onCompleted = () => {
    setError(null)
  }

  const { loading: isLoading, data } = useQuery<LabSubscriptionsQueryData, void>(GET_LAB_SUBSCRIPTIONS, {
    onError,
    onCompleted,
    ...params,
  })

  const studies = React.useMemo(() => {
    if (!data || data.labSubscriptions === null) {
      return []
    }

    const translated = data.labSubscriptions.docs.map((study) => {
      const { name, description } = study

      const translatedName = name?.text
        ? intl.formatMessage({ id: `Dynamic.${name.text}`, defaultMessage: name.text })
        : ''

      const translatedDescription = description?.text
        ? intl.formatMessage({ id: `Dynamic.${description.text}`, defaultMessage: description.text })
        : ''

      return {
        ...study,
        translatedName,
        translatedDescription,
      }
    })

    const sorted = translated.sort((a, b) => {
      if (a.translatedName > b.translatedName) {
        return 1
      }
      if (b.translatedDescription > a.translatedDescription) {
        return -1
      }

      return 0
    })

    return sorted
  }, [data, intl])

  const onErrorClose = () => {
    setError(null)
  }
  return {
    isLoading,
    count: studies.length,
    studies: studies,
    error,
    onErrorClose,
  }
}
