
// Child growth standards
// DMI values for GIRLS between 24 - 228 months (2 - 19 years)
// Data taken from World Health Organization and then parsed to JSON
// Links:
// https://www.who.int/childgrowth/standards/tab_bmi_girls_p_2_5.txt (24 - 60 months)
// https://www.who.int/growthref/bmi_girls_perc_WHO2007_exp.txt      (61 - 228 months)

const bmiValues = [
  {
    month: 24,
    l: -0.5684,
    m: 15.6881,
    s: 0.08454,
    p01: 12.3,
    p1: 13,
    p3: 13.5,
    p5: 13.7,
    p10: 14.1,
    p15: 14.4,
    p25: 14.8,
    p50: 15.7,
    p75: 16.6,
    p85: 17.2,
    p90: 17.5,
    p95: 18.1,
    p97: 18.5,
    p99: 19.3,
    p999: 20.8
  },
  {
    month: 25,
    l: -0.5684,
    m: 15.659,
    s: 0.08452,
    p01: 12.3,
    p1: 13,
    p3: 13.4,
    p5: 13.7,
    p10: 14.1,
    p15: 14.4,
    p25: 14.8,
    p50: 15.7,
    p75: 16.6,
    p85: 17.1,
    p90: 17.5,
    p95: 18.1,
    p97: 18.5,
    p99: 19.3,
    p999: 20.8
  },
  {
    month: 26,
    l: -0.5684,
    m: 15.6308,
    s: 0.08449,
    p01: 12.3,
    p1: 13,
    p3: 13.4,
    p5: 13.7,
    p10: 14.1,
    p15: 14.4,
    p25: 14.8,
    p50: 15.6,
    p75: 16.6,
    p85: 17.1,
    p90: 17.5,
    p95: 18.1,
    p97: 18.5,
    p99: 19.3,
    p999: 20.7
  },
  {
    month: 27,
    l: -0.5684,
    m: 15.6037,
    s: 0.08446,
    p01: 12.2,
    p1: 13,
    p3: 13.4,
    p5: 13.7,
    p10: 14,
    p15: 14.3,
    p25: 14.8,
    p50: 15.6,
    p75: 16.5,
    p85: 17.1,
    p90: 17.4,
    p95: 18,
    p97: 18.4,
    p99: 19.2,
    p999: 20.7
  },
  {
    month: 28,
    l: -0.5684,
    m: 15.5777,
    s: 0.08444,
    p01: 12.2,
    p1: 12.9,
    p3: 13.4,
    p5: 13.6,
    p10: 14,
    p15: 14.3,
    p25: 14.7,
    p50: 15.6,
    p75: 16.5,
    p85: 17,
    p90: 17.4,
    p95: 18,
    p97: 18.4,
    p99: 19.2,
    p999: 20.7
  },
  {
    month: 29,
    l: -0.5684,
    m: 15.5523,
    s: 0.08443,
    p01: 12.2,
    p1: 12.9,
    p3: 13.4,
    p5: 13.6,
    p10: 14,
    p15: 14.3,
    p25: 14.7,
    p50: 15.6,
    p75: 16.5,
    p85: 17,
    p90: 17.4,
    p95: 18,
    p97: 18.4,
    p99: 19.2,
    p999: 20.6
  },
  {
    month: 30,
    l: -0.5684,
    m: 15.5276,
    s: 0.08444,
    p01: 12.2,
    p1: 12.9,
    p3: 13.3,
    p5: 13.6,
    p10: 14,
    p15: 14.3,
    p25: 14.7,
    p50: 15.5,
    p75: 16.5,
    p85: 17,
    p90: 17.4,
    p95: 17.9,
    p97: 18.3,
    p99: 19.1,
    p999: 20.6
  },
  {
    month: 31,
    l: -0.5684,
    m: 15.5034,
    s: 0.08448,
    p01: 12.2,
    p1: 12.9,
    p3: 13.3,
    p5: 13.6,
    p10: 14,
    p15: 14.2,
    p25: 14.7,
    p50: 15.5,
    p75: 16.4,
    p85: 17,
    p90: 17.3,
    p95: 17.9,
    p97: 18.3,
    p99: 19.1,
    p999: 20.6
  },
  {
    month: 32,
    l: -0.5684,
    m: 15.4798,
    s: 0.08455,
    p01: 12.1,
    p1: 12.8,
    p3: 13.3,
    p5: 13.5,
    p10: 13.9,
    p15: 14.2,
    p25: 14.6,
    p50: 15.5,
    p75: 16.4,
    p85: 16.9,
    p90: 17.3,
    p95: 17.9,
    p97: 18.3,
    p99: 19.1,
    p999: 20.5
  },
  {
    month: 33,
    l: -0.5684,
    m: 15.4572,
    s: 0.08467,
    p01: 12.1,
    p1: 12.8,
    p3: 13.3,
    p5: 13.5,
    p10: 13.9,
    p15: 14.2,
    p25: 14.6,
    p50: 15.5,
    p75: 16.4,
    p85: 16.9,
    p90: 17.3,
    p95: 17.9,
    p97: 18.3,
    p99: 19,
    p999: 20.5
  },
  {
    month: 34,
    l: -0.5684,
    m: 15.4356,
    s: 0.08484,
    p01: 12.1,
    p1: 12.8,
    p3: 13.2,
    p5: 13.5,
    p10: 13.9,
    p15: 14.2,
    p25: 14.6,
    p50: 15.4,
    p75: 16.4,
    p85: 16.9,
    p90: 17.3,
    p95: 17.9,
    p97: 18.2,
    p99: 19,
    p999: 20.5
  },
  {
    month: 35,
    l: -0.5684,
    m: 15.4155,
    s: 0.08506,
    p01: 12.1,
    p1: 12.8,
    p3: 13.2,
    p5: 13.5,
    p10: 13.9,
    p15: 14.1,
    p25: 14.6,
    p50: 15.4,
    p75: 16.3,
    p85: 16.9,
    p90: 17.3,
    p95: 17.8,
    p97: 18.2,
    p99: 19,
    p999: 20.5
  },
  {
    month: 36,
    l: -0.5684,
    m: 15.3968,
    s: 0.08535,
    p01: 12,
    p1: 12.8,
    p3: 13.2,
    p5: 13.5,
    p10: 13.8,
    p15: 14.1,
    p25: 14.5,
    p50: 15.4,
    p75: 16.3,
    p85: 16.9,
    p90: 17.2,
    p95: 17.8,
    p97: 18.2,
    p99: 19,
    p999: 20.5
  },
  {
    month: 37,
    l: -0.5684,
    m: 15.3796,
    s: 0.08569,
    p01: 12,
    p1: 12.7,
    p3: 13.2,
    p5: 13.4,
    p10: 13.8,
    p15: 14.1,
    p25: 14.5,
    p50: 15.4,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.8,
    p97: 18.2,
    p99: 19,
    p999: 20.5
  },
  {
    month: 38,
    l: -0.5684,
    m: 15.3638,
    s: 0.08609,
    p01: 12,
    p1: 12.7,
    p3: 13.2,
    p5: 13.4,
    p10: 13.8,
    p15: 14.1,
    p25: 14.5,
    p50: 15.4,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.8,
    p97: 18.2,
    p99: 19,
    p999: 20.5
  },
  {
    month: 39,
    l: -0.5684,
    m: 15.3493,
    s: 0.08654,
    p01: 12,
    p1: 12.7,
    p3: 13.1,
    p5: 13.4,
    p10: 13.8,
    p15: 14.1,
    p25: 14.5,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.8,
    p97: 18.2,
    p99: 19,
    p999: 20.5
  },
  {
    month: 40,
    l: -0.5684,
    m: 15.3358,
    s: 0.08704,
    p01: 11.9,
    p1: 12.7,
    p3: 13.1,
    p5: 13.4,
    p10: 13.8,
    p15: 14,
    p25: 14.5,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.8,
    p97: 18.2,
    p99: 19,
    p999: 20.5
  },
  {
    month: 41,
    l: -0.5684,
    m: 15.3233,
    s: 0.08757,
    p01: 11.9,
    p1: 12.6,
    p3: 13.1,
    p5: 13.3,
    p10: 13.7,
    p15: 14,
    p25: 14.5,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.8,
    p97: 18.2,
    p99: 19,
    p999: 20.6
  },
  {
    month: 42,
    l: -0.5684,
    m: 15.3116,
    s: 0.08813,
    p01: 11.9,
    p1: 12.6,
    p3: 13.1,
    p5: 13.3,
    p10: 13.7,
    p15: 14,
    p25: 14.4,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.8,
    p97: 18.2,
    p99: 19,
    p999: 20.6
  },
  {
    month: 43,
    l: -0.5684,
    m: 15.3007,
    s: 0.08872,
    p01: 11.9,
    p1: 12.6,
    p3: 13,
    p5: 13.3,
    p10: 13.7,
    p15: 14,
    p25: 14.4,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.8,
    p97: 18.2,
    p99: 19.1,
    p999: 20.6
  },
  {
    month: 44,
    l: -0.5684,
    m: 15.2905,
    s: 0.08931,
    p01: 11.8,
    p1: 12.6,
    p3: 13,
    p5: 13.3,
    p10: 13.7,
    p15: 14,
    p25: 14.4,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.8,
    p97: 18.2,
    p99: 19.1,
    p999: 20.6
  },
  {
    month: 45,
    l: -0.5684,
    m: 15.2814,
    s: 0.08991,
    p01: 11.8,
    p1: 12.5,
    p3: 13,
    p5: 13.3,
    p10: 13.7,
    p15: 14,
    p25: 14.4,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.8,
    p97: 18.3,
    p99: 19.1,
    p999: 20.7
  },
  {
    month: 46,
    l: -0.5684,
    m: 15.2732,
    s: 0.09051,
    p01: 11.8,
    p1: 12.5,
    p3: 13,
    p5: 13.2,
    p10: 13.7,
    p15: 13.9,
    p25: 14.4,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.8,
    p97: 18.3,
    p99: 19.1,
    p999: 20.7
  },
  {
    month: 47,
    l: -0.5684,
    m: 15.2661,
    s: 0.0911,
    p01: 11.8,
    p1: 12.5,
    p3: 13,
    p5: 13.2,
    p10: 13.6,
    p15: 13.9,
    p25: 14.4,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.9,
    p97: 18.3,
    p99: 19.1,
    p999: 20.7
  },
  {
    month: 48,
    l: -0.5684,
    m: 15.2602,
    s: 0.09168,
    p01: 11.7,
    p1: 12.5,
    p3: 12.9,
    p5: 13.2,
    p10: 13.6,
    p15: 13.9,
    p25: 14.4,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.9,
    p97: 18.3,
    p99: 19.2,
    p999: 20.8
  },
  {
    month: 49,
    l: -0.5684,
    m: 15.2556,
    s: 0.09227,
    p01: 11.7,
    p1: 12.5,
    p3: 12.9,
    p5: 13.2,
    p10: 13.6,
    p15: 13.9,
    p25: 14.4,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.9,
    p97: 18.3,
    p99: 19.2,
    p999: 20.8
  },
  {
    month: 50,
    l: -0.5684,
    m: 15.2523,
    s: 0.09286,
    p01: 11.7,
    p1: 12.4,
    p3: 12.9,
    p5: 13.2,
    p10: 13.6,
    p15: 13.9,
    p25: 14.3,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.3,
    p95: 17.9,
    p97: 18.3,
    p99: 19.2,
    p999: 20.9
  },
  {
    month: 51,
    l: -0.5684,
    m: 15.2503,
    s: 0.09345,
    p01: 11.7,
    p1: 12.4,
    p3: 12.9,
    p5: 13.2,
    p10: 13.6,
    p15: 13.9,
    p25: 14.3,
    p50: 15.3,
    p75: 16.3,
    p85: 16.8,
    p90: 17.3,
    p95: 17.9,
    p97: 18.4,
    p99: 19.2,
    p999: 20.9
  },
  {
    month: 52,
    l: -0.5684,
    m: 15.2496,
    s: 0.09403,
    p01: 11.7,
    p1: 12.4,
    p3: 12.9,
    p5: 13.1,
    p10: 13.6,
    p15: 13.9,
    p25: 14.3,
    p50: 15.2,
    p75: 16.3,
    p85: 16.9,
    p90: 17.3,
    p95: 17.9,
    p97: 18.4,
    p99: 19.3,
    p999: 21
  },
  {
    month: 53,
    l: -0.5684,
    m: 15.2502,
    s: 0.0946,
    p01: 11.6,
    p1: 12.4,
    p3: 12.9,
    p5: 13.1,
    p10: 13.6,
    p15: 13.9,
    p25: 14.3,
    p50: 15.3,
    p75: 16.3,
    p85: 16.9,
    p90: 17.3,
    p95: 17.9,
    p97: 18.4,
    p99: 19.3,
    p999: 21
  },
  {
    month: 54,
    l: -0.5684,
    m: 15.2519,
    s: 0.09515,
    p01: 11.6,
    p1: 12.4,
    p3: 12.9,
    p5: 13.1,
    p10: 13.6,
    p15: 13.9,
    p25: 14.3,
    p50: 15.3,
    p75: 16.3,
    p85: 16.9,
    p90: 17.3,
    p95: 18,
    p97: 18.4,
    p99: 19.3,
    p999: 21
  },
  {
    month: 55,
    l: -0.5684,
    m: 15.2544,
    s: 0.09568,
    p01: 11.6,
    p1: 12.4,
    p3: 12.9,
    p5: 13.1,
    p10: 13.5,
    p15: 13.9,
    p25: 14.3,
    p50: 15.3,
    p75: 16.3,
    p85: 16.9,
    p90: 17.3,
    p95: 18,
    p97: 18.4,
    p99: 19.4,
    p999: 21.1
  },
  {
    month: 56,
    l: -0.5684,
    m: 15.2575,
    s: 0.09618,
    p01: 11.6,
    p1: 12.4,
    p3: 12.8,
    p5: 13.1,
    p10: 13.5,
    p15: 13.8,
    p25: 14.3,
    p50: 15.3,
    p75: 16.3,
    p85: 16.9,
    p90: 17.3,
    p95: 18,
    p97: 18.5,
    p99: 19.4,
    p999: 21.1
  },
  {
    month: 57,
    l: -0.5684,
    m: 15.2612,
    s: 0.09665,
    p01: 11.6,
    p1: 12.4,
    p3: 12.8,
    p5: 13.1,
    p10: 13.5,
    p15: 13.8,
    p25: 14.3,
    p50: 15.3,
    p75: 16.3,
    p85: 16.9,
    p90: 17.4,
    p95: 18,
    p97: 18.5,
    p99: 19.4,
    p999: 21.2
  },
  {
    month: 58,
    l: -0.5684,
    m: 15.2653,
    s: 0.09709,
    p01: 11.6,
    p1: 12.3,
    p3: 12.8,
    p5: 13.1,
    p10: 13.5,
    p15: 13.8,
    p25: 14.3,
    p50: 15.3,
    p75: 16.3,
    p85: 16.9,
    p90: 17.4,
    p95: 18,
    p97: 18.5,
    p99: 19.4,
    p999: 21.2
  },
  {
    month: 59,
    l: -0.5684,
    m: 15.2698,
    s: 0.0975,
    p01: 11.6,
    p1: 12.3,
    p3: 12.8,
    p5: 13.1,
    p10: 13.5,
    p15: 13.8,
    p25: 14.3,
    p50: 15.3,
    p75: 16.3,
    p85: 16.9,
    p90: 17.4,
    p95: 18.1,
    p97: 18.5,
    p99: 19.5,
    p999: 21.3
  },
  {
    month: 60,
    l: -0.5684,
    m: 15.2747,
    s: 0.09789,
    p01: 11.6,
    p1: 12.3,
    p3: 12.8,
    p5: 13.1,
    p10: 13.5,
    p15: 13.8,
    p25: 14.3,
    p50: 15.3,
    p75: 16.3,
    p85: 17,
    p90: 17.4,
    p95: 18.1,
    p97: 18.6,
    p99: 19.5,
    p999: 21.3
  },
  {
    month: 61,
    l: -0.8886,
    m: 15.2441,
    s: 0.09692,
    p01: 11.689,
    p1: 12.412,
    p3: 12.874,
    p5: 13.133,
    p10: 13.55,
    p15: 13.846,
    p25: 14.306,
    p50: 15.244,
    p75: 16.306,
    p85: 16.936,
    p90: 17.388,
    p95: 18.103,
    p97: 18.598,
    p99: 19.605,
    p999: 21.594
  },
  {
    month: 62,
    l: -0.9068,
    m: 15.2434,
    s: 0.09738,
    p01: 11.682,
    p1: 12.405,
    p3: 12.868,
    p5: 13.126,
    p10: 13.544,
    p15: 13.84,
    p25: 14.301,
    p50: 15.243,
    p75: 16.311,
    p85: 16.945,
    p90: 17.402,
    p95: 18.124,
    p97: 18.624,
    p99: 19.643,
    p999: 21.662
  },
  {
    month: 63,
    l: -0.9248,
    m: 15.2433,
    s: 0.09783,
    p01: 11.676,
    p1: 12.399,
    p3: 12.861,
    p5: 13.12,
    p10: 13.538,
    p15: 13.835,
    p25: 14.298,
    p50: 15.243,
    p75: 16.317,
    p85: 16.956,
    p90: 17.416,
    p95: 18.145,
    p97: 18.65,
    p99: 19.681,
    p999: 21.731
  },
  {
    month: 64,
    l: -0.9427,
    m: 15.2438,
    s: 0.09829,
    p01: 11.671,
    p1: 12.393,
    p3: 12.855,
    p5: 13.114,
    p10: 13.533,
    p15: 13.831,
    p25: 14.294,
    p50: 15.244,
    p75: 16.324,
    p85: 16.967,
    p90: 17.431,
    p95: 18.167,
    p97: 18.677,
    p99: 19.721,
    p999: 21.802
  },
  {
    month: 65,
    l: -0.9605,
    m: 15.2448,
    s: 0.09875,
    p01: 11.665,
    p1: 12.387,
    p3: 12.85,
    p5: 13.109,
    p10: 13.529,
    p15: 13.827,
    p25: 14.292,
    p50: 15.245,
    p75: 16.331,
    p85: 16.979,
    p90: 17.447,
    p95: 18.189,
    p97: 18.705,
    p99: 19.763,
    p999: 21.876
  },
  {
    month: 66,
    l: -0.978,
    m: 15.2464,
    s: 0.0992,
    p01: 11.661,
    p1: 12.382,
    p3: 12.845,
    p5: 13.104,
    p10: 13.525,
    p15: 13.824,
    p25: 14.29,
    p50: 15.246,
    p75: 16.339,
    p85: 16.991,
    p90: 17.463,
    p95: 18.212,
    p97: 18.734,
    p99: 19.804,
    p999: 21.95
  },
  {
    month: 67,
    l: -0.9954,
    m: 15.2487,
    s: 0.09966,
    p01: 11.657,
    p1: 12.378,
    p3: 12.841,
    p5: 13.1,
    p10: 13.521,
    p15: 13.821,
    p25: 14.288,
    p50: 15.249,
    p75: 16.347,
    p85: 17.005,
    p90: 17.481,
    p95: 18.237,
    p97: 18.764,
    p99: 19.848,
    p999: 22.027
  },
  {
    month: 68,
    l: -1.0126,
    m: 15.2516,
    s: 0.10012,
    p01: 11.653,
    p1: 12.374,
    p3: 12.837,
    p5: 13.097,
    p10: 13.518,
    p15: 13.819,
    p25: 14.287,
    p50: 15.252,
    p75: 16.357,
    p85: 17.019,
    p90: 17.499,
    p95: 18.262,
    p97: 18.795,
    p99: 19.892,
    p999: 22.106
  },
  {
    month: 69,
    l: -1.0296,
    m: 15.2551,
    s: 0.10058,
    p01: 11.649,
    p1: 12.37,
    p3: 12.834,
    p5: 13.094,
    p10: 13.516,
    p15: 13.817,
    p25: 14.287,
    p50: 15.255,
    p75: 16.367,
    p85: 17.034,
    p90: 17.518,
    p95: 18.289,
    p97: 18.827,
    p99: 19.938,
    p999: 22.187
  },
  {
    month: 70,
    l: -1.0464,
    m: 15.2592,
    s: 0.10104,
    p01: 11.646,
    p1: 12.367,
    p3: 12.831,
    p5: 13.091,
    p10: 13.514,
    p15: 13.816,
    p25: 14.287,
    p50: 15.259,
    p75: 16.377,
    p85: 17.049,
    p90: 17.537,
    p95: 18.316,
    p97: 18.86,
    p99: 19.985,
    p999: 22.27
  },
  {
    month: 71,
    l: -1.063,
    m: 15.2641,
    s: 0.10149,
    p01: 11.645,
    p1: 12.365,
    p3: 12.829,
    p5: 13.09,
    p10: 13.513,
    p15: 13.816,
    p25: 14.288,
    p50: 15.264,
    p75: 16.388,
    p85: 17.065,
    p90: 17.558,
    p95: 18.344,
    p97: 18.894,
    p99: 20.033,
    p999: 22.354
  },
  {
    month: 72,
    l: -1.0794,
    m: 15.2697,
    s: 0.10195,
    p01: 11.643,
    p1: 12.363,
    p3: 12.828,
    p5: 13.089,
    p10: 13.513,
    p15: 13.816,
    p25: 14.29,
    p50: 15.27,
    p75: 16.401,
    p85: 17.083,
    p90: 17.579,
    p95: 18.373,
    p97: 18.929,
    p99: 20.082,
    p999: 22.441
  },
  {
    month: 73,
    l: -1.0956,
    m: 15.276,
    s: 0.10241,
    p01: 11.642,
    p1: 12.362,
    p3: 12.827,
    p5: 13.088,
    p10: 13.513,
    p15: 13.817,
    p25: 14.292,
    p50: 15.276,
    p75: 16.414,
    p85: 17.101,
    p90: 17.601,
    p95: 18.403,
    p97: 18.966,
    p99: 20.133,
    p999: 22.531
  },
  {
    month: 74,
    l: -1.1115,
    m: 15.2831,
    s: 0.10287,
    p01: 11.641,
    p1: 12.361,
    p3: 12.826,
    p5: 13.088,
    p10: 13.514,
    p15: 13.818,
    p25: 14.295,
    p50: 15.283,
    p75: 16.427,
    p85: 17.12,
    p90: 17.625,
    p95: 18.434,
    p97: 19.003,
    p99: 20.186,
    p999: 22.623
  },
  {
    month: 75,
    l: -1.1272,
    m: 15.2911,
    s: 0.10333,
    p01: 11.641,
    p1: 12.361,
    p3: 12.827,
    p5: 13.089,
    p10: 13.516,
    p15: 13.821,
    p25: 14.299,
    p50: 15.291,
    p75: 16.442,
    p85: 17.14,
    p90: 17.649,
    p95: 18.466,
    p97: 19.042,
    p99: 20.239,
    p999: 22.717
  },
  {
    month: 76,
    l: -1.1427,
    m: 15.2998,
    s: 0.10379,
    p01: 11.642,
    p1: 12.362,
    p3: 12.828,
    p5: 13.09,
    p10: 13.518,
    p15: 13.824,
    p25: 14.303,
    p50: 15.3,
    p75: 16.458,
    p85: 17.161,
    p90: 17.674,
    p95: 18.5,
    p97: 19.081,
    p99: 20.295,
    p999: 22.813
  },
  {
    month: 77,
    l: -1.1579,
    m: 15.3095,
    s: 0.10425,
    p01: 11.643,
    p1: 12.363,
    p3: 12.83,
    p5: 13.093,
    p10: 13.521,
    p15: 13.828,
    p25: 14.309,
    p50: 15.31,
    p75: 16.475,
    p85: 17.183,
    p90: 17.701,
    p95: 18.534,
    p97: 19.123,
    p99: 20.352,
    p999: 22.912
  },
  {
    month: 78,
    l: -1.1728,
    m: 15.32,
    s: 0.10471,
    p01: 11.645,
    p1: 12.365,
    p3: 12.832,
    p5: 13.096,
    p10: 13.525,
    p15: 13.832,
    p25: 14.315,
    p50: 15.32,
    p75: 16.492,
    p85: 17.206,
    p90: 17.728,
    p95: 18.57,
    p97: 19.165,
    p99: 20.41,
    p999: 23.013
  },
  {
    month: 79,
    l: -1.1875,
    m: 15.3314,
    s: 0.10517,
    p01: 11.647,
    p1: 12.368,
    p3: 12.836,
    p5: 13.099,
    p10: 13.53,
    p15: 13.838,
    p25: 14.322,
    p50: 15.331,
    p75: 16.511,
    p85: 17.23,
    p90: 17.757,
    p95: 18.607,
    p97: 19.209,
    p99: 20.47,
    p999: 23.117
  },
  {
    month: 80,
    l: -1.2019,
    m: 15.3439,
    s: 0.10562,
    p01: 11.651,
    p1: 12.372,
    p3: 12.84,
    p5: 13.104,
    p10: 13.535,
    p15: 13.844,
    p25: 14.33,
    p50: 15.344,
    p75: 16.53,
    p85: 17.255,
    p90: 17.787,
    p95: 18.645,
    p97: 19.254,
    p99: 20.531,
    p999: 23.222
  },
  {
    month: 81,
    l: -1.216,
    m: 15.3572,
    s: 0.10608,
    p01: 11.654,
    p1: 12.376,
    p3: 12.845,
    p5: 13.11,
    p10: 13.542,
    p15: 13.851,
    p25: 14.339,
    p50: 15.357,
    p75: 16.551,
    p85: 17.281,
    p90: 17.818,
    p95: 18.685,
    p97: 19.3,
    p99: 20.594,
    p999: 23.331
  },
  {
    month: 82,
    l: -1.2298,
    m: 15.3717,
    s: 0.10654,
    p01: 11.659,
    p1: 12.381,
    p3: 12.851,
    p5: 13.116,
    p10: 13.549,
    p15: 13.86,
    p25: 14.349,
    p50: 15.372,
    p75: 16.573,
    p85: 17.309,
    p90: 17.85,
    p95: 18.726,
    p97: 19.348,
    p99: 20.659,
    p999: 23.443
  },
  {
    month: 83,
    l: -1.2433,
    m: 15.3871,
    s: 0.107,
    p01: 11.664,
    p1: 12.387,
    p3: 12.857,
    p5: 13.123,
    p10: 13.557,
    p15: 13.869,
    p25: 14.36,
    p50: 15.387,
    p75: 16.596,
    p85: 17.337,
    p90: 17.883,
    p95: 18.768,
    p97: 19.397,
    p99: 20.726,
    p999: 23.557
  },
  {
    month: 84,
    l: -1.2565,
    m: 15.4036,
    s: 0.10746,
    p01: 11.671,
    p1: 12.394,
    p3: 12.865,
    p5: 13.131,
    p10: 13.566,
    p15: 13.879,
    p25: 14.371,
    p50: 15.404,
    p75: 16.62,
    p85: 17.367,
    p90: 17.918,
    p95: 18.812,
    p97: 19.448,
    p99: 20.794,
    p999: 23.674
  },
  {
    month: 85,
    l: -1.2693,
    m: 15.4211,
    s: 0.10792,
    p01: 11.677,
    p1: 12.401,
    p3: 12.873,
    p5: 13.14,
    p10: 13.576,
    p15: 13.89,
    p25: 14.384,
    p50: 15.421,
    p75: 16.645,
    p85: 17.398,
    p90: 17.954,
    p95: 18.857,
    p97: 19.501,
    p99: 20.864,
    p999: 23.793
  },
  {
    month: 86,
    l: -1.2819,
    m: 15.4397,
    s: 0.10837,
    p01: 11.685,
    p1: 12.41,
    p3: 12.882,
    p5: 13.15,
    p10: 13.587,
    p15: 13.902,
    p25: 14.398,
    p50: 15.44,
    p75: 16.671,
    p85: 17.43,
    p90: 17.991,
    p95: 18.903,
    p97: 19.554,
    p99: 20.935,
    p999: 23.914
  },
  {
    month: 87,
    l: -1.2941,
    m: 15.4593,
    s: 0.10883,
    p01: 11.693,
    p1: 12.419,
    p3: 12.892,
    p5: 13.16,
    p10: 13.599,
    p15: 13.914,
    p25: 14.412,
    p50: 15.459,
    p75: 16.699,
    p85: 17.464,
    p90: 18.03,
    p95: 18.951,
    p97: 19.609,
    p99: 21.009,
    p999: 24.039
  },
  {
    month: 88,
    l: -1.306,
    m: 15.4798,
    s: 0.10929,
    p01: 11.702,
    p1: 12.429,
    p3: 12.903,
    p5: 13.172,
    p10: 13.611,
    p15: 13.928,
    p25: 14.428,
    p50: 15.48,
    p75: 16.727,
    p85: 17.499,
    p90: 18.069,
    p95: 19,
    p97: 19.666,
    p99: 21.084,
    p999: 24.167
  },
  {
    month: 89,
    l: -1.3175,
    m: 15.5014,
    s: 0.10974,
    p01: 11.712,
    p1: 12.439,
    p3: 12.915,
    p5: 13.184,
    p10: 13.625,
    p15: 13.943,
    p25: 14.444,
    p50: 15.501,
    p75: 16.757,
    p85: 17.534,
    p90: 18.11,
    p95: 19.05,
    p97: 19.723,
    p99: 21.16,
    p999: 24.295
  },
  {
    month: 90,
    l: -1.3287,
    m: 15.524,
    s: 0.1102,
    p01: 11.722,
    p1: 12.451,
    p3: 12.927,
    p5: 13.197,
    p10: 13.639,
    p15: 13.958,
    p25: 14.462,
    p50: 15.524,
    p75: 16.788,
    p85: 17.571,
    p90: 18.152,
    p95: 19.102,
    p97: 19.783,
    p99: 21.239,
    p999: 24.428
  },
  {
    month: 91,
    l: -1.3395,
    m: 15.5476,
    s: 0.11065,
    p01: 11.733,
    p1: 12.463,
    p3: 12.94,
    p5: 13.211,
    p10: 13.655,
    p15: 13.974,
    p25: 14.48,
    p50: 15.548,
    p75: 16.819,
    p85: 17.609,
    p90: 18.195,
    p95: 19.154,
    p97: 19.843,
    p99: 21.318,
    p999: 24.562
  },
  {
    month: 92,
    l: -1.3499,
    m: 15.5723,
    s: 0.1111,
    p01: 11.745,
    p1: 12.476,
    p3: 12.954,
    p5: 13.226,
    p10: 13.671,
    p15: 13.992,
    p25: 14.499,
    p50: 15.572,
    p75: 16.852,
    p85: 17.648,
    p90: 18.24,
    p95: 19.208,
    p97: 19.905,
    p99: 21.399,
    p999: 24.698
  },
  {
    month: 93,
    l: -1.36,
    m: 15.5979,
    s: 0.11156,
    p01: 11.757,
    p1: 12.489,
    p3: 12.969,
    p5: 13.241,
    p10: 13.688,
    p15: 14.01,
    p25: 14.52,
    p50: 15.598,
    p75: 16.887,
    p85: 17.689,
    p90: 18.286,
    p95: 19.264,
    p97: 19.969,
    p99: 21.483,
    p999: 24.838
  },
  {
    month: 94,
    l: -1.3697,
    m: 15.6246,
    s: 0.11201,
    p01: 11.77,
    p1: 12.504,
    p3: 12.984,
    p5: 13.257,
    p10: 13.706,
    p15: 14.029,
    p25: 14.541,
    p50: 15.625,
    p75: 16.922,
    p85: 17.73,
    p90: 18.333,
    p95: 19.321,
    p97: 20.034,
    p99: 21.567,
    p999: 24.979
  },
  {
    month: 95,
    l: -1.379,
    m: 15.6523,
    s: 0.11246,
    p01: 11.783,
    p1: 12.519,
    p3: 13.001,
    p5: 13.275,
    p10: 13.724,
    p15: 14.049,
    p25: 14.563,
    p50: 15.652,
    p75: 16.958,
    p85: 17.773,
    p90: 18.381,
    p95: 19.379,
    p97: 20.1,
    p99: 21.653,
    p999: 25.122
  },
  {
    month: 96,
    l: -1.388,
    m: 15.681,
    s: 0.11291,
    p01: 11.798,
    p1: 12.535,
    p3: 13.018,
    p5: 13.293,
    p10: 13.744,
    p15: 14.07,
    p25: 14.586,
    p50: 15.681,
    p75: 16.995,
    p85: 17.817,
    p90: 18.43,
    p95: 19.438,
    p97: 20.167,
    p99: 21.74,
    p999: 25.268
  },
  {
    month: 97,
    l: -1.3966,
    m: 15.7107,
    s: 0.11335,
    p01: 11.813,
    p1: 12.551,
    p3: 13.036,
    p5: 13.312,
    p10: 13.764,
    p15: 14.091,
    p25: 14.61,
    p50: 15.711,
    p75: 17.034,
    p85: 17.862,
    p90: 18.48,
    p95: 19.498,
    p97: 20.235,
    p99: 21.828,
    p999: 25.415
  },
  {
    month: 98,
    l: -1.4047,
    m: 15.7415,
    s: 0.1138,
    p01: 11.828,
    p1: 12.569,
    p3: 13.055,
    p5: 13.331,
    p10: 13.786,
    p15: 14.114,
    p25: 14.635,
    p50: 15.742,
    p75: 17.073,
    p85: 17.908,
    p90: 18.532,
    p95: 19.56,
    p97: 20.305,
    p99: 21.919,
    p999: 25.565
  },
  {
    month: 99,
    l: -1.4125,
    m: 15.7732,
    s: 0.11424,
    p01: 11.845,
    p1: 12.587,
    p3: 13.075,
    p5: 13.352,
    p10: 13.808,
    p15: 14.137,
    p25: 14.661,
    p50: 15.773,
    p75: 17.114,
    p85: 17.955,
    p90: 18.585,
    p95: 19.623,
    p97: 20.376,
    p99: 22.01,
    p999: 25.716
  },
  {
    month: 100,
    l: -1.4199,
    m: 15.8058,
    s: 0.11469,
    p01: 11.862,
    p1: 12.605,
    p3: 13.095,
    p5: 13.373,
    p10: 13.831,
    p15: 14.162,
    p25: 14.687,
    p50: 15.806,
    p75: 17.156,
    p85: 18.004,
    p90: 18.639,
    p95: 19.687,
    p97: 20.449,
    p99: 22.103,
    p999: 25.871
  },
  {
    month: 101,
    l: -1.427,
    m: 15.8394,
    s: 0.11513,
    p01: 11.879,
    p1: 12.625,
    p3: 13.116,
    p5: 13.395,
    p10: 13.854,
    p15: 14.187,
    p25: 14.715,
    p50: 15.839,
    p75: 17.198,
    p85: 18.053,
    p90: 18.693,
    p95: 19.752,
    p97: 20.522,
    p99: 22.198,
    p999: 26.026
  },
  {
    month: 102,
    l: -1.4336,
    m: 15.8738,
    s: 0.11557,
    p01: 11.897,
    p1: 12.645,
    p3: 13.137,
    p5: 13.418,
    p10: 13.879,
    p15: 14.213,
    p25: 14.743,
    p50: 15.874,
    p75: 17.242,
    p85: 18.103,
    p90: 18.749,
    p95: 19.818,
    p97: 20.597,
    p99: 22.293,
    p999: 26.183
  },
  {
    month: 103,
    l: -1.4398,
    m: 15.909,
    s: 0.11601,
    p01: 11.915,
    p1: 12.666,
    p3: 13.159,
    p5: 13.441,
    p10: 13.904,
    p15: 14.239,
    p25: 14.772,
    p50: 15.909,
    p75: 17.286,
    p85: 18.154,
    p90: 18.806,
    p95: 19.885,
    p97: 20.672,
    p99: 22.389,
    p999: 26.341
  },
  {
    month: 104,
    l: -1.4456,
    m: 15.9451,
    s: 0.11644,
    p01: 11.934,
    p1: 12.687,
    p3: 13.182,
    p5: 13.465,
    p10: 13.93,
    p15: 14.266,
    p25: 14.802,
    p50: 15.945,
    p75: 17.331,
    p85: 18.206,
    p90: 18.864,
    p95: 19.953,
    p97: 20.748,
    p99: 22.486,
    p999: 26.499
  },
  {
    month: 105,
    l: -1.4511,
    m: 15.9818,
    s: 0.11688,
    p01: 11.954,
    p1: 12.708,
    p3: 13.206,
    p5: 13.489,
    p10: 13.956,
    p15: 14.294,
    p25: 14.832,
    p50: 15.982,
    p75: 17.377,
    p85: 18.259,
    p90: 18.922,
    p95: 20.022,
    p97: 20.826,
    p99: 22.584,
    p999: 26.661
  },
  {
    month: 106,
    l: -1.4561,
    m: 16.0194,
    s: 0.11731,
    p01: 11.974,
    p1: 12.731,
    p3: 13.23,
    p5: 13.514,
    p10: 13.983,
    p15: 14.323,
    p25: 14.864,
    p50: 16.019,
    p75: 17.424,
    p85: 18.313,
    p90: 18.982,
    p95: 20.092,
    p97: 20.904,
    p99: 22.683,
    p999: 26.821
  },
  {
    month: 107,
    l: -1.4607,
    m: 16.0575,
    s: 0.11774,
    p01: 11.994,
    p1: 12.753,
    p3: 13.254,
    p5: 13.54,
    p10: 14.011,
    p15: 14.352,
    p25: 14.895,
    p50: 16.058,
    p75: 17.472,
    p85: 18.367,
    p90: 19.042,
    p95: 20.162,
    p97: 20.983,
    p99: 22.783,
    p999: 26.983
  },
  {
    month: 108,
    l: -1.465,
    m: 16.0964,
    s: 0.11816,
    p01: 12.014,
    p1: 12.776,
    p3: 13.279,
    p5: 13.566,
    p10: 14.039,
    p15: 14.382,
    p25: 14.928,
    p50: 16.096,
    p75: 17.52,
    p85: 18.422,
    p90: 19.102,
    p95: 20.233,
    p97: 21.062,
    p99: 22.882,
    p999: 27.144
  },
  {
    month: 109,
    l: -1.4688,
    m: 16.1358,
    s: 0.11859,
    p01: 12.035,
    p1: 12.8,
    p3: 13.305,
    p5: 13.592,
    p10: 14.067,
    p15: 14.412,
    p25: 14.96,
    p50: 16.136,
    p75: 17.569,
    p85: 18.478,
    p90: 19.164,
    p95: 20.305,
    p97: 21.142,
    p99: 22.983,
    p999: 27.308
  },
  {
    month: 110,
    l: -1.4723,
    m: 16.1759,
    s: 0.11901,
    p01: 12.057,
    p1: 12.824,
    p3: 13.331,
    p5: 13.62,
    p10: 14.097,
    p15: 14.443,
    p25: 14.994,
    p50: 16.176,
    p75: 17.618,
    p85: 18.535,
    p90: 19.226,
    p95: 20.378,
    p97: 21.223,
    p99: 23.084,
    p999: 27.471
  },
  {
    month: 111,
    l: -1.4753,
    m: 16.2166,
    s: 0.11943,
    p01: 12.078,
    p1: 12.848,
    p3: 13.357,
    p5: 13.647,
    p10: 14.126,
    p15: 14.474,
    p25: 15.028,
    p50: 16.217,
    p75: 17.669,
    p85: 18.592,
    p90: 19.289,
    p95: 20.451,
    p97: 21.304,
    p99: 23.186,
    p999: 27.633
  },
  {
    month: 112,
    l: -1.478,
    m: 16.258,
    s: 0.11985,
    p01: 12.1,
    p1: 12.873,
    p3: 13.384,
    p5: 13.675,
    p10: 14.156,
    p15: 14.506,
    p25: 15.063,
    p50: 16.258,
    p75: 17.72,
    p85: 18.65,
    p90: 19.352,
    p95: 20.524,
    p97: 21.386,
    p99: 23.289,
    p999: 27.798
  },
  {
    month: 113,
    l: -1.4803,
    m: 16.2999,
    s: 0.12026,
    p01: 12.122,
    p1: 12.898,
    p3: 13.411,
    p5: 13.704,
    p10: 14.187,
    p15: 14.538,
    p25: 15.098,
    p50: 16.3,
    p75: 17.771,
    p85: 18.708,
    p90: 19.416,
    p95: 20.598,
    p97: 21.468,
    p99: 23.391,
    p999: 27.96
  },
  {
    month: 114,
    l: -1.4823,
    m: 16.3425,
    s: 0.12067,
    p01: 12.145,
    p1: 12.924,
    p3: 13.439,
    p5: 13.733,
    p10: 14.218,
    p15: 14.571,
    p25: 15.134,
    p50: 16.343,
    p75: 17.823,
    p85: 18.767,
    p90: 19.481,
    p95: 20.673,
    p97: 21.551,
    p99: 23.494,
    p999: 28.123
  },
  {
    month: 115,
    l: -1.4838,
    m: 16.3858,
    s: 0.12108,
    p01: 12.168,
    p1: 12.95,
    p3: 13.467,
    p5: 13.762,
    p10: 14.25,
    p15: 14.605,
    p25: 15.17,
    p50: 16.386,
    p75: 17.876,
    p85: 18.827,
    p90: 19.546,
    p95: 20.749,
    p97: 21.635,
    p99: 23.598,
    p999: 28.286
  },
  {
    month: 116,
    l: -1.485,
    m: 16.4298,
    s: 0.12148,
    p01: 12.191,
    p1: 12.976,
    p3: 13.496,
    p5: 13.792,
    p10: 14.283,
    p15: 14.639,
    p25: 15.207,
    p50: 16.43,
    p75: 17.93,
    p85: 18.887,
    p90: 19.612,
    p95: 20.825,
    p97: 21.719,
    p99: 23.701,
    p999: 28.448
  },
  {
    month: 117,
    l: -1.4859,
    m: 16.4746,
    s: 0.12188,
    p01: 12.215,
    p1: 13.003,
    p3: 13.525,
    p5: 13.823,
    p10: 14.316,
    p15: 14.674,
    p25: 15.245,
    p50: 16.475,
    p75: 17.984,
    p85: 18.949,
    p90: 19.679,
    p95: 20.902,
    p97: 21.804,
    p99: 23.806,
    p999: 28.611
  },
  {
    month: 118,
    l: -1.4864,
    m: 16.52,
    s: 0.12228,
    p01: 12.24,
    p1: 13.031,
    p3: 13.555,
    p5: 13.854,
    p10: 14.349,
    p15: 14.709,
    p25: 15.283,
    p50: 16.52,
    p75: 18.039,
    p85: 19.011,
    p90: 19.746,
    p95: 20.979,
    p97: 21.889,
    p99: 23.911,
    p999: 28.774
  },
  {
    month: 119,
    l: -1.4866,
    m: 16.5663,
    s: 0.12268,
    p01: 12.264,
    p1: 13.059,
    p3: 13.585,
    p5: 13.886,
    p10: 14.384,
    p15: 14.745,
    p25: 15.323,
    p50: 16.566,
    p75: 18.096,
    p85: 19.074,
    p90: 19.815,
    p95: 21.058,
    p97: 21.976,
    p99: 24.017,
    p999: 28.937
  },
  {
    month: 120,
    l: -1.4864,
    m: 16.6133,
    s: 0.12307,
    p01: 12.29,
    p1: 13.088,
    p3: 13.616,
    p5: 13.919,
    p10: 14.418,
    p15: 14.782,
    p25: 15.362,
    p50: 16.613,
    p75: 18.152,
    p85: 19.137,
    p90: 19.884,
    p95: 21.137,
    p97: 22.063,
    p99: 24.123,
    p999: 29.098
  },
  {
    month: 121,
    l: -1.4859,
    m: 16.6612,
    s: 0.12346,
    p01: 12.315,
    p1: 13.117,
    p3: 13.648,
    p5: 13.952,
    p10: 14.454,
    p15: 14.819,
    p25: 15.403,
    p50: 16.661,
    p75: 18.21,
    p85: 19.202,
    p90: 19.954,
    p95: 21.217,
    p97: 22.151,
    p99: 24.23,
    p999: 29.26
  },
  {
    month: 122,
    l: -1.4851,
    m: 16.71,
    s: 0.12384,
    p01: 12.342,
    p1: 13.147,
    p3: 13.681,
    p5: 13.986,
    p10: 14.491,
    p15: 14.858,
    p25: 15.445,
    p50: 16.71,
    p75: 18.269,
    p85: 19.267,
    p90: 20.025,
    p95: 21.298,
    p97: 22.239,
    p99: 24.337,
    p999: 29.421
  },
  {
    month: 123,
    l: -1.4839,
    m: 16.7595,
    s: 0.12422,
    p01: 12.368,
    p1: 13.177,
    p3: 13.714,
    p5: 14.02,
    p10: 14.528,
    p15: 14.897,
    p25: 15.487,
    p50: 16.76,
    p75: 18.328,
    p85: 19.334,
    p90: 20.097,
    p95: 21.379,
    p97: 22.328,
    p99: 24.444,
    p999: 29.581
  },
  {
    month: 124,
    l: -1.4825,
    m: 16.81,
    s: 0.1246,
    p01: 12.396,
    p1: 13.208,
    p3: 13.747,
    p5: 14.055,
    p10: 14.566,
    p15: 14.937,
    p25: 15.53,
    p50: 16.81,
    p75: 18.389,
    p85: 19.401,
    p90: 20.17,
    p95: 21.462,
    p97: 22.418,
    p99: 24.553,
    p999: 29.742
  },
  {
    month: 125,
    l: -1.4807,
    m: 16.8614,
    s: 0.12497,
    p01: 12.424,
    p1: 13.24,
    p3: 13.782,
    p5: 14.092,
    p10: 14.604,
    p15: 14.977,
    p25: 15.574,
    p50: 16.861,
    p75: 18.45,
    p85: 19.469,
    p90: 20.244,
    p95: 21.545,
    p97: 22.509,
    p99: 24.661,
    p999: 29.901
  },
  {
    month: 126,
    l: -1.4787,
    m: 16.9136,
    s: 0.12534,
    p01: 12.452,
    p1: 13.272,
    p3: 13.817,
    p5: 14.128,
    p10: 14.644,
    p15: 15.019,
    p25: 15.619,
    p50: 16.914,
    p75: 18.512,
    p85: 19.538,
    p90: 20.318,
    p95: 21.629,
    p97: 22.601,
    p99: 24.77,
    p999: 30.06
  },
  {
    month: 127,
    l: -1.4763,
    m: 16.9667,
    s: 0.12571,
    p01: 12.481,
    p1: 13.305,
    p3: 13.852,
    p5: 14.165,
    p10: 14.684,
    p15: 15.061,
    p25: 15.664,
    p50: 16.967,
    p75: 18.575,
    p85: 19.608,
    p90: 20.394,
    p95: 21.714,
    p97: 22.693,
    p99: 24.88,
    p999: 30.219
  },
  {
    month: 128,
    l: -1.4737,
    m: 17.0208,
    s: 0.12607,
    p01: 12.511,
    p1: 13.339,
    p3: 13.889,
    p5: 14.204,
    p10: 14.724,
    p15: 15.104,
    p25: 15.71,
    p50: 17.021,
    p75: 18.64,
    p85: 19.679,
    p90: 20.47,
    p95: 21.8,
    p97: 22.786,
    p99: 24.991,
    p999: 30.377
  },
  {
    month: 129,
    l: -1.4708,
    m: 17.0757,
    s: 0.12643,
    p01: 12.54,
    p1: 13.373,
    p3: 13.926,
    p5: 14.242,
    p10: 14.766,
    p15: 15.147,
    p25: 15.758,
    p50: 17.076,
    p75: 18.705,
    p85: 19.751,
    p90: 20.547,
    p95: 21.887,
    p97: 22.88,
    p99: 25.102,
    p999: 30.534
  },
  {
    month: 130,
    l: -1.4677,
    m: 17.1316,
    s: 0.12678,
    p01: 12.571,
    p1: 13.408,
    p3: 13.964,
    p5: 14.282,
    p10: 14.809,
    p15: 15.192,
    p25: 15.806,
    p50: 17.132,
    p75: 18.771,
    p85: 19.824,
    p90: 20.626,
    p95: 21.974,
    p97: 22.975,
    p99: 25.213,
    p999: 30.69
  },
  {
    month: 131,
    l: -1.4642,
    m: 17.1883,
    s: 0.12713,
    p01: 12.602,
    p1: 13.444,
    p3: 14.002,
    p5: 14.322,
    p10: 14.852,
    p15: 15.237,
    p25: 15.854,
    p50: 17.188,
    p75: 18.838,
    p85: 19.898,
    p90: 20.705,
    p95: 22.063,
    p97: 23.07,
    p99: 25.325,
    p999: 30.845
  },
  {
    month: 132,
    l: -1.4606,
    m: 17.2459,
    s: 0.12748,
    p01: 12.634,
    p1: 13.48,
    p3: 14.041,
    p5: 14.363,
    p10: 14.896,
    p15: 15.283,
    p25: 15.904,
    p50: 17.246,
    p75: 18.906,
    p85: 19.973,
    p90: 20.785,
    p95: 22.152,
    p97: 23.167,
    p99: 25.438,
    p999: 31.002
  },
  {
    month: 133,
    l: -1.4567,
    m: 17.3044,
    s: 0.12782,
    p01: 12.666,
    p1: 13.516,
    p3: 14.081,
    p5: 14.405,
    p10: 14.94,
    p15: 15.33,
    p25: 15.955,
    p50: 17.304,
    p75: 18.974,
    p85: 20.048,
    p90: 20.866,
    p95: 22.242,
    p97: 23.264,
    p99: 25.55,
    p999: 31.155
  },
  {
    month: 134,
    l: -1.4526,
    m: 17.3637,
    s: 0.12816,
    p01: 12.699,
    p1: 13.554,
    p3: 14.122,
    p5: 14.447,
    p10: 14.986,
    p15: 15.378,
    p25: 16.006,
    p50: 17.364,
    p75: 19.044,
    p85: 20.125,
    p90: 20.948,
    p95: 22.333,
    p97: 23.361,
    p99: 25.664,
    p999: 31.309
  },
  {
    month: 135,
    l: -1.4482,
    m: 17.4238,
    s: 0.12849,
    p01: 12.732,
    p1: 13.592,
    p3: 14.163,
    p5: 14.49,
    p10: 15.032,
    p15: 15.426,
    p25: 16.058,
    p50: 17.424,
    p75: 19.114,
    p85: 20.202,
    p90: 21.03,
    p95: 22.424,
    p97: 23.459,
    p99: 25.777,
    p999: 31.46
  },
  {
    month: 136,
    l: -1.4436,
    m: 17.4847,
    s: 0.12882,
    p01: 12.766,
    p1: 13.63,
    p3: 14.205,
    p5: 14.533,
    p10: 15.078,
    p15: 15.475,
    p25: 16.11,
    p50: 17.485,
    p75: 19.186,
    p85: 20.28,
    p90: 21.113,
    p95: 22.516,
    p97: 23.558,
    p99: 25.891,
    p999: 31.612
  },
  {
    month: 137,
    l: -1.4389,
    m: 17.5464,
    s: 0.12914,
    p01: 12.801,
    p1: 13.669,
    p3: 14.247,
    p5: 14.578,
    p10: 15.126,
    p15: 15.525,
    p25: 16.164,
    p50: 17.546,
    p75: 19.258,
    p85: 20.359,
    p90: 21.197,
    p95: 22.609,
    p97: 23.658,
    p99: 26.005,
    p999: 31.761
  },
  {
    month: 138,
    l: -1.4339,
    m: 17.6088,
    s: 0.12946,
    p01: 12.835,
    p1: 13.709,
    p3: 14.29,
    p5: 14.623,
    p10: 15.174,
    p15: 15.575,
    p25: 16.218,
    p50: 17.609,
    p75: 19.331,
    p85: 20.439,
    p90: 21.282,
    p95: 22.703,
    p97: 23.758,
    p99: 26.12,
    p999: 31.91
  },
  {
    month: 139,
    l: -1.4288,
    m: 17.6719,
    s: 0.12978,
    p01: 12.87,
    p1: 13.749,
    p3: 14.333,
    p5: 14.668,
    p10: 15.222,
    p15: 15.626,
    p25: 16.273,
    p50: 17.672,
    p75: 19.404,
    p85: 20.519,
    p90: 21.368,
    p95: 22.797,
    p97: 23.858,
    p99: 26.235,
    p999: 32.06
  },
  {
    month: 140,
    l: -1.4235,
    m: 17.7357,
    s: 0.13009,
    p01: 12.906,
    p1: 13.79,
    p3: 14.377,
    p5: 14.714,
    p10: 15.271,
    p15: 15.678,
    p25: 16.328,
    p50: 17.736,
    p75: 19.478,
    p85: 20.6,
    p90: 21.454,
    p95: 22.892,
    p97: 23.959,
    p99: 26.35,
    p999: 32.206
  },
  {
    month: 141,
    l: -1.418,
    m: 17.8001,
    s: 0.1304,
    p01: 12.942,
    p1: 13.831,
    p3: 14.422,
    p5: 14.76,
    p10: 15.321,
    p15: 15.73,
    p25: 16.384,
    p50: 17.8,
    p75: 19.553,
    p85: 20.682,
    p90: 21.54,
    p95: 22.987,
    p97: 24.061,
    p99: 26.465,
    p999: 32.353
  },
  {
    month: 142,
    l: -1.4123,
    m: 17.8651,
    s: 0.1307,
    p01: 12.978,
    p1: 13.872,
    p3: 14.467,
    p5: 14.807,
    p10: 15.371,
    p15: 15.782,
    p25: 16.441,
    p50: 17.865,
    p75: 19.629,
    p85: 20.764,
    p90: 21.628,
    p95: 23.082,
    p97: 24.162,
    p99: 26.579,
    p999: 32.496
  },
  {
    month: 143,
    l: -1.4065,
    m: 17.9306,
    s: 0.13099,
    p01: 13.015,
    p1: 13.914,
    p3: 14.512,
    p5: 14.855,
    p10: 15.422,
    p15: 15.836,
    p25: 16.498,
    p50: 17.931,
    p75: 19.705,
    p85: 20.846,
    p90: 21.715,
    p95: 23.178,
    p97: 24.264,
    p99: 26.694,
    p999: 32.638
  },
  {
    month: 144,
    l: -1.4006,
    m: 17.9966,
    s: 0.13129,
    p01: 13.052,
    p1: 13.956,
    p3: 14.558,
    p5: 14.902,
    p10: 15.473,
    p15: 15.889,
    p25: 16.555,
    p50: 17.997,
    p75: 19.781,
    p85: 20.929,
    p90: 21.803,
    p95: 23.275,
    p97: 24.366,
    p99: 26.809,
    p999: 32.781
  },
  {
    month: 145,
    l: -1.3945,
    m: 18.063,
    s: 0.13158,
    p01: 13.089,
    p1: 13.999,
    p3: 14.604,
    p5: 14.95,
    p10: 15.524,
    p15: 15.943,
    p25: 16.613,
    p50: 18.063,
    p75: 19.858,
    p85: 21.013,
    p90: 21.892,
    p95: 23.371,
    p97: 24.469,
    p99: 26.924,
    p999: 32.922
  },
  {
    month: 146,
    l: -1.3883,
    m: 18.1297,
    s: 0.13186,
    p01: 13.126,
    p1: 14.041,
    p3: 14.65,
    p5: 14.999,
    p10: 15.576,
    p15: 15.997,
    p25: 16.671,
    p50: 18.13,
    p75: 19.935,
    p85: 21.096,
    p90: 21.98,
    p95: 23.467,
    p97: 24.571,
    p99: 27.038,
    p999: 33.059
  },
  {
    month: 147,
    l: -1.3819,
    m: 18.1967,
    s: 0.13214,
    p01: 13.164,
    p1: 14.084,
    p3: 14.696,
    p5: 15.047,
    p10: 15.628,
    p15: 16.052,
    p25: 16.73,
    p50: 18.197,
    p75: 20.012,
    p85: 21.18,
    p90: 22.069,
    p95: 23.564,
    p97: 24.673,
    p99: 27.152,
    p999: 33.196
  },
  {
    month: 148,
    l: -1.3755,
    m: 18.2639,
    s: 0.13241,
    p01: 13.201,
    p1: 14.127,
    p3: 14.743,
    p5: 15.096,
    p10: 15.68,
    p15: 16.106,
    p25: 16.788,
    p50: 18.264,
    p75: 20.09,
    p85: 21.264,
    p90: 22.157,
    p95: 23.66,
    p97: 24.775,
    p99: 27.265,
    p999: 33.33
  },
  {
    month: 149,
    l: -1.3689,
    m: 18.3312,
    s: 0.13268,
    p01: 13.239,
    p1: 14.17,
    p3: 14.79,
    p5: 15.145,
    p10: 15.733,
    p15: 16.161,
    p25: 16.847,
    p50: 18.331,
    p75: 20.167,
    p85: 21.348,
    p90: 22.246,
    p95: 23.756,
    p97: 24.876,
    p99: 27.378,
    p999: 33.463
  },
  {
    month: 150,
    l: -1.3621,
    m: 18.3986,
    s: 0.13295,
    p01: 13.276,
    p1: 14.213,
    p3: 14.836,
    p5: 15.193,
    p10: 15.785,
    p15: 16.216,
    p25: 16.906,
    p50: 18.399,
    p75: 20.245,
    p85: 21.432,
    p90: 22.335,
    p95: 23.853,
    p97: 24.978,
    p99: 27.49,
    p999: 33.594
  },
  {
    month: 151,
    l: -1.3553,
    m: 18.466,
    s: 0.13321,
    p01: 13.314,
    p1: 14.256,
    p3: 14.883,
    p5: 15.242,
    p10: 15.837,
    p15: 16.271,
    p25: 16.965,
    p50: 18.466,
    p75: 20.323,
    p85: 21.516,
    p90: 22.423,
    p95: 23.948,
    p97: 25.079,
    p99: 27.601,
    p999: 33.723
  },
  {
    month: 152,
    l: -1.3483,
    m: 18.5333,
    s: 0.13347,
    p01: 13.351,
    p1: 14.299,
    p3: 14.93,
    p5: 15.291,
    p10: 15.889,
    p15: 16.325,
    p25: 17.024,
    p50: 18.533,
    p75: 20.4,
    p85: 21.6,
    p90: 22.511,
    p95: 24.044,
    p97: 25.179,
    p99: 27.712,
    p999: 33.85
  },
  {
    month: 153,
    l: -1.3413,
    m: 18.6006,
    s: 0.13372,
    p01: 13.389,
    p1: 14.342,
    p3: 14.976,
    p5: 15.34,
    p10: 15.942,
    p15: 16.38,
    p25: 17.082,
    p50: 18.601,
    p75: 20.477,
    p85: 21.683,
    p90: 22.599,
    p95: 24.139,
    p97: 25.279,
    p99: 27.821,
    p999: 33.974
  },
  {
    month: 154,
    l: -1.3341,
    m: 18.6677,
    s: 0.13397,
    p01: 13.426,
    p1: 14.385,
    p3: 15.023,
    p5: 15.388,
    p10: 15.994,
    p15: 16.435,
    p25: 17.141,
    p50: 18.668,
    p75: 20.555,
    p85: 21.766,
    p90: 22.687,
    p95: 24.233,
    p97: 25.378,
    p99: 27.929,
    p999: 34.097
  },
  {
    month: 155,
    l: -1.3269,
    m: 18.7346,
    s: 0.13421,
    p01: 13.463,
    p1: 14.428,
    p3: 15.069,
    p5: 15.437,
    p10: 16.046,
    p15: 16.489,
    p25: 17.2,
    p50: 18.735,
    p75: 20.631,
    p85: 21.849,
    p90: 22.774,
    p95: 24.327,
    p97: 25.477,
    p99: 28.036,
    p999: 34.216
  },
  {
    month: 156,
    l: -1.3195,
    m: 18.8012,
    s: 0.13445,
    p01: 13.499,
    p1: 14.47,
    p3: 15.115,
    p5: 15.485,
    p10: 16.097,
    p15: 16.544,
    p25: 17.258,
    p50: 18.801,
    p75: 20.708,
    p85: 21.931,
    p90: 22.86,
    p95: 24.42,
    p97: 25.574,
    p99: 28.143,
    p999: 34.333
  },
  {
    month: 157,
    l: -1.3121,
    m: 18.8675,
    s: 0.13469,
    p01: 13.536,
    p1: 14.512,
    p3: 15.161,
    p5: 15.533,
    p10: 16.149,
    p15: 16.598,
    p25: 17.316,
    p50: 18.868,
    p75: 20.784,
    p85: 22.013,
    p90: 22.946,
    p95: 24.513,
    p97: 25.671,
    p99: 28.248,
    p999: 34.449
  },
  {
    month: 158,
    l: -1.3046,
    m: 18.9335,
    s: 0.13492,
    p01: 13.572,
    p1: 14.554,
    p3: 15.207,
    p5: 15.581,
    p10: 16.2,
    p15: 16.651,
    p25: 17.373,
    p50: 18.934,
    p75: 20.859,
    p85: 22.094,
    p90: 23.032,
    p95: 24.605,
    p97: 25.767,
    p99: 28.352,
    p999: 34.561
  },
  {
    month: 159,
    l: -1.297,
    m: 18.9991,
    s: 0.13514,
    p01: 13.608,
    p1: 14.595,
    p3: 15.252,
    p5: 15.628,
    p10: 16.251,
    p15: 16.705,
    p25: 17.431,
    p50: 18.999,
    p75: 20.934,
    p85: 22.175,
    p90: 23.116,
    p95: 24.695,
    p97: 25.862,
    p99: 28.454,
    p999: 34.67
  },
  {
    month: 160,
    l: -1.2894,
    m: 19.0642,
    s: 0.13537,
    p01: 13.643,
    p1: 14.636,
    p3: 15.297,
    p5: 15.675,
    p10: 16.301,
    p15: 16.758,
    p25: 17.488,
    p50: 19.064,
    p75: 21.009,
    p85: 22.255,
    p90: 23.201,
    p95: 24.786,
    p97: 25.956,
    p99: 28.556,
    p999: 34.779
  },
  {
    month: 161,
    l: -1.2816,
    m: 19.1289,
    s: 0.13559,
    p01: 13.678,
    p1: 14.677,
    p3: 15.341,
    p5: 15.722,
    p10: 16.351,
    p15: 16.81,
    p25: 17.544,
    p50: 19.129,
    p75: 21.083,
    p85: 22.335,
    p90: 23.284,
    p95: 24.875,
    p97: 26.05,
    p99: 28.655,
    p999: 34.884
  },
  {
    month: 162,
    l: -1.2739,
    m: 19.1931,
    s: 0.1358,
    p01: 13.713,
    p1: 14.718,
    p3: 15.386,
    p5: 15.768,
    p10: 16.401,
    p15: 16.862,
    p25: 17.6,
    p50: 19.193,
    p75: 21.156,
    p85: 22.413,
    p90: 23.367,
    p95: 24.963,
    p97: 26.141,
    p99: 28.753,
    p999: 34.986
  },
  {
    month: 163,
    l: -1.2661,
    m: 19.2567,
    s: 0.13601,
    p01: 13.748,
    p1: 14.758,
    p3: 15.429,
    p5: 15.814,
    p10: 16.451,
    p15: 16.914,
    p25: 17.656,
    p50: 19.257,
    p75: 21.229,
    p85: 22.491,
    p90: 23.448,
    p95: 25.05,
    p97: 26.232,
    p99: 28.85,
    p999: 35.086
  },
  {
    month: 164,
    l: -1.2583,
    m: 19.3197,
    s: 0.13622,
    p01: 13.781,
    p1: 14.797,
    p3: 15.472,
    p5: 15.859,
    p10: 16.499,
    p15: 16.965,
    p25: 17.711,
    p50: 19.32,
    p75: 21.301,
    p85: 22.569,
    p90: 23.529,
    p95: 25.137,
    p97: 26.322,
    p99: 28.946,
    p999: 35.185
  },
  {
    month: 165,
    l: -1.2504,
    m: 19.382,
    s: 0.13642,
    p01: 13.815,
    p1: 14.836,
    p3: 15.515,
    p5: 15.904,
    p10: 16.547,
    p15: 17.016,
    p25: 17.765,
    p50: 19.382,
    p75: 21.372,
    p85: 22.645,
    p90: 23.609,
    p95: 25.222,
    p97: 26.41,
    p99: 29.039,
    p999: 35.279
  },
  {
    month: 166,
    l: -1.2425,
    m: 19.4437,
    s: 0.13662,
    p01: 13.848,
    p1: 14.875,
    p3: 15.557,
    p5: 15.948,
    p10: 16.595,
    p15: 17.066,
    p25: 17.819,
    p50: 19.444,
    p75: 21.443,
    p85: 22.72,
    p90: 23.688,
    p95: 25.306,
    p97: 26.497,
    p99: 29.132,
    p999: 35.373
  },
  {
    month: 167,
    l: -1.2345,
    m: 19.5045,
    s: 0.13681,
    p01: 13.88,
    p1: 14.913,
    p3: 15.599,
    p5: 15.992,
    p10: 16.642,
    p15: 17.115,
    p25: 17.872,
    p50: 19.504,
    p75: 21.512,
    p85: 22.795,
    p90: 23.765,
    p95: 25.388,
    p97: 26.583,
    p99: 29.222,
    p999: 35.461
  },
  {
    month: 168,
    l: -1.2266,
    m: 19.5647,
    s: 0.137,
    p01: 13.912,
    p1: 14.95,
    p3: 15.64,
    p5: 16.035,
    p10: 16.688,
    p15: 17.164,
    p25: 17.925,
    p50: 19.565,
    p75: 21.581,
    p85: 22.868,
    p90: 23.842,
    p95: 25.47,
    p97: 26.667,
    p99: 29.311,
    p999: 35.549
  },
  {
    month: 169,
    l: -1.2186,
    m: 19.624,
    s: 0.13719,
    p01: 13.943,
    p1: 14.987,
    p3: 15.68,
    p5: 16.077,
    p10: 16.734,
    p15: 17.212,
    p25: 17.977,
    p50: 19.624,
    p75: 21.648,
    p85: 22.94,
    p90: 23.918,
    p95: 25.55,
    p97: 26.75,
    p99: 29.398,
    p999: 35.634
  },
  {
    month: 170,
    l: -1.2107,
    m: 19.6824,
    s: 0.13738,
    p01: 13.973,
    p1: 15.023,
    p3: 15.72,
    p5: 16.119,
    p10: 16.779,
    p15: 17.259,
    p25: 18.028,
    p50: 19.682,
    p75: 21.715,
    p85: 23.012,
    p90: 23.992,
    p95: 25.629,
    p97: 26.832,
    p99: 29.484,
    p999: 35.719
  },
  {
    month: 171,
    l: -1.2027,
    m: 19.74,
    s: 0.13756,
    p01: 14.003,
    p1: 15.058,
    p3: 15.759,
    p5: 16.16,
    p10: 16.823,
    p15: 17.306,
    p25: 18.078,
    p50: 19.74,
    p75: 21.781,
    p85: 23.082,
    p90: 24.065,
    p95: 25.707,
    p97: 26.912,
    p99: 29.568,
    p999: 35.799
  },
  {
    month: 172,
    l: -1.1947,
    m: 19.7966,
    s: 0.13774,
    p01: 14.033,
    p1: 15.093,
    p3: 15.797,
    p5: 16.2,
    p10: 16.867,
    p15: 17.352,
    p25: 18.127,
    p50: 19.797,
    p75: 21.845,
    p85: 23.151,
    p90: 24.137,
    p95: 25.783,
    p97: 26.991,
    p99: 29.65,
    p999: 35.877
  },
  {
    month: 173,
    l: -1.1867,
    m: 19.8523,
    s: 0.13791,
    p01: 14.061,
    p1: 15.127,
    p3: 15.834,
    p5: 16.239,
    p10: 16.909,
    p15: 17.397,
    p25: 18.176,
    p50: 19.852,
    p75: 21.909,
    p85: 23.219,
    p90: 24.208,
    p95: 25.857,
    p97: 27.067,
    p99: 29.729,
    p999: 35.951
  },
  {
    month: 174,
    l: -1.1788,
    m: 19.907,
    s: 0.13808,
    p01: 14.089,
    p1: 15.16,
    p3: 15.871,
    p5: 16.278,
    p10: 16.951,
    p15: 17.441,
    p25: 18.223,
    p50: 19.907,
    p75: 21.971,
    p85: 23.285,
    p90: 24.277,
    p95: 25.93,
    p97: 27.143,
    p99: 29.808,
    p999: 36.024
  },
  {
    month: 175,
    l: -1.1708,
    m: 19.9607,
    s: 0.13825,
    p01: 14.116,
    p1: 15.193,
    p3: 15.907,
    p5: 16.316,
    p10: 16.992,
    p15: 17.484,
    p25: 18.27,
    p50: 19.961,
    p75: 22.032,
    p85: 23.35,
    p90: 24.345,
    p95: 26.002,
    p97: 27.217,
    p99: 29.884,
    p999: 36.094
  },
  {
    month: 176,
    l: -1.1629,
    m: 20.0133,
    s: 0.13841,
    p01: 14.143,
    p1: 15.224,
    p3: 15.942,
    p5: 16.353,
    p10: 17.033,
    p15: 17.527,
    p25: 18.316,
    p50: 20.013,
    p75: 22.092,
    p85: 23.414,
    p90: 24.411,
    p95: 26.072,
    p97: 27.288,
    p99: 29.958,
    p999: 36.161
  },
  {
    month: 177,
    l: -1.1549,
    m: 20.0648,
    s: 0.13858,
    p01: 14.168,
    p1: 15.255,
    p3: 15.976,
    p5: 16.389,
    p10: 17.072,
    p15: 17.568,
    p25: 18.361,
    p50: 20.065,
    p75: 22.151,
    p85: 23.477,
    p90: 24.477,
    p95: 26.141,
    p97: 27.359,
    p99: 30.031,
    p999: 36.227
  },
  {
    month: 178,
    l: -1.147,
    m: 20.1152,
    s: 0.13873,
    p01: 14.194,
    p1: 15.285,
    p3: 16.01,
    p5: 16.425,
    p10: 17.11,
    p15: 17.609,
    p25: 18.404,
    p50: 20.115,
    p75: 22.208,
    p85: 23.538,
    p90: 24.54,
    p95: 26.207,
    p97: 27.427,
    p99: 30.1,
    p999: 36.288
  },
  {
    month: 179,
    l: -1.139,
    m: 20.1644,
    s: 0.13889,
    p01: 14.218,
    p1: 15.314,
    p3: 16.042,
    p5: 16.459,
    p10: 17.147,
    p15: 17.648,
    p25: 18.447,
    p50: 20.164,
    p75: 22.264,
    p85: 23.598,
    p90: 24.602,
    p95: 26.273,
    p97: 27.494,
    p99: 30.169,
    p999: 36.348
  },
  {
    month: 180,
    l: -1.1311,
    m: 20.2125,
    s: 0.13904,
    p01: 14.241,
    p1: 15.343,
    p3: 16.074,
    p5: 16.492,
    p10: 17.184,
    p15: 17.687,
    p25: 18.489,
    p50: 20.212,
    p75: 22.319,
    p85: 23.656,
    p90: 24.663,
    p95: 26.336,
    p97: 27.559,
    p99: 30.235,
    p999: 36.404
  },
  {
    month: 181,
    l: -1.1232,
    m: 20.2595,
    s: 0.1392,
    p01: 14.263,
    p1: 15.37,
    p3: 16.105,
    p5: 16.525,
    p10: 17.219,
    p15: 17.724,
    p25: 18.529,
    p50: 20.26,
    p75: 22.373,
    p85: 23.713,
    p90: 24.722,
    p95: 26.398,
    p97: 27.623,
    p99: 30.3,
    p999: 36.461
  },
  {
    month: 182,
    l: -1.1153,
    m: 20.3053,
    s: 0.13934,
    p01: 14.285,
    p1: 15.397,
    p3: 16.135,
    p5: 16.557,
    p10: 17.254,
    p15: 17.761,
    p25: 18.569,
    p50: 20.305,
    p75: 22.425,
    p85: 23.768,
    p90: 24.779,
    p95: 26.458,
    p97: 27.684,
    p99: 30.361,
    p999: 36.511
  },
  {
    month: 183,
    l: -1.1074,
    m: 20.3499,
    s: 0.13949,
    p01: 14.306,
    p1: 15.423,
    p3: 16.164,
    p5: 16.587,
    p10: 17.287,
    p15: 17.796,
    p25: 18.608,
    p50: 20.35,
    p75: 22.476,
    p85: 23.822,
    p90: 24.836,
    p95: 26.517,
    p97: 27.744,
    p99: 30.422,
    p999: 36.561
  },
  {
    month: 184,
    l: -1.0996,
    m: 20.3934,
    s: 0.13963,
    p01: 14.326,
    p1: 15.448,
    p3: 16.192,
    p5: 16.617,
    p10: 17.32,
    p15: 17.831,
    p25: 18.645,
    p50: 20.393,
    p75: 22.525,
    p85: 23.875,
    p90: 24.89,
    p95: 26.574,
    p97: 27.802,
    p99: 30.48,
    p999: 36.608
  },
  {
    month: 185,
    l: -1.0917,
    m: 20.4357,
    s: 0.13977,
    p01: 14.345,
    p1: 15.472,
    p3: 16.219,
    p5: 16.646,
    p10: 17.352,
    p15: 17.864,
    p25: 18.682,
    p50: 20.436,
    p75: 22.573,
    p85: 23.926,
    p90: 24.943,
    p95: 26.629,
    p97: 27.858,
    p99: 30.536,
    p999: 36.652
  },
  {
    month: 186,
    l: -1.0838,
    m: 20.4769,
    s: 0.13991,
    p01: 14.364,
    p1: 15.495,
    p3: 16.245,
    p5: 16.674,
    p10: 17.382,
    p15: 17.897,
    p25: 18.717,
    p50: 20.477,
    p75: 22.62,
    p85: 23.976,
    p90: 24.995,
    p95: 26.683,
    p97: 27.913,
    p99: 30.591,
    p999: 36.695
  },
  {
    month: 187,
    l: -1.076,
    m: 20.517,
    s: 0.14005,
    p01: 14.381,
    p1: 15.517,
    p3: 16.27,
    p5: 16.701,
    p10: 17.412,
    p15: 17.928,
    p25: 18.752,
    p50: 20.517,
    p75: 22.666,
    p85: 24.025,
    p90: 25.045,
    p95: 26.735,
    p97: 27.966,
    p99: 30.643,
    p999: 36.735
  },
  {
    month: 188,
    l: -1.0681,
    m: 20.556,
    s: 0.14018,
    p01: 14.398,
    p1: 15.539,
    p3: 16.295,
    p5: 16.727,
    p10: 17.441,
    p15: 17.959,
    p25: 18.785,
    p50: 20.556,
    p75: 22.71,
    p85: 24.072,
    p90: 25.094,
    p95: 26.785,
    p97: 28.017,
    p99: 30.693,
    p999: 36.772
  },
  {
    month: 189,
    l: -1.0603,
    m: 20.5938,
    s: 0.14031,
    p01: 14.415,
    p1: 15.56,
    p3: 16.319,
    p5: 16.752,
    p10: 17.469,
    p15: 17.989,
    p25: 18.818,
    p50: 20.594,
    p75: 22.754,
    p85: 24.117,
    p90: 25.141,
    p95: 26.834,
    p97: 28.066,
    p99: 30.742,
    p999: 36.806
  },
  {
    month: 190,
    l: -1.0525,
    m: 20.6306,
    s: 0.14044,
    p01: 14.43,
    p1: 15.58,
    p3: 16.342,
    p5: 16.777,
    p10: 17.496,
    p15: 18.018,
    p25: 18.849,
    p50: 20.631,
    p75: 22.795,
    p85: 24.162,
    p90: 25.187,
    p95: 26.882,
    p97: 28.114,
    p99: 30.789,
    p999: 36.84
  },
  {
    month: 191,
    l: -1.0447,
    m: 20.6663,
    s: 0.14057,
    p01: 14.445,
    p1: 15.599,
    p3: 16.363,
    p5: 16.8,
    p10: 17.522,
    p15: 18.045,
    p25: 18.88,
    p50: 20.666,
    p75: 22.836,
    p85: 24.205,
    p90: 25.231,
    p95: 26.928,
    p97: 28.16,
    p99: 30.834,
    p999: 36.871
  },
  {
    month: 192,
    l: -1.0368,
    m: 20.7008,
    s: 0.1407,
    p01: 14.458,
    p1: 15.617,
    p3: 16.384,
    p5: 16.823,
    p10: 17.547,
    p15: 18.072,
    p25: 18.909,
    p50: 20.701,
    p75: 22.876,
    p85: 24.247,
    p90: 25.274,
    p95: 26.972,
    p97: 28.205,
    p99: 30.877,
    p999: 36.899
  },
  {
    month: 193,
    l: -1.029,
    m: 20.7344,
    s: 0.14082,
    p01: 14.472,
    p1: 15.635,
    p3: 16.405,
    p5: 16.845,
    p10: 17.571,
    p15: 18.098,
    p25: 18.938,
    p50: 20.734,
    p75: 22.914,
    p85: 24.287,
    p90: 25.316,
    p95: 27.015,
    p97: 28.248,
    p99: 30.918,
    p999: 36.925
  },
  {
    month: 194,
    l: -1.0212,
    m: 20.7668,
    s: 0.14094,
    p01: 14.484,
    p1: 15.651,
    p3: 16.424,
    p5: 16.866,
    p10: 17.595,
    p15: 18.123,
    p25: 18.966,
    p50: 20.767,
    p75: 22.951,
    p85: 24.326,
    p90: 25.356,
    p95: 27.056,
    p97: 28.289,
    p99: 30.957,
    p999: 36.948
  },
  {
    month: 195,
    l: -1.0134,
    m: 20.7982,
    s: 0.14106,
    p01: 14.496,
    p1: 15.667,
    p3: 16.443,
    p5: 16.886,
    p10: 17.617,
    p15: 18.148,
    p25: 18.992,
    p50: 20.798,
    p75: 22.987,
    p85: 24.364,
    p90: 25.395,
    p95: 27.096,
    p97: 28.329,
    p99: 30.995,
    p999: 36.97
  },
  {
    month: 196,
    l: -1.0055,
    m: 20.8286,
    s: 0.14118,
    p01: 14.506,
    p1: 15.682,
    p3: 16.461,
    p5: 16.905,
    p10: 17.639,
    p15: 18.171,
    p25: 19.018,
    p50: 20.829,
    p75: 23.021,
    p85: 24.4,
    p90: 25.433,
    p95: 27.134,
    p97: 28.367,
    p99: 31.031,
    p999: 36.989
  },
  {
    month: 197,
    l: -0.9977,
    m: 20.858,
    s: 0.1413,
    p01: 14.517,
    p1: 15.697,
    p3: 16.478,
    p5: 16.924,
    p10: 17.66,
    p15: 18.193,
    p25: 19.043,
    p50: 20.858,
    p75: 23.055,
    p85: 24.436,
    p90: 25.469,
    p95: 27.171,
    p97: 28.404,
    p99: 31.065,
    p999: 37.008
  },
  {
    month: 198,
    l: -0.9898,
    m: 20.8863,
    s: 0.14142,
    p01: 14.526,
    p1: 15.71,
    p3: 16.494,
    p5: 16.941,
    p10: 17.679,
    p15: 18.215,
    p25: 19.067,
    p50: 20.886,
    p75: 23.087,
    p85: 24.47,
    p90: 25.504,
    p95: 27.207,
    p97: 28.439,
    p99: 31.098,
    p999: 37.024
  },
  {
    month: 199,
    l: -0.9819,
    m: 20.9137,
    s: 0.14153,
    p01: 14.535,
    p1: 15.723,
    p3: 16.509,
    p5: 16.958,
    p10: 17.699,
    p15: 18.235,
    p25: 19.09,
    p50: 20.914,
    p75: 23.119,
    p85: 24.503,
    p90: 25.538,
    p95: 27.241,
    p97: 28.473,
    p99: 31.128,
    p999: 37.036
  },
  {
    month: 200,
    l: -0.974,
    m: 20.9401,
    s: 0.14164,
    p01: 14.543,
    p1: 15.735,
    p3: 16.524,
    p5: 16.974,
    p10: 17.717,
    p15: 18.255,
    p25: 19.112,
    p50: 20.94,
    p75: 23.149,
    p85: 24.535,
    p90: 25.57,
    p95: 27.273,
    p97: 28.505,
    p99: 31.157,
    p999: 37.047
  },
  {
    month: 201,
    l: -0.9661,
    m: 20.9656,
    s: 0.14176,
    p01: 14.55,
    p1: 15.746,
    p3: 16.538,
    p5: 16.989,
    p10: 17.734,
    p15: 18.274,
    p25: 19.133,
    p50: 20.966,
    p75: 23.178,
    p85: 24.565,
    p90: 25.601,
    p95: 27.305,
    p97: 28.536,
    p99: 31.186,
    p999: 37.058
  },
  {
    month: 202,
    l: -0.9582,
    m: 20.9901,
    s: 0.14187,
    p01: 14.556,
    p1: 15.757,
    p3: 16.551,
    p5: 17.004,
    p10: 17.751,
    p15: 18.292,
    p25: 19.154,
    p50: 20.99,
    p75: 23.206,
    p85: 24.595,
    p90: 25.631,
    p95: 27.335,
    p97: 28.566,
    p99: 31.212,
    p999: 37.066
  },
  {
    month: 203,
    l: -0.9503,
    m: 21.0138,
    s: 0.14198,
    p01: 14.562,
    p1: 15.767,
    p3: 16.564,
    p5: 17.018,
    p10: 17.767,
    p15: 18.31,
    p25: 19.173,
    p50: 21.014,
    p75: 23.233,
    p85: 24.623,
    p90: 25.66,
    p95: 27.364,
    p97: 28.594,
    p99: 31.236,
    p999: 37.072
  },
  {
    month: 204,
    l: -0.9423,
    m: 21.0367,
    s: 0.14208,
    p01: 14.568,
    p1: 15.777,
    p3: 16.576,
    p5: 17.031,
    p10: 17.783,
    p15: 18.327,
    p25: 19.193,
    p50: 21.037,
    p75: 23.259,
    p85: 24.651,
    p90: 25.688,
    p95: 27.392,
    p97: 28.62,
    p99: 31.259,
    p999: 37.075
  },
  {
    month: 205,
    l: -0.9344,
    m: 21.0587,
    s: 0.14219,
    p01: 14.573,
    p1: 15.785,
    p3: 16.587,
    p5: 17.044,
    p10: 17.797,
    p15: 18.343,
    p25: 19.211,
    p50: 21.059,
    p75: 23.285,
    p85: 24.677,
    p90: 25.715,
    p95: 27.418,
    p97: 28.646,
    p99: 31.281,
    p999: 37.078
  },
  {
    month: 206,
    l: -0.9264,
    m: 21.0801,
    s: 0.1423,
    p01: 14.577,
    p1: 15.793,
    p3: 16.597,
    p5: 17.056,
    p10: 17.811,
    p15: 18.358,
    p25: 19.228,
    p50: 21.08,
    p75: 23.309,
    p85: 24.703,
    p90: 25.741,
    p95: 27.444,
    p97: 28.672,
    p99: 31.302,
    p999: 37.08
  },
  {
    month: 207,
    l: -0.9184,
    m: 21.1007,
    s: 0.1424,
    p01: 14.581,
    p1: 15.801,
    p3: 16.607,
    p5: 17.067,
    p10: 17.825,
    p15: 18.373,
    p25: 19.245,
    p50: 21.101,
    p75: 23.333,
    p85: 24.727,
    p90: 25.766,
    p95: 27.469,
    p97: 28.695,
    p99: 31.321,
    p999: 37.079
  },
  {
    month: 208,
    l: -0.9104,
    m: 21.1206,
    s: 0.1425,
    p01: 14.584,
    p1: 15.808,
    p3: 16.617,
    p5: 17.078,
    p10: 17.838,
    p15: 18.388,
    p25: 19.262,
    p50: 21.121,
    p75: 23.355,
    p85: 24.751,
    p90: 25.79,
    p95: 27.492,
    p97: 28.717,
    p99: 31.339,
    p999: 37.077
  },
  {
    month: 209,
    l: -0.9024,
    m: 21.1399,
    s: 0.14261,
    p01: 14.587,
    p1: 15.815,
    p3: 16.626,
    p5: 17.088,
    p10: 17.85,
    p15: 18.401,
    p25: 19.277,
    p50: 21.14,
    p75: 23.378,
    p85: 24.774,
    p90: 25.813,
    p95: 27.515,
    p97: 28.739,
    p99: 31.357,
    p999: 37.076
  },
  {
    month: 210,
    l: -0.8944,
    m: 21.1586,
    s: 0.14271,
    p01: 14.589,
    p1: 15.821,
    p3: 16.635,
    p5: 17.098,
    p10: 17.862,
    p15: 18.414,
    p25: 19.292,
    p50: 21.159,
    p75: 23.399,
    p85: 24.796,
    p90: 25.836,
    p95: 27.537,
    p97: 28.76,
    p99: 31.373,
    p999: 37.072
  },
  {
    month: 211,
    l: -0.8863,
    m: 21.1768,
    s: 0.14281,
    p01: 14.591,
    p1: 15.827,
    p3: 16.643,
    p5: 17.108,
    p10: 17.873,
    p15: 18.427,
    p25: 19.307,
    p50: 21.177,
    p75: 23.42,
    p85: 24.818,
    p90: 25.857,
    p95: 27.558,
    p97: 28.78,
    p99: 31.388,
    p999: 37.067
  },
  {
    month: 212,
    l: -0.8783,
    m: 21.1944,
    s: 0.14291,
    p01: 14.593,
    p1: 15.832,
    p3: 16.65,
    p5: 17.116,
    p10: 17.884,
    p15: 18.439,
    p25: 19.321,
    p50: 21.194,
    p75: 23.44,
    p85: 24.839,
    p90: 25.878,
    p95: 27.578,
    p97: 28.799,
    p99: 31.403,
    p999: 37.061
  },
  {
    month: 213,
    l: -0.8703,
    m: 21.2116,
    s: 0.14301,
    p01: 14.594,
    p1: 15.837,
    p3: 16.658,
    p5: 17.125,
    p10: 17.895,
    p15: 18.451,
    p25: 19.335,
    p50: 21.212,
    p75: 23.46,
    p85: 24.859,
    p90: 25.899,
    p95: 27.598,
    p97: 28.817,
    p99: 31.417,
    p999: 37.055
  },
  {
    month: 214,
    l: -0.8623,
    m: 21.2282,
    s: 0.14311,
    p01: 14.595,
    p1: 15.842,
    p3: 16.665,
    p5: 17.133,
    p10: 17.905,
    p15: 18.463,
    p25: 19.348,
    p50: 21.228,
    p75: 23.479,
    p85: 24.879,
    p90: 25.919,
    p95: 27.617,
    p97: 28.835,
    p99: 31.43,
    p999: 37.048
  },
  {
    month: 215,
    l: -0.8542,
    m: 21.2444,
    s: 0.1432,
    p01: 14.596,
    p1: 15.846,
    p3: 16.671,
    p5: 17.141,
    p10: 17.915,
    p15: 18.474,
    p25: 19.361,
    p50: 21.244,
    p75: 23.498,
    p85: 24.898,
    p90: 25.938,
    p95: 27.635,
    p97: 28.852,
    p99: 31.441,
    p999: 37.038
  },
  {
    month: 216,
    l: -0.8462,
    m: 21.2603,
    s: 0.1433,
    p01: 14.596,
    p1: 15.85,
    p3: 16.678,
    p5: 17.149,
    p10: 17.924,
    p15: 18.485,
    p25: 19.374,
    p50: 21.26,
    p75: 23.516,
    p85: 24.917,
    p90: 25.957,
    p95: 27.653,
    p97: 28.868,
    p99: 31.453,
    p999: 37.031
  },
  {
    month: 217,
    l: -0.8382,
    m: 21.2757,
    s: 0.1434,
    p01: 14.596,
    p1: 15.854,
    p3: 16.683,
    p5: 17.156,
    p10: 17.933,
    p15: 18.495,
    p25: 19.386,
    p50: 21.276,
    p75: 23.534,
    p85: 24.936,
    p90: 25.975,
    p95: 27.671,
    p97: 28.884,
    p99: 31.464,
    p999: 37.022
  },
  {
    month: 218,
    l: -0.8301,
    m: 21.2908,
    s: 0.14349,
    p01: 14.596,
    p1: 15.857,
    p3: 16.689,
    p5: 17.163,
    p10: 17.942,
    p15: 18.505,
    p25: 19.398,
    p50: 21.291,
    p75: 23.551,
    p85: 24.953,
    p90: 25.993,
    p95: 27.687,
    p97: 28.899,
    p99: 31.474,
    p999: 37.011
  },
  {
    month: 219,
    l: -0.8221,
    m: 21.3055,
    s: 0.14359,
    p01: 14.595,
    p1: 15.86,
    p3: 16.694,
    p5: 17.169,
    p10: 17.95,
    p15: 18.515,
    p25: 19.41,
    p50: 21.306,
    p75: 23.568,
    p85: 24.971,
    p90: 26.01,
    p95: 27.704,
    p97: 28.915,
    p99: 31.484,
    p999: 37.002
  },
  {
    month: 220,
    l: -0.814,
    m: 21.32,
    s: 0.14368,
    p01: 14.594,
    p1: 15.863,
    p3: 16.7,
    p5: 17.176,
    p10: 17.959,
    p15: 18.524,
    p25: 19.421,
    p50: 21.32,
    p75: 23.585,
    p85: 24.988,
    p90: 26.027,
    p95: 27.719,
    p97: 28.929,
    p99: 31.493,
    p999: 36.99
  },
  {
    month: 221,
    l: -0.806,
    m: 21.3341,
    s: 0.14377,
    p01: 14.593,
    p1: 15.866,
    p3: 16.705,
    p5: 17.182,
    p10: 17.967,
    p15: 18.534,
    p25: 19.432,
    p50: 21.334,
    p75: 23.601,
    p85: 25.004,
    p90: 26.043,
    p95: 27.734,
    p97: 28.942,
    p99: 31.502,
    p999: 36.978
  },
  {
    month: 222,
    l: -0.798,
    m: 21.348,
    s: 0.14386,
    p01: 14.592,
    p1: 15.869,
    p3: 16.709,
    p5: 17.188,
    p10: 17.975,
    p15: 18.543,
    p25: 19.443,
    p50: 21.348,
    p75: 23.617,
    p85: 25.021,
    p90: 26.059,
    p95: 27.749,
    p97: 28.956,
    p99: 31.51,
    p999: 36.966
  },
  {
    month: 223,
    l: -0.7899,
    m: 21.3617,
    s: 0.14396,
    p01: 14.59,
    p1: 15.871,
    p3: 16.714,
    p5: 17.193,
    p10: 17.982,
    p15: 18.552,
    p25: 19.454,
    p50: 21.362,
    p75: 23.633,
    p85: 25.037,
    p90: 26.076,
    p95: 27.764,
    p97: 28.969,
    p99: 31.519,
    p999: 36.955
  },
  {
    month: 224,
    l: -0.7819,
    m: 21.3752,
    s: 0.14405,
    p01: 14.589,
    p1: 15.873,
    p3: 16.718,
    p5: 17.199,
    p10: 17.99,
    p15: 18.56,
    p25: 19.464,
    p50: 21.375,
    p75: 23.648,
    p85: 25.053,
    p90: 26.091,
    p95: 27.779,
    p97: 28.982,
    p99: 31.526,
    p999: 36.943
  },
  {
    month: 225,
    l: -0.7738,
    m: 21.3884,
    s: 0.14414,
    p01: 14.587,
    p1: 15.875,
    p3: 16.722,
    p5: 17.204,
    p10: 17.997,
    p15: 18.569,
    p25: 19.475,
    p50: 21.388,
    p75: 23.663,
    p85: 25.068,
    p90: 26.106,
    p95: 27.793,
    p97: 28.995,
    p99: 31.533,
    p999: 36.929
  },
  {
    month: 226,
    l: -0.7658,
    m: 21.4014,
    s: 0.14423,
    p01: 14.585,
    p1: 15.876,
    p3: 16.726,
    p5: 17.21,
    p10: 18.004,
    p15: 18.577,
    p25: 19.485,
    p50: 21.401,
    p75: 23.678,
    p85: 25.084,
    p90: 26.121,
    p95: 27.807,
    p97: 29.007,
    p99: 31.54,
    p999: 36.917
  },
  {
    month: 227,
    l: -0.7577,
    m: 21.4143,
    s: 0.14432,
    p01: 14.583,
    p1: 15.878,
    p3: 16.73,
    p5: 17.215,
    p10: 18.011,
    p15: 18.585,
    p25: 19.495,
    p50: 21.414,
    p75: 23.693,
    p85: 25.099,
    p90: 26.136,
    p95: 27.82,
    p97: 29.019,
    p99: 31.547,
    p999: 36.903
  },
  {
    month: 228,
    l: -0.7496,
    m: 21.4269,
    s: 0.14441,
    p01: 14.58,
    p1: 15.879,
    p3: 16.734,
    p5: 17.22,
    p10: 18.017,
    p15: 18.593,
    p25: 19.504,
    p50: 21.427,
    p75: 23.707,
    p85: 25.113,
    p90: 26.151,
    p95: 27.833,
    p97: 29.03,
    p99: 31.553,
    p999: 36.889
  }
]

export default bmiValues
