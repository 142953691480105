import React from 'react'
import { useSearchParams } from 'react-router-dom'

const DEFAULT_OFFSET = 0
const DEFAULT_LIMIT = 10

interface Params {
  defaultQ?: string
  defaultOffset?: number
  defaultLimit?: number
}

export type Filter = {
  q: string
  offset: number
  limit: number
}
export const usePagination = (params?: Params) => {
  const [q, setQ] = React.useState<string>(params?.defaultQ ?? '')
  const [offset, setOffset] = React.useState<number>(params?.defaultOffset ?? DEFAULT_OFFSET)
  const [limit, setLimit] = React.useState<number>(params?.defaultLimit ?? DEFAULT_LIMIT)

  const onLimitChange = (newLimit: number) => {
    setLimit(isNaN(newLimit) ? 0 : newLimit)
  }

  const onPageChange = (newPage: number) => {
    setOffset(isNaN(newPage * limit) ? 0 : newPage * limit)
  }

  const onSearch = (newQ: string) => {
    setQ(newQ)
    setOffset(0)
  }

  const filter: Filter = {
    q,
    offset,
    limit,
  }

  const page = Math.ceil(filter.offset / filter.limit)

  return {
    onLimitChange,
    onPageChange,
    onSearch,
    filter,
    page,
  }
}

export const useURLPagination = (params?: Params) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { defaultLimit, defaultOffset, defaultQ } = useInitialValuesFromURL(searchParams, params)

  const [q, setQ] = React.useState<string>(defaultQ)
  const [offset, setOffset] = React.useState<number>(defaultOffset)
  const [limit, setLimit] = React.useState<number>(defaultLimit)

  const onLimitChange = (newLimit: number) => {
    setLimit(isNaN(newLimit) ? 0 : newLimit)
  }

  const onPageChange = (newPage: number) => {
    setOffset(isNaN(newPage * limit) ? 0 : newPage * limit)

    setSearchParams({ q, page: (newPage + 1).toString() })
  }

  const onSearch = (newQ: string) => {
    setQ(newQ)
    setOffset(0)

    setSearchParams({ q: newQ, page: '1' })
  }

  const filter = {
    q,
    offset,
    limit,
  }

  const page = Math.ceil(filter.offset / filter.limit)

  return {
    onLimitChange,
    onPageChange,
    onSearch,
    filter,
    page,
  }
}

interface InitialValues {
  defaultQ: string
  defaultLimit: number
  defaultOffset: number
}

const useInitialValuesFromURL = (queryParams: URLSearchParams, params?: Params): InitialValues => {
  const defaultQ = queryParams.get('q') ?? ''

  const pageFromParams = queryParams.get('page')
  const page = pageFromParams !== null && parseInt(pageFromParams) - 1 >= 1 
    ? parseInt(pageFromParams) - 1 
    : DEFAULT_OFFSET

  const limitFromParams = queryParams.get('limit')
  const limit = params?.defaultLimit 
    ? params.defaultLimit
    : (limitFromParams !== null && parseInt(limitFromParams) >= 1) 
      ? parseInt(limitFromParams) 
      : DEFAULT_LIMIT

  return {
    defaultQ,
    defaultLimit: limit,
    defaultOffset: page * limit,
  }
}
