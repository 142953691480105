import React, { useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { RiShareFill as ShareIcon } from 'react-icons/ri'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  Heading,
  Icon,
  Spinner,
  useToast,
} from '@chakra-ui/react'
import { useReactToPrint } from 'react-to-print'
import jsPDF from 'jspdf'

const messages = defineMessages({
  printVisitButton: {
    id: 'UI.print_button',
    defaultMessage: 'Print',
  },
  shareButton: {
    id: 'UI.button_share',
    defaultMessage: 'Share',
  },
  errorPdf: {
    id: 'PrintButton.error_pdf',
    defaultMessage: 'An error ocurred, please try again later',
  },
  errorSharing: {
    id: 'PrintButton.error_sharing',
    defaultMessage: 'An error ocurred while sharing the file',
  },
  shareTitle: {
    id: 'PrintButton.share_title',
    defaultMessage: 'Share this WD file',
  },
  generatingPdf: {
    id: 'PrintButton.generating_pdf',
    defaultMessage: 'Generating PDF',
  },
})

type PrintButtonProps = ButtonProps & {
  componentToPrint: React.RefObject<HTMLDivElement>
  filename?: string
}

const pageStyle = `
    body {
      padding: 1cm;
      width: 595pt;
      background: white!important;
      color: black!important
    }
    ul, ol {
      padding: 0!important;
      list-style: none!important;
    }
    li:before {
      content: '•';
      margin-right: 4px;
    }
    li {
      font-size: 14px;
    }
    h2 {
      padding: 2px 0;
    }
    .pdfGeneralReviewOfSystem-section {
      margin: 0!important;
      padding: 0!important;
    }
    .pdfPhysicalExam-section {
      margin: 0!important;
      padding: 0!important;
    }
    .pdfDiagnosisChecklists {
      margin: 0!important;
      padding: 6px 0!important;
    }
    .pdfPatientInfo {
      margin: 8px 0!important;
    }
    .pdfManualDiagnose {
      margin: 0;
      padding: 4px 0;
    }
    .pdfPertinentSectionItem {
      color: inherit!important;
    }
`

export const PrintButton: React.FC<PrintButtonProps> = (props) => {
  const { componentToPrint, filename, ...buttonProps } = props

  const cancelRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)

  const intl = useIntl()
  const toast = useToast()

  const _filename = filename ?? `wd-${(Math.random() + 1).toString(36).substring(7)}`

  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
    pageStyle,
    copyStyles: false,
    print: async (target) => {
      setIsLoading(true)
      try {
        const doc = new jsPDF({
          unit: 'pt',
          format: 'a4',
          orientation: 'portrait',
        })

        const canvas = target.contentDocument?.querySelector<Element>('html') || new Element()

        const _svgs = canvas?.querySelectorAll<HTMLElement>('svg')

        _svgs.forEach((svg) => svg.remove())

        const printables = canvas.querySelectorAll('div[class="printable"]')

        printables.forEach((printable) => printable.classList.remove('printable'))

        await doc.html((canvas as HTMLElement) || '', {
          autoPaging: 'text',
          width: doc.internal.pageSize.getWidth(),
          windowWidth: 794,
        })

        const file = new File([doc.output('blob')], _filename, { type: 'application/pdf' })
        const shareData = {
          title: intl.formatMessage(messages.shareTitle),
          text: intl.formatMessage(messages.shareTitle),
          files: [file],
        }

        try {
          await navigator.share(shareData)
        } catch (e) {
          toast({
            title: intl.formatMessage(messages.errorSharing),
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
          doc.save(_filename)
        }
      } catch (e) {
        toast({
          title: intl.formatMessage(messages.errorPdf),
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <>
      <Button {...buttonProps} onClick={handlePrint} disabled={isLoading}>
        <Icon as={ShareIcon} mr={1} />
        {intl.formatMessage(messages.shareButton)}
      </Button>
      <AlertDialog
        isOpen={isLoading}
        onClose={() => {
          return
        }}
        leastDestructiveRef={cancelRef}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        size="xl"
        autoFocus
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent justifyContent={'center'} alignItems="center">
            <AlertDialogHeader>
              <Heading sx={{ color: 'white' }}>
                {intl.formatMessage(messages.generatingPdf)}
              </Heading>
            </AlertDialogHeader>
            <AlertDialogBody>
              <Spinner size="xl" color="blue.500" />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
