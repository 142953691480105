import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullExamSection } from '../../api/interfaces'
import { RiPencilLine as EditIcon } from 'react-icons/ri'
import { Box, BoxProps, Heading, HStack, IconButton, Text } from '@chakra-ui/react'
import { DynamicTranslation, useTranslatedList } from '../i18n'
import { FormattedSectionValues, getSectionValues } from './utils'
import { ClearingCodesWithText } from '../../utils'

const messages = defineMessages({
  editButton: {
    id: 'UI.edit_button',
    defaultMessage: 'Edit',
  },
  noteLabel: {
    id: 'UI.note_label',
    defaultMessage: 'Note',
  },
})

type SectionProps = Omit<BoxProps, 'onSelect'> & {
  section: FullExamSection
  onSelect: (section: FullExamSection) => void
  isDisabled?: boolean
  clearingCodes: ClearingCodesWithText
}

export const Section: React.FC<SectionProps> = (props) => {
  const { onSelect, isDisabled, section, clearingCodes, ...boxProps } = props
  const { name, note } = section

  const intl = useIntl()

  const onEdit = () => {
    onSelect(section)
  }

  const values: Array<FormattedSectionValues> = getSectionValues(section.groups, clearingCodes)

  return (
    <Box borderWidth={1} borderRadius={'md'} py={1} px={2} my={2} {...boxProps}>
      <HStack justifyContent={'space-between'} alignItems={'center'}>
        <Heading size={'sm'} fontWeight={'bold'} mb={0}>
          <DynamicTranslation message={name} />
        </Heading>
        <IconButton
          onClick={onEdit}
          size="sm"
          isDisabled={isDisabled}
          icon={<EditIcon />}
          aria-label={intl.formatMessage(messages.editButton)}
        ></IconButton>
      </HStack>
      <Text as="span">
        {values.map((value, index) => {
          const key = `section-${value.name.text}-${index}`
          return <SectionValuesFromGroups key={key} value={value} />
        })}
      </Text>
      {note && (
        <HStack mt="1">
          <Text fontSize={'sm'} fontStyle={'italic'}>
            {intl.formatMessage(messages.noteLabel)}:
          </Text>
          <Text fontSize={'sm'} fontStyle={'italic'}>
            {note}
          </Text>
        </HStack>
      )}
    </Box>
  )
}

type SectionValuesProps = {
  value: FormattedSectionValues
}

const SectionValuesFromGroups: React.FC<SectionValuesProps> = (props) => {
  const { value } = props
  const translatedSubValues = useTranslatedList(value.subValues)

  const { isPertinent } = value
  const isPertinentStyles = isPertinent ? { color: 'red' } : {}

  return (
    <Text fontSize={'sm'} as="span">
      <DynamicTranslation
        as="span"
        message={value.name}
        style={isPertinentStyles}
        className="pdfPertinentSectionItem"
      />
      {translatedSubValues.length > 0 && (
        <Text as="span" fontStyle={'italic'} ml={1}>
          {`(${translatedSubValues.join(', ')})`}
        </Text>
      )}
      {'. '}
    </Text>
  )
}

type PrintableSectionProps = BoxProps & {
  section: FullExamSection
  clearingCodes: ClearingCodesWithText
}

export const PrintableSection: React.FC<PrintableSectionProps> = (props) => {
  const { section, clearingCodes, ...boxProps } = props

  const { name, note } = section

  const intl = useIntl()

  const values: Array<FormattedSectionValues> = getSectionValues(section.groups, clearingCodes)

  return (
    <Box borderWidth={1} borderRadius={'md'} {...boxProps}>
      <Heading size={'sm'} fontWeight={'bold'} mb={0}>
        <DynamicTranslation message={name} />
      </Heading>

      <Text as="span">
        {values.map((value, index) => {
          const key = `section-${value.name.text}-${index}`
          return <SectionValuesFromGroups key={key} value={value} />
        })}
      </Text>
      {note && (
        <HStack mt="1">
          <Text fontSize={'sm'} fontStyle={'italic'}>
            {intl.formatMessage(messages.noteLabel)}:
          </Text>
          <Text fontSize={'sm'} fontStyle={'italic'}>
            {note}
          </Text>
        </HStack>
      )}
    </Box>
  )
}
