import { gql } from '@apollo/client'

export const FullUser = gql`
  fragment FullUser on User {
    id
    username
    family_name
    given_name
    family_name
    locale
    email
    created
    birthdate
    email_verified
    enabled
    lastModified
    phone_number
    phone_number_verified
    status
    permissions
    location {
      id
      name
    }
    system {
      id
      name
    }
    subscription {
      customerId
      status
    }
  }
`

export const FullUserPaymentMethod = gql`
  fragment FullUserPaymentMethod on UserPaymentMethod {
    type
    last4
    brand
    expYear
    expMonth
  }
`

export const FullUserSubscription = gql`
  fragment FullUserSubscription on UserSubscription {
    id
    customerId
    created
    nickname
    currentPeriodStart
    currentPeriodEnd
    status
    price
    canceledAt
    trialStart
    trialEnd
    currency
    interval
    intervalCount
    paymentMethod {
      ...FullUserPaymentMethod
    }
  }
  ${FullUserPaymentMethod}
`
