import React from 'react'
import {
    Icon,
    IconButton,
    useDisclosure,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { AppDrawer } from './AppDrawer'
import { RiMenuLine as MenuIcon } from 'react-icons/ri'


export const Sidebar: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { pathname } = useLocation()

    React.useEffect(() => {
        onClose()
    }, [pathname, onClose])

    return <>
        <IconButton
            aria-label='menu'
            colorScheme='brand'
            variant={'ghost'}
            onClick={onOpen}
            icon={<Icon as={MenuIcon} />}
        />
        <AppDrawer onClose={onClose} isOpen={isOpen} />

    </>
}
