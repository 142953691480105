import React from 'react'
import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import { GET_UPDATE_PAYMENT_METHOD_CHECKOUT_URI } from '../../api/requests/staff'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  errorCreatingSession: {
    id: 'UserPreferences.update_payment_method_error',
    defaultMessage: 'Could not create the session to update your payment method. Try again.',
  },
})

interface UseUpdatePaymentMethodCheckoutData {
  updatePaymentMethodCheckoutURI: string
}

type RegularOptins = LazyQueryHookOptions<UseUpdatePaymentMethodCheckoutData, void>

export type useUpdatePaymentMethodCheckoutParams = Omit<RegularOptins, 'onError'> & {
  onError?: (translatedError: string) => void
}

export const useUpdatePaymentMethodCheckout = (params?: useUpdatePaymentMethodCheckoutParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'could_not_create_session':
        return intl.formatMessage(messages.errorCreatingSession)

      default:
        return intl.formatMessage(messages.internalServerError)
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    const translatedError = translateError(firstError)

    setError(translatedError)
    if (params?.onError) {
      params.onError(translatedError)
    }
  }

  const [checkout, { loading, data }] = useLazyQuery<UseUpdatePaymentMethodCheckoutData, void>(
    GET_UPDATE_PAYMENT_METHOD_CHECKOUT_URI,
    {
      ...params,
      onError,
    }
  )

  const onErrorClose = () => {
    setError(null)
  }

  React.useEffect(() => {
    if (data?.updatePaymentMethodCheckoutURI) {
      window.location.href = data.updatePaymentMethodCheckoutURI
    }
  }, [data])

  return {
    isLoading: loading,
    error,
    checkout,
    onErrorClose,
  }
}
