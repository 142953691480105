import React from 'react'

type SeparatedListProps = {
  list: Array<React.ReactElement>
  separator?: string
  className?: string
  listStyle?: string
}

const SeparatedList: React.FC<SeparatedListProps> = (props) => {
  const { list, separator = ',', className, listStyle } = props

  return (
    <ul
      style={{
        listStyle: listStyle || 'none',
        margin: 0,
        paddingLeft: listStyle ? '14px' : 'initial',
      }}
      className={className}
    >
      <li>
        {list.map((listItem, index) => {
          return (
            <span key={index}>
              {listItem}
              {index < list.length - 1 && `${separator} `}
            </span>
          )
        })}
      </li>
    </ul>
  )
}

export { SeparatedList }
