import React from 'react'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'
import Select from 'react-select'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SelectorProps,
  useChakraThemeParser,
} from '.'
import { BillableCategory } from '../../api/interfaces/billing'
import { Nullable } from '../../utils'
import { isValidBillableCategory } from '../Billing'

const messages = defineMessages({
  category: {
    id: 'UI.label_category',
    defaultMessage: 'Category',
  },
})

export const billableCategoriesTranslations: Record<BillableCategory, MessageDescriptor> = {
  [BillableCategory.ADMINISTRATION]: {
    id: 'BillableCategory.administration',
    defaultMessage: 'Administration'
  },
  [BillableCategory.DOCTOR_FEE]: {
    id: 'BillableCategory.doctor_fee',
    defaultMessage: 'Provider fee'
  },
  [BillableCategory.LAB]: {
    id: `BillableCategory.lab`,
    defaultMessage: 'Lab'
  },
  [BillableCategory.MEDICINES]: {
    id: `BillableCategory.medicines`,
    defaultMessage: 'Medicines'
  },
  [BillableCategory.OTHERS]: {
    id: 'BillableCategory.category_name_other',
    defaultMessage: 'Other'
  },
  [BillableCategory.PROCEDURES]: {
    id: `BillableCategory.procedures`,
    defaultMessage: 'Procedures'
  }
}

/**
 * See the IntlDefineMessage definition
 */
const translationMessages = defineMessages(billableCategoriesTranslations)

export type BillableCategorySelectorProps = SelectorProps<BillableCategory>

export const BillableCategorySelector: React.FC<BillableCategorySelectorProps> = props => {
  const {
    value,
    isMulti,
  } = props

  const intl = useIntl()
  const theme = useChakraThemeParser()

  const formatOption = (visitType: BillableCategory): ReactSelectInternalSingleValue => {
    return {
      label: intl.formatMessage(translationMessages[visitType]),
      value: visitType
    }
  }

  const options = Object.values(BillableCategory)
    .filter(item => (
      [
        BillableCategory.DOCTOR_FEE
      ].includes(item)
    ))
    .map(formatOption)

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (isMulti) {
      if (!Array.isArray(value)) {
        return null
      }

      const formattedValues = value
        .reduce((prev: Array<ReactSelectInternalSingleValue>, value: string) => {
          if (isValidBillableCategory(value)) {
            return [
              ...prev,
              formatOption(value as BillableCategory)
            ]
          }

          return prev
        }, [])

      return formattedValues
    } else {
      if (isValidBillableCategory(value as string)) {
        return formatOption(value as BillableCategory)
      }

      return null
    }
  }

  const _onChange = value => {
    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return props.onChange([])
      }

      const selectedValues = value
      const newValues = selectedValues.map(item => item.value)

      props.onChange(newValues as Array<BillableCategory>)
    } else {
      const selectedValue = value

      props.onChange(selectedValue.value as BillableCategory)
    }
  }

  return <Select
    options={options}
    value={parseValue(value)}
    onChange={_onChange}
    isLoading={props.isLoading}
    isMulti={props.isMulti}
    isClearable={props.isClearable}
    isDisabled={props.isDisabled}
    isSearchable={props.isSearchable}
    aria-invalid={props.isInvalid}
    placeholder={intl.formatMessage(messages.category)}
    blurInputOnSelect={props.blurInputOnSelect}
    theme={theme}
  />
}

