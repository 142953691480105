import React from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  HStack,
  Icon,
  Tag,
  Text,
} from '@chakra-ui/react'
import { FullChecklist, QualityChecksEquation, TranslatableText } from '../../api/interfaces'
import {
  EvaluatedNode,
  evaluateQualityEquation,
  getDefaultIndex,
  isValidNodeType,
  NODE_TYPE,
} from './utils'
import { RiArrowRightSLine as ExpandButton, RiCheckboxBlankCircleLine as UnCheckIcon, RiCheckFill as CheckIcon } from 'react-icons/ri'
import { defineMessages } from '@formatjs/intl'
import { useIntl, MessageDescriptor } from 'react-intl'

const messages = defineMessages({
  passed: {
    id: 'QualityEquationResult.quality_check_passed',
    defaultMessage: 'Quality check passed',
  },
  failed: {
    id: 'QualityEquationResult.quality_check_failed',
    defaultMessage: 'Quality check failed',
  },
  result: {
    id: 'UI.result_button',
    defaultMessage: 'Result'
  },

})

const nodeNameTranslations: Record<NODE_TYPE, MessageDescriptor> = {

  [NODE_TYPE.AND]: { id: 'QualityTreeNode.name_equation_and', defaultMessage: 'AND' },
  [NODE_TYPE.OR]: { id: 'QualityTreeNode.name_equation_or', defaultMessage: 'OR' },
  [NODE_TYPE.ANY]: { id: 'QualityTreeNode.name_equation_any', defaultMessage: 'ANY' },
  [NODE_TYPE.ALL]: { id: 'QualityTreeNode.name_equation_all', defaultMessage: 'ALL' },
  [NODE_TYPE.NOT]: { id: 'QualityTreeNode.name_equation_not', defaultMessage: 'NOT' },
  [NODE_TYPE.XOR]: { id: 'QualityTreeNode.name_equation_xor', defaultMessage: 'XOR' },

}

const nodeDescriptionTranslations: Record<NODE_TYPE, MessageDescriptor> = {

  [NODE_TYPE.AND]: { id: 'QualityTreeNode.description_equation_and', defaultMessage: 'TRUE if everything inside is TRUE' },
  [NODE_TYPE.OR]: { id: 'QualityTreeNode.description_equation_or', defaultMessage: 'TRUE if at least one thing inside is TRUE' },
  [NODE_TYPE.ANY]: { id: 'QualityTreeNode.description_equation_any', defaultMessage: 'TRUE if any of these codes are checked' },
  [NODE_TYPE.ALL]: { id: 'QualityTreeNode.description_equation_all', defaultMessage: 'TRUE if all of these codes are checked' },
  [NODE_TYPE.NOT]: { id: 'QualityTreeNode.description_equation_not', defaultMessage: 'TRUE if the thing inside is FALSE' },
  [NODE_TYPE.XOR]: { id: 'QualityTreeNode.description_equation_xor', defaultMessage: 'TRUE if exactly one thing inside is TRUE' }
}

const translatedNodeNames = defineMessages(nodeNameTranslations)
const translatedNodeDescriptions = defineMessages(nodeDescriptionTranslations)

interface Props {
  checklist: FullChecklist
  equation: QualityChecksEquation
}

export const QualityTree: React.FC<Props> = (props) => {
  const { equation, checklist } = props

  const intl = useIntl()

  const { tree } = React.useMemo(
    () => evaluateQualityEquation(equation, checklist),
    [equation, checklist]
  )

  return (
    <Box alignItems="start" flex={1}>

      <Tree tree={tree} value={!!tree.value} />
      <HStack spacing={2} my={2} >

        <Text  >
          {intl.formatMessage(messages.result)}:
        </Text>
        <Badge colorScheme={tree.value ? 'green' : 'red'}>
          {tree.value
            ? intl.formatMessage(messages.passed)
            : intl.formatMessage(messages.failed)
          }


        </Badge>
      </HStack>
    </Box >
  )
}

interface TreeProps {
  tree: EvaluatedNode
  value: boolean
}

const Tree: React.FC<TreeProps> = (props) => {
  const { tree, value } = props

  const defaultIndex = getDefaultIndex(tree, value)
  return (
    <Box pb={4} flex={1}>
      <Accordion defaultIndex={defaultIndex} allowToggle allowMultiple reduceMotion>
        {tree.children.map((node, index) => (
          <Node node={node} key={`root-node-${index}-${node.type}`} rootValue={value} />
        ))}
      </Accordion>
    </Box>
  )
}

interface NodeProps {
  node: EvaluatedNode
  rootValue: boolean
}

const Node: React.FC<NodeProps> = (props) => {
  const { node, rootValue } = props

  const intl = useIntl()

  const color = node.value ? 'green' : 'red'
  const message = {
    name: '',
    description: ''
  }

  const isValidNode = isValidNodeType(node.type)
  if (isValidNode) {
    message.name = intl.formatMessage(translatedNodeNames[node.type])
    message.description = intl.formatMessage(translatedNodeDescriptions[node.type])
  }

  return (
    <AccordionItem border={0}>
      {({ isExpanded }) => (
        <Box  >
          <AccordionButton borderLeftColor={`${color}.300`} borderLeftWidth={2} pl={2} borderBottomWidth={1} bg={isExpanded ? 'var(--chakra-colors-blackAlpha-50)' : 'inherit'} my={0}>
            <HStack spacing={2}>
              <Icon
                fontSize="sm"
                as={ExpandButton}
                sx={{ transform: isExpanded ? 'rotateZ(90deg)' : 'rotateZ(0deg)' }}
              />
              <Tag size={'sm'} colorScheme={color}>
                {message.name ?? node.type}
              </Tag>

            </HStack>
            <Box display={'flex'} justifyContent={'end'} alignItems={'center'} flex={1}>
              <Text fontSize={'sm'} fontStyle='italic'>
                {message.description}
              </Text>
            </Box>
          </AccordionButton>
          <NestedTree isExpanded={isExpanded} node={node} rootValue={rootValue} />
        </Box>
      )
      }
    </AccordionItem >
  )
}

interface NestedTreeProps {
  isExpanded: boolean
  rootValue: boolean
  node: EvaluatedNode
}

const NestedTree: React.FC<NestedTreeProps> = (props) => {
  if (!props.isExpanded) {
    return null
  }

  const { node, rootValue } = props
  const { children } = node

  const defaultIndex = getDefaultIndex(node, rootValue)
  return (
    <AccordionPanel py={1} pr={0} pl={3} >
      <Accordion defaultIndex={defaultIndex} allowToggle allowMultiple reduceMotion>
        {children.map((node, index) => {
          if (node.children.length > 0) {
            // ignore dummy nodes
            if (node.children.length === 1 && node.type === NODE_TYPE.ANY) {
              return <Leaf key={`leaf-${index}-${name}`} node={node.children[0]} />
            }

            return <Node node={node} key={`node-${index}-${name}`} rootValue={rootValue} />
          }

          return <Leaf
            node={node}
            key={`leaf-${index}-${name}`}
          />
        })}
      </Accordion>
    </AccordionPanel>
  )
}

interface LeafProps {
  node: EvaluatedNode
}

const Leaf: React.FC<LeafProps> = (props) => {
  const { node } = props
  const { value } = node

  const intl = useIntl()

  const translate = (message: TranslatableText): string => {
    if(typeof message.text !== 'string' || message.text.length === 0){
      return ''
    }

    const prefix = 'Dynamic'
    const defaultMessage = message.text.trim()
    const id = `${prefix}.${defaultMessage}`

    return intl.formatMessage({ id, defaultMessage })
  }

  const name = node.description
    ? translate(node.description)
    : node.codes[0]

  const areTheSame = name === node.codes[0]

  return (
    <HStack borderLeftWidth={0.5} spacing={2} ml={0} my={2} px={2} justifyContent="space-between" alignItems={'center'} >
      {value ? (
        <Icon as={CheckIcon} fontSize={'sm'} />
      ) : (
        <Icon as={UnCheckIcon} fontSize={'xs'} color='gray' />
      )}
      <Box flex="1" textAlign="left">
        <Text fontSize={'sm'}>{name}</Text>
        {!areTheSame && <Text fontSize='xs' color='gray'>{node.codes[0]}</Text>}
      </Box>
    </HStack>
  )
}
