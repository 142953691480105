import React from 'react'
import { Nullable } from '../../utils'
import { DateTime, Duration } from 'luxon'
import { useSystems } from '../Systems'
import { useSession } from '../Auth'
import { EventInput } from '@fullcalendar/common'
import { getSlotDurationFromSettings, roomEventToCalendarEvent, scheduledVisitToCalendarEvent } from './utils'
import { useScheduledVisits } from './useScheduledVisits'
import { EventType } from './Calendar/utils'
import { RoomEvent, ScheduledVisit } from '../../api/interfaces'

export type EventFilter = Record<EventType, boolean>
export interface CalendarContextValue {
  currentDate: Date
  selectedEvent: Nullable<string>
  selectedStartDate: Nullable<Date>
  selectedEndDate: Nullable<Date>
  roomId: Nullable<string>
  eventFilter: EventFilter
  events: Array<EventInput>
  slotDuration: Duration
  locale: string
  onEventFilterChange: (filter: EventFilter) => void
  onEventSelect: (event: string) => void
  onEventChange: (event: EventInput) => void
  onCurrentDateChange: (current: Date) => void
  onRoomChange: (roomId: string) => void
  onPeriodChange: (start: Date, end: Date) => void
  onReset: () => void
  onRefresh: () => void
}

const initialEventFilter = {
  [EventType.IN_PERSON]: true,
  [EventType.PERSONAL_APPOINTMENT]: true,
  [EventType.TELEHEALTH]: true,
}
export const CalendarContext = React.createContext<Nullable<CalendarContextValue>>(null)
export const CalendarProvider: React.FC = (props) => {
  // current room id
  const [roomId, setRoomId] = React.useState<Nullable<string>>(null)

  // current calendar date
  const [currentDate, setCurrentDate] = React.useState<Date>(new Date())

  // event dates selection
  const [selectedStartDate, setSelectedStartDate] = React.useState<Nullable<Date>>(null)
  const [selectedEndDate, setSelectedEndDate] = React.useState<Nullable<Date>>(null)

  // event selection
  const [selectedEvent, setSelectedEvent] = React.useState<Nullable<string>>(null)

  // event filter
  const [eventFilter, setEventFilter] = React.useState<EventFilter>(initialEventFilter)

  const { getUserLocale } = useSession()
  const locale = getUserLocale()

  const { events, slotDuration, refetchVisits } = useRoomSettings({ roomId, currentDate, eventFilter })

  const onCurrentDateChange = (current: Date): void => {
    setCurrentDate(current)
  }

  const onRoomChange = React.useCallback((roomId: string): void => {
    setRoomId(roomId)
  }, [])

  const onPeriodChange = (start: Date, end: Date): void => {
    setSelectedStartDate(start)

    // end of day correction to avoid display issues in the date pickers
    const fixedEnd = end.getHours() === 0 && end.getMinutes() === 0 ? new Date(end.getTime() - 60_000) : end
    setSelectedEndDate(fixedEnd)
  }

  const onEventSelect = (eventId: string) => {
    setSelectedEvent(eventId)
  }

  const onEventChange = (event: EventInput) => {
    setSelectedEvent(event.id ?? null)
  }

  const onEventFilterChange = (filter: EventFilter) => {
    setEventFilter(filter)
  }

  const onReset = () => {
    setSelectedEndDate(null)
    setSelectedStartDate(null)
    setSelectedEvent(null)
  }

  const value = {
    currentDate,
    roomId,
    selectedEvent,
    selectedStartDate,
    selectedEndDate,
    slotDuration,
    locale,
    events,
    eventFilter,
    onEventFilterChange,
    onEventSelect,
    onEventChange,
    onCurrentDateChange,
    onRoomChange,
    onPeriodChange,
    onReset,
    onRefresh: refetchVisits,
  }

  return <CalendarContext.Provider value={value}>{props.children}</CalendarContext.Provider>
}

interface RoomSettingsParams {
  roomId: Nullable<string>
  currentDate: Date
  eventFilter: EventFilter
}

const useRoomSettings = (params: RoomSettingsParams) => {
  const { roomId, currentDate, eventFilter } = params

  /**
   * The largest visible period is close to a month plus 2 weeks:
   * Monthly view with the last week of the previous month and the first
   * one of the next month. So, if we want our calendar to correctly show all the
   * events in range, we have to fetch from -(startOfMonth - 1w) to + (endOfMonth + 1w)
   */
  const from = DateTime.fromJSDate(currentDate)
    .startOf('month')
    .minus(Duration.fromObject({ week: 1 }))
    .toISO()

  const to = DateTime.fromJSDate(currentDate)
    .endOf('month')
    .plus(Duration.fromObject({ week: 1 }))
    .toISO()

  const visitParams = {
    roomId,
    from,
    to,
  }
  const { scheduledVisits, refetch } = useScheduledVisits(visitParams)
  const eventsFromVisits = filterAndFormatVisit(scheduledVisits, eventFilter)

  const { getMyLocation } = useSystems()
  const { rooms, telehealth } = getMyLocation()
  const slotDuration = getSlotDurationFromSettings(telehealth)

  const room = rooms.find((room) => room.id === roomId)
  const eventsFromRoom = filterAndFormatRoomEvents(room?.events ?? [], eventFilter)

  return {
    events: [...eventsFromVisits, ...eventsFromRoom],
    slotDuration,
    refetchVisits: refetch,
  }
}

const filterAndFormatVisit = (scheduledVisits: Array<ScheduledVisit>, filter: EventFilter): Array<EventInput> => {
  if (filter.inPerson === filter.telehealth) {
    return filter.telehealth ? scheduledVisits.map(scheduledVisitToCalendarEvent) : []
  }

  return scheduledVisits
    .filter((visit) => visit.registrationData.telehealth === filter.telehealth)
    .map(scheduledVisitToCalendarEvent)
}

const filterAndFormatRoomEvents = (roomEvents: Array<RoomEvent>, filter: EventFilter): Array<EventInput> => {
  if (filter.personalAppointment) {
    return roomEvents.map(roomEventToCalendarEvent)
  }

  return []
}
