import React from 'react'
import { FormControl, FormErrorMessage, FormControlProps } from '@chakra-ui/react'
import { useField } from 'react-final-form'

export type ValidationError<T> = {
  [K in keyof T]?: string
}

export type ValidateFn<T> = (values: T) => ValidationError<T> | Promise<ValidationError<T>>

type ControlProps = FormControlProps & {
  name: string
}
export const Control: React.FC<ControlProps> = (props) => {
  const { name, ...htmlChakraProps } = props
  const {
    meta: { error, touched },
  } = useField(name, { subscription: { touched: true, error: true } })

  return <FormControl my={3} {...htmlChakraProps} isInvalid={error && touched} />
}

interface ErrorProps {
  name: string
}
export const Error: React.FC<ErrorProps> = (props) => {
  const { name } = props

  const {
    meta: { error },
  } = useField(name, { subscription: { error: true } })

  return <FormErrorMessage my={3}>{error}</FormErrorMessage>
}
