import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { START_EXAM } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  startExamInvalidSignatureError: {
    id: 'StartExam.start_exam_invalid_signature_error',
    defaultMessage: 'We could not validate your identity. Refresh the page and try again.',
  },
  startExamInvalidVisitError: {
    id: 'StartExam.start_exam_invalid_visit_error',
    defaultMessage: 'This visit is not valid. Refresh the page and try again.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface StartExamVariables {
  patientId: string
  visitId: string
}
interface StartExamMutationData {
  startExam: FullPatientWithActiveVisit
}

type Params = MutationHookOptions<StartExamMutationData, StartExamVariables> & {
  patientId: string
  visitId: string
}

export const useStartExam = (params: Params) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'error_invalid_signature':
        return setError(intl.formatMessage(messages.startExamInvalidSignatureError))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.startExamInvalidVisitError))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [startExam, { loading: isLoading }] = useMutation(START_EXAM, {
    onError,
    ...restParams,
  })

  const onSubmit = () => {
    setError(null)

    const variables = {
      patientId,
      visitId,
    }

    startExam({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    onErrorClose,
  }
}
