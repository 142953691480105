import { UserData } from 'amazon-cognito-identity-js'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Nullable } from '../../utils'
import { useSession } from '../Auth'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export const useMFAActions = () => {
  const intl = useIntl()
  const { associateMFAToken, enableMFA, disableMFA, logOut } = useSession()

  const [secret, setSecret] = React.useState<Nullable<string>>(null)
  const [isDeviceAssociated, setIsDeviceAssociated] = React.useState<boolean>(false)

  const [isEnabling, setIsEnabling] = React.useState<boolean>(false)
  const [isDisabling, setIsDisabling] = React.useState<boolean>(false)
  const [isAssociating, setIsAssociating] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (err) => {
    const { code } = err

    switch (code) {
      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const onAssiciate = async () => {
    setError(null)
    try {
      setIsAssociating(true)
      const secret = await associateMFAToken()

      setSecret(secret)
    } catch (error) {
      translateError(error)
    }

    setIsAssociating(false)
  }

  const onCancel = () => {
    setError(null)
    setSecret(null)
    setIsDeviceAssociated(false)
  }

  const onVerify = () => {
    setIsDeviceAssociated(true)
  }

  const onEnable = async () => {
    if (isDeviceAssociated) {
      setIsEnabling(true)

      try {
        await enableMFA()

        /**
         * This aws lib doesn't provide us with a new
         * cognito session. So we need to logout & login
         * to get the new mfa settings.
         * Using the refresh token doesn't work
         */
        logOut()
      } catch (error: any) {
        translateError(error)
        setIsEnabling(false)
      }
    }
  }

  const onDisable = async () => {
    setIsDisabling(true)
    try {
      await disableMFA()

      /**
       * This aws lib doesn't provide us with a new
       * cognito session. So we need to logout & login
       * to get the new mfa settings.
       * Using the refresh token doesn't work
       */
      logOut()
    } catch (error: any) {
      translateError(error)
      setIsDisabling(false)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    secret,
    onAssiciate,
    onDisable,
    onEnable,
    isAssociating,
    isDisabling,
    isEnabling,
    isDeviceAssociated,
    onVerify,
    onCancel,
    error,
    onErrorClose,
  }
}

export const useMFASettings = () => {
  const intl = useIntl()

  const { getUserData } = useSession()

  const isMounted = React.useRef(false)

  const [error, setError] = React.useState<Nullable<string>>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [userData, setUserData] = React.useState<Nullable<UserData>>(null)

  const onErrorClose = () => {
    setError(null)
  }

  React.useEffect(() => {
    if (isMounted.current === false) {
      isMounted.current = true
    }

    const getOptions = async () => {
      setError(null)
      setIsLoading(true)

      try {
        const userData = await getUserData()
        if (isMounted.current) {
          setIsLoading(false)
          setUserData(userData)
        }
      } catch (err: any) {
        if (isMounted.current) {
          setIsLoading(false)
          setError(intl.formatMessage(messages.internalServerError))
        }
      }
    }

    getOptions()

    return () => {
      isMounted.current = false
    }
  }, [getUserData, intl, isMounted])

  /**
   * Only virtual mfa is allowed allowed
   */
  const isMFAEnabled = userData?.PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA'
  return {
    isMFAEnabled,
    isLoading,
    error,
    onErrorClose,
  }
}
