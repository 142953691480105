import { gql } from '@apollo/client'
import { FullComment, UserSignature } from './common'
import { FullChecklistEntry } from './checklists'

export const FullStudyOrder = gql`
  fragment FullStudyOrder on StudyOrder {
    id
    type
    comments {
      ...FullComment
    }
    guideline {
      ...FullChecklistEntry
    }
    order {
      text
      created {
        ...UserSignature
      }
    }
    canceled
    acknowledged
    received
    studyId
  }
  ${FullComment}
  ${FullChecklistEntry}
  ${UserSignature}
`

export const BasicStudyTemplate = gql`
  fragment BasicStudyTemplate on StudyTemplate {
    id
    name {
      text
    }
    description {
      text
    }
    method {
      text
    }
    created {
      ...UserSignature
    }
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`
