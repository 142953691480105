import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { RiAddLine as AddIcon } from 'react-icons/ri'
import {
  Button,
  ButtonProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useBreakpointValue,
  useDisclosure,
  Text,
  Icon,
} from '@chakra-ui/react'
import { useAddAssessment } from './useAssessments'
import { ErrorViewer } from '..'
import { TextField } from '../Form'
import { Form } from 'react-final-form'

const messages = defineMessages({
  addAssessment: {
    id: 'UI.add_assessment_label',
    defaultMessage: 'Diagnosis',
  },
  assessmentTitle: {
    id: 'UI.assessment_title',
    defaultMessage: 'Add Diagnosis',
  },
  saveButton: {
    id: 'UI.save_button',
    defaultMessage: 'Save',
  },
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close',
  },
})

type AddAssessmentButtonProps = ButtonProps & {
  patientId: string
  visitId: string
  isDisabled?: boolean
  preventModalFromClosing?: boolean
}

export const AddAssessmentButton: React.FC<AddAssessmentButtonProps> = (props) => {
  const { patientId, visitId, isDisabled, preventModalFromClosing, ...buttonProps } = props

  const intl = useIntl()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button onClick={onOpen} disabled={isDisabled || isOpen} colorScheme="brand" size="sm" {...buttonProps}>
        <Icon as={AddIcon} />
        <Text ml={1}>{intl.formatMessage(messages.addAssessment)}</Text>
      </Button>

      {isOpen && (
        <AssessmentsModal
          patientId={patientId}
          visitId={visitId}
          isOpen={isOpen}
          onClose={onClose}
          preventModalFromClosing={preventModalFromClosing}
          initialFocus
        />
      )}
    </>
  )
}

interface AssessmentsModalProps {
  patientId: string
  visitId: string
  isOpen: boolean
  onClose: () => void
  initialFocus?: boolean
  preventModalFromClosing?: boolean
}

const AssessmentsModal: React.FC<AssessmentsModalProps> = (props) => {
  const { patientId, visitId, isOpen, onClose, initialFocus, preventModalFromClosing } = props

  const intl = useIntl()

  const params = {
    patientId,
    visitId,
    onCompleted: preventModalFromClosing ? undefined : onClose,
  }

  const { onSubmit, isLoading, validate, error, onErrorClose } = useAddAssessment(params)

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Form
        onSubmit={onSubmit}
        validate={validate}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ModalContent>
                <ModalHeader>{intl.formatMessage(messages.assessmentTitle)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <TextField
                    name="assessment"
                    label={intl.formatMessage(messages.addAssessment)}
                    autoFocus={initialFocus}
                  />

                  {error && <ErrorViewer title={error} onClose={onErrorClose} />}
                </ModalBody>

                <ModalFooter>
                  <Button mr={3} onClick={onClose} isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.closeButton)}
                  </Button>
                  <Button colorScheme={'brand'} isLoading={isLoading} type="submit" isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.saveButton)}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )
        }}
      />
    </Modal>
  )
}
