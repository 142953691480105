import React from 'react'
import { FullList, TranslatableText } from '../../api/interfaces'
import { Box, BoxProps, Button, Heading } from '@chakra-ui/react'
import { DynamicTranslation } from '../i18n'
import { ExamEditorContextValue, useListContext, useExamEditorContext, ListContextValue } from './'

type ExamChoiceDetailProps = BoxProps & {
  sectionIndex: number
  groupIndex: number
  choiceIndex: number
  detailIndex: number
  isDisabled?: boolean
}

export const ExamChoiceDetails: React.FC<ExamChoiceDetailProps> = (props) => {
  const { sectionIndex, groupIndex, choiceIndex, detailIndex, isDisabled, ...boxProps } = props

  const { exam } = useExamEditorContext() as ExamEditorContextValue
  const { getList } = useListContext() as ListContextValue

  const { details } = exam.sections[sectionIndex].groups[groupIndex].choices[choiceIndex]
  if (!Array.isArray(details)) {
    return null
  }

  const { list_id: listId, selected_items: selectedItems } = details[detailIndex]
  const list = getList(listId)

  if (!list) {
    return null
  }

  return (
    <Box {...boxProps}>
      <Heading size={'sm'}>
        <DynamicTranslation message={list.name} />
      </Heading>
      <ChoiceDetailsList
        sectionIndex={sectionIndex}
        groupIndex={groupIndex}
        choiceIndex={choiceIndex}
        detailIndex={detailIndex}
        selectedItems={selectedItems ?? []}
        list={list}
        isDisabled={isDisabled}
      />
    </Box>
  )
}

type ChoiceDetailsListProps = BoxProps & {
  sectionIndex: number
  groupIndex: number
  choiceIndex: number
  detailIndex: number
  list: FullList
  selectedItems: Array<TranslatableText>
  isDisabled?: boolean
}
const ChoiceDetailsList: React.FC<ChoiceDetailsListProps> = (props) => {
  const {
    sectionIndex,
    groupIndex,
    choiceIndex,
    detailIndex,
    list,
    selectedItems,
    isDisabled,
    ...boxProps
  } = props

  const { onDetailsSelect } = useExamEditorContext() as ExamEditorContextValue

  return (
    <Box {...boxProps}>
      <Box display={'flex'} flexWrap={'wrap'}>
        {list.items.map((item, listItemIndex) => {
          const { name, key: listItemKey } = item

          const isSelected = selectedItems.some((selectedItem) => selectedItem.text === name.text)

          const onClick = () => {
            const params = {
              sectionIndex,
              groupIndex,
              choiceIndex,
              detailIndex,
              listId: list.id,
              listItemIndex,
              isSelected,
            }

            onDetailsSelect(params)
          }
          return (
            <Button
              key={`choice-detail-${listItemKey}-${listItemIndex}`}
              colorScheme={isSelected ? 'brand' : undefined}
              isDisabled={isDisabled}
              size={'sm'}
              my={1}
              mr={2}
              onClick={onClick}
            >
              <DynamicTranslation message={name} />
            </Button>
          )
        })}
      </Box>
    </Box>
  )
}
