import React from 'react'
import ReactDatePicker from 'react-datepicker'
import type { ReactDatePickerProps } from 'react-datepicker'
import { Box, BoxProps, useColorMode } from '@chakra-ui/react'

import 'react-datepicker/dist/react-datepicker.css'

/**
 * Quick styles customization to make it work with the
 * brand color set in the chakra context in light & dark mode.
 */
import './styles.css'

export type DatePickerProps = Omit<ReactDatePickerProps, 'selectsRange'> & {
  onChange: (date: Date) => void
  value?: Date | undefined
  isInvalid?: boolean
  isDisabled?: boolean
  boxProps?: BoxProps
  selectsRange?: boolean
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    value,
    onChange,
    isClearable = false,
    showPopperArrow = false,
    isInvalid,
    isDisabled,
    boxProps,
    ...otherProps
  } = props

  const { colorMode } = useColorMode()

  const className = isInvalid
    ? 'react-datapicker__input-text invalid'
    : 'react-datapicker__input-text'

  return (
    <Box className={colorMode === 'light' ? 'light-theme' : 'dark-theme'} {...boxProps}>
      <ReactDatePicker
        selected={value}
        onChange={onChange}
        isClearable={isClearable}
        disabled={isDisabled}
        showPopperArrow={showPopperArrow}
        showYearDropdown
        className={className}
        disabledKeyboardNavigation
        autoComplete={'false'}
        {...otherProps}
      />
    </Box>
  )
}
