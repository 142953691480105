import { Nullable } from '../../utils'

export interface DefaultSystemOrLocation {
  id: string
  name: string
}

/**
 * Subscription status from stripe
 */
export enum SubscriptionStatus {
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
}

/**
 * This is what we get from apollo
 */
export interface SubscriptionData {
  customerId: string
  status: Nullable<SubscriptionStatus>
}

export interface User {
  id: string
  username: string
  family_name: string
  given_name: string
  email: string
  created: string
  locale: Nullable<string>
  birthdate: Nullable<string>
  email_verified: Nullable<boolean>
  enabled: Nullable<boolean>
  lastModified: Nullable<string>
  phone_number: string
  phone_number_verified: Nullable<boolean>
  status: Nullable<string>
  permissions: Nullable<string>
  location: Nullable<DefaultSystemOrLocation>
  system: Nullable<DefaultSystemOrLocation>
  subscription: Nullable<SubscriptionData>
}

export interface UserPaymentMethod {
  type: string
  last4?: string
  brand?: string
  expYear?: string
  expMonth?: string
}

export interface FullUserSubscription {
  id: string
  customerId: string
  created: string
  nickname: Nullable<string>
  currentPeriodStart: string
  currentPeriodEnd: string
  status: SubscriptionStatus
  price: Nullable<number>
  canceledAt: Nullable<string>
  trialStart: Nullable<string>
  trialEnd: Nullable<string>
  currency: Nullable<string>
  interval: Nullable<string>
  intervalCount: Nullable<number>
  paymentMethod: UserPaymentMethod
}
