import React from 'react'
import { Box, HStack, Text, TextProps, useRadio, useRadioGroup, UseRadioProps } from '@chakra-ui/react'
import { useIntl, defineMessages, MessageDescriptor } from 'react-intl'
import { ClinicalStatusPriority } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { getClinicalStatusColor } from '../ActiveVisit'

export const clinicalStatusTranslation: Record<ClinicalStatusPriority, MessageDescriptor> = {
  [ClinicalStatusPriority.ROUTINE]: {
    id: 'ClinicalStatus.routine',
    defaultMessage: 'Routine'
  },
  [ClinicalStatusPriority.GUARDED]: {
    id: 'ClinicalStatus.guarded',
    defaultMessage: 'Guarded'
  },
  [ClinicalStatusPriority.EMERGENCY]: {
    id: 'ClinicalStatus.emergency',
    defaultMessage: 'Emergency'
  },
  [ClinicalStatusPriority.DECEASED]: {
    id: 'ClinicalStatus.deceased',
    defaultMessage: 'Deceased'
  }
}

const clinicalStatusMessages = defineMessages(clinicalStatusTranslation)

type ClinicalStatusProps = Omit<UseRadioProps, 'value'> & {
  value: Nullable<ClinicalStatusPriority>
  onChange: (priority: ClinicalStatusPriority) => void
}

export const ClinicalStatusSelector: React.FC<ClinicalStatusProps> = (props) => {
  const {
    value,
    onChange,
    ...otherProps
  } = props



  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'priority',
    value: value ?? '',
    onChange,
  })

  const group = getRootProps()

  return <HStack {...group} spacing={1}>
    {
      Object.values(ClinicalStatusPriority)
        .filter(status => status !== ClinicalStatusPriority.DECEASED)
        .map(status => {
          const radio = getRadioProps({ value: status })

          return <RadioCard key={status} {...radio} {...otherProps} >
            <ClinicalStatusTranslation priority={status} />
          </RadioCard>
        })
    }
  </HStack>

}

const RadioCard: React.FC<UseRadioProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  const internalBoxStyles = {
    ...checkbox,
    cursor: 'pointer',
    borderWidth: '1px',
    borderRadius: 'md',
    _checked: {
      color: 'white',
      bg: getClinicalStatusColor(props?.value as ClinicalStatusPriority),
      borderColor: getClinicalStatusColor(props?.value as ClinicalStatusPriority),
    },
    _active: {
      boxShadow: 'outline',
    },
    px: 2,
    py: 1
  }

  return (
    <Box
      as='label'
    >
      <input {...input} />
      <Box {...internalBoxStyles}>
        {props.children}
      </Box>
    </Box >
  )
}

type ClinicalStatusTranslationProps = TextProps & {
  priority: ClinicalStatusPriority
}

export const ClinicalStatusTranslation: React.FC<ClinicalStatusTranslationProps> = props => {
  const { priority, ...textProps } = props

  const intl = useIntl()

  return <Text {...textProps}>
    {intl.formatMessage(clinicalStatusMessages[priority])}
  </Text>
}