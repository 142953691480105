import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { ADD_HISTORY_COMMENT, REMOVE_HISTORY_COMMENT } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ValidateFn, ValidationError } from '../Form'
// reused messages
const messages = defineMessages({
  requiredError: {
    id: 'UI.required_error',
    defaultMessage: 'You must enter a value for this field.',
  },
  patientNotFoundError: {
    id: 'AddManualDiagnose.patient_not_found_error',
    defaultMessage: 'This patient was not found, refresh the page and try again.',
  },
  visitNotFoundError: {
    id: 'AddManualDiagnose.visit_not_found_error',
    defaultMessage: 'This visit was not found, refresh the page and try again.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface HistoryCommentFormValues {
  comment: string
}

interface AddHistoryCommentVariables {
  patientId: string
  visitId: string
  comment: string
}

interface AddHistoryCommentData {
  addHistoryComment: FullPatientWithActiveVisit
}

type AddHistoryCommentParams = MutationHookOptions<
  AddHistoryCommentData,
  AddHistoryCommentVariables
> & {
  patientId: string
  visitId: string
}

export const useAddHistoryComment = (params: AddHistoryCommentParams) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFoundError))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFoundError))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [addHistoryComment, { loading: isLoading }] = useMutation(ADD_HISTORY_COMMENT, {
    onError,
    ...restParams,
  })

  const validate: ValidateFn<HistoryCommentFormValues> = async (values) => {
    const errors: ValidationError<HistoryCommentFormValues> = {}

    if (!values.comment) {
      errors.comment = intl.formatMessage(messages.requiredError)
    }

    return errors
  }

  const onSubmit = (values: HistoryCommentFormValues) => {
    setError(null)

    const { comment } = values
    const variables = {
      patientId,
      visitId,
      comment,
    }

    addHistoryComment({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    validate,
    error,
    onErrorClose,
  }
}

interface RemoveHistoryCommentVariables {
  patientId: string
  visitId: string
  commentId: string
}

interface RemoveHistoryCommentData {
  removeHistoryComment: FullPatientWithActiveVisit
}

type RemoveHistoryCommentParams = MutationHookOptions<
  RemoveHistoryCommentData,
  RemoveHistoryCommentVariables
> & {
  patientId: string
  visitId: string
}

export const useRemoveHistoryComment = (params: RemoveHistoryCommentParams) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFoundError))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFoundError))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [removeHistoryComment, { loading: isLoading }] = useMutation(REMOVE_HISTORY_COMMENT, {
    onError,
    ...restParams,
  })

  const onDelete = (commentId: string) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      commentId,
    }

    removeHistoryComment({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}
