// https://github.com/google/google-authenticator/wiki/Key-Uri-Format

/**
 * Number of digit
 * Default = 6
 */
const DIGIT = 6

/**
 * Valid period in seconds
 * Default = 30
 */
const PERIOD = 30

/**
 * Provider for authenticator app
 */
const PROVIDER = 'walking-docs'

/**
 * This fn takes the username and secret returned by cognito after calling
 * the associate MFA token method and returns and string to be shown as a QR code
 * for the authenticator app
 * @param {string} secret Base32 encoded key
 * @param {string} username Username from cognito
 * @returns {string} Authenticator URI
 */
export const generateTOTPAuthURI = (secret, username) => {
  if (typeof secret !== 'string' || typeof username !== 'string') return ''

  const formatedName = username.replace(/ /g, '').toLowerCase()
  return `otpauth://totp/${PROVIDER}:${formatedName}?secret=${secret}&digits=${DIGIT}&period=${PERIOD}&issuer=${PROVIDER}`
}
