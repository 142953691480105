import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { RiDeleteBin2Line as DeleteIcon } from 'react-icons/ri'
import { ManualDiagnosis as ManualDiagnosisInterface } from '../../api/interfaces'
import { ConfirmButton, SignatureTag } from '..'
import { Box, BoxProps, Flex, Heading, HStack, Text, VStack } from '@chakra-ui/layout'
import Icon from '@chakra-ui/icon'
import { Collapse, Fade } from '@chakra-ui/transition'
import { useDisclosure } from '@chakra-ui/hooks'
import { IconButton } from '@chakra-ui/button'
import { RiArrowRightSLine as ExpandButton } from 'react-icons/ri'
import { useRemoveManualDiagnosis } from '.'

const messages = defineMessages({
  additionalDiagnoses: {
    id: 'ManualDiagnoses.additional_diagnoses_treatments',
    defaultMessage: 'Diagnoses',
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  confirmationMessage: {
    id: 'ManualDiagnoses.removing_confirmation_message',
    defaultMessage: 'This item will permanently delete this diagnosis. Are you sure?',
  },
  moreItems: {
    id: 'UI.label_more_items',
    defaultMessage: '... {count} hidden item/s',
  },
})

type ManualDiagnosesProps = BoxProps & {
  patientId: string
  visitId: string
  items: Array<ManualDiagnosisInterface>
  isDisabled?: boolean
}

export const ManualDiagnoses: React.FC<ManualDiagnosesProps> = (props) => {
  const { items, patientId, visitId, isDisabled, ...boxProps } = props

  const intl = useIntl()

  const { isOpen, onToggle } = useDisclosure()

  const firstTwoItems = items.slice(0, 2)
  const restItems = items.length > 2 ? items.slice(2) : []
  const showExpandButton = restItems.length > 0

  return (
    <Box {...boxProps}>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Heading size={'md'} mb={1} onClick={onToggle} cursor="pointer">
          {intl.formatMessage(messages.additionalDiagnoses)}
        </Heading>
        {showExpandButton && (
          <IconButton
            size="sm"
            aria-label={'expand all'}
            colorScheme={'brand'}
            variant="ghost"
            onClick={onToggle}
            icon={<ExpandButton />}
            sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}
          />
        )}
      </HStack>
      {firstTwoItems.map((manualDiagnosis) => (
        <ManualDiagnosis
          key={manualDiagnosis.id}
          patientId={patientId}
          visitId={visitId}
          manualDiagnosis={manualDiagnosis}
          isDisabled={isDisabled}
        />
      ))}
      {showExpandButton && (
        <Collapse startingHeight={0} in={isOpen} animateOpacity>
          {restItems.map((manualDiagnosis) => (
            <ManualDiagnosis
              key={manualDiagnosis.id}
              patientId={patientId}
              visitId={visitId}
              manualDiagnosis={manualDiagnosis}
              isDisabled={isDisabled}
            />
          ))}
        </Collapse>
      )}
      {showExpandButton && (
        <Fade in={!isOpen}>
          <Flex justifyContent={'center'}>
            <Text
              fontSize={'xs'}
              onClick={onToggle}
              cursor={'pointer'}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {intl.formatMessage(messages.moreItems, { count: items.length - 2 })}
            </Text>
          </Flex>
        </Fade>
      )}
    </Box>
  )
}

interface ManualDiagnosisProps {
  patientId: string
  visitId: string
  manualDiagnosis: ManualDiagnosisInterface
  isDisabled?: boolean
}

const ManualDiagnosis: React.FC<ManualDiagnosisProps> = (props) => {
  const { patientId, visitId, manualDiagnosis, isDisabled } = props

  const intl = useIntl()

  const { id, description, treatment, created } = manualDiagnosis

  const params = {
    visitId,
    patientId,
  }

  const { onDelete, isLoading, error, onErrorClose } = useRemoveManualDiagnosis(params)

  const isLegacy = id === null

  return (
    <HStack
      alignItems={'start'}
      justifyContent={'space-between'}
      mt={1}
      p={2}
      borderWidth={1}
      borderRadius="lg"
    >
      <VStack alignItems={'start'}>
        <Heading size="sm" fontWeight={'bold'} lineHeight={1.5}>
          <SignatureTag signature={created} mr={1} />
          {description}
        </Heading>
        <Text>{treatment}</Text>
      </VStack>
      {!isLegacy && (
        <ConfirmButton
          onConfirm={() => onDelete(id)}
          message={intl.formatMessage(messages.confirmationMessage)}
          buttonProps={{
            size: 'sm',
            color: 'red.400',
            label: <Icon as={DeleteIcon} />,
            isDisabled,
            isLoading,
          }}
          error={error}
          onErrorClose={onErrorClose}
        />
      )}
    </HStack>
  )
}

type PrintableManualDiagnosesProps = BoxProps & {
  items: Array<ManualDiagnosisInterface>
}

export const PrintableManualDiagnoses: React.FC<PrintableManualDiagnosesProps> = (props) => {
  const { items, ...boxProps } = props

  const intl = useIntl()

  if (items.length === 0) {
    return null
  }

  return (
    <Box w={'100%'} {...boxProps}>
      <Heading
        size={'md'}
        borderBottomWidth={1}
        mb={1}
        sx={{
          '@media print': {
            margin: '0 0 .5rem 0',
            borderBottom: '1px solid black',
          },
        }}
      >
        {intl.formatMessage(messages.additionalDiagnoses)}
      </Heading>
      {items.map((manualDiagnosis, index) => {
        const { id, description, treatment } = manualDiagnosis
        const key = `manual-diagnosis-${id}-${index}`

        return (
          <VStack
            alignItems={'start'}
            spacing={0}
            mt={1}
            p={2}
            borderWidth={1}
            borderRadius="lg"
            key={key}
            sx={{
              '@media print': {
                margin: '0 0 .2rem 0',
                padding: 0,
                border: 0,
              },
            }}
            className="pdfManualDiagnose"
          >
            <Heading size="sm" display={'flex'}>
              {description}
            </Heading>
            <Text>{treatment}</Text>
          </VStack>
        )
      })}
    </Box>
  )
}
