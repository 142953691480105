import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { RiDeleteBin2Line as DeleteIcon } from 'react-icons/ri'
import { FullComment } from '../../api/interfaces'
import { ConfirmButton, SignatureTag } from '..'
import { Box, BoxProps, Flex, Heading, HStack, Text } from '@chakra-ui/layout'
import Icon from '@chakra-ui/icon'
import { Collapse, Fade } from '@chakra-ui/transition'
import { useDisclosure } from '@chakra-ui/hooks'
import { IconButton } from '@chakra-ui/button'
import { RiArrowRightSLine as ExpandButton } from 'react-icons/ri'
import { useBreakpointValue } from '@chakra-ui/react'
import { AddHistoryCommentButton, useRemoveHistoryComment } from '../ActiveVisit'

const messages = defineMessages({
  commentsLabel: {
    id: 'UI.comments_label',
    defaultMessage: 'Comments'
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove'
  },
  confirmationMessage: {
    id: 'HistoryComments.removing_confirmation_message',
    defaultMessage: 'This item will permanently delete this note. Are you sure?'
  },
  moreItems: {
    id: 'UI.label_more_items',
    defaultMessage: '... {count} hidden item/s'
  },
})


type HistoryCommentsProps = BoxProps & {
  patientId: string
  visitId: string
  items: Array<FullComment>
  isDisabled?: boolean
}

export const HistoryComments: React.FC<HistoryCommentsProps> = (props) => {
  const { items, patientId, visitId, isDisabled, ...boxProps } = props


  const intl = useIntl()
  const isFullWidth = useBreakpointValue({ base: true, sm: false })

  const { isOpen, onToggle } = useDisclosure()

  const firstTwoItems = items.slice(0, 2)
  const restItems = items.length > 2 ? items.slice(2) : []
  const showExpandButton = restItems.length > 0


  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'sm'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.commentsLabel)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>
    {
      firstTwoItems.map(historyComment => (
        <HistoryComment
          key={historyComment.id}
          patientId={patientId}
          visitId={visitId}
          historyComment={historyComment}
          isDisabled={isDisabled}
        />
      ))
    }
    {
      showExpandButton &&
      <Collapse startingHeight={0} in={isOpen} animateOpacity>
        {
          restItems.map(historyComment => (
            <HistoryComment
              key={historyComment.id}
              patientId={patientId}
              visitId={visitId}
              historyComment={historyComment}
              isDisabled={isDisabled}
            />
          ))
        }
      </Collapse>
    }
    {
      showExpandButton &&
      <Fade in={!isOpen}>
        <Flex justifyContent={'center'}>
          <Text
            fontSize={'xs'}
            cursor={'pointer'}
            onClick={onToggle}
            _hover={{
              textDecoration: 'underline'
            }}
          >
            {intl.formatMessage(messages.moreItems, { count: items.length - 2 })}
          </Text>
        </Flex>
      </Fade>

    }

    <AddHistoryCommentButton
      patientId={patientId}
      visitId={visitId}
      isDisabled={isDisabled}
      isFullWidth={isFullWidth}
      mt={4}
    />
  </Box>
}




interface HistoryCommentProps {
  patientId: string
  visitId: string
  historyComment: FullComment
  isDisabled?: boolean
}

export const HistoryComment: React.FC<HistoryCommentProps> = props => {
  const {
    patientId,
    visitId,
    historyComment,
    isDisabled,
  } = props

  const intl = useIntl()

  const { id, text, created } = historyComment
  const params = {
    visitId,
    patientId
  }

  const { onDelete, isLoading, error, onErrorClose } = useRemoveHistoryComment(params)


  return (
    <HStack justifyContent={'space-between'} mt={1} p={2} borderWidth={1} borderRadius='lg'>
      <Text >
        <SignatureTag signature={created} mr={1} />
        {text}
      </Text>
      <ConfirmButton
        onConfirm={() => onDelete(id)}
        message={intl.formatMessage(messages.confirmationMessage)}
        buttonProps={{
          size: 'sm',
          color: 'red.400',
          label: <Icon as={DeleteIcon} />,
          isDisabled,
          isLoading,
        }}
        error={error}
        onErrorClose={onErrorClose}
      />

    </HStack>
  )
}
