import {
  FullExamChoice,
  FullExamGroup,
  FullExamSection,
  TranslatableText,
} from '../../api/interfaces'
import { isAProcedureChoice, isAToothSection } from 'wd-common/src/logic/exams/utils'
import { ClearingCodesWithText } from '../../utils'

export interface FormattedSectionValues {
  name: TranslatableText
  subValues: Array<TranslatableText>
  isPertinent?: boolean
}

export const getSectionValues = (
  groups: Array<FullExamGroup>,
  clearingCodes: ClearingCodesWithText
): Array<FormattedSectionValues> => {
  return groups.reduce((sectionValues: Array<FormattedSectionValues>, group) => {
    const { choices } = group

    let groupChoices = choices.reduce((selectedValues: Array<FormattedSectionValues>, choice) => {
      const { selected } = choice

      if (selected) {
        const sectionValue: FormattedSectionValues = {
          name: choice.name,
          subValues: getSelectedItemsFromChoice(choice),
        }

        return [...selectedValues, sectionValue]
      }

      return selectedValues
    }, [])

    if (isSomethingClearedInGroup(group, clearingCodes)) {
      const clearedValuesToAdd: Array<FormattedSectionValues> = []

      group.physical_exam_codes?.forEach((code) => {
        const values = clearingCodes.get(code) || []

        values.forEach((v) => {
          if (v) {
            clearedValuesToAdd.push({
              name: v,
              subValues: [],
              isPertinent: true,
            })
          }
        })
      })

      groupChoices = [...groupChoices, ...clearedValuesToAdd]
    }

    return [...sectionValues, ...groupChoices]
  }, [])
}

export const getSelectedItemsFromChoice = (choice: FullExamChoice): Array<TranslatableText> => {
  const { details } = choice

  if (!Array.isArray(details)) {
    return []
  }

  return details.reduce((selectedItems: Array<TranslatableText>, detail) => {
    if (!Array.isArray(detail.selected_items)) {
      return selectedItems
    }

    return [...selectedItems, ...detail.selected_items]
  }, [])
}

/**
 * Classify groups of selected section between
 * procedures and statuses.
 *
 * @param {Object} selectedSection
 * @returns
 */
export const classifySectionGroups = (selectedSection) => {
  const initialValue = {
    statuses: [],
    procedures: [],
  }

  return Array.isArray((selectedSection || []).groups)
    ? selectedSection.groups.reduce((prev, group) => {
        const { choices } = group || {}

        const isProcedure = (choices || []).some((choice) => {
          return isAProcedureChoice(choice.name.text)
        })

        if (isProcedure) {
          return {
            ...prev,
            procedures: [...prev.procedures, group],
          }
        }

        return {
          ...prev,
          statuses: [...prev.statuses, group],
        }
      }, initialValue)
    : initialValue
}

type ClassifyExamSectionsReturnedValue = {
  usedSections: Array<FullExamSection>
  unusedSections: Array<FullExamSection>
}
type ClassifyExamSections = (
  sections: Array<FullExamSection>,
  clearingCodes: ClearingCodesWithText
) => ClassifyExamSectionsReturnedValue

export const classifyExamSections: ClassifyExamSections = (sections, clearingCodes) => {
  const initialValue = {
    usedSections: [],
    unusedSections: [],
  }

  if (!Array.isArray(sections)) {
    return initialValue
  }

  const groupHasSomethingSelected = (group) => {
    return group && (group.choices || []).some((choice) => choice.selected)
  }

  const groupHasSomethingCleared = (group, clearingCodes) => {
    return group?.physical_exam_codes?.some((pce) => clearingCodes.has(pce))
  }

  return sections
    .filter((section) => !isAToothSection(section.name.text))
    .reduce((prev: ClassifyExamSectionsReturnedValue, section) => {
      const hasSomethingSelected = (section.groups || []).some(
        (group) =>
          groupHasSomethingSelected(group) || groupHasSomethingCleared(group, clearingCodes)
      )

      if (hasSomethingSelected) {
        return {
          ...prev,
          usedSections: [...prev.usedSections, section],
        }
      }

      return {
        ...prev,
        unusedSections: [...prev.unusedSections, section],
      }
    }, initialValue)
}

export const isSomethingSelectedInSection = (section: FullExamSection) => {
  return section.groups.some((group) => {
    const { choices } = group

    return choices.some((detail) => detail.selected)
  })
}

export const isSomethingClearedInGroup = (
  group: FullExamGroup,
  clearingCodes: ClearingCodesWithText
) => {
  return Boolean(
    group &&
      group.physical_exam_codes &&
      group.physical_exam_codes.some((pce) => clearingCodes.has(pce))
  )
}
