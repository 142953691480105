import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit, StatusAssesment } from '../../api/interfaces'
import { UPDATE_STATUS_ASSESSMENT } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  invalidSignatureError: {
    id: 'StatusAndDisposition.start_exam_invalid_signature_error',
    defaultMessage: 'We could not validate your identity. Refresh the page and try again.',
  },
  invalidVisitError: {
    id: 'StatusAndDisposition.start_exam_invalid_visit_error',
    defaultMessage: 'This visit id is not valid. Refresh the page and try again.',
  },
  invalidStatus: {
    id: 'StatusAndDisposition.invalid_status_assessment',
    defaultMessage: 'Please, select a valid status assessment.',
  },
  errorUpdatingStatus: {
    id: 'StatusAndDisposition.error_updating_status_assesment',
    defaultMessage: 'Could not update the status assessment. Please, try again.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface UpdateStatusAssessmentVariables {
  patientId: string
  visitId: string
  status: StatusAssesment
}

interface UpdateStatusAssesmentMutationData {
  updateStatusAssessment: FullPatientWithActiveVisit
}

type Params = MutationHookOptions<
  UpdateStatusAssesmentMutationData,
  UpdateStatusAssessmentVariables
> & {
  patientId: string
  visitId: string
}

export const useUpdateStatusAssessment = (params: Params) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'error_invalid_signature':
        return setError(intl.formatMessage(messages.invalidSignatureError))

      case 'patient_not_found':
      case 'visit_not_found':
        return setError(intl.formatMessage(messages.invalidVisitError))

      case 'invalid_follow_up':
        return setError(intl.formatMessage(messages.invalidStatus))

      case 'error_updating_follow_up':
        return setError(intl.formatMessage(messages.errorUpdatingStatus))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [updateStatusAssessment, { loading: isLoading }] = useMutation(UPDATE_STATUS_ASSESSMENT, {
    onError,
    ...restParams,
  })

  const onStatusAssessmentChange = (newStatusAssessment: StatusAssesment) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      status: newStatusAssessment,
    }

    updateStatusAssessment({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onStatusAssessmentChange,
    isLoading,
    error,
    onErrorClose,
  }
}
