import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { PaginatedResult } from '../../api/interfaces'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { GET_SURVEYS } from '../../api/requests/surveys'
import { Survey } from '../../api/interfaces'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface SurveysVariables {
  q?: string
  offset?: number
  limit?: number
}

interface SurveysData {
  surveys: PaginatedResult<Survey>
}

export type UseSurveysParams = QueryHookOptions<SurveysData, SurveysVariables>

interface ReturnedValue {
  surveys: Array<Survey>
  count: number
  isLoading: boolean
  error: any
  getSurvey: (patientId: string) => Nullable<Survey>
  refetch: (newArgs?: SurveysVariables) => void
  onErrorClose: () => void
}

export const useSurveys = (params?: UseSurveysParams): ReturnedValue => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const { loading, data, refetch } = useQuery<SurveysData, SurveysVariables>(GET_SURVEYS, {
    onError,
    ...params,
  })

  const getSurvey = React.useCallback(
    (surveyId: string): Nullable<Survey> => {
      if (!data?.surveys) {
        return null
      }

      const { docs } = data.surveys
      const found = docs.find((survey) => survey.id === surveyId)

      return found ?? null
    },
    [data]
  )

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading: loading,
    surveys: data?.surveys.docs ?? [],
    count: data?.surveys.count ?? 0,
    error,
    getSurvey,
    refetch,
    onErrorClose,
  }
}
