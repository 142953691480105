import { Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import { useChakraThemeParser } from '.'
import { BasicChecklist } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { useChecklists } from '../Checklist'
import {
    ReactSelectInternalValue,
    ReactSelectInternalSingleValue,
    SelectorProps,
    isMultiSelector
} from './utils'


type ChecklistSelectorProps = SelectorProps<string> & {
    tag?: Array<string>
}


export const ChecklistSelector: React.FC<ChecklistSelectorProps> = props => {
    const {
        value,
        tag = ['Diagnosis']
    } = props

    const intl = useIntl()
    const theme = useChakraThemeParser()

    const variables = { limit: 1000 }
    const { checklists, isLoading, getChecklist } = useChecklists({ variables })

    const formatOption = React.useCallback((checklist: BasicChecklist): ReactSelectInternalSingleValue => {
        const { id, name, background } = checklist

        const translatedName = name?.text
            ? intl.formatMessage({ id: `Dynamic.${name.text}`, defaultMessage: name.text })
            : ''

        const translatedBackground = background?.text
            ? intl.formatMessage({ id: `Dynamic.${background.text}`, defaultMessage: background.text })
            : ''

        const [shortText] = translatedName.split('(')
        return {
            label: <VStack alignItems={'start'} justifyContent={'center'} spacing={0}>
                <Text fontWeight={'bold'} >{shortText}</Text>
                {
                    translatedBackground &&
                    <Text fontSize={'sm'} noOfLines={1} fontStyle={'italic'}>{translatedBackground}</Text>
                }
            </VStack>,
            id: id,
            value: shortText,
        }
    }, [intl])

    const options = React.useMemo(() => {
        if (Array.isArray(checklists)) {
            return checklists
                .filter(checklist => checklist.name?.text && tag.some(tag => checklist.general_tags?.includes(tag)))
                .map(value => formatOption(value as BasicChecklist))
        }

        return []
    }, [checklists, tag, formatOption])

    /**
     * Parse value prop to internal react select state by searching into the options
     * when they're loaded
     */
    const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
        if (options === null) {
            return null
        }

        if (isMultiSelector(props)) {
            if (!Array.isArray(value)) {
                return null
            }

            const formattedValues = value
                .reduce((prev: Array<ReactSelectInternalSingleValue>, checklistId: string) => {
                    const checklist = getChecklist(checklistId)

                    if (checklist) {
                        return [
                            ...prev,
                            formatOption(checklist)
                        ]
                    }

                    return prev
                }, [])

            return formattedValues
        } else {
            const checklist = getChecklist(value as string)

            if (checklist) {
                return formatOption(checklist)
            }

            return null
        }
    }

    const _onChange = (internalValue): void => {
        if (isMultiSelector(props)) {
            if (!Array.isArray(internalValue)) {
                return props.onChange([])
            }

            const selectedValues = internalValue
            const newValues = selectedValues.map(item => item.id)

            props.onChange(newValues)
        } else {
            if (!internalValue) {
                return props.onChange('')
            }

            const selectedValue = internalValue

            props.onChange(selectedValue.id)
        }
    }

    return <Select
        options={options}
        value={parseValue(value)}
        onChange={_onChange}
        isLoading={isLoading}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
        isDisabled={props.isDisabled}
        isSearchable={props.isSearchable}
        aria-invalid={props.isInvalid}
        placeholder={props.placeholder}
        blurInputOnSelect={props.blurInputOnSelect}
        theme={theme}
    />
}