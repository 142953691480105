import { FormLabel } from '@chakra-ui/react'
import React from 'react'
import { useField } from 'react-final-form'
import {
  BillableCategorySelector,
  BillableCategorySelectorProps,
} from '../Selector/BillableCategorySelector'
import { Control, Error } from './utils'

type BillableCategoryFieldProps = Omit<
  BillableCategorySelectorProps,
  'defaultValue' | 'value' | 'onChange'
> & {
  name: string
  label?: string
}
export const BillableCategoryField: React.FC<BillableCategoryFieldProps> = (props) => {
  const { name, label, ...selectorProps } = props
  const { input, meta } = useField(name)

  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <BillableCategorySelector
        isInvalid={meta.error && meta.touched}
        placeholder={label ?? undefined}
        {...input}
        {...selectorProps}
      />
      <Error name={name} />
    </Control>
  )
}
