import React from 'react'
import { FormLabel, Textarea, TextareaProps } from '@chakra-ui/react'
import { useField } from 'react-final-form'
import { Control, Error } from './utils'

interface TextFieldProps extends TextareaProps {
  label: string
  name: string
  maxRows?: number
}

export const MultiLineTextField: React.FC<TextFieldProps> = (props) => {
  const { name, label, maxRows, ...otherInputProps } = props
  const { input, meta } = useField(name)

  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Textarea
        {...input}
        {...otherInputProps}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={label}
        onChange={(event) => {
          if (maxRows) {
            const { value } = event.target
            const rows = value.split('\n')
            if (rows.length <= maxRows) {
              input.onChange(event)
            }
          } else {
            input.onChange(event)
          }
        }}
      />
      <Error name={name} />
    </Control>
  )
}
