import React, { ReactElement } from 'react'
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	AlertDialogProps,
	Button,
	ButtonProps,
	Text,
	useBreakpointValue,
	useDisclosure
} from '@chakra-ui/react'
import { defineMessages, useIntl } from 'react-intl'
import { Nullable } from '../utils'
import { ErrorViewer } from '.'

const messages = defineMessages({
	confirmation: {
		id: 'ConfirmationModal.title',
		defaultMessage: 'Confirmation'
	},
	cancelButton: {
		id: 'UI.button_cancel',
		defaultMessage: 'Cancel'
	},
	confirmButton: {
		id: 'UI.button_confirm',
		defaultMessage: 'Confirm'
	}
})

export type NestedButtonProps = Omit<ButtonProps, 'onClick'> & {
	label?: string | ReactElement
}

export interface ConfirmButtonProps {
	onConfirm: () => void | Promise<void>
	onCancel?: () => void | Promise<void>
	message: string
	error?: Nullable<string>
	onErrorClose?: () => void
	buttonProps: NestedButtonProps
	confirmButtonProps?: NestedButtonProps
	cancelButtonProps?: NestedButtonProps
	skipClosing?: boolean
	motionPreset?: Extract<AlertDialogProps, 'MotionPreset'>
	stopPropagation?: boolean
}

export const ConfirmButton: React.FC<ConfirmButtonProps> = (props) => {
	const {
		message,
		buttonProps,
		confirmButtonProps,
		cancelButtonProps,
		error,
		onErrorClose,
		motionPreset = 'slideInBottom'
	} = props

	const intl = useIntl()
	const disclosure = useDisclosure()
	const isFullWidth = useBreakpointValue({ base: true, sm: false })

	const onClose = async () => {
		if (typeof props.onCancel === 'function') {
			await props.onCancel()
		}
		disclosure.onClose()
	}

	const onConfirm = async () => {
		if (typeof props.onConfirm === 'function') {
			await props.onConfirm()
		}
		if (!props.skipClosing) {
			disclosure.onClose()
		}
	}

	const onClick = (event) => {
		if (props.stopPropagation) {
			event.stopPropagation()
		}

		disclosure.onOpen()
	}

	// to handle focus on open/close
	const cancelRef = React.useRef<Nullable<HTMLButtonElement>>(null)

	const { label, ...restButtonProps } = buttonProps

	const confirmLabel = confirmButtonProps?.label ?? intl.formatMessage(messages.confirmButton) 
	const cancelLabel = cancelButtonProps?.label ?? intl.formatMessage(messages.cancelButton)

	return <>
		<Button {...restButtonProps} onClick={onClick}>
			{label}
		</Button>
		<AlertDialog
			motionPreset={motionPreset}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
			isOpen={disclosure.isOpen}
			isCentered
		>
			<AlertDialogOverlay />

			<AlertDialogContent>
				<AlertDialogHeader>
					{intl.formatMessage(messages.confirmation)}
				</AlertDialogHeader>
				<AlertDialogCloseButton />
				<AlertDialogBody>
					<Text>
						{message}
					</Text>
					{
						error &&
						<ErrorViewer title={error} onClose={onErrorClose} />
					}
				</AlertDialogBody>
				<AlertDialogFooter>
					<Button
						onClick={onClose}
						isFullWidth={isFullWidth}
						{...cancelButtonProps}
						ref={cancelRef}
					>
						{cancelLabel}
					</Button>
					<Button
						colorScheme='red'
						ml={3}
						isFullWidth={isFullWidth}
						{...confirmButtonProps}
						onClick={onConfirm}>
						{confirmLabel}
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	</>
}

