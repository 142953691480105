import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'

type PercentileWarningProps = TextProps & {
  percentile: number
}

export const PercentileWarning: React.FC<PercentileWarningProps> = (props) => {
  const { percentile, ...textProps } = props

  if (percentile < 3) {
    return <Text {...textProps}>{`(< 1%)`}</Text>
  } else if (percentile >= 98) {
    return <Text {...textProps}>{`(> 99%)`}</Text>
  } else {
    return <Text {...textProps}>{`(${Math.round(Math.round(percentile * 10) / 10)}%)`}</Text>
  }
}

export const PdfPercentileWarning: React.FC<PercentileWarningProps> = (props) => {
  const { percentile } = props

  if (percentile < 3) {
    return <span>{`(< 1%)`}</span>
  } else if (percentile >= 98) {
    return <span>{`(> 99%)`}</span>
  } else {
    return <span>{`(${Math.round(Math.round(percentile * 10) / 10)}%)`}</span>
  }
}
