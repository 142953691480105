import React from 'react'
import { useLazyQuery } from '@apollo/client'
import { Nullable } from '../../utils'
import { GET_ACTIVATION_CHECKOUT_URI } from '../../api/requests/setup'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred. Try again',
  },
})
interface ActivationCheckoutQueryData {
  activationCheckout: string
}
interface ReturnedValue {
  checkout: () => void
  isLoading: boolean
  error: any
}

export const useActivationCheckout = (): ReturnedValue => {
  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = () => {
    setError(intl.formatMessage(messages.internalServerError))
  }

  const [checkout, { loading, data }] = useLazyQuery<ActivationCheckoutQueryData, void>(
    GET_ACTIVATION_CHECKOUT_URI,
    {
      onError,
    }
  )

  React.useEffect(() => {
    if (data?.activationCheckout) {
      window.location.href = data.activationCheckout
    }
  }, [data])

  return {
    isLoading: loading,
    checkout,
    error,
  }
}
