import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { FullVisit } from '../../api/interfaces'
import { defineMessages, useIntl } from 'react-intl'
import { GET_VISIT_BY_ID } from '../../api/requests/visits'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})
interface PastVisitParams {
  id: string
}

interface PastVisitData {
  visit: FullVisit
}

export type UsePastVisitByIdParams = QueryHookOptions<PastVisitData, PastVisitParams> & {
  id: string
}

export function usePastVisitByID(params: UsePastVisitByIdParams) {
  const { id, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery(GET_VISIT_BY_ID, {
    variables: {
      id,
    },
    ...rest,
    onError,
  })

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading,
    visit: data ? data.visit : null,
    error,
    onErrorClose,
    refetch,
  }
}
