import React from 'react'
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
  fromPromise,
  Operation,
} from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { useSession } from '../components/Auth'
import { getAuthUri, getSetupUri, getUnAuthUri } from './utils'
import { captureException } from '@sentry/browser'
import { AccountStatus } from '../components/Auth/subscription'
import { useDebounceExecute } from '../components'
import { Button, createStandaloneToast, HStack, Text } from '@chakra-ui/react'

const MAX_REFRESH_TOKEN_ATTEMPT = 3
const useHttpLink = (): HttpLink => {
  const { getToken, getUserLocation, getUserSystem, isLoggedIn, getAccountStatus } = useSession()

  if (isLoggedIn()) {
    const token = getToken()

    const accountStatus = getAccountStatus()

    switch (accountStatus) {
      case AccountStatus.FULL_ACCESS:
      case AccountStatus.READ_ONLY_ACCESS: {
        const wdLocation = getUserLocation()
        const wdSystem = getUserSystem()

        return new HttpLink({
          uri: getAuthUri(),
          credentials: 'same-origin',
          headers: {
            Authorization: `Bearer ${token}`,
            ['WD-System']: wdSystem ? wdSystem.id : '',
            ['WD-Location']: wdLocation ? wdLocation.id : '',
          },
        })
      }

      case AccountStatus.PAYMENT_REQUIRED:
      case AccountStatus.SETUP_PENDING:
      case AccountStatus.REACTIVATION_REQUIRED:
      default: {
        return new HttpLink({
          uri: getSetupUri(),
          credentials: 'same-origin',
          headers: {
            Authorization: `Bearer ${token}`,
            ['WD-System']: '',
            ['WD-Location']: '',
          },
        })
      }
    }
  }

  const unauthURI = getUnAuthUri()
  return new HttpLink({
    uri: `${unauthURI}/graphql`,
    credentials: 'same-origin',
  })
}

const useErrorLink = (): ApolloLink => {
  const { isLoggedIn, refresh, getToken } = useSession()

  const toastId = 'api-lost-toast'
  const toast = createStandaloneToast()

  const { execute } = useDebounceExecute(1000)
  const [refreshTokenAttempt, setRefreshTokenAttempt] = React.useState<number>(0)
  const [isWaiting, setIsWaiting] = React.useState<boolean>(false)

  const refreshToken = async (operation: Operation) => {
    setIsWaiting(true)
    await refresh()
    const newToken = getToken()
    operation.setContext((prev) => ({
      ...prev,
      headers: {
        ...prev.headers,
        Authorization: `Bearer ${newToken}`,
      },
    }))
    console.log('done')
    setIsWaiting(false)
  }

  /**
   * Translate it
   */
  const onAPIDisconnect = () => {
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        position: 'bottom-right',
        title: 'Server is not responding',
        description: (
          <HStack>
            <Text>{'We could not connect to the API.'}</Text>
            <Button variant={'link'} color="white" onClick={() => setRefreshTokenAttempt(0)}>
              Retry
            </Button>
          </HStack>
        ),
        status: 'error',
        duration: null,
        isClosable: true,
      })
    }
  }

  return onError((errorResponse: ErrorResponse) => {
    const { graphQLErrors, networkError, forward, operation } = errorResponse

    if (networkError !== null) {
      if (networkError) {
        const isUnauthenticatedError = (networkError as any)?.statusCode === 401
        const isLog = isLoggedIn()
        if (isUnauthenticatedError && isLog) {
          if (refreshTokenAttempt < MAX_REFRESH_TOKEN_ATTEMPT) {
            if (isWaiting) return

            execute(async () => {
              setRefreshTokenAttempt(refreshTokenAttempt + 1)
              return fromPromise(refreshToken(operation)).flatMap(() => forward(operation))
            })
          } else {
            onAPIDisconnect()
          }
        }

        if (graphQLErrors) {
          captureException(graphQLErrors)
        }
      }
    }
  })
}

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: string) => (key === '__typename' ? undefined : value)
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
  }
  return forward(operation)
})

function useApolloClient() {
  const httpLink = useHttpLink()
  const errorLink = useErrorLink()

  // clean __typename from the mutations

  return new ApolloClient({
    ssrMode: false,
    link: from([cleanTypeName, errorLink, httpLink]),
    cache: new InMemoryCache(),
  })
}

export const CustomApolloProvider: React.FC = (props) => {
  const apolloClient = useApolloClient()

  return (
    <>
      <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
    </>
  )
}
