/**
 * All date time abstractions are defined here to make the
 * migration easier in case we need to change the used librarys
 */
import { DateTime, DurationUnit, Interval, Settings } from 'luxon'

/**
 * Compare dates.
 * It will compare with the current datetime if the
 * reference is not passed
 * @param value date to compare
 * @param reference reference
 */
export const isBefore = (value: Date, reference?: Date) => {
  const date = DateTime.fromJSDate(value)
  const referenceDate = DateTime.fromJSDate(reference ?? new Date())
  const duration = date.diff(referenceDate)

  return duration.toMillis() < 0
}

export const getAge = (birthdate: Date, unit?: DurationUnit) => {
  const date = DateTime.fromJSDate(new Date(birthdate))

  return date ? Interval.fromDateTimes(date, DateTime.now()).length(unit ?? 'year') : null
}

export const isValidDate = (value: string | number | Date): boolean => {
  const jsDate = new Date(value)
  const fromString = DateTime.fromJSDate(jsDate)

  return fromString.isValid
}

export const getTimezone = (): string => {
  if (typeof Settings.defaultZone === 'string') {
    return Settings.defaultZone
  }

  return Settings.defaultZone.name
}
