import React from 'react'
import {
  Badge,
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { PaginatedList, useURLPagination, Filter } from '../../components/Searches'
import { defineMessages, useIntl } from 'react-intl'
import {
  CustomField,
  CustomFieldType,
  NonPrimitiveCustomFieldType,
} from '../../api/interfaces'
import { ConfirmButton } from '../../components'
import { RiMore2Line as RightArrowIcon, RiDeleteBin2Line as DeleteIcon } from 'react-icons/ri'
import { useSystems } from '../Systems'
import { customFieldTypeMessages } from '../Selector/CustomFieldTypeSelector'
import { useDeleteCustomField } from './useCustomFieldActions'
import { isPrimitiveCustomField } from './utils'
import { CreateCustomFieldButton, UpdateCustomFieldButton } from './CustomFieldModal'
import { ConfirmButtonProps } from '../ConfirmButton'

const messages = defineMessages({
  title: {
    id: 'CustomFieldsTab.custom_fields',
    defaultMessage: 'Custom fields',
  },
  requiredLabel: {
    id: 'CustomFieldsTab.required',
    defaultMessage: 'Required',
  },
  delete: {
    id: 'UI.button_delete',
    defaultMessage: 'Delete',
  },
  confirmationMessage: {
    id: 'CustomFieldsTab.remove_custom_field',
    defaultMessage: 'This field will be removed from the registration form. Are you sure?',
  },
  edit: {
    id: 'UI.button_edit',
    defaultMessage: 'Edit',
  },
})

function filterItems<T>(items: Array<T>, filter: Filter, fn: (q: string, item: T) => boolean): Array<T> {
  const { q, offset, limit } = filter

  return items
    .filter((item) => fn(q, item))
    .slice(offset, offset + limit)
}

const filterFn = (q: string, item: CustomField) => {
  if (!q) {
    return true
  }

  const str = (item.name ?? '').toLowerCase()

  return str.indexOf(q.toLowerCase()) >= 0
}

export const CustomFieldsList: React.FC = () => {
  const intl = useIntl()

  const { getMySystem } = useSystems()
  const { customFields } = getMySystem()

  const { filter, page, onSearch, onPageChange } = useURLPagination({ defaultLimit: 5 })
  const filteredItems = Array.isArray(customFields)
    ? filterItems(customFields, filter, filterFn)
    : []

  return (
    <Box p={2}>
      <Heading size={'md'} mb={2}>
        {intl.formatMessage(messages.title)}
      </Heading>
      <PaginatedList
        items={filteredItems}
        total={customFields?.length ?? 0}
        itemsPerPage={filter.limit}
        page={page}
        defaultSearch={filter.q}
        onSearch={onSearch}
        onPageChange={onPageChange}
        renderItem={(customField) => <Item customField={customField} key={customField.id} />}
      />
      <HStack justifyContent={'end'} mt={3}>
        <CreateCustomFieldButton />
      </HStack>
    </Box>
  )
}

interface ItemProps {
  customField: CustomField
}

const Item: React.FC<ItemProps> = (props) => {
  const { customField } = props

  const intl = useIntl()

  const { onSubmit, isLoading, error, onErrorClose } = useDeleteCustomField({ id: customField.id })

  const onConfirm = () => {
    onSubmit()
  }

  const buttonStyles = {
    variant: 'ghost',
    isFullWidth: true,
    justifyContent: 'start',
    borderRadius: 'sm',
  }

  const deletePatientProps: ConfirmButtonProps = {
    onConfirm,
    message: intl.formatMessage(messages.confirmationMessage),
    error,
    onErrorClose,
    buttonProps: {
      label: (
        <>
          <Icon as={DeleteIcon} mr={2} />
          {intl.formatMessage(messages.delete)}
        </>
      ),
      colorScheme: 'red',
      ...buttonStyles,
    },
    confirmButtonProps: {
      label: intl.formatMessage(messages.delete),
      isLoading,
    },
    skipClosing: true,
  }

  return (
    <ListItem p={3} borderBottomWidth={1}>
      <Flex alignItems={'center'}>
        <Box>
          <HStack>
            <Heading size="sm" fontWeight={'bolder'}>
              {customField.name}
            </Heading>
            {customField.required && (
              <Badge mx={1} colorScheme="brand">{intl.formatMessage(messages.requiredLabel)}</Badge>
            )}
          </HStack>
          <CustomFieldTypeLabel customField={customField} />
        </Box>
        <Spacer />
        <Menu>
          <MenuButton as={IconButton} icon={<RightArrowIcon />} variant={'ghost'} />
          <MenuList zIndex={2}>
            <UpdateCustomFieldButton
              customField={customField}
              {...buttonStyles}
              colorScheme='brand'
              as={MenuItem}
            />
            <MenuDivider />
            <ConfirmButton {...deletePatientProps} />
          </MenuList>
        </Menu>
      </Flex>
    </ListItem>
  )
}

interface CustomFieldTypeProps {
  customField: CustomField
}

const CustomFieldTypeLabel: React.FC<CustomFieldTypeProps> = (props) => {
  const { customField } = props

  const intl = useIntl()

  const type: CustomFieldType = isPrimitiveCustomField(customField)
    ? customField.type
    : NonPrimitiveCustomFieldType.TEXT_LIST

  return <Text fontSize={'sm'}>{intl.formatMessage(customFieldTypeMessages[type])}</Text>
}


