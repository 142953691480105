import React from 'react'
import { Button, Stack, useBreakpointValue } from '@chakra-ui/react'
import { defineMessages } from '@formatjs/intl'
import { Form } from 'react-final-form'
import { useIntl } from 'react-intl'
import { Slot } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { ErrorViewer } from '../ErrorViewer'
import { TextField, ValidateFn } from '../Form'
import { TimeUnitField } from '../Form/TimeUnitField'

const messages = defineMessages({
  slotUnit: {
    id: 'TelehealthTab.label_slot_unit',
    defaultMessage: 'Slot unit',
  },
  slotLength: {
    id: 'TelehealthTab.label_slot_length',
    defaultMessage: 'Slot length',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
})

interface CreatePricelistForm {
  initialValues?: Slot
  onSubmit: (values: Slot) => void
  isLoading?: boolean
  validate: ValidateFn<Slot>
  error: Nullable<string>
  onErrorClose: () => void
  onCancel: () => void
}

export const TelehealthSettingsForm: React.FC<CreatePricelistForm> = (props) => {
  const { initialValues, validate, error, onErrorClose, onSubmit, isLoading, onCancel } = props

  const intl = useIntl()

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TextField
              type="number"
              name="length"
              label={intl.formatMessage(messages.slotLength)}
            />
            <TimeUnitField name="unit" label={intl.formatMessage(messages.slotUnit)} />

            {error && <ErrorViewer title={error} onClose={onErrorClose} />}

            <Stack isInline={!isFullWidth} justifyContent={'end'} py={3}>
              <Button onClick={onCancel} isFullWidth={isFullWidth} >
                {intl.formatMessage(messages.cancelButton)}
              </Button>
              <Button
                colorScheme={'brand'}
                isLoading={isLoading}
                type="submit"
                isFullWidth={isFullWidth}
                isDisabled={pristine}
              >
                {intl.formatMessage(messages.saveButton)}
              </Button>
            </Stack>
          </form>
        )
      }}
    />
  )
}
