export const VITALS = Object.freeze({
  BLOOD_PRESSURE: 'blood_pressure',
  WEIGHT: 'weight',
  HEIGHT: 'height',
  HEAD_CIRCUMFERENCE: 'head_circumference',
  MID_UPPER_ARM_CIRCUMFERENCE: 'mid_upper_arm_circumference',
  TEMPERATURE: 'temperature',
  HEART_RATE: 'heart_rate',
  RESPIRATORY_RATE: 'respiratory_rate',
  O2_SATURATION: 'o2_saturation'
})

export const AGE_CATEGORIES = Object.freeze({
  NEW_BORN: 'newBorn',
  NEONATE: 'neonate',
  INFANT: 'infant',
  TODDLER: 'toddler',
  PRESCHOOLER: 'preschooler',
  SCHOOL_AGED_CHILD: 'schoolAgedChild',
  PREADOLESCENT: 'preadolescent',
  ADOLESCENT_13_15: 'adolescent13to15',
  ADOLESCENT_16_19: 'adolescent16to19',
  ADULT: 'adult'
})
/**
 * Normal vitals by age in days
 *
 *
 * We used moment to calculate the range date {min, max}
 *
 * For example:
 * 1 day = moment.duration(1, 'day').asDays() => 1
 * 1 month = moment.duration(1, 'month').asDays() => 30
 * 1 year = moment.duration(6, 'year').asDays() => 365
 *
 * Then we calculate the difference between the current day
 * and the birthdate:
 *
 * ageInDays = moment().diff(moment(birthdate), 'days')
 *
 * min <= ageInDays < max
 *
 * This means:
 *  - Fixed periods
 *  - leap year support
 *  - no runtime calculation
 *
 *
 * The other option would be:
 * Take the current day and substract the corresponding
 * periods. Then we would need to check the birthdate
 * to be between both dates
 *
 * min = moment().diff(moment(max), 'days')
 * max = moment().diff(moment(min), 'days')
 * ageInDays = moment().diff(moment(birthdate), 'days')
 *
 * min < ageInDays < max
 *
 * The second one would be more precise in term of 'months'
 * but it allows to have 2 patients with the same age in days
 * belonging to different categories because 1 month would mean
 * 28 days or 31 days depending on when the patient born
 *
 * Fox example:
 * PatientA born 1st of Feb
 * PatientB born 1st of May
 *
 * PatientA goes to the hospital on 2st of March: INFANT (1m - 1y)
 * PatientB goes to the hospital on 30th of May: NEONATE (< 1 month)
 *
 * Both are 29 days, but they're placed in different categories.
 *
 * Doctors prefered the firt approach to improve precision in term of
 * days
 */
export const NORMAL_VITALS = Object.freeze({
  // 0 to 4 days
  [AGE_CATEGORIES.NEW_BORN]: {
    age: { min: 0, max: 4 },
    t: { min: 36.5, max: 37.9 },
    wt: { min: 5, max: 95 },
    ht: { min: 5, max: 95 },
    hcm: { min: 5, max: 95 },
    hr: { min: 100, max: 205 },
    rr: { min: 40, max: 60 },
    bp: {
      systolic: { min: 60, max: 76 },
      diastolic: { min: 31, max: 45 }
    },
    o2: { min: 92, max: 100 }
  },
  // 4 days to 1 month
  [AGE_CATEGORIES.NEONATE]: {
    age: { min: 4, max: 30 },
    t: { min: 36.5, max: 37.9 },
    wt: { min: 5, max: 95 },
    ht: { min: 5, max: 95 },
    hcm: { min: 5, max: 95 },
    hr: { min: 100, max: 205 },
    rr: { min: 40, max: 60 },
    bp: {
      systolic: { min: 67, max: 84 },
      diastolic: { min: 35, max: 53 }
    },
    o2: { min: 92, max: 100 }
  },
  // 1 month to 1 year
  [AGE_CATEGORIES.INFANT]: {
    age: { min: 30, max: 365 },
    t: { min: 36.5, max: 37.9 },
    wt: { min: 5, max: 95 },
    ht: { min: 5, max: 95 },
    hcm: { min: 5, max: 95 },
    hr: { min: 100, max: 180 },
    rr: { min: 30, max: 53 },
    bp: {
      systolic: { min: 72, max: 104 },
      diastolic: { min: 37, max: 56 }
    },
    o2: { min: 92, max: 100 },
    muac: {
      male: { min: 12.5, max: 1000 },
      female: { min: 12.5, max: 1000 }
    }
  },
  // 1 to 3 years
  [AGE_CATEGORIES.TODDLER]: {
    age: { min: 365, max: 1096 },
    t: { min: 36.5, max: 37.9 },
    wt: { min: 5, max: 95 },
    ht: { min: 5, max: 95 },
    hcm: { min: 5, max: 95 },
    hr: { min: 98, max: 140 },
    rr: { min: 22, max: 37 },
    bp: {
      systolic: { min: 86, max: 106 },
      diastolic: { min: 42, max: 63 }
    },
    o2: { min: 92, max: 100 },
    bmi: {
      male: { min: 5, max: 95 },
      female: { min: 5, max: 95 }
    },
    muac: {
      male: { min: 12.5, max: 1000 },
      female: { min: 12.5, max: 1000 }
    }
  },
  // 3 to 6 years
  [AGE_CATEGORIES.PRESCHOOLER]: {
    age: { min: 1096, max: 2191 },
    t: { min: 36.5, max: 37.9 },
    wt: { min: 5, max: 95 },
    ht: { min: 5, max: 95 },
    hcm: { min: 5, max: 95 },
    hr: { min: 80, max: 120 },
    rr: { min: 20, max: 28 },
    bp: {
      systolic: { min: 89, max: 112 },
      diastolic: { min: 46, max: 72 }
    },
    o2: { min: 92, max: 100 },
    bmi: {
      male: { min: 5, max: 95 },
      female: { min: 5, max: 95 }
    },
    muac: {
      male: { min: 12.5, max: 1000 },
      female: { min: 12.5, max: 1000 }
    }
  },
  // 6 to 10 years
  [AGE_CATEGORIES.SCHOOL_AGED_CHILD]: {
    age: { min: 2191, max: 3652 },
    t: { min: 36.5, max: 37.9 },
    wt: { min: 5, max: 95 },
    ht: { min: 5, max: 95 },
    hcm: { min: 5, max: 95 },
    hr: { min: 75, max: 118 },
    rr: { min: 18, max: 25 },
    bp: {
      systolic: { min: 97, max: 115 },
      diastolic: { min: 57, max: 76 }
    },
    o2: { min: 92, max: 100 },
    bmi: {
      male: { min: 5, max: 95 },
      female: { min: 5, max: 95 }
    },
    muac: {
      male: { min: 12.5, max: 1000 },
      female: { min: 12.5, max: 1000 }
    }
  },
  // 10 to 13 years
  [AGE_CATEGORIES.PREADOLESCENT]: {
    age: { min: 3652, max: 4748 },
    t: { min: 36.5, max: 37.9 },
    wt: { min: 5, max: 95 },
    ht: { min: 5, max: 95 },
    hcm: { min: 5, max: 95 },
    hr: { min: 60, max: 125 },
    rr: { min: 12, max: 26 },
    bp: {
      systolic: { min: 102, max: 120 },
      diastolic: { min: 61, max: 80 }
    },
    o2: { min: 92, max: 100 },
    bmi: {
      male: { min: 5, max: 95 },
      female: { min: 5, max: 95 }
    }
  },
  // 13 to 16 years
  [AGE_CATEGORIES.ADOLESCENT_13_15]: {
    age: { min: 4748, max: 5844 },
    t: { min: 36.5, max: 37.9 },
    wt: { min: 5, max: 95 },
    ht: { min: 5, max: 95 },
    hcm: { min: 5, max: 95 },
    hr: { min: 60, max: 100 },
    rr: { min: 12, max: 20 },
    bp: {
      systolic: { min: 110, max: 131 },
      diastolic: { min: 64, max: 83 }
    },
    o2: { min: 92, max: 100 },
    bmi: {
      male: { min: 5, max: 95 },
      female: { min: 5, max: 95 }
    }
  },
  // 16 to 19 years
  [AGE_CATEGORIES.ADOLESCENT_16_19]: {
    age: { min: 5844, max: 6940 },
    t: { min: 36.5, max: 37.9 },
    wt: { min: 5, max: 95 },
    ht: { min: 5, max: 95 },
    hcm: { min: 5, max: 95 },
    hr: { min: 60, max: 100 },
    rr: { min: 12, max: 20 },
    bp: {
      systolic: { min: 96, max: 139 },
      diastolic: { min: 60, max: 89 }
    },
    o2: { min: 92, max: 100 },
    bmi: {
      male: { min: 5, max: 95 },
      female: { min: 5, max: 95 }
    },
    muac: {
      male: { min: 23.1, max: 1000 },
      female: { min: 22.2, max: 1000 }
    }
  },
  // 19 to 200 years
  [AGE_CATEGORIES.ADULT]: {
    age: { min: 6940, max: 73049 },
    t: { min: 36.5, max: 37.9 },
    hr: { min: 60, max: 100 },
    rr: { min: 12, max: 20 },
    bp: {
      systolic: { min: 96, max: 139 },
      diastolic: { min: 60, max: 89 }
    },
    o2: { min: 92, max: 100 },
    bmi: {
      male: { min: 18.5, max: 25 },
      female: { min: 18.5, max: 25 }
    },
    muac: {
      male: { min: 23.1, max: 1000 },
      female: { min: 22.2, max: 1000 }
    }
  }
})
