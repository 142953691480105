import { useQuery } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ComplaintLists, PaginatedVariables } from '../../api/interfaces'
import { GET_COMPLAINTS_LISTS } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  errorGetComplaintListsDefault: {
    id: 'AddComplaint.error_get_complaint_lists_default',
    defaultMessage: 'Something went wrong while getting complaint lists, try again later.',
  },
})

interface Data {
  complaintLists: ComplaintLists
}

interface ComplaintListContextValue {
  complaintLists: Nullable<ComplaintLists>
  isLoading: boolean
  error: Nullable<string>
  onErrorClose?: () => void
}

export const ComplaintListContext = React.createContext<Nullable<ComplaintListContextValue>>(null)
export const ComplaintListProvider: React.FC = (props) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (errorCode) => {
    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.errorGetComplaintListsDefault))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const onCompleted = () => {
    setError(null)
  }

  const { loading, data } = useQuery<Data, PaginatedVariables>(GET_COMPLAINTS_LISTS, {
    onCompleted,
    onError,
    fetchPolicy: 'cache-first',
  })

  const onErrorClose = () => {
    setError(null)
  }

  const value: ComplaintListContextValue = {
    complaintLists: data?.complaintLists ?? null,
    error,
    onErrorClose,
    isLoading: !!loading,
  }

  return (
    <ComplaintListContext.Provider value={value}>{props.children}</ComplaintListContext.Provider>
  )
}
