import { DeprecatedVisitType, VisitType } from '../../api/interfaces'

export const isValidVisitType = (value: string): boolean => {
  return (Object.values(VisitType) as Array<string>).includes(value)
}

export const isDeprecatedVisitType = (value: string): boolean => {
  return (Object.values(DeprecatedVisitType) as Array<string>).includes(value)
}

/**
 * form interface
 */

export interface StartVisitFormValues {
  backdated?: boolean
  date?: Date
  type?: VisitType
}
