import React from 'react'
import { IntlProvider } from 'react-intl'
import { useSession } from '../Auth'
import { useAuthTranslations, useUnauthTranslations } from './useTranslations'
import { Locales, isValidLocale } from './utils'
import { getAuthUri, getUnAuthUri } from '../../api/utils'
import browserDefaultLocale from 'locale2'
import { Settings } from 'luxon'
import { AccountStatus } from '../Auth/subscription'
/**
 *  How to use Static translations:
 *
 *  1. Define a messages object in your component
 *
 *  const messages = defineMessages({
 *    yourMessage: {
 *      id: '[PREFIX].message_Id',
 *      defaultMessage: 'The message written in English (en-US)'
 *   }, ...)
 *
 *  2. Import and use TranslationWrapper in your component
 *
 *  import { TranslationWrapper as T } from 'i18n'
 *  <T id={messages.yourMessage.id}>{formatMessage(messages.yourMessage)}</T>
 *
 *  Known shared Prefixes:
 *  UI: for common UI objects (such as: buttons, dropdowns, etc.)
 *  NonStaffUI: for UI elements accessible without login
 *
 *  How to use Dynamic Translations:
 *
 *  1. Import <DynamicTranslation /> and use it in your component
 *
 *  <DynamicTranslation text={text} />
 */

export const DEFAUL_LOCALE = Locales.EN_US

export const FALLBACK_LOCALE = isValidLocale(browserDefaultLocale)
  ? browserDefaultLocale.toLocaleLowerCase()
  : DEFAUL_LOCALE

export const TranslationProvider: React.FC = (props) => {
  const { isBooting, isLoggedIn, getAccountStatus } = useSession()

  if (isBooting()) {
    return null
  }

  if (isLoggedIn()) {
    const accountStatus = getAccountStatus()
    if (accountStatus === AccountStatus.READ_ONLY_ACCESS || accountStatus === AccountStatus.FULL_ACCESS) {
      return <AuthTranslationsProvider {...props} />
    }
  }

  return <UnauthTranslationsProvider {...props} />
}

const AuthTranslationsProvider: React.FC = props => {
  const { getUserLocale, getUserLocation, getToken, getUserSystem } = useSession()

  const userPreferredLocale = (getUserLocale() ?? '').toLowerCase()
  const userLocale = isValidLocale(userPreferredLocale)
    ? userPreferredLocale
    : FALLBACK_LOCALE

  const { id: userLocationId } = getUserLocation()
  const { id: userSystemId } = getUserSystem()

  const params = {
    locale: userLocale,
    baseURL: getAuthUri(),
    token: getToken(),
    userSystemId,
    userLocationId
  }

  const { translations } = useAuthTranslations(params)

  /**
   * Set local for luxon settings
   */
  React.useEffect(() => {
    Settings.defaultLocale = userLocale
  }, [userLocale])


  return <IntlProvider locale={userLocale} defaultLocale={DEFAUL_LOCALE} messages={translations}>
    {props.children}
  </IntlProvider>
}

const UnauthTranslationsProvider: React.FC = props => {
  const params = {
    locale: browserDefaultLocale,
    baseURL: getUnAuthUri()
  }

  const { translations } = useUnauthTranslations(params)

  return <IntlProvider locale={browserDefaultLocale} defaultLocale={DEFAUL_LOCALE} messages={translations}>
    {props.children}
  </IntlProvider>
}