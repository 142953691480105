import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  BoxProps,
  Button,
  HStack,
  Text,
  VStack,
  Divider,
  Heading,
  useDisclosure,
  useBreakpointValue,
  Stack,
  SkeletonText,
  Skeleton,
} from '@chakra-ui/react'
import { DateTime } from 'luxon'
import { FullUserSubscription } from '../../api/interfaces'
import { ErrorViewer } from '..'
import { CancelStaffSubscriptionButton, useStaffSubscription, SubscriptionStatus } from './'
import { SubscriptionStatus as SubscriptionStatusEnum } from '../../api/interfaces'
import { defineMessages, useIntl } from 'react-intl'
import { TrialIndicator } from '../Preferences'

const messages = defineMessages({
  title: {
    id: 'StaffAdmin.subscription_title',
    defaultMessage: 'Subscription',
  },
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close',
  },
  manageButton: {
    id: 'UI.button_details',
    defaultMessage: 'Details',
  },
  noSubscription: {
    id: 'StaffAdmin.user_does_not_have_an_active_subscription',
    defaultMessage: "User doesn't have an active subscription",
  },
  nextPaymentMessage: {
    id: 'SubscriptionSettings.next_payment_information',
    defaultMessage: 'Next payment: ${amount} on {date}',
  },
  subscriberSince: {
    id: 'SubscriptionSettings.subscriber_since',
    defaultMessage: 'Subscriber since {date}',
  },
  regular: {
    id: 'SubscriptionSettings.regular_plan_label',
    defaultMessage: 'Regular',
  },
  plan: {
    id: 'SubscriptionSettings.plan_label',
    defaultMessage: 'Plan',
  },
  payment: {
    id: 'SubscriptionSettings.payment_label',
    defaultMessage: 'Payment',
  },
})
interface SubscriptionBoxProps extends BoxProps {
  subscription: FullUserSubscription
  onCanceled: () => void
}

export const StaffSubscriptionBox: React.FC<SubscriptionBoxProps> = (props) => {
  const { subscription, onCanceled, ...boxProps } = props
  const intl = useIntl()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const endDate = DateTime.fromMillis(Number(subscription.currentPeriodEnd) * 1000)
  const date = endDate.isValid ? endDate.toLocaleString(DateTime.DATE_MED) : '-'

  return (
    <Box {...boxProps} borderRadius="md" borderWidth={1} p={3}>
      <HStack flex={1} justifyContent={'space-between'}>
        <VStack spacing={0} alignItems="start">
          <HStack spacing={2}>
            <Text fontSize={'lg'} fontWeight="bold">
              {subscription?.nickname ?? intl.formatMessage(messages.regular)}
            </Text>
            <SubscriptionStatus status={subscription.status} />
          </HStack>
          <Text fontSize={'sm'} color="gray">
            {intl.formatMessage(messages.nextPaymentMessage, {
              amount: subscription.price,
              currency: subscription.currency,
              date,
            })}
          </Text>
        </VStack>
        <Button onClick={onOpen} variant="ghost" colorScheme="brand">
          {intl.formatMessage(messages.manageButton)}
        </Button>
      </HStack>
      <SubscriptionModal
        subscription={subscription}
        onClose={onClose}
        isOpen={isOpen}
        onCanceled={onCanceled}
      />
    </Box>
  )
}

interface ModalProps extends SubscriptionBoxProps {
  onClose: () => void
  isOpen: boolean
}

const SubscriptionModal: React.FC<ModalProps> = (props) => {
  const { subscription, isOpen, onClose, onCanceled } = props

  const intl = useIntl()

  const createdDate = DateTime.fromMillis(Number(subscription.created) * 1000)
  const subscribedDate = createdDate.isValid ? createdDate.toLocaleString(DateTime.DATE_MED) : '-'

  const endDate = DateTime.fromMillis(Number(subscription.currentPeriodEnd) * 1000)
  const nextPaymentDate = endDate.isValid ? endDate.toLocaleString(DateTime.DATE_MED) : '-'

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {intl.formatMessage(messages.title)}
          <SubscriptionStatus status={subscription.status} ml={2} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          {subscription.status === SubscriptionStatusEnum.TRIALING && (
            <>
              <TrialIndicator
                trialStart={subscription.trialStart}
                trialEnd={subscription.trialEnd}
              />

              <Divider my={6} />
            </>
          )}
          <Box alignItems="flex-start" my={2}>
            <Heading size={'md'} fontWeight={'bold'}>
              {intl.formatMessage(messages.plan)}
            </Heading>

            <HStack alignItems="end" justifyContent={'space-between'}>
              <Text>{subscription?.nickname ?? intl.formatMessage(messages.regular)}</Text>
              <HStack alignItems="baseline" spacing={2}>
                <Heading size={'md'}>{`$${subscription.price}`}</Heading>
                <Text>{`/${subscription.interval}`}</Text>
              </HStack>
            </HStack>
          </Box>

          <Divider my={6} />

          <Box alignItems="flex-start" my={2}>
            <Heading size={'md'} fontWeight={'bold'}>
              {intl.formatMessage(messages.payment)}
            </Heading>
            <VStack alignItems="flex-start" spacing={0}>
              <Text>
                {intl.formatMessage(messages.nextPaymentMessage, {
                  amount: subscription.price,
                  date: nextPaymentDate,
                })}
              </Text>
              <Text color='gray' fontSize={'sm'}>{intl.formatMessage(messages.subscriberSince, { date: subscribedDate })}</Text>
            </VStack>
          </Box>
          <Divider mt={6} />
        </ModalBody>

        <ModalFooter>
          <Stack spacing={3} isInline={!isFullWidth} flex={1} justifyContent={'end'}>
            <Button onClick={onClose} isFullWidth={isFullWidth}>
              {intl.formatMessage(messages.closeButton)}
            </Button>
            <CancelStaffSubscriptionButton
              isFullWidth={isFullWidth}
              customerId={subscription.customerId}
              onCanceled={onCanceled}
            />
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface SubscriptionProps extends BoxProps {
  customerId: string
}

export const StaffSubscription: React.FC<SubscriptionProps> = (props) => {
  const { customerId, ...boxProps } = props
  const { isLoading, subscription, error, refetch } = useStaffSubscription({ customerId })
  const intl = useIntl()

  if (isLoading) {
    return (
      <HStack py={4} px={2} justifyContent="space-between">
        <SkeletonText noOfLines={2} spacing="4" w={200} />
        <Skeleton height="24px" borderRadius={'md'} w={100} />
      </HStack>
    )
  }

  if (error) {
    return <ErrorViewer title={error ?? ''} />
  }

  if (!subscription) {
    return (
      <Text textAlign={'center'} p={3}>
        {intl.formatMessage(messages.noSubscription)}
      </Text>
    )
  }

  return <StaffSubscriptionBox subscription={subscription} onCanceled={refetch} {...boxProps} />
}
