import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { useUpdatePaymentMethod } from '.'
import { Box, Button, Heading, useBreakpointValue } from '@chakra-ui/react'
import { Form } from 'react-final-form'
import { ErrorViewer } from '..'
import { PaymentTypeField } from '../Form/PaymentTypeField'

const messages = defineMessages({
  paymentTitle: {
    id: 'PaymentCard.payment_title',
    defaultMessage: 'Payment',
  },
  updateButton: {
    id: 'UI.button_update',
    defaultMessage: 'Update',
  },
  defaultPaymentMethod: {
    id: 'PaymentCard.default_payment_method',
    defaultMessage: 'Default payment method',
  },
})

interface PaymentCardProps {
  patientId: string
  defaultPaymentMethod?: string
}

export const PaymentCard: React.FC<PaymentCardProps> = (props) => {
  const intl = useIntl()

  const params = {
    id: props.patientId,
  }
  const { onErrorClose, onSubmit, error, isLoading, validate } = useUpdatePaymentMethod(params)

  const initialValues = {
    pricelist: props.defaultPaymentMethod,
  }

  const isFullWidth = useBreakpointValue({ base: true, md: false })
  return (
    <Box p={4} borderWidth="1px" my={4} rounded={'lg'}>
      <Heading size="md">{intl.formatMessage(messages.paymentTitle)}</Heading>

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        keepDirtyOnReinitialize
        render={({ handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <PaymentTypeField
              name="pricelist"
              label={intl.formatMessage(messages.defaultPaymentMethod)}
            />
            {error && <ErrorViewer title={error} onClose={onErrorClose} />}
            <Button
              isDisabled={pristine}
              isLoading={isLoading}
              colorScheme="brand"
              type="submit"
              isFullWidth={isFullWidth}
            >
              {intl.formatMessage(messages.updateButton)}
            </Button>
          </form>
        )}
      />
    </Box>
  )
}
