import React from 'react'
// import { defineMessages, useIntl } from 'react-intl'
import { ChecklistReferenceBody } from './ChecklistReference'
// import { hashChecklistItem } from 'wd-common/src/logic/checklists/utils'
import { FullChecklistEntry } from '../../api/interfaces'
import { Checkbox, CheckboxProps } from '@chakra-ui/react'
import { DynamicTranslation } from '../i18n'
// import { FullStudyOrder } from '../../api/interfaces/studies'
// import { StudyOrderModal } from '../Studies'

// const messages = defineMessages({
//   seeStudy: {
//     id: 'ChecklistStudy.text_see_study',
//     defaultMessage: '(see study in results tab)',
//   },
//   missingOrder: {
//     id: 'ChecklistStudy.missing_order',
//     defaultMessage: '(missing order)',
//   },
// })

// const checkOrders = (entry, studyOrders) => {
//   const entryHash = hashChecklistItem(entry)

//   const alreadyUsed = (studyOrders || []).some((order) => {
//     const ordered = hashChecklistItem(order.guideline || {})

//     return ordered && !order.canceled && entryHash && entryHash === ordered
//   })

//   return alreadyUsed
// }

/**
 * The modal must be shown after checking the item if there is no linked order.
 * It must be auto shown if the checklist is loaded and the item is already checked.
 * It should be closable without ordering a new order
 */

type ChecklistStudyProps = Omit<CheckboxProps, 'onChange' | 'value' | 'defaultValue'> & {
  entry: FullChecklistEntry
  onCheckChange: (checked: boolean) => void
  // patientId: string
  // visitId: string
  // studyOrders: Array<FullStudyOrder>
}

export const ChecklistStudy: React.FC<ChecklistStudyProps> = (props) => {
  const { entry, onCheckChange, ...checkboxProps } = props

  // const intl = useIntl()
  // const { isOpen, onClose, onOpen } = useDisclosure()

  // const alreadyUsed = checkOrders(entry, studyOrders)

  const _onCheckChange = (e) => {
    const wantToBeChecked = e.target.checked
    onCheckChange(wantToBeChecked)
    /**
     * DISABLED FOR NOW
     * 
     * if the item changes to checked, we need to wait for the order
     * before of setting it as checked
     */
    // if (wantToBeChecked) {
    //   // this means the order is already created.
    //   if (alreadyUsed) {
    //     onCheckChange(true)
    //   } else {
    //     onOpen()
    //   }
    // } else {
    //   onCheckChange(false)
    // }
  }

  // const modalCallback = () => {
  //   onCheckChange(true)
  // }

  // const showModal = !checkboxProps.isDisabled && isOpen && !alreadyUsed
  return (
    <>
      <Checkbox onChange={_onCheckChange} isChecked={!!entry.checked} my={1} {...checkboxProps}>
        {entry.name && <DynamicTranslation fontStyle={'italic'} message={entry.name} />}

        {entry.reference && <ChecklistReferenceBody reference={entry.reference} />}

        {/* {entry.checked && alreadyUsed && <Text>{intl.formatMessage(messages.seeStudy)}</Text>} */}
      </Checkbox>
      {/* {showModal && (
        <StudyOrderModal
          patientId={patientId}
          visitId={visitId}
          isOpen={isOpen}
          guideline={entry}
          onClose={onClose}
          onOrder={modalCallback}
        />
      )} */}
    </>
  )
}
