import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { GET_STAFF_SUBSCRIPTION } from '../../api/requests/staff'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { FullUserSubscription } from '../../api/interfaces'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  subscriptionNotFound: {
    id: 'UserSubscription.subscription_not_found',
    defaultMessage: 'Suscription not found. Please, contact with our staff',
  },
})

interface SubscriptionVariables {
  customerId: string
}

interface StaffSubscriptionQueryData {
  staffSubscription: FullUserSubscription
}

export type StaffSubscriptionParams = QueryHookOptions<
  StaffSubscriptionQueryData,
  SubscriptionVariables
> &
  SubscriptionVariables

export const useStaffSubscription = (params: StaffSubscriptionParams) => {
  const { customerId, ...rest } = params
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'subscription_not_found':
        return setError(intl.formatMessage(messages.subscriptionNotFound))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)

    translateError(firstError)
  }

  const { loading, data, refetch } = useQuery<StaffSubscriptionQueryData, SubscriptionVariables>(
    GET_STAFF_SUBSCRIPTION,
    {
      ...rest,
      skip: customerId === null,
      variables: {
        customerId: customerId ?? '',
      },
      onError,
    }
  )

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading: loading,
    subscription: data?.staffSubscription,
    error,
    refetch,
    onErrorClose,
  }
}
