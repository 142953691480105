import React from 'react'
import { Center, Spinner, SpinnerProps } from "@chakra-ui/react"

interface Props extends SpinnerProps {
    size?: 'sm' | 'md' | 'lg'
}

export const Loading: React.FC<Props> = props => {

    return <Center flex={1}>
        <Spinner color="brand.500" label='loading' {...props} />
    </Center>
}