import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { QualityCheckResult } from '../../QualityChecks'
import { FullChecklist } from '../../../api/interfaces'
import { Box, BoxProps, Heading, HStack } from '@chakra-ui/react'

const messages = defineMessages({
  titleQuality: {
    id: 'Checklist.title_quality',
    defaultMessage: 'Quality Checks',
  },
})

/**
 * Quality checks
 */
type QualityCheckProps = BoxProps & {
  checklist: FullChecklist
}

export const QualityChecks: React.FC<QualityCheckProps> = (props) => {
  const { checklist, ...boxProps } = props

  const intl = useIntl()

  if (!checklist?.quality_checks.length) {
    return null
  }

  return (
    <Box {...boxProps}>
      <Heading size={'md'} fontWeight={'bold'}>
        {intl.formatMessage(messages.titleQuality)}
      </Heading>

      <HStack justifyContent={'center'} spacing={3} py={2}>
        {checklist.quality_checks
          .filter((item) => item)
          .map((item, index) => {
            const key = `${item.name}-${index}`

            return <QualityCheckResult item={item} key={key} checklist={checklist} />
          })}
      </HStack>
    </Box>
  )
}
