import React from 'react'
import Select from 'react-select'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SelectorProps,
  useChakraThemeParser,
} from '.'
import { Nullable } from '../../utils'
import { LocaleInfo, SUPPORTED_LOCALES, Locales } from '../i18n'
import { getEmojiFlag } from 'countries-list'

type FullLocale = LocaleInfo & {
  id: Locales
}
export type LocaleSelectorProps = SelectorProps<Locales>
export const LocaleSelector: React.FC<LocaleSelectorProps> = props => {
  const {
    value,
  } = props


  const theme = useChakraThemeParser()

  const formatOption = (locale: FullLocale): ReactSelectInternalSingleValue => {
    return {
      value: locale.id,
      label: `${getEmojiFlag(locale.countryCode)} ${locale.language} (${locale.country})`
    }
  }

  const options = Object.entries(SUPPORTED_LOCALES)
    .map(([id, localeInfo]) => ({ ...localeInfo, id } as FullLocale))
    .map(formatOption)

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: Locales | Array<Locales>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return null
      }

      const formattedValues = value
        .reduce((prev: Array<ReactSelectInternalSingleValue>, value: string) => {
          return [
            ...prev,
            formatOption({
              ...SUPPORTED_LOCALES[value as string],
              id: value as Locales
            })
          ]
        }, [])

      return formattedValues
    } else {
      return formatOption({
        ...SUPPORTED_LOCALES[value as string],
        id: value as Locales
      })
    }
  }

  const _onChange = value => {
    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return props.onChange([])
      }

      const selectedValues = value
      const newValues = selectedValues.map(item => item.value)

      props.onChange(newValues as Array<Locales>)
    } else {
      const selectedValue = value

      props.onChange(selectedValue.value as Locales)
    }
  }

  return <Select
    options={options}
    value={parseValue(value)}
    onChange={_onChange}
    isLoading={props.isLoading}
    isMulti={props.isMulti}
    isClearable={props.isClearable}
    isDisabled={props.isDisabled}
    isSearchable={props.isSearchable}
    aria-invalid={props.isInvalid}
    placeholder={props.placeholder}
    blurInputOnSelect={props.blurInputOnSelect}
    theme={theme}
  />
}

