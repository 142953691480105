import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { ErrorViewer } from '..'
import { CountryField, TextField } from '../Form'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Button,
  ModalFooter,
  ButtonProps,
  useBreakpointValue,
  VStack,
  Heading,
  Text,
  HStack,
} from '@chakra-ui/react'
import { useUpdateClinicData } from '../Systems'
import { useSession } from '../Auth'
import { AccountStatus } from '../Auth/subscription'

const messages = defineMessages({
  name: {
    id: 'LocationViewer.label_name',
    defaultMessage: 'Clinic name',
  },
  address: {
    id: 'LocationViewer.label_address',
    defaultMessage: 'Address',
  },
  phone: {
    id: 'LocationViewer.label_phone_number',
    defaultMessage: 'Phone number',
  },
  update: {
    id: 'UI.button_update',
    defaultMessage: 'Update',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
  closeButton: {
    id: 'UI.button_close',
    defaultMessage: 'Close',
  },
  clinicInfo: {
    id: 'DomesticSetup.tab_label_location',
    defaultMessage: 'Clinic info',
  },
  clinicInfoDescrption: {
    id: 'UpdateClinicInfo.clinic_info_description',
    defaultMessage: 'Clinic name, address & phone number',
  },
})

interface UpdateClinicInfoModalProps {
  isOpen: boolean
  onClose: () => void
  initialFocus?: boolean
}

export const UpdateClinicInfoModal: React.FC<UpdateClinicInfoModalProps> = (props) => {
  const { isOpen, onClose } = props

  const intl = useIntl()

  const {
    isLoading,
    validate,
    onErrorClose,
    onSubmit,
    error,
    initialValues
  } = useUpdateClinicData({ onCompleted: onClose })

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ModalContent>
                <ModalHeader>{initialValues?.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <TextField name="name" label={intl.formatMessage(messages.name)} />
                  <TextField name="address" label={intl.formatMessage(messages.address)} />
                  <TextField name="phoneNumber" label={intl.formatMessage(messages.phone)} />
                  <CountryField name="country" />

                  {error && <ErrorViewer title={error} onClose={onErrorClose} />}
                </ModalBody>

                <ModalFooter>
                  <Button mr={3} onClick={props.onClose} isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.closeButton)}
                  </Button>
                  <Button
                    colorScheme={'brand'}
                    isLoading={isLoading}
                    type="submit"
                    isFullWidth={isFullWidth}
                  >
                    {intl.formatMessage(messages.saveButton)}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export const UpdateClinicInfo: React.FC<Omit<ButtonProps, 'onClick'>> = (props) => {
  const { ...buttonProps } = props

  const intl = useIntl()
  const { getAccountStatus } = useSession()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const onClick = () => {
    onOpen()
  }

  const accountStatus = getAccountStatus()
  const isDisabled =
    props.isDisabled ||
    accountStatus === AccountStatus.PAYMENT_REQUIRED ||
    accountStatus === AccountStatus.SETUP_PENDING ||
    accountStatus === AccountStatus.REACTIVATION_REQUIRED
  return (
    <>
      <HStack justifyContent={'space-between'} p={2}>
        <VStack alignItems={'start'} justifyContent={'start'} spacing={0}>
          <Heading size="sm">{intl.formatMessage(messages.clinicInfo)}</Heading>
          <Text as={'span'} fontSize="sm">
            {intl.formatMessage(messages.clinicInfoDescrption)}
          </Text>
        </VStack>

        <Button
          colorScheme={'brand'}
          variant="ghost"
          onClick={onClick}
          {...buttonProps}
          isDisabled={isDisabled}
        >
          {intl.formatMessage(messages.update)}
        </Button>
      </HStack>
      {isOpen && <UpdateClinicInfoModal isOpen={isOpen} onClose={onClose} />}
    </>
  )
}
