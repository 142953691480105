import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  ModalFooter,
  useBreakpointValue,
  Center,
} from '@chakra-ui/react'
import { BillableCategory, FullBillingItem } from '../../api/interfaces/billing'
import { ErrorViewer } from '..'
import { BillingItemFormValues, useUpdateBillingItem, useCreateBillingItem } from '.'
import { BillableCategoryField, TextField } from '../Form'
import { PaymentTypeField } from '../Form/PaymentTypeField'
import createDecorator from 'final-form-calculate'
import usePrices from '../Pricing/usePrices'
import { Loading } from '../Loading'

const messages = defineMessages({
  title: {
    id: 'BillingItemEditor.title',
    defaultMessage: 'Billing item',
  },
  buttonRemove: {
    id: 'button_remove',
    defaultMessage: 'Remove',
  },
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?',
  },
  category: {
    id: 'BillingEditor.column_name_category',
    defaultMessage: 'Category',
  },
  name: {
    id: 'BillingItemEditor.label_name',
    defaultMessage: 'Name',
  },
  unitPrice: {
    id: 'BillingItemEditor.label_unit_price',
    defaultMessage: 'Unit Price',
  },
  quantity: {
    id: 'BillingItemEditor.label_quantity',
    defaultMessage: 'Quantity',
  },
  amount: {
    id: 'BillingItemEditor.label_amount',
    defaultMessage: 'Amount',
  },
  notes: {
    id: 'BillingItemEditor.label_notes',
    defaultMessage: 'Notes',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
  updateButton: {
    id: 'UI.button_update',
    defaultMessage: 'Update',
  },
  addButton: {
    id: 'UI.button_add',
    defaultMessage: 'Add',
  },
  paymentMethod: {
    id: 'PricelistSelector.payment_method',
    defaultMessage: 'Payment method',
  },
})

interface AddBillItemModalProps {
  billId: string
  isOpen: boolean
  onClose: () => void
  initialFocus?: boolean
}

export const AddBillItemModal: React.FC<AddBillItemModalProps> = (props) => {
  const { billId, isOpen, onClose } = props

  const intl = useIntl()

  const params = {
    billId,
    onCompleted: () => {
      onClose()
    },
  }

  const { onSubmit, isLoading, validate, error, onErrorClose } = useCreateBillingItem(params)

  const initialValues: BillingItemFormValues = {
    name: '',
    billingCode: '',
    category: BillableCategory.OTHERS,
    quantity: 1,
    amount: 0,
    unitPrice: 0,
    note: '',
    userAdded: true,
    pricelistId: '',
  }

  const decorator = React.useMemo(() => {
    return createDecorator(
      {
        field: 'unitPrice',
        updates: {
          amount: (prev, allValues) => {
            const { quantity, unitPrice } = allValues as BillingItemFormValues
            const newTotal = calculatePrice(quantity, unitPrice)

            return isNaN(newTotal) ? null : newTotal
          },
        },
      },
      {
        field: 'quantity',
        updates: {
          amount: (prev, allValues) => {
            const { quantity, unitPrice } = allValues as BillingItemFormValues
            const newTotal = calculatePrice(quantity, unitPrice)

            return isNaN(newTotal) ? null : newTotal
          },
        },
      }
    )
  }, [])

  const isFullWidth = useBreakpointValue({ base: true, sm: false })

  return (
    <Modal isOpen={isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        decorators={[decorator]}
        keepDirtyOnReinitialize
        render={({ handleSubmit, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ModalContent>
                <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {<>

                    <BillableCategoryField
                      name="category"
                      label={intl.formatMessage(messages.category)}
                    />
                    <PaymentTypeField
                      name="pricelistId"
                      label={intl.formatMessage(messages.paymentMethod)}
                      isClearable
                    />
                    <TextField name="name" label={intl.formatMessage(messages.name)} />
                    <TextField name="quantity" label={intl.formatMessage(messages.quantity)} type={'number'} step={0.1} />
                    <TextField
                      name="unitPrice"
                      label={intl.formatMessage(messages.unitPrice)}
                      type={'number'}
                      step={0.01}
                    />
                    <TextField name="amount" label={intl.formatMessage(messages.amount)} type={'number'} step={0.01} />
                    <TextField name="note" label={intl.formatMessage(messages.notes)} />
                    {error && <ErrorViewer title={error} onClose={onErrorClose} />}
                  </>
                  }
                </ModalBody>

                <ModalFooter>
                  <Button mr={3} onClick={onClose} isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.cancelButton)}
                  </Button>
                  <Button isDisabled={pristine} colorScheme={'brand'} isLoading={isLoading} type="submit" isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.addButton)}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )
        }}
      />
    </Modal>
  )
}

interface UpdateBillItemModalProps extends AddBillItemModalProps {
  item: FullBillingItem
}

export const UpdateBillItemModal: React.FC<UpdateBillItemModalProps> = (props) => {
  const { billId, item, isOpen, onClose } = props

  const intl = useIntl()

  const { id, category, billingCode } = item

  const { getPrice, isLoading: isLoadingPrices, refetch } = usePrices({ category, billingCode })

  const params = {
    billId,
    itemId: item.id,
    onCompleted: () => {
      refetch()
      onClose()
    },
  }

  const { onSubmit, isLoading, validate, error, onErrorClose } = useUpdateBillingItem(params)

  const initialValues: BillingItemFormValues = {
    name: item.name,
    billingCode: billingCode ?? '',
    category: category,
    quantity: item.quantity,
    amount: item.amount ?? 0,
    unitPrice: item.unitPrice ?? 0,
    note: item.note ?? '',
    userAdded: item.userAdded ?? true,
    pricelistId: item.pricelistId ?? '',
  }

  const decorator = React.useMemo(() => {
    return createDecorator(
      {
        field: 'pricelistId',
        updates: {
          unitPrice: (prev, allValues) => {
            const { pricelistId, category, billingCode, unitPrice } = allValues as BillingItemFormValues
            const price = getPrice(category, pricelistId, billingCode)

            return price?.amount ?? unitPrice ?? null
          },
        },
      },
      {
        field: 'unitPrice',
        updates: {
          amount: (prev, allValues) => {
            const { quantity, unitPrice } = allValues as BillingItemFormValues
            const newTotal = calculatePrice(quantity, unitPrice)

            return isNaN(newTotal) ? null : newTotal
          },
        },
      },
      {
        field: 'quantity',
        updates: {
          amount: (prev, allValues) => {
            const { quantity, unitPrice } = allValues as BillingItemFormValues
            const newTotal = calculatePrice(quantity, unitPrice)

            return isNaN(newTotal) ? null : newTotal
          },
        },
      }
    )
  }, [getPrice])

  const isFullWidth = useBreakpointValue({ base: true, sm: false })

  return (
    <Modal isOpen={isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        decorators={[decorator]}
        keepDirtyOnReinitialize
        render={({ handleSubmit, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ModalContent>
                <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {
                    isLoadingPrices
                      ? <Center>
                        <Loading />
                      </Center>
                      : <>

                        <BillableCategoryField
                          name="category"
                          label={intl.formatMessage(messages.category)}
                          isDisabled={!!id}
                        />
                        <PaymentTypeField
                          name="pricelistId"
                          label={intl.formatMessage(messages.paymentMethod)}
                          isClearable
                        />
                        <TextField name="name" label={intl.formatMessage(messages.name)} />
                        <TextField name="quantity" label={intl.formatMessage(messages.quantity)} type={'number'} step={0.1} />
                        <TextField
                          name="unitPrice"
                          label={intl.formatMessage(messages.unitPrice)}
                          type={'number'}
                          step={0.01}
                        />
                        <TextField name="amount" label={intl.formatMessage(messages.amount)} type={'number'} step={0.01} />
                        <TextField name="note" label={intl.formatMessage(messages.notes)} />
                        {error && <ErrorViewer title={error} onClose={onErrorClose} />}
                      </>
                  }
                </ModalBody>

                <ModalFooter>
                  <Button mr={3} onClick={onClose} isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.cancelButton)}
                  </Button>
                  <Button isDisabled={pristine} colorScheme={'brand'} isLoading={isLoading} type="submit" isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.updateButton)}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )
        }}
      />
    </Modal>
  )
}

const calculatePrice = (quantity, unitPrice) => {
  return Math.round(parseFloat(quantity) * (parseFloat(unitPrice) * 100)) / 100
}