export const EXAM_TYPES = Object.freeze({
  DENTAL: 'Dental',
  GENERAL: 'General',
  INFANT: 'Infant',
  MIDWIFE: 'Midwife',
  OBSTETRIC: 'OBGYN',
  LACTATION: 'Lactation'
})

/**
 * dental exam
 */
export const ToothPosition = Object.freeze({
  BUCCAL: 'BUCCAL',
  OCCLUSAL: 'OCCLUSAL',
  DISTAL: 'DISTAL',
  MESIAL: 'MESIAL',
  LINGUAL: 'LINGUAL',
  ENTIRE: 'ENTIRE'
})

export const ToothAbsolutePosition = Object.freeze({
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  CENTER: 'CENTER',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
})

export const ToothStatus = Object.freeze({
  PAIN: 'pain',
  MOBILE: 'mobile',
  CARIES: 'caries',
  MISSING: 'missing',
  FRACTURE: 'fracture',
  CROWN: 'crown',
  CROWN_ABNORMAL: 'crown abnormal',
  FILLING: 'filling',
  FILLING_ABNORMAL: 'filling abnormal',
  IMPLANT: 'implant',
  IMPLANT_ABNORMAL: 'implant abnormal',
  DENTURE: 'denture',
  DENTURE_ABNORMAL: 'denture abnormal',
  FIXED_BRIDGE: 'fixed bridge',
  BRIDGE_ABNORMAL: 'bridge abnormal',
  OTHER: 'other'
})

export const StatusColor = Object.freeze({
  PAIN: '#ff0001',
  MOBILE: '#548135',
  CARIES: '#c35b11',
  MISSING: '#0d0d0d',
  FRACTURE: '#1f4e79',
  CROWN: '#7031a0',
  CROWN_ABNORMAL: '#7031a0',
  FILLING: '#c5e0b4',
  FILLING_ABNORMAL: '#c5e0b4',
  IMPLANT: '#f8cbad',
  IMPLANT_ABNORMAL: '#f8cbad',
  DENTURE: '#ffe699',
  DENTURE_ABNORMAL: '#ffe699',
  FIXED_BRIDGE: '#ffccd4',
  BRIDGE_ABNORMAL: '#ffccd4',
  OTHER: '#ff816b'
})

export const ToothProcedure = Object.freeze({
  FILLING: 'procedure - filling',
  CROWN: 'procedure - crown',
  EXTRACTION: 'procedure - extraction',
  ROOT_CANAL: 'procedure - root canal',
  SPLINTING: 'procedure - splinting',
  DENTURES: 'procedure - dentures',
  BRIDGE: 'procedure - bridge',
  IMPLANT: 'procedure - implant'
})

export const ProcedureColor = Object.freeze({
  FILLING: '#c5e0b4',
  CROWN: '#7031a0',
  EXTRACTION: '#0d0d0d',
  ROOT_CANAL: '#bdd7ee',
  SPLINTING: '#bfbfbf',
  DENTURES: '#ffe699',
  BRIDGE: '#ffccd4',
  IMPLANT: '#f8cbad'
})
