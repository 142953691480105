import { useColorMode, useTheme } from "@chakra-ui/react"
import { Theme } from 'react-select'


/**
 * Parse react select theme into a chakra based theme
 * @returns {() => Theme} parser
 */
export const useChakraThemeParser = () => {
  const { colorMode } = useColorMode()
  const { colors } = useTheme()

  const newColors = colorMode === 'light'
    ? {
      primary: colors.brand['500'],
      primary25: colors.brand['300'],
      primary50: colors.brand['200'],
      primary75: colors.brand['100'],
      danger: colors.red["500"],
      dangerLight: colors.red["200"],
      neutral0: colors.white,
      neutral5: colors.gray['50'],
      neutral10: colors.gray['100'],
      neutral20: colors.gray['200'],
      neutral30: colors.gray['300'],
      neutral40: colors.gray['400'],
      neutral50: colors.gray['500'],
      neutral60: colors.gray['600'],
      neutral70: colors.gray['700'],
      neutral80: colors.gray['800'],
      neutral90: colors.gray['900'],
    }
    : {
      primary: colors.brand['600'],
      primary25: colors.brand['500'],
      primary50: colors.brand['400'],
      primary75: colors.brand['300'],
      danger: colors.red["500"],
      dangerLight: colors.red["200"],
      neutral0: colors.gray['700'],
      neutral5: colors.gray['600'],
      neutral10: colors.gray['600'],
      neutral20: colors.gray['500'],
      neutral30: colors.gray['400'],
      neutral40: colors.gray['400'],
      neutral50: colors.gray['300'],
      neutral60: colors.gray['200'],
      neutral70: colors.gray['100'],
      neutral80: colors.white,
      neutral90: colors.white,
    }

  const parser = (theme: Theme) => {
    return {
      ...theme,
      colors: {
        ...newColors,
      }
    }
  }

  return parser
}