import { defineMessages, useIntl } from 'react-intl'
import { assertUnreachable } from '../utils'
import { useSession } from './Auth'

const messages = defineMessages({
  kg: {
    id: 'UI.unit_kg',
    defaultMessage: 'kg',
  },
  pound: {
    id: 'UI.unit_pound',
    defaultMessage: 'lbs',
  },
  gram: {
    id: 'UI.unit_g',
    defaultMessage: 'g',
  },
  ounce: {
    id: 'UI.unit_ounce',
    defaultMessage: 'oz',
  },
  celsius: {
    id: 'UI.unit_celsius',
    defaultMessage: 'ºC',
  },
  fahrenheit: {
    id: 'UI.unit_fahrenheit',
    defaultMessage: 'ºF',
  },
  cm: {
    id: 'UI.unit_cm',
    defaultMessage: 'cm',
  },
  inch: {
    id: 'UI.unit_inch',
    defaultMessage: 'in',
  },
})

export enum UnitSystems {
  metric = 'metric',
  imperial = 'imperial',
}

export enum MetricUnits {
  KG = 'kg',
  CM = 'cm',
  CELSIUS = 'celsius',
  G = 'gram',
}

export enum ImperialUnits {
  INCH = 'inch',
  POUND = 'pound',
  F = 'fahrenheit',
  OUNCE = 'ounce',
}

export enum UnitTypes {
  LENGTH = 'length',
  TEMPERATURE = 'temperature',
  WEIGHT = 'weight',
}

export const cmToInch = (cm: number) => cm / 2.54

export const inchToCm = (inches: number) => inches * 2.54

export const kgToPounds = (kg: number) => kg * 2.20462262185

export const gToOz = (g: number) => g * 0.03527396195

export const ozToG = (oz: number) => oz / 0.03527396195

export const poundsToKg = (pounds: number) => pounds / 2.20462262185

export const celsiusToFahrenheit = (temp: number) => (temp * 9) / 5 + 32

export const fahrenheitToCelsius = (temp: number) => ((temp - 32) * 5) / 9

const betterRound = (number: number, precision = 10) => Math.round((number + Number.EPSILON) * precision) / precision

export const useUnitConversion = () => {
  const intl = useIntl()
  const { getUserUnitSystem } = useSession()
  const unitSystem = getUserUnitSystem() || UnitSystems.metric

  return {
    unitSystem,
    formatNumber: (value: number, unitType: UnitTypes) => {
      switch (unitType) {
        case UnitTypes.WEIGHT: {
          if (unitSystem === UnitSystems.metric) return `${intl.formatNumber(value)}${intl.formatMessage(messages.kg)}`

          const decimals = value % 1

          if (!decimals) return `${intl.formatNumber(value)}${intl.formatMessage(messages.pound)}`

          return `${intl.formatNumber(Math.trunc(value))}${intl.formatMessage(messages.pound)}, ${intl.formatNumber(
            betterRound(decimals * 16)
          )}${intl.formatMessage(messages.ounce)}`
        }
        case UnitTypes.LENGTH: {
          if (unitSystem === UnitSystems.metric) return `${intl.formatNumber(value)}${intl.formatMessage(messages.cm)}`

          return
        }
        case UnitTypes.TEMPERATURE: {
          if (unitSystem === UnitSystems.metric)
            return `${intl.formatNumber(value)}${intl.formatMessage(messages.celsius)}`

          return
        }
        default:
          assertUnreachable('value never reached', unitType)
      }
    },
    getUnitTranslation: (unit: MetricUnits) => {
      if (unitSystem === UnitSystems.imperial) {
        switch (unit) {
          case MetricUnits.KG:
            return intl.formatMessage(messages.pound)
          case MetricUnits.G:
            return intl.formatMessage(messages.ounce)
          case MetricUnits.CELSIUS:
            return intl.formatMessage(messages.fahrenheit)
          case MetricUnits.CM:
            return intl.formatMessage(messages.inch)
          default:
            assertUnreachable('value not reached', unit)
        }
      } else {
        return intl.formatMessage(messages[unit])
      }
    },
    getDifference: (valueA: number, valueB: number, unitType: UnitTypes) => {
      switch (unitType) {
        case UnitTypes.WEIGHT: {
          let diff = valueA - valueB
          let unit =
            unitSystem === UnitSystems.imperial ? intl.formatMessage(messages.pound) : intl.formatMessage(messages.kg)

          let formatted = `${intl.formatNumber(diff)}${unit}`

          if (Math.abs(diff) < 1) {
            diff = diff * 1000
            unit = intl.formatMessage(messages.gram)

            if (unitSystem === UnitSystems.imperial) {
              diff = betterRound(gToOz(diff))
              unit = intl.formatMessage(messages.ounce)

              if (diff % 16 === 0) {
                diff = diff / 16
                unit = intl.formatMessage(messages.pound)
              }
            } else {
              diff = Math.round(diff)
            }

            formatted = `${intl.formatNumber(diff)}${unit}`
          } else {
            if (unitSystem === UnitSystems.imperial) {
              diff = betterRound(kgToPounds(diff), 100)
              const decimals = Math.abs(diff % 1)

              if (decimals) {
                formatted = `${intl.formatNumber(Math.trunc(diff))}${intl.formatMessage(
                  messages.pound
                )}, ${intl.formatNumber(decimals * 16)}${intl.formatMessage(messages.ounce)}`
              } else {
                formatted = `${intl.formatNumber(diff)}${unit}`
              }
            }
          }

          return { diff, unit, formatted }
        }
        case UnitTypes.LENGTH:
          break
        case UnitTypes.TEMPERATURE:
          break
        default:
          assertUnreachable('value never reached', unitType)
      }

      return {
        diff: 0,
        unit: '',
      }
    },
    convertUnitFromDb: (value: number, unitType: UnitTypes) => {
      if (unitSystem === UnitSystems.metric) return value

      switch (unitType) {
        case UnitTypes.WEIGHT:
          return betterRound(kgToPounds(value), 100)
        case UnitTypes.TEMPERATURE:
          return celsiusToFahrenheit(value)
        case UnitTypes.LENGTH:
          return cmToInch(value)
        default:
          assertUnreachable('value never reached', unitType)
      }
    },
    convertUnitFromInput: (value: number, unitType: UnitTypes) => {
      if (unitSystem === UnitSystems.metric) return value

      switch (unitType) {
        case UnitTypes.WEIGHT:
          return poundsToKg(value)
        case UnitTypes.TEMPERATURE:
          return fahrenheitToCelsius(value)
        case UnitTypes.LENGTH:
          return inchToCm(value)
        default:
          assertUnreachable('value never reached', unitType)
      }
    },
  }
}
