import React from 'react'
import { FullExamGroup, TranslatableText } from '../../api/interfaces'
import { Box, BoxProps, Button } from '@chakra-ui/react'
import { DynamicTranslation } from '../i18n'
import { ErrorViewer } from '..'
import { useUpdateGeneralReviewOfSystem } from './useGeneralReviewOfSystemsActions'
import { ClearingCodesWithText } from '../../utils'

type ExamGroupProps = BoxProps & {
  patientId: string
  visitId: string
  sectionKey: number
  group: FullExamGroup
  isDisabled?: boolean
  clearingCodes: ClearingCodesWithText
}

export const GeneralReviewOfSystemGroup: React.FC<ExamGroupProps> = (props) => {
  const { patientId, visitId, sectionKey, group, isDisabled, clearingCodes, ...boxProps } = props

  const { choices, key: groupKey, physical_exam_codes } = group

  const params = {
    patientId,
    visitId,
    sectionKey,
    groupKey,
  }

  const { onSubmit, isLoading, error, onErrorClose } = useUpdateGeneralReviewOfSystem(params)

  const pertinentsFromChecklist = (physical_exam_codes ?? [])
    .filter((code) => clearingCodes.has(code))
    .map((code) => clearingCodes.get(code) as Array<TranslatableText>)

  return (
    <Box borderWidth={1} borderRadius={'md'} p={2} my={1} {...boxProps}>
      <Box display={'flex'} flexWrap={'wrap'}>
        {pertinentsFromChecklist.length === 0 &&
          choices.map((choice) => {
            const { name, selected, key } = choice

            return (
              <Button
                size={'sm'}
                key={key}
                colorScheme={selected ? 'brand' : undefined}
                isDisabled={isDisabled}
                isLoading={isLoading}
                onClick={() => onSubmit(key, !selected)}
                my={1}
                mr={2}
              >
                <DynamicTranslation message={name} />
              </Button>
            )
          })}
        {pertinentsFromChecklist?.map((list, index) =>
          list.map((p) => (
            <Button
              isDisabled
              size="sm"
              key={`${p.text}-${index}`}
              my={1}
              mr={2}
              colorScheme="red"
              sx={{ opacity: '1!important', backgroundColor: 'red' }}
            >
              <DynamicTranslation message={p} />
            </Button>
          ))
        )}
      </Box>
      {error && <ErrorViewer title={error} onClose={onErrorClose} />}
    </Box>
  )
}
