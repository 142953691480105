import React, { useMemo } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ErrorViewer, PrintButton, Loading } from '../../components'
import { VisitResume } from '../../components/Visit'
import { defineMessages, useIntl } from 'react-intl'
import { ActiveVisit, FullVisit } from '../../api/interfaces'
import { UsePastVisitByIdParams, usePastVisitByID } from '.'

const messages = defineMessages({
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close',
  },
})

interface PastVisitModalFromObjectProps {
  visit: FullVisit
  onClose: () => void
}

export const PastVisitModalFromObject: React.FC<PastVisitModalFromObjectProps> = (props) => {
  const { visit, onClose } = props

  const intl = useIntl()
  const resumeRef = React.createRef<HTMLDivElement>()

  const { patient, ...rest } = visit

  const filename = useMemo(
    () =>
      `${patient ? patient.first_name + ' ' + patient.last_name : ''}-${new Date(
        (rest as ActiveVisit).start
      ).toLocaleDateString()}.pdf`,
    [patient, rest]
  )

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={true} onClose={onClose} size={'4xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pt={8}>
          <VisitResume visit={rest as ActiveVisit} patient={patient} ref={resumeRef} />
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose} isFullWidth={isFullWidth}>
            {intl.formatMessage(messages.closeButton)}
          </Button>
          <PrintButton
            isFullWidth={isFullWidth}
            componentToPrint={resumeRef}
            colorScheme={'brand'}
            filename={filename}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface PastVisitModalFromIDProps {
  visitId: string
  onClose: () => void
}

export const PastVisitModalFromId: React.FC<PastVisitModalFromIDProps> = (props) => {
  const { visitId, onClose } = props

  const intl = useIntl()
  const resumeRef = React.createRef<HTMLDivElement>()
  const isFullWidth = useBreakpointValue({ base: true, sm: false })

  const params: UsePastVisitByIdParams = {
    id: visitId,
  }
  const { visit, isLoading, error, onErrorClose } = usePastVisitByID(params)
  const { patient, ...rest } = visit ?? {}

  const filename = `${patient ? patient.first_name + ' ' + patient.last_name : ''}-${new Date(
    (rest as ActiveVisit).start
  ).toLocaleDateString()}.pdf`

  return (
    <Modal isOpen={true} onClose={onClose} size={'4xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pt={8}>
          {isLoading ? (
            <Loading size="lg" />
          ) : patient && rest ? (
            <VisitResume visit={rest as ActiveVisit} patient={patient} ref={resumeRef} />
          ) : null}
          {error && <ErrorViewer title={error} onClose={onErrorClose} />}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose} isFullWidth={isFullWidth}>
            {intl.formatMessage(messages.closeButton)}
          </Button>
          <PrintButton
            isFullWidth={isFullWidth}
            componentToPrint={resumeRef}
            colorScheme={'brand'}
            filename={filename}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
