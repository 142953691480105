import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSession, VerifyMFATokenParams } from '../Auth'
import { VStack, HStack, Text, Collapse } from '@chakra-ui/react'
import { ErrorViewer, Loading } from '..'
import { Form } from 'react-final-form'
import { TextField, OTPField, ValidateFn, ValidationError } from '../Form'
import { Nullable } from '../../utils'
import { MFASecret } from '.'

const messages = defineMessages({
  authenticationCode: {
    id: 'MFAConfiguration.authentication_code',
    defaultMessage: 'Authentication Code'
  },
  codeMismatch: {
    id: 'MFAConfiguration.code_mismatch',
    defaultMessage: 'Code mismatch and fail enable Software Token MFA'
  },
  deviceName: {
    id: 'MFAConfiguration.device_name',
    defaultMessage: 'Device Name'
  },
  authenticationCodeDescription: {
    id: 'MFAConfiguration.authentication_code_description',
    defaultMessage: 'After configuring the app, enter a one-time password from your app in the below input.'
  },
  requiredError: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required'
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface AssociateDeviceProps {
  secret: Nullable<string>
  onVerify: () => void
  onCancel: () => void
}


export const MFATokenAssociation: React.FC<AssociateDeviceProps> = props => {
  const { secret } = props

  return <Collapse startingHeight={0} in={!!secret} >
    {
      secret &&
      <VStack mt={2} borderRadius='md' borderWidth='1px' p={3}>
        <MFASecret secret={secret} />
        <TokenVerification onVerify={props.onVerify} />
      </VStack>
    }
  </Collapse >
}


export const useTokenVerification = (params: TokenVerificationProps) => {
  const intl = useIntl()

  const { verifyMFAToken, getUserFullName } = useSession()

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (err) => {
    const { code } = err

    switch (code) {
      case 'EnableSoftwareTokenMFAException':
        setError(intl.formatMessage(messages.codeMismatch))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const validate: ValidateFn<VerifyMFATokenParams> = (values) => {
    const errors: ValidationError<VerifyMFATokenParams> = {}

    if (!values.friendlyDeviceName) errors.friendlyDeviceName = intl.formatMessage(messages.requiredError)
    if (!values.totpCode) errors.totpCode = intl.formatMessage(messages.requiredError)

    return errors
  }

  const onSubmit = async (values: VerifyMFATokenParams) => {
    setIsLoading(true)
    try {
      const result = await verifyMFAToken(values) as any

      if (result?.Status === 'SUCCESS') {
        params.onVerify()
      }

    } catch (error: any) {
      translateError(error)
      setIsLoading(false)
    }
  }

  const fullName = getUserFullName()
  const initialValues = {
    friendlyDeviceName: `${fullName}'s device`
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    initialValues
  }
}

interface TokenVerificationProps {
  onVerify: () => void
}

const TokenVerification: React.FC<TokenVerificationProps> = props => {
  const intl = useIntl()
  const {
    onSubmit,
    validate,
    initialValues,
    error,
    isLoading,
  } = useTokenVerification({
    onVerify: props.onVerify
  })


  return <div className='d-flex justify-content-center flex-column px-2'>
    <HStack>

      <Text>
        {intl.formatMessage(messages.authenticationCodeDescription)}
      </Text>
    </HStack>
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit }) => {
        if (isLoading) {
          return <Loading size='sm' />
        }

        return (
          <form onSubmit={handleSubmit}>
            <TextField
              name="friendlyDeviceName"
              label={intl.formatMessage(messages.deviceName)}
              maxLength={50}
            />

            <OTPField
              name="totpCode"
              length={6}
              autoSubmit
            />

            {
              error &&
              <ErrorViewer title={error} />
            }
          </form>
        )
      }}
    />
  </div>
}

