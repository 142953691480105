import React from 'react'
import { Nullable } from '../../utils'

interface AuthParams {
  locale: string
  baseURL: string
  token: string
  userSystemId: string
  userLocationId: string
}

interface UnauthParams {
  locale: string
  baseURL: string
}

interface ReturnedValue {
  isLoading: boolean
  error: Nullable<string>
  translations: Record<string, string>
}

export const useAuthTranslations = (params: AuthParams): ReturnedValue => {
  const [error, setError] = React.useState<Nullable<string>>(null)
  const [translations, setTranslations] = React.useState<Record<string, string>>({})

  const url = `${params.baseURL}/translations-v2/${params.locale}/bundle`
  const headers = React.useMemo(
    () => ({
      Accept: 'application/json',
      Authorization: 'Bearer ' + params.token,
      'Content-Type': 'application/json',
      'WD-System': params.userSystemId,
      'WD-Location': params.userLocationId,
    }),
    [params.token, params.userSystemId, params.userLocationId]
  )

  const lastRequest = React.useRef({
    isLoading: false,
  })

  React.useEffect(() => {
    const getTranslations = async () => {
      if (lastRequest.current.isLoading) return

      lastRequest.current = {
        isLoading: true,
      }

      try {
        const response: Response = await fetch(url, { headers })
        if (response.ok) {
          const result = await response.json()

          lastRequest.current = {
            isLoading: false,
          }

          setTranslations(result)
        } else {
          throw new Error(response.statusText)
        }
      } catch (err) {
        lastRequest.current = {
          isLoading: false,
        }

        setError("We couldn't get the translations.")
      }
    }
    getTranslations()
  }, [headers, url])

  return {
    isLoading: lastRequest.current && lastRequest.current.isLoading,
    translations,
    error,
  }
}

export const useUnauthTranslations = (params: UnauthParams): ReturnedValue => {
  const [error, setError] = React.useState<Nullable<string>>(null)
  const [translations, setTranslations] = React.useState<Record<string, string>>({})

  const url = `${params.baseURL}/translations-v2/${params.locale}/bundle`

  const lastRequest = React.useRef({
    isLoading: false,
  })

  React.useEffect(() => {
    const getTranslations = async () => {
      if (lastRequest.current.isLoading) return

      lastRequest.current = {
        isLoading: true,
      }

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }

      try {
        const response: Response = await fetch(url, { headers })
        if (response.ok) {
          const result = await response.json()

          lastRequest.current = {
            isLoading: false,
          }

          setTranslations(result)
        } else {
          throw new Error(response.statusText)
        }
      } catch (err) {
        lastRequest.current = {
          isLoading: false,
        }

        setError("We couldn't get the translations.")
      }
    }
    getTranslations()
  }, [url])

  return {
    isLoading: lastRequest.current && lastRequest.current.isLoading,
    translations,
    error,
  }
}
