import React from 'react'
import { useNavigate } from 'react-router'
import { Button, ButtonProps, MenuItemProps, MenuItem } from '@chakra-ui/react'

type Props = ButtonProps & {
  label: React.ReactNode
  to: string
}

export const RedirectButton: React.FC<Props> = (props) => {
  const { label, to, ...buttonProps } = props

  const navigate = useNavigate()

  const onClick = () => {
    navigate(to)
  }

  return <Button onClick={onClick} variant={'ghost'} {...buttonProps}>
    {label}
  </Button>
}


type MenuProps = MenuItemProps & {
  label: React.ReactNode
  to: string
}

export const RedirectMenuItem: React.FC<MenuProps> = (props) => {
  const { label, to, ...buttonProps } = props

  const navigate = useNavigate()

  const onClick = () => {
    navigate(to)
  }


  return <MenuItem onClick={onClick} variant={'ghost'} {...buttonProps}>
    {label}
  </MenuItem>
}
