import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ValidateFn, ValidationError } from '../Form'
import { HealthSystem } from '../../api/interfaces'
import {
  ADD_REGISTRATION_GROUP,
  DELETE_REGISTRATION_GROUP,
  UPDATE_REGISTRATION_GROUP,
} from '../../api/requests/systems'
import { useSystems } from '../Systems'
import { isNonEmptyArray } from '@apollo/client/utilities'

const messages = defineMessages({
  RegistrationGroups: {
    id: 'RegistrationTab.label_custom_groups',
    defaultMessage: 'Custom groups',
  },
  invalidGroupId: {
    id: 'RegistrationTab.invalid_group_id',
    defaultMessage: 'Invalid group ID',
  },
  errorAddingGroup: {
    id: 'RegistrationTab.error_adding_registration_group',
    defaultMessage: 'Could not create the registration group. Please, reatry',
  },
  errorUpdatingGroup: {
    id: 'RegistrationTab.error_updating_registration_group',
    defaultMessage: 'Could not update the registration group. Please, reatry',
  },
  errorDeletingGroup: {
    id: 'RegistrationTab.error_deleting_registration_group',
    defaultMessage: 'Could not delete the registration group. Please, reatry',
  },
  invalidCustomFieldId: {
    id: 'RegistrationTab.invalid_custom_field_id',
    defaultMessage: 'Invalid custom field ID',
  },
  customFieldNotFound: {
    id: 'RegistrationTab.custom_field_not_found',
    defaultMessage: 'Custom field not found',
  },
  nameLabel: {
    id: 'RegistrationTab.group_name',
    defaultMessage: 'Group name',
  },
  requiredError: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  emptyArrayError: {
    id: 'form_validation.non_empty_array',
    defaultMessage: 'This list must not be empty',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface RegistrationGroupFormValues {
  name: string
  customFields: Array<string>
}

interface AddRegistrationGroupVariables {
  systemId: string
  newRegistrationGroup: RegistrationGroupFormValues
}

interface AddRegistrationGroupData {
  addRegistrationGroup: HealthSystem
}

export type UseAddRegistrationGroupParams = MutationHookOptions<
  AddRegistrationGroupData,
  AddRegistrationGroupVariables
>

export const useAddRegistrationGroup = (params?: UseAddRegistrationGroupParams) => {
  const intl = useIntl()

  const { getMySystem } = useSystems()
  const { id: systemId } = getMySystem()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const initialValues: RegistrationGroupFormValues = {
    name: '',
    customFields: [],
  }

  const validate: ValidateFn<RegistrationGroupFormValues> = async (values) => {
    const errors: ValidationError<RegistrationGroupFormValues> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.requiredError)
    }

    if (!isNonEmptyArray(values.customFields)) {
      errors.customFields = intl.formatMessage(messages.emptyArrayError)
    }

    return errors
  }

  const translateError = (code) => {
    switch (code) {
      case 'invalid_group_id':
        setError(intl.formatMessage(messages.invalidGroupId))
        break

      case 'error_adding_custom_group':
        setError(intl.formatMessage(messages.errorAddingGroup))
        break

      case 'invalid_custom_field_id':
        setError(intl.formatMessage(messages.invalidCustomFieldId))
        break

      case 'custom_field_not_found':
        setError(intl.formatMessage(messages.customFieldNotFound))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    translateError(errorCode)

    if (params?.onError) {
      params.onError(error)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }

  const onCompleted = (data: AddRegistrationGroupData) => {
    onErrorClose()
    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [addRegistrationGroup, { loading: isLoading }] = useMutation<
    AddRegistrationGroupData,
    AddRegistrationGroupVariables
  >(ADD_REGISTRATION_GROUP, {
    ...params,
    onError,
    onCompleted,
  })

  const onSubmit = (values: RegistrationGroupFormValues) => {
    setError(null)

    const variables: AddRegistrationGroupVariables = {
      systemId,
      newRegistrationGroup: {
        ...values,
      },
    }

    addRegistrationGroup({ variables })
  }

  return {
    onSubmit,
    isLoading,
    error,
    initialValues,
    validate,
    onErrorClose,
  }
}

interface UpdateRegistrationGroupVariables {
  systemId: string
  id: string
  newRegistrationGroup: RegistrationGroupFormValues
}

interface UpdateRegistrationGroupData {
  updateRegistrationGroup: HealthSystem
}

export type UseUpdateRegistrationGroupParams = MutationHookOptions<
  UpdateRegistrationGroupData,
  UpdateRegistrationGroupVariables
> & {
  id: string
}

export const useUpdateRegistrationGroup = (params: UseUpdateRegistrationGroupParams) => {
  const { id, ...rest } = params

  const intl = useIntl()

  const { getMySystem } = useSystems()
  const { id: systemId } = getMySystem()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<RegistrationGroupFormValues> = async (values) => {
    const errors: ValidationError<RegistrationGroupFormValues> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.requiredError)
    }

    if (!isNonEmptyArray(values.customFields)) {
      errors.customFields = intl.formatMessage(messages.emptyArrayError)
    }

    return errors
  }

  const translateError = (code) => {
    switch (code) {
      case 'invalid_group_id':
        setError(intl.formatMessage(messages.invalidGroupId))
        break

      case 'error_updating_custom_group':
        setError(intl.formatMessage(messages.errorUpdatingGroup))
        break

      case 'invalid_custom_field_id':
        setError(intl.formatMessage(messages.invalidCustomFieldId))
        break

      case 'custom_field_not_found':
        setError(intl.formatMessage(messages.customFieldNotFound))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }
  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    translateError(errorCode)

    if (params?.onError) {
      params.onError(error)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }

  const onCompleted = (data: UpdateRegistrationGroupData) => {
    onErrorClose()
    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [updateRegistrationGroup, { loading: isLoading }] = useMutation<
    UpdateRegistrationGroupData,
    UpdateRegistrationGroupVariables
  >(UPDATE_REGISTRATION_GROUP, {
    ...rest,
    onError,
    onCompleted,
  })

  const onSubmit = (values: RegistrationGroupFormValues) => {
    setError(null)

    const variables: UpdateRegistrationGroupVariables = {
      systemId,
      id,
      newRegistrationGroup: {
        ...values,
      },
    }

    updateRegistrationGroup({ variables })
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}

/**
 * Deletion
 */
interface DeleteRegistrationGroupVariables {
  systemId: string
  id: string
}

interface DeleteRegistrationGroupData {
  deleteRegistrationGroup: HealthSystem
}

export type UseDeleteRegistrationGroupParams = MutationHookOptions<
  DeleteRegistrationGroupData,
  DeleteRegistrationGroupVariables
> & {
  id: string
}

export const useDeleteRegistrationGroup = (params: UseDeleteRegistrationGroupParams) => {
  const { id, ...rest } = params

  const intl = useIntl()

  const { getMySystem } = useSystems()
  const { id: systemId } = getMySystem()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'error_deleting_custom_group':
        setError(intl.formatMessage(messages.errorDeletingGroup))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    translateError(errorCode)

    if (params?.onError) {
      params.onError(error)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }

  const onCompleted = (data: DeleteRegistrationGroupData) => {
    onErrorClose()
    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [deleteRegistrationGroup, { loading: isLoading }] = useMutation<
    DeleteRegistrationGroupData,
    DeleteRegistrationGroupVariables
  >(DELETE_REGISTRATION_GROUP, {
    ...rest,
    onError,
    onCompleted,
  })

  const onSubmit = () => {
    setError(null)

    const variables: DeleteRegistrationGroupVariables = {
      systemId,
      id,
    }

    deleteRegistrationGroup({ variables })
  }

  return {
    onSubmit,
    isLoading,
    error,
    onErrorClose,
  }
}
