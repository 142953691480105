import React from 'react'
import { Text } from '@chakra-ui/react'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'
import { EventRecurrence as EventRecurrenceEnum } from '../../api/interfaces'

export const eventRecurrenceTranslations: Record<EventRecurrenceEnum, MessageDescriptor> = {
  [EventRecurrenceEnum.UNIQUE]: {
    id: 'EventRecurrence.unique',
    defaultMessage: 'Unique',
  },
  [EventRecurrenceEnum.DAILY]: {
    id: 'EventRecurrence.daily',
    defaultMessage: 'Daily',
  },
  [EventRecurrenceEnum.WEEKLY]: {
    id: 'EventRecurrence.weekly',
    defaultMessage: 'Weekly',
  },

}

const messages = defineMessages(eventRecurrenceTranslations)

interface EventRecurrenceProps {
  recurrence: EventRecurrenceEnum
}

export const EventRecurrence: React.FC<EventRecurrenceProps> = (props) => {
  const { recurrence } = props

  const intl = useIntl()

  return <Text>{intl.formatMessage(messages[recurrence])}</Text>
}

export const useEventRecurrenceTranslation = (recurrence: EventRecurrenceEnum): string => {
  const intl = useIntl()

  return intl.formatMessage(messages[recurrence])
}
