import React from 'react'
import { useMutation } from '@apollo/client'
import { Nullable } from '../../utils'
import { CREATE_LOCATION } from '../../api/requests/setup'
import { defineMessages, useIntl } from 'react-intl'
import { isValidPhoneNumber, ValidateFn, ValidationError } from '../Form'
import { useSession } from '../Auth'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred. Try again',
  },
  invalidPhone: {
    id: 'form_validation.invalid_phone_number',
    defaultMessage: 'Invalid phone number',
  },
  requiredError: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
})

interface NewLocation {
  name: string
  address?: string
  phoneNumber?: string
  country: string
}

export interface CreateLocationMutationParams {
  newLocation: NewLocation
}

interface ReturnedValue {
  onSubmit: (values: NewLocation) => void
  isLoading: boolean
  error: Nullable<string>
  validate: ValidateFn<NewLocation>
  onErrorClose: () => void
}

export const useCreateLocation = (): ReturnedValue => {
  const intl = useIntl()
  const { refresh } = useSession()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = () => {
    setError(intl.formatMessage(messages.internalServerError))
  }

  // refresh the session
  const onCompleted = () => {
    refresh()
  }

  const [createLocation, { loading }] = useMutation(CREATE_LOCATION, {
    onError,
    onCompleted,
  })

  const validate: ValidateFn<NewLocation> = (values) => {
    const errors: ValidationError<NewLocation> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.requiredError)
    }

    const phoneValidationOptions = { country: values.country }
    if (values.phoneNumber && !isValidPhoneNumber(values.phoneNumber, phoneValidationOptions)) {
      errors.phoneNumber = intl.formatMessage(messages.invalidPhone)
    }

    return errors
  }

  const onSubmit = (values: NewLocation) => {
    setError(null)

    const variables = {
      newLocation: values,
    }

    createLocation({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    validate,
    error,
    isLoading: loading,
    onErrorClose,
  }
}
