import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { RiAddLine as AddIcon } from 'react-icons/ri'
import {
  Button,
  ButtonProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useBreakpointValue,
  useDisclosure,
  Text,
  Icon,
} from '@chakra-ui/react'
import { ErrorViewer } from '..'
import { MultiLineTextField } from '../Form'
import { Form } from 'react-final-form'
import { useAddManualTreatment } from './useManualTreatment'

const messages = defineMessages({
  treatment: {
    id: 'UI.treatment_label',
    defaultMessage: 'Treatment',
  },
  saveButton: {
    id: 'UI.save_button',
    defaultMessage: 'Save',
  },
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close',
  },
})

type AddManualTreatmentButtonProps = ButtonProps & {
  patientId: string
  visitId: string
  isDisabled?: boolean
  preventModalFromClosing?: boolean
}

export const AddManualTreatmentButton: React.FC<AddManualTreatmentButtonProps> = (props) => {
  const { patientId, visitId, isDisabled, preventModalFromClosing, ...buttonProps } = props

  const intl = useIntl()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        onClick={onOpen}
        disabled={isDisabled || isOpen}
        colorScheme="brand"
        size="sm"
        {...buttonProps}
      >
        <Icon as={AddIcon} />
        <Text ml={1}>{intl.formatMessage(messages.treatment)}</Text>
      </Button>

      {isOpen && (
        <TreatmentModal
          patientId={patientId}
          visitId={visitId}
          isOpen={isOpen}
          onClose={onClose}
          preventModalFromClosing={preventModalFromClosing}
          initialFocus
        />
      )}
    </>
  )
}

interface ManualTreatmentModalProps {
  patientId: string
  visitId: string
  isOpen: boolean
  onClose: () => void
  initialFocus?: boolean
  preventModalFromClosing?: boolean
}

const TreatmentModal: React.FC<ManualTreatmentModalProps> = (props) => {
  const { patientId, visitId, isOpen, onClose, initialFocus, preventModalFromClosing } = props

  const intl = useIntl()

  const params = {
    patientId,
    visitId,
    onCompleted: preventModalFromClosing ? undefined : onClose,
  }

  const { onSubmit, isLoading, validate, error, onErrorClose } = useAddManualTreatment(params)

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Form
        onSubmit={onSubmit}
        validate={validate}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ModalContent>
                <ModalHeader>{intl.formatMessage(messages.treatment)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <MultiLineTextField
                    name="treatment"
                    label={intl.formatMessage(messages.treatment)}
                    autoFocus={initialFocus}
                  />

                  {error && <ErrorViewer title={error} onClose={onErrorClose} />}
                </ModalBody>

                <ModalFooter>
                  <Button mr={3} onClick={onClose} isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.closeButton)}
                  </Button>
                  <Button
                    colorScheme={'brand'}
                    isLoading={isLoading}
                    type="submit"
                    isFullWidth={isFullWidth}
                  >
                    {intl.formatMessage(messages.saveButton)}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )
        }}
      />
    </Modal>
  )
}
