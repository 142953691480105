import React from 'react'
import Select from 'react-select'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SelectorProps,
  useChakraThemeParser,
} from '.'
import { countries } from 'countries-list'
import { Nullable } from '../../utils'


export type CurrencySelectorProps = SelectorProps<string> & {
  country: string
}

const getCurrencies = (countryCode: string): Array<string> => {
  if (!countries[countryCode]) {
    return []
  }

  const { currency } = countries[countryCode]

  return currency.split(',')
}

export const CurrencySelector: React.FC<CurrencySelectorProps> = props => {
  const {
    value,
    country
  } = props

  const theme = useChakraThemeParser()

  const currencyList = getCurrencies(country)

  const formatOption = (currency: string): ReactSelectInternalSingleValue => {
    return {
      value: currency,
      label: currency
    }
  }

  const options = currencyList
    .map(formatOption)

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return null
      }

      const formattedValues = value
        .reduce((prev: Array<ReactSelectInternalSingleValue>, value: string) => {
          if (currencyList.includes(value)) {
            return [
              ...prev,
              formatOption(value)
            ]
          }

          return prev
        }, [])

      return formattedValues
    } else {
      if (currencyList.includes((value as string))) {
        return formatOption((value as string))
      }

      return null
    }
  }

  const _onChange = value => {
    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return props.onChange([])
      }

      const selectedValues = value
      const newValues = selectedValues.map(item => item.value)

      props.onChange(newValues as Array<string>)
    } else {
      const selectedValue = value

      props.onChange(selectedValue.value as string)
    }
  }

  return <Select
    options={options}
    value={parseValue(value)}
    onChange={_onChange}
    isLoading={props.isLoading}
    isMulti={props.isMulti}
    isClearable={props.isClearable}
    isDisabled={props.isDisabled}
    isSearchable={props.isSearchable}
    aria-invalid={props.isInvalid}
    placeholder={props.placeholder}
    blurInputOnSelect={props.blurInputOnSelect}
    theme={theme}
  />
}



