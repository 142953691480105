import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import {
  getNormalVitalsByAge,
  isInRange,
} from 'wd-common/src/logic/triage/utils'
import { Nullable } from '../../utils'
import { HeartRate as HeartRateType } from '../../api/interfaces'
import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagProps,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { TextField, ValidateFn } from '../Form'
import { PatientAgeCategory } from '../Patients'
import { NewHeartRate, useCreateHeartRate, useUpdateHeartRate } from './'
import { ErrorViewer } from '..'
import {
  RiAddLine as AddIcon,
  RiAlertLine as WarningIcon
} from 'react-icons/ri'
import { DeleteMeasurementButton } from './DeleteMeasurementButton'


const messages = defineMessages({
  title: {
    id: 'Vitals.heart_rate_title',
    defaultMessage: 'Heart Rate'
  },
  shortTitle: {
    id: 'Vitals.heart_rate_abbreviation',
    defaultMessage: 'HR'
  },
  bpm: {
    id: 'Vitals.bpm_label',
    defaultMessage: 'bpm'
  },
  minValueError: {
    id: 'form_validation.min_value',
    defaultMessage: 'This field must be greater than: '
  },
  reference: {
    id: 'Vitals.reference_title',
    defaultMessage: 'Reference'
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  submitButton: {
    id: 'NonStaffUI.button_submit',
    defaultMessage: 'Submit',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
})

type HeartRateProps = ButtonGroupProps & {
  patientId: string
  visitId: string
  birthdate: string
  value: Nullable<HeartRateType>
  isDisabled?: boolean
}

export const HeartRate: React.FC<HeartRateProps> = (props) => {
  const { value, patientId, visitId, birthdate, isDisabled, ...buttonGroupProps } = props

  if (value) {
    return (
      <Editor
        value={value}
        patientId={patientId}
        visitId={visitId}
        birthdate={birthdate}
        isDisabled={isDisabled}
        {...buttonGroupProps}
      />
    )
  }

  return (
    <Creator
      patientId={patientId}
      visitId={visitId}
      birthdate={birthdate}
      isDisabled={isDisabled}
      {...buttonGroupProps}
    />
  )
}

type CreatorProps = ButtonGroupProps & {
  patientId: string
  visitId: string
  birthdate: string
  isDisabled?: boolean
}

const Creator: React.FC<CreatorProps> = (props) => {
  const { patientId, visitId, birthdate, isDisabled, ...buttonGroupProps } = props

  const intl = useIntl()

  const { onToggle, isOpen } = useDisclosure()

  const params = {
    patientId,
    visitId,
  }
  const { onCreate, onErrorClose, error, isLoading, validate } = useCreateHeartRate(params)

  return (
    <>
      <ButtonGroup size="sm" isAttached isDisabled={isDisabled} {...buttonGroupProps}>
        <Button mr="-px" onClick={onToggle}>
          <Icon as={AddIcon} />
          <Text ml={2}>{intl.formatMessage(messages.shortTitle)}</Text>
        </Button>
      </ButtonGroup>

      <HeartRateModal
        heartRate={null}
        isOpen={isOpen}
        onToggle={onToggle}
        birthdate={birthdate}
        onSubmit={onCreate}
        onErrorClose={onErrorClose}
        error={error}
        isLoading={isLoading}
        validate={validate}
      />

    </>
  )
}

type EditorProps = ButtonGroupProps & {
  patientId: string
  visitId: string
  value: Nullable<HeartRateType>
  birthdate: string
  isDisabled?: boolean
}

const Editor: React.FC<EditorProps> = (props) => {
  const { patientId, visitId, birthdate, isDisabled, value, ...buttonGroupProps } = props

  const { onToggle, isOpen } = useDisclosure()

  const isLegacy = !value?.id

  const updateParams = {
    patientId,
    visitId,
    onCompleted: onToggle,
  }

  const {
    onUpdate,
    onErrorClose: onUpdatingErrorClose,
    error: updatingError,
    isLoading: isUpdating,
    validate,
  } = useUpdateHeartRate(updateParams)

  const onSubmit = (values: NewHeartRate) => {
    if (isLegacy) {
      return null
    }
    const measurementId = value.id
    onUpdate(measurementId, values)
  }

  return (
    <>
      <ButtonGroup size="sm" isAttached isDisabled={isDisabled} {...buttonGroupProps}>
        <Button mr="-px" onClick={onToggle}>
          <ValueWithWarning heartRate={value} birthdate={birthdate} />
        </Button>
        {!isLegacy && (
          <DeleteMeasurementButton
            patientId={patientId}
            visitId={visitId}
            measurementId={value.id}
            isDisabled={isDisabled}
          />
        )}
      </ButtonGroup>

      <HeartRateModal
        heartRate={value}
        isOpen={isOpen}
        onToggle={onToggle}
        birthdate={birthdate}
        onSubmit={onSubmit}
        onErrorClose={onUpdatingErrorClose}
        error={updatingError}
        isLoading={isUpdating}
        validate={validate}
      />

    </>
  )
}

type HeartRateModalProps = {
  birthdate: string
  heartRate: Nullable<HeartRateType>
  isOpen: boolean
  onToggle: () => void
  onSubmit: (values: NewHeartRate) => void
  validate: ValidateFn<NewHeartRate>
  error: Nullable<string>
  onErrorClose: () => void
  isLoading: boolean
  isDisabled?: boolean
}

const HeartRateModal: React.FC<HeartRateModalProps> = (props) => {
  const { validate, onSubmit, error, onErrorClose, isLoading, heartRate, birthdate, isDisabled, isOpen, onToggle } = props

  const intl = useIntl()

  const _onSubmit = (values) => {
    const newHeartRate: NewHeartRate = {
      bpm: parseFloat(values?.bpm),
    }

    onSubmit(newHeartRate)
  }

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <Form
        onSubmit={_onSubmit}
        validate={validate}
        initialValues={heartRate}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TextField
                  name="bpm"
                  label={intl.formatMessage(messages.bpm)}
                  type={'number'}
                  autoFocus
                />

                <VitalReference birthdate={birthdate} hr={heartRate} />
                {error && <ErrorViewer title={error} onClose={onErrorClose} />}
              </ModalBody>

              <ModalFooter>
                <Button isFullWidth={isFullWidth} onClick={onToggle}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  colorScheme={'brand'}
                  isLoading={isLoading}
                  type="submit"
                  isDisabled={isDisabled}
                  isFullWidth={isFullWidth}
                  ml={2}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        )}
      />
    </Modal>
  )
}

const checkWarnings = (patient, hr) => {
  const reference = getNormalVitalsByAge(patient)
  const hrReference = (reference || {}).hr

  const inRange = !hrReference || !hr || isInRange(hrReference?.min, hrReference?.max, hr)

  return {
    inRange,
    reference: hrReference
  }
}

type ValueWithWarningProps = {
  heartRate: Nullable<HeartRateType>
  birthdate: string
}

const ValueWithWarning: React.FC<ValueWithWarningProps> = (props) => {
  const { heartRate, birthdate } = props

  const intl = useIntl()

  const { inRange } = checkWarnings({ birthdate }, heartRate?.bpm)

  return (
    <HStack alignItems={'center'}>
      {!inRange && <Icon as={WarningIcon} color="red.400" />}
      <Text mx={1}>{intl.formatMessage(messages.shortTitle)}:</Text>
      <Text fontWeight={'bold'} color={!inRange ? 'red.400' : undefined}>
        {heartRate?.bpm ?? '--'}
        {intl.formatMessage(messages.bpm)}
      </Text>
    </HStack>
  )
}

type VitalReferenceProps = {
  hr: Nullable<HeartRateType>
  birthdate: string
}

const VitalReference: React.FC<VitalReferenceProps> = (props) => {
  const { hr, birthdate } = props

  const intl = useIntl()

  const { inRange, reference } = checkWarnings({ birthdate }, hr?.bpm)

  if (!reference) {
    return null
  }

  return (
    <Box p={4} borderWidth={1} rounded={'lg'}>
      <PatientAgeCategory birthdate={birthdate} mb={1} fontWeight={'bold'} />
      <HStack>
        <Text>{intl.formatMessage(messages.reference)}</Text>
        <Text fontWeight={'bold'} ml={2}>
          {`${reference.min} - ${reference.max} ${intl.formatMessage(messages.bpm)}`}
        </Text>
        {!inRange && <Icon as={WarningIcon} ml={2} color="red.400" />}
      </HStack>
    </Box>
  )
}

type PrintableHeartRateProps = TagProps & {
  birthdate: string
  value: Nullable<HeartRateType>
}

export const PrintableHeartRate: React.FC<PrintableHeartRateProps> = (props) => {
  const { value, birthdate, ...tagProps } = props

  return <Tag {...tagProps}>
    <ValueWithWarning
      heartRate={value}
      birthdate={birthdate}
    />
  </Tag>
}
