import React from 'react'
import Select from 'react-select'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SelectorProps,
  useChakraThemeParser,
} from '.'
import { countries, getEmojiFlag, Country } from 'countries-list'
import { Nullable } from '../../utils'

type FullCountry = Country & {
  code: string
}

export type CountrySelectorProps = SelectorProps<string>
export const CountrySelector: React.FC<CountrySelectorProps> = props => {
  const {
    value,
  } = props

  const theme = useChakraThemeParser()


  const formatOption = (country: FullCountry): ReactSelectInternalSingleValue => {
    return {
      value: country.code,
      label: `${getEmojiFlag(country.code)}  ${country.name}`
    }
  }

  const options = Object.entries(countries)
    .map(([code, country]) => ({ ...country, code }))
    .map(formatOption)

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return null
      }

      const formattedValues = value
        .reduce((prev: Array<ReactSelectInternalSingleValue>, value: string) => {
          const key = (value as string).toUpperCase()

          if (countries[key]) {
            return [
              ...prev,
              formatOption({
                ...countries[key],
                code: key
              })
            ]
          }

          return prev
        }, [])

      return formattedValues
    } else {
      const key = (value as string).toUpperCase()

      if (countries[key]) {
        return formatOption({
          ...countries[key],
          code: key
        })
      }

      return null
    }
  }

  const _onChange = value => {
    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return props.onChange([])
      }

      const selectedValues = value
      const newValues = selectedValues.map(item => item.value)

      props.onChange(newValues as Array<string>)
    } else {
      const selectedValue = value

      props.onChange(selectedValue.value as string)
    }
  }

  return <Select
    options={options}
    value={parseValue(value)}
    onChange={_onChange}
    isLoading={props.isLoading}
    isMulti={props.isMulti}
    isClearable={props.isClearable}
    isDisabled={props.isDisabled}
    isSearchable={props.isSearchable}
    aria-invalid={props.isInvalid}
    placeholder={props.placeholder}
    blurInputOnSelect={props.blurInputOnSelect}
    theme={theme}
  />
}



