import React from 'react'
import { Modal, ModalBody, ModalContent, ModalOverlay, ModalHeader } from '@chakra-ui/react'
import { useUpdateTelehealthSettings } from '../Systems/useTelehealthSettings'
import { defineMessages, useIntl } from 'react-intl'
import { TelehealthSettingsForm } from './TelehealthSettingsForm'

const messages = defineMessages({
  settings: {
    id: 'Telehealth.label_Settings',
    defaultMessage: 'Calendar settings',
  },
})

interface UpdateTelehealthSettingsModalProps {
  onClose: () => void
  onCompleted?: () => void
}

export const UpdateTelehealthSettingsModal: React.FC<UpdateTelehealthSettingsModalProps> = (
  props
) => {
  const { onClose, onCompleted } = props

  const intl = useIntl()

  const { isLoading, validate, onErrorClose, onSubmit, error, initialValues } = useUpdateTelehealthSettings({ onCompleted })

  return (
    <Modal isOpen={true} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{intl.formatMessage(messages.settings)}</ModalHeader>
        <ModalBody>
          <TelehealthSettingsForm
            isLoading={isLoading}
            validate={validate}
            onErrorClose={onErrorClose}
            onSubmit={onSubmit}
            error={error}
            initialValues={initialValues}
            onCancel={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
