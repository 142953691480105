import React from 'react'
import {
  PopoverCloseButton,
  Button,
  ButtonGroup,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  PopoverArrow,
} from '@chakra-ui/react'
import { defineMessages, useIntl } from 'react-intl'
import { useSession } from '../Auth'
import { UnitSystems } from '../useUnitConversion'

const messages = defineMessages({
  imperialUnits: {
    id: 'UnitSystemSwitch.imperial_units_label',
    defaultMessage: 'Imperial Units',
  },
  metricUnits: {
    id: 'UnitSystemSwitch.metric_units_label',
    defaultMessage: 'Metric Units',
  },
  tooltipLabel: {
    id: 'UnitSystemSwitch.null_tooltip_label',
    defaultMessage: 'Please select a unit system to use, metric system will be default',
  },
})

export const UnitSystemSwitch: React.FC = () => {
  const intl = useIntl()
  const { changeUserUnitSystem, getUserUnitSystem } = useSession()

  const [isLoading, setIsLoading] = React.useState(false)

  const selectedUnit = getUserUnitSystem()
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(Boolean(!selectedUnit))

  return (
    <HStack>
      {isLoading && <Spinner colorScheme={'brand'} />}

      <Popover
        isLazy
        isOpen={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <ButtonGroup isAttached size="sm" variant="outline" colorScheme={'brand'}>
            <Button
              isActive={selectedUnit === UnitSystems.metric}
              disabled={isLoading}
              onClick={(e) => {
                setIsLoading(true)
                changeUserUnitSystem(UnitSystems.metric).finally(() => setIsLoading(false))
                e.currentTarget.blur()
              }}
            >
              {intl.formatMessage(messages.metricUnits)}
            </Button>
            <Button
              isActive={selectedUnit === UnitSystems.imperial}
              disabled={isLoading}
              onClick={(e) => {
                setIsLoading(true)
                changeUserUnitSystem(UnitSystems.imperial).finally(() => setIsLoading(false))
                e.currentTarget.blur()
              }}
            >
              {intl.formatMessage(messages.imperialUnits)}
            </Button>
          </ButtonGroup>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>{intl.formatMessage(messages.tooltipLabel)}</PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  )
}
