import { FormLabel } from '@chakra-ui/react'
import React from 'react'
import { useField } from 'react-final-form'
import { HowLongSelector, HowLongSelectorProps } from '../Selector'
import { Control, Error } from './utils'

// allow all selector props apart from the ones related to the state
type HowLongFieldProps = Omit<HowLongSelectorProps, 'value' | 'onChange'> & {
  name: string
  label?: string
}

export const HowLongField: React.FC<HowLongFieldProps> = (props) => {
  const { name, label } = props
  const { input } = useField(name)

  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <HowLongSelector {...input} />
      <Error name={name} />
    </Control>
  )
}
