import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { UPDATE_PAYMENT_SETTINGS } from '../../api/requests/systems'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { Location, PaymentSettings } from '../../api/interfaces'
import { useSystems } from '.'
import { useSession } from '../Auth'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  savingPaymentError: {
    id: 'PaymentSetting.error_saving_payment_settings',
    defaultMessage: 'Error saving payment settings. Try again.',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
})

interface NewPaymentSettings {
  defaultPricelist: string
  currency: string
}

interface UpdatePaymentSettingVariables {
  systemId: string
  locationId: string
  paymentSettings: NewPaymentSettings
}

interface UpdatePaymentSettingData {
  updatePaymentSettings: Location
}

export type UpdatePaymentSettingParams = MutationHookOptions<
  UpdatePaymentSettingData,
  UpdatePaymentSettingVariables
>

export const useUpdatePaymentSettings = (params?: UpdatePaymentSettingParams) => {
  const intl = useIntl()

  const paymentSettings = usePaymentSettings()

  const { getUserLocation, getUserSystem } = useSession()
  const { id: systemId } = getUserSystem()
  const { id: locationId } = getUserLocation()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const initialValues = paymentSettings ?? {
    currency: '',
    defaultPricelist: '',
  }

  const onError = (error) => {
    if (params?.onError) {
      params.onError(error)
    }

    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'error_saving_payment_settings':
        return setError(intl.formatMessage(messages.savingPaymentError))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onCompleted = (data) => {
    setError(null)

    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [updateSettings, { loading: isLoading }] = useMutation<
    UpdatePaymentSettingData,
    UpdatePaymentSettingVariables
  >(UPDATE_PAYMENT_SETTINGS, { ...params, onError, onCompleted })

  const onSubmit = (paymentSettings: NewPaymentSettings) => {
    const variables = {
      systemId,
      locationId,
      paymentSettings,
    }

    updateSettings({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    initialValues,
    onSubmit,
    isLoading,
    error,
    onErrorClose,
  }
}

export const usePaymentSettings = (): Nullable<PaymentSettings> => {
  const { getMyLocation } = useSystems()
  const location = getMyLocation()

  return location.paymentSettings
}
