import { gql } from '@apollo/client'
import { BasicStudyTemplate } from '../fragments'

export const GET_LAB_SUBSCRIPTIONS = gql`
  ${BasicStudyTemplate}
  query labSubscriptions {
    labSubscriptions {
      docs {
        ...BasicStudyTemplate
      }
      count
    }
  }
`
