import { FormLabel } from '@chakra-ui/react'
import React from 'react'
import { useField } from 'react-final-form'
import { CountrySelector, LocaleSelectorProps } from '../Selector'
import { Control, Error } from './utils'

// allow all selector props apart from the ones related to the state
type CountryFieldProps = Omit<LocaleSelectorProps, 'value' | 'onChange'> & {
  name: string
  label?: string
}

export const CountryField: React.FC<CountryFieldProps> = (props) => {
  const { name, label } = props
  const { input, meta } = useField(name)

  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <CountrySelector isInvalid={meta.error && meta.touched} placeholder={props?.label ?? undefined} {...input} />
      <Error name={name} />
    </Control>
  )
}
