import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import { useCalendarContext } from './useCalendarContext'
import { CreateRoomEventTab, UpdateRoomEventTab } from './RoomEventTab'
import { Nullable } from '../../utils'
import { CreateScheduleVisitTab, UpdateScheduleVisitTab } from './ScheduledVisitTab'
import { calendarEventToRoomEvent, calendarEventToScheduledVisit } from './utils'

const messages = defineMessages({
  createEvent: {
    id: 'TelehealthTab.button_create_event',
    defaultMessage: 'Create event',
  },
  updateEvent: {
    id: 'TelehealthTab.button_update_event',
    defaultMessage: 'Update event',
  },
  scheduledVisit: {
    id: 'EventViewer.scheduled_viewer',
    defaultMessage: 'Scheduled Visit',
  },
  blockedTime: {
    id: 'EventViewer.personal_appointment',
    defaultMessage: 'Personal appointment',
  },
})

interface CreateEventModalProps {
  roomId: string
  start: Date
  end: Date
  onClose: () => void
  onCompleted?: () => void
}

export const CreateEventModal: React.FC<CreateEventModalProps> = (props) => {
  const intl = useIntl()

  return (
    <Modal isOpen={true} onClose={props.onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{intl.formatMessage(messages.createEvent)}</ModalHeader>
        <ModalBody py={0}>
          <Tabs isLazy>
            <TabList>
              <Tab>{intl.formatMessage(messages.scheduledVisit)}</Tab>
              <Tab>{intl.formatMessage(messages.blockedTime)}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <CreateScheduleVisitTab {...props} />
              </TabPanel>
              <TabPanel>
                <CreateRoomEventTab {...props} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface UpdateEventModalProps {
  roomId: string
  eventId: string
  start: Nullable<Date>
  end: Nullable<Date>
  onClose: () => void
}

export const UpdateEventModal: React.FC<UpdateEventModalProps> = (props) => {
  const { eventId } = props

  const { events } = useCalendarContext()

  const event = events.find((event) => event.id === eventId)
  const isScheduledVisit = event?.extendedProps?.isScheduledVisit

  const intl = useIntl()

  if (!event) {
    return null
  }

  return (
    <Modal isOpen={true} onClose={props.onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{intl.formatMessage(messages.updateEvent)}</ModalHeader>
        <ModalBody>
          {isScheduledVisit ? (
            <UpdateScheduleVisitTab {...props} event={calendarEventToScheduledVisit(event)} />
          ) : (
            <UpdateRoomEventTab {...props} event={calendarEventToRoomEvent(event)} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export type EventModalProps = UpdateEventModalProps | CreateEventModalProps
const isUpdateModalProps = (props: EventModalProps): props is UpdateEventModalProps => {
  return 'eventId' in props
}

export const EventModal: React.FC<EventModalProps> = (props) => {
  if (isUpdateModalProps(props)) {
    return <UpdateEventModal {...props} />
  }

  return <CreateEventModal {...props} />
}
