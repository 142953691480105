import { EventInput } from '@fullcalendar/common'
import { DateTime, Duration, Interval } from 'luxon'
import { CalendarSlotUnit, EventRecurrence, RoomEvent, ScheduledVisit, TelehealthSettings } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { getPatientName } from '../Patients'

export enum CalendarViewType {
  LIST = 'listWeek',
  DAY = 'timeGridDay',
  WEEK = 'timeGridWeek',
  MONTH = 'dayGridMonth',
}

export enum CalendarNavigationOptions {
  TODAY = 'TODAY',
  PREV = 'PREV',
  NEXT = 'NEXT',
}

export const isAValidView = (viewType: string) => Object.values(CalendarViewType).includes(viewType as CalendarViewType)

export const isValidSlotUnit = (unit: string) => Object.values(CalendarSlotUnit).includes(unit as CalendarSlotUnit)

export const isValidEventRecurrence = (recurrence: string) =>
  Object.values(EventRecurrence).includes(recurrence as EventRecurrence)

export const getSlotDurationFromSettings = (settings: Nullable<TelehealthSettings>): Duration => {
  if (!settings) {
    return Duration.fromObject({ minutes: 30 })
  }

  const { slot } = settings
  switch (slot.unit) {
    case CalendarSlotUnit.DAYS:
      return Duration.fromObject({ days: slot.length })

    case CalendarSlotUnit.HOURS:
      return Duration.fromObject({ hours: slot.length })

    case CalendarSlotUnit.MINUTES:
      return Duration.fromObject({ minutes: slot.length })

    default:
      return Duration.fromObject({ minutes: 30 })
  }
}

/**
 * Validate that both contained strings are valid dates
 */
const isValidDateTimeSlot = (current: InternalNormalizedAvailableSlot): boolean => {
  return current.start.isValid && current.end.isValid
}

const parseSlotToDateTimeSlot = (slot: AvailableSlot): InternalNormalizedAvailableSlot => {
  return {
    start: DateTime.fromISO(slot.start),
    end: DateTime.fromISO(slot.end),
  }
}

const divideIntoNormalizedSlots = (
  source: InternalNormalizedAvailableSlot,
  slotDurationInMinutes: number,
  useEndRangeValue?: boolean
): Array<Date> => {
  const slotDuration = Duration.fromObject({ minutes: slotDurationInMinutes })

  /**
   * Add 1 slot to start and end to get end of interval
   */
  const interval = useEndRangeValue
    ? Interval.fromDateTimes(source.start.plus(slotDuration), source.end.plus(slotDuration))
    : Interval.fromDateTimes(source.start, source.end)

  const normalizedIntervals = interval.splitBy(slotDuration)

  return normalizedIntervals.map((interval) => interval.start.toJSDate())
}

/**
 * Take an array of AvailableSlot [start, end] and return
 * a normalized one in which all the items have the same duration
 */
export interface AvailableSlot {
  start: string
  end: string
}

interface InternalNormalizedAvailableSlot {
  start: DateTime
  end: DateTime
}

export interface NormalizedAvailableSlot {
  start: Date
  end: Date
}

export const normalizeSlots = (slots: Array<AvailableSlot>, slotDurationInMinutes: number): Array<Date> => {
  const normalizedSlots = slots.reduce((normalized, current) => {
    const datetimeSlot = parseSlotToDateTimeSlot(current)

    if (!isValidDateTimeSlot(datetimeSlot)) {
      return normalized as Array<Date>
    }

    const subSlots = divideIntoNormalizedSlots(datetimeSlot, slotDurationInMinutes)

    return [...normalized, ...subSlots] as Array<Date>
  }, [] as Array<Date>)

  return normalizedSlots
}

/**
 * End time selector needs a filtered and formatted version of the available slots.
 * This should be after the start time selection and before the next occupied slot
 */
export const formatNormalizedSlotsForEndTimeSelector = (
  slots: Array<AvailableSlot>,
  slotDurationInMinutes: number,
  selectedStart?: Date
): Array<Date> => {
  if (!selectedStart) {
    return normalizeSlots(slots, slotDurationInMinutes)
  }

  // find the container
  const container = slots.find((curr) => {
    const selected = DateTime.fromJSDate(selectedStart)
    const interval = Interval.fromDateTimes(DateTime.fromISO(curr.start), DateTime.fromISO(curr.end))

    return interval.contains(selected)
  })

  if (!container) {
    return []
  }

  const source = {
    start: DateTime.fromJSDate(selectedStart),
    end: DateTime.fromISO(container.end),
  }

  // true as 3rd params means that I want the end value of the normalized slots
  return divideIntoNormalizedSlots(source, slotDurationInMinutes, true)
}

/**
 * Event parsers
 */
export const scheduledVisitToCalendarEvent = (event: ScheduledVisit): EventInput => {
  const { id, registrationData } = event

  const { patient, type, end, start } = registrationData
  const patientName = getPatientName(patient)

  return {
    id,
    title: patientName,
    description: type,
    allDay: false,
    resourceEditable: false,
    start: new Date(start),
    end: new Date(end),
    extendedProps: {
      isScheduledVisit: true,
      scheduledVisit: {
        id,
        registrationData,
      },
    },
  }
}

export const roomEventToCalendarEvent = (event: RoomEvent): EventInput => {
  const { id, name, description, recurrence, start, end } = event

  const baseEvent = {
    id,
    title: name,
    description,
    allDay: false,
    resourceEditable: false,
    extendedProps: {
      isRoomEvent: true,
      originalEvent: event,
    },
  }

  switch (recurrence) {
    case EventRecurrence.UNIQUE:
      return {
        ...baseEvent,
        start: new Date(start),
        end: new Date(end),
      }

    case EventRecurrence.DAILY:
      return {
        ...baseEvent,
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
        groupId: `daily-${id}`,
        startTime: DateTime.fromISO(start).toFormat('HH:mm:ss'),
        endTime: DateTime.fromISO(end).toFormat('HH:mm:ss'),
      }

    case EventRecurrence.WEEKLY:
      return {
        ...baseEvent,
        daysOfWeek: [new Date(start).getDay()],
        groupId: `weekly-${id}`,
        startTime: DateTime.fromISO(start).toFormat('HH:mm:ss'),
        endTime: DateTime.fromISO(end).toFormat('HH:mm:ss'),
      }

    default:
      return {
        ...baseEvent,
        start: new Date(start),
        end: new Date(end),
      }
  }
}

export const calendarEventToRoomEvent = (event: EventInput): RoomEvent => {
  return event?.extendedProps?.originalEvent
}

export const calendarEventToScheduledVisit = (event: EventInput): ScheduledVisit => {
  return event?.extendedProps?.scheduledVisit
}
