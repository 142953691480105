import { FullChecklist } from '../../api/interfaces'

export const getAllChecklistItems = (checklist: FullChecklist) => {
  const allItems = [
    ...(checklist.education ?? []),
    ...(checklist.treatment ?? []),
    ...(checklist.studies ?? []),
    ...(checklist.physical_exam ?? []),
    ...(checklist.history ?? []),
  ]

  return allItems
}
