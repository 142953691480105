import { gql } from '@apollo/client'

export const GET_ACTIVATION_CHECKOUT_URI = gql`
  query activationCheckout {
    activationCheckout
  }
`

/**
 * Not use the fragment. This one is comes from a different
 * graphql server
 */
export const CREATE_LOCATION = gql`
  mutation clinicSetup($newLocation: NewLocation!) {
    clinicSetup(newLocation: $newLocation) {
      id
      name
      address
      phoneNumber
    }
  }
`
