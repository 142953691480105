import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { User } from '../../api/interfaces'
import { ENABLE_USER_ACCOUNT, DISABLE_USER_ACCOUNT } from '../../api/requests/staff'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  genericErrorMessage: {
    id: 'UseOrderActions.generic_error_message',
    defaultMessage: 'Something went wrong, try again later.',
  },
})

type UpdateAccountStatusVariables = {
  username: string
}

type EnableAccountStatusData = {
  enableUserAccount: User
}

export type UseEnableUserAccount = MutationHookOptions<
  EnableAccountStatusData,
  UpdateAccountStatusVariables
>

export const useEnableUserAccount = (params?: UseEnableUserAccount) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.genericErrorMessage))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)

    translateError(firstError)
  }

  const onErrorClose = () => {
    setError(null)
  }

  const [enableAccount, { loading: isLoading }] = useMutation<
    EnableAccountStatusData,
    UpdateAccountStatusVariables
  >(ENABLE_USER_ACCOUNT, {
    onError,
    onCompleted: onErrorClose,
    ...params,
  })

  return {
    enableAccount,
    isLoading,
    error,
    onErrorClose,
  }
}

type DisableAccountStatusData = {
  disableUserAccount: User
}

export type UseDisableUserAccount = MutationHookOptions<
  DisableAccountStatusData,
  UpdateAccountStatusVariables
>

export const useDisableUserAccount = (params?: UseDisableUserAccount) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.genericErrorMessage))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)

    translateError(firstError)
  }

  const onErrorClose = () => {
    setError(null)
  }

  const [disableAccount, { loading: isLoading }] = useMutation<
    DisableAccountStatusData,
    UpdateAccountStatusVariables
  >(DISABLE_USER_ACCOUNT, {
    onError,
    onCompleted: onErrorClose,
    ...params,
  })

  return {
    disableAccount,
    isLoading,
    error,
    onErrorClose,
  }
}
