import React from 'react'
import Select from 'react-select'
import { useChakraThemeParser } from '.'
import { Pricelist } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { usePricelists } from '../Pricing'
import { ReactSelectInternalValue, ReactSelectInternalSingleValue, SelectorProps, isMultiSelector } from './utils'

export type PaymentTypeSelectorProps = SelectorProps<string>

const formatOption = (pricelist: Pricelist): ReactSelectInternalSingleValue => {
  const { id, name } = pricelist

  return {
    label: name,
    value: id,
  }
}

export const PaymentTypeSelector: React.FC<PaymentTypeSelectorProps> = (props) => {
  const { value } = props

  const theme = useChakraThemeParser()

  const { pricelists, isLoading, getPricelist } = usePricelists({ fetchPolicy: 'cache-and-network' })

  const options = React.useMemo(() => {
    if (Array.isArray(pricelists)) {
      return pricelists.map((value) => formatOption(value as Pricelist))
    }

    return []
  }, [pricelists])

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (isMultiSelector(props)) {
      if (!Array.isArray(value)) {
        return null
      }

      const formattedValues = value.reduce((prev: Array<ReactSelectInternalSingleValue>, pricelistId: string) => {
        const pricelist = getPricelist(pricelistId)

        if (pricelist) {
          return [...prev, formatOption(pricelist)]
        }

        return prev
      }, [])

      return formattedValues
    } else {
      const pricelist = getPricelist(value as string)

      if (pricelist) {
        return formatOption(pricelist)
      }

      return null
    }
  }

  const _onChange = (internalValue): void => {
    if (isMultiSelector(props)) {
      if (!Array.isArray(internalValue)) {
        return props.onChange([])
      }

      const selectedValues = internalValue
      const newValues = selectedValues.map((item) => item.value)

      props.onChange(newValues)
    } else {
      if (!internalValue) {
        return props.onChange('')
      }

      const selectedValue = internalValue

      props.onChange(selectedValue.value)
    }
  }

  return (
    <Select
      options={options}
      value={parseValue(value)}
      onChange={_onChange}
      isLoading={isLoading}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      aria-invalid={props.isInvalid}
      placeholder={props.placeholder}
      blurInputOnSelect={props.blurInputOnSelect}
      theme={theme}
    />
  )
}
