import React from 'react'
import { BoxProps, FormLabel, InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { useField } from 'react-final-form'
import { Control, Error } from './utils'
import { DatePicker } from './DatePicker/DatePicker'

interface DateFieldProps extends Omit<BoxProps, 'onChange'> {
  label: string
  name: string
  onChange?: (newDate: Date) => void
  before?: React.ReactNode
  isDisabled?: boolean
  isClearable?: boolean
  autoComplete?: string
  autoFocus?: boolean
}

export const DateField: React.FC<DateFieldProps> = (props) => {
  if (props.before) {
    return <DecoratedInput {...props} />
  }

  return <PlainInput {...props} />
}

const DecoratedInput: React.FC<DateFieldProps> = (props) => {
  const { name, before, label } = props

  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <InputGroup>
        <InputLeftAddon>{before}</InputLeftAddon>
        <Input {...props} />
      </InputGroup>
      <Error name={name} />
    </Control>
  )
}

const PlainInput: React.FC<DateFieldProps> = (props) => {
  const { name, label } = props

  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input {...props} />
      <Error name={name} />
    </Control>
  )
}

const Input: React.FC<DateFieldProps> = (props) => {
  const { name, label, isDisabled, isClearable, onChange, ...boxProps } = props

  const { input, meta } = useField(name)

  return (
    <DatePicker
      {...input}
      onChange={(event) => {
        if (typeof onChange === 'function') {
          onChange(event)
        }
        input.onChange(event)
      }}
      isInvalid={meta.error && meta.touched}
      id={name}
      placeholderText={label}
      disabled={isDisabled}
      isClearable={isClearable}
      maxDate={new Date()}
      showYearDropdown
      dropdownMode="select"
      boxProps={{
        ...boxProps,
      }}
    />
  )
}
