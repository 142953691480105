import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { RiAddLine as AddIcon } from 'react-icons/ri'
import { Button, ButtonProps, Icon, Text, useDisclosure } from '@chakra-ui/react'
import { StudyOrderModal } from '../Studies'

const messages = defineMessages({
  addStudyButton: {
    id: 'UI.add_study_button',
    defaultMessage: 'Study'
  }
})

type AddStudyButtonProps = ButtonProps & {
  patientId: string
  visitId: string
  isDisabled?: boolean
}

export const AddStudyButton: React.FC<AddStudyButtonProps> = props => {
  const { patientId, visitId, isDisabled, ...buttonProps } = props

  const intl = useIntl()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return <>
    <Button
      onClick={onOpen}
      disabled={isDisabled || isOpen}
      colorScheme='brand'
      size='sm'
      {...buttonProps}
    >
      <Icon as={AddIcon} />
      <Text ml={1}>
        {intl.formatMessage(messages.addStudyButton)}
      </Text>
    </Button>

    {
      isOpen &&
      <StudyOrderModal
        patientId={patientId}
        visitId={visitId}
        isOpen={isOpen}
        onClose={onClose}
      />
    }
  </>
}

