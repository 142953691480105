import React from 'react'
import { ChecklistReferenceBody } from './ChecklistReference'
import { FullReference, TranslatableText } from '../../api/interfaces'
import { VStack } from '@chakra-ui/react'
import { DynamicTranslation } from '../i18n'
import { Nullable } from '../../utils'

interface ChecklistDividerProps {
  name: TranslatableText
  reference: Nullable<FullReference>
  isDisabled?: boolean
}

export const ChecklistDivider: React.FC<ChecklistDividerProps> = props => {
  const { name, reference } = props

  return <VStack alignItems={'start'} mt={3} mb={1} spacing={0}>
    {
      name &&
      <DynamicTranslation message={name} />
    }
    {
      reference &&
      <ChecklistReferenceBody reference={reference} />
    }
  </VStack>
}

