import React from 'react'
import { defineMessages } from 'react-intl'
import { useListContext } from './useListContext'
import { ListContextValue } from './ListContext'
import { ActiveVisit, FullExamWithoutId } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { useUpdateExam } from './useUpdateExam'
import { useActiveVisit } from '../Patients'

export const messages = defineMessages({
  errorGetListsDefault: {
    id: 'useList.error_get_lists_default',
    defaultMessage: 'Something went wrong while trying to get lists. Please, try again',
  },
  errorNoToothTemplate: {
    id: 'Odontogram.error_no_tooth_template',
    defaultMessage: 'Please create a Tooth template',
  },
})

interface SelectChoiceParams {
  sectionIndex: number
  groupIndex: number
  choiceIndex: number
  isSelected: boolean
}

interface SelectDetailsParams extends SelectChoiceParams {
  detailIndex: number
  listId: string
  listItemIndex: number
}

interface ChangeNoteParams {
  sectionIndex: number
  note: string
}

export interface ExamEditorContextValue {
  patientId: string
  visitId: string
  exam: FullExamWithoutId
  onChoiceSelect: (params: SelectChoiceParams) => void
  onDetailsSelect: (params: SelectDetailsParams) => void
  onSectionRemove: (sectionIndex: number) => void
  onSectionNoteChange: (params: ChangeNoteParams) => void
  onSave: () => void
  onRefresh: () => void
  isLoading: boolean
  error: Nullable<string>
  onErrorClose?: () => void
}

interface Props {
  patientId: string
  visitId: string
  onCompleted?: () => void
}

export const ExamEditorContext = React.createContext<Nullable<ExamEditorContextValue>>(null)
export const ExamEditorProvider: React.FC<Props> = (props) => {
  const { patientId, visitId, onCompleted } = props

  const { onSubmit, isLoading, error, onErrorClose } = useUpdateExam({
    patientId,
    visitId,
    onCompleted,
  })
  const { activeVisit } = useActiveVisit({ patientId })
  const { getList } = useListContext() as ListContextValue

  const initialValue = { ...(activeVisit as ActiveVisit).exam }
  const [inMemoryExam, updateInMemoryExam] = React.useState<FullExamWithoutId>(initialValue)

  const onChoiceSelect = (params: SelectChoiceParams) => {
    const { sectionIndex, groupIndex, choiceIndex, isSelected } = params

    updateInMemoryExam((prev) => {
      const { sections, ...restExam } = prev

      if (!sections[sectionIndex]) {
        return prev
      }

      const { groups, ...restGroups } = sections[sectionIndex]
      if (!groups[groupIndex]) {
        return prev
      }

      const { choices, ...restChoices } = groups[groupIndex]
      if (!choices[choiceIndex]) {
        return prev
      }

      // re-build state from the basement
      const newChoices = choices.map((choice, index) => ({
        ...choice,
        selected: index === choiceIndex ? !isSelected : false,
      }))

      const newGroups = [
        ...groups.slice(0, groupIndex),
        {
          ...restChoices,
          choices: newChoices,
        },
        ...groups.slice(groupIndex + 1),
      ]

      const newSections = [
        ...sections.slice(0, sectionIndex),
        {
          ...restGroups,
          groups: newGroups,
        },
        ...sections.slice(sectionIndex + 1),
      ]

      return {
        ...restExam,
        sections: newSections,
      }
    })
  }

  const onDetailsSelect = (params: SelectDetailsParams) => {
    const {
      sectionIndex,
      groupIndex,
      choiceIndex,
      detailIndex,
      listId,
      listItemIndex,
      isSelected,
    } = params

    updateInMemoryExam((prev) => {
      const { sections, ...restExam } = prev

      if (!sections[sectionIndex]) {
        return prev
      }

      const { groups, ...restGroups } = sections[sectionIndex]
      if (!groups[groupIndex]) {
        return prev
      }

      const { choices, ...restChoices } = groups[groupIndex]
      if (!choices[choiceIndex]) {
        return prev
      }

      const { details, ...restDetails } = choices[choiceIndex]
      if (!details || !details[detailIndex]) {
        return prev
      }

      // check list existence
      const list = getList(listId)
      if (!list) {
        return prev
      }

      // check list item existence
      const listItem = list.items[listItemIndex]
      if (!listItem) {
        return prev
      }

      // re-build state from the basement
      const currentSelectedItems = details[detailIndex].selected_items ?? []

      // if it's already selected, we should remove it from the array
      const newSelectedItems = isSelected
        ? currentSelectedItems.filter((item) => item.text !== listItem.name.text)
        : [...currentSelectedItems, listItem.name]

      const newDetails = [
        ...details.slice(0, detailIndex),
        {
          ...details[detailIndex],
          selected_items: newSelectedItems,
        },
        ...details.slice(detailIndex + 1),
      ]

      const newChoices = [
        ...choices.slice(0, choiceIndex),
        {
          ...restDetails,
          details: newDetails,
        },
        ...choices.slice(choiceIndex + 1),
      ]

      const newGroups = [
        ...groups.slice(0, groupIndex),
        {
          ...restChoices,
          choices: newChoices,
        },
        ...groups.slice(groupIndex + 1),
      ]

      const newSections = [
        ...sections.slice(0, sectionIndex),
        {
          ...restGroups,
          groups: newGroups,
        },
        ...sections.slice(sectionIndex + 1),
      ]

      return {
        ...restExam,
        sections: newSections,
      }
    })
  }

  const onSectionNoteChange = (params: ChangeNoteParams) => {
    const { sectionIndex, note } = params

    updateInMemoryExam((prev) => {
      const { sections, ...rest } = prev

      return {
        ...rest,
        sections: [
          ...sections.slice(0, sectionIndex),
          {
            ...sections[sectionIndex],
            note,
          },
          ...sections.slice(sectionIndex + 1),
        ],
      }
    })
  }

  const onSectionRemove = (sectionIndex: number) => {
    updateInMemoryExam((prev) => {
      if (!prev?.sections[sectionIndex]) {
        return prev
      }

      const { sections, ...rest } = prev
      return {
        ...rest,
        sections: [...sections.slice(0, sectionIndex), ...sections.slice(sectionIndex + 1)],
      }
    })
  }

  const onRefresh = () => {
    if (activeVisit?.exam) {
      updateInMemoryExam(activeVisit.exam)
    }
  }

  const onSave = () => {
    if (isLoading) {
      return
    }

    onSubmit(inMemoryExam)
  }

  React.useEffect(() => {
    if (activeVisit?.exam) {
      updateInMemoryExam(activeVisit.exam)
    }
  }, [activeVisit])

  const value: ExamEditorContextValue = {
    patientId,
    visitId,
    exam: inMemoryExam,
    onChoiceSelect,
    onDetailsSelect,
    onSectionRemove,
    onSectionNoteChange,
    onSave,
    onRefresh,
    isLoading,
    error,
    onErrorClose,
  }

  return <ExamEditorContext.Provider value={value}>{props.children}</ExamEditorContext.Provider>
}
