import React from 'react'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'
import Select from 'react-select'
import { ReactSelectInternalSingleValue, SingleSelectorProps, useChakraThemeParser } from '.'
import { HowLong } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { isValidHowLong } from '../ActiveVisit'

/**
 * Record will force us to add the translation
 * every time we updated the HowLong list
 */
const howLongTranslations: Record<HowLong, MessageDescriptor> = {
  [HowLong.JUST_NOW]: {
    id: 'ChiefComplaint.how_long_just_now',
    defaultMessage: 'Just now',
  },
  [HowLong.AN_HOUR]: {
    id: 'ChiefComplaint.how_long_an_hour',
    defaultMessage: 'An hour',
  },
  [HowLong.FEW_HOURS]: {
    id: 'ChiefComplaint.how_long_few_hours',
    defaultMessage: 'Few hours',
  },
  [HowLong.SEVERAL_HOURS]: {
    id: 'ChiefComplaint.how_long_several_hours',
    defaultMessage: 'Several hours',
  },
  [HowLong.A_DAY]: {
    id: 'ChiefComplaint.how_long_a_day',
    defaultMessage: 'A day',
  },
  [HowLong.FEW_DAYS]: {
    id: 'ChiefComplaint.how_long_few_days',
    defaultMessage: 'Few days',
  },
  [HowLong.SEVERAL_DAYS]: {
    id: 'ChiefComplaint.how_long_several_days',
    defaultMessage: 'Several days',
  },
  [HowLong.A_WEEK]: {
    id: 'ChiefComplaint.how_long_a_week',
    defaultMessage: 'A week',
  },
  [HowLong.FEW_WEEKS]: {
    id: 'ChiefComplaint.how_long_few_weeks',
    defaultMessage: 'Few weeks',
  },
  [HowLong.SEVERAL_WEEKS]: {
    id: 'ChiefComplaint.how_long_several_weeks',
    defaultMessage: 'Several weeks',
  },
  [HowLong.A_MONTH]: {
    id: 'ChiefComplaint.how_long_a_month',
    defaultMessage: 'A month',
  },
  [HowLong.FEW_MONTHS]: {
    id: 'ChiefComplaint.how_long_few_months',
    defaultMessage: 'Few months',
  },
  [HowLong.SEVERAL_MONTHS]: {
    id: 'ChiefComplaint.how_long_several_months',
    defaultMessage: 'Several months',
  },
  [HowLong.A_YEAR]: {
    id: 'ChiefComplaint.how_long_a_year',
    defaultMessage: 'A year',
  },
  [HowLong.FEW_YEARS]: {
    id: 'ChiefComplaint.how_long_few_years',
    defaultMessage: 'Few years',
  },
  [HowLong.SEVERAL_YEARS]: {
    id: 'ChiefComplaint.how_long_several_years',
    defaultMessage: 'Several years',
  },
}

export const howLongMessages = defineMessages(howLongTranslations)

export type HowLongSelectorProps = SingleSelectorProps<HowLong>

export const HowLongSelector: React.FC<HowLongSelectorProps> = (props) => {
  const { value } = props

  const intl = useIntl()
  const theme = useChakraThemeParser()

  const formatOption = (howLong: HowLong): ReactSelectInternalSingleValue => {
    return {
      label: intl.formatMessage(howLongMessages[howLong]),
      value: howLong,
    }
  }

  const options = Object.values(HowLong).map(formatOption)

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalSingleValue> => {
    if (options === null) {
      return null
    }

    if (isValidHowLong(value as string)) {
      return formatOption(value as HowLong)
    }

    return null
  }

  const _onChange = (value) => {
    if (!value) {
      props.onChange('' as HowLong)
      return
    }

    const selectedValue = value
    props.onChange(selectedValue.value as HowLong)
  }

  return (
    <Select
      options={options}
      value={parseValue(value)}
      onChange={_onChange}
      isLoading={props.isLoading}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      aria-invalid={props.isInvalid}
      placeholder={props.placeholder}
      blurInputOnSelect={props.blurInputOnSelect}
      theme={theme}
    />
  )
}
