import React from 'react'
import {
  Button,
  HStack,
  Icon,
  Link,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Image,
  VStack,
  useBreakpointValue,
  Box,
  BoxProps,
  useColorMode,
  useTheme,
} from '@chakra-ui/react'
import { RiCopyrightLine as CopyrightIcon } from 'react-icons/ri'
import { defineMessages } from '@formatjs/intl'
import { useIntl } from 'react-intl'
import { RiInstagramLine as InstagramIcon, RiMailLine as MailIcon } from 'react-icons/ri'
import Logo from '../../assets/images/wd-2.png'

const messages = defineMessages({
  contact: {
    id: 'UI.contact_label',
    defaultMessage: 'Contact us',
  },
})

/**
 * should I move this to envs?
 */
const CONTACT = {
  instagram: 'walking_doctors',
  mail: 'hello@walkingdocs.com',
}

export const FOOTER_HEIGHT = 48
export const Footer: React.FC<BoxProps> = (props) => {
  const isSmallScreen = useBreakpointValue({ base: true, sm: false })
  const { colorMode } = useColorMode()
  const { colors } = useTheme()

  const background = colorMode === 'light' ? colors.white : colors.gray['900']
  const color = colorMode === 'light' ? colors.black : colors.white
  const borderTopColor = colorMode === 'light' ? colors.gray['300'] : colors.gray['700']

  const boxProps = {
    ...props,
    height: `${FOOTER_HEIGHT}px`,
    background,
    color,
  }
  return (
    <Box {...boxProps}>
      <HStack
        px={4}
        py={2}
        borderTopColor={borderTopColor}
        borderTopWidth={1}
        justifyContent={'space-between'}
        alignItems="center"
      >
        <Image src={Logo} alt="logo" maxH={'32px'} />
        {!isSmallScreen && (
          <HStack>
            <Text>Copyright</Text>
            <Icon as={CopyrightIcon} />
            <Text>{new Date().getFullYear()}</Text>
            <Link isExternal href="http://walkingdocs.org">
              WD
            </Link>
          </HStack>
        )}
        <ContactUs />
      </HStack>
    </Box>
  )
}

const ContactUs: React.VFC = () => {
  const intl = useIntl()

  return (
    <Box>
      <Popover gutter={10} size="sm" computePositionOnMount>
        <PopoverTrigger>
          <Button size="sm" colorScheme={'brand'} variant="ghost">
            <Text>{intl.formatMessage(messages.contact)}</Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent width={250}>
          <PopoverArrow />
          <PopoverBody>
            <VStack spacing={2}>
              <Link
                href={`https://www.instagram.com/${CONTACT.instagram}`}
                isExternal
                display={'flex'}
                alignItems={'center'}
                p={1}
              >
                <Icon as={InstagramIcon} fontSize={'xl'} mr={2} />
                <Text>{CONTACT.instagram}</Text>
              </Link>
              <Link href={`mailto:${CONTACT.mail}`} isExternal display={'flex'} alignItems={'center'} p={1}>
                <Icon as={MailIcon} fontSize={'xl'} mr={2} />
                <Text>{CONTACT.mail}</Text>
              </Link>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
