import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FollowUp, FullPatientWithActiveVisit } from '../../api/interfaces'
import { UPDATE_FOLLOW_UP } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  invalidSignatureError: {
    id: 'StatusAndDisposition.start_exam_invalid_signature_error',
    defaultMessage: 'We could not validate your identity. Refresh the page and try again.',
  },
  invalidVisitError: {
    id: 'StatusAndDisposition.start_exam_invalid_visit_error',
    defaultMessage: 'This visit id is not valid. Refresh the page and try again.',
  },
  invalidFollowUp: {
    id: 'StatusAndDisposition.invalid_follow_up_value',
    defaultMessage: 'Please, select a valid follow up value.',
  },
  errorUpdatingFollowUp: {
    id: 'StatusAndDisposition.error_updating_follow_)up',
    defaultMessage: 'Could not update follow up. Please, try again.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface UpdateFollowUpVariables {
  patientId: string
  visitId: string
  followUp: FollowUp
}

interface UpdateFollowUpMutationData {
  updateFollowUp: FullPatientWithActiveVisit
}

type Params = MutationHookOptions<UpdateFollowUpMutationData, UpdateFollowUpVariables> & {
  patientId: string
  visitId: string
}

export const useUpdateFollowUp = (params: Params) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'error_invalid_signature':
        return setError(intl.formatMessage(messages.invalidSignatureError))

      case 'patient_not_found':
      case 'visit_not_found':
        return setError(intl.formatMessage(messages.invalidVisitError))

      case 'invalid_follow_up':
        return setError(intl.formatMessage(messages.invalidFollowUp))

      case 'error_updating_follow_up':
        return setError(intl.formatMessage(messages.errorUpdatingFollowUp))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [updateFollowUp, { loading: isLoading }] = useMutation(UPDATE_FOLLOW_UP, {
    onError,
    ...restParams,
  })

  const onFollowUpChange = (newFollowUp: FollowUp) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      followUp: newFollowUp,
    }

    updateFollowUp({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onFollowUpChange,
    isLoading,
    error,
    onErrorClose,
  }
}
