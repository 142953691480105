// Child growth standards
// Weight reference values for BOYS between 0 - 120 months (10 years)
// Data taken from World Health Organization and then parsed to JSON
// Links:
// https://www.who.int/childgrowth/standards/tab_wfa_boys_p_0_13.txt (0 - 13 weeks)
// https://www.who.int/childgrowth/standards/tab_wfa_boys_p_0_5.txt  (0 - 60 MOnths)
// https://www.who.int/growthref/wfa_boys_perc_WHO2007_exp.txt       (61 - 120 Months)

const weightValues = [
  {
    week: 0,
    l: 0.3487,
    m: 3.3464,
    s: 0.14602,
    p01: 2,
    p1: 2.3,
    p3: 2.5,
    p5: 2.6,
    p10: 2.8,
    p15: 2.9,
    p25: 3,
    p50: 3.3,
    p75: 3.7,
    p85: 3.9,
    p90: 4,
    p95: 4.2,
    p97: 4.3,
    p99: 4.6,
    p999: 5.1
  },
  {
    week: 1,
    l: 0.2776,
    m: 3.4879,
    s: 0.14483,
    p01: 2.2,
    p1: 2.4,
    p3: 2.6,
    p5: 2.7,
    p10: 2.9,
    p15: 3,
    p25: 3.2,
    p50: 3.5,
    p75: 3.8,
    p85: 4,
    p90: 4.2,
    p95: 4.4,
    p97: 4.5,
    p99: 4.8,
    p999: 5.3
  },
  {
    week: 2,
    l: 0.2581,
    m: 3.7529,
    s: 0.14142,
    p01: 2.4,
    p1: 2.7,
    p3: 2.8,
    p5: 3,
    p10: 3.1,
    p15: 3.2,
    p25: 3.4,
    p50: 3.8,
    p75: 4.1,
    p85: 4.3,
    p90: 4.5,
    p95: 4.7,
    p97: 4.9,
    p99: 5.1,
    p999: 5.7
  },
  {
    week: 3,
    l: 0.2442,
    m: 4.0603,
    s: 0.13807,
    p01: 2.6,
    p1: 2.9,
    p3: 3.1,
    p5: 3.2,
    p10: 3.4,
    p15: 3.5,
    p25: 3.7,
    p50: 4.1,
    p75: 4.5,
    p85: 4.7,
    p90: 4.8,
    p95: 5.1,
    p97: 5.2,
    p99: 5.5,
    p999: 6.1
  },
  {
    week: 4,
    l: 0.2331,
    m: 4.3671,
    s: 0.13497,
    p01: 2.8,
    p1: 3.2,
    p3: 3.4,
    p5: 3.5,
    p10: 3.7,
    p15: 3.8,
    p25: 4,
    p50: 4.4,
    p75: 4.8,
    p85: 5,
    p90: 5.2,
    p95: 5.4,
    p97: 5.6,
    p99: 5.9,
    p999: 6.5
  },
  {
    week: 5,
    l: 0.2237,
    m: 4.659,
    s: 0.13215,
    p01: 3,
    p1: 3.4,
    p3: 3.6,
    p5: 3.7,
    p10: 3.9,
    p15: 4.1,
    p25: 4.3,
    p50: 4.7,
    p75: 5.1,
    p85: 5.3,
    p90: 5.5,
    p95: 5.8,
    p97: 5.9,
    p99: 6.3,
    p999: 6.9
  },
  {
    week: 6,
    l: 0.2155,
    m: 4.9303,
    s: 0.1296,
    p01: 3.2,
    p1: 3.6,
    p3: 3.8,
    p5: 4,
    p10: 4.2,
    p15: 4.3,
    p25: 4.5,
    p50: 4.9,
    p75: 5.4,
    p85: 5.6,
    p90: 5.8,
    p95: 6.1,
    p97: 6.3,
    p99: 6.6,
    p999: 7.2
  },
  {
    week: 7,
    l: 0.2081,
    m: 5.1817,
    s: 0.12729,
    p01: 3.4,
    p1: 3.8,
    p3: 4.1,
    p5: 4.2,
    p10: 4.4,
    p15: 4.5,
    p25: 4.8,
    p50: 5.2,
    p75: 5.6,
    p85: 5.9,
    p90: 6.1,
    p95: 6.4,
    p97: 6.5,
    p99: 6.9,
    p999: 7.6
  },
  {
    week: 8,
    l: 0.2014,
    m: 5.4149,
    s: 0.1252,
    p01: 3.6,
    p1: 4,
    p3: 4.3,
    p5: 4.4,
    p10: 4.6,
    p15: 4.7,
    p25: 5,
    p50: 5.4,
    p75: 5.9,
    p85: 6.2,
    p90: 6.3,
    p95: 6.6,
    p97: 6.8,
    p99: 7.2,
    p999: 7.9
  },
  {
    week: 9,
    l: 0.1952,
    m: 5.6319,
    s: 0.1233,
    p01: 3.8,
    p1: 4.2,
    p3: 4.4,
    p5: 4.6,
    p10: 4.8,
    p15: 4.9,
    p25: 5.2,
    p50: 5.6,
    p75: 6.1,
    p85: 6.4,
    p90: 6.6,
    p95: 6.9,
    p97: 7.1,
    p99: 7.4,
    p999: 8.1
  },
  {
    week: 10,
    l: 0.1894,
    m: 5.8346,
    s: 0.12157,
    p01: 4,
    p1: 4.4,
    p3: 4.6,
    p5: 4.8,
    p10: 5,
    p15: 5.1,
    p25: 5.4,
    p50: 5.8,
    p75: 6.3,
    p85: 6.6,
    p90: 6.8,
    p95: 7.1,
    p97: 7.3,
    p99: 7.7,
    p999: 8.4
  },
  {
    week: 11,
    l: 0.184,
    m: 6.0242,
    s: 0.12001,
    p01: 4.1,
    p1: 4.5,
    p3: 4.8,
    p5: 4.9,
    p10: 5.2,
    p15: 5.3,
    p25: 5.6,
    p50: 6,
    p75: 6.5,
    p85: 6.8,
    p90: 7,
    p95: 7.3,
    p97: 7.5,
    p99: 7.9,
    p999: 8.6
  },
  {
    week: 12,
    l: 0.1789,
    m: 6.2019,
    s: 0.1186,
    p01: 4.2,
    p1: 4.7,
    p3: 4.9,
    p5: 5.1,
    p10: 5.3,
    p15: 5.5,
    p25: 5.7,
    p50: 6.2,
    p75: 6.7,
    p85: 7,
    p90: 7.2,
    p95: 7.5,
    p97: 7.7,
    p99: 8.1,
    p999: 8.8
  },
  {
    week: 13,
    l: 0.174,
    m: 6.369,
    s: 0.11732,
    p01: 4.4,
    p1: 4.8,
    p3: 5.1,
    p5: 5.2,
    p10: 5.5,
    p15: 5.6,
    p25: 5.9,
    p50: 6.4,
    p75: 6.9,
    p85: 7.2,
    p90: 7.4,
    p95: 7.7,
    p97: 7.9,
    p99: 8.3,
    p999: 9.1
  },
  {
    month: 0,
    l: 0.3487,
    m: 3.3464,
    s: 0.14602,
    p01: 2,
    p1: 2.3,
    p3: 2.5,
    p5: 2.6,
    p10: 2.8,
    p15: 2.9,
    p25: 3,
    p50: 3.3,
    p75: 3.7,
    p85: 3.9,
    p90: 4,
    p95: 4.2,
    p97: 4.3,
    p99: 4.6,
    p999: 5.1
  },
  {
    month: 1,
    l: 0.2297,
    m: 4.4709,
    s: 0.13395,
    p01: 2.9,
    p1: 3.2,
    p3: 3.4,
    p5: 3.6,
    p10: 3.8,
    p15: 3.9,
    p25: 4.1,
    p50: 4.5,
    p75: 4.9,
    p85: 5.1,
    p90: 5.3,
    p95: 5.5,
    p97: 5.7,
    p99: 6,
    p999: 6.6
  },
  {
    month: 2,
    l: 0.197,
    m: 5.5675,
    s: 0.12385,
    p01: 3.7,
    p1: 4.1,
    p3: 4.4,
    p5: 4.5,
    p10: 4.7,
    p15: 4.9,
    p25: 5.1,
    p50: 5.6,
    p75: 6,
    p85: 6.3,
    p90: 6.5,
    p95: 6.8,
    p97: 7,
    p99: 7.4,
    p999: 8.1
  },
  {
    month: 3,
    l: 0.1738,
    m: 6.3762,
    s: 0.11727,
    p01: 4.4,
    p1: 4.8,
    p3: 5.1,
    p5: 5.2,
    p10: 5.5,
    p15: 5.6,
    p25: 5.9,
    p50: 6.4,
    p75: 6.9,
    p85: 7.2,
    p90: 7.4,
    p95: 7.7,
    p97: 7.9,
    p99: 8.3,
    p999: 9.1
  },
  {
    month: 4,
    l: 0.1553,
    m: 7.0023,
    s: 0.11316,
    p01: 4.9,
    p1: 5.4,
    p3: 5.6,
    p5: 5.8,
    p10: 6,
    p15: 6.2,
    p25: 6.5,
    p50: 7,
    p75: 7.6,
    p85: 7.9,
    p90: 8.1,
    p95: 8.4,
    p97: 8.6,
    p99: 9.1,
    p999: 9.8
  },
  {
    month: 5,
    l: 0.1395,
    m: 7.5105,
    s: 0.1108,
    p01: 5.3,
    p1: 5.8,
    p3: 6.1,
    p5: 6.2,
    p10: 6.5,
    p15: 6.7,
    p25: 7,
    p50: 7.5,
    p75: 8.1,
    p85: 8.4,
    p90: 8.6,
    p95: 9,
    p97: 9.2,
    p99: 9.7,
    p999: 10.5
  },
  {
    month: 6,
    l: 0.1257,
    m: 7.934,
    s: 0.10958,
    p01: 5.6,
    p1: 6.1,
    p3: 6.4,
    p5: 6.6,
    p10: 6.9,
    p15: 7.1,
    p25: 7.4,
    p50: 7.9,
    p75: 8.5,
    p85: 8.9,
    p90: 9.1,
    p95: 9.5,
    p97: 9.7,
    p99: 10.2,
    p999: 11.1
  },
  {
    month: 7,
    l: 0.1134,
    m: 8.297,
    s: 0.10902,
    p01: 5.9,
    p1: 6.4,
    p3: 6.7,
    p5: 6.9,
    p10: 7.2,
    p15: 7.4,
    p25: 7.7,
    p50: 8.3,
    p75: 8.9,
    p85: 9.3,
    p90: 9.5,
    p95: 9.9,
    p97: 10.2,
    p99: 10.7,
    p999: 11.5
  },
  {
    month: 8,
    l: 0.1021,
    m: 8.6151,
    s: 0.10882,
    p01: 6.1,
    p1: 6.7,
    p3: 7,
    p5: 7.2,
    p10: 7.5,
    p15: 7.7,
    p25: 8,
    p50: 8.6,
    p75: 9.3,
    p85: 9.6,
    p90: 9.9,
    p95: 10.3,
    p97: 10.5,
    p99: 11.1,
    p999: 12
  },
  {
    month: 9,
    l: 0.0917,
    m: 8.9014,
    s: 0.10881,
    p01: 6.3,
    p1: 6.9,
    p3: 7.2,
    p5: 7.4,
    p10: 7.7,
    p15: 7.9,
    p25: 8.3,
    p50: 8.9,
    p75: 9.6,
    p85: 10,
    p90: 10.2,
    p95: 10.6,
    p97: 10.9,
    p99: 11.4,
    p999: 12.4
  },
  {
    month: 10,
    l: 0.082,
    m: 9.1649,
    s: 0.10891,
    p01: 6.5,
    p1: 7.1,
    p3: 7.5,
    p5: 7.7,
    p10: 8,
    p15: 8.2,
    p25: 8.5,
    p50: 9.2,
    p75: 9.9,
    p85: 10.3,
    p90: 10.5,
    p95: 10.9,
    p97: 11.2,
    p99: 11.8,
    p999: 12.8
  },
  {
    month: 11,
    l: 0.073,
    m: 9.4122,
    s: 0.10906,
    p01: 6.7,
    p1: 7.3,
    p3: 7.7,
    p5: 7.9,
    p10: 8.2,
    p15: 8.4,
    p25: 8.7,
    p50: 9.4,
    p75: 10.1,
    p85: 10.5,
    p90: 10.8,
    p95: 11.2,
    p97: 11.5,
    p99: 12.1,
    p999: 13.1
  },
  {
    month: 12,
    l: 0.0644,
    m: 9.6479,
    s: 0.10925,
    p01: 6.9,
    p1: 7.5,
    p3: 7.8,
    p5: 8.1,
    p10: 8.4,
    p15: 8.6,
    p25: 9,
    p50: 9.6,
    p75: 10.4,
    p85: 10.8,
    p90: 11.1,
    p95: 11.5,
    p97: 11.8,
    p99: 12.4,
    p999: 13.5
  },
  {
    month: 13,
    l: 0.0563,
    m: 9.8749,
    s: 0.10949,
    p01: 7,
    p1: 7.6,
    p3: 8,
    p5: 8.2,
    p10: 8.6,
    p15: 8.8,
    p25: 9.2,
    p50: 9.9,
    p75: 10.6,
    p85: 11.1,
    p90: 11.4,
    p95: 11.8,
    p97: 12.1,
    p99: 12.7,
    p999: 13.8
  },
  {
    month: 14,
    l: 0.0487,
    m: 10.0953,
    s: 0.10976,
    p01: 7.2,
    p1: 7.8,
    p3: 8.2,
    p5: 8.4,
    p10: 8.8,
    p15: 9,
    p25: 9.4,
    p50: 10.1,
    p75: 10.9,
    p85: 11.3,
    p90: 11.6,
    p95: 12.1,
    p97: 12.4,
    p99: 13,
    p999: 14.1
  },
  {
    month: 15,
    l: 0.0413,
    m: 10.3108,
    s: 0.11007,
    p01: 7.3,
    p1: 8,
    p3: 8.4,
    p5: 8.6,
    p10: 9,
    p15: 9.2,
    p25: 9.6,
    p50: 10.3,
    p75: 11.1,
    p85: 11.6,
    p90: 11.9,
    p95: 12.3,
    p97: 12.7,
    p99: 13.3,
    p999: 14.5
  },
  {
    month: 16,
    l: 0.0343,
    m: 10.5228,
    s: 0.11041,
    p01: 7.5,
    p1: 8.1,
    p3: 8.5,
    p5: 8.8,
    p10: 9.1,
    p15: 9.4,
    p25: 9.8,
    p50: 10.5,
    p75: 11.3,
    p85: 11.8,
    p90: 12.1,
    p95: 12.6,
    p97: 12.9,
    p99: 13.6,
    p999: 14.8
  },
  {
    month: 17,
    l: 0.0275,
    m: 10.7319,
    s: 0.11079,
    p01: 7.6,
    p1: 8.3,
    p3: 8.7,
    p5: 8.9,
    p10: 9.3,
    p15: 9.6,
    p25: 10,
    p50: 10.7,
    p75: 11.6,
    p85: 12,
    p90: 12.4,
    p95: 12.9,
    p97: 13.2,
    p99: 13.9,
    p999: 15.1
  },
  {
    month: 18,
    l: 0.0211,
    m: 10.9385,
    s: 0.11119,
    p01: 7.7,
    p1: 8.4,
    p3: 8.9,
    p5: 9.1,
    p10: 9.5,
    p15: 9.7,
    p25: 10.1,
    p50: 10.9,
    p75: 11.8,
    p85: 12.3,
    p90: 12.6,
    p95: 13.1,
    p97: 13.5,
    p99: 14.2,
    p999: 15.4
  },
  {
    month: 19,
    l: 0.0148,
    m: 11.143,
    s: 0.11164,
    p01: 7.9,
    p1: 8.6,
    p3: 9,
    p5: 9.3,
    p10: 9.7,
    p15: 9.9,
    p25: 10.3,
    p50: 11.1,
    p75: 12,
    p85: 12.5,
    p90: 12.9,
    p95: 13.4,
    p97: 13.7,
    p99: 14.4,
    p999: 15.7
  },
  {
    month: 20,
    l: 0.0087,
    m: 11.3462,
    s: 0.11211,
    p01: 8,
    p1: 8.7,
    p3: 9.2,
    p5: 9.4,
    p10: 9.8,
    p15: 10.1,
    p25: 10.5,
    p50: 11.3,
    p75: 12.2,
    p85: 12.7,
    p90: 13.1,
    p95: 13.6,
    p97: 14,
    p99: 14.7,
    p999: 16
  },
  {
    month: 21,
    l: 0.0029,
    m: 11.5486,
    s: 0.11261,
    p01: 8.2,
    p1: 8.9,
    p3: 9.3,
    p5: 9.6,
    p10: 10,
    p15: 10.3,
    p25: 10.7,
    p50: 11.5,
    p75: 12.5,
    p85: 13,
    p90: 13.3,
    p95: 13.9,
    p97: 14.3,
    p99: 15,
    p999: 16.4
  },
  {
    month: 22,
    l: -0.0028,
    m: 11.7504,
    s: 0.11314,
    p01: 8.3,
    p1: 9,
    p3: 9.5,
    p5: 9.8,
    p10: 10.2,
    p15: 10.5,
    p25: 10.9,
    p50: 11.8,
    p75: 12.7,
    p85: 13.2,
    p90: 13.6,
    p95: 14.2,
    p97: 14.5,
    p99: 15.3,
    p999: 16.7
  },
  {
    month: 23,
    l: -0.0083,
    m: 11.9514,
    s: 0.11369,
    p01: 8.4,
    p1: 9.2,
    p3: 9.7,
    p5: 9.9,
    p10: 10.3,
    p15: 10.6,
    p25: 11.1,
    p50: 12,
    p75: 12.9,
    p85: 13.4,
    p90: 13.8,
    p95: 14.4,
    p97: 14.8,
    p99: 15.6,
    p999: 17
  },
  {
    month: 24,
    l: -0.0137,
    m: 12.1515,
    s: 0.11426,
    p01: 8.5,
    p1: 9.3,
    p3: 9.8,
    p5: 10.1,
    p10: 10.5,
    p15: 10.8,
    p25: 11.3,
    p50: 12.2,
    p75: 13.1,
    p85: 13.7,
    p90: 14.1,
    p95: 14.7,
    p97: 15.1,
    p99: 15.9,
    p999: 17.3
  },
  {
    month: 25,
    l: -0.0189,
    m: 12.3502,
    s: 0.11485,
    p01: 8.7,
    p1: 9.5,
    p3: 10,
    p5: 10.2,
    p10: 10.7,
    p15: 11,
    p25: 11.4,
    p50: 12.4,
    p75: 13.3,
    p85: 13.9,
    p90: 14.3,
    p95: 14.9,
    p97: 15.3,
    p99: 16.1,
    p999: 17.6
  },
  {
    month: 26,
    l: -0.024,
    m: 12.5466,
    s: 0.11544,
    p01: 8.8,
    p1: 9.6,
    p3: 10.1,
    p5: 10.4,
    p10: 10.8,
    p15: 11.1,
    p25: 11.6,
    p50: 12.5,
    p75: 13.6,
    p85: 14.1,
    p90: 14.6,
    p95: 15.2,
    p97: 15.6,
    p99: 16.4,
    p999: 18
  },
  {
    month: 27,
    l: -0.0289,
    m: 12.7401,
    s: 0.11604,
    p01: 8.9,
    p1: 9.7,
    p3: 10.2,
    p5: 10.5,
    p10: 11,
    p15: 11.3,
    p25: 11.8,
    p50: 12.7,
    p75: 13.8,
    p85: 14.4,
    p90: 14.8,
    p95: 15.4,
    p97: 15.9,
    p99: 16.7,
    p999: 18.3
  },
  {
    month: 28,
    l: -0.0337,
    m: 12.9303,
    s: 0.11664,
    p01: 9,
    p1: 9.9,
    p3: 10.4,
    p5: 10.7,
    p10: 11.1,
    p15: 11.5,
    p25: 12,
    p50: 12.9,
    p75: 14,
    p85: 14.6,
    p90: 15,
    p95: 15.7,
    p97: 16.1,
    p99: 17,
    p999: 18.6
  },
  {
    month: 29,
    l: -0.0385,
    m: 13.1169,
    s: 0.11723,
    p01: 9.2,
    p1: 10,
    p3: 10.5,
    p5: 10.8,
    p10: 11.3,
    p15: 11.6,
    p25: 12.1,
    p50: 13.1,
    p75: 14.2,
    p85: 14.8,
    p90: 15.2,
    p95: 15.9,
    p97: 16.4,
    p99: 17.3,
    p999: 18.9
  },
  {
    month: 30,
    l: -0.0431,
    m: 13.3,
    s: 0.11781,
    p01: 9.3,
    p1: 10.1,
    p3: 10.7,
    p5: 11,
    p10: 11.4,
    p15: 11.8,
    p25: 12.3,
    p50: 13.3,
    p75: 14.4,
    p85: 15,
    p90: 15.5,
    p95: 16.2,
    p97: 16.6,
    p99: 17.5,
    p999: 19.2
  },
  {
    month: 31,
    l: -0.0476,
    m: 13.4798,
    s: 0.11839,
    p01: 9.4,
    p1: 10.3,
    p3: 10.8,
    p5: 11.1,
    p10: 11.6,
    p15: 11.9,
    p25: 12.4,
    p50: 13.5,
    p75: 14.6,
    p85: 15.2,
    p90: 15.7,
    p95: 16.4,
    p97: 16.9,
    p99: 17.8,
    p999: 19.5
  },
  {
    month: 32,
    l: -0.052,
    m: 13.6567,
    s: 0.11896,
    p01: 9.5,
    p1: 10.4,
    p3: 10.9,
    p5: 11.2,
    p10: 11.7,
    p15: 12.1,
    p25: 12.6,
    p50: 13.7,
    p75: 14.8,
    p85: 15.5,
    p90: 15.9,
    p95: 16.6,
    p97: 17.1,
    p99: 18,
    p999: 19.8
  },
  {
    month: 33,
    l: -0.0564,
    m: 13.8309,
    s: 0.11953,
    p01: 9.6,
    p1: 10.5,
    p3: 11.1,
    p5: 11.4,
    p10: 11.9,
    p15: 12.2,
    p25: 12.8,
    p50: 13.8,
    p75: 15,
    p85: 15.7,
    p90: 16.1,
    p95: 16.9,
    p97: 17.3,
    p99: 18.3,
    p999: 20.1
  },
  {
    month: 34,
    l: -0.0606,
    m: 14.0031,
    s: 0.12008,
    p01: 9.7,
    p1: 10.6,
    p3: 11.2,
    p5: 11.5,
    p10: 12,
    p15: 12.4,
    p25: 12.9,
    p50: 14,
    p75: 15.2,
    p85: 15.9,
    p90: 16.3,
    p95: 17.1,
    p97: 17.6,
    p99: 18.6,
    p999: 20.4
  },
  {
    month: 35,
    l: -0.0648,
    m: 14.1736,
    s: 0.12062,
    p01: 9.8,
    p1: 10.7,
    p3: 11.3,
    p5: 11.6,
    p10: 12.2,
    p15: 12.5,
    p25: 13.1,
    p50: 14.2,
    p75: 15.4,
    p85: 16.1,
    p90: 16.6,
    p95: 17.3,
    p97: 17.8,
    p99: 18.8,
    p999: 20.7
  },
  {
    month: 36,
    l: -0.0689,
    m: 14.3429,
    s: 0.12116,
    p01: 9.9,
    p1: 10.8,
    p3: 11.4,
    p5: 11.8,
    p10: 12.3,
    p15: 12.7,
    p25: 13.2,
    p50: 14.3,
    p75: 15.6,
    p85: 16.3,
    p90: 16.8,
    p95: 17.5,
    p97: 18,
    p99: 19.1,
    p999: 21
  },
  {
    month: 37,
    l: -0.0729,
    m: 14.5113,
    s: 0.12168,
    p01: 10,
    p1: 11,
    p3: 11.6,
    p5: 11.9,
    p10: 12.4,
    p15: 12.8,
    p25: 13.4,
    p50: 14.5,
    p75: 15.8,
    p85: 16.5,
    p90: 17,
    p95: 17.8,
    p97: 18.3,
    p99: 19.3,
    p999: 21.2
  },
  {
    month: 38,
    l: -0.0769,
    m: 14.6791,
    s: 0.1222,
    p01: 10.1,
    p1: 11.1,
    p3: 11.7,
    p5: 12,
    p10: 12.6,
    p15: 12.9,
    p25: 13.5,
    p50: 14.7,
    p75: 15.9,
    p85: 16.7,
    p90: 17.2,
    p95: 18,
    p97: 18.5,
    p99: 19.6,
    p999: 21.5
  },
  {
    month: 39,
    l: -0.0808,
    m: 14.8466,
    s: 0.12271,
    p01: 10.2,
    p1: 11.2,
    p3: 11.8,
    p5: 12.2,
    p10: 12.7,
    p15: 13.1,
    p25: 13.7,
    p50: 14.8,
    p75: 16.1,
    p85: 16.9,
    p90: 17.4,
    p95: 18.2,
    p97: 18.7,
    p99: 19.8,
    p999: 21.8
  },
  {
    month: 40,
    l: -0.0846,
    m: 15.014,
    s: 0.12322,
    p01: 10.3,
    p1: 11.3,
    p3: 11.9,
    p5: 12.3,
    p10: 12.8,
    p15: 13.2,
    p25: 13.8,
    p50: 15,
    p75: 16.3,
    p85: 17.1,
    p90: 17.6,
    p95: 18.4,
    p97: 19,
    p99: 20.1,
    p999: 22.1
  },
  {
    month: 41,
    l: -0.0883,
    m: 15.1813,
    s: 0.12373,
    p01: 10.4,
    p1: 11.4,
    p3: 12.1,
    p5: 12.4,
    p10: 13,
    p15: 13.4,
    p25: 14,
    p50: 15.2,
    p75: 16.5,
    p85: 17.3,
    p90: 17.8,
    p95: 18.6,
    p97: 19.2,
    p99: 20.3,
    p999: 22.4
  },
  {
    month: 42,
    l: -0.092,
    m: 15.3486,
    s: 0.12425,
    p01: 10.5,
    p1: 11.5,
    p3: 12.2,
    p5: 12.5,
    p10: 13.1,
    p15: 13.5,
    p25: 14.1,
    p50: 15.3,
    p75: 16.7,
    p85: 17.5,
    p90: 18,
    p95: 18.9,
    p97: 19.4,
    p99: 20.6,
    p999: 22.7
  },
  {
    month: 43,
    l: -0.0957,
    m: 15.5158,
    s: 0.12478,
    p01: 10.6,
    p1: 11.7,
    p3: 12.3,
    p5: 12.7,
    p10: 13.2,
    p15: 13.6,
    p25: 14.3,
    p50: 15.5,
    p75: 16.9,
    p85: 17.7,
    p90: 18.2,
    p95: 19.1,
    p97: 19.7,
    p99: 20.8,
    p999: 23
  },
  {
    month: 44,
    l: -0.0993,
    m: 15.6828,
    s: 0.12531,
    p01: 10.7,
    p1: 11.8,
    p3: 12.4,
    p5: 12.8,
    p10: 13.4,
    p15: 13.8,
    p25: 14.4,
    p50: 15.7,
    p75: 17.1,
    p85: 17.9,
    p90: 18.4,
    p95: 19.3,
    p97: 19.9,
    p99: 21.1,
    p999: 23.3
  },
  {
    month: 45,
    l: -0.1028,
    m: 15.8497,
    s: 0.12586,
    p01: 10.8,
    p1: 11.9,
    p3: 12.5,
    p5: 12.9,
    p10: 13.5,
    p15: 13.9,
    p25: 14.6,
    p50: 15.8,
    p75: 17.3,
    p85: 18.1,
    p90: 18.6,
    p95: 19.5,
    p97: 20.1,
    p99: 21.3,
    p999: 23.6
  },
  {
    month: 46,
    l: -0.1063,
    m: 16.0163,
    s: 0.12643,
    p01: 10.9,
    p1: 12,
    p3: 12.7,
    p5: 13,
    p10: 13.6,
    p15: 14.1,
    p25: 14.7,
    p50: 16,
    p75: 17.4,
    p85: 18.3,
    p90: 18.9,
    p95: 19.8,
    p97: 20.4,
    p99: 21.6,
    p999: 23.9
  },
  {
    month: 47,
    l: -0.1097,
    m: 16.1827,
    s: 0.127,
    p01: 11,
    p1: 12.1,
    p3: 12.8,
    p5: 13.2,
    p10: 13.8,
    p15: 14.2,
    p25: 14.9,
    p50: 16.2,
    p75: 17.6,
    p85: 18.5,
    p90: 19.1,
    p95: 20,
    p97: 20.6,
    p99: 21.9,
    p999: 24.2
  },
  {
    month: 48,
    l: -0.1131,
    m: 16.3489,
    s: 0.12759,
    p01: 11.1,
    p1: 12.2,
    p3: 12.9,
    p5: 13.3,
    p10: 13.9,
    p15: 14.3,
    p25: 15,
    p50: 16.3,
    p75: 17.8,
    p85: 18.7,
    p90: 19.3,
    p95: 20.2,
    p97: 20.9,
    p99: 22.1,
    p999: 24.5
  },
  {
    month: 49,
    l: -0.1165,
    m: 16.515,
    s: 0.12819,
    p01: 11.2,
    p1: 12.3,
    p3: 13,
    p5: 13.4,
    p10: 14,
    p15: 14.5,
    p25: 15.2,
    p50: 16.5,
    p75: 18,
    p85: 18.9,
    p90: 19.5,
    p95: 20.4,
    p97: 21.1,
    p99: 22.4,
    p999: 24.8
  },
  {
    month: 50,
    l: -0.1198,
    m: 16.6811,
    s: 0.1288,
    p01: 11.3,
    p1: 12.4,
    p3: 13.1,
    p5: 13.5,
    p10: 14.2,
    p15: 14.6,
    p25: 15.3,
    p50: 16.7,
    p75: 18.2,
    p85: 19.1,
    p90: 19.7,
    p95: 20.7,
    p97: 21.3,
    p99: 22.6,
    p999: 25.1
  },
  {
    month: 51,
    l: -0.123,
    m: 16.8471,
    s: 0.12943,
    p01: 11.4,
    p1: 12.5,
    p3: 13.3,
    p5: 13.7,
    p10: 14.3,
    p15: 14.7,
    p25: 15.4,
    p50: 16.8,
    p75: 18.4,
    p85: 19.3,
    p90: 19.9,
    p95: 20.9,
    p97: 21.6,
    p99: 22.9,
    p999: 25.4
  },
  {
    month: 52,
    l: -0.1262,
    m: 17.0132,
    s: 0.13005,
    p01: 11.5,
    p1: 12.6,
    p3: 13.4,
    p5: 13.8,
    p10: 14.4,
    p15: 14.9,
    p25: 15.6,
    p50: 17,
    p75: 18.6,
    p85: 19.5,
    p90: 20.1,
    p95: 21.1,
    p97: 21.8,
    p99: 23.2,
    p999: 25.7
  },
  {
    month: 53,
    l: -0.1294,
    m: 17.1792,
    s: 0.13069,
    p01: 11.6,
    p1: 12.7,
    p3: 13.5,
    p5: 13.9,
    p10: 14.6,
    p15: 15,
    p25: 15.7,
    p50: 17.2,
    p75: 18.8,
    p85: 19.7,
    p90: 20.3,
    p95: 21.4,
    p97: 22.1,
    p99: 23.4,
    p999: 26
  },
  {
    month: 54,
    l: -0.1325,
    m: 17.3452,
    s: 0.13133,
    p01: 11.7,
    p1: 12.9,
    p3: 13.6,
    p5: 14,
    p10: 14.7,
    p15: 15.2,
    p25: 15.9,
    p50: 17.3,
    p75: 19,
    p85: 19.9,
    p90: 20.6,
    p95: 21.6,
    p97: 22.3,
    p99: 23.7,
    p999: 26.3
  },
  {
    month: 55,
    l: -0.1356,
    m: 17.5111,
    s: 0.13197,
    p01: 11.8,
    p1: 13,
    p3: 13.7,
    p5: 14.1,
    p10: 14.8,
    p15: 15.3,
    p25: 16,
    p50: 17.5,
    p75: 19.2,
    p85: 20.1,
    p90: 20.8,
    p95: 21.8,
    p97: 22.5,
    p99: 24,
    p999: 26.6
  },
  {
    month: 56,
    l: -0.1387,
    m: 17.6768,
    s: 0.13261,
    p01: 11.9,
    p1: 13.1,
    p3: 13.8,
    p5: 14.3,
    p10: 14.9,
    p15: 15.4,
    p25: 16.2,
    p50: 17.7,
    p75: 19.3,
    p85: 20.3,
    p90: 21,
    p95: 22.1,
    p97: 22.8,
    p99: 24.2,
    p999: 27
  },
  {
    month: 57,
    l: -0.1417,
    m: 17.8422,
    s: 0.13325,
    p01: 12,
    p1: 13.2,
    p3: 13.9,
    p5: 14.4,
    p10: 15.1,
    p15: 15.6,
    p25: 16.3,
    p50: 17.8,
    p75: 19.5,
    p85: 20.5,
    p90: 21.2,
    p95: 22.3,
    p97: 23,
    p99: 24.5,
    p999: 27.3
  },
  {
    month: 58,
    l: -0.1447,
    m: 18.0073,
    s: 0.13389,
    p01: 12,
    p1: 13.3,
    p3: 14.1,
    p5: 14.5,
    p10: 15.2,
    p15: 15.7,
    p25: 16.5,
    p50: 18,
    p75: 19.7,
    p85: 20.7,
    p90: 21.4,
    p95: 22.5,
    p97: 23.3,
    p99: 24.8,
    p999: 27.6
  },
  {
    month: 59,
    l: -0.1477,
    m: 18.1722,
    s: 0.13453,
    p01: 12.1,
    p1: 13.4,
    p3: 14.2,
    p5: 14.6,
    p10: 15.3,
    p15: 15.8,
    p25: 16.6,
    p50: 18.2,
    p75: 19.9,
    p85: 20.9,
    p90: 21.6,
    p95: 22.8,
    p97: 23.5,
    p99: 25,
    p999: 27.9
  },
  {
    month: 60,
    l: -0.1506,
    m: 18.3366,
    s: 0.13517,
    p01: 12.2,
    p1: 13.5,
    p3: 14.3,
    p5: 14.7,
    p10: 15.5,
    p15: 16,
    p25: 16.7,
    p50: 18.3,
    p75: 20.1,
    p85: 21.1,
    p90: 21.9,
    p95: 23,
    p97: 23.8,
    p99: 25.3,
    p999: 28.2
  },
  {
    month: 61,
    l: -0.2026,
    m: 18.5057,
    s: 0.12988,
    p01: 12.581,
    p1: 13.802,
    p3: 14.58,
    p5: 15.013,
    p10: 15.711,
    p15: 16.204,
    p25: 16.967,
    p50: 18.506,
    p75: 20.216,
    p85: 21.212,
    p90: 21.92,
    p95: 23.023,
    p97: 23.774,
    p99: 25.276,
    p999: 28.126
  },
  {
    month: 62,
    l: -0.213,
    m: 18.6802,
    s: 0.13028,
    p01: 12.695,
    p1: 13.926,
    p3: 14.711,
    p5: 15.149,
    p10: 15.854,
    p15: 16.352,
    p25: 17.123,
    p50: 18.68,
    p75: 20.413,
    p85: 21.423,
    p90: 22.142,
    p95: 23.262,
    p97: 24.026,
    p99: 25.553,
    p999: 28.456
  },
  {
    month: 63,
    l: -0.2234,
    m: 18.8563,
    s: 0.13067,
    p01: 12.81,
    p1: 14.052,
    p3: 14.844,
    p5: 15.286,
    p10: 15.998,
    p15: 16.501,
    p25: 17.28,
    p50: 18.856,
    p75: 20.612,
    p85: 21.636,
    p90: 22.366,
    p95: 23.503,
    p97: 24.279,
    p99: 25.833,
    p999: 28.79
  },
  {
    month: 64,
    l: -0.2338,
    m: 19.034,
    s: 0.13105,
    p01: 12.927,
    p1: 14.179,
    p3: 14.978,
    p5: 15.424,
    p10: 16.143,
    p15: 16.652,
    p25: 17.439,
    p50: 19.034,
    p75: 20.812,
    p85: 21.851,
    p90: 22.591,
    p95: 23.746,
    p97: 24.535,
    p99: 26.115,
    p999: 29.127
  },
  {
    month: 65,
    l: -0.2443,
    m: 19.2132,
    s: 0.13142,
    p01: 13.045,
    p1: 14.307,
    p3: 15.114,
    p5: 15.564,
    p10: 16.29,
    p15: 16.804,
    p25: 17.6,
    p50: 19.213,
    p75: 21.015,
    p85: 22.068,
    p90: 22.819,
    p95: 23.991,
    p97: 24.793,
    p99: 26.4,
    p999: 29.468
  },
  {
    month: 66,
    l: -0.2548,
    m: 19.394,
    s: 0.13178,
    p01: 13.164,
    p1: 14.437,
    p3: 15.251,
    p5: 15.705,
    p10: 16.438,
    p15: 16.957,
    p25: 17.762,
    p50: 19.394,
    p75: 21.218,
    p85: 22.286,
    p90: 23.048,
    p95: 24.238,
    p97: 25.053,
    p99: 26.687,
    p999: 29.812
  },
  {
    month: 67,
    l: -0.2653,
    m: 19.5765,
    s: 0.13213,
    p01: 13.285,
    p1: 14.568,
    p3: 15.389,
    p5: 15.848,
    p10: 16.588,
    p15: 17.113,
    p25: 17.926,
    p50: 19.576,
    p75: 21.424,
    p85: 22.507,
    p90: 23.28,
    p95: 24.488,
    p97: 25.315,
    p99: 26.977,
    p999: 30.159
  },
  {
    month: 68,
    l: -0.2758,
    m: 19.7607,
    s: 0.13246,
    p01: 13.408,
    p1: 14.701,
    p3: 15.53,
    p5: 15.992,
    p10: 16.74,
    p15: 17.27,
    p25: 18.091,
    p50: 19.761,
    p75: 21.632,
    p85: 22.729,
    p90: 23.513,
    p95: 24.739,
    p97: 25.58,
    p99: 27.268,
    p999: 30.509
  },
  {
    month: 69,
    l: -0.2864,
    m: 19.9468,
    s: 0.13279,
    p01: 13.532,
    p1: 14.836,
    p3: 15.672,
    p5: 16.139,
    p10: 16.893,
    p15: 17.428,
    p25: 18.259,
    p50: 19.947,
    p75: 21.841,
    p85: 22.954,
    p90: 23.749,
    p95: 24.994,
    p97: 25.847,
    p99: 27.564,
    p999: 30.865
  },
  {
    month: 70,
    l: -0.2969,
    m: 20.1344,
    s: 0.13311,
    p01: 13.658,
    p1: 14.972,
    p3: 15.815,
    p5: 16.286,
    p10: 17.048,
    p15: 17.588,
    p25: 18.427,
    p50: 20.134,
    p75: 22.053,
    p85: 23.18,
    p90: 23.986,
    p95: 25.25,
    p97: 26.117,
    p99: 27.862,
    p999: 31.223
  },
  {
    month: 71,
    l: -0.3075,
    m: 20.3235,
    s: 0.13342,
    p01: 13.785,
    p1: 15.11,
    p3: 15.959,
    p5: 16.435,
    p10: 17.204,
    p15: 17.749,
    p25: 18.597,
    p50: 20.324,
    p75: 22.265,
    p85: 23.408,
    p90: 24.226,
    p95: 25.508,
    p97: 26.388,
    p99: 28.162,
    p999: 31.584
  },
  {
    month: 72,
    l: -0.318,
    m: 20.5137,
    s: 0.13372,
    p01: 13.913,
    p1: 15.248,
    p3: 16.105,
    p5: 16.585,
    p10: 17.361,
    p15: 17.912,
    p25: 18.768,
    p50: 20.514,
    p75: 22.48,
    p85: 23.637,
    p90: 24.467,
    p95: 25.767,
    p97: 26.661,
    p99: 28.464,
    p999: 31.948
  },
  {
    month: 73,
    l: -0.3285,
    m: 20.7052,
    s: 0.13402,
    p01: 14.042,
    p1: 15.388,
    p3: 16.252,
    p5: 16.736,
    p10: 17.519,
    p15: 18.076,
    p25: 18.941,
    p50: 20.705,
    p75: 22.695,
    p85: 23.868,
    p90: 24.709,
    p95: 26.029,
    p97: 26.937,
    p99: 28.769,
    p999: 32.316
  },
  {
    month: 74,
    l: -0.339,
    m: 20.8979,
    s: 0.13432,
    p01: 14.172,
    p1: 15.528,
    p3: 16.4,
    p5: 16.888,
    p10: 17.678,
    p15: 18.24,
    p25: 19.114,
    p50: 20.898,
    p75: 22.912,
    p85: 24.101,
    p90: 24.953,
    p95: 26.293,
    p97: 27.215,
    p99: 29.077,
    p999: 32.688
  },
  {
    month: 75,
    l: -0.3494,
    m: 21.0918,
    s: 0.13462,
    p01: 14.303,
    p1: 15.669,
    p3: 16.548,
    p5: 17.041,
    p10: 17.839,
    p15: 18.406,
    p25: 19.288,
    p50: 21.092,
    p75: 23.131,
    p85: 24.335,
    p90: 25.2,
    p95: 26.558,
    p97: 27.494,
    p99: 29.387,
    p999: 33.063
  },
  {
    month: 76,
    l: -0.3598,
    m: 21.287,
    s: 0.13493,
    p01: 14.434,
    p1: 15.811,
    p3: 16.697,
    p5: 17.194,
    p10: 17.999,
    p15: 18.572,
    p25: 19.464,
    p50: 21.287,
    p75: 23.351,
    p85: 24.571,
    p90: 25.448,
    p95: 26.827,
    p97: 27.777,
    p99: 29.701,
    p999: 33.444
  },
  {
    month: 77,
    l: -0.3701,
    m: 21.4833,
    s: 0.13523,
    p01: 14.566,
    p1: 15.954,
    p3: 16.848,
    p5: 17.349,
    p10: 18.161,
    p15: 18.739,
    p25: 19.64,
    p50: 21.483,
    p75: 23.572,
    p85: 24.809,
    p90: 25.697,
    p95: 27.096,
    p97: 28.061,
    p99: 30.016,
    p999: 33.827
  },
  {
    month: 78,
    l: -0.3804,
    m: 21.681,
    s: 0.13554,
    p01: 14.699,
    p1: 16.097,
    p3: 16.999,
    p5: 17.504,
    p10: 18.324,
    p15: 18.908,
    p25: 19.818,
    p50: 21.681,
    p75: 23.795,
    p85: 25.048,
    p90: 25.949,
    p95: 27.369,
    p97: 28.349,
    p99: 30.336,
    p999: 34.216
  },
  {
    month: 79,
    l: -0.3906,
    m: 21.8799,
    s: 0.13586,
    p01: 14.832,
    p1: 16.241,
    p3: 17.15,
    p5: 17.66,
    p10: 18.488,
    p15: 19.077,
    p25: 19.996,
    p50: 21.88,
    p75: 24.02,
    p85: 25.29,
    p90: 26.203,
    p95: 27.644,
    p97: 28.639,
    p99: 30.658,
    p999: 34.61
  },
  {
    month: 80,
    l: -0.4007,
    m: 22.08,
    s: 0.13618,
    p01: 14.966,
    p1: 16.386,
    p3: 17.303,
    p5: 17.817,
    p10: 18.653,
    p15: 19.247,
    p25: 20.176,
    p50: 22.08,
    p75: 24.246,
    p85: 25.533,
    p90: 26.459,
    p95: 27.921,
    p97: 28.931,
    p99: 30.984,
    p999: 35.007
  },
  {
    month: 81,
    l: -0.4107,
    m: 22.2813,
    s: 0.13652,
    p01: 15.1,
    p1: 16.531,
    p3: 17.455,
    p5: 17.974,
    p10: 18.818,
    p15: 19.418,
    p25: 20.356,
    p50: 22.281,
    p75: 24.474,
    p85: 25.778,
    p90: 26.717,
    p95: 28.201,
    p97: 29.227,
    p99: 31.314,
    p999: 35.411
  },
  {
    month: 82,
    l: -0.4207,
    m: 22.4837,
    s: 0.13686,
    p01: 15.234,
    p1: 16.676,
    p3: 17.609,
    p5: 18.133,
    p10: 18.983,
    p15: 19.59,
    p25: 20.537,
    p50: 22.484,
    p75: 24.703,
    p85: 26.025,
    p90: 26.977,
    p95: 28.483,
    p97: 29.525,
    p99: 31.646,
    p999: 35.82
  },
  {
    month: 83,
    l: -0.4305,
    m: 22.6872,
    s: 0.13722,
    p01: 15.368,
    p1: 16.822,
    p3: 17.762,
    p5: 18.291,
    p10: 19.15,
    p15: 19.762,
    p25: 20.719,
    p50: 22.687,
    p75: 24.934,
    p85: 26.273,
    p90: 27.24,
    p95: 28.767,
    p97: 29.826,
    p99: 31.983,
    p999: 36.235
  },
  {
    month: 84,
    l: -0.4402,
    m: 22.8915,
    s: 0.13759,
    p01: 15.503,
    p1: 16.968,
    p3: 17.916,
    p5: 18.449,
    p10: 19.316,
    p15: 19.935,
    p25: 20.901,
    p50: 22.892,
    p75: 25.167,
    p85: 26.524,
    p90: 27.504,
    p95: 29.054,
    p97: 30.13,
    p99: 32.323,
    p999: 36.654
  },
  {
    month: 85,
    l: -0.4499,
    m: 23.0968,
    s: 0.13797,
    p01: 15.637,
    p1: 17.114,
    p3: 18.07,
    p5: 18.608,
    p10: 19.483,
    p15: 20.108,
    p25: 21.084,
    p50: 23.097,
    p75: 25.4,
    p85: 26.776,
    p90: 27.77,
    p95: 29.344,
    p97: 30.436,
    p99: 32.666,
    p999: 37.08
  },
  {
    month: 86,
    l: -0.4594,
    m: 23.3029,
    s: 0.13838,
    p01: 15.771,
    p1: 17.259,
    p3: 18.224,
    p5: 18.767,
    p10: 19.65,
    p15: 20.281,
    p25: 21.268,
    p50: 23.303,
    p75: 25.635,
    p85: 27.03,
    p90: 28.038,
    p95: 29.636,
    p97: 30.746,
    p99: 33.014,
    p999: 37.512
  },
  {
    month: 87,
    l: -0.4688,
    m: 23.5101,
    s: 0.1388,
    p01: 15.904,
    p1: 17.405,
    p3: 18.378,
    p5: 18.926,
    p10: 19.818,
    p15: 20.455,
    p25: 21.452,
    p50: 23.51,
    p75: 25.872,
    p85: 27.286,
    p90: 28.309,
    p95: 29.931,
    p97: 31.059,
    p99: 33.366,
    p999: 37.951
  },
  {
    month: 88,
    l: -0.4781,
    m: 23.7182,
    s: 0.13923,
    p01: 16.038,
    p1: 17.551,
    p3: 18.532,
    p5: 19.085,
    p10: 19.986,
    p15: 20.629,
    p25: 21.636,
    p50: 23.718,
    p75: 26.11,
    p85: 27.543,
    p90: 28.581,
    p95: 30.229,
    p97: 31.375,
    p99: 33.722,
    p999: 38.395
  },
  {
    month: 89,
    l: -0.4873,
    m: 23.9272,
    s: 0.13969,
    p01: 16.171,
    p1: 17.696,
    p3: 18.686,
    p5: 19.244,
    p10: 20.154,
    p15: 20.803,
    p25: 21.821,
    p50: 23.927,
    p75: 26.35,
    p85: 27.803,
    p90: 28.856,
    p95: 30.53,
    p97: 31.695,
    p99: 34.083,
    p999: 38.849
  },
  {
    month: 90,
    l: -0.4964,
    m: 24.1371,
    s: 0.14016,
    p01: 16.304,
    p1: 17.842,
    p3: 18.841,
    p5: 19.404,
    p10: 20.322,
    p15: 20.978,
    p25: 22.007,
    p50: 24.137,
    p75: 26.591,
    p85: 28.065,
    p90: 29.134,
    p95: 30.833,
    p97: 32.017,
    p99: 34.448,
    p999: 39.307
  },
  {
    month: 91,
    l: -0.5053,
    m: 24.3479,
    s: 0.14065,
    p01: 16.437,
    p1: 17.987,
    p3: 18.995,
    p5: 19.563,
    p10: 20.49,
    p15: 21.153,
    p25: 22.193,
    p50: 24.348,
    p75: 26.834,
    p85: 28.328,
    p90: 29.413,
    p95: 31.139,
    p97: 32.343,
    p99: 34.817,
    p999: 39.774
  },
  {
    month: 92,
    l: -0.5142,
    m: 24.5595,
    s: 0.14117,
    p01: 16.569,
    p1: 18.131,
    p3: 19.148,
    p5: 19.722,
    p10: 20.658,
    p15: 21.328,
    p25: 22.379,
    p50: 24.56,
    p75: 27.078,
    p85: 28.594,
    p90: 29.695,
    p95: 31.449,
    p97: 32.674,
    p99: 35.192,
    p999: 40.249
  },
  {
    month: 93,
    l: -0.5229,
    m: 24.7722,
    s: 0.1417,
    p01: 16.7,
    p1: 18.276,
    p3: 19.302,
    p5: 19.882,
    p10: 20.827,
    p15: 21.504,
    p25: 22.566,
    p50: 24.772,
    p75: 27.324,
    p85: 28.862,
    p90: 29.98,
    p95: 31.762,
    p97: 33.007,
    p99: 35.571,
    p999: 40.732
  },
  {
    month: 94,
    l: -0.5315,
    m: 24.9858,
    s: 0.14226,
    p01: 16.831,
    p1: 18.42,
    p3: 19.456,
    p5: 20.041,
    p10: 20.995,
    p15: 21.679,
    p25: 22.754,
    p50: 24.986,
    p75: 27.572,
    p85: 29.132,
    p90: 30.267,
    p95: 32.079,
    p97: 33.345,
    p99: 35.956,
    p999: 41.224
  },
  {
    month: 95,
    l: -0.5399,
    m: 25.2005,
    s: 0.14284,
    p01: 16.962,
    p1: 18.564,
    p3: 19.609,
    p5: 20.2,
    p10: 21.164,
    p15: 21.855,
    p25: 22.941,
    p50: 25.2,
    p75: 27.821,
    p85: 29.405,
    p90: 30.558,
    p95: 32.398,
    p97: 33.687,
    p99: 36.346,
    p999: 41.724
  },
  {
    month: 96,
    l: -0.5482,
    m: 25.4163,
    s: 0.14344,
    p01: 17.092,
    p1: 18.708,
    p3: 19.763,
    p5: 20.359,
    p10: 21.333,
    p15: 22.031,
    p25: 23.13,
    p50: 25.416,
    p75: 28.073,
    p85: 29.68,
    p90: 30.85,
    p95: 32.722,
    p97: 34.033,
    p99: 36.742,
    p999: 42.233
  },
  {
    month: 97,
    l: -0.5564,
    m: 25.6332,
    s: 0.14407,
    p01: 17.221,
    p1: 18.851,
    p3: 19.916,
    p5: 20.518,
    p10: 21.502,
    p15: 22.208,
    p25: 23.319,
    p50: 25.633,
    p75: 28.326,
    p85: 29.957,
    p90: 31.147,
    p95: 33.05,
    p97: 34.384,
    p99: 37.145,
    p999: 42.754
  },
  {
    month: 98,
    l: -0.5644,
    m: 25.8513,
    s: 0.14472,
    p01: 17.35,
    p1: 18.994,
    p3: 20.069,
    p5: 20.677,
    p10: 21.671,
    p15: 22.385,
    p25: 23.508,
    p50: 25.851,
    p75: 28.582,
    p85: 30.238,
    p90: 31.446,
    p95: 33.381,
    p97: 34.74,
    p99: 37.553,
    p999: 43.284
  },
  {
    month: 99,
    l: -0.5722,
    m: 26.0706,
    s: 0.14539,
    p01: 17.478,
    p1: 19.137,
    p3: 20.222,
    p5: 20.836,
    p10: 21.84,
    p15: 22.562,
    p25: 23.698,
    p50: 26.071,
    p75: 28.839,
    p85: 30.52,
    p90: 31.748,
    p95: 33.716,
    p97: 35.099,
    p99: 37.967,
    p999: 43.823
  },
  {
    month: 100,
    l: -0.5799,
    m: 26.2911,
    s: 0.14608,
    p01: 17.606,
    p1: 19.28,
    p3: 20.375,
    p5: 20.995,
    p10: 22.01,
    p15: 22.74,
    p25: 23.889,
    p50: 26.291,
    p75: 29.099,
    p85: 30.806,
    p90: 32.053,
    p95: 34.055,
    p97: 35.464,
    p99: 38.387,
    p999: 44.372
  },
  {
    month: 101,
    l: -0.5873,
    m: 26.5128,
    s: 0.14679,
    p01: 17.734,
    p1: 19.422,
    p3: 20.528,
    p5: 21.154,
    p10: 22.18,
    p15: 22.918,
    p25: 24.08,
    p50: 26.513,
    p75: 29.36,
    p85: 31.093,
    p90: 32.362,
    p95: 34.398,
    p97: 35.832,
    p99: 38.813,
    p999: 44.931
  },
  {
    month: 102,
    l: -0.5946,
    m: 26.7358,
    s: 0.14752,
    p01: 17.861,
    p1: 19.564,
    p3: 20.681,
    p5: 21.313,
    p10: 22.35,
    p15: 23.096,
    p25: 24.272,
    p50: 26.736,
    p75: 29.624,
    p85: 31.384,
    p90: 32.673,
    p95: 34.745,
    p97: 36.206,
    p99: 39.245,
    p999: 45.501
  },
  {
    month: 103,
    l: -0.6017,
    m: 26.9602,
    s: 0.14828,
    p01: 17.987,
    p1: 19.706,
    p3: 20.833,
    p5: 21.473,
    p10: 22.521,
    p15: 23.275,
    p25: 24.465,
    p50: 26.96,
    p75: 29.89,
    p85: 31.678,
    p90: 32.989,
    p95: 35.097,
    p97: 36.585,
    p99: 39.686,
    p999: 46.083
  },
  {
    month: 104,
    l: -0.6085,
    m: 27.1861,
    s: 0.14905,
    p01: 18.114,
    p1: 19.848,
    p3: 20.986,
    p5: 21.632,
    p10: 22.692,
    p15: 23.454,
    p25: 24.659,
    p50: 27.186,
    p75: 30.158,
    p85: 31.974,
    p90: 33.307,
    p95: 35.453,
    p97: 36.969,
    p99: 40.131,
    p999: 46.674
  },
  {
    month: 105,
    l: -0.6152,
    m: 27.4137,
    s: 0.14984,
    p01: 18.24,
    p1: 19.99,
    p3: 21.14,
    p5: 21.793,
    p10: 22.863,
    p15: 23.635,
    p25: 24.853,
    p50: 27.414,
    p75: 30.429,
    p85: 32.274,
    p90: 33.629,
    p95: 35.814,
    p97: 37.358,
    p99: 40.584,
    p999: 47.278
  },
  {
    month: 106,
    l: -0.6216,
    m: 27.6432,
    s: 0.15066,
    p01: 18.365,
    p1: 20.132,
    p3: 21.293,
    p5: 21.953,
    p10: 23.036,
    p15: 23.816,
    p25: 25.049,
    p50: 27.643,
    p75: 30.703,
    p85: 32.578,
    p90: 33.956,
    p95: 36.18,
    p97: 37.753,
    p99: 41.046,
    p999: 47.895
  },
  {
    month: 107,
    l: -0.6278,
    m: 27.875,
    s: 0.15149,
    p01: 18.492,
    p1: 20.274,
    p3: 21.448,
    p5: 22.114,
    p10: 23.209,
    p15: 23.998,
    p25: 25.247,
    p50: 27.875,
    p75: 30.98,
    p85: 32.885,
    p90: 34.287,
    p95: 36.551,
    p97: 38.155,
    p99: 41.515,
    p999: 48.523
  },
  {
    month: 108,
    l: -0.6337,
    m: 28.1092,
    s: 0.15233,
    p01: 18.618,
    p1: 20.418,
    p3: 21.603,
    p5: 22.277,
    p10: 23.384,
    p15: 24.182,
    p25: 25.446,
    p50: 28.109,
    p75: 31.26,
    p85: 33.196,
    p90: 34.622,
    p95: 36.927,
    p97: 38.562,
    p99: 41.99,
    p999: 49.162
  },
  {
    month: 109,
    l: -0.6393,
    m: 28.3459,
    s: 0.15319,
    p01: 18.745,
    p1: 20.562,
    p3: 21.759,
    p5: 22.44,
    p10: 23.56,
    p15: 24.368,
    p25: 25.647,
    p50: 28.346,
    p75: 31.544,
    p85: 33.512,
    p90: 34.962,
    p95: 37.309,
    p97: 38.975,
    p99: 42.475,
    p999: 49.815
  },
  {
    month: 110,
    l: -0.6446,
    m: 28.5854,
    s: 0.15406,
    p01: 18.872,
    p1: 20.707,
    p3: 21.917,
    p5: 22.605,
    p10: 23.737,
    p15: 24.555,
    p25: 25.85,
    p50: 28.585,
    p75: 31.831,
    p85: 33.832,
    p90: 35.307,
    p95: 37.697,
    p97: 39.395,
    p99: 42.967,
    p999: 50.479
  },
  {
    month: 111,
    l: -0.6496,
    m: 28.8277,
    s: 0.15493,
    p01: 19.001,
    p1: 20.853,
    p3: 22.076,
    p5: 22.772,
    p10: 23.917,
    p15: 24.744,
    p25: 26.056,
    p50: 28.828,
    p75: 32.122,
    p85: 34.155,
    p90: 35.656,
    p95: 38.089,
    p97: 39.82,
    p99: 43.465,
    p999: 51.152
  },
  {
    month: 112,
    l: -0.6543,
    m: 29.0731,
    s: 0.15581,
    p01: 19.13,
    p1: 21.001,
    p3: 22.236,
    p5: 22.94,
    p10: 24.098,
    p15: 24.936,
    p25: 26.263,
    p50: 29.073,
    p75: 32.417,
    p85: 34.484,
    p90: 36.01,
    p95: 38.488,
    p97: 40.252,
    p99: 43.972,
    p999: 51.838
  },
  {
    month: 113,
    l: -0.6585,
    m: 29.3217,
    s: 0.1567,
    p01: 19.26,
    p1: 21.149,
    p3: 22.398,
    p5: 23.11,
    p10: 24.282,
    p15: 25.129,
    p25: 26.474,
    p50: 29.322,
    p75: 32.716,
    p85: 34.817,
    p90: 36.369,
    p95: 38.892,
    p97: 40.69,
    p99: 44.487,
    p999: 52.535
  },
  {
    month: 114,
    l: -0.6624,
    m: 29.5736,
    s: 0.1576,
    p01: 19.39,
    p1: 21.299,
    p3: 22.561,
    p5: 23.281,
    p10: 24.467,
    p15: 25.325,
    p25: 26.687,
    p50: 29.574,
    p75: 33.02,
    p85: 35.155,
    p90: 36.734,
    p95: 39.303,
    p97: 41.135,
    p99: 45.01,
    p999: 53.244
  },
  {
    month: 115,
    l: -0.6659,
    m: 29.8289,
    s: 0.1585,
    p01: 19.522,
    p1: 21.451,
    p3: 22.727,
    p5: 23.455,
    p10: 24.655,
    p15: 25.523,
    p25: 26.902,
    p50: 29.829,
    p75: 33.327,
    p85: 35.497,
    p90: 37.104,
    p95: 39.719,
    p97: 41.587,
    p99: 45.54,
    p999: 53.963
  },
  {
    month: 116,
    l: -0.6689,
    m: 30.0877,
    s: 0.1594,
    p01: 19.655,
    p1: 21.604,
    p3: 22.894,
    p5: 23.631,
    p10: 24.845,
    p15: 25.724,
    p25: 27.121,
    p50: 30.088,
    p75: 33.639,
    p85: 35.844,
    p90: 37.479,
    p95: 40.141,
    p97: 42.044,
    p99: 46.076,
    p999: 54.69
  },
  {
    month: 117,
    l: -0.6714,
    m: 30.3501,
    s: 0.16031,
    p01: 19.789,
    p1: 21.758,
    p3: 23.063,
    p5: 23.808,
    p10: 25.037,
    p15: 25.927,
    p25: 27.342,
    p50: 30.35,
    p75: 33.956,
    p85: 36.197,
    p90: 37.859,
    p95: 40.569,
    p97: 42.508,
    p99: 46.621,
    p999: 55.428
  },
  {
    month: 118,
    l: -0.6735,
    m: 30.616,
    s: 0.16122,
    p01: 19.924,
    p1: 21.914,
    p3: 23.234,
    p5: 23.987,
    p10: 25.231,
    p15: 26.133,
    p25: 27.566,
    p50: 30.616,
    p75: 34.276,
    p85: 36.554,
    p90: 38.245,
    p95: 41.003,
    p97: 42.978,
    p99: 47.173,
    p999: 56.175
  },
  {
    month: 119,
    l: -0.6752,
    m: 30.8854,
    s: 0.16213,
    p01: 20.06,
    p1: 22.071,
    p3: 23.406,
    p5: 24.169,
    p10: 25.428,
    p15: 26.341,
    p25: 27.793,
    p50: 30.885,
    p75: 34.601,
    p85: 36.916,
    p90: 38.635,
    p95: 41.443,
    p97: 43.455,
    p99: 47.732,
    p999: 56.931
  },
  {
    month: 120,
    l: -0.6764,
    m: 31.1586,
    s: 0.16305,
    p01: 20.196,
    p1: 22.23,
    p3: 23.581,
    p5: 24.352,
    p10: 25.627,
    p15: 26.551,
    p25: 28.023,
    p50: 31.159,
    p75: 34.931,
    p85: 37.283,
    p90: 39.032,
    p95: 41.889,
    p97: 43.938,
    p99: 48.299,
    p999: 57.698
  }
]

export default weightValues
