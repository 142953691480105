import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { CREATE_PRICELIST, DELETE_PRICELIST, UPDATE_PRICELIST } from '../../api/requests/pricing'
import { Pricelist } from '../../api/interfaces'
import { ValidateFn, ValidationError } from '../Form'

export const messages = defineMessages({
  errorDeletingPricelist: {
    id: 'DeletePricelistModal.not_deleted',
    defaultMessage: 'Could not delete this pricelist.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
})
interface NewPricelist {
  name: string
  description?: string
  currency: string
}
export interface CreatePricelistsVariables {
  pricelist: NewPricelist
}

interface CreatePricelistMutationData {
  createPricelist: Pricelist
}

export type UsePricelistCreationParams = MutationHookOptions<CreatePricelistMutationData, CreatePricelistsVariables>

export const usePricelistCreation = (params?: UsePricelistCreationParams) => {
  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const validate: ValidateFn<NewPricelist> = async (values) => {
    const errors: ValidationError<NewPricelist> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.required)
    }

    return errors
  }

  const [createPricelist, { loading: isLoading }] = useMutation<CreatePricelistMutationData, CreatePricelistsVariables>(
    CREATE_PRICELIST,
    {
      ...params,
      onError,
    }
  )

  const onSubmit = (pricelist: NewPricelist) => {
    setError(null)

    createPricelist({ variables: { pricelist } })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    validate,
    error,
    isLoading,
    onErrorClose,
  }
}

export interface UpdatePricelistsVariables {
  id: string
  pricelist: NewPricelist
}

interface UpdatePricelistMutationData {
  createPricelist: Pricelist
}

export type UsePricelistUpdateParams = MutationHookOptions<UpdatePricelistMutationData, UpdatePricelistsVariables> & {
  id: string
}

export const usePricelistUpdate = (params: UsePricelistUpdateParams) => {
  const { id, ...rest } = params
  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const validate: ValidateFn<NewPricelist> = async (values) => {
    const errors: ValidationError<NewPricelist> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.required)
    }

    return errors
  }

  const [updatePricelist, { loading: isLoading }] = useMutation<UpdatePricelistMutationData, UpdatePricelistsVariables>(
    UPDATE_PRICELIST,
    {
      ...rest,
      onError,
    }
  )

  const onSubmit = (pricelist: NewPricelist) => {
    setError(null)

    const variables = {
      id,
      pricelist,
    }

    updatePricelist({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    validate,
    error,
    isLoading,
    onErrorClose,
  }
}
interface DeletePricelistsVariables {
  id: string
}

interface DeletePricelistMutationData {
  done: boolean
}

export type UsePricelistDeletionParams = MutationHookOptions<DeletePricelistMutationData, DeletePricelistsVariables>

export const usePricelistDeletion = (params?: UsePricelistDeletionParams) => {
  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'error_deleting_pricelist':
        return setError(intl.formatMessage(messages.errorDeletingPricelist))

      case 'invalid_signature':
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [deletePricelist, { loading: isLoading }] = useMutation<DeletePricelistMutationData, DeletePricelistsVariables>(
    DELETE_PRICELIST,
    {
      ...params,
      onError,
    }
  )

  const onDelete = (id: string) => {
    setError(null)

    const variables = {
      id,
    }

    deletePricelist({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    error,
    isLoading,
    onErrorClose,
  }
}
