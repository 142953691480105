import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { UPDATE_TELEHEALTH } from '../../api/requests/systems'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { HealthSystem, Slot } from '../../api/interfaces'
import { useSystems } from '.'
import { useSession } from '../Auth'
import { ValidateFn, ValidationError } from '../Form'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
})

interface NewTelehealthSettings {
  slot: Slot
}

interface UpdateTelehealthSettingsVariables {
  systemId: string
  locationId: string
  settings: NewTelehealthSettings
}

interface UpdateTelehealthSettingsData {
  updateTelehealthSettings: HealthSystem
}

export type UpdateTelehealthSettingsParams = MutationHookOptions<
  UpdateTelehealthSettingsData,
  UpdateTelehealthSettingsVariables
>

export const useUpdateTelehealthSettings = (params?: UpdateTelehealthSettingsParams) => {
  const intl = useIntl()

  const { getMyLocation } = useSystems()
  const { telehealth } = getMyLocation()

  const { getUserLocation, getUserSystem } = useSession()
  const { id: systemId } = getUserSystem()
  const { id: locationId } = getUserLocation()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const initialValues =
    telehealth !== null
      ? {
          unit: telehealth.slot.unit,
          length: telehealth.slot.length,
        }
      : undefined

  const validate: ValidateFn<Slot> = async (values) => {
    const errors: ValidationError<Slot> = {}

    if (!values.unit) {
      errors.unit = intl.formatMessage(messages.required)
    }

    if (!values.length || values.length <= 0) {
      errors.length = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    if (params?.onError) {
      params.onError(error)
    }

    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onCompleted = (data) => {
    setError(null)
    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [updateSettings, { loading: isLoading }] = useMutation<
    UpdateTelehealthSettingsData,
    UpdateTelehealthSettingsVariables
  >(UPDATE_TELEHEALTH, { ...params, onError, onCompleted })

  const onSubmit = (slot: Slot) => {
    const variables = {
      systemId,
      locationId,
      settings: {
        slot: {
          unit: slot.unit,
          length: Number(slot.length),
        },
      },
    }

    updateSettings({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    initialValues,
    validate,
    onSubmit,
    isLoading,
    error,
    onErrorClose,
  }
}
