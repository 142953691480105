import React from 'react'
import {
  Box,
  Button,
  ListItem,
  UnorderedList,
  useBreakpointValue
} from '@chakra-ui/react'
import { Form } from 'react-final-form'
import { useIntl, defineMessages } from 'react-intl'
import { ErrorViewer } from '..'
import { CountryField, DateField, MultiLineTextField, SexField, TextField, ValidateFn } from '../Form'
import { BasePatientFormValues } from '.'
import { Nullable } from '../../utils'
import { CheckboxField } from '../Form/CheckboxField'

export const messages = defineMessages({
  firstName: {
    id: 'PersonalInfoCard.first_name',
    defaultMessage: 'First name'
  },
  lastName: {
    id: 'PersonalInfoCard.last_name',
    defaultMessage: 'Last name'
  },
  birthdate: {
    id: 'PersonalInfoCard.birthdate',
    defaultMessage: 'Birthdate'
  },
  sex: {
    id: 'SexSelector.label',
    defaultMessage: 'Sex'
  },
  phone: {
    id: 'PersonalInfoCard.phone',
    defaultMessage: 'Phone'
  },
  phoneE164: {
    id: 'PersonalInfoCard.phone_machine_readable',
    defaultMessage: 'Phone (machine-readable)'
  },
  email: {
    id: 'PersonalInfoCard.email',
    defaultMessage: 'Email'
  },
  address: {
    id: 'PersonalInfoCard.address',
    defaultMessage: 'Address'
  },
  address1: {
    id: 'PersonalInfoCard.address_1',
    defaultMessage: 'Address #1'
  },
  address2: {
    id: 'PersonalInfoCard.address_2',
    defaultMessage: 'Address #2'
  },
  address3: {
    id: 'PersonalInfoCard.address_3',
    defaultMessage: 'Address #3'
  },
  locality: {
    id: 'PersonalInfoCard.locality',
    defaultMessage: 'City / Town / Locality'
  },
  postal_code: {
    id: 'PersonalInfoCard.postal_code',
    defaultMessage: 'Postal code'
  },
  country: {
    id: 'PersonalInfoCard.country',
    defaultMessage: 'Country'
  },
  invalidDate: {
    id: 'PersonalInfoCard.invalid_date',
    defaultMessage: 'Invalid date'
  },
  invalidMail: {
    id: 'PersonalInfoCard.invalid_email_address',
    defaultMessage: 'Invalid e-mail address'
  },
  invalidPhone: {
    id: 'PersonalInfoCard.invalid_phone_number',
    defaultMessage: 'Invalid phone number'
  },
  validPhone: {
    id: 'PersonalInfoCard.valid_phone_number',
    defaultMessage: 'Valid phone number'
  },
  requiredError: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required'
  },

  review: {
    id: 'PersonalInfoCard.reviewed_with_patient',
    defaultMessage: 'The following were reviewed with the patient'
  },
  rightsAndOblications: {
    id: 'PersonalInfoCard.patient_rights_and_oblications',
    defaultMessage: 'Patient rights and obligations'
  },
  clinicProcesses: {
    id: 'PersonalInfoCard.consultant_processes_and_charges',
    defaultMessage: 'Consultant processes and charges'
  },

})


interface BasePatientFormProps {
  action: string,
  initialValues?: BasePatientFormValues
  onSubmit: (values: BasePatientFormValues) => void
  validate: ValidateFn<BasePatientFormValues>
  error: Nullable<string>
  onErrorClose: () => void
  isLoading?: boolean
  isCreating?: boolean
  initialFocus?: boolean
}
export const BasePatientForm: React.FC<BasePatientFormProps> = props => {
  const {
    action,
    onSubmit,
    isLoading,
    validate,
    initialValues,
    initialFocus,
    isCreating,
    error,
    onErrorClose
  } = props

  const intl = useIntl()

  const isFullWidth = useBreakpointValue({ base: true, md: false })
  return <Box>
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({
        handleSubmit,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            name="first_name"
            label={intl.formatMessage(messages.firstName)}
            autoComplete='off'
            autoFocus={initialFocus}
          />

          <TextField
            name="last_name"
            label={intl.formatMessage(messages.lastName)}
            autoComplete='off'
          />

          <DateField
            name="birthdate"
            label={intl.formatMessage(messages.birthdate)}
            autoComplete='off'
          />

          <SexField
            name='sex'
            label={intl.formatMessage(messages.sex)}
          />

          <TextField
            name="phone_number"
            label={intl.formatMessage(messages.phone)}
            autoComplete='off'
          />

          <TextField
            name="email"
            label={intl.formatMessage(messages.email)}
            autoComplete='off'
          />

          <MultiLineTextField
            name="address"
            label={intl.formatMessage(messages.address)}
            autoComplete='off'
            rows={3}
            maxRows={3}
          />

          <TextField
            name="locality"
            label={intl.formatMessage(messages.locality)}
            autoComplete='off'
          />

          <TextField
            name="postal_code"
            label={intl.formatMessage(messages.postal_code)}
            autoComplete='off'
            type='number'
          />

          <CountryField
            name={'country'}
            label={intl.formatMessage(messages.country)}
          />

          {
            isCreating && <Box mt={10}>
              <CheckboxField
                name='consent'
                label={intl.formatMessage(messages.review)}
              />
              <UnorderedList pl={4}>
                <ListItem>{intl.formatMessage(messages.rightsAndOblications)}</ListItem>
                <ListItem>{intl.formatMessage(messages.clinicProcesses)}</ListItem>
              </UnorderedList>
            </Box>
          }
          {
            error &&
            <ErrorViewer title={error} onClose={onErrorClose} />
          }
          <Button
            isDisabled={pristine}
            isLoading={isLoading}
            colorScheme='brand'
            type="submit"
            isFullWidth={isFullWidth}
            mb={2}
            mt={8}
          >
            {action}
          </Button>

        </form>
      )}
    />

  </Box>
}



