import { BoxProps, Box } from "@chakra-ui/react";
import React from "react";
import { FullHistory } from "../../api/interfaces";
import {
  PastVisits,
  ReadOnlyManualProblems,
  ReadOnlyManualMedications,
  ReadOnlyAllergies,
  ReadOnlyDiet,
  ReadOnlyDevelopment,
  ReadOnlySocial,
  ReadOnlyFamily,
} from "../History";
import { ReadOnlySurveysSection } from "./SurveysSection";

type ReadOnlyHistoryProps = BoxProps & {
  history: FullHistory
}

export const ReadOnlyHistory: React.FC<ReadOnlyHistoryProps> = props => {
  const { history, ...boxProps } = props

  const sectionProps = {
    defaultIsOpen: true,
    mb: 6,
    p: 4,
    borderWidth: 1,
    borderRadius: 'lg'
  }

  return <Box {...boxProps}>
    <PastVisits
      items={history.pastVisits ?? []}
      {...sectionProps}
    />
    <ReadOnlyManualProblems
      items={history.manualProblems ?? []}
      {...sectionProps}
    />
    <ReadOnlySurveysSection
      items={history.surveys ?? []}
      {...sectionProps}
    />
    <ReadOnlyManualMedications
      items={history.manualMedications ?? []}
      {...sectionProps}
    />
    <ReadOnlyAllergies
      items={history.allergies ?? []}
      {...sectionProps}
    />
    <ReadOnlyDiet
      items={history.dietHistory ?? []}
      {...sectionProps}
    />
    <ReadOnlyDevelopment
      items={history.developmentHistory ?? []}
      {...sectionProps}
    />
    <ReadOnlySocial
      items={history.socialHistory ?? []}
      {...sectionProps}
    />
    <ReadOnlyFamily
      items={history.familyHistory ?? []}
      {...sectionProps}
    />
  </Box>
}