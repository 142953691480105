import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { ErrorViewer } from '../'
import { DateField, VisitTypeField } from '../Form'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Button,
  ModalFooter,
  ButtonProps,
  useBreakpointValue,
} from '@chakra-ui/react'
import { isDeprecatedVisitType, useStartVisit } from '.'
import { CheckboxField } from '../Form/CheckboxField'
import { isBefore } from '../DateAndTime'
import { VisitType } from '../../api/interfaces'

const messages = defineMessages({
  startNewVisit: {
    id: 'NewVisitModal.start_new_visit',
    defaultMessage: 'Start new visit',
  },
  visitType: {
    id: 'NewVisitModal.visit_type_label',
    defaultMessage: 'Visit type',
  },
  date: {
    id: 'UI.date_label',
    defaultMessage: 'Date',
  },
  backdated: {
    id: 'NewVisitModal.backdated',
    defaultMessage: 'Backdated',
  },
  startButton: {
    id: 'NewVisitModal.start_visit',
    defaultMessage: 'Start visit',
  },
  closeButton: {
    id: 'UI.button_close',
    defaultMessage: 'Close',
  },
})

interface NewVisitModalProps {
  patientId: string
  isOpen: boolean
  onClose: () => void
  initialFocus?: boolean
}

export const NewVisitModal: React.FC<NewVisitModalProps> = (props) => {
  const { patientId, isOpen, initialFocus } = props

  const intl = useIntl()
  const { initialValues, onSubmit, isLoading, validate, error, onErrorClose } = useStartVisit({ patientId })

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={({ handleSubmit, form }) => {
          const onDateChange = (newDate: any) => {
            form.change('backdated', isBefore(newDate))
          }

          return (
            <form onSubmit={handleSubmit}>
              <ModalContent>
                <ModalHeader>{intl.formatMessage(messages.startNewVisit)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <DateField
                    name="date"
                    label={intl.formatMessage(messages.date)}
                    autoFocus={initialFocus}
                    onChange={onDateChange}
                  />

                  <VisitTypeField
                    name="type"
                    label={intl.formatMessage(messages.visitType)}
                    filter={type => type !== VisitType.DENTAL && !isDeprecatedVisitType(type)}
                  />

                  <CheckboxField name="backdated" label={intl.formatMessage(messages.backdated)} />

                  {error && <ErrorViewer title={error} onClose={onErrorClose} />}
                </ModalBody>

                <ModalFooter>
                  <Button mr={3} onClick={props.onClose} isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.closeButton)}
                  </Button>
                  <Button colorScheme={'brand'} isLoading={isLoading} type="submit" isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.startButton)}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )
        }}
      />
    </Modal>
  )
}

interface StartVisitButtonProps extends Omit<ButtonProps, 'onClick'> {
  patientId: string
}

export const StartVisitButton: React.FC<StartVisitButtonProps> = (props) => {
  const { patientId, ...buttonProps } = props
  const intl = useIntl()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const onClick = () => {
    onOpen()
  }
  return (
    <>
      <Button colorScheme={'brand'} onClick={onClick} {...buttonProps}>
        {intl.formatMessage(messages.startNewVisit)}
      </Button>
      <NewVisitModal isOpen={isOpen} onClose={onClose} patientId={patientId} />
    </>
  )
}
