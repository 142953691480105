import React from 'react'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'
import { Checkbox, Stack } from '@chakra-ui/react'
import { EventColor, EventType } from './utils'
import { useCalendarContext } from '../useCalendarContext'

const eventTypeTranslations: Record<EventType, MessageDescriptor> = {
  [EventType.IN_PERSON]: {
    id: 'VisitCalendar.label_in_person',
    defaultMessage: 'In Person',
  },
  [EventType.TELEHEALTH]: {
    id: 'VisitCalendar.label_telehealth',
    defaultMessage: 'Telehealth',
  },
  [EventType.PERSONAL_APPOINTMENT]: {
    id: 'VisitCalendar.label_personal_events',
    defaultMessage: 'Personal events',
  },
}
const messages = defineMessages(eventTypeTranslations)

export const EventFilter: React.VFC = () => {
  const intl = useIntl()

  const { eventFilter, onEventFilterChange } = useCalendarContext()
  return (
    <Stack mt={3} isInline={true} justifyContent={'end'}>
      {Object.values(EventType).map((type) => {
        const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const isChecked = event.target.checked
          const newFilter = {
            ...eventFilter,
            [type]: isChecked,
          }

          onEventFilterChange(newFilter)
        }

        return (
          <Checkbox defaultChecked={eventFilter[type]} onChange={onChange} key={type} colorScheme={EventColor[type]}>
            {intl.formatMessage(messages[type])}
          </Checkbox>
        )
      })}
    </Stack>
  )
}
