import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { RiCheckFill as CheckIcon } from 'react-icons/ri'
import { ActiveVisit, FullPatient } from '../../api/interfaces'
import { useCompleteVisit } from '.'

import {
  Button,
  ButtonProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useBreakpointValue,
  Icon,
  Stack,
} from '@chakra-ui/react'
import { ErrorViewer, PrintButton } from '..'
import { VisitResume } from '../Visit'
const messages = defineMessages({
  completeVisitButton: {
    id: 'UI.complete_visit_button',
    defaultMessage: 'Complete visit',
  },
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close',
  },
  doneButton: {
    id: 'UI.done_button',
    defaultMessage: 'Done',
  },
})

type CompleteVisitButtonProps = ButtonProps & {
  patient: FullPatient
  activeVisit: ActiveVisit
}

export const CompleteVisitButton: React.FC<CompleteVisitButtonProps> = (props) => {
  const { patient, activeVisit, ...buttonProps } = props

  const intl = useIntl()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Button onClick={onOpen} colorScheme={'brand'} {...buttonProps}>
      <CompleteVisitModal
        patient={patient}
        activeVisit={activeVisit}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Icon as={CheckIcon} mr={2} />
      {intl.formatMessage(messages.completeVisitButton)}
    </Button>
  )
}

interface VisitResumeModalProps {
  patient: FullPatient
  activeVisit: ActiveVisit
  isOpen: boolean
  onClose: () => void
  preventModalFromClosing?: boolean
}

const CompleteVisitModal: React.FC<VisitResumeModalProps> = (props) => {
  const { patient, activeVisit, isOpen, onClose } = props

  const intl = useIntl()
  const componentToPrint = React.createRef<HTMLDivElement>()

  const params = {
    patientId: patient.id,
    visitId: activeVisit.id,
  }

  const filename = `${patient ? patient.first_name + ' ' + patient.last_name : ''}-${new Date(
    activeVisit.start
  ).toLocaleDateString()}.pdf`

  const { onSubmit, error, onErrorClose, isLoading } = useCompleteVisit(params)

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pt={8}>
          <VisitResume visit={activeVisit} patient={patient} ref={componentToPrint} />

          {error && <ErrorViewer title={error} onClose={onErrorClose} />}
        </ModalBody>

        <ModalFooter>
          <Stack isInline={!isFullWidth} flex={1} direction="column-reverse" justifyContent={'end'}>
            <Button onClick={onClose} isFullWidth={isFullWidth}>
              {intl.formatMessage(messages.closeButton)}
            </Button>
            <PrintButton
              componentToPrint={componentToPrint}
              colorScheme={'brand'}
              isFullWidth={isFullWidth}
              filename={filename}
            />
            <Button
              colorScheme={'green'}
              isLoading={isLoading}
              isFullWidth={isFullWidth}
              onClick={onSubmit}
            >
              <Icon as={CheckIcon} mr={2} />
              {intl.formatMessage(messages.completeVisitButton)}
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
