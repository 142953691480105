import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Input,
  ModalFooter,
  Box,
  Icon,
  Heading,
  Text,
  BoxProps,
  ButtonProps,
  VStack,
  HStack,
  useBreakpointValue,
} from '@chakra-ui/react'
import { FullChecklistEntry, FullProcedure } from '../../api/interfaces'
import { usePagination } from '../Searches/usePagination'
import { PaginatedList } from '../Searches/PaginatedList'
import { useLabSubscriptions } from './useLabSubscriptions'
import { Nullable } from '../../utils'
import { ErrorViewer } from '..'
import { useCreateStudyOrder } from './useCreateStudyOrder'
import { RiCheckboxCircleLine as CheckIcon } from 'react-icons/ri'

const messages = defineMessages({
  orderNewStudy: {
    id: 'StudyOrderModal.order_new_study',
    defaultMessage: 'Order new study'
  },
  searchStudies: {
    id: 'StudyOrderModal.search_studies',
    defaultMessage: 'Search studies'
  },
  orderButton: {
    id: 'StudyOrderModal.order',
    defaultMessage: 'Order'
  },
  comment: {
    id: 'StudyOrderModal.comment',
    defaultMessage: 'Comment'
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel'
  }
})

interface StudyOrderModalProps {
  patientId: string
  visitId: string
  guideline?: FullChecklistEntry
  isOpen: boolean
  onClose: () => void
  onOrder?: () => void
}

interface SelectedItem {
  id: string
  name: string
}
export const StudyOrderModal: React.FC<StudyOrderModalProps> = props => {
  const { patientId, visitId, guideline, isOpen, onClose } = props
  const intl = useIntl()

  const [selectedStudy, setSelectedStudy] = React.useState<Nullable<SelectedItem>>(null)
  const [comment, setComment] = React.useState<string>('')


  /**
 * This is the easiest way to uncheck the checklist item
 * if the order is canceled that doesn't require rewriting
 * all the involved components. It is probably worth doing
 * so at a later time in order to have more maintainable code.
 * For now:
 * Callback should trigger the item update in case the order is added.
 * If callback doesn't exist, just toggle the modal.
 */
  const onCompleted = () => {
    if (typeof props.onOrder === 'function') {
      props.onOrder()
    } else {
      onClose()
    }
  }

  const {
    createStudyOrder,
    isLoading,
    error,
    onErrorClose
  } = useCreateStudyOrder({ onCompleted })

  const onSelect = (item: Nullable<SelectedItem>) => {
    setSelectedStudy(item)
  }

  const onCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setComment(value)
  }

  const shouldBeDisabled = selectedStudy === null || !visitId
  const onSubmit = () => {
    if (shouldBeDisabled) {
      return
    }

    const variables = {
      patientId,
      visitId,
      studyOrder: {
        templateId: selectedStudy.id,
        orderTitle: selectedStudy.name,
        comment,
        guideline,
      }
    }

    createStudyOrder({ variables })
  }

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        {intl.formatMessage(messages.orderNewStudy)}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>

        <ProcedureList
          initialQuery={guideline?.name?.text ?? undefined}
          onSelect={onSelect}
          selectedItem={selectedStudy}
          mb={2}
          p={2}
        />

        <Input
          value={comment}
          onChange={onCommentChange}
          placeholder={intl.formatMessage(messages.comment)}
          maxLength={120}
        />
        {
          error &&
          <ErrorViewer title={error} onClose={onErrorClose} />
        }
      </ModalBody>

      <ModalFooter>
        <Button mr={3} onClick={props.onClose} isFullWidth={isFullWidth}>
          {intl.formatMessage(messages.cancelButton)}
        </Button>
        <Button
          colorScheme={'brand'}
          isLoading={isLoading}
          onClick={onSubmit}
          isDisabled={shouldBeDisabled}
          isFullWidth={isFullWidth}
        >
          {intl.formatMessage(messages.orderButton)}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>


}

type ProcedureListProps = Omit<BoxProps, 'onSelect'> & {
  initialQuery?: string
  onSelect: (selected: Nullable<SelectedItem>) => void
  selectedItem: Nullable<SelectedItem>
}

const ProcedureList: React.FC<ProcedureListProps> = props => {
  const { initialQuery, onSelect, selectedItem, ...boxProps } = props

  const intl = useIntl()

  const params = {
    defaultQ: initialQuery
  }

  const { filter, page, onSearch, onPageChange, } = usePagination(params)
  const {
    studies,
    count,
    isLoading,
    error,
    onErrorClose
  } = useLabSubscriptions()

  const items = studies.slice(filter.offset, filter.offset + filter.limit)

  return <Box borderRadius='md' borderWidth='1px' {...boxProps}>
    <PaginatedList
      placeholder={intl.formatMessage(messages.searchStudies)}
      items={items}
      total={count}
      itemsPerPage={filter.limit}
      defaultSearch={initialQuery}
      page={page}
      onSearch={onSearch}
      onPageChange={onPageChange}
      isLoading={isLoading}
      initialFocus
      renderItem={study => (
        <Item
          key={study.id}
          study={study}
          onSelect={onSelect}
          isSelected={selectedItem?.id === study.id}
          px={4}
          isFullWidth
        />
      )}
    />
    {
      error &&
      <ErrorViewer title={error} onClose={onErrorClose} />
    }
  </Box >
}

interface TranslatedProcedures extends FullProcedure {
  translatedName: string
  translatedDescription: string
}

type ItemProps = Omit<ButtonProps, 'onClick' | 'onSelect'> & {
  study: TranslatedProcedures
  isSelected?: boolean
  onSelect: (selected: Nullable<SelectedItem>) => void
}

const Item: React.FC<ItemProps> = props => {
  const { study, isSelected, onSelect, ...buttonProps } = props

  const { id, translatedName, translatedDescription } = study

  const onClick = () => {
    onSelect(isSelected ? null : { id, name: translatedName })
  }

  return <Button onClick={onClick} {...buttonProps} isActive={isSelected} my={1}>
    <HStack>
      <VStack>
        <Heading size='sm' fontWeight={'bolder'}>
          {translatedName}
        </Heading>
        {
          translatedDescription &&
          <Text fontSize={'sm'}>
            {translatedDescription}
          </Text>
        }
      </VStack>
      {
        isSelected &&
        <Icon as={CheckIcon} />
      }
    </HStack>
  </Button >
}

