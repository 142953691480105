import { gql } from '@apollo/client'
import { BasicPatient, FullPatientWithActiveVisit, FullBulkRegistrationHistory } from '../fragments'
export const GET_PATIENTS = gql`
  ${BasicPatient}
  query patients($q: String, $offset: Int, $limit: Int, $location: [String]) {
    patients(q: $q, offset: $offset, limit: $limit, location: $location) {
      docs {
        ...BasicPatient
      }
      count
    }
  }
`

export const GET_INPATIENTS = gql`
  ${FullPatientWithActiveVisit}
  query inpatients($q: String, $offset: Int, $limit: Int, $location: String, $visitType: String) {
    inpatients(q: $q, offset: $offset, limit: $limit, location: $location, visitType: $visitType) {
      docs {
        ...FullPatientWithActiveVisit
      }
      count
    }
  }
`

export const GET_PATIENT_BY_ID = gql`
  ${FullPatientWithActiveVisit}
  query patient($id: ID!) {
    patient(id: $id) {
      ...FullPatientWithActiveVisit
    }
  }
`

export const GET_PATIENT_WITH_ACTIVE_VISIT_BY_ID = gql`
  ${FullPatientWithActiveVisit}
  query patientWithActiveVisit($id: ID!) {
    patient(id: $id) {
      ...FullPatientWithActiveVisit
    }
  }
`

export const REGISTER_PATIENT = gql`
  ${FullPatientWithActiveVisit}
  mutation registerPatient($newPatient: NewPatientPersonalInfo!) {
    registerPatient(newPatient: $newPatient) {
      ...FullPatientWithActiveVisit
    }
  }
`

export const UPDATE_PERSONAL_INFO = gql`
  ${FullPatientWithActiveVisit}
  mutation updatePatientPersonalInfo($id: ID!, $newPersonalInfo: NewPatientPersonalInfo!) {
    updatePatientPersonalInfo(id: $id, newPersonalInfo: $newPersonalInfo) {
      ...FullPatientWithActiveVisit
    }
  }
`

export const UPDATE_PAYMENT_INFO = gql`
  ${FullPatientWithActiveVisit}
  mutation updatePatientPaymentInfo($id: ID!, $newPatientPaymentInfo: NewPatientPaymentInfo!) {
    updatePatientPaymentInfo(id: $id, newPatientPaymentInfo: $newPatientPaymentInfo) {
      ...FullPatientWithActiveVisit
    }
  }
`

export const UPDATE_CUSTOM_FIELDS = gql`
  ${FullPatientWithActiveVisit}
  mutation updateCustomFields(
    $patientId: ID!
    $newCustomFieldBoolean: [NewCustomFieldBooleanWithValue]!
    $newCustomFieldNumber: [NewCustomFieldNumberWithValue]!
    $newCustomFieldText: [NewCustomFieldTextWithValue]!
    $newCustomFieldDate: [NewCustomFieldDateWithValue]!
    $newCustomFieldTextList: [NewCustomFieldTextListWithValue]!
  ) {
    updatePatientCustomFields(
      patientId: $patientId
      newCustomFieldBoolean: $newCustomFieldBoolean
      newCustomFieldNumber: $newCustomFieldNumber
      newCustomFieldText: $newCustomFieldText
      newCustomFieldDate: $newCustomFieldDate
      newCustomFieldTextList: $newCustomFieldTextList
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`

export const DELETE_PATIENT = gql`
  mutation deletePatient($id: ID!) {
    deletePatient(id: $id) {
      done
    }
  }
`

export const CLEAN_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation cleanActiveVisit($patientId: ID!) {
    cleanActiveVisit(patientId: $patientId) {
      ...FullPatientWithActiveVisit
    }
  }
`

/**
 * Bulk registration
 */

export const BULK_REGISTRATION_HISTORY = gql`
  ${FullBulkRegistrationHistory}
  query bulkRegistrationHistory($systemId: String!, $locationId: String!) {
    bulkRegistrationHistory(systemId: $systemId, locationId: $locationId) {
      ...FullBulkRegistrationHistory
    }
  }
`

export const BULK_REGISTRATION = gql`
  mutation bulkRegistration(
    $patients: [NewPatientPersonalInfo!]!
    $systemId: String!
    $locationId: String!
    $bulkId: String
  ) {
    bulkRegistration(
      patients: $patients
      systemId: $systemId
      locationId: $locationId
      bulkId: $bulkId
    ) {
      done
    }
  }
`

export const BULK_DELETE = gql`
  mutation bulkDelete($bulkId: String!) {
    bulkDelete(bulkId: $bulkId) {
      done
    }
  }
`
