import { gql } from '@apollo/client'
import { UserSignature } from './common'

export const FullProcedure = gql`
  fragment FullProcedure on Procedure {
    id
    name {
      text
    }
    description {
      text
    }
    created {
      ...UserSignature
    }
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`
