import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  Icon,
  Text,
  FormControlProps,
} from '@chakra-ui/react'
import { useDebounceExecute } from '../useDebounceExecute'
import { useSession, VerifiableAttribute } from '../Auth'
import { Nullable } from '../../utils'
import { RiCheckDoubleLine as CheckedIcon } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { sitemap } from '../Routing'
const messages = defineMessages({
  verifyButton: {
    id: 'UI.button_send_verification_coce',
    defaultMessage: 'Send verification code',
  },
  verifiedFieldWarning: {
    id: 'PersonalInformation.verified_field_warning',
    defaultMessage:
      'This field is verified. If you modify its value you should perform the verification process again.',
  },
  limitExceededException: {
    id: 'StaffRegisterConfirm.attempt_limit_exceeded',
    defaultMessage: 'Attempt limit exceeded. Please, try again later.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has occurred.',
  },
})

interface CognitoAttributeProps extends FormControlProps {
  name: string
  label: string
  value: string
  parser?: (value: string) => string
  validate?: (value: string) => Nullable<string>
  isVerified?: boolean
  isVerifiable?: boolean
}

export const CognitoAttribute: React.FC<CognitoAttributeProps> = (props) => {
  const { name, label, value, isVerified, isVerifiable, validate, parser, ...controlProps } = props

  const intl = useIntl()

  const { updateAttribute } = useSession()
  const { execute } = useDebounceExecute(200)

  const [error, setError] = React.useState<Nullable<string>>(null)
  const [warning, setWarning] = React.useState<Nullable<string>>(null)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)

  const onError = (error) => {
    const { code } = error

    switch (code) {
      case 'LimitExceededException':
        setError(intl.formatMessage(messages.limitExceededException))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const saveAttribute = async (value) => {
    setWarning(null)
    setError(null)
    setIsSaving(true)

    try {
      const params = {
        attributeName: name,
        value: typeof parser === 'function'
          ? parser(value)
          : value,
      }
      await updateAttribute(params)
    } catch (error) {
      onError(error)
    }
    setIsSaving(false)
  }

  const onInputChange = (event) => {
    const { value } = event.target

    setWarning(null)

    // check for validator
    if (typeof validate === 'function') {
      const inputError = validate(value)

      if (!inputError) {
        execute(() => saveAttribute(value))
      } else {
        setError(inputError)
      }
    } else {
      execute(() => saveAttribute(value))
    }
  }

  const onFocus = () => {
    if (isVerifiable && isVerified) {
      setWarning(intl.formatMessage(messages.verifiedFieldWarning))
    }
  }
  return (
    <FormControl isInvalid={!!error} {...controlProps}>
      <FormLabel mr={0} display={'flex'} justifyContent={'space-between'} alignItems="center">
        <Text>{label}</Text>
        {isVerifiable && (
          <AttributeStatus
            name={name}
            isVerified={!!isVerified}
            onError={onError}
            isDisabled={isSaving}
          />
        )}
      </FormLabel>

      <Input
        title={label}
        placeholder={label}
        aria-label={label}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        defaultValue={value}
        onChange={onInputChange}
        onFocus={onFocus}
      />
      {warning && <FormHelperText>{warning}</FormHelperText>}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  )
}

interface AttributeStatusProps {
  name: string
  isVerified: boolean
  isDisabled: boolean
  onError: (error: any) => void
}
const AttributeStatus: React.FC<AttributeStatusProps> = (props) => {
  const { name, isVerified, isDisabled, onError } = props

  const intl = useIntl()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const { requestAttributeVerification } = useSession()

  if (isVerified) {
    return <Icon as={CheckedIcon} />
  }

  const onClick = async () => {
    setIsLoading(true)

    try {
      const params = {
        attributeName: name as VerifiableAttribute,
      }

      await requestAttributeVerification(params)
      setIsLoading(true)

      // replace with a fn with an enum to consider all the VerifiableAttribute
      if (name === VerifiableAttribute.EMAIL) {
        navigate(sitemap.auth.routes.emailVerification.absolutePath)
      } else {
        navigate(sitemap.auth.routes.phoneVerification.absolutePath)
      }
    } catch (error) {
      setIsLoading(false)
      onError(error)
    }
  }

  return (
    <Button
      variant="ghost"
      size={'sm'}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {intl.formatMessage(messages.verifyButton)}
    </Button>
  )
}
