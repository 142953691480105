import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Box, BoxProps, Text } from '@chakra-ui/react'
import { TranslatableText } from '../../api/interfaces'
import { useTranslatedList } from '../i18n'

const messages = defineMessages({
  pertinentLabel: {
    id: 'UI.pertinent_label',
    defaultMessage: 'Pertinent +/-'
  }
})

type PertinentListProps = BoxProps & {
  items: Array<TranslatableText>
}

export const PertinentList: React.FC<PertinentListProps> = (props) => {
  const { items, ...boxProps } = props

  const intl = useIntl()
  const translatedItems = useTranslatedList(items)
  if (items.length === 0) {
    return null
  }

  return <Box flexWrap={'wrap'} {...boxProps}>
    <Text color={'gray'} fontSize={'sm'}>
      {intl.formatMessage(messages.pertinentLabel)}
    </Text>
    <Text>
      {
        translatedItems.join(', ')
      }
    </Text>
  </Box>
}

