import { GlobalRole, Permissions } from '../../components/Auth'
import { Nullable } from '../../utils'

export const isUserAdmin = (permissionsAttr: Nullable<string>): boolean => {
  if (permissionsAttr === null) return false

  try {
    const permissions = new Permissions(permissionsAttr)

    return permissions.hasGlobalRole(GlobalRole.ADMIN)
  } catch (e) {
    return false
  }
}
