import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { GET_PATIENTS } from '../../api/requests/patients'
import { BasePatient, PaginatedResult } from '../../api/interfaces'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { useSession } from '../Auth'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface PatientsVariables {
  q?: string
  offset?: number
  limit?: number
  location: Array<string>
}

interface PatientsQueryData {
  patients: PaginatedResult<BasePatient>
}

export type UsePatientsParams = QueryHookOptions<PatientsQueryData, PatientsVariables>

interface ReturnedValue {
  patients: Array<BasePatient>
  count: number
  isLoading: boolean
  error: any
  getPatient: (patientId: string) => Nullable<BasePatient>
  refetch: (newArgs?: PatientsVariables) => void
  onErrorClose: () => void
}

export const usePatients = (params?: UsePatientsParams): ReturnedValue => {
  const intl = useIntl()

  const { getUserLocation } = useSession()
  const { id: locationId } = getUserLocation()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)

    if (typeof params?.onError === 'function') {
      params.onError(errors)
    }
  }

  const { loading, data, refetch } = useQuery<PatientsQueryData, PatientsVariables>(GET_PATIENTS, {
    variables: {
      location: [locationId],
    },
    fetchPolicy: 'cache-and-network',
    onError,
    ...params,
  })

  const getPatient = React.useCallback(
    (patientId: string): Nullable<BasePatient> => {
      if (!data?.patients) {
        return null
      }

      const { docs } = data.patients
      const found = docs.find((patient) => patient.id === patientId)

      return found ?? null
    },
    [data]
  )

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading: loading,
    patients: data?.patients.docs ?? [],
    count: data?.patients.count ?? 0,
    error,
    getPatient,
    refetch,
    onErrorClose,
  }
}
