import { gql } from '@apollo/client'
import { UserSignature } from './common'

export const FullSurveyBody = gql`
  fragment FullSurveyBody on SurveyBody {
    version
    blocks {
      ... on SurveyHeaderBlock {
        id
        type
        data {
          text {
            text
          }
          level
        }
      }
      ... on SurveyParagraphBlock {
        id
        type
        data {
          text {
            text
          }
        }
      }
      ... on SurveyInputBlock {
        id
        type
        data {
          type
          value
          label {
            text
          }
        }
      }

      ... on SurveyRangeBlock {
        id
        type
        data {
          label {
            text
          }
          value
          min
          max
          step
        }
      }

      ... on SurveyCheckboxBlock {
        id
        type
        data {
          label {
            text
          }
          checked
        }
      }

      ... on SurveySelectorBlock {
        id
        type
        data {
          label {
            text
          }
          value
          options {
            text
          }
        }
      }
    }
  }
  ${UserSignature}
`

export const FullSurvey = gql`
  fragment FullSurvey on Survey {
    id
    name {
      text
    }
    description {
      text
    }
    body {
      ...FullSurveyBody
    }
    created {
      ...UserSignature
    }
    updated {
      ...UserSignature
    }
  }
  ${FullSurveyBody}
  ${UserSignature}
`
