import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { GET_PATIENT_BY_ID } from '../../api/requests/patients'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { FullPatient, FullPatientWithActiveVisit } from '../../api/interfaces'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})
interface PatientQueryParams {
  id: string
}

interface PatientQueryData {
  patient: FullPatientWithActiveVisit
}

type UsePatientByIDParams = QueryHookOptions<PatientQueryData, PatientQueryParams> & {
  id: string
}

interface ReturnedValue {
  patient: Nullable<FullPatient>
  isLoading: boolean
  error: Nullable<string>
  onErrorClose: () => void
}

export function usePatientByID(params: UsePatientByIDParams): ReturnedValue {
  const { id, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const { loading: isLoading, data } = useQuery(GET_PATIENT_BY_ID, {
    variables: {
      id,
    },
    ...rest,
    onError,
  })

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading,
    patient: data ? data.patient : null,
    error,
    onErrorClose,
  }
}
