import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { GET_INPATIENTS } from '../../api/requests/patients'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { FullPatientWithActiveVisit, PaginatedResult } from '../../api/interfaces'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface InpatientsVariables {
  q?: string
  offset?: number
  limit?: number
  location: string
}

interface InpatientsQueryData {
  inpatients: PaginatedResult<FullPatientWithActiveVisit>
}

export type UseInpatientsParams = QueryHookOptions<InpatientsQueryData, InpatientsVariables>

interface ReturnedValue {
  patients: Array<FullPatientWithActiveVisit>
  count: number
  isLoading: boolean
  error: any
  onErrorClose: () => void
  refetch: (newArgs?: InpatientsVariables) => void
}

export const useInpatients = (params: UseInpatientsParams): ReturnedValue => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery<InpatientsQueryData, InpatientsVariables>(GET_INPATIENTS, {
    onError,
    ...params,
  })

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading,
    count: data && data.inpatients ? data.inpatients.count : 0,
    patients: data && data.inpatients ? data.inpatients.docs : [],
    error,
    onErrorClose,
    refetch,
  }
}
