import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { BasePatientFormValues, PatientRegistrationParams } from '.'
import { extractFirstErrorCode, isValidSex, Nullable } from '../../utils'
import {
  isValidEmail,
  isValidPhoneNumber,
  sanitizePhone,
  ValidateFn,
  ValidationError,
} from '../Form'
import { UPDATE_PAYMENT_INFO, UPDATE_PERSONAL_INFO } from '../../api/requests/patients'
import phone from 'phone'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { isValidDate } from '../DateAndTime'

const messages = defineMessages({
  invalidLocation: {
    id: 'PersonalInfoCard.invalid_location',
    defaultMessage: 'Invalid location',
  },
  errorSavingPatient: {
    id: 'PersonalInfoCard.error_saving_patient_info',
    defaultMessage: 'Could not save the patient info. Please, try again',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  country: {
    id: 'PersonalInfoCard.country',
    defaultMessage: 'Country',
  },
  invalidDate: {
    id: 'PersonalInfoCard.invalid_date',
    defaultMessage: 'Invalid date',
  },
  invalidMail: {
    id: 'PersonalInfoCard.invalid_email_address',
    defaultMessage: 'Invalid e-mail address',
  },
  invalidPhone: {
    id: 'PersonalInfoCard.invalid_phone_number',
    defaultMessage: 'Invalid phone number',
  },
  errorUpdatingPaymentInfo: {
    id: 'PersonalInfoCard.error_saving_payment_info',
    defaultMessage: 'Could not save the payment info. Please, try again',
  },
  errorDefaultPaymentMethod: {
    id: 'PersonalInfoCard.invalid_default_payment_method',
    defaultMessage: 'Invalid default payment method',
  },
})

interface PatientUpdateHookVariables {
  id: string
  newPersonalInfo: PatientRegistrationParams
}

interface UpdatePatientMutationData {
  updatePatient: FullPatientWithActiveVisit
}

export type UsePatientUpdateParams = MutationHookOptions<
  UpdatePatientMutationData,
  PatientUpdateHookVariables
> & {
  id: string
}

export const usePatientUpdate = (params: UsePatientUpdateParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<BasePatientFormValues> = async (values) => {
    const errors: ValidationError<BasePatientFormValues> = {}

    if (!values.first_name) {
      errors.first_name = intl.formatMessage(messages.required)
    }

    if (!values.last_name) {
      errors.last_name = intl.formatMessage(messages.required)
    }

    if (!values.birthdate) {
      errors.birthdate = intl.formatMessage(messages.required)
    } else if (!isValidDate(values.birthdate)) {
      errors.birthdate = intl.formatMessage(messages.invalidDate)
    }

    if (!values.sex || !isValidSex(values.sex)) {
      errors.sex = intl.formatMessage(messages.required)
    }

    if (values.email && !isValidEmail(values.email)) {
      errors.email = intl.formatMessage(messages.invalidMail)
    }

    const phoneValidationOptions = { country: values.country }
    if (values.phone_number && !isValidPhoneNumber(values.phone_number, phoneValidationOptions)) {
      errors.phone_number = intl.formatMessage(messages.invalidPhone)
    }

    if (!values.country) {
      errors.country = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'error_saving_patient_info':
        return setError(intl.formatMessage(messages.errorSavingPatient))

      case 'invalid_location':
      case 'invalid_signature':
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [updatePatient, { loading: isLoading }] = useMutation<
    UpdatePatientMutationData,
    PatientUpdateHookVariables
  >(UPDATE_PERSONAL_INFO, {
    onError,
    ...params,
  })

  const onSubmit = (values: BasePatientFormValues) => {
    setError(null)

    const { address, locality, country, postal_code, birthdate, ...rest } = values

    const [line_1, line_2, line_3] = (address || '').split('\n')
    const newPersonalInfo = {
      ...rest,
      birthdate: birthdate?.toISOString(),
      address: {
        line_1,
        line_2,
        line_3,
        locality,
        postal_code,
        country,
      },
    }

    if (values?.phone_number) {
      const phoneValidationOptions = { country: values.country }
      const sanitized = sanitizePhone(values.phone_number, phoneValidationOptions)
      const parsed = phone(sanitized)

      if (parsed.isValid) {
        newPersonalInfo.phone_number_e164 = parsed.phoneNumber
      }
    }
    const variables: PatientUpdateHookVariables = {
      id: params.id,
      newPersonalInfo: newPersonalInfo as PatientRegistrationParams,
    }

    updatePatient({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}

interface NewPatientPaymentInfo {
  pricelist: string
}
interface UpdatePaymentMethodHookVariables {
  id: string
  newPatientPaymentInfo: NewPatientPaymentInfo
}

interface UpdatePaymentMethodMutationData {
  updatePatientPaymentInfo: FullPatientWithActiveVisit
}

export type UseUpdatePaymentMethodParams = MutationHookOptions<
  UpdatePaymentMethodMutationData,
  UpdatePaymentMethodHookVariables
> & {
  id: string
}

export const useUpdatePaymentMethod = (params: UseUpdatePaymentMethodParams) => {
  const { id, ...rest } = params
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<NewPatientPaymentInfo> = async (values) => {
    const errors: ValidationError<NewPatientPaymentInfo> = {}

    if (!values.pricelist) {
      errors.pricelist = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'error_saving_payment_info':
        return setError(intl.formatMessage(messages.errorUpdatingPaymentInfo))

      case 'invalid_default_payment_method':
        return setError(intl.formatMessage(messages.errorDefaultPaymentMethod))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }
  const onCompleted = () => {
    setError(null)
  }

  const [updatePaymentInfo, { loading: isLoading }] = useMutation<
    UpdatePaymentMethodMutationData,
    UpdatePaymentMethodHookVariables
  >(UPDATE_PAYMENT_INFO, {
    ...rest,
    onError,
    onCompleted,
  })

  const onErrorClose = () => {
    setError(null)
  }

  const onSubmit = (values: NewPatientPaymentInfo) => {
    const variables: UpdatePaymentMethodHookVariables = {
      id,
      newPatientPaymentInfo: {
        pricelist: values.pricelist,
      },
    }

    updatePaymentInfo({ variables })
  }

  return {
    error,
    validate,
    onSubmit,
    isLoading,
    onErrorClose,
  }
}
