import { gql } from '@apollo/client'

export const FullExamWithoutId = gql`
  fragment FullExamWithoutId on Exam {
    examId
    name {
      text
    }
    sections {
      type
      key
      name {
        text
      }
      note
      groups {
        key
        type
        physical_exam_codes
        choices {
          key
          type
          selected
          name {
            text
          }
          details {
            list_id
            key
            selected_items {
              text
            }
            type
            name {
              text
            }
            value {
              text
            }
          }
        }
      }
    }
  }
`

export const FullExam = gql`
  fragment FullExam on Exam {
    id
    ...FullExamWithoutId
  }
  ${FullExamWithoutId}
`
