import { FullComment, Signature, TranslatableText } from './common'
import { Nullable } from '../../utils'
import { FullListItem } from './lists'
import { BasePatient } from './patients'
import { FullVitals } from './triage'
import { FullChecklist } from './checklists'
import { FullExamWithoutId } from './exams'
import { FullStudyOrder } from './studies'
import { SurveyBody } from './surveys'
import { FullProcedureOrder } from './procedures'

export enum VisitType {
  GENERAL_PRACTITIONER = 'General Practitioner',
  OBGYN = 'OBGYN',
  ANTENATAL_CARE = 'Antenatal Care',
  PEDIATRICIAN = 'Pediatrician',
  LACTATION_COUNSELING = 'Lactation Counseling',
  FAMILY_PLANNING = 'Family Planning',
  INTERNIST = 'Internist',
  INMUNIZATION = 'Immunization',
  DENTAL = 'Dental',
  TESTING = 'Testing',
  OTHER = 'Other',
  MIDWIFE = 'Midwife',

  // deprecated
  SICK_VISIT = 'Sick Visit',
  FOLLOW_UP = 'Follow-up',
  CHIILD_HEALTH = 'Child Health',
  DELIVERY = 'Delivery',
  PHYSICAL_EXAM = 'Physical Exam',
  TRANSFER = 'Transfer',
  MEDICAL_CLEARANCE = 'Medical Clearance',
}

// These ones were depreecated
export enum DeprecatedVisitType {
  SICK_VISIT = 'Sick Visit',
  FOLLOW_UP = 'Follow-up',
  CHIILD_HEALTH = 'Child Health',
  DELIVERY = 'Delivery',
  PHYSICAL_EXAM = 'Physical Exam',
  TRANSFER = 'Transfer',
  MEDICAL_CLEARANCE = 'Medical Clearance',
}

export enum ClinicalStatusPriority {
  ROUTINE = 'Routine',
  GUARDED = 'Guarded',
  EMERGENCY = 'Emergency',
  DECEASED = 'Deceased',
}

export enum HowLong {
  JUST_NOW = 'Just now',
  AN_HOUR = 'An hour',
  FEW_HOURS = 'Few hours',
  SEVERAL_HOURS = 'Several hours',
  A_DAY = 'A day',
  FEW_DAYS = 'Few days',
  SEVERAL_DAYS = 'Several days',
  A_WEEK = 'A week',
  FEW_WEEKS = 'Few weeks',
  SEVERAL_WEEKS = 'Several weeks',
  A_MONTH = 'A month',
  FEW_MONTHS = 'Few months',
  SEVERAL_MONTHS = 'Several months',
  A_YEAR = 'A year',
  FEW_YEARS = 'Few years',
  SEVERAL_YEARS = 'Several years',
}

export enum FollowUp {
  TOMORROW = 'Tomorrow',
  TWO_DAYS = '2-3 Days',
  FOUR_DAYS = '4-6 Days',
  WEEK = 'One Week',
  TWO_WEEKS = 'Two Weeks',
  THREE_WEEKS = 'Three Weeks',
  MONTH = 'One Month',
  TWO_MONTHS = 'Two Months',
  THREE_MONTHS = 'Three Months',
  SIX_MONTHS = 'Six Months',
  YEAR = 'One Year',
}

export enum Disposition {
  DISCHARGE_HOME = 'Discharge home',
  OBSERVE = 'Observe',
  INTERNAL_REFERRAL = 'Internal Referral',
  EXTERNAL_REFERRAL = 'External Referral',
  TRANSFER = 'Transfer',
  ADMIT = 'Admit',
}

export enum StatusAssesment {
  STABLE = 'Stable',
  WELL = 'Well',
  GUARDED = 'Guarded',
  CRITICAL = 'Critical',
  DECEASED = 'Deceased',
}

export interface Characteristic {
  id: string
  list: Nullable<FullListItem>
  values: Nullable<Array<FullListItem>>
}

export interface Complaints {
  id: string
  key: number
  type: string
  name: TranslatableText
  note: Nullable<string>
  degree: Nullable<FullListItem>
  characteristics: Nullable<Array<Characteristic>>
  timings: Nullable<Array<FullListItem>>
  bodyParts: Nullable<Array<FullListItem>>
  positions: Nullable<Array<FullListItem>>
}

export interface ChiefComplaint {
  complaint: Nullable<string>
  how_long: Nullable<HowLong>
}

export interface NewChiefComplaint {
  complaint: Nullable<string>
  howLong: Nullable<HowLong>
}

export interface ClinicalStatus {
  priority: Nullable<ClinicalStatusPriority>
}

export interface ManualDiagnosis {
  id: Nullable<string>
  type: string
  description: string
  treatment: Nullable<string>
  created: Nullable<Signature>
}

export interface Assessment {
  id: Nullable<string>
  type: string
  description: string
  created: Nullable<Signature>
}

export interface Treatment {
  id: Nullable<string>
  type: string
  treatment: string
  created: Nullable<Signature>
}

export interface VitalsHistory {
  end: Nullable<string>
  start: Nullable<string>
  vitals: Nullable<FullVitals>
}
export interface FullHistoryItem {
  name: string
  historyId: Nullable<string>
  signature: Nullable<Signature>
  start: Nullable<string>
  end: Nullable<string>
}

export interface FullSurveyHistoryItem {
  historyId: string
  surveyId: string
  name: TranslatableText
  signature: Signature
  description?: TranslatableText
  body: Nullable<SurveyBody>
}
export interface ActiveVisitHistory {
  complaints: Nullable<Complaints>
  generalReviewOfSystems: Nullable<FullExamWithoutId>
  manualMedications: Nullable<Array<FullHistoryItem>>
  manualProblems: Nullable<Array<FullHistoryItem>>
  allergies: Nullable<Array<FullHistoryItem>>
  family: Nullable<Array<FullHistoryItem>>
  diet: Nullable<Array<FullHistoryItem>>
  development: Nullable<Array<FullHistoryItem>>
  social: Nullable<Array<FullHistoryItem>>
  comments: Nullable<Array<FullComment>>
  surveys: Nullable<Array<FullSurveyHistoryItem>>
}

export interface PastVisit {
  id: string
  start: string
  end: string
  type: VisitType
  chief_complaint: Nullable<ChiefComplaint>
  assessments: Nullable<Array<Assessment>>
  treatments: Nullable<Array<Treatment>>
  diagnoses: Nullable<Array<FullChecklist>>
}

export interface FullHistory {
  vitals: Nullable<Array<VitalsHistory>>
  pastVisits: Nullable<Array<PastVisit>>
  manualMedications: Nullable<Array<FullHistoryItem>>
  manualProblems: Nullable<Array<FullHistoryItem>>
  allergies: Nullable<Array<FullHistoryItem>>
  familyHistory: Nullable<Array<FullHistoryItem>>
  dietHistory: Nullable<Array<FullHistoryItem>>
  developmentHistory: Nullable<Array<FullHistoryItem>>
  socialHistory: Nullable<Array<FullHistoryItem>>
  surveys: Nullable<Array<FullSurveyHistoryItem>>
}

/**
 * Ids
 */
interface VisitRXs {
  prescriptions: Nullable<Array<string>>
  orders: Nullable<Array<string>>
}
export interface ActiveVisit {
  id: string
  type: VisitType
  location: string
  start: string
  end: string
  backdated: Nullable<boolean>
  chief_complaint: Nullable<ChiefComplaint>
  clinical_status: Nullable<ClinicalStatus>
  vitals: Nullable<FullVitals>

  history: Nullable<ActiveVisitHistory>
  exam: FullExamWithoutId

  status_assesment: Nullable<StatusAssesment>
  disposition: Nullable<Disposition>
  followup: Nullable<FollowUp>

  // aux for disposition
  external_referral_location: Nullable<string>
  external_referral_reason: Nullable<string>
  new_visit_type: Nullable<string>

  procedure_orders: Nullable<Array<FullProcedureOrder>>
  study_orders: Nullable<Array<FullStudyOrder>>
  checklists: Nullable<Array<FullChecklist>>
  rx: Nullable<VisitRXs>
  assessments: Nullable<Array<Assessment>>
  treatments: Nullable<Array<Treatment>>
  progress_notes: Nullable<Array<FullComment>>

  patient: Nullable<BasePatient>
  signature: Nullable<Signature>
  exam_started_by: Nullable<Signature>
}

export interface FullVisit extends ActiveVisit {
  patient: BasePatient
}
