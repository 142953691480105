import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { classifySectionGroups } from './utils'
import {
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { FullExamSection } from '../../api/interfaces'
import { DynamicTranslation } from '../i18n'
import { ClearingCodesWithText, Nullable } from '../../utils'
import { GeneralReviewOfSystemGroup } from './'

const messages = defineMessages({
  noteLabel: {
    id: 'UI.note_label',
    defaultMessage: 'Notes',
  },
  closeButton: {
    id: 'UI.button_close',
    defaultMessage: 'Close',
  },
  statusTitle: {
    id: 'ExamSectionModal.status_title',
    defaultMessage: 'Status',
  },
  proceduresTitle: {
    id: 'ExamSectionModal.procedures_title',
    defaultMessage: 'Procedures',
  },
})

type GeneralReviewOfSystemSectionModalProps = {
  patientId: string
  visitId: string
  selectedSection: Nullable<FullExamSection>
  onClose: () => void
  clearingCodes: ClearingCodesWithText
}
export const GeneralReviewOfSystemSectionModal: React.FC<GeneralReviewOfSystemSectionModalProps> = (
  props
) => {
  const { visitId, patientId, selectedSection, onClose, clearingCodes } = props

  const intl = useIntl()

  const { statuses, procedures } = classifySectionGroups(selectedSection)
  const isFullWidth = useBreakpointValue({ base: true, sm: false })

  if (selectedSection === null) {
    return null
  }

  return (
    <Modal isOpen onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <DynamicTranslation message={selectedSection.name} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {statuses.length > 0 && (
            <VStack alignItems={'self-start'}>
              <Heading size={'sm'}>{intl.formatMessage(messages.statusTitle)}</Heading>
              {statuses.map((group, index) => {
                const key = `${group.key}-${index}`

                return (
                  <GeneralReviewOfSystemGroup
                    key={key}
                    group={group}
                    patientId={patientId}
                    visitId={visitId}
                    sectionKey={selectedSection.key}
                    clearingCodes={clearingCodes}
                  />
                )
              })}
            </VStack>
          )}
          {procedures.length > 0 && (
            <VStack alignItems={'self-start'}>
              <Heading size={'sm'}>{intl.formatMessage(messages.proceduresTitle)}</Heading>
              {procedures.map((group, index) => {
                const key = `${group.key}-${index}`

                return (
                  <GeneralReviewOfSystemGroup
                    key={key}
                    group={group}
                    patientId={patientId}
                    visitId={visitId}
                    sectionKey={selectedSection.key}
                    clearingCodes={clearingCodes}
                  />
                )
              })}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose} isFullWidth={isFullWidth}>
            {intl.formatMessage(messages.closeButton)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
