import React from 'react'
import { Nullable } from '../utils'

type Callback = () => void
export const useDebounceExecute = (delay: number) => {
  const ref = React.useRef<Nullable<NodeJS.Timeout>>(null)

  const execute = (callback: Callback) => {
    if (ref.current) {
      clearTimeout(ref.current)
    }

    const timeoutId = setTimeout(callback, delay)
    ref.current = timeoutId
  }

  React.useEffect(() => {
    const clean = () => {
      if (ref.current !== null) {
        clearTimeout(ref.current)
      }
    }

    return clean
  }, [])
  return { execute }
}
