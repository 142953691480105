import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useBreakpointValue,
  Button,
  Text,
  useDisclosure,
  ButtonProps,
  HStack,
  Heading,
  VStack,
} from '@chakra-ui/react'
import { useSystems, useUpdatePaymentSettings } from '../Systems'
import { Form } from 'react-final-form'
import { PaymentTypeField } from '../Form/PaymentTypeField'
import { ErrorViewer } from '../ErrorViewer'
import { CurrencyField } from '../Form'
import { useSession } from '../Auth'
import { AccountStatus } from '../Auth/subscription'


// static translations
const messages = defineMessages({
  paymentSetting: {
    id: 'PricelistSelector.title',
    defaultMessage: 'Payment'
  },
  paymentSettingDescription: {
    id: 'PricelistSelector.description',
    defaultMessage: 'Default payment method and currency'
  },
  currency: {
    id: 'PaymentSetting.currency',
    defaultMessage: 'Currency'
  },
  paymentMethod: {
    id: 'PricelistSelector.payment_method',
    defaultMessage: 'Payment method'
  },
  saveButton: {
    id: 'UI.save_button',
    defaultMessage: 'Save'
  },
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close'
  },
  updateButton: {
    id: 'UI.button_update',
    defaultMessage: 'Update',
  },
})



interface PaymentSettingModalProps {
  isOpen: boolean
  onClose: () => void
}

const PaymentSettingModal: React.FC<PaymentSettingModalProps> = (props) => {
  const { isOpen } = props

  const intl = useIntl()

  const { getMyLocation } = useSystems()
  const { country } = getMyLocation()
  const {
    initialValues,
    isLoading,
    error,
    onErrorClose,
    onSubmit
  } = useUpdatePaymentSettings({ onCompleted: props.onClose })

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={({ handleSubmit, pristine }) => {

          return (
            <form onSubmit={handleSubmit}>
              <ModalContent>
                <ModalHeader>{intl.formatMessage(messages.paymentMethod)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <PaymentTypeField
                    name="defaultPricelist"
                    label={intl.formatMessage(messages.paymentMethod)}
                  />

                  <CurrencyField
                    country={country ?? ''}
                    name="currency"
                    label={intl.formatMessage(messages.currency)}
                  />

                  {error && <ErrorViewer title={error} onClose={onErrorClose} />}
                </ModalBody>

                <ModalFooter>
                  <Button mr={3} onClick={props.onClose} isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.closeButton)}
                  </Button>
                  <Button colorScheme={'brand'} isLoading={isLoading} type="submit" isFullWidth={isFullWidth} isDisabled={pristine}>
                    {intl.formatMessage(messages.saveButton)}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export const PaymentSettings: React.FC<Omit<ButtonProps, 'onClick'>> = (props) => {
  const { ...buttonProps } = props

  const intl = useIntl()

  const { getAccountStatus } = useSession()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const onClick = () => {
    onOpen()
  }

  const accountStatus = getAccountStatus()
  const isDisabled =
    props.isDisabled ||
    accountStatus === AccountStatus.PAYMENT_REQUIRED ||
    accountStatus === AccountStatus.SETUP_PENDING ||
    accountStatus === AccountStatus.REACTIVATION_REQUIRED

  return (
    <>
      <HStack justifyContent={'space-between'} p={2}>
        <VStack spacing={0} alignItems="flex-start">
          <Heading size="sm">{intl.formatMessage(messages.paymentSetting)}</Heading>
          <Text as={'span'} fontSize="sm">
            {intl.formatMessage(messages.paymentSettingDescription)}
          </Text>
        </VStack>
        <Button colorScheme={'brand'} variant="ghost" onClick={onClick} {...buttonProps} isDisabled={isDisabled}>
          {intl.formatMessage(messages.updateButton)}
        </Button>
      </HStack>
      {isOpen && <PaymentSettingModal isOpen={isOpen} onClose={onClose} />}
    </>
  )
}

