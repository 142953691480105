import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Nullable } from '../../utils'
import { LogInParams, useSession } from '../../components/Auth'
import { ValidateFn, ValidationError } from '../../components/Form'
import { useNavigate } from 'react-router-dom'
import { sitemap } from '../../components/Routing/sitemap'

const messages = defineMessages({
  requiredUsername: {
    id: 'StaffLogin.username_required_error',
    defaultMessage: 'Username is required',
  },
  requiredPassword: {
    id: 'StaffLogin.password_required_error',
    defaultMessage: 'Password is required',
  },
  wrongCredentials: {
    id: 'StaffLogin.incorrect_username_or_password',
    defaultMessage: 'Incorrect username or password',
  },
  accountDisabled: {
    id: 'StaffLogin.account_disabled',
    defaultMessage: 'Your account was disabled. Please, contact to our staff',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export const useLogIn = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { logIn } = useSession()

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (err) => {
    const { code, message } = err

    switch (code) {
      case 'NotAuthorizedException': {
        if (message === 'User is disabled.') {
          setError(intl.formatMessage(messages.accountDisabled))
        } else {
          setError(intl.formatMessage(messages.wrongCredentials))
        }
        break
      }

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const validate: ValidateFn<LogInParams> = (values) => {
    const errors: ValidationError<LogInParams> = {}

    if (!values.password) errors.password = intl.formatMessage(messages.requiredPassword)
    if (!values.username) errors.username = intl.formatMessage(messages.requiredUsername)

    return errors
  }

  const onSubmit = async (values: LogInParams): Promise<void> => {
    setIsLoading(true)
    try {
      const { totpRequired } = await logIn(values)

      if (totpRequired) {
        navigate(sitemap.unauth.routes.mfa.absolutePath)
      }
    } catch (error: any) {
      setIsLoading(false)

      if (error && error?.code === 'UserNotConfirmedException') {
        navigate(sitemap.unauth.routes.signUpConfirm.absolutePath)
      } else {
        translateError(error)
      }
    }
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
  }
}
