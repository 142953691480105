import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  Box,
  Button,
  ButtonProps,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { AsyncImage } from '..'
import { RiImageLine as ImageIcon } from 'react-icons/ri'
const messages = defineMessages({
  buttonClose: {
    id: 'UI.button_close',
    defaultMessage: 'Close'
  },
  imageLabel: {
    id: 'ReferenceImage.image_label',
    defaultMessage: 'Image'
  }
})

type ReferenceImageProps = Omit<ButtonProps, 'onClick'> & {
  url: string
}

export const ReferenceImage: React.FC<ReferenceImageProps> = props => {
  const { url, ...buttonProps } = props

  const intl = useIntl()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return <Box display='inline' mx={2}>
    <Button onClick={onOpen} variant='link' colorScheme={'brand'} alignItems='center' {...buttonProps}>
      <Icon as={ImageIcon} />
      <Text ml={1}>
        {intl.formatMessage(messages.imageLabel)}
      </Text>
    </Button>

    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>

        <ModalCloseButton />
        <ModalBody >
          <AsyncImage
            alt='Reference'
            borderRadius='md'
            src={url}
            objectFit='cover'
            boxProps={{
              pt: 10,
              display: 'flex',
              justifyContent: 'center',
              flex: 1
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='brand' mr={3} onClick={onClose}>
            {intl.formatMessage(messages.buttonClose)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </Box>

}

