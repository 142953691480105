import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { GET_PRICELISTS } from '../../api/requests/pricing'
import { PaginatedResult, Pricelist } from '../../api/interfaces'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface PricelistsVariables {
  q?: string
  offset?: number
  limit?: number
}

interface PricelistsQueryData {
  pricelists: PaginatedResult<Pricelist>
}

export type UsePricelistsParams = QueryHookOptions<PricelistsQueryData, PricelistsVariables>

interface ReturnedValue {
  pricelists: Array<Pricelist>
  count: number
  isLoading: boolean
  error: any
  getPricelist: (pricelistId: string) => Nullable<Pricelist>
  refetch: (newArgs?: PricelistsVariables) => void
  onErrorClose: () => void
}

export const usePricelists = (params?: UsePricelistsParams): ReturnedValue => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (errors) => {
    if (params?.onError) {
      params.onError(errors)
    }

    const code = extractFirstErrorCode(errors)
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onCompleted = (data) => {
    setError(null)

    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const { loading, data, refetch } = useQuery<PricelistsQueryData, PricelistsVariables>(
    GET_PRICELISTS,
    {
      ...params,
      onError,
      onCompleted,
    }
  )

  const getPricelist = React.useCallback(
    (pricelistId: string): Nullable<Pricelist> => {
      if (!data?.pricelists) {
        return null
      }

      const { docs } = data.pricelists
      const found = docs.find((pricelist) => pricelist.id === pricelistId)

      return found ?? null
    },
    [data]
  )

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading: loading,
    pricelists: data?.pricelists.docs ?? [],
    count: data?.pricelists.count ?? 0,
    error,
    getPricelist,
    refetch,
    onErrorClose,
  }
}
