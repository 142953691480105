import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import {
  ADD_ENTRY_TO_DIET_HISTORY,
  ADD_ENTRY_TO_SOCIAL_HISTORY,
  ADD_ENTRY_TO_DEVELOPMENT_HISTORY,
  ADD_ENTRY_TO_FAMILY_HISTORY,
  ADD_ENTRY_TO_ALLERGIES_HISTORY,
  ADD_ENTRY_TO_MANUAL_PROBLEMS_HISTORY,
  ADD_ENTRY_TO_MANUAL_MEDICATIONS_HISTORY,
  REMOVE_ENTRY_FROM_DIET_HISTORY,
  REMOVE_ENTRY_FROM_SOCIAL_HISTORY,
  REMOVE_ENTRY_FROM_DEVELOPMENT_HISTORY,
  REMOVE_ENTRY_FROM_FAMILY_HISTORY,
  REMOVE_ENTRY_FROM_ALLERGIES_HISTORY,
  REMOVE_ENTRY_FROM_MANUAL_PROBLEMS_HISTORY,
  REMOVE_ENTRY_FROM_MANUAL_MEDICATIONS_HISTORY,
} from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ValidateFn, ValidationError } from '../Form'

const messages = defineMessages({
  patientNotFound: {
    id: 'MedicalHistory.patient_not_found',
    defaultMessage: 'Patient not found',
  },
  errorAddingEntry: {
    id: 'MedicalHistory.error_adding_entry',
    defaultMessage: 'Could not add entry to the history. Please, try again',
  },
  errorRemovingEntry: {
    id: 'MedicalHistory.error_removing_entry',
    defaultMessage: 'Could not remove entry from the history. Please, try again',
  },
  errorHistoryActionsDefault: {
    id: 'MedicalHistory.error_history_actions_default',
    defaultMessage: 'Something went wrong while trying to perform an action in the history. Please, try again',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
})

/**
 * All the adding mutations share these interface
 */
interface AddEntryVariables {
  patientId: string
  visitId: string
  content: string
}

/**
 * We don't need to specify the returned value.
 * The cache will automatically updated
 */
export type UseAddEntryToHistoryParams = MutationHookOptions<any, AddEntryVariables> & {
  patientId: string
  visitId: string
}

export interface AddEntryFormValues {
  content: string
}

export const useAddManualMedicationToHistory = (params: UseAddEntryToHistoryParams) => {
  const { patientId, visitId, ...rest } = params
  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<AddEntryFormValues> = async (values) => {
    const errors: ValidationError<AddEntryFormValues> = {}

    if (!values.content) {
      errors.content = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_content_to_history':
        return setError(intl.formatMessage(messages.errorAddingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [addManualMedication, { loading: isLoading }] = useMutation(ADD_ENTRY_TO_MANUAL_MEDICATIONS_HISTORY, {
    onError,
    ...rest,
  })

  const onSubmit = (values: AddEntryFormValues) => {
    setError(null)

    const variables: AddEntryVariables = {
      patientId,
      visitId,
      content: values.content,
    }

    addManualMedication({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}

export const useAddManualProblemsToHistory = (params: UseAddEntryToHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<AddEntryFormValues> = async (values) => {
    const errors: ValidationError<AddEntryFormValues> = {}

    if (!values.content) {
      errors.content = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_content_to_history':
        return setError(intl.formatMessage(messages.errorAddingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [addManualProblems, { loading: isLoading }] = useMutation(ADD_ENTRY_TO_MANUAL_PROBLEMS_HISTORY, {
    onError,
    ...rest,
  })

  const onSubmit = (values: AddEntryFormValues) => {
    setError(null)

    const variables: AddEntryVariables = {
      patientId,
      visitId,
      content: values.content,
    }

    addManualProblems({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}

export const useAddAllergiesToHistory = (params: UseAddEntryToHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<AddEntryFormValues> = async (values) => {
    const errors: ValidationError<AddEntryFormValues> = {}

    if (!values.content) {
      errors.content = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_content_to_history':
        return setError(intl.formatMessage(messages.errorAddingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [addAllergies, { loading: isLoading }] = useMutation(ADD_ENTRY_TO_ALLERGIES_HISTORY, {
    onError,
    ...rest,
  })

  const onSubmit = (values: AddEntryFormValues) => {
    setError(null)

    const variables: AddEntryVariables = {
      patientId,
      visitId,
      content: values.content,
    }

    addAllergies({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}

export const useAddFamilyToHistory = (params: UseAddEntryToHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<AddEntryFormValues> = async (values) => {
    const errors: ValidationError<AddEntryFormValues> = {}

    if (!values.content) {
      errors.content = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_content_to_history':
        return setError(intl.formatMessage(messages.errorAddingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [addFamily, { loading: isLoading }] = useMutation(ADD_ENTRY_TO_FAMILY_HISTORY, {
    onError,
    ...rest,
  })

  const onSubmit = (values: AddEntryFormValues) => {
    setError(null)

    const variables: AddEntryVariables = {
      patientId,
      visitId,
      content: values.content,
    }

    addFamily({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}

export const useAddDevelopmentToHistory = (params: UseAddEntryToHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<AddEntryFormValues> = async (values) => {
    const errors: ValidationError<AddEntryFormValues> = {}

    if (!values.content) {
      errors.content = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_content_to_history':
        return setError(intl.formatMessage(messages.errorAddingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [addDevelopment, { loading: isLoading }] = useMutation(ADD_ENTRY_TO_DEVELOPMENT_HISTORY, {
    onError,
    ...rest,
  })

  const onSubmit = (values: AddEntryFormValues) => {
    setError(null)

    const variables: AddEntryVariables = {
      patientId,
      visitId,
      content: values.content,
    }

    addDevelopment({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}

export const useAddDietToHistory = (params: UseAddEntryToHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<AddEntryFormValues> = async (values) => {
    const errors: ValidationError<AddEntryFormValues> = {}

    if (!values.content) {
      errors.content = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_content_to_history':
        return setError(intl.formatMessage(messages.errorAddingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [addDiet, { loading: isLoading }] = useMutation(ADD_ENTRY_TO_DIET_HISTORY, {
    onError,
    ...rest,
  })

  const onSubmit = (values: AddEntryFormValues) => {
    setError(null)

    const variables: AddEntryVariables = {
      patientId,
      visitId,
      content: values.content,
    }

    addDiet({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}

export const useAddSocialToHistory = (params: UseAddEntryToHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<AddEntryFormValues> = async (values) => {
    const errors: ValidationError<AddEntryFormValues> = {}

    if (!values.content) {
      errors.content = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_content_to_history':
        return setError(intl.formatMessage(messages.errorAddingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [addSocial, { loading: isLoading }] = useMutation(ADD_ENTRY_TO_SOCIAL_HISTORY, {
    onError,
    ...rest,
  })

  const onSubmit = (values: AddEntryFormValues) => {
    setError(null)

    const variables: AddEntryVariables = {
      patientId,
      visitId,
      content: values.content,
    }

    addSocial({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}

/**
 * All the removing mutations share these interface
 */
interface RemoveEntryVariables {
  patientId: string
  visitId: string
  historyId: string
}

/**
 * We don't need to specify the returned value.
 * The cache will automatically updated
 */
export type UseRemoveEntryFromHistoryParams = MutationHookOptions<any, RemoveEntryVariables> & {
  patientId: string
  visitId: string
}

export const useRemoveManualMedicationFromHistory = (params: UseRemoveEntryFromHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_removing_content_from_history':
        return setError(intl.formatMessage(messages.errorRemovingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [removeManualMedication, { loading: isLoading }] = useMutation(REMOVE_ENTRY_FROM_MANUAL_MEDICATIONS_HISTORY, {
    onError,
    ...rest,
  })

  const onDelete = (historyId: string) => {
    setError(null)

    const variables: RemoveEntryVariables = {
      patientId,
      visitId,
      historyId,
    }

    removeManualMedication({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}

export const useRemoveManualProblemsFromHistory = (params: UseRemoveEntryFromHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_removing_content_from_history':
        return setError(intl.formatMessage(messages.errorRemovingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [removeManualMedication, { loading: isLoading }] = useMutation(REMOVE_ENTRY_FROM_MANUAL_PROBLEMS_HISTORY, {
    onError,
    ...rest,
  })

  const onDelete = (historyId: string) => {
    setError(null)

    const variables: RemoveEntryVariables = {
      patientId,
      visitId,
      historyId,
    }

    removeManualMedication({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}

export const useRemoveAllergiesFromHistory = (params: UseRemoveEntryFromHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_removing_content_from_history':
        return setError(intl.formatMessage(messages.errorRemovingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [removeAllergies, { loading: isLoading }] = useMutation(REMOVE_ENTRY_FROM_ALLERGIES_HISTORY, {
    onError,
    ...rest,
  })

  const onDelete = (historyId: string) => {
    setError(null)

    const variables: RemoveEntryVariables = {
      patientId,
      visitId,
      historyId,
    }

    removeAllergies({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}

export const useRemoveFamilyFromHistory = (params: UseRemoveEntryFromHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_removing_content_from_history':
        return setError(intl.formatMessage(messages.errorRemovingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [removeFamily, { loading: isLoading }] = useMutation(REMOVE_ENTRY_FROM_FAMILY_HISTORY, {
    onError,
    ...rest,
  })

  const onDelete = (historyId: string) => {
    setError(null)

    const variables: RemoveEntryVariables = {
      patientId,
      visitId,
      historyId,
    }

    removeFamily({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}

export const useRemoveDevelopmentFromHistory = (params: UseRemoveEntryFromHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_removing_content_from_history':
        return setError(intl.formatMessage(messages.errorRemovingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [removeDevelopment, { loading: isLoading }] = useMutation(REMOVE_ENTRY_FROM_DEVELOPMENT_HISTORY, {
    onError,
    ...rest,
  })

  const onDelete = (historyId: string) => {
    setError(null)

    const variables: RemoveEntryVariables = {
      patientId,
      visitId,
      historyId,
    }

    removeDevelopment({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}

export const useRemoveDietFromHistory = (params: UseRemoveEntryFromHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_removing_content_from_history':
        return setError(intl.formatMessage(messages.errorRemovingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [removeDiet, { loading: isLoading }] = useMutation(REMOVE_ENTRY_FROM_DIET_HISTORY, {
    onError,
    ...rest,
  })

  const onDelete = (historyId: string) => {
    setError(null)

    const variables: RemoveEntryVariables = {
      patientId,
      visitId,
      historyId,
    }

    removeDiet({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}

export const useRemoveSocialFromHistory = (params: UseRemoveEntryFromHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_removing_content_from_history':
        return setError(intl.formatMessage(messages.errorRemovingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [removeSocial, { loading: isLoading }] = useMutation(REMOVE_ENTRY_FROM_SOCIAL_HISTORY, {
    onError,
    ...rest,
  })

  const onDelete = (historyId: string) => {
    setError(null)

    const variables: RemoveEntryVariables = {
      patientId,
      visitId,
      historyId,
    }

    removeSocial({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}
