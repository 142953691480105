import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { GET_SCHEDULED_VISITS } from '../../api/requests/scheduler'
import { useSystems } from '../Systems'
import { PaginatedResult, ScheduledVisit } from '../../api/interfaces'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface ScheduledVisitsVariables {
  location: string
  room: string
  from: string
  to: string
}

interface ScheduledVisitsData {
  scheduledVisits: PaginatedResult<ScheduledVisit>
}

export type UseScheduledVisitsParams = QueryHookOptions<ScheduledVisitsData, ScheduledVisitsVariables> & {
  roomId: Nullable<string>
  from: string
  to: string
}

export const useScheduledVisits = (params: UseScheduledVisitsParams) => {
  const { roomId, from, to, ...rest } = params
  const intl = useIntl()

  const { getMyLocation } = useSystems()
  const { id: locationId } = getMyLocation()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)

    if (typeof rest?.onError === 'function') {
      rest.onError(errors)
    }
  }

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery<ScheduledVisitsData, ScheduledVisitsVariables>(GET_SCHEDULED_VISITS, {
    ...rest,
    variables: {
      location: locationId,
      room: roomId ?? '',
      from,
      to,
    },
    skip: !roomId || !from || !to,
    fetchPolicy: 'cache-and-network',
    onError,
  })

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading,
    scheduledVisits: data?.scheduledVisits?.docs ?? [],
    error,
    refetch,
    onErrorClose,
  }
}
