import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Heading,
  HStack,
  Icon,
  IconButton,
  Text,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { ConfirmButton, ErrorViewer, SignatureTag } from '..'
import { FullHistoryItem } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { TextField, ValidateFn } from '../Form'
import {
  RiDeleteBin2Line as DeleteIcon,
  RiArrowRightSLine as ExpandButton
} from 'react-icons/ri'
import {
  AddEntryFormValues,
  useAddAllergiesToHistory,
  useAddDietToHistory,
  useAddDevelopmentToHistory,
  useAddFamilyToHistory,
  useAddSocialToHistory,
  useAddManualMedicationToHistory,
  useAddManualProblemsToHistory,
  useRemoveAllergiesFromHistory,
  useRemoveDietFromHistory,
  useRemoveDevelopmentFromHistory,
  useRemoveFamilyFromHistory,
  useRemoveSocialFromHistory,
  useRemoveManualMedicationFromHistory,
  useRemoveManualProblemsFromHistory
} from '.'

const messages = defineMessages({
  addToHistoryPlaceholder: {
    id: 'HistorySection.add_to_history_placeholder',
    defaultMessage: 'Add entry to history'
  },
  addEntryButton: {
    id: 'UI.add_entry_button',
    defaultMessage: 'Add entry'
  },
  removeEntryButton: {
    id: 'UI.remove_entry_button',
    defaultMessage: 'Remove entry'
  },
  problemsHistoryTitle: {
    id: 'History.problems_history_title',
    defaultMessage: 'Problems',
  },
  medicationsHistoryTitle: {
    id: 'History.medications_history_title',
    defaultMessage: 'Medications',
  },
  allergiesHistoryTitle: {
    id: 'History.allergies_history_title',
    defaultMessage: 'Allergies',
  },
  dietHistoryTitle: {
    id: 'History.diet_history_title',
    defaultMessage: 'Diet',
  },
  developmentHistoryTitle: {
    id: 'History.development_history_title',
    defaultMessage: 'Development',
  },
  socialHistoryTitle: {
    id: 'History.social_history_title',
    defaultMessage: 'Social',
  },
  familyHistoryTitle: {
    id: 'History.family_history_title',
    defaultMessage: 'Family',
  },
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?'
  },
})


type EditableHistoryItemProps = {
  item: FullHistoryItem
  onDelete: (id: string) => void
  isLoading?: boolean
  error: Nullable<string>
  onErrorClose: () => void
  isReadOnly?: false
}

type ReadOnlyHistoryItemProps = {
  item: FullHistoryItem
  isReadOnly: true
}

type HistoryItemProps = (EditableHistoryItemProps | ReadOnlyHistoryItemProps)

const HistoryItem: React.FC<HistoryItemProps> = props => {
  const {
    item,
    isReadOnly,
  } = props

  const intl = useIntl()

  const { historyId, name, signature } = item

  return (
    <HStack justifyContent={'space-between'} mt={1} p={2} borderWidth={1} borderRadius='lg' >
      <Text >
        {
          signature?.timestamp &&
          <SignatureTag signature={signature} mr={2} />
        }
        {name}
      </Text>
      {
        !props.isReadOnly && historyId !== null &&
        <ConfirmButton
          onConfirm={() => props.onDelete(historyId)}
          message={intl.formatMessage(messages.confirmationMessage)}
          buttonProps={{
            size: 'sm',
            color: 'red.400',
            label: <Icon as={DeleteIcon} />,
            isDisabled: isReadOnly,
            isLoading: props.isLoading,
          }}
          error={props.error}
          onErrorClose={props.onErrorClose}
        />
      }
    </HStack>
  )
}

type AddEntryProps = Omit<BoxProps, 'onSubmit'> & {
  onSubmit: (values: AddEntryFormValues) => void
  isLoading?: boolean
  validate: ValidateFn<AddEntryFormValues>
  error: Nullable<string>
  onErrorClose: () => void
}

const AddEntry: React.FC<AddEntryProps> = props => {
  const {
    onSubmit,
    isLoading,
    validate,
    error,
    onErrorClose,
    ...boxProps
  } = props

  const intl = useIntl()
  const isFullWidth = useBreakpointValue({ base: true, sm: false })

  return <Box {...boxProps}>
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, form }) => (
        <form onSubmit={async event => {
          await handleSubmit(event)
          form.resetFieldState('content')
          form.reset()
        }}>
          <TextField
            name="content"
            label={intl.formatMessage(messages.addToHistoryPlaceholder)}
            hideLabel
          />

          {
            error &&
            <ErrorViewer title={error} onClose={onErrorClose} />
          }
          <Button
            colorScheme={'brand'}
            isLoading={isLoading}
            type="submit"
            isFullWidth={isFullWidth}
            size={'sm'}
          >
            {intl.formatMessage(messages.addEntryButton)}
          </Button>
        </form>
      )}
    />
  </Box>
}

/**
 * This is for the read only version of the
 * history in the patient scene
 */
type ReadOnlyHistorySectionProps = BoxProps & {
  items: Array<FullHistoryItem>
  defaultIsOpen?: boolean
}

/**
 * And this one for the history tab in the
 * active visit scene which allow users to
 * add/remove entries.
 *
 * read only items belog to old visits so they cannot be
 * deleted.
 */
type EditableHistorySectionProps = BoxProps & {
  patientId: string
  visitId: string
  items: Array<FullHistoryItem>
  readOnlyItems?: Array<FullHistoryItem>
  defaultIsOpen?: boolean
}

export const ManualProblems: React.FC<EditableHistorySectionProps> = props => {
  const {
    patientId,
    visitId,
    items,
    readOnlyItems,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  const params = {
    patientId,
    visitId,
  }

  const {
    onDelete,
    isLoading: isDeleting,
    error: deletingError,
    onErrorClose: onDeletingErrorClose
  } = useRemoveManualProblemsFromHistory(params)

  const {
    validate,
    onSubmit,
    error: addingError,
    isLoading: isAdding,
    onErrorClose: onAddingErrorClose,
  } = useAddManualProblemsToHistory(params)

  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.problemsHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        Array.isArray(readOnlyItems) &&
        readOnlyItems.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            onDelete={onDelete}
            isLoading={isDeleting}
            error={deletingError}
            onErrorClose={onDeletingErrorClose}
          />
        })
      }
    </Collapse>


    <AddEntry
      validate={validate}
      onSubmit={onSubmit}
      isLoading={isAdding}
      error={addingError}
      onErrorClose={onAddingErrorClose}
      mt={4}
    />
  </Box>
}

export const ManualMedications: React.FC<EditableHistorySectionProps> = props => {
  const {
    patientId,
    visitId,
    items,
    readOnlyItems,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  const params = {
    patientId,
    visitId,
  }

  const {
    onDelete,
    isLoading: isDeleting,
    error: deletingError,
    onErrorClose: onDeletingErrorClose
  } = useRemoveManualMedicationFromHistory(params)

  const {
    validate,
    onSubmit,
    error: addingError,
    isLoading: isAdding,
    onErrorClose: onAddingErrorClose,
  } = useAddManualMedicationToHistory(params)

  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.medicationsHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        Array.isArray(readOnlyItems) &&
        readOnlyItems.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            onDelete={onDelete}
            isLoading={isDeleting}
            error={deletingError}
            onErrorClose={onDeletingErrorClose}
          />
        })
      }
    </Collapse>


    <AddEntry
      validate={validate}
      onSubmit={onSubmit}
      isLoading={isAdding}
      error={addingError}
      onErrorClose={onAddingErrorClose}
      mt={4}
    />
  </Box>
}

export const Allergies: React.FC<EditableHistorySectionProps> = props => {
  const {
    patientId,
    visitId,
    items,
    readOnlyItems,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  const params = {
    patientId,
    visitId,
  }

  const {
    onDelete,
    isLoading: isDeleting,
    error: deletingError,
    onErrorClose: onDeletingErrorClose
  } = useRemoveAllergiesFromHistory(params)

  const {
    validate,
    onSubmit,
    error: addingError,
    isLoading: isAdding,
    onErrorClose: onAddingErrorClose,
  } = useAddAllergiesToHistory(params)

  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.allergiesHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        Array.isArray(readOnlyItems) &&
        readOnlyItems.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            onDelete={onDelete}
            isLoading={isDeleting}
            error={deletingError}
            onErrorClose={onDeletingErrorClose}
          />
        })
      }
    </Collapse>


    <AddEntry
      validate={validate}
      onSubmit={onSubmit}
      isLoading={isAdding}
      error={addingError}
      onErrorClose={onAddingErrorClose}
      mt={4}
    />
  </Box>
}

export const Diet: React.FC<EditableHistorySectionProps> = props => {
  const {
    patientId,
    visitId,
    items,
    readOnlyItems,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  const params = {
    patientId,
    visitId,
  }

  const {
    onDelete,
    isLoading: isDeleting,
    error: deletingError,
    onErrorClose: onDeletingErrorClose
  } = useRemoveDietFromHistory(params)

  const {
    validate,
    onSubmit,
    error: addingError,
    isLoading: isAdding,
    onErrorClose: onAddingErrorClose,
  } = useAddDietToHistory(params)

  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.dietHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        Array.isArray(readOnlyItems) &&
        readOnlyItems.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            onDelete={onDelete}
            isLoading={isDeleting}
            error={deletingError}
            onErrorClose={onDeletingErrorClose}
          />
        })
      }
    </Collapse>


    <AddEntry
      validate={validate}
      onSubmit={onSubmit}
      isLoading={isAdding}
      error={addingError}
      onErrorClose={onAddingErrorClose}
      mt={4}
    />
  </Box>
}

export const Development: React.FC<EditableHistorySectionProps> = props => {
  const {
    patientId,
    visitId,
    items,
    readOnlyItems,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  const params = {
    patientId,
    visitId,
  }

  const {
    onDelete,
    isLoading: isDeleting,
    error: deletingError,
    onErrorClose: onDeletingErrorClose
  } = useRemoveDevelopmentFromHistory(params)

  const {
    validate,
    onSubmit,
    error: addingError,
    isLoading: isAdding,
    onErrorClose: onAddingErrorClose,
  } = useAddDevelopmentToHistory(params)

  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.developmentHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        Array.isArray(readOnlyItems) &&
        readOnlyItems.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            onDelete={onDelete}
            isLoading={isDeleting}
            error={deletingError}
            onErrorClose={onDeletingErrorClose}
          />
        })
      }
    </Collapse>


    <AddEntry
      validate={validate}
      onSubmit={onSubmit}
      isLoading={isAdding}
      error={addingError}
      onErrorClose={onAddingErrorClose}
      mt={4}
    />
  </Box>
}

export const Social: React.FC<EditableHistorySectionProps> = props => {
  const {
    patientId,
    visitId,
    items,
    readOnlyItems,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  const params = {
    patientId,
    visitId,
  }

  const {
    onDelete,
    isLoading: isDeleting,
    error: deletingError,
    onErrorClose: onDeletingErrorClose
  } = useRemoveSocialFromHistory(params)

  const {
    validate,
    onSubmit,
    error: addingError,
    isLoading: isAdding,
    onErrorClose: onAddingErrorClose,
  } = useAddSocialToHistory(params)

  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.socialHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        Array.isArray(readOnlyItems) &&
        readOnlyItems.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            onDelete={onDelete}
            isLoading={isDeleting}
            error={deletingError}
            onErrorClose={onDeletingErrorClose}
          />
        })
      }
    </Collapse>


    <AddEntry
      validate={validate}
      onSubmit={onSubmit}
      isLoading={isAdding}
      error={addingError}
      onErrorClose={onAddingErrorClose}
      mt={4}
    />
  </Box>
}

export const Family: React.FC<EditableHistorySectionProps> = props => {
  const {
    patientId,
    visitId,
    items,
    readOnlyItems,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  const params = {
    patientId,
    visitId,
  }

  const {
    onDelete,
    isLoading: isDeleting,
    error: deletingError,
    onErrorClose: onDeletingErrorClose
  } = useRemoveFamilyFromHistory(params)

  const {
    validate,
    onSubmit,
    error: addingError,
    isLoading: isAdding,
    onErrorClose: onAddingErrorClose,
  } = useAddFamilyToHistory(params)

  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.familyHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        Array.isArray(readOnlyItems) &&
        readOnlyItems.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            onDelete={onDelete}
            isLoading={isDeleting}
            error={deletingError}
            onErrorClose={onDeletingErrorClose}
          />
        })
      }
    </Collapse>


    <AddEntry
      validate={validate}
      onSubmit={onSubmit}
      isLoading={isAdding}
      error={addingError}
      onErrorClose={onAddingErrorClose}
      mt={4}
    />
  </Box>
}


/**
 * Read only version of the same sections
 * I think there could be a good abstraction for this but
 * I didn't find it
 */
export const ReadOnlyManualProblems: React.FC<ReadOnlyHistorySectionProps> = props => {
  const {
    items,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.problemsHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
    </Collapse>


  </Box>
}

export const ReadOnlyManualMedications: React.FC<ReadOnlyHistorySectionProps> = props => {
  const {
    items,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })


  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.medicationsHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
    </Collapse>


  </Box>
}

export const ReadOnlyAllergies: React.FC<ReadOnlyHistorySectionProps> = props => {
  const {
    items,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })


  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.allergiesHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
    </Collapse>


  </Box>
}

export const ReadOnlyDiet: React.FC<ReadOnlyHistorySectionProps> = props => {
  const {
    items,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })


  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.dietHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
    </Collapse>


  </Box>
}

export const ReadOnlyDevelopment: React.FC<ReadOnlyHistorySectionProps> = props => {
  const {
    items,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })


  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.developmentHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
    </Collapse>


  </Box>
}

export const ReadOnlySocial: React.FC<ReadOnlyHistorySectionProps> = props => {
  const {
    items,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })


  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.socialHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
    </Collapse>


  </Box>
}

export const ReadOnlyFamily: React.FC<ReadOnlyHistorySectionProps> = props => {
  const {
    items,
    defaultIsOpen,
    ...boxProps
  } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })


  const showExpandButton = items.length > 0
  return <Box {...boxProps}>
    <HStack alignItems={'center'} justifyContent={'space-between'}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor='pointer'>
        {intl.formatMessage(messages.familyHistoryTitle)}
      </Heading>
      {
        showExpandButton &&
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant='ghost'
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}

        />
      }
    </HStack>

    <Collapse startingHeight={0} in={isOpen} animateOpacity>
      {
        items.map((item, index) => {
          const key = `history-item-${index}-${item.historyId}`
          return <HistoryItem
            key={key}
            item={item}
            isReadOnly
          />
        })
      }
    </Collapse>


  </Box>
}