import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { GET_STAFF } from '../../api/requests/staff'
import { User, PaginatedResult } from '../../api/interfaces'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface StaffVariables {
  q?: string
  offset?: number
  limit?: number
  location?: Array<string>
  system?: Array<string>
}

interface StaffQueryData {
  staff: PaginatedResult<User>
}

export type UsestaffParams = QueryHookOptions<StaffQueryData, StaffVariables>

export const useStaff = (params?: UsestaffParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const { loading, data, refetch } = useQuery<StaffQueryData, StaffVariables>(GET_STAFF, {
    onError,
    ...params,
  })

  const getUser = React.useCallback(
    (userId: string): Nullable<User> => {
      if (!data?.staff) {
        return null
      }

      const { docs } = data.staff
      const found = docs.find((user) => user.id === userId)

      return found ?? null
    },
    [data]
  )

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading: loading,
    staff: data?.staff.docs ?? [],
    count: data?.staff.count ?? 0,
    error,
    getUser,
    refetch,
    onErrorClose,
  }
}
