import React from 'react'
// import { defineMessages, useIntl } from 'react-intl'
import { ChecklistReferenceBody } from './ChecklistReference'
// import { hashChecklistItem } from 'wd-common/src/logic/checklists/utils'
import { FullChecklistEntry } from '../../api/interfaces'
import { Checkbox, CheckboxProps } from '@chakra-ui/react'
import { DynamicTranslation } from '../i18n'
// import { ProcedureOrderModal } from '../Procedures'

// const messages = defineMessages({
//   seeProcedure: {
//     id: 'ChecklistProcedure.text_see_procedure',
//     defaultMessage: '(see procedure in results tab)',
//   },
//   missingOrder: {
//     id: 'ChecklistProcedure.missing_order',
//     defaultMessage: '(missing order)',
//   },
// })

// const checkOrders = (entry, studyOrders) => {
//   const entryHash = hashChecklistItem(entry)

//   const alreadyUsed = (studyOrders || []).some((order) => {
//     const ordered = hashChecklistItem(order.guideline || {})

//     return ordered && !order.canceled && entryHash && entryHash === ordered
//   })

//   return alreadyUsed
// }

/**
 * The modal must be shown after checking the item if there is no linked order.
 * It must be auto shown if the checklist is loaded and the item is already checked.
 * It should be closable without ordering a new order
 */

type ChecklistProcedureProps = Omit<CheckboxProps, 'onChange' | 'value' | 'defaultValue'> & {
  entry: FullChecklistEntry
  onCheckChange: (checked: boolean) => void
  // patientId: string
  // visitId: string
  // procedureOrders: Array<FullProcedureOrder>
}

export const ChecklistProcedure: React.FC<ChecklistProcedureProps> = (props) => {
  const { entry, onCheckChange, ...checkboxProps } = props

  // const intl = useIntl()
  // const { isOpen, onClose, onOpen } = useDisclosure()

  // const alreadyUsed = checkOrders(entry, procedureOrders)

  const _onCheckChange = (e) => {
    const wantToBeChecked = e.target.checked
    onCheckChange(wantToBeChecked)
    /**
     * DISABLED FOR NOW
     * 
     * if the item changes to checked, we need to wait for the order
     * before of setting it as checked
     */
    // if (wantToBeChecked) {
    //   // this means the order is already created.
    //   if (alreadyUsed) {
    //     onCheckChange(true)
    //   } else {
    //     onOpen()
    //   }
    // } else {
    //   onCheckChange(false)
    // }
  }

  // const modalCallback = () => {
  //   onCheckChange(true)
  // }

  // const showModal = !checkboxProps.isDisabled && isOpen && !alreadyUsed
  return (
    <>
      <Checkbox
        onChange={_onCheckChange}
        defaultChecked={!!entry.checked}
        my={1}
        {...checkboxProps}
      >
        {entry.name && <DynamicTranslation fontStyle={'italic'} message={entry.name} />}

        {entry.reference && <ChecklistReferenceBody reference={entry.reference} />}

        {/* {entry.checked && alreadyUsed && <Text>{intl.formatMessage(messages.seeProcedure)}</Text>} */}
      </Checkbox>
      {/* {showModal && (
        <ProcedureOrderModal
          patientId={patientId}
          visitId={visitId}
          isOpen={isOpen}
          guideline={entry}
          onClose={onClose}
          onOrder={modalCallback}
        />
      )} */}
    </>
  )
}
