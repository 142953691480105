import React from 'react'
import Select from 'react-select'
import { useChakraThemeParser } from '.'
import { defineMessages, useIntl } from 'react-intl'
import { isValidVisitType } from '../Visit'

import { ReactSelectInternalValue, ReactSelectInternalSingleValue, SelectorProps } from '.'
import { Nullable } from '../../utils'
import { visitTypeTranslations } from '../Visit/VisitTypeTranslation'
import { VisitType } from '../../api/interfaces'

/**
 * See the IntlDefineMessage definition
 */
const translationMessages = defineMessages(visitTypeTranslations)

/**
 * For now, ww wants to only show one visit type.
 * :)
 */
const lactationVisitType = [
  VisitType.LACTATION_COUNSELING,
  VisitType.GENERAL_PRACTITIONER,
  VisitType.PEDIATRICIAN,
]

export type VisitTypeSelectorProps = SelectorProps<VisitType> & {
  filter?: (type: VisitType) => boolean
}

export const VisitTypeSelector: React.FC<VisitTypeSelectorProps> = (props) => {
  const { value, filter } = props

  const intl = useIntl()
  const theme = useChakraThemeParser()

  const formatOption = (visitType: VisitType): ReactSelectInternalSingleValue => {
    return {
      label: intl.formatMessage(translationMessages[visitType]),
      value: visitType,
    }
  }

  const filteredOptions = filter
    ? lactationVisitType.filter((type) => filter(type))
    : lactationVisitType

  const options = filteredOptions.map(formatOption)

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return null
      }

      const formattedValues = value.reduce(
        (prev: Array<ReactSelectInternalSingleValue>, value: string) => {
          if (isValidVisitType(value) && filteredOptions.includes(value as VisitType)) {
            return [...prev, formatOption(value as VisitType)]
          }

          return prev
        },
        []
      )

      return formattedValues
    } else {
      if (isValidVisitType(value as string) && filteredOptions.includes(value as VisitType)) {
        return formatOption(value as VisitType)
      }

      return null
    }
  }

  const _onChange = (value) => {
    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return props.onChange([])
      }

      const selectedValues = value
      const newValues = selectedValues.map((item) => item.value)

      props.onChange(newValues as Array<VisitType>)
    } else {
      const selectedValue = value

      props.onChange(selectedValue.value as VisitType)
    }
  }

  return (
    <Select
      options={options}
      value={parseValue(value)}
      onChange={_onChange}
      isLoading={props.isLoading}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      aria-invalid={props.isInvalid}
      placeholder={props.placeholder}
      blurInputOnSelect={props.blurInputOnSelect}
      theme={theme}
    />
  )
}
