import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { isValidPhoneNumber, ValidateFn, ValidationError } from '../Form'
import { UPDATE_LOCATION } from '../../api/requests/systems'
import { Location } from '../../api/interfaces'
import { useSession } from '../Auth'
import { useSystems } from './useSystems'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  invalidPhone: {
    id: 'form_validation.invalid_phone_number',
    defaultMessage: 'Invalid phone number',
  },
})

// form
interface FormValues {
  name: string
  address: string
  phoneNumber: string
  country: string
}

// interface required by the server
interface LocationData extends FormValues {
  id: string
}

interface UpdateLocationVariables {
  systemId: string
  location: LocationData
}

interface UpdateLocationData {
  updateLocation: Location
}

export type UseUpdateLocationParams = MutationHookOptions<
  UpdateLocationData,
  UpdateLocationVariables
>

export const useUpdateClinicData = (params?: UseUpdateLocationParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const { getLocation } = useSystems()
  const { getUserLocation, getUserSystem } = useSession()
  const { id: locationId } = getUserLocation()
  const { id: systemId } = getUserSystem()

  const { name, address, phoneNumber, country } = getLocation(systemId, locationId) ?? {}

  const initialValues = {
    name,
    address,
    phoneNumber,
    country,
  }

  const validate: ValidateFn<FormValues> = async (values) => {
    const errors: ValidationError<FormValues> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.required)
    }

    const phoneValidationOptions = { country: values.country }
    if (values.phoneNumber && !isValidPhoneNumber(values.phoneNumber, phoneValidationOptions)) {
      errors.phoneNumber = intl.formatMessage(messages.invalidPhone)
    }

    return errors
  }

  const onError = (error) => {
    if (params?.onError) {
      params.onError(error)
    }

    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onCompleted = (data) => {
    if (params?.onCompleted) {
      params.onCompleted(data)
    }

    setError(null)
  }

  const [updateLocation, { loading: isLoading }] = useMutation<
    UpdateLocationData,
    UpdateLocationVariables
  >(UPDATE_LOCATION, { ...params, onError, onCompleted })

  const onSubmit = (values: FormValues) => {
    const location = {
      ...values,
      id: locationId,
    }

    const variables = {
      systemId,
      location,
    }

    updateLocation({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    initialValues,
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}
