import React from "react"
import { CheckboxField } from '../Form/CheckboxField'
import { Control, DateField, Error, TextField } from '../Form'
import { CustomFieldType, NonPrimitiveCustomFieldType, PrimitiveCustomFieldType } from '../../api/interfaces'
import { FormLabel } from "@chakra-ui/react"
import { useField } from "react-final-form"
import { useChakraThemeParser } from "../Selector"
import Select from 'react-select'

interface CustomFieldInputProps {
  id: string
  type: CustomFieldType
  name: string
  options?: Array<string>
}

export const CustomFieldInput: React.FC<CustomFieldInputProps> = props => {
  const { id, type, name, options } = props

  switch (type) {
    case PrimitiveCustomFieldType.BOOLEAN:
      return <CheckboxField label={name} name={id} />

    case PrimitiveCustomFieldType.NUMBER:
      return <TextField type='number' label={name} name={id} />

    case PrimitiveCustomFieldType.TEXT:
      return <TextField label={name} name={id} />

    case PrimitiveCustomFieldType.DATE:
      return <DateField label={name} name={id} />

    case NonPrimitiveCustomFieldType.TEXT_LIST:
      return <TextListField label={name} name={id} options={options ?? []} />

    default:
      return null
  }
}


interface TextListFieldProps {
  name: string
  label: string
  options: Array<string>
}

const TextListField: React.FC<TextListFieldProps> = props => {
  const { name, label, options } = props
  const { input } = useField(name)

  const formatOption = (option: string) => ({
    value: option,
    label: option
  })

  const formattedOptions = options.map(formatOption)

  const onChange = (val) => {
    if (val) {
      input.onChange(val.value)
    } else {
      input.onChange(null)
    }

  }

  const theme = useChakraThemeParser()
  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        value={formatOption(input.value)}
        onChange={onChange}
        options={formattedOptions}
        theme={theme}
        isClearable
      />
      <Error name={name} />
    </Control>
  )
}
