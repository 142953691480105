import {
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'
import { defineMessages } from '@formatjs/intl'
import React from 'react'
import { useField } from 'react-final-form'
import { useIntl } from 'react-intl'
import { Control, Error } from './utils'
import {
  RiEyeFill as ShowPasswordIcon,
  RiEyeOffFill as HidePasswordIcon
} from 'react-icons/ri'

const messages = defineMessages({
  showPassword: {
    id: 'StaffLogin.show_password',
    defaultMessage: 'Show',
  },
  hidePassword: {
    id: 'StaffLogin.hide_password',
    defaultMessage: 'Hide',
  },
})
interface PasswordFieldProps extends InputProps {
  name: string
  label: string
  before?: React.ReactNode
}

export const PasswordField: React.FC<PasswordFieldProps> = (props) => {
  const { name, label, before, ...otherProps } = props
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow((prev) => !prev)

  const { input, meta } = useField(name)
  const intl = useIntl()

  const ariaLabel = show ? intl.formatMessage(messages.showPassword) : intl.formatMessage(messages.hidePassword)

  const icon = show ? <Icon as={ShowPasswordIcon} /> : <Icon as={HidePasswordIcon} />

  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <InputGroup size="md">
        {
          before &&
          <InputLeftAddon>{before}</InputLeftAddon>
        }
        <Input
          {...input}
          {...otherProps}
          pr="2.5rem"
          type={show ? 'text' : 'password'}
          isInvalid={meta.error && meta.touched}
          id={name}
          placeholder={label}
        />
        <InputRightElement width="2.5rem">
          <IconButton
            size="sm"
            aria-label={ariaLabel}
            colorScheme={'brand'}
            variant='ghost'
            onClick={handleClick}
            icon={icon}
          />
        </InputRightElement>
      </InputGroup>
      <Error name={name} />
    </Control>
  )
}
