import React from 'react'
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Spacer,
  Text,
  Collapse,
  useDisclosure,
  BoxProps,
} from '@chakra-ui/react'
import { RiMore2Line as RightArrowIcon } from 'react-icons/ri'
import { PastVisitModalFromId } from '../Visit'
import { defineMessages, useIntl } from 'react-intl'
import { FullChecklist, PastVisit, TranslatableText } from '../../api/interfaces'
import { useTranslatedList } from '../i18n'
import { DateTime } from 'luxon'
import { RiCalendarEventLine as DateIcon, RiArrowRightSLine as ExpandButton, RiEyeLine as Record } from 'react-icons/ri'
import { Nullable } from '../../utils'

const messages = defineMessages({
  title: {
    id: 'PastVisits.title',
    defaultMessage: 'Past Visits',
  },
  backButton: {
    id: 'UI.button_back',
    defaultMessage: 'Back',
  },
  searchPlaceholder: {
    id: 'PastVisits.search_placeholder',
    defaultMessage: 'Search past visits...',
  },
  recordButton: {
    id: 'UI.button_record',
    defaultMessage: 'Record',
  },
  noDiagnosis: {
    id: 'UI.button_no_diagnosis',
    defaultMessage: '- No diagnosis -',
  },
  profile: {
    id: 'UI.button_patient_profile',
    defaultMessage: 'Patient',
  },
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close',
  },
})

type PastHistoryProps = BoxProps & {
  items: Array<PastVisit>
  defaultIsOpen?: boolean
}
export const PastVisits: React.FC<PastHistoryProps> = (props) => {
  const { items, defaultIsOpen, ...boxProps } = props

  const intl = useIntl()

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })
  const [selectedVisitId, setSelectedVisitId] = React.useState<Nullable<string>>(null)

  const showExpandButton = items.length > 0
  return (
    <Box {...boxProps}>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Heading size={'md'} mb={1} onClick={onToggle} cursor="pointer">
          {intl.formatMessage(messages.title)}
        </Heading>
        {showExpandButton && (
          <IconButton
            size="sm"
            aria-label={'expand all'}
            colorScheme={'brand'}
            variant="ghost"
            onClick={onToggle}
            icon={<ExpandButton />}
            sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}
          />
        )}
      </HStack>

      <Collapse startingHeight={0} in={isOpen} animateOpacity>
        {items.map((pastVisit) => {
          const key = `past-visit-${pastVisit.id}`

          return <PastVisitItem key={key} visit={pastVisit} onSelect={() => setSelectedVisitId(pastVisit.id)} />
        })}
      </Collapse>

      {selectedVisitId !== null && (
        <PastVisitModalFromId visitId={selectedVisitId} onClose={() => setSelectedVisitId(null)} />
      )}
    </Box>
  )
}

interface ItemProps {
  visit: PastVisit
  onSelect: () => void
}

export const PastVisitItem: React.FC<ItemProps> = (props) => {
  const { visit, onSelect } = props

  const intl = useIntl()

  const { diagnoses: checklists, assessments, start } = visit

  const fromChecklists = extractDiagnosesFromChecklists(checklists ?? [])
  const translatedDiagnosesFromChecklists = useTranslatedList(fromChecklists)

  const fromManualEntries = Array.isArray(assessments) ? assessments.map((item) => item.description) : []

  const diagnoses = [...translatedDiagnosesFromChecklists, ...fromManualEntries]

  const visitDate = DateTime.fromJSDate(new Date(start))

  const buttonStyles = {
    variant: 'ghost',
    isFullWidth: true,
    justifyContent: 'start',
    borderRadius: 'sm',
  }

  return (
    <HStack justifyContent={'space-between'} mt={1} p={2} borderWidth={1} borderRadius="lg">
      <Box>
        <HStack spacing={1}>
          <Text fontSize={'md'} noOfLines={1}>
            {diagnoses.length > 0 ? diagnoses.join(', ') : intl.formatMessage(messages.noDiagnosis)}
          </Text>
        </HStack>
        <HStack>
          {visitDate.isValid && (
            <HStack spacing={1}>
              <Icon as={DateIcon} fontSize={'xs'} mr={1} />
              <Text fontSize={'sm'} mr={2}>
                {visitDate.toLocaleString(DateTime.DATE_MED)}
              </Text>
            </HStack>
          )}
        </HStack>
      </Box>
      <Spacer />
      <Menu>
        <MenuButton as={IconButton} icon={<RightArrowIcon />} variant={'ghost'} />
        <MenuList>
          <Button {...buttonStyles} onClick={onSelect}>
            <Icon as={Record} mr={2} />
            {intl.formatMessage(messages.recordButton)}
          </Button>
        </MenuList>
      </Menu>
    </HStack>
  )
}

const extractDiagnosesFromChecklists = (checklists: Array<FullChecklist>): Array<TranslatableText> => {
  const filteredChecklists = checklists.filter((item) => item.general_tags?.includes('Diagnosis'))
  const diagnoses = filteredChecklists.map((item) => item.name)

  return diagnoses
}
