import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { ADD_MANUAL_DIAGNOSE, REMOVE_MANUAL_DIAGNOSE } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ValidateFn, ValidationError } from '../Form'

const messages = defineMessages({
  requiredError: {
    id: 'UI.required_error',
    defaultMessage: 'You must enter a value for this field.',
  },
  patientNotFoundError: {
    id: 'AddManualDiagnose.patient_not_found_error',
    defaultMessage: 'This patient was not found, refresh the page and try again.',
  },
  visitNotFoundError: {
    id: 'AddManualDiagnose.visit_not_found_error',
    defaultMessage: 'This visit was not found, refresh the page and try again.',
  },
  removeDiagnoseError: {
    id: 'AddManualDiagnose.remove_diagnose__error',
    defaultMessage: 'Something went wrong while trying to remove the diagnosis, try again later.',
  },
  addDiagnoseError: {
    id: 'AddManualDiagnose.add_diagnose__error',
    defaultMessage: 'Something went wrong while trying to add a diagnosis, try again later.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface ManualDiagnoseFormValues {
  treatment: string
  diagnose: string
}

interface AddManualDiagnoseVariables {
  patientId: string
  visitId: string
  treatment: string
  diagnose: string
}

interface AddManualDiagnoseData {
  addNewManualDiagnoseToActiveVisit: FullPatientWithActiveVisit
}

type AddManualDiagnoseParams = MutationHookOptions<
  AddManualDiagnoseData,
  AddManualDiagnoseVariables
> & {
  patientId: string
  visitId: string
}

export const useAddManualDiagnose = (params: AddManualDiagnoseParams) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFoundError))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFoundError))

      case 'error_adding_new_diagnose':
        return setError(intl.formatMessage(messages.addDiagnoseError))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [addManualDiagnose, { loading: isLoading }] = useMutation(ADD_MANUAL_DIAGNOSE, {
    onError,
    ...restParams,
  })

  const validate: ValidateFn<ManualDiagnoseFormValues> = async (values) => {
    const errors: ValidationError<ManualDiagnoseFormValues> = {}

    if (!values.diagnose) {
      errors.diagnose = intl.formatMessage(messages.requiredError)
    }

    if (!values.treatment) {
      errors.treatment = intl.formatMessage(messages.requiredError)
    }

    return errors
  }

  const onSubmit = (values: ManualDiagnoseFormValues) => {
    setError(null)

    const { diagnose, treatment } = values
    const variables = {
      patientId,
      visitId,
      diagnose,
      treatment,
    }

    addManualDiagnose({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    validate,
    error,
    onErrorClose,
  }
}

interface RemoveManualDiagnoseVariables {
  patientId: string
  visitId: string
  diagnoseId: string
}

interface RemoveManualDiagnoseData {
  removeManualDiagnoseFromActiveVisit: FullPatientWithActiveVisit
}

type RemoveManualDiagnoseParams = MutationHookOptions<
  RemoveManualDiagnoseData,
  RemoveManualDiagnoseVariables
> & {
  patientId: string
  visitId: string
}

export const useRemoveManualDiagnosis = (params: RemoveManualDiagnoseParams) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFoundError))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFoundError))

      case 'error_removing_diagnose':
        return setError(intl.formatMessage(messages.removeDiagnoseError))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [removeManualDiagnose, { loading: isLoading }] = useMutation(REMOVE_MANUAL_DIAGNOSE, {
    onError,
    ...restParams,
  })

  const onDelete = (diagnoseId: string) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      diagnoseId,
    }

    removeManualDiagnose({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}
