import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { CHANGE_EXAM_IN_ACTIVE_VISIT } from '../../api/requests/activeVisit'

const messages = defineMessages({
  patientNotFound: {
    id: 'PhysicalExam.patient_not_found',
    defaultMessage: 'Patient not found',
  },
  errorChagingExam: {
    id: 'PhysicalExam.error_chaging_exam',
    defaultMessage: 'Could not change the exam. Please, try again',
  },
  errorExamActionsDefault: {
    id: 'PhysicalExam.error_exam_actions_default',
    defaultMessage: 'Something went wrong while trying to perform an action in the examn. Please, try again',
  },
})

interface ChangeExamVariables {
  patientId: string
  visitId: string
  examId: string
}

interface ChangeExamData {
  changeExamInActiveVisit: FullPatientWithActiveVisit
}

export type UsePatientUpdateParams = MutationHookOptions<ChangeExamData, ChangeExamVariables> & {
  patientId: string
  visitId: string
}

export const useChangeExam = (params: UsePatientUpdateParams) => {
  const { patientId, visitId, ...rest } = params
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_changing_exam':
        return setError(intl.formatMessage(messages.errorChagingExam))

      default:
        return setError(intl.formatMessage(messages.errorExamActionsDefault))
    }
  }

  const [changeExam, { loading: isLoading }] = useMutation<ChangeExamData, ChangeExamVariables>(
    CHANGE_EXAM_IN_ACTIVE_VISIT,
    {
      ...rest,
      onError,
    }
  )

  const onSubmit = (examId: string) => {
    setError(null)

    const variables: ChangeExamVariables = {
      patientId,
      visitId,
      examId,
    }

    changeExam({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    onErrorClose,
  }
}
