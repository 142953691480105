import React from 'react'
import {
  CognitoUserSession,
  CognitoUserPool,
  CognitoUser,
  CognitoRefreshToken,
  CognitoIdToken,
  AuthenticationDetails,
  CognitoUserAttribute,
  ISignUpResult,
  UserData,
} from 'amazon-cognito-identity-js'
import {
  ChangePasswordParams,
  LogInParams,
  RequestAttributeVerificationParams,
  Session,
  SessionState,
  UpdateAttributeParams,
  VerifyAttributeParams,
} from './session'
import { Nullable, validateNotNil } from '../../utils'
import {
  ConfirmRegistrationParams,
  DecodedUserPayload,
  ForgotPasswordParams,
  ForgotPasswordResponse,
  LogInResponse,
  ResetPasswordParams,
  SendMFATokenParams,
  SetUserMfaPreferenceResponse,
  SignUpParams,
  UpdateLocaleParams,
  validateSystemOrLocationAttr,
  VerifyMFATokenParams,
  VerifyMFATokenResponse,
} from '.'
import { GlobalRole, Permissions } from './permissions'
import { Locales } from '../i18n'
import { FALLBACK_LOCALE } from '../i18n/provider'
import { DefaultSystemOrLocation, SubscriptionStatus } from '../../api/interfaces'
import {
  Subscription,
  AccountStatus,
  isFullAccessAllowed,
  isReadOnlyAccessAllowed,
  isValidSubscriptionAttr,
  isAccessAllowed,
} from './subscription'
import { getEnvs } from '../envs'
import { UnitSystems } from '../useUnitConversion'

const envs = getEnvs()
const { UserPoolId = '', ClientId = '' } = envs.cognito
const pool: CognitoUserPool = new CognitoUserPool({
  UserPoolId,
  ClientId,
})

export const SessionContext = React.createContext<Nullable<Session>>(null)
export const SessionProvider: React.FC = (props) => {
  const [sessionState, setSessionState] = React.useState<SessionState>(SessionState.BOOTING)
  const [user, setUser] = React.useState<Nullable<CognitoUser>>(pool.getCurrentUser())
  const [token, setToken] = React.useState<Nullable<CognitoIdToken>>(null)

  /**
   * Internal utility to pull the current session from cognito library.
   */
  const getCognitoSession = React.useCallback(async (): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
      if (user !== null) {
        user.getSession((error: Nullable<Error>, session: Nullable<CognitoUserSession>) => {
          if (error !== null) {
            return reject(error)
          }

          if (session !== null) {
            return resolve(session)
          }
        })
      } else {
        return reject(new Error('Never authenticated'))
      }
    })
  }, [user])

  /**
   * Internal utility to refresh the given cognito session. Returns a
   * promise for the new session
   */
  const refreshCognitoSession = React.useCallback(
    async (newSession: CognitoUserSession): Promise<CognitoUserSession> => {
      return new Promise((resolve, reject) => {
        if (user !== null) {
          const refreshToken: CognitoRefreshToken = newSession.getRefreshToken()

          user.refreshSession(
            refreshToken,
            (error: Nullable<Error>, session: Nullable<CognitoUserSession>) => {
              if (error !== null) {
                return reject(error)
              }

              if (session !== null) {
                return resolve(session)
              }
            }
          )
        } else {
          return reject(new Error('Tried to refresh but never authenticated'))
        }
      })
    },
    [user]
  )

  /**
   * Internal utility to set the store state to a cognito session object
   */
  const setCurrentSession = React.useCallback((session: CognitoUserSession): void => {
    if (session.isValid()) {
      const newToken = session.getIdToken()
      setToken(newToken)
      setSessionState(SessionState.LOGGED_IN)
    }
  }, [])

  /**
   * Refreshes a user's token with cognito. Useful for updating the UI when
   * there are permissions or other token changes. Returns the current cognito
   * session.
   */
  const refresh = React.useCallback(async (): Promise<void> => {
    try {
      const initialSession = await getCognitoSession()
      const currentSession = await refreshCognitoSession(initialSession)

      setCurrentSession(currentSession)
    } catch (e) {
      setSessionState(SessionState.LOGGED_OUT)
    }
  }, [getCognitoSession, setCurrentSession, refreshCognitoSession])

  /**
   * Exported methods
   */

  /**
   * Associate MFA token
   */
  const associateMFAToken = async (): Promise<string> => {
    if (user === null || typeof user.associateSoftwareToken !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    return new Promise((resolve, reject) => {
      user.associateSoftwareToken({
        associateSecretCode: (secretCode) => resolve(secretCode),
        onFailure: (error) => {
          if (error.code === 'NotAuthorizedException') {
            refresh()
          }
          reject(error)
        },
      })
    })
  }

  /**
   * Associate MFA token
   */
  const changePassword = async (params: ChangePasswordParams): Promise<void> => {
    if (user === null || typeof user.changePassword !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    return new Promise((resolve, reject) => {
      user.changePassword(params.oldPassword, params.newPassword, (error) => {
        if (error) {
          return reject(error)
        }

        resolve()
      })
    })
  }

  const changeUserUnitSystem = async (unitSystem: UnitSystems) => {
    if (user === null || typeof user.updateAttributes !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    return updateAttribute({ attributeName: 'custom:unitSystem', value: unitSystem })
  }

  const getUserUnitSystem = () => {
    validateNotNil<CognitoIdToken>(token)

    const userInfo = token.payload as DecodedUserPayload
    return userInfo['custom:unitSystem'] as UnitSystems
  }

  /**
   * Confirm signup
   * @param params Signup confirmation params
   * @returns {Promise<void>}
   */
  const confirmRegistration = (params: ConfirmRegistrationParams): Promise<void> => {
    if (user === null || typeof user.confirmRegistration !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    const { code } = params
    return new Promise((resolve, reject) => {
      user.confirmRegistration(code, false, (error) => {
        if (error) {
          return reject(error)
        }

        resolve()
        refresh()
      })
    })
  }

  /**
   * Disable Multi Factor Authentication
   */
  const disableMFA = async (): Promise<SetUserMfaPreferenceResponse> => {
    if (user === null || typeof user.setUserMfaPreference !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    const SoftwareTokenMfaSettings = {
      Enabled: false,
      PreferredMfa: false,
    }

    return new Promise((resolve, reject) => {
      user.setUserMfaPreference(null, SoftwareTokenMfaSettings, function (error, data: any) {
        if (error) {
          reject(error)
        }
        if (data) {
          resolve(data as SetUserMfaPreferenceResponse)
        }

        reject(new Error('unknown'))
      })
    })
  }

  /**
   * * Start MFA activation
   */
  const enableMFA = async (): Promise<SetUserMfaPreferenceResponse> => {
    if (user === null || typeof user.setUserMfaPreference !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    const SoftwareTokenMfaSettings = {
      Enabled: true,
      PreferredMfa: true,
    }

    return new Promise((resolve, reject) => {
      user.setUserMfaPreference(null, SoftwareTokenMfaSettings, function (err, data: any) {
        if (err) {
          reject(err)
        }
        if (data) {
          resolve(data as SetUserMfaPreferenceResponse)
        }
        reject(new Error('unknown'))
      })
    })
  }

  /**
   * Trigger a password restor
   * @param params Forgot password params
   * @returns ForgotPasswordResponse with recovery code destination partially hidden
   */
  const forgotPassword = (params: ForgotPasswordParams): Promise<ForgotPasswordResponse> => {
    const cognitoUser = new CognitoUser({
      Username: params.username,
      Pool: pool,
    })

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          resolve(data)
          setUser(cognitoUser)
          setSessionState(SessionState.RESET_PASSWORD)
        },
        onFailure: reject,
      })
    })
  }

  /**
   * Get the status of the account
   * When the user creates a new account, this will
   */
  const getAccountStatus = (): AccountStatus => {
    try {
      validateNotNil<CognitoIdToken>(token)

      const userInfo = token.payload as DecodedUserPayload

      // no valid subscription attr means that the user still didn't pay
      if (
        !userInfo['custom:subscription'] ||
        !isValidSubscriptionAttr(userInfo['custom:subscription'])
      ) {
        return AccountStatus.PAYMENT_REQUIRED
      }

      const subscription = JSON.parse(userInfo['custom:subscription']) as Subscription
      /**
       * Users can cancel the subscription before setting their clinic up
       */
      if (!isAccessAllowed(subscription)) {
        return AccountStatus.REACTIVATION_REQUIRED
      }

      /**
       * If paid, we have to check permissions and default attributes
       */
      try {
        const permissions = JSON.parse(userInfo['custom:permissions'])
        Permissions.validatePermissions(permissions)
        validateSystemOrLocationAttr(userInfo['custom:location'])
        validateSystemOrLocationAttr(userInfo['custom:system'])

        // valid permissions and subscription paid => user has full regular access
        if (isFullAccessAllowed(subscription)) {
          return AccountStatus.FULL_ACCESS
        }

        if (isReadOnlyAccessAllowed(subscription)) {
          return AccountStatus.READ_ONLY_ACCESS
        }
      } catch (error) {
        /**
         * if the permissions validation fails, the user have to set up their clinic
         */
        if (isFullAccessAllowed(subscription)) {
          return AccountStatus.SETUP_PENDING
        }
      }
    } catch (error) {
      // invalid token
    }

    return AccountStatus.REACTIVATION_REQUIRED
  }

  /**
   * Get the status of the account
   * When the user creates a new account, this will
   */
  const getSubscriptionStatus = (): Nullable<SubscriptionStatus> => {
    try {
      validateNotNil<CognitoIdToken>(token)

      const userInfo = token.payload as DecodedUserPayload

      // no valid subscription attr means that the user still didn't pay
      if (
        !userInfo['custom:subscription'] ||
        !isValidSubscriptionAttr(userInfo['custom:subscription'])
      ) {
        return null
      }

      /**
       * If paid, we have to check permissions and default attributes
       */
      const subscription = JSON.parse(userInfo['custom:subscription']) as Subscription

      return subscription.status
    } catch (errror) {
      // invalid token
    }

    return null
  }

  /**
   * Get jwt token for authentication
   * @returns {string} Cognito jwtToken
   */
  const getToken = (): string => {
    validateNotNil<CognitoIdToken>(token)

    return token.getJwtToken()
  }

  /**
   * This contains some extra information like MFA preferences
   */
  const getUserData = async (): Promise<UserData> => {
    if (user === null || typeof user.getUserData !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    return new Promise((resolve, reject) => {
      user.getUserData((error, data) => {
        if (error) {
          return reject(error)
        }

        if (data) {
          resolve(data)
        }

        reject(new Error('Unknown'))
      })
    })
  }

  /**
   * Get user email
   * @returns {string} User full name
   */
  const getUserEmail = (): string => {
    validateNotNil<CognitoIdToken>(token)

    const userInfo = token.payload as DecodedUserPayload
    return userInfo.email
  }

  /**
   * Get user family name
   */
  const getUserFamilyName = (): string => {
    validateNotNil<CognitoIdToken>(token)

    const userInfo = token.payload as DecodedUserPayload
    return userInfo.family_name
  }

  /**
   * Get user full name
   */
  const getUserFullName = (): string => {
    validateNotNil<CognitoIdToken>(token)

    const userInfo = token.payload as DecodedUserPayload
    return `${userInfo.given_name} ${userInfo.family_name}`
  }

  /**
   * Get user given name
   */
  const getUserGivenName = (): string => {
    validateNotNil<CognitoIdToken>(token)

    const userInfo = token.payload as DecodedUserPayload
    return userInfo.given_name
  }

  /**
   * Get the subject of the jwt token
   * @returns {string} subject
   */
  const getUserId = (): string => {
    validateNotNil<CognitoIdToken>(token)

    const userInfo = token.payload as DecodedUserPayload
    return userInfo.sub
  }

  /**
   * Get user locale
   * @returns {string} locale
   */
  const getUserLocale = (): Locales => {
    validateNotNil<CognitoIdToken>(token)

    const userInfo = token.payload as DecodedUserPayload
    return userInfo.locale as Locales
  }

  /**
   * Get custom attr: default location id
   * @returns {string} location id
   */
  const getUserLocation = (): DefaultSystemOrLocation => {
    validateNotNil<CognitoIdToken>(token)

    const userInfo = token.payload as DecodedUserPayload

    // custom attributes can be null for new users
    const locationAttr = userInfo['custom:location']
    validateSystemOrLocationAttr(locationAttr)

    return JSON.parse(locationAttr) as DefaultSystemOrLocation
  }

  /**
   * Get cognito username
   * @returns {string} Cognito username
   */
  const getUsername = (): Nullable<string> => {
    if (user === null || typeof user.getUsername !== 'function') {
      return null
    }

    return user.getUsername()
  }

  /**
   * Get user phone
   * @returns {string} User full name
   */
  const getUserPhoneNumber = (): string => {
    validateNotNil<CognitoIdToken>(token)

    const userInfo = token.payload as DecodedUserPayload
    return userInfo.phone_number
  }

  /**
   * Get custom attr: default system id
   * @returns {string} system
   */
  const getUserSystem = (): DefaultSystemOrLocation => {
    validateNotNil<CognitoIdToken>(token)

    const userInfo = token.payload as DecodedUserPayload

    const systemAttr = userInfo['custom:system']
    validateSystemOrLocationAttr(systemAttr)

    return JSON.parse(systemAttr) as DefaultSystemOrLocation
  }

  /**
   * Check if session is booting
   * @returns {boolean} User is logged in
   */
  const isBooting = (): boolean => {
    return sessionState === SessionState.BOOTING
  }

  /**
   * Check if user is logged in
   * @returns {boolean} User is logged in
   */
  const isLoggedIn = (): boolean => {
    try {
      validateNotNil<CognitoIdToken>(token)
    } catch (error) {
      return false
    }

    return sessionState === SessionState.LOGGED_IN
  }

  /**
   * Check if user is an admin
   * @returns {boolean} User is admin
   */
  const isAdmin = (): boolean => {
    try {
      validateNotNil<CognitoIdToken>(token)
    } catch (error) {
      return false
    }

    const userInfo = token.payload as DecodedUserPayload
    const userPermissions = new Permissions(userInfo['custom:permissions'])
    return userPermissions.hasGlobalRole(GlobalRole.ADMIN)
  }

  /**
   * Check if user email is verified
   */
  const isEmailVerified = (): boolean => {
    try {
      validateNotNil<CognitoIdToken>(token)
    } catch (error) {
      return false
    }

    const userInfo = token.payload as DecodedUserPayload
    return userInfo.email_verified
  }

  /**
   * Check if user phone number is verified
   */
  const isPhoneNumberVerified = (): boolean => {
    try {
      validateNotNil<CognitoIdToken>(token)
    } catch (error) {
      return false
    }

    const userInfo = token.payload as DecodedUserPayload
    return userInfo.phone_number_verified
  }

  /**
   * Log in user
   * @param params login params
   * @returns {Promise<void>}
   */
  const logIn = async (params: LogInParams): Promise<LogInResponse> => {
    const { username, password } = params

    const credentials = new AuthenticationDetails({
      Username: username,
      Password: password,
    })

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: pool,
    })

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(credentials, {
        onSuccess: (newSession: CognitoUserSession) => {
          setUser(cognitoUser)
          setCurrentSession(newSession)
          const result: LogInResponse = {
            totpRequired: false,
          }
          resolve(result)
        },
        onFailure: (error) => {
          if (error?.code === 'UserNotConfirmedException') {
            setUser(cognitoUser)
          }

          reject(error)
        },
        totpRequired: () => {
          setUser(cognitoUser)
          const result: LogInResponse = {
            totpRequired: true,
          }
          resolve(result)
        },
      })
    })
  }

  /**
   * Log out user
   */
  const logOut = () => {
    if (user && typeof user.signOut === 'function') {
      user.signOut()
    }

    setSessionState(SessionState.LOGGED_OUT)
  }

  /**
   * Request an attr verification by sending a code
   */
  const requestAttributeVerification = async (
    params: RequestAttributeVerificationParams
  ): Promise<void> => {
    if (user === null || typeof user.getAttributeVerificationCode !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    return new Promise((resolve, reject) => {
      user.getAttributeVerificationCode(params.attributeName, {
        onSuccess: () => {
          resolve()
        },
        onFailure: (error) => reject(error),
      })
    })
  }

  /**
   * Request a new attr verification code
   */
  const resendAttributeVerificationCode = async (
    params: RequestAttributeVerificationParams
  ): Promise<void> => {
    if (user === null || typeof user.getAttributeVerificationCode !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    return new Promise((resolve, reject) => {
      user.getAttributeVerificationCode(params.attributeName, {
        onSuccess: () => {
          resolve()
        },
        onFailure: (error) => reject(error),
      })
    })
  }

  /**
   * Request confrimatino code re-sending
   * @returns {Promise<void>}
   */
  const resendConfirmationCode = (): Promise<void> => {
    if (user === null || typeof user.resendConfirmationCode !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    return new Promise((resolve, reject) => {
      user.resendConfirmationCode((error) => {
        if (error) {
          return reject(error)
        }
        resolve()
      })
    })
  }

  /**
   * Update password
   * @param params Reset password params
   */
  const resetPassword = (params: ResetPasswordParams): Promise<string> => {
    if (user === null || typeof user.confirmPassword !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    const { verificationCode, newPassword } = params
    return new Promise((resolve, reject) => {
      user.confirmPassword(verificationCode, newPassword, {
        onSuccess: (data) => resolve(data),
        onFailure: reject,
      })
    })
  }

  /**
   * Send MFA code
   */
  const sendMFACode = async (params: SendMFATokenParams): Promise<void> => {
    if (user === null || typeof user.sendMFACode !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    return new Promise((resolve, reject) => {
      user.sendMFACode(
        params.confirmationCode,
        {
          onSuccess: (session) => {
            setCurrentSession(session)
            resolve()
          },
          onFailure: (error) => {
            reject(error)
          },
        },
        'SOFTWARE_TOKEN_MFA'
      )
    })
  }

  /**
   * Register new user
   * @param params Sign up params
   * @returns {Promise<void>}
   */
  const signUp = (params: SignUpParams): Promise<void> => {
    const { username, password, email, firstName, lastName, phoneNumber } = params
    const locale = FALLBACK_LOCALE
    const atts = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
      new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
      new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
      new CognitoUserAttribute({ Name: 'phone_number', Value: phoneNumber ?? '' }),
      new CognitoUserAttribute({ Name: 'locale', Value: locale }),
    ]

    return new Promise((resolve, reject) => {
      pool.signUp(username, password, atts, [], (err?: Error, result?: ISignUpResult) => {
        if (err) {
          reject(err)
          return
        }

        if (result) {
          setUser(result.user)
          resolve()
        }

        reject(new Error('Invalid result'))
      })
    })
  }

  /**
   * Update cognito attribute
   * Name, mail & phone are updated in the same ways in the
   * preferences page using this fn
   *
   * @param Name Cognit attr name
   * @param Value attr value
   * @returns
   */
  const updateAttribute = async (params: UpdateAttributeParams): Promise<void> => {
    if (user === null || typeof user.updateAttributes !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    return new Promise((resolve, reject) => {
      const atts = [
        new CognitoUserAttribute({
          Name: params.attributeName,
          Value: params.value,
        }),
      ]

      user.updateAttributes(atts, (error) => {
        if (error) {
          return reject(error)
        }

        refresh()
        resolve()
      })
    })
  }

  /**
   * Update user locale [Replace by the above fn]
   * @param locale string
   */
  const updateUserLocale = async (params: UpdateLocaleParams): Promise<void> => {
    if (user === null || typeof user.updateAttributes !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    return new Promise((resolve, reject) => {
      const atts = [new CognitoUserAttribute({ Name: 'locale', Value: params.locale })]

      user.updateAttributes(atts, (error) => {
        if (error) {
          return reject(error)
        }

        refresh()
        resolve()
      })
    })
  }

  /**
   * Verify attribute using the received code
   */
  const verifyAttribute = (params: VerifyAttributeParams): Promise<void> => {
    if (user === null || typeof user.getAttributeVerificationCode !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    return new Promise((resolve, reject) => {
      user.verifyAttribute(params.attributeName, params.code, {
        onSuccess: () => {
          resolve()
          refresh()
        },
        onFailure: (error) => reject(error),
      })
    })
  }

  /**
   * MFA Token verification
   */
  const verifyMFAToken = async (params: VerifyMFATokenParams): Promise<VerifyMFATokenResponse> => {
    if (user === null || typeof user.verifySoftwareToken !== 'function') {
      return Promise.reject(new Error('Invalid user'))
    }

    return new Promise((resolve, reject) => {
      user.verifySoftwareToken(params.totpCode, params.friendlyDeviceName, {
        onSuccess: (value: any) => {
          // types file is wrong
          resolve(value as VerifyMFATokenResponse)
        },
        onFailure: (error) => reject(error),
      })
    })
  }

  // const updateAttribute = (params: UpdateAttributeParams) => Promise < void>
  // const userHasRole = (role: string) => boolean
  // const verifyAttribute = (params: VerifyAttributeParams) => Promise < void>

  /**
   * Refresh the existing section after mounting the context if exist
   */
  React.useEffect(() => {
    if (sessionState === SessionState.BOOTING) {
      refresh()
    }
  }, [refresh, sessionState])

  const session = {
    associateMFAToken,
    changePassword,
    confirmRegistration,
    disableMFA,
    enableMFA,
    forgotPassword,
    getAccountStatus,
    getSubscriptionStatus,
    getToken,
    getUserData,
    getUserEmail,
    getUserId,
    getUserFamilyName,
    getUserFullName,
    getUserGivenName,
    getUserLocale,
    getUserLocation,
    getUsername,
    getUserPhoneNumber,
    getUserSystem,
    getUserUnitSystem,
    isAdmin,
    isBooting,
    isEmailVerified,
    isLoggedIn,
    isPhoneNumberVerified,
    logIn,
    logOut,
    refresh,
    requestAttributeVerification,
    resendAttributeVerificationCode,
    resendConfirmationCode,
    resetPassword,
    sendMFACode,
    signUp,
    state: sessionState,
    updateAttribute,
    updateUserLocale,
    changeUserUnitSystem,
    verifyAttribute,
    verifyMFAToken,
  }

  return <SessionContext.Provider value={session}>{props.children}</SessionContext.Provider>
}
