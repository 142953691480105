import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { Form } from 'react-final-form'
import { FullPatient, NonPrimitiveCustomFieldType } from '../../api/interfaces'
import { useUpdateCustomGroup } from './useUpdateCustomGroup'
import { ErrorViewer } from '../ErrorViewer'
import { Box, Button, Heading, useBreakpointValue } from '@chakra-ui/react'

import { isPrimitiveCustomField } from '../CustomFields/utils'
import { CustomFieldInput } from './CustomFieldInput'

const messages = defineMessages({
  updateButton: {
    id: 'UI.button_update',
    defaultMessage: 'Update'
  },
})
interface Props {
  groupId: string
  patient: FullPatient
}

export const CustomGroup: React.FC<Props> = props => {
  const { patient, groupId } = props

  const intl = useIntl()

  const params = {
    patient,
    id: groupId,
  }

  const {
    name,
    fields,
    initialValues,
    validate,
    error,
    onErrorClose,
    onSubmit,
    isLoading
  } = useUpdateCustomGroup(params)

  const isFullWidth = useBreakpointValue({ base: true, md: false })
  return <Box p={4} borderWidth="1px" my={4} rounded={'lg'}>
    <Heading size="md" >
      {name}
    </Heading>

    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      keepDirtyOnReinitialize
      render={({
        handleSubmit,
        pristine
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          {

            fields.map((field, index) => {
              const { id, name } = field
              const key = `cf-${id}-${index}`

              if (isPrimitiveCustomField(field)) {
                return <CustomFieldInput key={key} id={id} name={name} type={field.type} />
              }

              return <CustomFieldInput
                key={key}
                id={id}
                name={name}
                type={NonPrimitiveCustomFieldType.TEXT_LIST}
                options={field?.options}
              />
            })
          }
          {
            error &&
            <ErrorViewer title={error} onClose={onErrorClose} />
          }
          <Button
            isDisabled={pristine}
            isLoading={isLoading}
            colorScheme='brand'
            type="submit"
            isFullWidth={isFullWidth}
          >
            {intl.formatMessage(messages.updateButton)}
          </Button>
        </form>
      )}
    />
  </Box>

}

