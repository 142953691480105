import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { REFRESH_INDEX } from '../../api/requests/staff'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  patientNotFound: {
    id: 'UseOrderActions.patient_not_found',
    defaultMessage: 'The patient was not found.',
  },
  invalidProcedureId: {
    id: 'UseOrderActions.invalid_procedure_id',
    defaultMessage: 'You must select a valid procedure.',
  },
  genericErrorMessage: {
    id: 'UseOrderActions.generic_error_message',
    defaultMessage: 'Something went wrong, try again later.',
  },
})
export type UseRefreshIndexParams = MutationHookOptions<void, void>

export const useRefreshIndex = (params?: UseRefreshIndexParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.genericErrorMessage))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)

    translateError(firstError)
  }

  const onErrorClose = () => {
    setError(null)
  }

  const [refreshIndex, { loading: isLoading }] = useMutation<void, void>(REFRESH_INDEX, {
    onError,
    onCompleted: onErrorClose,
    ...params,
  })

  return {
    refreshIndex,
    isLoading,
    error,
    onErrorClose,
  }
}
