import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ADD_PROGRESS_NOTE, REMOVE_PROGRESS_NOTE } from '../../api/requests/activeVisit'
import { ValidateFn, ValidationError } from '../Form'
import { FullPatientWithActiveVisit } from '../../api/interfaces'

const messages = defineMessages({
  requiredError: {
    id: 'UI.required_error',
    defaultMessage: 'You must enter a value for this field.',
  },
  defaultError: {
    id: 'AddProgressNoteButton.default_error_progress_note',
    defaultMessage: 'Something went wrong. Please, try again later.',
  },
})

interface NewProgressNote {
  note: string
}

interface AddProgressNoteVariables {
  patientId: string
  visitId: string
  note: string
}

interface AddProgressNoteData {
  addProgressNote: FullPatientWithActiveVisit
}

export type UseAddProgressNoteParams = MutationHookOptions<AddProgressNoteData, AddProgressNoteVariables> & {
  patientId: string
  visitId: string
}

export const useAddProgressNote = (params: UseAddProgressNoteParams) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
      case 'visit_not_found':
      case 'error_adding_progress_note':
      default:
        return setError(intl.formatMessage(messages.defaultError))
    }
  }

  const validate: ValidateFn<NewProgressNote> = async (values) => {
    const errors: ValidationError<NewProgressNote> = {}

    if (!values.note) {
      errors.note = intl.formatMessage(messages.requiredError)
    }

    return errors
  }

  const [addProgressNote, { loading: isLoading }] = useMutation(ADD_PROGRESS_NOTE, {
    ...restParams,
    onError,
  })

  const initialValues = {
    note: '',
  }

  const onSubmit = (values: NewProgressNote) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      note: values.note,
    }

    addProgressNote({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    initialValues,
    onSubmit,
    isLoading,
    validate,
    error,
    onErrorClose,
  }
}

interface RemoveProgressNoteVariables {
  patientId: string
  visitId: string
  progressNoteId: string
}

interface RemoveProgressNoteData {
  removeProgressNote: FullPatientWithActiveVisit
}

export type UseRemoveProgressNoteParams = MutationHookOptions<RemoveProgressNoteData, RemoveProgressNoteVariables> & {
  patientId: string
  visitId: string
}
export const useRemoveProgressNote = (params: UseRemoveProgressNoteParams) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
      case 'visit_not_found':
      case 'error_removing_progress_note':
      default:
        return setError(intl.formatMessage(messages.defaultError))
    }
  }

  const [removeProgressNote, { loading: isLoading }] = useMutation(REMOVE_PROGRESS_NOTE, {
    ...restParams,
    onError,
  })

  const onDelete = (progressNoteId: string) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      progressNoteId,
    }

    removeProgressNote({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}
