import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Loading } from '..'
import { useSession } from '../Auth'
import { sitemap } from './sitemap'
import { UnauthTemplate } from '../Layout/Template'
import {
  AsyncActiveVisit,
  AsyncAuth,
  AsyncAppContent,
  AsyncBilling,
  AsyncMetrics,
  AsyncPatients,
  AsyncPreferences,
  AsyncPricing,
  AsyncScheduler,
  AsyncSetup,
  AsyncStaff,
  AsyncUnauth,
  AsyncVisits,
} from './LazyScenes'
import { SystemsProvider } from '../Systems'
import { AccountStatus } from '../Auth/subscription'
import { ComplaintListProvider } from '../Exam/ComplaintListContext'
import { ListProvider } from '../Exam/ListContext'
import { ExamProvider } from '../Exam/ExamContext'

/**
 * IDEA
 * Users can be authenticated or not.
 *
 * Authenticated users may still need to complete the setup
 * process.
 * So, the routing will depend on the status of their account
 *
 * In other case, they will be redirected
 * to the unauth scene
 */
export const RouterContainer = () => {
  const { isLoggedIn } = useSession()

  return <Suspense fallback={<Loading my={6} />}>{isLoggedIn() ? <AuthRouter /> : <UnauthRouter />}</Suspense>
}

const UnauthRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<UnauthTemplate />}>
        <Route path="*" element={<AsyncUnauth />} />
      </Route>
    </Routes>
  )
}

const AuthRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<Auth />} />
    </Routes>
  )
}

/**
 * The setup progress contains 2 basic steps:
 * Payment checkout and Clinic customization
 *
 * This may seem confusing but using different routes allow us to restrict the navigation
 * No 403 or 404 screens
 */
const Auth: React.FC = () => {
  const { getAccountStatus } = useSession()
  const accountStatus = getAccountStatus()

  switch (accountStatus) {
    case AccountStatus.PAYMENT_REQUIRED:
    case AccountStatus.REACTIVATION_REQUIRED:
      return (
        <Routes>
          <Route path={`${sitemap.setup.route}/*`} element={<AsyncSetup />} />
          <Route path={`${sitemap.preferences.route}/*`} element={<AsyncPreferences />} />
          <Route path={`${sitemap.auth.route}/*`} element={<AsyncAuth />} />

          {/* default redirection to avoid white screens */}
          <Route path="*" element={<Navigate to={sitemap.setup.routes.checkout.absolutePath} replace={true} />} />
        </Routes>
      )

    case AccountStatus.SETUP_PENDING:
      return (
        <Routes>
          <Route path={`${sitemap.setup.route}/*`} element={<AsyncSetup />} />
          <Route path={`${sitemap.preferences.route}/*`} element={<AsyncPreferences />} />
          <Route path={`${sitemap.auth.route}/*`} element={<AsyncAuth />} />

          {/* default redirection to avoid white screens */}
          <Route path="*" element={<Navigate to={sitemap.setup.routes.location.absolutePath} replace={true} />} />
        </Routes>
      )

    case AccountStatus.READ_ONLY_ACCESS:
    case AccountStatus.FULL_ACCESS:
      return <AuthRouterWithSystemInfo />
  }
}

/**
 * This allow us to use the useSystem hook synchronously
 */
const AuthRouterWithSystemInfo: React.FC = () => {
  return (
    <SystemsProvider>
      <ComplaintListProvider>
        <ListProvider>
          <ExamProvider>
            <Routes>
              <Route path={`${sitemap.activeVisit.route}/*`} element={<AsyncActiveVisit />} />
              <Route path={`${sitemap.appContent.route}/*`} element={<AsyncAppContent />} />
              <Route path={`${sitemap.auth.route}/*`} element={<AsyncAuth />} />
              <Route path={`${sitemap.billing.route}/*`} element={<AsyncBilling />} />
              <Route path={`${sitemap.metrics.route}/*`} element={<AsyncMetrics />} />
              <Route path={`${sitemap.patients.route}/*`} element={<AsyncPatients />} />
              <Route path={`${sitemap.preferences.route}/*`} element={<AsyncPreferences />} />
              <Route path={`${sitemap.scheduler.route}/*`} element={<AsyncScheduler />} />
              <Route path={`${sitemap.pricing.route}/*`} element={<AsyncPricing />} />
              <Route path={`${sitemap.staff.route}/*`} element={<AsyncStaff />} />
              <Route path={`${sitemap.visits.route}/*`} element={<AsyncVisits />} />

              {/* default redirection to avoid white screens */}
              <Route path="*" element={<Navigate to={sitemap.patients.routes.waiting.absolutePath} replace={true} />} />
            </Routes>
          </ExamProvider>
        </ListProvider>
      </ComplaintListProvider>
    </SystemsProvider>
  )
}
