import React from 'react'
import { RegistrationGroup } from '../../api/interfaces'
import {
  Box,
  SimpleGrid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  UnorderedList,
  ListItem,
  Text,
} from '@chakra-ui/react'
import { defineMessages, useIntl } from 'react-intl'
import { ConfirmButton, ConfirmButtonProps } from '..'
import { RiMore2Line as RightArrowIcon, RiDeleteBin2Line as DeleteIcon } from 'react-icons/ri'
import { useSystems } from '../Systems'
import {
  CreateRegistrationGroupButton,
  UpdateRegistrationGroupButton,
  useDeleteRegistrationGroup,
  useCustomFields
} from '.'

const messages = defineMessages({
  customGroups: {
    id: 'RegistrationTab.label_custom_groups',
    defaultMessage: 'Custom groups'
  },
  nameLabel: {
    id: 'RegistrationTab.group_name',
    defaultMessage: 'Group name'
  },
  fieldCount: {
    id: 'form_validation.registration_field_count',
    defaultMessage: '{count} fields'
  },
  confirmationMessage: {
    id: 'RegistrationTab.remove_registration_group',
    defaultMessage: 'This group will be removed from the registration form. Are you sure?'
  },
  noFieldsFound: {
    id: 'RegistrationTab.no_fields_found',
    defaultMessage: 'No fields found'
  },
  addField: {
    id: 'RegistrationTab.add_field',
    defaultMessage: 'Add field'
  },
  delete: {
    id: 'UI.button_delete',
    defaultMessage: 'Delete',
  },
  edit: {
    id: 'UI.button_edit',
    defaultMessage: 'Edit',
  },
})

export const RegistrationGroups: React.FC = () => {
  const { getMySystem } = useSystems()
  const { registration } = getMySystem()

  const groups = registration?.groups ?? []

  return <List groups={groups} />
}


interface RegistrationGroupsProps {
  groups: Array<RegistrationGroup>
}

const List: React.FC<RegistrationGroupsProps> = props => {
  const { groups } = props

  const intl = useIntl()


  return (
    <Box p={2}>
      <Heading size={'md'} mb={2}>
        {intl.formatMessage(messages.customGroups)}
      </Heading>
      <SimpleGrid columns={[1, 2, 3]} spacing={3}>
        {
          groups.map((group) => <Item group={group} key={group.id} />)
        }
      </SimpleGrid>
      <HStack justifyContent={'end'} mt={3}>
        <CreateRegistrationGroupButton />
      </HStack>
    </Box>
  )
}


interface ItemProps {
  group: RegistrationGroup
}

const Item: React.FC<ItemProps> = (props) => {
  const { group } = props

  const intl = useIntl()
  const { getCustomField } = useCustomFields()

  const { onSubmit, isLoading, error, onErrorClose } = useDeleteRegistrationGroup({ id: group.id })


  const onConfirm = () => {
    onSubmit()
  }

  const buttonStyles = {
    variant: 'ghost',
    isFullWidth: true,
    justifyContent: 'start',
    borderRadius: 'sm',
  }

  const deletePatientProps: ConfirmButtonProps = {
    onConfirm,
    message: intl.formatMessage(messages.confirmationMessage),
    error,
    onErrorClose,
    buttonProps: {
      label: (
        <>
          <Icon as={DeleteIcon} mr={2} />
          {intl.formatMessage(messages.delete)}
        </>
      ),
      colorScheme: 'red',
      ...buttonStyles,
    },
    confirmButtonProps: {
      label: intl.formatMessage(messages.delete),
      isLoading,
    },
    skipClosing: true,
  }

  return (
    <Box p={3} borderWidth={1} borderRadius='md' position={'relative'}>
      <HStack p={0} justifyContent='space-between' alignItems={'center'} pb={1} mb={1}>
        <Heading size="md" fontWeight={'bolder'} >
          {group.name}
        </Heading>
        <Menu >
          <MenuButton
            icon={<RightArrowIcon />}
            variant={'ghost'}
            as={IconButton}
          />
          <MenuList zIndex={2}>
            <UpdateRegistrationGroupButton
              group={group}
              {...buttonStyles}
              colorScheme='brand'
              as={MenuItem}
            />
            <MenuDivider />
            <ConfirmButton {...deletePatientProps} />
          </MenuList>
        </Menu>
      </HStack>
      <UnorderedList >
        {
          group.customFields.map(fieldId => {
            const field = getCustomField(fieldId)

            if (!field) {
              return null
            }

            return < ListItem key={field.id}>
              <Text fontSize={'sm'}>{field.name}</Text>
            </ListItem>
          })
        }
      </UnorderedList>


    </Box >
  )
}