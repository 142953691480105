import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { TranslatableText } from '../../api/interfaces'

interface DynamicTranslationProps extends TextProps {
  message: TranslatableText
}

export const DynamicTranslation: React.FC<DynamicTranslationProps> = props => {
  const { message, ...textProps } = props

  const intl = useIntl()
  
  const text = (message?.text ?? '').trim()
  if (!text) {
    return null
  }

  const prefix = 'Dynamic'
  const defaultMessage = text 
  const id = `${prefix}.${defaultMessage}`

  return <Text {...textProps}>
    {intl.formatMessage({ id, defaultMessage })}
  </Text>
}

