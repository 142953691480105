import { gql } from '@apollo/client'
import { BasicChecklist, FullChecklist } from '../fragments'

export const GET_CHECKLISTS = gql`
  ${BasicChecklist}
  query ($q: String, $offset: Int, $limit: Int) {
    checklists(q: $q, offset: $offset, limit: $limit) {
      docs {
        ...BasicChecklist
      }
      count
    }
  }
`

export const GET_CHECKLIST_BY_ID = gql`
  ${FullChecklist}
  query checklist($id: ID!) {
    checklist(id: $id) {
      ...FullChecklist
    }
  }
`
