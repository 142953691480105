import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ButtonProps } from '@chakra-ui/react'
import { ConfirmButton, ConfirmButtonProps } from '..'
import { useCancelStaffSubscription } from './'

const messages = defineMessages({
  enableAccount: {
    id: 'UI.button_enable',
    defaultMessage: 'Cancel subscription'
  },
  confirmationMessage: {
    id: 'StaffAdmin.cancel_subscription_warning',
    defaultMessage: 'You are going to cancel the user subscription. That means this user will no longer have access to their data until activating a new subscription. Do you want to continue?'
  },
})

type Props = ButtonProps & {
  customerId: string
  onCanceled: () => void
}


export const CancelStaffSubscriptionButton: React.FC<Props> = props => {
  const { customerId, onCanceled, ...buttonProps } = props

  const intl = useIntl()

  const {
    cancelSubscription,
    isLoading,
    error,
    onErrorClose
  } = useCancelStaffSubscription({ onCompleted: onCanceled })

  const onConfirm = () => {
    const variables = {
      customerId
    }

    cancelSubscription({ variables })
  }


  const buttonStyles = {
    borderRadius: 'md',
    isLoading,
    ...buttonProps
  }

  const cleanVisitProps: ConfirmButtonProps = {
    onConfirm,
    message: intl.formatMessage(messages.confirmationMessage),
    buttonProps: {
      label: intl.formatMessage(messages.enableAccount),
      colorScheme: 'red',
      ...buttonStyles
    },
    confirmButtonProps: {
      isLoading,
      colorScheme: 'red',
      label: intl.formatMessage(messages.enableAccount)
    },
    error,
    onErrorClose
  }

  return <ConfirmButton {...cleanVisitProps} />
}
