import { API, BlockTool, BlockToolData } from '@editorjs/editorjs'
import { RangeIcon } from './icons'

interface Data {
  value: string
  label: string
  min: string
  max: string
  step: string
}
interface Config {
  isFillable: boolean
  onInputChange: (blockId: string, value: string) => void
}

const DEFAULT_VALUE: Data = {
  label: 'Label',
  value: '0',
  min: '0',
  max: '10',
  step: '1',
}

enum Settings {
  MIN = 'min',
  MAX = 'max',
  STEP = 'step',
}

class Range implements BlockTool {
  private api: API
  private config: Config
  private data: Data
  private input: HTMLInputElement
  private after: HTMLSpanElement
  private before: HTMLSpanElement
  private valueIndicator: HTMLSpanElement

  constructor(params) {
    this.api = params.api
    this.config = params.config
    this.data = {
      label: params?.data?.label ?? DEFAULT_VALUE.label,
      value: params?.data?.value ?? DEFAULT_VALUE.value,
      min: params?.data?.min ?? DEFAULT_VALUE.min,
      max: params?.data?.max ?? DEFAULT_VALUE.max,
      step: params?.data?.step ?? DEFAULT_VALUE.step,
    }

    this.before = document.createElement('span')
    this.after = document.createElement('span')
    this.valueIndicator = document.createElement('span')
    this.input = document.createElement('input')
    this.input.type = 'range'
    this.input.id = params.block.id
    this.input.name = params.block.id
    this.input.defaultValue = this.data.value.toString()

    /**
     * Read only flag blocks the data saving
     * So, this allow us to edit only the value
     * when the doctor is filling the survey
     */
    if (this.config.isFillable) {
      this.input.addEventListener('input', (event: Event) => {
        const value = (<HTMLInputElement>event.target).value

        this.valueIndicator.innerHTML = value
        this.config.onInputChange(this.input.id, value)
      })
    }
  }

  render(): HTMLElement {
    const view = document.createElement('div')
    const labelWrapper = document.createElement('div')
    const label = document.createElement('label')
    const inputGroup = document.createElement('div')

    this.input.min = this.data.min.toString()
    this.input.max = this.data.max.toString()
    this.input.step = this.data.step.toString()

    this.input.disabled = !this.config.isFillable
    this.input.style.flex = '1'

    inputGroup.style.display = 'flex'
    inputGroup.style.alignItems = 'center'
    inputGroup.style.justifyContent = 'space-between'
    inputGroup.style.gap = '5px'
    inputGroup.style.marginTop = '5px'
    inputGroup.style.width = '100%'

    this.before.innerHTML = this.data.min
    this.after.innerHTML = this.data.max

    inputGroup.appendChild(this.before)
    inputGroup.appendChild(this.input)
    inputGroup.appendChild(this.after)

    // only during the creation. not when doctors fill it
    label.contentEditable = this.api.readOnly.isEnabled ? 'false' : 'true'
    label.innerHTML = this.data.label
    label.htmlFor = this.input.id

    this.valueIndicator.innerHTML = this.input.value

    labelWrapper.style.display = 'flex'
    labelWrapper.style.justifyContent = 'space-between'
    labelWrapper.style.alignItems = 'center'

    labelWrapper.appendChild(label)
    labelWrapper.appendChild(this.valueIndicator)

    view.style.marginBottom = '10px'
    view.style.marginTop = '10px'
    view.appendChild(labelWrapper)
    view.appendChild(inputGroup)

    return view
  }

  validate?(blockData: BlockToolData): boolean {
    const minAsNumber = parseFloat(this.data.min)
    const maxAsNumber = parseFloat(this.data.max)
    const stepAsNumber = parseFloat(this.data.step)

    return (
      blockData.label && !isNaN(minAsNumber) && maxAsNumber > minAsNumber && maxAsNumber - minAsNumber >= stepAsNumber
    )
  }

  save(blockContent: HTMLElement): BlockToolData {
    const label = blockContent.querySelector('[contenteditable]')

    return {
      label: label?.innerHTML,
      value: this.input.value,
      min: this.data.min,
      max: this.data.max,
      step: this.data.step,
    }
  }

  renderSettings() {
    const wrapper = document.createElement('div')
    wrapper.style.padding = '2px'

    Object.values(Settings).forEach((tune) => {
      const option = document.createElement('div')
      const optionInput = document.createElement('input')
      const label = document.createElement('label')

      option.style.display = 'flex'
      option.style.alignItems = 'center'
      option.style.justifyContent = 'flex-start'
      option.style.width = '100%'

      label.htmlFor = tune
      label.innerHTML = tune
      label.style.margin = '0 0 0 4px'
      label.style.width = '100%'
      label.style.textAlign = 'start'
      label.style.cursor = 'pointer'

      optionInput.type = 'number'
      optionInput.value = this.data[tune].toString()
      optionInput.name = tune
      optionInput.id = tune
      optionInput.style.width = '8ch'
      optionInput.style.height = '2rem'
      optionInput.style.borderRadius = '3px'
      optionInput.style.borderWidth = '1px'
      optionInput.style.marginLeft = '3px'

      optionInput.addEventListener('change', (event: Event) => {
        const { value } = event.target as HTMLInputElement

        const asNumber = parseFloat(value)
        if (!isNaN(asNumber)) {
          this.updateSettings(tune, value)
        }
      })

      option.appendChild(label)
      option.appendChild(optionInput)

      wrapper.appendChild(option)
    })

    return wrapper
  }

  updateSettings(tune: Settings, value: string) {
    this.data[tune] = value
    this.input[tune] = value
    if (tune === Settings.MAX) {
      this.after.innerHTML = value
    }

    if (tune === Settings.MIN) {
      this.before.innerHTML = value
    }
  }
  /**
   * Displaying at the toolbox
   */
  static get toolbox() {
    return {
      title: 'Range',
      icon: RangeIcon,
    }
  }

  /**
   * Notify core that the read-only mode is supported
   *
   * @returns {boolean}
   * @public
   */
  static get isReadOnlySupported() {
    return true
  }
}

export default Range
