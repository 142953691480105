import { gql } from '@apollo/client'
import { UserSignature } from './common'

export const FullBillableItem = gql`
  fragment FullBillableItem on BillableItem {
    billingCode
    category
    description {
      text
    }
    wholesale
  }
`

export const FullPrice = gql`
  fragment FullPrice on Price {
    id
    pricelistId
    billingCode
    category
    description {
      text
    }
    amount
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`
