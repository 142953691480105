import React from 'react'
import { FullComment } from '../api/interfaces'
import { PrintableSignatureTag } from '.'
import { Box, Text } from '@chakra-ui/layout'

type PrintableCommentsProps = {
  items: Array<FullComment>
}

export const PrintableComments: React.FC<PrintableCommentsProps> = props => {
  const { items } = props

  return <Box>
    {
      items.map((comment, index) => {
        const { id, text, created } = comment
        const key = `history-comment-${id}-${index}`

        return <Text key={key} as={'span'} display={'flex'}>
          <PrintableSignatureTag signature={created} mr={2} />
          {text}
        </Text>
      })
    }
  </Box>
}