import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  FullChecklist,
  //  FullProcedureOrder,
  // FullStudyOrder,
  FullRX,
} from '../../api/interfaces'
import { Box, BoxProps, Flex, Heading, VStack } from '@chakra-ui/react'
import { Nullable } from '../../utils'
import { Checklist } from '../../components/Checklist/Checklist'
import { useAddChecklistToVisit, useRemoveChecklistFromVisit } from '.'
import { ErrorViewer, Loading } from '..'
import { ChecklistSelector } from '../Selector'
import { DynamicTranslation } from '../i18n'

const messages = defineMessages({
  addChecklist: {
    id: 'DiagnosisChecklists.add_checklist',
    defaultMessage: 'Add a checklist',
  },
  checklistTitle: {
    id: 'VisitResume.checklist_title',
    defaultMessage: 'Assessment',
  },
})

type DiagnosisChecklistsProps = BoxProps & {
  patientId: string
  visitId: string
  checklists: Nullable<Array<FullChecklist>>
  // studyOrders: Nullable<Array<FullStudyOrder>>
  // procedureOrders: Nullable<Array<FullProcedureOrder>>
  rxs?: Nullable<Array<FullRX>>
  isDisabled?: boolean
}

export const DiagnosisChecklists: React.FC<DiagnosisChecklistsProps> = (props) => {
  const {
    patientId,
    visitId,
    checklists,
    // studyOrders,
    // procedureOrders,
    isDisabled,
    ...boxProps
  } = props

  const params = {
    visitId,
    patientId,
  }

  const { onRemove, error, onErrorClose, isLoading } = useRemoveChecklistFromVisit(params)

  const filteredChecklists = React.useMemo(() => {
    if (checklists === null) {
      return []
    }
    return checklists.filter((checklist) => (checklist.general_tags || []).includes('Diagnosis'))
  }, [checklists])

  return (
    <Box {...boxProps}>
      <VStack w={'100%'}>
        {filteredChecklists.map((checklist, index) => {
          const key = `${checklist.name?.text}-${index}`
          const isLastOne = index === filteredChecklists.length - 1

          return (
            <Checklist
              key={key}
              checklist={checklist}
              visitId={visitId}
              patientId={patientId}
              // studyOrders={studyOrders ?? []}
              // procedureOrders={procedureOrders ?? []}
              onRemove={onRemove}
              isDisabled={isDisabled}
              isDeleting={isLoading}
              w={'100%'}
              defaultIsOpen={isLastOne}
            />
          )
        })}
      </VStack>
      {error && <ErrorViewer title={error} onClose={onErrorClose} />}
      <AddChecklist patientId={patientId} visitId={visitId} isDisabled={isDisabled} mt={2} />
    </Box>
  )
}

type AddChecklistProps = BoxProps & {
  patientId: string
  visitId: string
  isDisabled?: boolean
}

const AddChecklist: React.FC<AddChecklistProps> = (props) => {
  const { patientId, visitId, isDisabled, ...boxProps } = props

  const intl = useIntl()

  const params = {
    visitId,
    patientId,
  }

  const { onAdd, error, onErrorClose, isLoading } = useAddChecklistToVisit(params)

  return (
    <Box {...boxProps}>
      {isLoading && (
        <Flex justifyContent="center" my={2}>
          <Loading size="sm" />
        </Flex>
      )}
      <ChecklistSelector
        onChange={onAdd}
        value={''}
        placeholder={intl.formatMessage(messages.addChecklist)}
        isDisabled={isDisabled}
      />

      {error && <ErrorViewer title={error} onClose={onErrorClose} />}
    </Box>
  )
}

type PrintableDiagnosisChecklistsProps = BoxProps & {
  checklists: Array<FullChecklist>
}

export const PrintableDiagnosisChecklists: React.FC<PrintableDiagnosisChecklistsProps> = (
  props
) => {
  const { checklists, ...boxProps } = props

  const intl = useIntl()

  const filteredChecklists = React.useMemo(() => {
    if (checklists === null) {
      return []
    }
    return checklists.filter((checklist) => (checklist.general_tags || []).includes('Diagnosis'))
  }, [checklists])

  if (filteredChecklists.length === 0) {
    return null
  }

  return (
    <Box w={'100%'} {...boxProps}>
      <Heading
        size={'md'}
        mb={1}
        borderBottomWidth={1}
        sx={{
          '@media print': {
            margin: '0 0 .5rem 0',
            borderBottom: '1px solid black',
          },
        }}
      >
        {intl.formatMessage(messages.checklistTitle)}
      </Heading>
      <VStack w={'100%'} spacing={1}>
        {filteredChecklists.map((checklist, index) => {
          const key = `${checklist.name?.text}-${index}`

          return (
            <Box
              key={key}
              borderWidth={1}
              borderRadius={'md'}
              p={2}
              w={'100%'}
              className="pdfDiagnosisChecklists"
            >
              <DynamicTranslation fontSize={'md'} fontWeight={'bold'} message={checklist.name} />
            </Box>
          )
        })}
      </VStack>
    </Box>
  )
}
