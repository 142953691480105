import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { ADD_WEIGHT_MEASUREMENT, UPDATE_WEIGHT_MEASUREMENT } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ValidateFn, ValidationError } from '../Form'

const messages = defineMessages({
  patientNotFound: {
    id: 'RXActions.patient_not_found',
    defaultMessage: 'The patient was not found.',
  },
  visitNotFound: {
    id: 'Checklist.visit_not_found',
    defaultMessage: 'Could not find the current visit. Please, reload the page',
  },
  errorCreatingMeasurement: {
    id: 'Vitals.error_adding_measurement',
    defaultMessage: 'We could not add the measurement, try again later.',
  },
  invalidMeasurementId: {
    id: 'Vitals.invalid_measurement_id',
    defaultMessage: 'You must use a valid measurement id.',
  },
  errorRemovingMeasurement: {
    id: 'Vitals.error_removing_measurement',
    defaultMessage: 'We could not remove the measurement, try again later.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  minValueError: {
    id: 'form_validation.min_value',
    defaultMessage: 'This field must be greater than: {value}',
  },
  maxValueError: {
    id: 'form_validation.max_value',
    defaultMessage: 'This field must be lower than: {value}',
  },
})

export interface NewWeight {
  kg: number
}

interface CreateWeightVariables {
  patientId: string
  visitId: string
  measurement: NewWeight
}

interface CreateWeightData {
  addWeightMeasurement: FullPatientWithActiveVisit
}

type CreateWeightParams = MutationHookOptions<CreateWeightData, CreateWeightVariables> & {
  patientId: string
  visitId: string
}

export const useCreateWeight = (params: CreateWeightParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'invalid_measurement_id':
        return setError(intl.formatMessage(messages.invalidMeasurementId))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFound))

      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_measurement':
        return setError(intl.formatMessage(messages.errorCreatingMeasurement))

      case 'error_removing_measurement':
        return setError(intl.formatMessage(messages.errorRemovingMeasurement))

      case 'invalid_signature':
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [addWeightMeasurement, { loading: isLoading }] = useMutation(ADD_WEIGHT_MEASUREMENT, {
    onError,
    ...rest,
  })

  const validateMetric: ValidateFn<NewWeight> = async (values) => {
    const errors: ValidationError<NewWeight> = {}

    if (!Number(values.kg)) {
      errors.kg = intl.formatMessage(messages.minValueError, { value: 0 })
    }

    return errors
  }

  const validateImperial: ValidateFn<{ pounds: number; ounces: number }> = async (values) => {
    const errors: ValidationError<{ pounds: number; ounces: number }> = {}
    if (!Number(values.pounds) && !Number(values.ounces)) {
      errors.pounds = intl.formatMessage(messages.minValueError, { value: 0 })
    }

    if (values.ounces && Number(values.ounces) > 15.9) {
      errors.ounces = intl.formatMessage(messages.maxValueError, { value: 15.9 })
    }

    return errors
  }

  const onCreate = (newWeight: NewWeight) => {
    const variables = {
      patientId,
      visitId,
      measurement: newWeight,
    }

    addWeightMeasurement({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onCreate,
    validateMetric,
    validate: validateMetric,
    validateImperial,
    isLoading,
    error,
    onErrorClose,
  }
}

interface UpdateWeightVariables {
  patientId: string
  visitId: string
  measurementId: string
  measurement: NewWeight
}
interface UpdateWeightData {
  updateWeightMeasurement: FullPatientWithActiveVisit
}

type UpdateWeightParams = MutationHookOptions<UpdateWeightData, UpdateWeightVariables> & {
  patientId: string
  visitId: string
}

export const useUpdateWeight = (params: UpdateWeightParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'invalid_measurement_id':
        return setError(intl.formatMessage(messages.invalidMeasurementId))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFound))

      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_measurement':
        return setError(intl.formatMessage(messages.errorCreatingMeasurement))

      case 'error_removing_measurement':
        return setError(intl.formatMessage(messages.errorRemovingMeasurement))

      case 'invalid_signature':
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [updateWeightMeasurement, { loading: isLoading }] = useMutation(UPDATE_WEIGHT_MEASUREMENT, {
    onError,
    ...rest,
  })

  const validateMetric: ValidateFn<NewWeight> = async (values) => {
    const errors: ValidationError<NewWeight> = {}

    if (!Number(values.kg)) {
      errors.kg = intl.formatMessage(messages.minValueError, { value: 0 })
    }

    return errors
  }

  const validateImperial: ValidateFn<{ pounds: number; ounces: number }> = async (values) => {
    const errors: ValidationError<{ pounds: number; ounces: number }> = {}
    if (!Number(values.pounds) && !Number(values.ounces)) {
      errors.pounds = intl.formatMessage(messages.minValueError, { value: 0 })
    }

    if (values.ounces && Number(values.ounces) > 15.9) {
      errors.ounces = intl.formatMessage(messages.maxValueError, { value: 15.9 })
    }

    return errors
  }

  const onUpdate = (measurementId: string, bloodPressure: NewWeight) => {
    const variables = {
      patientId,
      visitId,
      measurementId,
      measurement: bloodPressure,
    }

    updateWeightMeasurement({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onUpdate,
    isLoading,
    validate: validateMetric,
    validateMetric,
    validateImperial,
    error,
    onErrorClose,
  }
}
