import { gql } from '@apollo/client'
import { ActiveVisit } from './visits'
import { UserSignature } from './common'
import {
  FullCustomFieldTextListWithValue,
  FullCustomFieldBooleanWithValue,
  FullCustomFieldNumberWithValue,
  FullCustomFieldDateWithValue,
  FullCustomFieldTextWithValue,
} from './customFields'
export const FullAddress = gql`
  fragment FullAddress on Address {
    line_1
    line_2
    line_3
    locality
    postal_code
    province
    country
  }
`

export const BasicPatient = gql`
  fragment BasicPatient on Patient {
    id
    first_name
    last_name
    birthdate
    sex
    original_location
    phone_number
    phone_number_e164
    email
    address {
      ...FullAddress
    }
    pricelist
    updated {
      ...UserSignature
    }
    created {
      ...UserSignature
    }
  }
  ${FullAddress}
  ${UserSignature}
`

export const FullPatient = gql`
  fragment FullPatient on Patient {
    ...BasicPatient
    customFields {
      ... on CustomFieldTextListWithValue {
        ...FullCustomFieldTextListWithValue
      }
      ... on CustomFieldBooleanWithValue {
        ...FullCustomFieldBooleanWithValue
      }
      ... on CustomFieldNumberWithValue {
        ...FullCustomFieldNumberWithValue
      }
      ... on CustomFieldDateWithValue {
        ...FullCustomFieldDateWithValue
      }
      ... on CustomFieldTextWithValue {
        ...FullCustomFieldTextWithValue
      }
    }
  }
  ${FullCustomFieldTextListWithValue}
  ${FullCustomFieldBooleanWithValue}
  ${FullCustomFieldNumberWithValue}
  ${FullCustomFieldDateWithValue}
  ${FullCustomFieldTextWithValue}
  ${BasicPatient}
  ${UserSignature}
`

export const FullPatientWithActiveVisit = gql`
  fragment FullPatientWithActiveVisit on Patient {
    ...FullPatient
    active_visit {
      ...ActiveVisit
    }
  }
  ${ActiveVisit}
  ${FullPatient}
`

/**
 * Bulk patient registration
 */
export const FullBulkRegistrationHistory = gql`
  fragment FullBulkRegistrationHistory on BulkRegistrationHistory {
    id
    count
    signature {
      ...UserSignature
    }
  }
  ${UserSignature}
`
