import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { classifySectionGroups } from './utils'
import {
  Box,
  BoxProps,
  Button,
  Heading,
  Input,
  InputGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { DynamicTranslation } from '../i18n'
import { ClearingCodesWithText, Nullable } from '../../utils'
import { ExamGroup, ExamEditorContextValue, useExamEditorContext } from './'
import { ErrorViewer } from '../ErrorViewer'

const messages = defineMessages({
  noteLabel: {
    id: 'UI.note_label',
    defaultMessage: 'Notes',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
  statusTitle: {
    id: 'ExamSectionModal.status_title',
    defaultMessage: 'Status',
  },
  proceduresTitle: {
    id: 'ExamSectionModal.procedures_title',
    defaultMessage: 'Procedures',
  },
})

type ExamSectionModalProps = {
  sectionIndex: number
  onClose: () => void
  isDisabled?: boolean
  clearingCodes: ClearingCodesWithText
}
export const ExamSectionModal: React.FC<ExamSectionModalProps> = (props) => {
  const { sectionIndex, onClose, clearingCodes } = props

  const intl = useIntl()

  const editorContextValues = useExamEditorContext() as ExamEditorContextValue
  const { exam, onSave, onRefresh, error, onErrorClose, isLoading } = editorContextValues
  const section = exam.sections[sectionIndex]

  const onCancel = () => {
    onRefresh()
    onClose()
  }

  const { statuses, procedures } = classifySectionGroups(section)
  const isFullWidth = useBreakpointValue({ base: true, sm: false })

  if (!section) {
    return null
  }

  return (
    <Modal isOpen onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <DynamicTranslation message={section.name} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {statuses.length > 0 && (
            <VStack alignItems={'self-start'}>
              <Heading size={'sm'}>{intl.formatMessage(messages.statusTitle)}</Heading>
              {statuses.map((group) => {
                const { key } = group
                const groupIndex = section.groups.findIndex((group) => group.key === key)
                const isGroupCleared = (group.physical_exam_codes ?? []).some((code) =>
                  clearingCodes.has(code)
                )

                return (
                  <ExamGroup
                    key={key}
                    groupIndex={groupIndex}
                    sectionIndex={sectionIndex}
                    clearingCodes={clearingCodes}
                    isDisabled={isGroupCleared}
                  />
                )
              })}
            </VStack>
          )}
          {procedures.length > 0 && (
            <VStack alignItems={'self-start'}>
              <Heading size={'sm'}>{intl.formatMessage(messages.proceduresTitle)}</Heading>
              {procedures.map((group) => {
                const { key } = group
                const groupIndex = section.groups.findIndex((group) => group.key === key)
                const isGroupCleared = (group.physical_exam_codes ?? []).some((code) =>
                  clearingCodes.has(code)
                )

                return (
                  <ExamGroup
                    key={key}
                    groupIndex={groupIndex}
                    sectionIndex={sectionIndex}
                    clearingCodes={clearingCodes}
                    isDisabled={isGroupCleared}
                  />
                )
              })}
            </VStack>
          )}
          <SectionNote sectionIndex={sectionIndex} note={section.note} mt={6} />

          {error && <ErrorViewer title={error} onClose={onErrorClose} />}
        </ModalBody>
        <ModalFooter>
          <Button
            mr={3}
            onClick={onSave}
            isLoading={isLoading}
            isFullWidth={isFullWidth}
            colorScheme="brand"
          >
            {intl.formatMessage(messages.saveButton)}
          </Button>
          <Button mr={3} onClick={onCancel} isFullWidth={isFullWidth}>
            {intl.formatMessage(messages.cancelButton)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type SectionNoteProps = BoxProps & {
  sectionIndex: number
  note: Nullable<string>
}

const SectionNote: React.FC<SectionNoteProps> = (props) => {
  const { sectionIndex, note, ...boxProps } = props

  const intl = useIntl()

  const { onSectionNoteChange } = useExamEditorContext() as ExamEditorContextValue

  const onChange = (e) => {
    const { value } = e.target
    const params = {
      sectionIndex,
      note: value,
    }

    onSectionNoteChange(params)
  }

  return (
    <Box {...boxProps}>
      <InputGroup size="md">
        <Input
          defaultValue={note ?? ''}
          onChange={onChange}
          placeholder={intl.formatMessage(messages.noteLabel)}
          aria-label={intl.formatMessage(messages.noteLabel)}
        />
      </InputGroup>
    </Box>
  )
}
