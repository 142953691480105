import { gql } from '@apollo/client'
import { UserSignature } from './common'

export const BasicBill = gql`
  fragment BasicBill on Bill {
    id
    status
    created {
      ...UserSignature
    }
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`

export const FullBillingItem = gql`
  fragment FullBillingItem on BillingItem {
    id
    name
    amount
    quantity
    unitPrice
    note
    billingCode
    pricelistId
    category
    userAdded
  }
`

export const FullBill = gql`
  fragment FullBill on Bill {
    ...BasicBill
    xendit {
      id
      externalId
      url
      status
      paidAt
      updated {
        ...UserSignature
      }
    }
    visitId
    total
    currencyString
    location
    items {
      ...FullBillingItem
    }
  }
  ${BasicBill}
  ${UserSignature}
  ${FullBillingItem}
`
