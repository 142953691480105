/**
 * UI Selectors
 */
export type ReactSelectInternalSingleValue = {
  value: string
  label: any
  id?: string
}

export type ReactSelectInternalMultiValue = Array<ReactSelectInternalSingleValue>
export type ReactSelectInternalValue = ReactSelectInternalSingleValue | ReactSelectInternalMultiValue

interface BaseSelectorProps {
  isClearable?: boolean
  isDisabled?: boolean
  isInvalid?: boolean
  isSearchable?: boolean
  isLoading?: boolean
  placeholder?: string
  blurInputOnSelect?: boolean
}

export interface SingleSelectorProps<T> extends BaseSelectorProps {
  value: T
  defaultValue?: T
  onChange: (newValue: T) => void
  isMulti?: false
}

export interface MultiSelectorProps<T> extends BaseSelectorProps {
  value: Array<T>
  defaultValue?: Array<T>
  onChange: (newValue: Array<T>) => void
  isMulti: true
}

export type SelectorProps<T> = SingleSelectorProps<T> | MultiSelectorProps<T>

export const isMultiSelector = (props: SelectorProps<string>): props is MultiSelectorProps<string> => {
  return !!props.isMulti
}
