import { BasePatient } from '.'
import { Nullable } from '../../utils'
import { Signature } from './common'

export enum BillableCategory {
  ADMINISTRATION = 'administration',
  DOCTOR_FEE = 'doctorFee',
  LAB = 'lab',
  MEDICINES = 'medicines',
  OTHERS = 'others',
  PROCEDURES = 'procedures',
}

export interface FullBillingItem {
  id: string
  name: string
  category: BillableCategory
  quantity: number
  amount: Nullable<number>
  note: Nullable<string>
  userAdded: Nullable<boolean>
  unitPrice: Nullable<number>
  billingCode: string
  pricelistId: Nullable<string>
}

export interface FullBill {
  id: string
  visitId: string
  location: string
  items: Array<FullBillingItem>
  currencyString: Nullable<string>
  total: Nullable<number>
  created: Nullable<Signature>
  updated: Nullable<Signature>
  status: Nullable<string>
}

export interface BasicVisitWithBill {
  visitId: string
  visitType: string
  location: string
  start: string
  patient: BasePatient
  end: string
  bill: FullBill
  exam_started_by: Nullable<Signature>
}
