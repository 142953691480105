import React from 'react'
import { useCreateRoomEvent, useUpdateRoomEvent } from './useRoomEventActions'
import { RoomEventForm } from './RoomEventForm'
import { EventRecurrence, RoomEvent } from '../../api/interfaces'
import { useAvailableSlots } from './useAvailableSlots'
import { Nullable } from '../../utils'

interface CreateEventModalProps {
  roomId: string
  start: Date
  end: Date
  onClose: () => void
}

export const CreateRoomEventTab: React.FC<CreateEventModalProps> = (props) => {
  const { roomId, onClose, start, end } = props

  const { isLoading, validate, onErrorClose, onSubmit, error } = useCreateRoomEvent({
    onCompleted: onClose,
    roomId,
  })

  const { availableSlots, slotDurationInMinutes } = useAvailableSlots({
    date: start.toISOString(),
  })

  const initialValues = {
    recurrence: EventRecurrence.UNIQUE,
    name: '',
    start: start.toISOString(),
    end: end.toISOString(),
  }

  return (
    <RoomEventForm
      availableSlots={availableSlots}
      slotDurationInMinutes={slotDurationInMinutes}
      isLoading={isLoading}
      validate={validate}
      onErrorClose={onErrorClose}
      onSubmit={onSubmit}
      error={error}
      initialValues={initialValues}
      onCancel={onClose}
    />
  )
}

interface UpdateEventModalProps {
  roomId: string
  start: Nullable<Date>
  end: Nullable<Date>
  onClose: () => void
  event: RoomEvent
}

export const UpdateRoomEventTab: React.FC<UpdateEventModalProps> = (props) => {
  const { roomId, event, onClose, start, end } = props

  const { isLoading, validate, onErrorClose, onSubmit, error } = useUpdateRoomEvent({
    onCompleted: onClose,
    roomId,
    eventId: event.id,
  })

  const { availableSlots, slotDurationInMinutes } = useAvailableSlots({ date: event.start })

  /**
   * take the new dates from the calendar
   */
  const modifiedInitialValues = {
    name: event.name,
    description: event.description ?? '',
    recurrence: event.recurrence,
    start: start ? start.toISOString() : event.start,
    end: end ? end.toISOString() : event.end,
  }

  return (
    <RoomEventForm
      availableSlots={availableSlots}
      slotDurationInMinutes={slotDurationInMinutes}
      isLoading={isLoading}
      validate={validate}
      onErrorClose={onErrorClose}
      onSubmit={onSubmit}
      error={error}
      initialValues={modifiedInitialValues}
      onCancel={onClose}
      isDeletable={true}
    />
  )
}
