import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ValidateFn, ValidationError } from '../Form'
import { UPDATE_CUSTOM_FIELDS } from '../../api/requests/patients'
import {
  FullPatient,
  FullPatientWithActiveVisit,
  PrimitiveCustomFieldType,
} from '../../api/interfaces'
import { isValidDate } from '../DateAndTime'
import { useCustomFieldLookUp, useCustomGroup } from './useCustomGroup'
import { isPrimitiveCustomField } from '../CustomFields/utils'
import {
  customFieldsToFormValues,
  CustomFieldValue,
  formValuesToCustomFields,
  UpdateCustomFieldsParams,
} from './utils'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  invalidId: {
    id: 'CustomCard.invalid_custom_field_ir',
    defaultMessage: 'Invalid custom field ID',
  },
  invalidValue: {
    id: 'CustomCard.invalid_custom_field_value',
    defaultMessage: 'Invalid custom field value',
  },
  errorUpdatingCustomFields: {
    id: 'CustomCard.error_updating_custom_fields',
    defaultMessage: 'Could not update these fields. Please, retry',
  },
  requiredError: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
})

type UpdateCustomGroupFormValues = Record<string, CustomFieldValue>
interface UpdateCustomGroupVariables extends UpdateCustomFieldsParams {
  patientId: string
}

interface UpdateCustomGroupData {
  updateCustomFields: FullPatientWithActiveVisit
}

export type UseUpdateCustomGroupParams = MutationHookOptions<
  UpdateCustomGroupData,
  UpdateCustomGroupVariables
> & {
  patient: FullPatient
  id: string
}

/**
 * We need the custom fields saved inside the patient to generate
 * the initial values in case they exist
 */
export const useUpdateCustomGroup = (params: UseUpdateCustomGroupParams) => {
  const { patient, id, ...rest } = params
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const group = useCustomGroup({ id })
  const fields = useCustomFieldLookUp(group?.customFields ?? [])
  const initialValues = customFieldsToFormValues(fields, patient?.customFields ?? [])

  const validate: ValidateFn<UpdateCustomGroupFormValues> = async (values) => {
    const errors: ValidationError<UpdateCustomGroupFormValues> = {}

    Object.entries(values).forEach(([key, value]) => {
      const customField = fields.find((field) => field.id === key)

      if (!value) {
        const isRequired = customField && customField.required

        if (isRequired) {
          errors[key] = intl.formatMessage(messages.requiredError)
        }
      } else {
        const isDateField =
          isPrimitiveCustomField(customField) && customField.type === PrimitiveCustomFieldType.DATE
        if (isDateField && !isValidDate(value as string)) {
          errors[key] = intl.formatMessage(messages.invalidValue)
        }
      }
    })

    return errors
  }

  const translateError = (errorCode) => {
    switch (errorCode) {
      case 'invalid_custom_field_id':
        setError(intl.formatMessage(messages.invalidId))
        break

      case 'invalid_custom_field_value':
        setError(intl.formatMessage(messages.invalidValue))
        break

      case 'error_updating_custom_fields':
        setError(intl.formatMessage(messages.errorUpdatingCustomFields))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    translateError(errorCode)

    if (params?.onError) {
      params.onError(error)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }

  const onCompleted = (data: UpdateCustomGroupData) => {
    onErrorClose()
    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [updateCustomFields, { loading: isLoading }] = useMutation<
    UpdateCustomGroupData,
    UpdateCustomGroupVariables
  >(UPDATE_CUSTOM_FIELDS, { onError, onCompleted, ...rest })

  const onSubmit = (values: UpdateCustomGroupFormValues) => {
    const variables: UpdateCustomGroupVariables = {
      ...formValuesToCustomFields(fields, values),
      patientId: patient.id,
    }

    updateCustomFields({ variables })
  }

  return {
    name: group?.name ?? '',
    fields,
    initialValues,
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}
