import React from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import { useChakraThemeParser } from '.'
import { FullExam } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { ExamContextValue, useExamContext } from '../Exam'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SelectorProps,
  isMultiSelector,
} from './utils'

type ExamSelectorProps = SelectorProps<string> & {
  filter?: (exam: FullExam) => boolean
}

export const ExamSelector: React.FC<ExamSelectorProps> = (props) => {
  const { value } = props

  const intl = useIntl()
  const theme = useChakraThemeParser()

  const { exams, isLoading, getExam } = useExamContext() as ExamContextValue

  const formatOption = React.useCallback(
    (exam: FullExam): ReactSelectInternalSingleValue => {
      const { id, name } = exam

      const translatedName = name?.text
        ? intl.formatMessage({ id: `Dynamic.${name.text}`, defaultMessage: name.text })
        : ''

      return {
        label: translatedName,
        value: id,
      }
    },
    [intl]
  )

  const options = props.filter
    ? exams.filter(props.filter).map((value) => formatOption(value as FullExam))
    : exams.map((value) => formatOption(value as FullExam))

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (isMultiSelector(props)) {
      if (!Array.isArray(value)) {
        return null
      }

      const formattedValues = value.reduce(
        (prev: Array<ReactSelectInternalSingleValue>, examId: string) => {
          const exam = getExam(examId)

          if (exam) {
            return [...prev, formatOption(exam)]
          }

          return prev
        },
        []
      )

      return formattedValues
    } else {
      const exam = getExam(value as string)

      if (exam) {
        return formatOption(exam)
      }

      return null
    }
  }

  const _onChange = (internalValue): void => {
    if (isMultiSelector(props)) {
      if (!Array.isArray(internalValue)) {
        return props.onChange([])
      }

      const selectedValues = internalValue
      const newValues = selectedValues.map((item) => item.value)

      props.onChange(newValues)
    } else {
      if (!internalValue) {
        return props.onChange('')
      }

      const selectedValue = internalValue

      props.onChange(selectedValue.value)
    }
  }

  return (
    <Select
      options={options}
      value={parseValue(value)}
      onChange={_onChange}
      isLoading={isLoading}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      aria-invalid={props.isInvalid}
      placeholder={props.placeholder}
      blurInputOnSelect={props.blurInputOnSelect}
      theme={theme}
    />
  )
}
