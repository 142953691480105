import React, { useEffect } from 'react'
import Select from 'react-select'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SingleSelectorProps,
} from './utils'
import { useSystems } from '../Systems'
import { useChakraThemeParser } from '.'
import { Nullable } from '../../utils'
import { Room } from '../../api/interfaces'

export type RoomSelectorProps = SingleSelectorProps<string> & {
  autoSelect?: boolean
}

const formatOption = (room: Room): ReactSelectInternalSingleValue => {
  const { id, name } = room

  return {
    value: id,
    label: name,
  }
}

export const RoomSelector: React.FC<RoomSelectorProps> = (props) => {
  const { value, onChange, autoSelect } = props

  const theme = useChakraThemeParser()
  const { isLoading, getMyLocation } = useSystems()

  const getRoom = (id: string): Nullable<Room> => {
    const { rooms } = getMyLocation()

    const room = rooms.find((room) => room.id === id)

    return room ?? null
  }

  const options = React.useMemo(() => {
    const { rooms } = getMyLocation()

    return rooms.map(formatOption)
  }, [getMyLocation])

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    const room = getRoom(value as string)

    if (room) {
      return formatOption(room)
    }

    return null
  }

  const _onChange = React.useCallback(
    (internalValue): void => {
      if (!internalValue) {
        return onChange('')
      }

      const selectedValue = internalValue

      onChange(selectedValue.value)
    },
    [onChange]
  )

  useEffect(() => {
    if (autoSelect && options.length > 0) {
      _onChange(options[0])
    }
  }, [options, autoSelect, _onChange])

  return (
    <Select
      options={options}
      value={parseValue(value)}
      onChange={_onChange}
      isLoading={isLoading}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      aria-invalid={props.isInvalid}
      placeholder={props.placeholder}
      blurInputOnSelect={props.blurInputOnSelect}
      theme={theme}
    />
  )
}
