import React from 'react'
import * as Sentry from '@sentry/browser'
import { Nullable } from '../../src/utils'


interface ErrorBoundaryState {
  error: Nullable<Error>
  info: Nullable<React.ErrorInfo>
  eventId: Nullable<string>
}

export class ErrorBoundary extends React.Component<{ children: Element | React.ReactNode }, ErrorBoundaryState> {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      info: null,
      eventId: null
    }
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras({ info })

      const eventId = Sentry.captureException(error)
      this.setState({ eventId, error, info })
    })
  }

  render() {
    if (this.state.error) {

      // render fallback UI
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.info ? this.state.info.componentStack : '-'}
          </details>
        </div>
      )
    }

    // when there's not an error, render children untouched
    return this.props.children
  }
}

