import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { getNormalVitalsByAge, isInRange } from 'wd-common/src/logic/triage/utils'
import { BloodPressure as BloodPressureType } from '../../api/interfaces'
import {
  Box,
  Button,
  ButtonProps,
  Collapse,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagProps,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { TextField } from '../Form'
import { PatientAgeCategory } from '../Patients'
import { NewBloodPressure, useCreateBloodPressure } from './'
import { ErrorViewer } from '..'
import { RiAddLine as AddIcon, RiAlertLine as WarningIcon } from 'react-icons/ri'
import { DeleteMeasurementButton } from './DeleteMeasurementButton'

const messages = defineMessages({
  title: {
    id: 'Vitals.blood_pressure_title',
    defaultMessage: 'Blood Pressure',
  },
  shortTitle: {
    id: 'Vitals.blood_pressure_abbreviation',
    defaultMessage: 'BP',
  },
  diastolic: {
    id: 'Vitals.diastolic_label',
    defaultMessage: 'Diastolic',
  },
  systolic: {
    id: 'Vitals.systolic_label',
    defaultMessage: 'Systolic',
  },
  reference: {
    id: 'Vitals.reference_title',
    defaultMessage: 'Reference',
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  submitButton: {
    id: 'NonStaffUI.button_submit',
    defaultMessage: 'Submit',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
})

type MeasurementListProps = {
  values: Array<BloodPressureType>
  patientId: string
  visitId: string
  birthdate: string
}

type BloodPressureProps = ButtonProps & MeasurementListProps

export const BloodPressure: React.FC<BloodPressureProps> = (props) => {
  const { values, patientId, visitId, birthdate, ...buttonGroupProps } = props

  const { onToggle, isOpen } = useDisclosure()

  const noValues = values.length === 0
  const intl = useIntl()

  return (
    <>
      <Button mr="-px" {...buttonGroupProps} onClick={onToggle}>
        {noValues ? (
          <>
            <Icon as={AddIcon} />
            <Text ml={2}>{intl.formatMessage(messages.shortTitle)}</Text>
          </>
        ) : (
          <ValueWithWarning value={values[0]} birthdate={birthdate} />
        )}
      </Button>

      <BloodPressureModal
        values={values}
        isOpen={isOpen}
        onToggle={onToggle}
        birthdate={birthdate}
        patientId={patientId}
        visitId={visitId}
      />
    </>
  )
}

type BloodPressureModalProps = MeasurementListProps & {
  isOpen: boolean
  onToggle: () => void
  isDisabled?: boolean
}

const BloodPressureModal: React.FC<BloodPressureModalProps> = (props) => {
  const { patientId, visitId, birthdate, values, isDisabled, isOpen, onToggle } = props

  const intl = useIntl()

  const params = {
    patientId,
    visitId,
  }

  const { onCreate, onErrorClose, error, isLoading, validate } = useCreateBloodPressure(params)

  const onSubmit = (values) => {
    const newBloodPressure: NewBloodPressure = {
      systolic: Number(values?.systolic),
      diastolic: Number(values?.diastolic),
    }

    onCreate(newBloodPressure)
  }

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <Form
        onSubmit={onSubmit}
        validate={validate}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values: formValues }) => (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TextField
                  name="systolic"
                  label={intl.formatMessage(messages.systolic)}
                  type={'number'}
                  autoFocus
                />

                <TextField
                  name="diastolic"
                  label={intl.formatMessage(messages.diastolic)}
                  type={'number'}
                  max={formValues.systolic}
                />

                {error && <ErrorViewer title={error} onClose={onErrorClose} />}

                <MeasurementList
                  patientId={patientId}
                  visitId={visitId}
                  birthdate={birthdate}
                  values={values}
                />

                <VitalReference birthdate={birthdate} />
              </ModalBody>

              <ModalFooter>
                <Button isFullWidth={isFullWidth} onClick={onToggle}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  colorScheme={'brand'}
                  isLoading={isLoading}
                  type="submit"
                  isDisabled={isDisabled}
                  isFullWidth={isFullWidth}
                  ml={2}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        )}
      />
    </Modal>
  )
}

const MeasurementList: React.FC<MeasurementListProps> = (props) => {
  const { values, patientId, visitId, birthdate } = props

  return (
    <Collapse>
      {values.map((value, index) => {
        const key = `bp-${value.id}${index}`
        return (
          <Flex alignItems={'center'} key={key}>
            <HStack>
              <ValueWithWarning value={value} birthdate={birthdate} />
              <DeleteMeasurementButton
                patientId={patientId}
                visitId={visitId}
                measurementId={value.id}
              />
            </HStack>
          </Flex>
        )
      })}
    </Collapse>
  )
}
interface CheckWarningsParams {
  birthdate: string
  systolic: number
  diastolic: number
}

const checkWarnings = (params: CheckWarningsParams) => {
  const { systolic, diastolic, birthdate } = params

  const reference = getNormalVitalsByAge({ birthdate })

  const systolicReference = reference?.bp?.systolic
  const diastolicReference = reference?.bp?.diastolic

  const isSystolicInRange =
    !systolicReference ||
    !systolic ||
    isInRange(systolicReference?.min, systolicReference?.max, systolic)
  const isDiastolicInRange =
    !diastolicReference ||
    !diastolic ||
    isInRange(diastolicReference?.min, diastolicReference?.max, diastolic)

  return {
    isSystolicInRange,
    isDiastolicInRange,
    reference,
  }
}

type ValueWithWarningProps = {
  value: BloodPressureType
  birthdate: string
}

const ValueWithWarning: React.FC<ValueWithWarningProps> = (props) => {
  const { value, birthdate } = props

  const intl = useIntl()

  const systolic = value?.systolic
  const diastolic = value?.diastolic

  const { isSystolicInRange, isDiastolicInRange } = checkWarnings({
    birthdate,
    systolic,
    diastolic,
  })

  const inRange = isSystolicInRange && isDiastolicInRange

  return (
    <HStack alignItems={'center'}>
      {!inRange && <Icon as={WarningIcon} color="red.400" />}
      <Text ml={1}>{intl.formatMessage(messages.shortTitle)}:</Text>
      <Text fontWeight={'bold'} color={!isSystolicInRange ? 'red.400' : undefined}>
        {systolic ?? '--'}
      </Text>
      <Text>{'/'}</Text>
      <Text fontWeight={'bold'} color={!diastolic ? 'red.400' : undefined}>
        {diastolic ?? '--'}
      </Text>
    </HStack>
  )
}

type VitalReferenceProps = {
  birthdate: string
}

const VitalReference: React.FC<VitalReferenceProps> = (props) => {
  const { birthdate } = props

  const intl = useIntl()

  const reference = getNormalVitalsByAge({ birthdate })
  if (!reference) {
    return null
  }

  const diastolicReference = reference?.bp?.diastolic
  const systolicReference = reference?.bp?.systolic

  return (
    <Box p={4} borderWidth={1} rounded={'lg'}>
      <PatientAgeCategory birthdate={birthdate} mb={1} fontWeight={'bold'} />
      <HStack>
        <Text>
          {intl.formatMessage(messages.reference)}
          {` (`}
          {intl.formatMessage(messages.systolic)}
          {`): `}
        </Text>
        <Text fontWeight={'bold'} ml={2}>
          {`${systolicReference.min} - ${systolicReference.max}`}
        </Text>
      </HStack>
      <HStack>
        <Text>
          {intl.formatMessage(messages.reference)}
          {` (`}
          {intl.formatMessage(messages.diastolic)}
          {`): `}
        </Text>
        <Text fontWeight={'bold'} ml={2}>
          {`${diastolicReference.min} - ${diastolicReference.max}`}
        </Text>
      </HStack>
    </Box>
  )
}

type PrintableBloodPressureProps = TagProps & {
  birthdate: string
  value: BloodPressureType
}

export const PrintableBloodPressure: React.FC<PrintableBloodPressureProps> = (props) => {
  const { value, birthdate, ...tagProps } = props

  return (
    <Tag {...tagProps}>
      <ValueWithWarning value={value} birthdate={birthdate} />
    </Tag>
  )
}

type PdfBloodPressureProps = {
  birthdate: string
  value: BloodPressureType
}

export const PdfBloodPressure: React.FC<PdfBloodPressureProps> = (props) => {
  const { value, birthdate } = props
  return <ValueWithWarning value={value} birthdate={birthdate} />
}
