import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { PaginatedResult } from '../../api/interfaces'
import { FullProcedure } from '../../api/interfaces/procedures'
import { GET_PROCEDURE_SUBSCRIPTIONS } from '../../api/requests/procedures'
import { Nullable } from '../../utils'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface ProcedureSubscriptionsQueryData {
  procedureSubscriptions: PaginatedResult<FullProcedure>
}

export type UseProcedureSubscriptionsParams = QueryHookOptions<ProcedureSubscriptionsQueryData, void>

const useProcedureSubscriptions = (params?: UseProcedureSubscriptionsParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)
  const onError = () => {
    setError(intl.formatMessage(messages.internalServerError))
  }

  const onCompleted = () => {
    setError(null)
  }

  const { loading: isLoading, data } = useQuery<ProcedureSubscriptionsQueryData, void>(GET_PROCEDURE_SUBSCRIPTIONS, {
    onError,
    onCompleted,
    ...params,
  })

  const procedures = React.useMemo(() => {
    if (!data || data.procedureSubscriptions === null) {
      return []
    }

    const translated = data.procedureSubscriptions.docs.map((procedure) => {
      const { name, description } = procedure

      const translatedName = name?.text
        ? intl.formatMessage({ id: `Dynamic.${name.text}`, defaultMessage: name.text })
        : ''

      const translatedDescription = description?.text
        ? intl.formatMessage({ id: `Dynamic.${description.text}`, defaultMessage: description.text })
        : ''

      return {
        ...procedure,
        translatedName,
        translatedDescription,
      }
    })

    const sorted = translated.sort((a, b) => {
      if (a.translatedName > b.translatedName) {
        return 1
      }
      if (b.translatedDescription > a.translatedDescription) {
        return -1
      }

      return 0
    })

    return sorted
  }, [data, intl])

  const onErrorClose = () => {
    setError(null)
  }
  return {
    isLoading,
    count: procedures.length,
    procedures: procedures,
    error,
    onErrorClose,
  }
}
export default useProcedureSubscriptions
