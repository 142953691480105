import React from 'react'
import { validateNotNil } from '../../../src/utils'
import { SessionContext } from './context'
import { Session } from './session'

export const useSession = (): Session => {
  const session = React.useContext(SessionContext)
  validateNotNil<Session>(session)

  return session
}
