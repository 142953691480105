import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Authors, useChecklistItemActions } from './'

import { evaluateQualityEquation } from 'wd-common/src/logic/checklists/utils'
import { RiDeleteBin2Line as DeleteIcon } from 'react-icons/ri'
import {
  FullChecklist,
  // FullProcedureOrder,
  // FullStudyOrder,
  QualityEquationResult as QualityEquationResultInterface,
} from '../../api/interfaces'
import { ConfirmButton, ErrorViewer } from '..'
import { DynamicTranslation } from '../i18n'
import {
  Box,
  BoxProps,
  Collapse,
  HStack,
  Icon,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import {
  Background,
  DifferentialDiagnosis,
  Education,
  History,
  PhysicalExam,
  QualityChecks,
  Studies,
  Treatment,
} from './Sections'
import { HEADER_HEIGHT } from '../Layout/Header'

const messages = defineMessages({
  title: {
    id: 'Checklist.title_checklist',
    defaultMessage: 'Checklist',
  },
  note: {
    id: 'label_note',
    defaultMessage: 'Note',
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  confirmationMessage: {
    id: 'Checklist.confirm_deletion',
    defaultMessage: 'This checklist will be removed. Are you sure?',
  },
})

type ChecklistProps = BoxProps & {
  checklist: FullChecklist
  visitId: string
  patientId: string
  // studyOrders: Array<FullStudyOrder>
  // procedureOrders: Array<FullProcedureOrder>
  onRemove: (id: string) => void
  defaultIsOpen?: boolean
  isDisabled?: boolean
  isDeleting?: boolean
}

export const Checklist: React.FC<ChecklistProps> = (props) => {
  const {
    defaultIsOpen,
    isDisabled,
    checklist,
    patientId,
    visitId,
    onRemove,
    // procedureOrders,
    // studyOrders,
    isDeleting,
    ...boxProps
  } = props

  const intl = useIntl()

  const { headerBg } = useColorModeValue({ headerBg: 'white' }, { headerBg: 'gray.800' })
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  const params = {
    visitId,
    patientId,
    checklistId: checklist.id,
  }

  const {
    onHistoryChange,
    onPhisicalExamChange,
    onStudyChange,
    onTreatmentChange,
    onEducationChange,
    error,
  } = useChecklistItemActions(params)

  /**
   * This allow us to inject specific quality checks
   * to specific sections.
   * They will be shown right to the section name
   */
  const getQualityCheckByName = (name) => {
    if (!name) return null

    const found = (checklist.quality_checks || []).find(
      (item) => item?.name && item.name.toLowerCase() === name.toLowerCase()
    )

    if (found && found.equation) {
      const value = evaluateQualityEquation(found.equation, checklist)

      return {
        value,
        name: found.name,
      } as QualityEquationResultInterface
    }

    return null
  }

  return (
    <Box px={2} borderWidth={1} rounded={'lg'} {...boxProps}>
      <HStack
        onClick={onToggle}
        justifyContent={'space-between'}
        p={2}
        top={isOpen ? `${HEADER_HEIGHT}px` : 0}
        position={isOpen ? 'sticky' : 'relative'}
        bg={isOpen ? headerBg : 'inherit'}
        zIndex={1}
        cursor={'pointer'}
        borderBottomWidth={isOpen ? 1 : 0}
      >
        <DynamicTranslation message={checklist.name} fontSize={'lg'} fontWeight={'bold'} />

        {
          <ConfirmButton
            onConfirm={() => onRemove(checklist.id)}
            message={intl.formatMessage(messages.confirmationMessage)}
            buttonProps={{
              colorScheme: 'red',
              variant: 'ghost',
              label: <Icon as={DeleteIcon} />,
              isDisabled,
            }}
            confirmButtonProps={{
              isLoading: isDeleting,
            }}
            stopPropagation
          />
        }
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Box>
          {error && <ErrorViewer title={error} />}
          {checklist.authors && (
            <Authors
              pt={1}
              px={2}
              authors={checklist.authors}
              display={'flex'}
              justifyContent={'end'}
            />
          )}
          {checklist.background && <Background p={2} background={checklist.background} />}

          {checklist.differential_diagnosis && checklist.differential_diagnosis.length > 0 && (
            <DifferentialDiagnosis p={2} differentialDiagnosis={checklist.differential_diagnosis} />
          )}
          {checklist.history && checklist.history.length > 0 && (
            <History
              items={checklist.history}
              onChange={onHistoryChange}
              qualityCheck={getQualityCheckByName('Diagnosis')}
              p={2}
            />
          )}
          {checklist.physical_exam && checklist.physical_exam.length > 0 && (
            <PhysicalExam
              // patientId={patientId}
              // visitId={visitId}
              items={checklist.physical_exam}
              onChange={onPhisicalExamChange}
              // studyOrders={studyOrders}
              p={2}
            />
          )}
          {checklist.studies && checklist.studies.length > 0 && (
            <Studies
              // patientId={patientId}
              // visitId={visitId}
              items={checklist.studies}
              onChange={onStudyChange}
              // studyOrders={studyOrders}
              // procedureOrders={procedureOrders}
              p={2}
            />
          )}
          {checklist.treatment && checklist.treatment.length > 0 && (
            <Treatment
              // patientId={patientId}
              // visitId={visitId}
              items={checklist.treatment}
              onChange={onTreatmentChange}
              // studyOrders={studyOrders}
              // procedureOrders={procedureOrders}
              qualityCheck={getQualityCheckByName('Treatment')}
              p={2}
            />
          )}
          {checklist.education && checklist.education.length > 0 && (
            <Education
              items={checklist.education}
              onChange={onEducationChange}
              qualityCheck={getQualityCheckByName('Education')}
              p={2}
            />
          )}
          <QualityChecks checklist={checklist} p={2} />
        </Box>
      </Collapse>
    </Box>
  )
}
