import React from 'react'
import { Button, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { sitemap } from '../Routing'

const messages = defineMessages({
  password: {
    id: 'PasswordSettings.password',
    defaultMessage: 'Password',
  },
  passwordDescription: {
    id: 'PasswordSettings.password description',
    defaultMessage: 'Change your password',
  },
  change: {
    id: 'UI.button_change',
    defaultMessage: 'Change',
  },
  changePasswordDescription: {
    id: 'PasswordSettings.change_password_description',
    defaultMessage:
      'We will send a code to your email address. You will need to enter this code to complete the password reset process.',
  },
  emailNotVerified: {
    id: 'PasswordSettings.email_not_verified_warning',
    defaultMessage: 'You must verify your email address before asking for a password reset.',
  },
})

export const PasswordSettings: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const onClick = () => {
    navigate(sitemap.auth.routes.changePassword.absolutePath)
  }
  return (
    <>
      <HStack justifyContent={'space-between'} alignItems={'center'} p={2}>
        <VStack alignItems={'start'} justifyContent={'start'} spacing={0}>
          <Heading size="sm">{intl.formatMessage(messages.password)}</Heading>
          <Text>{intl.formatMessage(messages.passwordDescription)}</Text>
        </VStack>

        <Button variant={'ghost'} colorScheme={'brand'} onClick={onClick}>
          {intl.formatMessage(messages.change)}
        </Button>
      </HStack>
    </>
  )
}
