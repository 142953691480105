import React from 'react'
import { Box, BoxProps, Button } from '@chakra-ui/react'
import { DynamicTranslation } from '../i18n'
import { ExamChoiceDetails } from '.'
import { useExamEditorContext } from './useExamEditorContext'
import { ExamEditorContextValue } from './ExamEditorContext'
import { ClearingCodesWithText } from '../../utils'
import { TranslatableText } from '../../api/interfaces'

type ExamGroupProps = BoxProps & {
  sectionIndex: number
  groupIndex: number
  isDisabled?: boolean
  clearingCodes: ClearingCodesWithText
}

export const ExamGroup: React.FC<ExamGroupProps> = (props) => {
  const { sectionIndex, groupIndex, isDisabled, clearingCodes, ...boxProps } = props

  const { exam, onChoiceSelect } = useExamEditorContext() as ExamEditorContextValue

  const { choices, physical_exam_codes } = exam.sections[sectionIndex].groups[groupIndex]
  const choiceIndex = choices.findIndex((detail) => detail.selected)

  const selectedChoice = choices[choiceIndex]

  const pertinentsFromChecklist = (physical_exam_codes ?? [])
    .filter((code) => clearingCodes.has(code))
    .map((code) => clearingCodes.get(code) as Array<TranslatableText>)

  return (
    <Box borderWidth={1} borderRadius={'md'} p={2} my={1} {...boxProps}>
      <Box display={'flex'} flexWrap={'wrap'}>
        {pertinentsFromChecklist.length === 0 &&
          choices.map((choice, index) => {
            const { name, selected, key } = choice

            const onClick = () => {
              const params = {
                sectionIndex,
                groupIndex,
                choiceIndex: index,
                isSelected: !!selected,
              }

              onChoiceSelect(params)
            }

            return (
              <Button
                size={'sm'}
                key={`group-${key}-${index}`}
                colorScheme={selected ? 'brand' : undefined}
                isDisabled={isDisabled}
                onClick={onClick}
                my={1}
                mr={2}
              >
                <DynamicTranslation message={name} />
              </Button>
            )
          })}
        {pertinentsFromChecklist?.map((list, index) =>
          list.map((p) => (
            <Button
              isDisabled
              size="sm"
              key={`${p.text}-${index}`}
              my={1}
              mr={2}
              colorScheme="red"
              sx={{ opacity: '1!important', backgroundColor: 'red' }}
            >
              <DynamicTranslation message={p} />
            </Button>
          ))
        )}
      </Box>
      {selectedChoice &&
        Array.isArray(selectedChoice?.details) &&
        selectedChoice.details.map((choiceDetail, detailIndex) => {
          const key = `selected-choice-details-${selectedChoice.key}-${detailIndex}`

          return (
            <ExamChoiceDetails
              key={key}
              sectionIndex={sectionIndex}
              groupIndex={groupIndex}
              choiceIndex={choiceIndex}
              detailIndex={detailIndex}
              px={1}
              pt={3}
            />
          )
        })}
    </Box>
  )
}
