import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit, HowLong } from '../../api/interfaces'
import { UPDATE_CHIEF_COMPLAINT } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  invalidChiefComplaint: {
    id: 'PatientStatus.invalid_chief_complaint',
    defaultMessage: 'Invalid chief complaint',
  },
  patientNotFound: {
    id: 'PatientStatus.patient_not_found',
    defaultMessage: 'Patient not found',
  },
  errorUpdatingStatus: {
    id: 'PatientStatus.error_updating_status',
    defaultMessage: 'Could not update the status. Please, try again',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface NewChiefComplaint {
  how_long: Nullable<HowLong>
  complaint: Nullable<string>
}

interface UpdateChiefComplaintVariables {
  patientId: string
  visitId: string
  chiefComplaint: NewChiefComplaint
}

interface UpdateClinicalStatusMutationData {
  updateChiefComplaint: FullPatientWithActiveVisit
}

type Params = MutationHookOptions<
  UpdateClinicalStatusMutationData,
  UpdateChiefComplaintVariables
> & {
  patientId: string
  visitId: string
}

export const useUpdateChiefComplaint = (params: Params) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'invalid_chief_complaint':
        return setError(intl.formatMessage(messages.invalidChiefComplaint))

      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_updating_clinical_status':
        return setError(intl.formatMessage(messages.errorUpdatingStatus))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [updateChiefComplaint, { loading: isLoading }] = useMutation(UPDATE_CHIEF_COMPLAINT, {
    onError,
    ...restParams,
  })

  const onChiefComplaintChange = (chiefComplaint: NewChiefComplaint) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      chiefComplaint: chiefComplaint,
    }

    updateChiefComplaint({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onChiefComplaintChange,
    isLoading,
    error,
    onErrorClose,
  }
}
