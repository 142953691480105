import React from 'react'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'
import Select from 'react-select'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SelectorProps,
  useChakraThemeParser,
} from '.'
import { FollowUp } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { isValidFollowUp } from '../ActiveVisit'

const messages = defineMessages({
  followUp: {
    id: 'FollowUpSelector.follow_up_label',
    defaultMessage: 'Follow up'
  },
})

export const followUpTranslation: Record<FollowUp, MessageDescriptor> = {
  [FollowUp.TOMORROW]: {
    id: 'FollowUpSelector.tomorrow',
    defaultMessage: 'Tomorrow'
  },
  [FollowUp.TWO_DAYS]: {
    id: 'FollowUpSelector.2_3_days',
    defaultMessage: '2-3 Days'
  },
  [FollowUp.FOUR_DAYS]: {
    id: 'FollowUpSelector.4_6_days',
    defaultMessage: '4-6 Days'
  },
  [FollowUp.WEEK]: {
    id: 'FollowUpSelector.one_week',
    defaultMessage: 'One week'
  },
  [FollowUp.TWO_WEEKS]: {
    id: 'FollowUpSelector.two_weeks',
    defaultMessage: 'Two weeks'
  },
  [FollowUp.THREE_WEEKS]: {
    id: 'FollowUpSelector.three_weeks',
    defaultMessage: 'Three weeks'
  },
  [FollowUp.MONTH]: {
    id: 'FollowUpSelector.one_month',
    defaultMessage: 'One month'
  },
  [FollowUp.TWO_MONTHS]: {
    id: 'FollowUpSelector.two_months',
    defaultMessage: 'Two months'
  },
  [FollowUp.THREE_MONTHS]: {
    id: 'FollowUpSelector.three_months',
    defaultMessage: 'Three months'
  },
  [FollowUp.SIX_MONTHS]: {
    id: 'FollowUpSelector.six_months',
    defaultMessage: 'Six months'
  },
  [FollowUp.YEAR]: {
    id: 'FollowUpSelector.one_year',
    defaultMessage: 'One year'
  }
}

const followUpMessages = defineMessages(followUpTranslation)

export type FollowUpSelectorProps = SelectorProps<FollowUp> & {
  isMulti: false
}

export const FollowUpSelector: React.FC<FollowUpSelectorProps> = (props) => {
  const {
    value,
  } = props

  const intl = useIntl()
  const theme = useChakraThemeParser()

  const formatOption = (statusAssesment: FollowUp): ReactSelectInternalSingleValue => {
    return {
      label: intl.formatMessage(followUpMessages[statusAssesment]),
      value: statusAssesment
    }
  }

  const options = Object.values(FollowUp).map(formatOption)

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (isValidFollowUp(value as string)) {
      return formatOption(value as FollowUp)
    }

    return null
  }

  const _onChange = value => {
    if (!value) {
      props.onChange('' as FollowUp)
      return
    }

    const selectedValue = value

    props.onChange(selectedValue.value as FollowUp)
  }

  return <Select
    options={options}
    value={parseValue(value)}
    onChange={_onChange}
    isLoading={props.isLoading}
    isMulti={props.isMulti}
    isClearable={props.isClearable}
    isDisabled={props.isDisabled}
    isSearchable={props.isSearchable}
    aria-invalid={props.isInvalid}
    placeholder={intl.formatMessage(messages.followUp)}
    blurInputOnSelect={props.blurInputOnSelect}
    theme={theme}
  />
}

