import {
  DrugGuideline,
  FullChecklist,
  FullChecklistEntry,
  QualityChecksEquation,
  QualityChecksEquationItems,
  TranslatableText,
} from '../../api/interfaces'
import { getAllChecklistItems } from '../Checklist/utils'

export interface EvaluatedQualityTree {
  name: string
  tree: EvaluatedNode
}

export interface EvaluatedNode {
  type: string
  codes: Array<string>
  children: Array<EvaluatedNode>
  missing: Array<string>
  value?: boolean
  description?: TranslatableText
}

export enum NODE_TYPE {
  AND = 'equation_and',
  OR = 'equation_or',
  XOR = 'equation_xor',
  ANY = 'equation_any',
  ALL = 'equation_all',
  NOT = 'equation_not',
}

export const isValidNodeType = (value: string): boolean => {
  return (Object.values(NODE_TYPE) as Array<string>).includes(value)
}

/**
 * expand the passed checks branch in case the root value is true
 * and expand the not-passed check branch when the root value is false
 * This is to give info about why the test passed or not
 */
export const getDefaultIndex = (node: EvaluatedNode, rootValue: boolean) => {
  const expanded = node.children.findIndex((child) => {
    if (node.type === NODE_TYPE.NOT) {
      return child.value !== rootValue
    }

    return child.value === rootValue
  })

  return [expanded]
}

const getAllCheckedCodes = (items: Array<FullChecklistEntry | DrugGuideline>): Set<string> => {
  const checked = new Set<string>()

  items.forEach((item) => {
    if (item.checked && Array.isArray(item.quality_codes)) {
      item.quality_codes.forEach((code: string) => checked.add(code))
    }
  })

  return checked
}

const getCodeDescriptions = (
  items: Array<FullChecklistEntry | DrugGuideline>
): Map<string, TranslatableText> => {
  const descriptions = new Map<string, TranslatableText>()

  items.forEach((item) => {
    if (Array.isArray(item.quality_codes)) {
      item.quality_codes.forEach((code: string) => {
        if (item.name !== null) {
          descriptions.set(code, item.name)
        }
      })
    }
  })

  return descriptions
}

const parseItemToNode = (item: QualityChecksEquationItems): EvaluatedNode => {
  return {
    type: item.type ?? '',
    codes: item.codes ?? [],
    children: item.items?.map(parseItemToNode) ?? [],
    missing: [],
  }
}

export const evaluateQualityEquation = (
  equation: QualityChecksEquation,
  checklist: FullChecklist
): EvaluatedQualityTree => {
  const items = getAllChecklistItems(checklist)
  const checkedCodes = getAllCheckedCodes(items)
  const codeDescriptions = getCodeDescriptions(items)

  const rootNode: EvaluatedNode = {
    codes: [],
    children: equation.items?.map(parseItemToNode) ?? [],
    type: equation.type ?? '',
    missing: [],
  }

  const tree = evaluateNode(rootNode, checkedCodes, codeDescriptions)

  return {
    tree,
    name: 'root',
  }
}

function evaluateNode(
  node: EvaluatedNode,
  checkedCodes: Set<string>,
  codeDescription: Map<string, TranslatableText>
): EvaluatedNode {
  const evaluatedNode = {
    ...node,
    children: node.children.map((item) => evaluateNode(item, checkedCodes, codeDescription)),
  }

  switch (node.type) {
    case NODE_TYPE.AND:
      return evaluateAnd(evaluatedNode)

    case NODE_TYPE.OR:
      return evaluateOR(evaluatedNode)

    case NODE_TYPE.XOR:
      return evaluateXOR(evaluatedNode)

    case NODE_TYPE.ANY:
      return evaluateAny(evaluatedNode, checkedCodes, codeDescription)

    case NODE_TYPE.ALL:
      return evaluateAll(evaluatedNode, checkedCodes, codeDescription)

    case NODE_TYPE.NOT:
      return evaluateNot(evaluatedNode)

    default: {
      const value = evaluatedNode.children.some((v) => v.value)
      const missing = evaluatedNode.children.flatMap((v) => v.missing ?? [])

      return {
        ...node,
        type: 'ROOT',
        missing,
        value,
      }
    }
  }
}

const evaluateAnd = (node: EvaluatedNode): EvaluatedNode => {
  const value = node.children.every((v) => v.value)

  if (!value) {
    const missing = node.children.flatMap((v) => v.missing ?? [])

    return {
      ...node,
      missing,
      value,
    }
  }

  return {
    ...node,
    missing: [],
    value,
  }
}

const evaluateOR = (node: EvaluatedNode): EvaluatedNode => {
  const value = node.children.some((v) => v.value)

  if (!value) {
    const missing = node.children.flatMap((v) => v.missing ?? [])

    return {
      ...node,
      missing,
      value,
    }
  }

  return {
    ...node,
    missing: [],
    value,
  }
}

const evaluateXOR = (node: EvaluatedNode): EvaluatedNode => {
  const value =
    node.children.map((v) => v.value).reduce((acc, current) => (current ? acc + 1 : acc), 0) === 1

  return {
    ...node,
    missing: [],
    value,
  }
}

const evaluateNot = (node: EvaluatedNode): EvaluatedNode => {
  const value = !node.children[0].value

  if (!value) {
    const missing = node.children.flatMap((v) => v.missing ?? [])

    return {
      ...node,
      missing,
      value,
    }
  }

  return {
    ...node,
    missing: [],
    value,
  }
}

/**
 * The next 2 evaluate functions create the leafs
 */
const evaluateAny = (
  node: EvaluatedNode,
  checkedCodes: Set<string>,
  codeDescription: Map<string, TranslatableText>
): EvaluatedNode => {
  const value = node.codes.some((code) => checkedCodes.has(code))

  const children = node.codes
    .map((name) =>
      checkedCodes.has(name)
        ? {
            type: 'equation_any',
            children: [],
            missing: [],
            codes: [name],
            value: true,
            description: codeDescription.get(name),
          }
        : {
            type: 'equation_any',
            children: [],
            missing: [],
            codes: [name],
            value: false,
            description: codeDescription.get(name),
          }
    )
    .sort((a) => (a.value ? -1 : 1))

  if (!value) {
    return {
      ...node,
      children,
      missing: node.codes.filter((code) => !checkedCodes.has(code)),
      value,
    }
  }

  return {
    ...node,
    children,
    missing: [],
    value,
  }
}

const evaluateAll = (
  node: EvaluatedNode,
  checkedCodes: Set<string>,
  codeDescription: Map<string, TranslatableText>
): EvaluatedNode => {
  const value = node.codes.every((code) => checkedCodes.has(code))

  // convert it in a leaf and use any to allow this dummy node to be removed
  const children = node.codes
    .map((name) =>
      checkedCodes.has(name)
        ? {
            type: 'equation_any',
            children: [],
            missing: [],
            codes: [name],
            value: true,
            description: codeDescription.get(name),
          }
        : {
            type: 'equation_any',
            children: [],
            missing: [],
            codes: [name],
            value: false,
            description: codeDescription.get(name),
          }
    )
    .sort((a) => (a.value ? -1 : 1))

  if (!value) {
    return {
      ...node,
      children,
      missing: node.codes.filter((code) => !checkedCodes.has(code)),
      value,
    }
  }

  return {
    ...node,
    children,
    missing: [],
    value,
  }
}

export const longestCommonPrefix = (codes: Array<string>) => {
  const first = codes[0] || ''
  let commonLength = first.length
  for (let i = 1; i < codes.length; ++i) {
    for (let j = 0; j < commonLength; ++j) {
      if (codes[i].charAt(j) !== first.charAt(j)) {
        commonLength = j
        break
      }
    }
  }

  return first.slice(0, commonLength)
}
