import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  useUpdateClinicalStatus,
  useUpdateChiefComplaint,
  NewChiefComplaint,
  isValidClinicalStatusPriority,
} from '.'
import { ClinicalStatusSelector, clinicalStatusTranslation, HowLongSelector } from '../Selector'
import {
  Box,
  Flex,
  HStack,
  Input,
  StackProps,
  Text,
  Stack,
  useBreakpointValue,
  Heading,
  VStack,
  Tag,
} from '@chakra-ui/react'
import { ErrorViewer, Loading, useDebounceExecute } from '..'
import {
  ChiefComplaint as ChiefComplaintInterface,
  ClinicalStatus,
  HowLong,
} from '../../api/interfaces'
import { Nullable } from '../../utils'
import { getClinicalStatusColorScheme } from './utils'

const messages = defineMessages({
  status: {
    id: 'ClinicalStatus.clinical_status_title',
    defaultMessage: 'Status',
  },
  chiefComplaint: {
    id: 'ChiefComplaint.label_chief_complaint',
    defaultMessage: 'Chief Complaint',
  },
  howLong: {
    id: 'ChiefComplaint.label_how_long',
    defaultMessage: 'How long?',
  },
  clinicalReview: {
    id: 'VisitResume.clinical_status',
    defaultMessage: 'Clinical Status',
  },
})

type StatusProps = StackProps & {
  patientId: string
  visitId: string
  clinicalStatus: Nullable<ClinicalStatus>
  chiefComplaint: Nullable<ChiefComplaintInterface>
  isDisabled?: boolean
}

export const Status: React.FC<StatusProps> = (props) => {
  const { patientId, visitId, clinicalStatus, chiefComplaint, isDisabled, ...boxProps } = props

  const intl = useIntl()

  const params = {
    visitId,
    patientId,
  }

  const { onClinicalStatusChange, error, onErrorClose, isLoading } = useUpdateClinicalStatus(params)

  return (
    <Box {...boxProps}>
      <ChiefComplaint
        patientId={patientId}
        visitId={visitId}
        chiefComplaint={chiefComplaint}
        isDisabled={isDisabled}
        spacing={3}
      />

      <HStack alignItems={'center'} justifyContent={'space-between'} mt={6}>
        <HStack>
          <Heading size={'md'}>{intl.formatMessage(messages.status)}</Heading>
          {isLoading && <Loading size="sm" />}
        </HStack>
        <ClinicalStatusSelector
          value={clinicalStatus?.priority ?? null}
          onChange={onClinicalStatusChange}
          isDisabled={isLoading || isDisabled}
        />
      </HStack>

      {error && <ErrorViewer title={error} onClose={onErrorClose} />}
    </Box>
  )
}

type ChiefComplaintProps = StackProps & {
  patientId: string
  visitId: string
  chiefComplaint: Nullable<ChiefComplaintInterface>
  isDisabled?: boolean
}

const ChiefComplaint: React.FC<ChiefComplaintProps> = (props) => {
  const { patientId, visitId, chiefComplaint, isDisabled, ...stackProps } = props

  const intl = useIntl()
  const isInline = useBreakpointValue({ base: false, md: true })
  const { execute } = useDebounceExecute(300)

  const params = {
    patientId,
    visitId,
  }
  const { onChiefComplaintChange, isLoading, error, onErrorClose } = useUpdateChiefComplaint(params)

  const onComplaintChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    const newChiefComplaint: NewChiefComplaint = {
      how_long: chiefComplaint?.how_long ?? null,
      complaint: value,
    }

    execute(() => onChiefComplaintChange(newChiefComplaint))
  }

  const onHowLongChange = (how_long: HowLong) => {
    const newChiefComplaint: NewChiefComplaint = {
      complaint: chiefComplaint?.complaint ?? null,
      how_long,
    }
    onChiefComplaintChange(newChiefComplaint)
  }

  return (
    <Stack isInline={isInline} {...stackProps}>
      <Flex direction={'column'} flex={1}>
        <Text>{intl.formatMessage(messages.chiefComplaint)}</Text>
        <Input
          defaultValue={chiefComplaint?.complaint ?? ''}
          onChange={onComplaintChange}
          disabled={isDisabled}
          placeholder={intl.formatMessage(messages.chiefComplaint)}
          maxLength={50}
        />
      </Flex>
      <Flex direction={'column'} flex={1}>
        <Text>{intl.formatMessage(messages.howLong)}</Text>
        <HowLongSelector
          isDisabled={isDisabled}
          value={chiefComplaint?.how_long as HowLong}
          onChange={onHowLongChange}
          isMulti={false}
          isLoading={isLoading}
          isClearable
        />
      </Flex>

      {error && <ErrorViewer title={error} onClose={onErrorClose} />}
    </Stack>
  )
}

type PrintableChiefComplaintProps = StackProps & {
  chiefComplaint: Nullable<ChiefComplaintInterface>
}

export const PrintableChiefComplaint: React.FC<PrintableChiefComplaintProps> = (props) => {
  const { chiefComplaint, ...stackProps } = props

  const intl = useIntl()

  if (!chiefComplaint?.complaint && !chiefComplaint?.how_long) {
    return null
  }

  return (
    <VStack alignItems={'start'} spacing={0} {...stackProps}>
      {chiefComplaint?.complaint && (
        <Text>
          <Text as={'span'} fontWeight={'bold'} mr={1}>
            {intl.formatMessage(messages.chiefComplaint)}:
          </Text>
          {chiefComplaint?.complaint}
        </Text>
      )}
      {chiefComplaint?.how_long && (
        <Text>
          <Text as={'span'} fontWeight={'bold'} mr={1}>
            {intl.formatMessage(messages.howLong)}:
          </Text>
          {chiefComplaint.how_long}
        </Text>
      )}
    </VStack>
  )
}

type PrintableClinicalStatusProps = StackProps & {
  clinicalStatus: Nullable<ClinicalStatus>
}

export const PrintableClinicalStatus: React.FC<PrintableClinicalStatusProps> = (props) => {
  const { clinicalStatus, ...stackProps } = props

  const intl = useIntl()

  if (clinicalStatus === null || clinicalStatus.priority == null) {
    return null
  }

  const translatedPriority = isValidClinicalStatusPriority(clinicalStatus.priority as string)
    ? clinicalStatusTranslation[clinicalStatus.priority]
    : null

  const color = isValidClinicalStatusPriority(clinicalStatus.priority as string)
    ? getClinicalStatusColorScheme(clinicalStatus.priority)
    : undefined

  return (
    <HStack width={'100%'} justifyContent={'flex-end'} alignItems={'center'} {...stackProps}>
      <Tag
        size={'lg'}
        colorScheme={color}
        sx={{
          '@media print': {
            color: 'black',
            borderColor: 'black',
            borderWidth: '1px',
          },
        }}
      >
        {translatedPriority ? intl.formatMessage(translatedPriority) : '--'}
      </Tag>
    </HStack>
  )
}

type PdfClinicalStatusProps = {
  clinicalStatus: Nullable<ClinicalStatus>
}

export const PdfClinicalStatus: React.FC<PdfClinicalStatusProps> = (props) => {
  const { clinicalStatus } = props

  const intl = useIntl()

  if (clinicalStatus === null || clinicalStatus.priority == null) {
    return null
  }

  const translatedPriority = isValidClinicalStatusPriority(clinicalStatus.priority as string)
    ? clinicalStatusTranslation[clinicalStatus.priority]
    : null

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: 'fit-content',
      }}
    >
      <span>{translatedPriority ? intl.formatMessage(translatedPriority) : '--'}</span>
    </div>
  )
}
