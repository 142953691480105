import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { ADD_STUDY_ORDER_TO_ACTIVE_VISIT } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { NewStudyOrder } from './utils'

const messages = defineMessages({
  studyNotFound: {
    id: 'UseOrderActions.study_not_found',
    defaultMessage: 'The selected study was not found.',
  },
  patientNotFound: {
    id: 'UseOrderActions.patient_not_found',
    defaultMessage: 'The patient was not found.',
  },
  errorAddingStudyOrder: {
    id: 'UseOrderActions.error_adding_study_order',
    defaultMessage: 'We could not create the study, try again later.',
  },
  invalidStudyTemplateId: {
    id: 'UseOrderActions.invalid_study_template_id',
    defaultMessage: 'You must select a valid procedure.',
  },
  genericErrorMessage: {
    id: 'UseOrderActions.generic_error_message',
    defaultMessage: 'Something went wrong, try again later.',
  },
})

interface CreateStudyOrderVariables {
  patientId: string
  visitId: string
  studyOrder: NewStudyOrder
}

interface CreateStudyOrderData {
  addStudyOrderToActiveVisit: FullPatientWithActiveVisit
}

export type UseCreateStudyOrderDataParams = MutationHookOptions<
  CreateStudyOrderData,
  CreateStudyOrderVariables
>

export const useCreateStudyOrder = (params: UseCreateStudyOrderDataParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'invalid_study_id':
      case 'missing_system_id':
      case 'template_not_found':
        return setError(intl.formatMessage(messages.studyNotFound))

      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_study_order':
      case 'error_creating_study':
        return setError(intl.formatMessage(messages.errorAddingStudyOrder))

      case 'invalid_study_template_id':
        return setError(intl.formatMessage(messages.invalidStudyTemplateId))

      default:
        return setError(intl.formatMessage(messages.genericErrorMessage))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)

    translateError(firstError)
  }

  const [createStudyOrder, { loading: isLoading }] = useMutation(ADD_STUDY_ORDER_TO_ACTIVE_VISIT, {
    onError,
    ...params,
  })

  const onErrorClose = () => {
    setError(null)
  }

  return {
    error,
    createStudyOrder,
    isLoading,
    onErrorClose,
  }
}
