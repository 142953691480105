import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { RiDeleteBin2Line as DeleteIcon } from 'react-icons/ri'
import { ConfirmButton, SignatureTag } from '..'
import { Box, BoxProps, Flex, Heading, HStack, Text, VStack, UnorderedList, ListItem } from '@chakra-ui/layout'
import Icon from '@chakra-ui/icon'
import { Collapse, Fade } from '@chakra-ui/transition'
import { useDisclosure } from '@chakra-ui/hooks'
import { IconButton } from '@chakra-ui/button'
import { RiArrowRightSLine as ExpandButton } from 'react-icons/ri'
import { useRemoveManualTreatment } from '.'
import { FullChecklist, Treatment as TreatmentInterface } from '../../api/interfaces'
import { ITEM_TYPE } from 'wd-common/src/logic/checklists/definitions'
import { DynamicTranslation, useDynamicTranslation } from '../i18n'

const messages = defineMessages({
  additionalTreatments: {
    id: 'ManualTreatments.additional_treatments',
    defaultMessage: 'Treatment',
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  confirmationMessage: {
    id: 'ManualTreatments.removing_confirmation_message',
    defaultMessage: 'This item will permanently delete this treatment. Are you sure?',
  },
  moreItems: {
    id: 'UI.label_more_items',
    defaultMessage: '... {count} hidden item/s',
  },
})

type TreatmentsProps = BoxProps & {
  patientId: string
  visitId: string
  items: Array<TreatmentInterface>
  checklists: Array<FullChecklist>
  isDisabled?: boolean
}

export const Treatments: React.FC<TreatmentsProps> = (props) => {
  const { items, patientId, visitId, isDisabled, checklists, ...boxProps } = props

  const intl = useIntl()

  const { isOpen, onToggle } = useDisclosure()

  const firstTwoItems = items.slice(0, 2)
  const restItems = items.length > 2 ? items.slice(2) : []
  const showExpandButton = restItems.length > 0

  const textEntries = checklists.map((c) => c.treatment?.filter((t) => t.checked && t.type === ITEM_TYPE.TEXT)).flat()

  return (
    <Box {...boxProps}>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Heading size={'md'} mb={1} onClick={onToggle} cursor="pointer">
          {intl.formatMessage(messages.additionalTreatments)}
        </Heading>

        {showExpandButton && (
          <IconButton
            size="sm"
            aria-label={'expand all'}
            colorScheme={'brand'}
            variant="ghost"
            onClick={onToggle}
            icon={<ExpandButton />}
            sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}
          />
        )}
      </HStack>
      {textEntries.length > 0 && (
        <UnorderedList p={2}>
          {textEntries.map((e, i) => {
            if (!e?.name) return null

            return (
              <ListItem key={`${e.name.text}-${i}`}>
                <DynamicTranslation message={e.name} />
              </ListItem>
            )
          })}
        </UnorderedList>
      )}
      {firstTwoItems.map((t) => (
        <Treatment key={t.id} patientId={patientId} visitId={visitId} treatment={t} isDisabled={isDisabled} />
      ))}
      {showExpandButton && (
        <Collapse startingHeight={0} in={isOpen} animateOpacity>
          {restItems.map((t) => (
            <Treatment key={t.id} patientId={patientId} visitId={visitId} treatment={t} isDisabled={isDisabled} />
          ))}
        </Collapse>
      )}
      {showExpandButton && (
        <Fade in={!isOpen}>
          <Flex justifyContent={'center'}>
            <Text
              fontSize={'xs'}
              onClick={onToggle}
              cursor={'pointer'}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {intl.formatMessage(messages.moreItems, { count: items.length - 2 })}
            </Text>
          </Flex>
        </Fade>
      )}
    </Box>
  )
}

interface TreatmentProps {
  patientId: string
  visitId: string
  treatment: TreatmentInterface
  isDisabled?: boolean
}

const Treatment: React.FC<TreatmentProps> = (props) => {
  const { patientId, visitId, treatment, isDisabled } = props

  const intl = useIntl()

  const { id, treatment: t, created } = treatment

  const params = {
    visitId,
    patientId,
  }

  const { onDelete, isLoading, error, onErrorClose } = useRemoveManualTreatment(params)

  const isLegacy = id === null

  return (
    <HStack alignItems={'start'} justifyContent={'space-between'} mt={1} p={2} borderWidth={1} borderRadius="lg">
      <VStack alignItems={'start'}>
        <Heading size="sm" fontWeight={'bold'} lineHeight={1.5}>
          <SignatureTag signature={created} mr={1} />
          {t}
        </Heading>
      </VStack>
      {!isLegacy && (
        <ConfirmButton
          onConfirm={() => onDelete(id)}
          message={intl.formatMessage(messages.confirmationMessage)}
          buttonProps={{
            size: 'sm',
            color: 'red.400',
            label: <Icon as={DeleteIcon} />,
            isDisabled,
            isLoading,
          }}
          error={error}
          onErrorClose={onErrorClose}
        />
      )}
    </HStack>
  )
}

type PrintableTreatmentsProps = BoxProps & {
  checklists: Array<FullChecklist>
  items: Array<TreatmentInterface>
}

export const PrintableTreatments: React.FC<PrintableTreatmentsProps> = (props) => {
  const { items, checklists, ...boxProps } = props

  const { translate } = useDynamicTranslation()

  const textEntries = checklists
    .map((c) => c.treatment?.filter((t) => t.checked && t.type === ITEM_TYPE.TEXT))
    .flat()
    .map((e) => (e && e.name ? translate(e.name) : null))

  if (items.length === 0 && textEntries.length === 0) {
    return null
  }

  return (
    <div style={{ width: '100%' }}>
      <ul style={{ padding: '0 24px' }} className="pdfDiagnosisChecklists">
        {textEntries.map((e, i) => {
          return <li key={`${e}-${i}`}>{e}</li>
        })}
      </ul>
      <Box {...boxProps}>
        {items.map((treatment, index) => {
          const { id, treatment: t } = treatment
          const key = `treatment-${id}-${index}`

          return (
            <VStack
              alignItems={'start'}
              spacing={0}
              mt={1}
              p={2}
              borderWidth={1}
              borderRadius="lg"
              key={key}
              sx={{
                '@media print': {
                  margin: '0 0 .2rem 0',
                  padding: 0,
                  border: 0,
                },
              }}
              className="pdfManualDiagnose"
            >
              <Heading size="sm" display={'flex'} sx={{ wordBreak: 'break-all' }}>
                {t}
              </Heading>
            </VStack>
          )
        })}
      </Box>
    </div>
  )
}
