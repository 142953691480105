import React from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  initialColorMode: 'dark',
  useSystemColorMode: true,
  colors: {
    brand: {
      50: '#def3ff',
      100: '#b6dcf9',
      200: '#8dc9f0',
      300: '#62b9e9',
      400: '#3baae2',
      500: '#2496c8',
      600: '#16799d',
      700: '#095071',
      800: '#002b46',
      900: '#000d1b',
    },
    white: '#fdfdfd',
  },
  styles: {
    global: () => ({
      '@page': {
        margin: '1cm',
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        color: 'black',
        borderColor: 'black',
      },
      '@media print': {
        '*, *::after, *::before': {
          color: 'black',
          borderColor: 'black',
        },
        button: {
          visibility: 'hidden',
        },
      },
      '.printable': {
        display: 'none!important',
      },
    }),
  },
})

export const ThemeProvider: React.FC = (props) => {
  return <ChakraProvider theme={theme}>{props.children}</ChakraProvider>
}
