import React from 'react'
import { ChecklistReferenceBody } from './ChecklistReference'
import { FullChecklistEntry } from '../../api/interfaces'
import { DynamicTranslation } from '../i18n'
import { Checkbox, CheckboxProps } from '@chakra-ui/react'

type ChecklistTextEntry = Omit<CheckboxProps, 'onChange' | 'value' | 'defaultValue'> & {
  entry: FullChecklistEntry
  onCheckChange: (checked: boolean) => void
}

export const ChecklistTextEntry: React.FC<ChecklistTextEntry> = (props) => {
  const { entry, onCheckChange, ...checkboxProps } = props

  const _onCheckChange = (e) => {
    const { checked } = e.target
    onCheckChange(checked)
  }

  return (
    <>
      <Checkbox
        onChange={_onCheckChange}
        defaultChecked={entry.checked ?? false}
        my={1}
        {...checkboxProps}
      >
        {entry?.name && <DynamicTranslation fontStyle={'italic'} message={entry.name} as="span" />}
        {entry.reference && (
          <ChecklistReferenceBody fontFamily={'sm'} ml={2} reference={entry.reference} />
        )}
      </Checkbox>
    </>
  )
}
