import { Checkbox, CheckboxProps } from '@chakra-ui/react'
import React from 'react'
import { useField } from 'react-final-form'
import { Control, Error } from './utils'

type CheckboxField = CheckboxProps & {
  name: string
  label: string
}

export const CheckboxField: React.FC<CheckboxField> = (props) => {
  const { name, label, ...otherInputProps } = props
  const { input, meta } = useField(name, { type: 'checkbox' })

  return (
    <Control name={name}>
      <Checkbox
        {...otherInputProps}
        {...input}
        isChecked={input.checked}
        isInvalid={meta.error && meta.touched}
        id={name}
      >
        {label}
      </Checkbox>
      <Error name={name} />
    </Control>
  )
}
