import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ValidateFn, ValidationError } from '../Form'
import { Nullable } from '../../utils'
import { useSession } from './useSession'
import { VerifiableAttribute } from './session'
import { useNavigate } from 'react-router-dom'
import { sitemap } from '../Routing'

const messages = defineMessages({
  authenticationCodeDescription: {
    id: 'StaffAttributeVerification.a_code_has_been_sent',
    defaultMessage:
      'An authentication code has been sent to your {translatedAttributeName}. Enter the code to continue.',
  },
  required: {
    id: 'StaffRegisterConfirm.this_field_is_required',
    defaultMessage: 'This field is required',
  },
  codeExpired: {
    id: 'StaffRegisterConfirm.invalid_code_provided',
    defaultMessage: 'Code expired, please request a new code',
  },
  invalidCodeError: {
    id: 'StaffRegisterConfirm.invalid_code_provided',
    defaultMessage: 'Invalid code provided, please request a code again',
  },
  limitExceededException: {
    id: 'StaffRegisterConfirm.attempt_limit_exceeded',
    defaultMessage: 'Attempt limit exceeded, please try after some time.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export const useAttributeVerification = (attributeName: VerifiableAttribute) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isReSendingCode, setIsReSendingCode] = React.useState<boolean>(false)

  const navigate = useNavigate()

  const { verifyAttribute, resendAttributeVerificationCode } = useSession()

  const initialValues = {
    code: '',
  }

  const validate: ValidateFn<{ code: string }> = (values) => {
    const errors: ValidationError<{ code: string }> = {}

    if (!values.code) {
      errors.code = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    const code = (error || {}).code
    switch (code) {
      case 'ExpiredCodeException':
        setError(intl.formatMessage(messages.codeExpired))
        break

      case 'CodeMismatchException':
        setError(intl.formatMessage(messages.invalidCodeError))
        break

      case 'LimitExceededException':
        setError(intl.formatMessage(messages.limitExceededException))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const onSubmit = async (params: { code: string }) => {
    setIsLoading(true)

    try {
      setIsLoading(false)
      await verifyAttribute({ ...params, attributeName })

      redirectToPreferences()
    } catch (error) {
      setIsLoading(false)
      onError(error)
    }
  }

  const onReSendCode = async () => {
    setIsReSendingCode(true)

    try {
      setIsReSendingCode(false)
      await resendAttributeVerificationCode({ attributeName })
    } catch (error) {
      setIsReSendingCode(false)
      onError(error)
    }
  }

  const redirectToPreferences = () => {
    navigate(sitemap.preferences.routes.account.absolutePath)
  }

  return {
    initialValues,
    onCancel: redirectToPreferences,
    onSubmit,
    isLoading,
    error,
    validate,
    onReSendCode,
    isReSendingCode,
  }
}
