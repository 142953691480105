import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import {
  getNormalVitalsByAge,
  isInRange,
  calculatePercentileAndZScore,
  getWeightStandards,
} from 'wd-common/src/logic/triage/utils'
import { Weight as WeightType } from '../../api/interfaces'
import {
  Badge,
  Box,
  Button,
  ButtonProps,
  Collapse,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagProps,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { TextField } from '../Form'
import { PatientAgeCategory } from '../Patients'
import {
  NewWeight,
  DeleteMeasurementButton,
  PercentileWarning,
  useCreateWeight,
  PdfPercentileWarning,
} from '.'
import { PrintableSignatureTag, ErrorViewer } from '..'
import {
  RiAddLine as AddIcon,
  RiAlertLine as WarningIcon,
  RiArrowRightSLine as ExpandButton,
} from 'react-icons/ri'
import { getAge } from '../DateAndTime'
import { DateTime } from 'luxon'
import { MetricUnits, UnitSystems, UnitTypes, useUnitConversion } from '../useUnitConversion'

const messages = defineMessages({
  title: {
    id: 'Vitals.weight_title',
    defaultMessage: 'Weight',
  },
  shortTitle: {
    id: 'Vitals.weight_abbreviation',
    defaultMessage: 'WT',
  },
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?',
  },
  kg: {
    id: 'Vitals.kg_label',
    defaultMessage: 'kg',
  },
  reference: {
    id: 'Vitals.reference_title',
    defaultMessage: 'Reference',
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  submitButton: {
    id: 'NonStaffUI.button_submit',
    defaultMessage: 'Submit',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
  values: {
    id: 'UI.values_label',
    defaultMessage: 'Values',
  },
})

type MeasurementListProps = {
  values: Array<WeightType>
  patientId: string
  visitId: string
  birthdate: string
  sex: string
}

type WeightProps = ButtonProps & MeasurementListProps

export const Weight: React.FC<WeightProps> = (props) => {
  const { values, patientId, visitId, birthdate, sex, ...buttonProps } = props

  const { onToggle, isOpen } = useDisclosure()

  const noValues = values.length === 0
  const intl = useIntl()

  return (
    <>
      <Button mr="-px" {...buttonProps} onClick={onToggle}>
        {noValues ? (
          <>
            <Icon as={AddIcon} />
            <Text ml={2}>{intl.formatMessage(messages.shortTitle)}</Text>
          </>
        ) : (
          <ValueWithWarning value={values[0]} birthdate={birthdate} sex={sex} />
        )}
      </Button>

      <WeightModal
        values={values}
        isOpen={isOpen}
        onToggle={onToggle}
        birthdate={birthdate}
        patientId={patientId}
        visitId={visitId}
        sex={sex}
      />
    </>
  )
}

const MeasurementList: React.FC<MeasurementListProps> = (props) => {
  const { values, patientId, visitId, birthdate, sex } = props

  const intl = useIntl()
  const { onToggle, isOpen } = useDisclosure({ defaultIsOpen: true })

  return (
    <Box px={4} py={2} my={2} borderWidth={1} rounded={'lg'}>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Heading size={'sm'} mb={1} onClick={onToggle} cursor="pointer">
          {intl.formatMessage(messages.values)}
        </Heading>
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant="ghost"
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}
        />
      </HStack>
      <Collapse startingHeight={0} in={isOpen} animateOpacity>
        {values.map((value, index, array) => {
          const key = `ht-${value.id}${index}`

          return (
            <HStack my={2} justifyContent={'space-between'} alignItems={'center'} key={key}>
              <VStack alignItems={'flex-start'} spacing={0}>
                <HStack alignItems="center" spacing={2}>
                  <ValueWithWarning value={value} birthdate={birthdate} sex={sex} />
                  <WeightDifference value={value} index={index} array={array} hideZeroDiff />
                </HStack>
                <PrintableSignatureTag
                  signature={value.updated}
                  dateFormat={DateTime.DATETIME_SHORT}
                  fontSize="sm"
                  m={0}
                  color="GrayText"
                />
              </VStack>
              <DeleteMeasurementButton
                patientId={patientId}
                visitId={visitId}
                measurementId={value.id}
                size="sm"
              />
            </HStack>
          )
        })}
      </Collapse>
    </Box>
  )
}

type WeightDifferenceProps = {
  value: WeightType
  index: number
  array: Array<WeightType>
  hideZeroDiff?: boolean
}

const WeightDifference: React.FC<WeightDifferenceProps> = (props) => {
  const { value, index, array, hideZeroDiff } = props
  const { getDifference } = useUnitConversion()

  if (!value || !array.length) return null

  // We check for the next item in the array because
  // the array is ordered by DATE DESC
  if (index < array.length - 1) {
    const prevValue = array[index + 1]

    if (isNaN(value.kg) || isNaN(prevValue.kg)) return null

    const { diff, formatted } = getDifference(value.kg, prevValue.kg, UnitTypes.WEIGHT)

    if (hideZeroDiff && diff === 0) return null

    let color

    if (diff > 0) color = 'green'
    if (diff < 0) color = 'red'

    const textToShow = `${diff > 0 ? '+' : ''}${formatted}`

    return (
      <Badge colorScheme={color} style={{ textTransform: 'initial' }}>
        {textToShow}
      </Badge>
    )
  }

  return null
}

type WeightModalProps = MeasurementListProps & {
  isOpen: boolean
  onToggle: () => void
}

const WeightModal: React.FC<WeightModalProps> = (props) => {
  const { values, birthdate, sex, visitId, patientId, isOpen, onToggle } = props

  const { unitSystem } = useUnitConversion()

  const isFullWidth = useBreakpointValue({ base: true, sm: false })

  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      {unitSystem === UnitSystems.metric ? (
        <MetricWeightModalContent
          values={values}
          birthdate={birthdate}
          sex={sex}
          visitId={visitId}
          patientId={patientId}
          isFullWidth={isFullWidth}
          onToggle={onToggle}
        />
      ) : (
        <ImperialWeightModalContent
          values={values}
          birthdate={birthdate}
          sex={sex}
          visitId={visitId}
          patientId={patientId}
          isFullWidth={isFullWidth}
          onToggle={onToggle}
        />
      )}
    </Modal>
  )
}

type WeightModalContentProps = {
  birthdate: string
  sex: string
  patientId: string
  visitId: string
  values: Array<WeightType>
  onToggle: any
  isFullWidth?: boolean
}

const MetricWeightModalContent: React.FC<WeightModalContentProps> = (props) => {
  const { birthdate, sex, patientId, visitId, values, onToggle, isFullWidth } = props

  const intl = useIntl()

  const { getUnitTranslation, convertUnitFromInput } = useUnitConversion()

  const params = {
    patientId,
    visitId,
  }
  const { onCreate, onErrorClose, error, isLoading, validateMetric } = useCreateWeight(params)

  const onSubmit = (values) => {
    onCreate({ kg: values.kg })
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateMetric}
      keepDirtyOnReinitialize
      render={({ form, handleSubmit, values: formValues }) => {
        const { isWarningEnabled, percentile } = checkWarnings({
          birthdate,
          sex,
          weight: convertUnitFromInput(Number(formValues.kg), UnitTypes.WEIGHT),
        })

        return (
          <form
            onSubmit={async (event) => {
              await handleSubmit(event)
              const { valid } = form.getState()
              if (valid) {
                form.resetFieldState('kg')
                form.reset()
              }
            }}
          >
            <ModalContent>
              <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TextField
                  name="kg"
                  label={getUnitTranslation(MetricUnits.KG)}
                  type={'number'}
                  step={0.001}
                  after={
                    isWarningEnabled && !isNaN(percentile) ? (
                      <PercentileWarning percentile={percentile} />
                    ) : undefined
                  }
                  autoFocus={Boolean(form.getFieldState('kg')?.value)}
                />

                {error && <ErrorViewer title={error} onClose={onErrorClose} />}

                <MeasurementList
                  patientId={patientId}
                  visitId={visitId}
                  birthdate={birthdate}
                  sex={sex}
                  values={values}
                />

                <VitalReference birthdate={birthdate} sex={sex} />
              </ModalBody>

              <ModalFooter>
                <Button isFullWidth={isFullWidth} onClick={onToggle}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  colorScheme={'brand'}
                  isLoading={isLoading}
                  type="submit"
                  isFullWidth={isFullWidth}
                  ml={2}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        )
      }}
    />
  )
}

const ImperialWeightModalContent: React.FC<WeightModalContentProps> = (props) => {
  const { birthdate, sex, patientId, visitId, values, onToggle, isFullWidth } = props

  const intl = useIntl()

  const { getUnitTranslation, convertUnitFromInput } = useUnitConversion()

  const params = {
    patientId,
    visitId,
  }
  const { onCreate, onErrorClose, error, isLoading, validateImperial } = useCreateWeight(params)

  const onSubmit = (values) => {
    const newWeight: NewWeight = {
      kg: convertUnitFromInput(
        Number(values.pounds || 0) + Number(values.ounces || 0) / 16,
        UnitTypes.WEIGHT
      ),
    }

    onCreate(newWeight)
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateImperial}
      keepDirtyOnReinitialize
      render={({ form, handleSubmit, values: formValues }) => {
        const { isWarningEnabled, percentile } = checkWarnings({
          birthdate,
          sex,
          weight: convertUnitFromInput(
            Number(formValues.pounds || 0) + Number(formValues.ounces || 0) / 16,
            UnitTypes.WEIGHT
          ),
        })

        return (
          <form
            onSubmit={async (e) => {
              await handleSubmit(e)

              const { valid } = form.getState()

              if (valid) {
                form.resetFieldState('pounds')
                form.resetFieldState('ounces')

                form.reset()
              }
            }}
          >
            <ModalContent>
              <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TextField
                  name="pounds"
                  label={getUnitTranslation(MetricUnits.KG)}
                  type={'number'}
                  step={1}
                  min={0}
                  after={
                    isWarningEnabled && !isNaN(percentile) ? (
                      <PercentileWarning percentile={percentile} />
                    ) : undefined
                  }
                  autoFocus={Boolean(form.getFieldState('pounds')?.value)}
                />
                <TextField
                  name="ounces"
                  label={getUnitTranslation(MetricUnits.G)}
                  type={'number'}
                  step={0.1}
                  max={15.9}
                  min={0}
                />

                {error && <ErrorViewer title={error} onClose={onErrorClose} />}

                <MeasurementList
                  patientId={patientId}
                  visitId={visitId}
                  birthdate={birthdate}
                  sex={sex}
                  values={values}
                />

                <VitalReference birthdate={birthdate} sex={sex} />
              </ModalBody>

              <ModalFooter>
                <Button isFullWidth={isFullWidth} onClick={onToggle}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  colorScheme={'brand'}
                  isLoading={isLoading}
                  type="submit"
                  isFullWidth={isFullWidth}
                  ml={2}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        )
      }}
    />
  )
}

interface CheckWarningsParams {
  birthdate: string
  sex: string
  weight: number
}

const checkWarnings = (params: CheckWarningsParams) => {
  const { weight, birthdate, sex } = params

  const reference = getNormalVitalsByAge({ birthdate })

  const weightStandards = getWeightStandards({ sex, birthdate })

  const calculation = calculatePercentileAndZScore(weight, weightStandards)

  const percentile = calculation?.percentile
  const wtReference = (reference || {}).wt

  const inRange =
    !wtReference ||
    !weight ||
    isInRange((wtReference || {}).min, (wtReference || {}).max, percentile)

  // only show warning for patients < 10y
  const ageInYears = getAge(new Date(birthdate), 'years')
  const isWarningEnabled = ageInYears !== null && ageInYears < 10

  return {
    isWarningEnabled,
    inRange,
    percentile,
    reference: wtReference,
  }
}

type ValueWithWarningProps = {
  value: WeightType
  birthdate: string
  sex: string
}

const ValueWithWarning: React.FC<ValueWithWarningProps> = (props) => {
  const { value, birthdate, sex } = props

  const intl = useIntl()
  const { convertUnitFromDb, formatNumber } = useUnitConversion()

  const convertedValue = convertUnitFromDb(value.kg, UnitTypes.WEIGHT)

  const { inRange, isWarningEnabled, percentile } = checkWarnings({
    birthdate,
    sex,
    weight: value.kg,
  })

  return (
    <HStack alignItems={'center'}>
      {isWarningEnabled && !inRange && <Icon as={WarningIcon} color="red.400" />}
      <Text mx={1}>{intl.formatMessage(messages.shortTitle)}:</Text>
      <Text fontWeight={'bold'} color={isWarningEnabled && !inRange ? 'red.400' : undefined}>
        {formatNumber(convertedValue, UnitTypes.WEIGHT) ?? '--'}
      </Text>

      {isWarningEnabled && <PercentileWarning percentile={percentile} />}
    </HStack>
  )
}

const PdfValueWithWarning: React.FC<ValueWithWarningProps> = (props) => {
  const { value, birthdate, sex } = props

  const intl = useIntl()
  const { formatNumber, convertUnitFromDb } = useUnitConversion()

  const { inRange, isWarningEnabled, percentile } = checkWarnings({
    birthdate,
    sex,
    weight: value.kg,
  })

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{intl.formatMessage(messages.shortTitle)}:</span>
      <span
        style={{
          fontWeight: 'bold',
          color: isWarningEnabled && !inRange ? 'red' : undefined,
          marginLeft: '4px',
          marginRight: '4px',
        }}
      >
        {formatNumber(convertUnitFromDb(value?.kg, UnitTypes.WEIGHT), UnitTypes.WEIGHT) ?? '--'}
      </span>
      {isWarningEnabled && <PdfPercentileWarning percentile={percentile} />}
    </div>
  )
}

type VitalReferenceProps = {
  birthdate: string
  sex: string
}

const VitalReference: React.FC<VitalReferenceProps> = (props) => {
  const { birthdate } = props

  const intl = useIntl()

  const reference = getNormalVitalsByAge({ birthdate })
  const wtReference = (reference || {}).wt

  if (!wtReference) {
    return null
  }

  return (
    <Box p={4} borderWidth={1} rounded={'lg'}>
      <PatientAgeCategory birthdate={birthdate} mb={1} fontWeight={'bold'} />
      <HStack>
        <Text>{intl.formatMessage(messages.reference)}</Text>
        <Text fontWeight={'bold'} ml={2}>
          {`${wtReference.min} - ${wtReference.max} %`}
        </Text>
      </HStack>
    </Box>
  )
}

type PrintableWeightProps = TagProps & {
  birthdate: string
  sex: string
  value: WeightType
}

export const PrintableWeight: React.FC<PrintableWeightProps> = (props) => {
  const { value, birthdate, sex, ...tagProps } = props

  return (
    <Tag {...tagProps}>
      <ValueWithWarning value={value} birthdate={birthdate} sex={sex} />
    </Tag>
  )
}

type PdfWeightProps = {
  birthdate: string
  sex: string
  value: WeightType
}

export const PdfWeight: React.FC<PdfWeightProps> = (props) => {
  const { value, birthdate, sex } = props

  return <PdfValueWithWarning value={value} birthdate={birthdate} sex={sex} />
}
