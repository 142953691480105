import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  ButtonProps,
  Icon,
} from '@chakra-ui/react'
import { ConfirmButton } from '..'
import { useRemoveMeasurement } from './useRemoveMeasurement'
import {
  RiDeleteBin2Line as DeleteIcon,
} from 'react-icons/ri'

const messages = defineMessages({
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?',
  },
})

type DeleteMeasurementButtonProps = ButtonProps & {
  patientId: string
  visitId: string
  measurementId: string
}

export const DeleteMeasurementButton: React.FC<DeleteMeasurementButtonProps> = props => {
  const { patientId, visitId, measurementId, ...buttonProps } = props

  const intl = useIntl()

  const { onDelete, isLoading, error, onErrorClose } = useRemoveMeasurement({ patientId, visitId })
  return <ConfirmButton
    onConfirm={() => onDelete(measurementId)}
    message={intl.formatMessage(messages.confirmationMessage)}
    buttonProps={{
      color: 'red.400',
      label: <Icon as={DeleteIcon} />,
      isLoading,
      ...buttonProps
    }}
    error={error}
    onErrorClose={onErrorClose}
  />
}