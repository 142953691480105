import React from 'react'
import { PdfBloodPressure, PdfWeight, PdfHeight, PdfHeadCircumference, PdfTemperature } from '.'
import {
  BloodPressure,
  Weight,
  Height,
  HeadCircumference,
  Temperature,
  Measurement,
  VitalType,
} from '../../api/interfaces'

type MeasurementProps = {
  birthdate: string
  sex: string
  type: VitalType
  values: Array<Measurement>
}

const PdfMeasurement: React.FC<MeasurementProps> = (props) => {
  const { type, values, sex, birthdate } = props

  if (values.length === 0) {
    return null
  }

  const getVitalComponent = (type: VitalType) => {
    switch (type) {
      case VitalType.BLOOD_PRESSURE:
        return <PdfBloodPressure value={value as BloodPressure} birthdate={birthdate} />

      case VitalType.HEIGHT:
        return <PdfHeight value={value as Height} birthdate={birthdate} sex={sex} />

      case VitalType.TEMPERATURE:
        return <PdfTemperature value={value as Temperature} birthdate={birthdate} />

      case VitalType.WEIGHT:
        return <PdfWeight value={value as Weight} birthdate={birthdate} sex={sex} />

      case VitalType.HEAD_CIRCUMFERENCE:
        return (
          <PdfHeadCircumference
            value={value as HeadCircumference}
            birthdate={birthdate}
            sex={sex}
          />
        )

      /**
       * The components for theese types still need to be
       * updated to work with several measurements
       */
      case VitalType.MID_UPPER_ARM_CIRCUMFERENCE:
      case VitalType.HEART_RATE:
      case VitalType.RESPIRATORY_RATE:
      case VitalType.O2_SATURATION:
      default:
        return null
    }
  }

  const value = values[0]

  return <div style={{ margin: '0 4px' }}>{getVitalComponent(type)}</div>
}

export { PdfMeasurement }
