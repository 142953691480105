import { CustomField } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { useSystems } from '../Systems'

export const useCustomFields = () => {
  const { getMySystem } = useSystems()
  const { customFields } = getMySystem()

  const getCustomField = (id: string): Nullable<CustomField> => {
    if (!Array.isArray(customFields)) {
      return null
    }
    const found = customFields.find((field) => field.id === id)

    return found ?? null
  }

  return {
    customFields: customFields ?? [],
    getCustomField,
  }
}
