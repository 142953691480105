import moment from 'moment-timezone'
import { ProcedureColor, StatusColor, ToothAbsolutePosition, ToothPosition, ToothProcedure, ToothStatus } from './definitions'

/**
 * We are linking our UI (tooth and position colors)
 * with a dynamic list stored in our database.
 * This is not 'fine' but is what we have.
 * So, this linking is posible through this ID.
 * It's the id of the list which contains the tooth positions
 */
const POSITION_LIST_ID = '5e040b78d0c0831f4877ac15'

/**
 * Create a tooth section based on the tooth tempalte
 */
export const createToothSection = (toothTemplateSection, toothName) => ({
  ...toothTemplateSection,
  name: { type: 'translatable_text', text: `Tooth - ${toothName}` },
  key: toothTemplateSection.key + Number(toothName) / 100
})

/**
 * Unified fn to create the tooth name based on the
 * tooth number
 * @param {number} toothNumber
 * @returns {string} tooth name
 */
export const createToothName = toothNumber => {
  return `Tooth - ${toothNumber}`
}

/**
 * Check if the section is a Tooth section or the tooth template
 * based on the name
 * @param {string} name
 * @returns {boolean} is a tooth section or not
 */
export const isAToothSection = (name) => {
  return /Tooth - (\d+)/.exec(name) || name === 'Tooth'
}

/**
 * Check if the section is a Tooth section
 * based on the name
 * @param {string} name
 * @returns {boolean} is a tooth section or not
 */
export const isASingleToothSection = (name) => {
  return /Tooth - (\d+)/.exec(name)
}

/**
 * Check if the dental section choice
 * is a procedure one
 * @param {string} name
 * @returns {boolean}
 */
export const isAProcedureChoice = (name) => {
  if (typeof name !== 'string') return false

  return /Procedure - .*/.test(name)
}

/**
 * This fn takes the dental history
 * and returns a map that contains all the teeth
 * which have at least one entry in the history
 * with another map with the tooth positions and their color
 * based on the color standard
 * @param {*} dentalHistory
 * @returns Map<tooth, Map<position, color>>
 */
export const getActiveTeeth = dentalHistory => {
  if (typeof dentalHistory !== 'object') return new Map()

  const activeTeeth = Object.entries(dentalHistory)
    .reduce((activeTeeth, [tooth, examSections]) => {
      // take only the last entry for each tooth
      const lastSection = (examSections || []).sort(sortSectionsByDate)[0]
      const positionsWithColor = getPositionWithColor(lastSection.groups)

      return activeTeeth.set(tooth, positionsWithColor)
    }, new Map())

  return activeTeeth
}

/**
 * Get the color of each position of the tooth
 * based on the exam choices and details
 * @param {*} examGroups
 * @returns
 */
const getPositionWithColor = examGroups => {
  if (!Array.isArray(examGroups) || examGroups.length === 0) return new Map()

  const positionsWithColor = examGroups.reduce((positions, group) => {
    /**
     * look for the last selected choice this means new findings
     * or status will overwrite previous ones on the graphical display
     */
    const selectedChoice = (group.choices || [])
      .find(choice => choice.selected)

    /**
     * Find the latest selected choice
     */
    if (selectedChoice) {
      const color = getColor(selectedChoice)

      if (color) {
        /**
         * In case of caries, we should specify the position of the tooth
         */
        if ((selectedChoice.name.text || '').toLowerCase() === ToothStatus.CARIES) {
          const cariesPositions = getCariesPositions(selectedChoice.details)
          if (cariesPositions) {
            return cariesPositions
          }
        }

        /**
         * in other cases, all the positions will be filled
         * with the same color
         */
        Object.values(ToothPosition)
          .forEach(position => positions.set(position, color))
      }
    }

    return positions
  }, new Map())

  return positionsWithColor
}

/**
 * Classify the choice between option | status
 * and get the corresponding filling color
 */
const getColor = selectedChoice => {
  const choiceName = selectedChoice.name.text

  if (isAProcedureChoice(selectedChoice.name.text)) {
    return getColorFromProcedure(choiceName)
  } else {
    return getColorFromStatus(choiceName)
  }
}

/**
 * return the color we use to fill the tooth
 * based on the status of it
 * @param {string} status
 * @returns {string} color
 */
const getColorFromStatus = status => {
  if (typeof status !== 'string') return null

  const statusFound = Object.entries(ToothStatus)
    .find(([key, value]) => value.toLowerCase() === status.toLowerCase())

  const color = StatusColor[(statusFound || [])[0]]

  return color
}

/**
 * return the color we use to fill the tooth
 * based on the status of it
 * @param {string} procedure
 * @returns {string} color
 */
const getColorFromProcedure = procedure => {
  if (typeof procedure !== 'string') return null

  const procedureFound = Object.entries(ToothProcedure)
    .find(([key, value]) => value.toLowerCase() === procedure.toLowerCase())

  const color = ProcedureColor[(procedureFound || [])[0]]

  return color
}

/**
 * Get the specific selected positions to colorize
 * based in the details of the Caries choice...
 * @param {*} details
 * @returns {Map<position, color}
 */
const getCariesPositions = details => {
  if (!Array.isArray(details)) return null

  // look for the position list between the details
  const positionChoice = details.find(detail => detail.list_id === POSITION_LIST_ID)

  if (positionChoice) {
    const positions = new Map();

    (positionChoice.selected_items || []).forEach(item => {
      const positionName = (item.text || '').split(' ')[0].toUpperCase()

      positions.set(positionName, StatusColor.CARIES)
    })

    if (positions.size > 0) {
      return positions
    }
  }

  return null
}

/**
 * sort sections by date. Last ones will appear before in the array
 */
const sortSectionsByDate = (sectionA, sectionB) => {
  if (!sectionA.visitStart) {
    return -1
  }

  return moment(sectionB.visitStart).diff(moment(sectionA.visitStart))
}

/**
 *
 * @param {number} quadrant
 * @returns {Object} Position names
 *
 * Positions
 * Messial = Toward midline
 * Distal = away from midline
 * Lingual = inside / tongue
 * Facual = outside / cheek
 * Occlusal = up / flat side
 *
 * The position is not absolute. It depends on the quadrant...
 * For example, the left part of the tooth would be Messial if the tooth
 * is in one of the right quadrants or Distal in other cases
 *
 * So, we added an extra prop called quadrant
 *
 *  1 | 2
 * --------
 *  3 | 4
 *
 * This fn calculate the corresponding position name based on the
 * quadrant and returns them under a non-technical classification
 */
export const getPositionNameFromQuadrant = (quadrant) => {
  switch (quadrant) {
    case 1:
      return {
        [ToothAbsolutePosition.TOP]: ToothPosition.BUCCAL,
        [ToothAbsolutePosition.BOTTOM]: ToothPosition.LINGUAL,
        [ToothAbsolutePosition.CENTER]: ToothPosition.OCCLUSAL,
        [ToothAbsolutePosition.LEFT]: ToothPosition.DISTAL,
        [ToothAbsolutePosition.RIGHT]: ToothPosition.MESIAL
      }
    case 2:
      return {
        [ToothAbsolutePosition.TOP]: ToothPosition.BUCCAL,
        [ToothAbsolutePosition.BOTTOM]: ToothPosition.LINGUAL,
        [ToothAbsolutePosition.CENTER]: ToothPosition.OCCLUSAL,
        [ToothAbsolutePosition.LEFT]: ToothPosition.MESIAL,
        [ToothAbsolutePosition.RIGHT]: ToothPosition.DISTAL
      }

    case 3:
      return {
        [ToothAbsolutePosition.TOP]: ToothPosition.LINGUAL,
        [ToothAbsolutePosition.BOTTOM]: ToothPosition.BUCCAL,
        [ToothAbsolutePosition.CENTER]: ToothPosition.OCCLUSAL,
        [ToothAbsolutePosition.LEFT]: ToothPosition.DISTAL,
        [ToothAbsolutePosition.RIGHT]: ToothPosition.MESIAL
      }

    case 4:
      return {
        [ToothAbsolutePosition.TOP]: ToothPosition.LINGUAL,
        [ToothAbsolutePosition.BOTTOM]: ToothPosition.BUCCAL,
        [ToothAbsolutePosition.CENTER]: ToothPosition.OCCLUSAL,
        [ToothAbsolutePosition.LEFT]: ToothPosition.MESIAL,
        [ToothAbsolutePosition.RIGHT]: ToothPosition.DISTAL
      }
    default:
      throw new Error('Invalid quadrant')
  }
}
