import { BasePatient, CustomField, CustomFieldWithValue, PrimitiveCustomFieldType } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { isPrimitiveCustomField, isTextListCustomField } from '../CustomFields/utils'

/**
 * Form values interface
 */
export interface BasePatientFormValues {
  first_name?: string
  last_name?: string
  sex?: string
  birthdate?: Date
  email?: string
  phone_number?: string
  phone_number_e164?: string
  address?: string
  locality?: string
  postal_code?: string
  country?: string
  consent?: boolean
}

/**
 * mutation param interfaces
 */
interface NewPatientAddress {
  line_1?: string
  line_2?: string
  line_3?: string
  locality?: string
  postal_code?: string
  country: string
}

export interface PatientRegistrationParams {
  first_name: string
  last_name: string
  sex: string
  birthdate: string
  address?: NewPatientAddress
  email?: string
  phone_number?: string
  phone_number_e164?: string
  registration_start?: string
}

export enum AgeCategories {
  NEW_BORN = 'newBorn',
  NEONATE = 'neonate',
  INFANT = 'infant',
  TODDLER = 'toddler',
  PRESCHOOLER = 'preschooler',
  SCHOOL_AGED_CHILD = 'schoolAgedChild',
  PREADOLESCENT = 'preadolescent',
  ADOLESCENT_13_15 = 'adolescent13to15',
  ADOLESCENT_16_19 = 'adolescent16to19',
  ADULT = 'adult',
}

export const isValidAgeCategory = (value: string): boolean => {
  return (Object.values(AgeCategories) as Array<string>).includes(value)
}

export const getPatientName = (patient: BasePatient): string => {
  const patientName = `${patient.first_name} ${patient.last_name}`

  return patientName
}

export type CustomFieldValue = Nullable<number | boolean | string | Date>

export interface NestedPrimitiveCustomField {
  id: string
  name: string
  type: PrimitiveCustomFieldType
  required?: boolean
}

export interface NestedTextListCustomField {
  id: string
  name: string
  required?: boolean
  options: Array<string>
}

export interface NewCustomFieldBooleanWithValue {
  customField: NestedPrimitiveCustomField
  boolean: boolean
}

export interface NewCustomFieldNumberWithValue {
  customField: NestedPrimitiveCustomField
  number: number
}

export interface NewCustomFieldTextWithValue {
  customField: NestedPrimitiveCustomField
  text: string
}

export interface NewCustomFieldDateWithValue {
  customField: NestedPrimitiveCustomField
  date: string
}
export interface NewCustomFieldTextListWithValue {
  customField: NestedTextListCustomField
  textList: string
}

/**
 * These are used to send all the custom field that belong to the same
 * registration group in the same mutation.
 * Because we need to specify the type of everything we want to send to the
 * apollo server, and custom field may be of different types
 */
export type NewCustomFieldValue =
  | NewCustomFieldBooleanWithValue
  | NewCustomFieldNumberWithValue
  | NewCustomFieldTextWithValue
  | NewCustomFieldDateWithValue
  | NewCustomFieldTextListWithValue

export interface UpdateCustomFieldsParams {
  newCustomFieldBoolean: Array<NewCustomFieldBooleanWithValue>
  newCustomFieldNumber: Array<NewCustomFieldNumberWithValue>
  newCustomFieldText: Array<NewCustomFieldTextWithValue>
  newCustomFieldDate: Array<NewCustomFieldDateWithValue>
  newCustomFieldTextList: Array<NewCustomFieldTextListWithValue>
}

export const customFieldsToFormValues = (
  fields: Array<CustomField>,
  patientFields: Array<CustomFieldWithValue>
): Record<string, CustomFieldValue> => {
  /**
   * Convert an array of custom fields into a object to be used as the initialValues
   * by react-final-form
   */
  const reducer = (acc: Record<string, CustomFieldValue>, field: CustomField): Record<string, CustomFieldValue> => {
    const found = patientFields.find(({ customField }) => customField.id === field.id)
    if (isTextListCustomField(field)) {
      acc[field.id] = found ? found['textList'] : ''
    }

    if (isPrimitiveCustomField(field)) {
      switch (field.type) {
        case PrimitiveCustomFieldType.BOOLEAN:
          acc[field.id] = found ? found['boolean'] : false
          break

        case PrimitiveCustomFieldType.NUMBER:
          acc[field.id] = found ? found['number'] : null
          break

        case PrimitiveCustomFieldType.TEXT:
          acc[field.id] = found ? found['text'] : ''
          break

        case PrimitiveCustomFieldType.DATE:
          acc[field.id] = found && found['date'] ? new Date(found['date']) : null
          break

        default:
          break
      }
    }

    return acc
  }

  const initialValues = fields.reduce(reducer, {})

  return initialValues
}

/**
 * Take the custom field id from the form values
 * Get the custom field type from the corresponding system field
 * using the id
 * Format them to them be used in the graphql query
 */
export const formValuesToCustomFields = (
  fields: Array<CustomField>,
  formValues: Record<string, CustomFieldValue>
): UpdateCustomFieldsParams => {
  const initialValue: UpdateCustomFieldsParams = {
    newCustomFieldBoolean: [],
    newCustomFieldNumber: [],
    newCustomFieldText: [],
    newCustomFieldDate: [],
    newCustomFieldTextList: [],
  }

  const reducer = (acc: UpdateCustomFieldsParams, [key, value]) => {
    const found = fields.find((field) => field.id === key)
    if (found) {
      if (isTextListCustomField(found)) {
        // deconstruct to remove __typename
        const { id, name, required, options } = found as NestedTextListCustomField

        const newField: NewCustomFieldTextListWithValue = {
          customField: {
            id,
            name,
            required,
            options,
          },
          textList: value,
        }

        acc.newCustomFieldTextList.push(newField)
        return acc
      }

      // deconstruct to remove __typename
      const { id, name, required } = found as NestedPrimitiveCustomField
      switch (found.type) {
        case PrimitiveCustomFieldType.BOOLEAN: {
          const newField: NewCustomFieldBooleanWithValue = {
            customField: {
              id,
              name,
              required,
              type: PrimitiveCustomFieldType.BOOLEAN,
            },
            boolean: value,
          }

          acc.newCustomFieldBoolean.push(newField)
          return acc
        }

        case PrimitiveCustomFieldType.NUMBER: {
          const newField: NewCustomFieldNumberWithValue = {
            customField: {
              id,
              name,
              required,
              type: PrimitiveCustomFieldType.NUMBER,
            },
            number: Number(value),
          }

          acc.newCustomFieldNumber.push(newField)
          return acc
        }

        case PrimitiveCustomFieldType.TEXT: {
          const newField: NewCustomFieldTextWithValue = {
            customField: {
              id,
              name,
              required,
              type: PrimitiveCustomFieldType.TEXT,
            },
            text: value,
          }

          acc.newCustomFieldText.push(newField)
          return acc
        }

        case PrimitiveCustomFieldType.DATE: {
          const newField: NewCustomFieldDateWithValue = {
            customField: {
              id,
              name,
              required,
              type: PrimitiveCustomFieldType.DATE,
            },
            date: value,
          }

          acc.newCustomFieldDate.push(newField)
          return acc
        }

        default:
          return acc
      }
    }

    return acc
  }

  return Object.entries(formValues).reduce(reducer, initialValue)
}
