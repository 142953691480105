import { useIntl } from 'react-intl'
import { TranslatableText } from '../../api/interfaces'

export const useDynamicTranslation = () => {
  const intl = useIntl()

  const translate = (message: TranslatableText): string => {
    if(typeof message.text !== 'string' || message.text.length === 0){
      return ''
    }
    
    const prefix = 'Dynamic'
    const defaultMessage = (message.text as string).trim()
    const id = `${prefix}.${defaultMessage}`
    
    return intl.formatMessage({ id, defaultMessage })
  }

  return {
    translate,
  }
}

export const useTranslatedList = (items: Array<TranslatableText>): Array<string> => {
  const { translate } = useDynamicTranslation()
  return items.filter((item) => item.text !== null).map(translate)
}
