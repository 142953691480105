export enum Scenes {
  ACTIVE_VISIT = 'activeVisit',
  APP_CONTENT = 'appContent',
  AUTH = 'auth',
  BILLING = 'billing',
  METRICS = 'metrics',
  PATIENTS = 'patients',
  PREFERENCES = 'preferences',
  PRICING = 'pricing',
  SCHEDULER = 'scheduler',
  SETUP = 'setup',
  STAFF = 'staff',
  UNAUTH = 'unauth',
  VISITS = 'visits',
}
interface Route {
  path: string
  name: string
  roles?: Array<string>
}

interface Scene {
  path: string
  name: string
  routes: Record<string, Route>
  roles?: Array<string>
}

export type Sitemap = Record<Scenes, Scene>

export const sitemap = {
  [Scenes.ACTIVE_VISIT]: {
    name: 'Active visit',
    route: '/active-visit/:patientId',
    routes: {
      exam: {
        route: 'exam',
        pathBuilder: (patientId) => `/active-visit/${patientId}/exam`,
        name: 'Exam tab',
      },
      history: {
        route: 'history',
        pathBuilder: (patientId) => `/active-visit/${patientId}/history`,
        name: 'History tab',
      },
    },
  },
  [Scenes.APP_CONTENT]: {
    name: 'App content',
    route: '/app-content',
    routes: {
      subscriptions: {
        route: 'subscriptions',
        absolutePath: '/app-content/subscriptions',
        name: 'Dynamic content subscriptions',
      },
      registration: {
        route: 'registration',
        absolutePath: '/app-content/registration',
        name: 'Patient registration tab',
      },
    },
  },
  [Scenes.AUTH]: {
    name: 'Auth',
    route: '/auth',
    routes: {
      changePassword: {
        route: 'change-password',
        absolutePath: '/auth/change-password',
        name: 'Change password',
      },
      emailVerification: {
        route: 'email-verification',
        absolutePath: '/auth/email-verification',
        name: 'Verify email address thru TOTP code',
      },
      phoneVerification: {
        route: 'phone-number-verification',
        absolutePath: '/auth/phone-number-verification',
        name: 'Verify phone number thru TOTP code',
      },
    },
  },
  [Scenes.BILLING]: {
    name: 'Billing',
    route: '/billing',
    routes: {
      list: {
        route: 'list',
        absolutePath: '/billing/list',
        name: 'Bills',
      },
      bill: {
        route: ':visitId',
        pathBuilder: (visitId) => `/billing/${visitId}`,
        name: 'Bill',
      },
    },
  },
  [Scenes.METRICS]: {
    route: '/metrics',
    name: 'Metrics',
    routes: {
      dashboard: {
        route: 'dashboard',
        absolutePath: '/metrics/dashboard',
        name: 'Metrics dashboard',
      },
    },
  },
  [Scenes.PATIENTS]: {
    name: 'Patients',
    route: '/patients',
    routes: {
      history: {
        route: ':patientId/history',
        pathBuilder: (patientId) => `/patients/${patientId}/history`,
        name: 'History page',
      },
      list: {
        route: 'list',
        absolutePath: '/patients/list',
        name: 'Patient list',
      },
      profile: {
        route: ':patientId',
        pathBuilder: (patientId) => `/patients/${patientId}`,
        name: 'Patient profile',
      },
      registration: {
        route: 'new',
        absolutePath: '/patients/new',
        name: 'Patient registration',
      },
      waiting: {
        route: 'waiting',
        absolutePath: '/patients/waiting',
        name: 'Waiting patients',
      },
    },
  },
  [Scenes.PREFERENCES]: {
    name: 'Preferences',
    route: '/preferences',
    routes: {
      account: {
        route: 'account',
        absolutePath: '/preferences/account',
        name: 'User account preferences',
      },
      system: {
        route: 'system',
        absolutePath: '/preferences/system',
        name: 'System preferences',
      },
    },
  },
  [Scenes.PRICING]: {
    name: 'Pricing',
    route: '/pricing',
    routes: {
      pricelists: {
        route: 'pricelists',
        absolutePath: '/pricing/pricelists',
        name: 'Pricelists - payment methods',
      },
      prices: {
        route: 'prices',
        absolutePath: '/pricing/prices',
        name: 'Prices',
      },
    },
  },
  [Scenes.SCHEDULER]: {
    name: 'Scheduler',
    route: '/scheduler',
    routes: {
      calendar: {
        route: 'calendar',
        absolutePath: '/scheduler/calendar',
        name: 'Calendar',
      },
      rooms: {
        route: 'rooms',
        absolutePath: '/scheduler/rooms',
        name: 'Rooms',
      },
    },
  },
  [Scenes.SETUP]: {
    name: 'Setup',
    route: '/setup',
    routes: {
      checkout: {
        route: 'checkout',
        absolutePath: '/setup/checkout',
        name: 'Payment checkout',
      },
      location: {
        route: 'location',
        absolutePath: '/setup/location',
        name: 'Location',
      },
    },
  },
  [Scenes.STAFF]: {
    name: 'Staff',
    route: '/staff',
    routes: {
      list: {
        route: 'list',
        absolutePath: '/staff/list',
        name: 'Staff list',
      },
      profile: {
        route: ':userId',
        pathBuilder: (userId) => `/staff/${userId}`,
        name: 'User profile',
      },
    },
  },
  [Scenes.UNAUTH]: {
    route: '/unauth',
    name: 'Unauth scene',
    routes: {
      forgotPassword: {
        route: 'forgot-password',
        absolutePath: '/unauth/forgot-password',
        name: 'Forgot password',
      },
      login: {
        route: 'login',
        absolutePath: '/unauth/login',
        name: 'Log In',
      },
      mfa: {
        route: 'multi-factor-authentication',
        absolutePath: '/unauth/multi-factor-authentication',
        name: 'Multi-factor Authentication',
      },
      passwordRestore: {
        route: 'password-restore',
        absolutePath: '/unauth/password-restore',
        name: 'Password restore',
      },
      signup: {
        route: 'signup',
        absolutePath: '/unauth/signup',
        name: 'Sign Up',
      },
      signUpConfirm: {
        route: 'signup-confirm',
        absolutePath: '/unauth/signup-confirm',
        name: 'Sign up confirm',
      },
    },
  },
  [Scenes.VISITS]: {
    name: 'Visits',
    route: '/visits',
    routes: {
      past: {
        route: 'past',
        absolutePath: '/visits/past',
        name: 'Past visits',
      },
    },
  },
}
