import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { UpdateClinicalStatusVariables } from '.'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { UPDATE_CLINICAL_STATUS } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  invalidClinicalStatus: {
    id: 'PatientStatus.invalid_clinical_status',
    defaultMessage: 'Invalid clinical status',
  },
  invalidChiefComplaint: {
    id: 'PatientStatus.invalid_chief_complaint',
    defaultMessage: 'Invalid chief complaint',
  },
  patientNotFound: {
    id: 'PatientStatus.patient_not_found',
    defaultMessage: 'Patient not found',
  },
  errorUpdatingStatus: {
    id: 'PatientStatus.error_updating_status',
    defaultMessage: 'Could not update the status. Please, try again',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface UpdateClinicalStatusMutationData {
  updateClinicalStatus: FullPatientWithActiveVisit
}

type Params = MutationHookOptions<
  UpdateClinicalStatusMutationData,
  UpdateClinicalStatusVariables
> & {
  patientId: string
  visitId: string
}

export const useUpdateClinicalStatus = (params: Params) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'invalid_clinical_status':
        return setError(intl.formatMessage(messages.invalidClinicalStatus))

      case 'invalid_chief_complaint':
        return setError(intl.formatMessage(messages.invalidChiefComplaint))

      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_updating_clinical_status':
        return setError(intl.formatMessage(messages.errorUpdatingStatus))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [updateClinicalStatus, { loading: isLoading }] = useMutation(UPDATE_CLINICAL_STATUS, {
    onError,
    ...restParams,
  })

  const onClinicalStatusChange = (priority) => {
    setError(null)

    if (priority) {
      const variables = {
        patientId,
        visitId,
        clinicalStatus: {
          priority,
        },
      }

      updateClinicalStatus({ variables })
    }
  }

  const onErrorClose = () => {
    setError(null)
  }
  return {
    onClinicalStatusChange,
    isLoading,
    error,
    onErrorClose,
  }
}
