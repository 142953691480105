import { Box, Center, FormLabel, IconButton, List, ListItem, Text } from '@chakra-ui/react'
import React from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import { defineMessages, useIntl } from 'react-intl'
import {
  RiDeleteBin2Line as DeleteIcon,
} from 'react-icons/ri'
import { CustomFieldSelector } from '../Selector'
import { CustomField } from '../../api/interfaces'
import { useCustomFields } from '../CustomFields'

const messages = defineMessages({
  options: {
    id: 'CustomFieldsTab.options',
    defaultMessage: 'Options'
  },
  noOptionsFound: {
    id: 'CustomFieldsTab.no_options_found',
    defaultMessage: 'No options found'
  },
  addOption: {
    id: 'CustomFieldsTab.add_options',
    defaultMessage: 'Add a new option'
  }
})

interface CustomFieldListFieldProps {
  name: string
  label: string
  initialValue?: Array<string>
  hideLabel?: boolean
}

/**
 * Bad name
 * This field is used inside of a form
 * and allow the customer to create a text list
 * from the existing custom fields in their system
 * using their ids
 */
export const CustomFieldListField: React.FC<CustomFieldListFieldProps> = props => {
  const { name, hideLabel, label, initialValue } = props

  const intl = useIntl()
  const { getCustomField } = useCustomFields()

  const { fields, meta } = useFieldArray(name, {
    subscription: { error: true, touched: true, value: true },
    initialValue
  })

  const onChange = (customFieldId: string) => {
    if (customFieldId) {
      fields.push(customFieldId)
    }
  }

  const onRemove = (index: number) => {
    fields.remove(index)
  }

  const values = (fields.value ?? [])
  const items = values
    .map(id => getCustomField(id))
    .filter(item => item !== null)

  const filterOptions = (field: CustomField): boolean => {
    const found = values.includes(field.id)

    return !found
  }

  const controlProps = {
    p: 2,
    borderRadius: 'md',
    borderWidth: 1,
    borderColor: (meta.error && meta.touched) ? 'red.400' : 'inherit'
  }


  return <>
    {!hideLabel && <FormLabel htmlFor={name}>{label}</FormLabel>}
    <Box {...controlProps}  >
      {
        items.length === 0
          ? <Center my={2}>
            <Text color={(meta.error && meta.touched) ? 'red.400' : 'inherit'}
            >{intl.formatMessage(messages.noOptionsFound)}</Text>
          </Center>
          : <List >
            {
              items.map((customField, i) => {
                const { id, name } = customField as CustomField

                const key = `${id}-${i}`

                const onClick = () => {
                  onRemove(i)
                }

                return <ListItem
                  key={key}
                  borderBottomWidth={1}
                  p={1}
                  display='flex'
                  justifyContent={'space-between'}
                  alignItems='center'
                >
                  <Text>{name}</Text>
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={onClick}
                    type='button'
                    aria-label={'expand all'}
                    size='sm'
                    variant={'ghost'}
                    colorScheme={'red'}
                  />
                </ListItem>
              })
            }
          </List>
      }
      <Box mt={3} >
        <CustomFieldSelector
          value={''}
          onChange={onChange}
          filter={filterOptions}
        />
      </Box>
    </Box >
  </>
}