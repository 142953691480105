import React from 'react'
import { RiQuestionFill as HelpIcon } from 'react-icons/ri'
import {
  ButtonProps,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  VStack,
  Link,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { Locales } from '../i18n'
import { useSession } from '../Auth'

interface HelpDescriptor {
  link: string
  label: string
}

const HELP: Record<Locales, Array<HelpDescriptor>> = {
  [Locales.EN_US]: [
    { link: 'https://youtu.be/g9uu7nQ_t0I', label: '<b>Billing</b> patients' },
    {
      link: 'https://youtu.be/3bCY33wrZCw',
      label: '<b>Charting</b> patient visits',
    },
    { link: 'https://youtu.be/_ihB-mi6P-M', label: 'Managing <b>Subscriptions</b>' },
    { link: 'https://youtu.be/u8usSLruQIA', label: 'Patient <b>Weight</b> trending' },
    { link: 'https://youtu.be/tHBiu8R9_fs', label: 'Patient <b>Surveys</b>' },
  ],
  [Locales.ES_MX]: [],
  [Locales.FR_FR]: [],
  [Locales.ID_ID]: [],
}

export const HelpReferences: React.FC<ButtonProps> = (props) => {
  const { isOpen, onToggle } = useDisclosure()
  const { getUserLocale } = useSession()

  const selectedLocale = getUserLocale()
  const helpItems = HELP[selectedLocale]

  return (
    <>
      <Button {...props} onClick={onToggle}>
        <Icon as={HelpIcon} fontSize="2xl" />
      </Button>
      <Modal isOpen={isOpen} onClose={onToggle}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Help Topics</ModalHeader>
          <ModalBody>
            <VStack alignItems={'flex-start'}>
              {helpItems.map((item, index) => {
                const key = `help-${index}-${item.label}`

                return (
                  <ReferenceLink url={item.link} key={key}>
                    <Text dangerouslySetInnerHTML={{ __html: item.label }} />
                  </ReferenceLink>
                )
              })}
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent={'flex-end'}>
            <Button onClick={onToggle}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

interface ReferenceLinkProps {
  url: string
}

const ReferenceLink: React.FC<ReferenceLinkProps> = (props) => {
  return (
    <Link isExternal href={props.url} rel="noopener noreferrer" target="_blank">
      {props.children}
    </Link>
  )
}
