import {
  PrimitiveCustomField,
  PrimitiveCustomFieldType,
  TextListCustomField,
} from '../../api/interfaces'

export const isValidPrimitiveCustomFieldType = (type: string) => {
  return (Object.values(PrimitiveCustomFieldType) as Array<string>).includes(type)
}

export function isPrimitiveCustomField(field: unknown): field is PrimitiveCustomField {
  if (field === null || field === undefined) {
    return false
  }

  if (typeof field !== 'object') {
    return false
  }

  if (Object.keys(field as object).length === 0) {
    return false
  }

  return typeof field['type'] === 'string'
}

export function isTextListCustomField(field: unknown): field is TextListCustomField {
  if (field === null || field === undefined) {
    return false
  }

  if (typeof field !== 'object') {
    return false
  }

  if (Object.keys(field as object).length === 0) {
    return false
  }

  if (!Array.isArray(field['options'])) {
    return false
  }

  return field['options'].length > 0 && typeof field['options'][0] === 'string'
}
