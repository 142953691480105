import { Heading, HStack, Stack, Text, useBreakpointValue, VStack } from '@chakra-ui/react'
import { DateTime } from 'luxon'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { VisitTypeTranslation } from '.'
import { ActiveVisit, BasePatient } from '../../api/interfaces'
import {
  PrintableAssessmentAndDisposition,
  PrintableClinicalStatus,
  PrintableDiagnosisChecklists,
  PrintableGeneralReviewOfSystem,
  PrintableHistoryOfPresentIllness,
  PrintablePhysicalExam,
  PrintableChiefComplaint,
  PrintableVitals,
  PrintableProgressNotes,
  PdfClinicalStatus,
  PdfVitals,
  PatientEducation,
  PdfPatientEducation,
  PrintableAssessments,
} from '../../components/ActiveVisit'
import { PrintableTreatments } from '../ActiveVisit/Treatments'
import { PrintablePatientInfo } from '../Patients'
import { ClinicInfo, PrintableClinicInfo } from '../Systems'

const messages = defineMessages({
  medicalRecordTitle: {
    id: 'VisitResume.medical_record_title',
    defaultMessage: 'Health Record',
  },
  visitDateLabel: {
    id: 'VisitResume.visit_date_label',
    defaultMessage: 'Visit Date',
  },
  visitType: {
    id: 'PatientStatus.visit_type_label',
    defaultMessage: 'Visit type',
  },
  treatmentTitle: {
    id: 'VisitResume.treatment_title',
    defaultMessage: 'Treatment',
  },
})

interface VisitResumeModalProps {
  patient: BasePatient
  visit: ActiveVisit
}

// eslint-disable-next-line
export const VisitResume = React.forwardRef<HTMLDivElement, VisitResumeModalProps>((props, ref) => {
  const { patient, visit } = props

  const intl = useIntl()
  const isInline = useBreakpointValue({ base: false, sm: true })

  const {
    checklists,
    chief_complaint,
    clinical_status,
    disposition,
    exam,
    followup,
    history,
    progress_notes,
    status_assesment,
    vitals,
    start,
    external_referral_location,
    external_referral_reason,
    type,
    assessments,
    treatments,
  } = visit

  const visitDate = DateTime.fromJSDate(new Date(start))

  const stackProps = {
    width: isInline ? '50%' : '100%',
    alignItems: 'start',
    px: 2,
    sx: {
      '@media print': {
        flex: '0 0 50%',
        width: '50%',
        display: 'block',
      },
    },
    spacing: 3,
  }

  return (
    <Stack isInline={isInline} alignItems={'start'} sx={PRINTING_STYLES}>
      <VStack {...stackProps}>
        <VStack alignItems={'start'} spacing={0}>
          <Heading>{intl.formatMessage(messages.medicalRecordTitle)}</Heading>
          <ClinicInfo />
        </VStack>
        <PrintablePatientInfo patient={patient} />

        <VStack alignItems={'start'} spacing={0}>
          {visitDate.isValid && (
            <HStack spacing={2}>
              <Text fontWeight={'bold'}>{intl.formatMessage(messages.visitDateLabel)}:</Text>
              <Text>{visitDate.toLocaleString(DateTime.DATETIME_MED)}</Text>
            </HStack>
          )}

          <HStack spacing={2}>
            <Text fontWeight={'bold'}>{intl.formatMessage(messages.visitType)}:</Text>
            <Text>
              <VisitTypeTranslation as={'span'} type={type} />
            </Text>
          </HStack>
          <PrintableChiefComplaint chiefComplaint={chief_complaint} />
        </VStack>

        <PrintableClinicalStatus clinicalStatus={clinical_status} />

        <PrintableHistoryOfPresentIllness checklists={checklists} history={history} />

        <PrintableGeneralReviewOfSystem
          generalReviewOfSystems={history?.generalReviewOfSystems ?? null}
          checklists={checklists ?? []}
        />
      </VStack>
      <VStack {...stackProps}>
        <PrintableVitals birthdate={patient.birthdate} sex={patient.sex} vitals={vitals} />

        <PrintablePhysicalExam exam={exam} checklists={checklists} />

        <PrintableProgressNotes items={progress_notes ?? []} />

        <PrintableDiagnosisChecklists checklists={checklists ?? []} />

        <PrintableAssessments items={assessments ?? []} />

        <Heading size={'md'} borderBottomWidth={1} style={{ width: '100%' }}>
          {intl.formatMessage(messages.treatmentTitle)}
        </Heading>

        <PrintableTreatments checklists={checklists ?? []} items={treatments ?? []} />

        <PatientEducation checklists={checklists ?? []} />

        <PrintableAssessmentAndDisposition
          followUp={followup}
          disposition={disposition}
          statusAssesment={status_assesment}
          externalReferralLocation={external_referral_location}
          externalReferralReason={external_referral_reason}
        />
      </VStack>
      <PrintableVisitResume visit={visit} patient={patient} ref={ref} />
    </Stack>
  )
})

type PrintableVisitResumeProps = {
  patient: BasePatient
  visit: ActiveVisit
}

const PrintableVisitResume = React.forwardRef<HTMLDivElement, PrintableVisitResumeProps>((props, ref) => {
  const { patient, visit } = props

  const {
    checklists,
    chief_complaint,
    clinical_status,
    disposition,
    exam,
    followup,
    history,
    progress_notes,
    status_assesment,
    vitals,
    start,
    type,
    external_referral_location,
    external_referral_reason,
    assessments,
    treatments,
  } = visit

  const visitDate = DateTime.fromJSDate(new Date(start))

  const intl = useIntl()

  return (
    <div ref={ref} className="printable" style={{ width: '100%', display: 'flex' }}>
      <div style={{ width: '50%', display: 'flex', flexDirection: 'column', margin: '0 6px' }}>
        <span style={{ fontSize: '32px', fontWeight: 'bold' }}>{intl.formatMessage(messages.medicalRecordTitle)}</span>
        <PrintableClinicInfo />
        <PrintablePatientInfo patient={patient} />
        {visitDate.isValid && (
          <div>
            <b>{intl.formatMessage(messages.visitDateLabel)}: </b>
            <span>{visitDate.toLocaleString(DateTime.DATETIME_MED)}</span>
          </div>
        )}

        <div>
          <b>{intl.formatMessage(messages.visitType)}: </b>
          <VisitTypeTranslation as={'span'} type={type} />
        </div>
        <div style={{ margin: '6px 0' }} />

        <PrintableChiefComplaint chiefComplaint={chief_complaint} />

        <PdfClinicalStatus clinicalStatus={clinical_status} />

        <PrintableHistoryOfPresentIllness checklists={checklists} history={history} />

        <PrintableGeneralReviewOfSystem
          generalReviewOfSystems={history?.generalReviewOfSystems ?? null}
          checklists={checklists ?? []}
        />
      </div>
      <div style={{ width: '50%', margin: '0 6px' }}>
        <PdfVitals birthdate={patient.birthdate} sex={patient.sex} vitals={vitals} />

        <PrintablePhysicalExam exam={exam} checklists={checklists} />

        <PrintableProgressNotes items={progress_notes ?? []} />

        <PrintableDiagnosisChecklists checklists={checklists ?? []} />

        <PrintableAssessments items={assessments ?? []} />

        <Heading size={'md'} borderBottomWidth={1} style={{ width: '100%' }}>
          {intl.formatMessage(messages.treatmentTitle)}
        </Heading>

        <PrintableTreatments checklists={checklists ?? []} items={treatments ?? []} />

        <PdfPatientEducation checklists={checklists ?? []} />

        <PrintableAssessmentAndDisposition
          followUp={followup}
          disposition={disposition}
          statusAssesment={status_assesment}
          externalReferralLocation={external_referral_location}
          externalReferralReason={external_referral_reason}
        />
      </div>
    </div>
  )
})

PrintableVisitResume.displayName = 'PrintableVisitResume'

const PRINTING_STYLES = {
  '@media print': {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    lineHeight: '18px',
    color: 'black',
    background: 'white',
    borderWidth: 0,
  },
}
