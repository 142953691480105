import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { GET_PRICES } from '../../api/requests/pricing'
import { PaginatedResult, Price } from '../../api/interfaces'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { usePricelists } from './usePricelists'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface PricesVariables {
  category?: string | Array<string>
  pricelistId?: string | Array<string>
  billingCode?: string | Array<string>
}

interface PricesQueryData {
  prices: PaginatedResult<Price>
}

export type UsePricesParams = QueryHookOptions<PricesQueryData, PricesVariables> & {
  category?: string | Array<string>
  billingCode?: string | Array<string>
}

export const usePrices = (params: UsePricesParams) => {
  const { category, billingCode, ...rest } = params

  const intl = useIntl()
  const { pricelists } = usePricelists()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const variables = {
    category: category && !Array.isArray(category) ? [category] : category,
    pricelistId: pricelists.map((item) => item.id),
    billingCode: billingCode && !Array.isArray(billingCode) ? [billingCode] : billingCode,
  }

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery<PricesQueryData, PricesVariables>(GET_PRICES, {
    variables,
    onError,
    ...rest,
    skip: !category || pricelists.length === 0,
  })

  const getPrice = React.useCallback(
    (category: string, pricelistId?: string, billingCode?: string) => {
      if (!data?.prices?.docs) {
        return null
      }

      const found = data.prices.docs.find(
        (price) =>
          price.category === category &&
          price.pricelistId === pricelistId &&
          price.billingCode === billingCode
      )

      return found ?? null
    },
    [data?.prices.docs]
  )

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading,
    prices: data?.prices?.docs ?? [],
    count: data?.prices?.count ?? 0,
    getPrice,
    error,
    onErrorClose,
    refetch,
  }
}
export default usePrices
