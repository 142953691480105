import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { ValidateFn, ValidationError, isSecure } from '../Form'
import { ResetPasswordParams, useSession } from '.'
import { Nullable } from '../../utils'
import { sitemap } from '../Routing/sitemap'

const messages = defineMessages({
  required: {
    id: 'PasswordReset.this_field_is_required',
    defaultMessage: 'This field is required',
  },
  invalidPassword: {
    id: 'StaffRegister.insecure_password',
    defaultMessage: 'Choose a more secure password',
  },
  invalidRePassword: {
    id: 'PasswordReset.invalid_re_password',
    defaultMessage: 'The passwords do not match',
  },
  limitExceededException: {
    id: 'PasswordReset.attempt_limit_exceeded',
    defaultMessage: 'Attempt limit exceeded, please try after some time.',
  },
  invalidCodeError: {
    id: 'PasswordReset.invalid_code_provided',
    defaultMessage: 'Invalid code provided, please request a code again',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})
interface ResetPasswordFormValues {
  username: string
  password: string
  rePassword: string
  code: string
}

export const usePasswordRestore = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [isReSendingCode, setIsReSendingCode] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<Nullable<string>>(null)

  const { resetPassword, resendConfirmationCode, getUsername } = useSession()
  const username = getUsername()
  const initialValues = {
    username,
  }

  const onError = (code) => {
    switch (code) {
      case 'ExpiredCodeException':
        setError(intl.formatMessage(messages.invalidCodeError))
        break
      case 'LimitExceededException':
        setError(intl.formatMessage(messages.limitExceededException))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const validate: ValidateFn<ResetPasswordFormValues> = async (values) => {
    const errors: ValidationError<ResetPasswordFormValues> = {}

    if (!values.password) {
      errors.password = intl.formatMessage(messages.required)
    } else {
      const otherUserInputs = [values.username]
      const isPasswordSecure = await isSecure(values.password, otherUserInputs)

      if (!isPasswordSecure) {
        errors.password = intl.formatMessage(messages.invalidPassword)
      }
    }

    if (values.password !== values.rePassword) {
      errors.rePassword = intl.formatMessage(messages.invalidRePassword)
    }

    if (!values.code) {
      errors.code = intl.formatMessage(messages.required)
    }
    return errors
  }

  const onSubmit = async (values: ResetPasswordFormValues) => {
    setIsLoading(true)
    const params: ResetPasswordParams = {
      newPassword: values.rePassword,
      verificationCode: values.code,
    }

    try {
      await resetPassword(params)
      navigate(sitemap.unauth.routes.login.absolutePath)
    } catch (e) {
      return onError(e)
    }
  }

  const reSendCode = async () => {
    setIsReSendingCode(true)
    try {
      await resendConfirmationCode()
    } catch (e) {
      onError(e)
    }
    setIsReSendingCode(false)
  }

  const onCancel = () => {
    navigate(sitemap.unauth.routes.login.absolutePath)
  }

  React.useEffect(() => {
    if (username === null) {
      navigate(sitemap.unauth.routes.forgotPassword.absolutePath)
    }
  }, [username, navigate])

  return {
    onCancel,
    onSubmit,
    isLoading,
    error,
    validate,
    reSendCode,
    initialValues,
    isReSendingCode,
  }
}
