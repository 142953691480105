import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { FullComment } from '../../api/interfaces'
import { useRemoveProgressNote } from '../Visit'
import { ConfirmButton, PrintableComments, SignatureTag } from '..'
import { Box, BoxProps, Flex, Heading, HStack, Text } from '@chakra-ui/layout'
import Icon from '@chakra-ui/icon'
import { Collapse, Fade } from '@chakra-ui/transition'
import { useDisclosure } from '@chakra-ui/hooks'
import { IconButton } from '@chakra-ui/button'
import { RiArrowRightSLine as ExpandButton } from 'react-icons/ri'
import { RiDeleteBin2Line as DeleteIcon } from 'react-icons/ri'

const messages = defineMessages({
  progressNotes: {
    id: 'ProgressNotes.title',
    defaultMessage: 'Progress Notes',
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  moreItems: {
    id: 'UI.label_more_items',
    defaultMessage: '... {count} hidden item/s',
  },
  confirmationMessage: {
    id: 'ProgressNotes.removing_confirmation_message',
    defaultMessage: 'This item will permanently delete this note. Are you sure?',
  },
})

type ProgressNotesProps = BoxProps & {
  patientId: string
  visitId: string
  items: Array<FullComment>
  isDisabled?: boolean
}

export const ProgressNotes: React.FC<ProgressNotesProps> = (props) => {
  const { items, patientId, visitId, isDisabled, ...boxProps } = props

  const intl = useIntl()

  const { isOpen, onToggle } = useDisclosure()

  const firstTwoItems = items.slice(0, 2)
  const restItems = items.length > 2 ? items.slice(2) : []
  const showExpandButton = restItems.length > 0

  return (
    <Box {...boxProps}>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Heading size={'md'} mb={1} onClick={onToggle} cursor="pointer">
          {intl.formatMessage(messages.progressNotes)}
        </Heading>
        {showExpandButton && (
          <IconButton
            size="sm"
            aria-label={'expand all'}
            colorScheme={'brand'}
            variant="ghost"
            onClick={onToggle}
            icon={<ExpandButton />}
            sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}
          />
        )}
      </HStack>
      {firstTwoItems.map((progressNote) => (
        <ProgressNote
          key={progressNote.id}
          patientId={patientId}
          visitId={visitId}
          progressNote={progressNote}
          isDisabled={isDisabled}
        />
      ))}
      {showExpandButton && (
        <Collapse startingHeight={0} in={isOpen} animateOpacity>
          {restItems.map((progressNote) => (
            <ProgressNote
              key={progressNote.id}
              patientId={patientId}
              visitId={visitId}
              progressNote={progressNote}
              isDisabled={isDisabled}
            />
          ))}
        </Collapse>
      )}
      {showExpandButton && (
        <Fade in={!isOpen}>
          <Flex justifyContent={'center'}>
            <Text
              fontSize={'xs'}
              cursor={'pointer'}
              onClick={onToggle}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {intl.formatMessage(messages.moreItems, { count: items.length - 2 })}
            </Text>
          </Flex>
        </Fade>
      )}
    </Box>
  )
}

interface ProgressNoteProps {
  patientId: string
  visitId: string
  progressNote: FullComment
  isDisabled?: boolean
}

export const ProgressNote: React.FC<ProgressNoteProps> = (props) => {
  const { patientId, visitId, progressNote, isDisabled } = props

  const intl = useIntl()

  const { id, text, created } = progressNote
  const params = {
    visitId,
    patientId,
  }

  const { onDelete, isLoading, error, onErrorClose } = useRemoveProgressNote(params)

  return (
    <HStack justifyContent={'space-between'} mt={1} p={2} borderWidth={1} borderRadius="lg">
      <Text>
        <SignatureTag signature={created} mr={1} />
        {text}
      </Text>
      <ConfirmButton
        onConfirm={() => onDelete(id)}
        message={intl.formatMessage(messages.confirmationMessage)}
        buttonProps={{
          size: 'sm',
          color: 'red.400',
          label: <Icon as={DeleteIcon} />,
          isDisabled,
          isLoading,
        }}
        error={error}
        onErrorClose={onErrorClose}
      />
    </HStack>
  )
}

type PrintableProgressNotesProps = BoxProps & {
  items: Array<FullComment>
}

export const PrintableProgressNotes: React.FC<PrintableProgressNotesProps> = (props) => {
  const { items, ...boxProps } = props
  const intl = useIntl()

  if (items.length === 0) {
    return null
  }
  return (
    <Box w={'100%'} {...boxProps}>
      <Heading
        size={'md'}
        mb={1}
        borderBottomWidth={1}
        sx={{
          '@media print': {
            margin: '0 0 .5rem 0',
            borderBottom: '1px solid black',
          },
        }}
      >
        {intl.formatMessage(messages.progressNotes)}
      </Heading>
      <PrintableComments items={items} />
    </Box>
  )
}
