import { gql } from '@apollo/client'

export const FullPrimitiveCustomField = gql`
  fragment FullPrimitiveCustomField on PrimitiveCustomField {
    id
    name
    type
    required
  }
`

export const FullTextListCustomField = gql`
  fragment FullTextListCustomField on TextListCustomField {
    id
    name
    options
    required
  }
`

export const FullCustomFieldTextListWithValue = gql`
  fragment FullCustomFieldTextListWithValue on CustomFieldTextListWithValue {
    customField {
      id
      name
      required
      options
    }
    textList
  }
`

export const FullCustomFieldBooleanWithValue = gql`
  fragment FullCustomFieldBooleanWithValue on CustomFieldBooleanWithValue {
    customField {
      id
      name
      required
      type
    }
    boolean
  }
`

export const FullCustomFieldNumberWithValue = gql`
  fragment FullCustomFieldNumberWithValue on CustomFieldNumberWithValue {
    customField {
      id
      name
      required
      type
    }
    number
  }
`

export const FullCustomFieldDateWithValue = gql`
  fragment FullCustomFieldDateWithValue on CustomFieldDateWithValue {
    customField {
      id
      name
      required
      type
    }
    date
  }
`

export const FullCustomFieldTextWithValue = gql`
  fragment FullCustomFieldTextWithValue on CustomFieldTextWithValue {
    customField {
      id
      name
      required
      type
    }
    text
  }
`
