// Child growth standards
// Head Circumference reference values for BOYS between 0 - 60 months
// Data taken from World Health Organization and then parsed to JSON
// Links:
// https://www.who.int/childgrowth/standards/second_set/tab_hcfa_boys_p_0_13.txt (0 - 13 weeks)
// https://www.who.int/childgrowth/standards/second_set/tab_hcfa_boys_p_0_5.txt  (0 - 60 months)

const hcValues = [
  {
    week: 0,
    l: 1,
    m: 34.4618,
    s: 0.03686,
    sd: 1.27026,
    p01: 30.5,
    p1: 31.5,
    p3: 32.1,
    p5: 32.4,
    p10: 32.8,
    p15: 33.1,
    p25: 33.6,
    p50: 34.5,
    p75: 35.3,
    p85: 35.8,
    p90: 36.1,
    p95: 36.6,
    p97: 36.9,
    p99: 37.4,
    p999: 38.4
  },
  {
    week: 1,
    l: 1,
    m: 35.1634,
    s: 0.03472,
    sd: 1.22087,
    p01: 31.4,
    p1: 32.3,
    p3: 32.9,
    p5: 33.2,
    p10: 33.6,
    p15: 33.9,
    p25: 34.3,
    p50: 35.2,
    p75: 36,
    p85: 36.4,
    p90: 36.7,
    p95: 37.2,
    p97: 37.5,
    p99: 38,
    p999: 38.9
  },
  {
    week: 2,
    l: 1,
    m: 35.8649,
    s: 0.03258,
    sd: 1.16848,
    p01: 32.3,
    p1: 33.1,
    p3: 33.7,
    p5: 33.9,
    p10: 34.4,
    p15: 34.7,
    p25: 35.1,
    p50: 35.9,
    p75: 36.7,
    p85: 37.1,
    p90: 37.4,
    p95: 37.8,
    p97: 38.1,
    p99: 38.6,
    p999: 39.5
  },
  {
    week: 3,
    l: 1,
    m: 36.5216,
    s: 0.03197,
    sd: 1.1676,
    p01: 32.9,
    p1: 33.8,
    p3: 34.3,
    p5: 34.6,
    p10: 35,
    p15: 35.3,
    p25: 35.7,
    p50: 36.5,
    p75: 37.3,
    p85: 37.7,
    p90: 38,
    p95: 38.4,
    p97: 38.7,
    p99: 39.2,
    p999: 40.1
  },
  {
    week: 4,
    l: 1,
    m: 37.0926,
    s: 0.03148,
    sd: 1.16768,
    p01: 33.5,
    p1: 34.4,
    p3: 34.9,
    p5: 35.2,
    p10: 35.6,
    p15: 35.9,
    p25: 36.3,
    p50: 37.1,
    p75: 37.9,
    p85: 38.3,
    p90: 38.6,
    p95: 39,
    p97: 39.3,
    p99: 39.8,
    p999: 40.7
  },
  {
    week: 5,
    l: 1,
    m: 37.601,
    s: 0.03107,
    sd: 1.16826,
    p01: 34,
    p1: 34.9,
    p3: 35.4,
    p5: 35.7,
    p10: 36.1,
    p15: 36.4,
    p25: 36.8,
    p50: 37.6,
    p75: 38.4,
    p85: 38.8,
    p90: 39.1,
    p95: 39.5,
    p97: 39.8,
    p99: 40.3,
    p999: 41.2
  },
  {
    week: 6,
    l: 1,
    m: 38.0609,
    s: 0.03072,
    sd: 1.16923,
    p01: 34.4,
    p1: 35.3,
    p3: 35.9,
    p5: 36.1,
    p10: 36.6,
    p15: 36.8,
    p25: 37.3,
    p50: 38.1,
    p75: 38.8,
    p85: 39.3,
    p90: 39.6,
    p95: 40,
    p97: 40.3,
    p99: 40.8,
    p999: 41.7
  },
  {
    week: 7,
    l: 1,
    m: 38.4824,
    s: 0.03041,
    sd: 1.17025,
    p01: 34.9,
    p1: 35.8,
    p3: 36.3,
    p5: 36.6,
    p10: 37,
    p15: 37.3,
    p25: 37.7,
    p50: 38.5,
    p75: 39.3,
    p85: 39.7,
    p90: 40,
    p95: 40.4,
    p97: 40.7,
    p99: 41.2,
    p999: 42.1
  },
  {
    week: 8,
    l: 1,
    m: 38.8724,
    s: 0.03014,
    sd: 1.17161,
    p01: 35.3,
    p1: 36.1,
    p3: 36.7,
    p5: 36.9,
    p10: 37.4,
    p15: 37.7,
    p25: 38.1,
    p50: 38.9,
    p75: 39.7,
    p85: 40.1,
    p90: 40.4,
    p95: 40.8,
    p97: 41.1,
    p99: 41.6,
    p999: 42.5
  },
  {
    week: 9,
    l: 1,
    m: 39.2368,
    s: 0.0299,
    sd: 1.17318,
    p01: 35.6,
    p1: 36.5,
    p3: 37,
    p5: 37.3,
    p10: 37.7,
    p15: 38,
    p25: 38.4,
    p50: 39.2,
    p75: 40,
    p85: 40.5,
    p90: 40.7,
    p95: 41.2,
    p97: 41.4,
    p99: 42,
    p999: 42.9
  },
  {
    week: 10,
    l: 1,
    m: 39.5797,
    s: 0.02969,
    sd: 1.17512,
    p01: 35.9,
    p1: 36.8,
    p3: 37.4,
    p5: 37.6,
    p10: 38.1,
    p15: 38.4,
    p25: 38.8,
    p50: 39.6,
    p75: 40.4,
    p85: 40.8,
    p90: 41.1,
    p95: 41.5,
    p97: 41.8,
    p99: 42.3,
    p999: 43.2
  },
  {
    week: 11,
    l: 1,
    m: 39.9033,
    s: 0.0295,
    sd: 1.17715,
    p01: 36.3,
    p1: 37.2,
    p3: 37.7,
    p5: 38,
    p10: 38.4,
    p15: 38.7,
    p25: 39.1,
    p50: 39.9,
    p75: 40.7,
    p85: 41.1,
    p90: 41.4,
    p95: 41.8,
    p97: 42.1,
    p99: 42.6,
    p999: 43.5
  },
  {
    week: 12,
    l: 1,
    m: 40.2096,
    s: 0.02933,
    sd: 1.17935,
    p01: 36.6,
    p1: 37.5,
    p3: 38,
    p5: 38.3,
    p10: 38.7,
    p15: 39,
    p25: 39.4,
    p50: 40.2,
    p75: 41,
    p85: 41.4,
    p90: 41.7,
    p95: 42.1,
    p97: 42.4,
    p99: 43,
    p999: 43.9
  },
  {
    week: 13,
    l: 1,
    m: 40.5008,
    s: 0.02918,
    sd: 1.18181,
    p01: 36.8,
    p1: 37.8,
    p3: 38.3,
    p5: 38.6,
    p10: 39,
    p15: 39.3,
    p25: 39.7,
    p50: 40.5,
    p75: 41.3,
    p85: 41.7,
    p90: 42,
    p95: 42.4,
    p97: 42.7,
    p99: 43.3,
    p999: 44.2
  },
  {
    month: 0,
    l: 1,
    m: 34.4618,
    s: 0.03686,
    sd: 1.27026,
    p01: 30.5,
    p1: 31.5,
    p3: 32.1,
    p5: 32.4,
    p10: 32.8,
    p15: 33.1,
    p25: 33.6,
    p50: 34.5,
    p75: 35.3,
    p85: 35.8,
    p90: 36.1,
    p95: 36.6,
    p97: 36.9,
    p99: 37.4,
    p999: 38.4
  },
  {
    month: 1,
    l: 1,
    m: 37.2759,
    s: 0.03133,
    sd: 1.16785,
    p01: 33.7,
    p1: 34.6,
    p3: 35.1,
    p5: 35.4,
    p10: 35.8,
    p15: 36.1,
    p25: 36.5,
    p50: 37.3,
    p75: 38.1,
    p85: 38.5,
    p90: 38.8,
    p95: 39.2,
    p97: 39.5,
    p99: 40,
    p999: 40.9
  },
  {
    month: 2,
    l: 1,
    m: 39.1285,
    s: 0.02997,
    sd: 1.17268,
    p01: 35.5,
    p1: 36.4,
    p3: 36.9,
    p5: 37.2,
    p10: 37.6,
    p15: 37.9,
    p25: 38.3,
    p50: 39.1,
    p75: 39.9,
    p85: 40.3,
    p90: 40.6,
    p95: 41.1,
    p97: 41.3,
    p99: 41.9,
    p999: 42.8
  },
  {
    month: 3,
    l: 1,
    m: 40.5135,
    s: 0.02918,
    sd: 1.18218,
    p01: 36.9,
    p1: 37.8,
    p3: 38.3,
    p5: 38.6,
    p10: 39,
    p15: 39.3,
    p25: 39.7,
    p50: 40.5,
    p75: 41.3,
    p85: 41.7,
    p90: 42,
    p95: 42.5,
    p97: 42.7,
    p99: 43.3,
    p999: 44.2
  },
  {
    month: 4,
    l: 1,
    m: 41.6317,
    s: 0.02868,
    sd: 1.194,
    p01: 37.9,
    p1: 38.9,
    p3: 39.4,
    p5: 39.7,
    p10: 40.1,
    p15: 40.4,
    p25: 40.8,
    p50: 41.6,
    p75: 42.4,
    p85: 42.9,
    p90: 43.2,
    p95: 43.6,
    p97: 43.9,
    p99: 44.4,
    p999: 45.3
  },
  {
    month: 5,
    l: 1,
    m: 42.5576,
    s: 0.02837,
    sd: 1.20736,
    p01: 38.8,
    p1: 39.7,
    p3: 40.3,
    p5: 40.6,
    p10: 41,
    p15: 41.3,
    p25: 41.7,
    p50: 42.6,
    p75: 43.4,
    p85: 43.8,
    p90: 44.1,
    p95: 44.5,
    p97: 44.8,
    p99: 45.4,
    p999: 46.3
  },
  {
    month: 6,
    l: 1,
    m: 43.3306,
    s: 0.02817,
    sd: 1.22062,
    p01: 39.6,
    p1: 40.5,
    p3: 41,
    p5: 41.3,
    p10: 41.8,
    p15: 42.1,
    p25: 42.5,
    p50: 43.3,
    p75: 44.2,
    p85: 44.6,
    p90: 44.9,
    p95: 45.3,
    p97: 45.6,
    p99: 46.2,
    p999: 47.1
  },
  {
    month: 7,
    l: 1,
    m: 43.9803,
    s: 0.02804,
    sd: 1.23321,
    p01: 40.2,
    p1: 41.1,
    p3: 41.7,
    p5: 42,
    p10: 42.4,
    p15: 42.7,
    p25: 43.1,
    p50: 44,
    p75: 44.8,
    p85: 45.3,
    p90: 45.6,
    p95: 46,
    p97: 46.3,
    p99: 46.8,
    p999: 47.8
  },
  {
    month: 8,
    l: 1,
    m: 44.53,
    s: 0.02796,
    sd: 1.24506,
    p01: 40.7,
    p1: 41.6,
    p3: 42.2,
    p5: 42.5,
    p10: 42.9,
    p15: 43.2,
    p25: 43.7,
    p50: 44.5,
    p75: 45.4,
    p85: 45.8,
    p90: 46.1,
    p95: 46.6,
    p97: 46.9,
    p99: 47.4,
    p999: 48.4
  },
  {
    month: 9,
    l: 1,
    m: 44.9998,
    s: 0.02792,
    sd: 1.25639,
    p01: 41.1,
    p1: 42.1,
    p3: 42.6,
    p5: 42.9,
    p10: 43.4,
    p15: 43.7,
    p25: 44.2,
    p50: 45,
    p75: 45.8,
    p85: 46.3,
    p90: 46.6,
    p95: 47.1,
    p97: 47.4,
    p99: 47.9,
    p999: 48.9
  },
  {
    month: 10,
    l: 1,
    m: 45.4051,
    s: 0.0279,
    sd: 1.2668,
    p01: 41.5,
    p1: 42.5,
    p3: 43,
    p5: 43.3,
    p10: 43.8,
    p15: 44.1,
    p25: 44.6,
    p50: 45.4,
    p75: 46.3,
    p85: 46.7,
    p90: 47,
    p95: 47.5,
    p97: 47.8,
    p99: 48.4,
    p999: 49.3
  },
  {
    month: 11,
    l: 1,
    m: 45.7573,
    s: 0.02789,
    sd: 1.27617,
    p01: 41.8,
    p1: 42.8,
    p3: 43.4,
    p5: 43.7,
    p10: 44.1,
    p15: 44.4,
    p25: 44.9,
    p50: 45.8,
    p75: 46.6,
    p85: 47.1,
    p90: 47.4,
    p95: 47.9,
    p97: 48.2,
    p99: 48.7,
    p999: 49.7
  },
  {
    month: 12,
    l: 1,
    m: 46.0661,
    s: 0.02789,
    sd: 1.28478,
    p01: 42.1,
    p1: 43.1,
    p3: 43.6,
    p5: 44,
    p10: 44.4,
    p15: 44.7,
    p25: 45.2,
    p50: 46.1,
    p75: 46.9,
    p85: 47.4,
    p90: 47.7,
    p95: 48.2,
    p97: 48.5,
    p99: 49.1,
    p999: 50
  },
  {
    month: 13,
    l: 1,
    m: 46.3395,
    s: 0.02789,
    sd: 1.29241,
    p01: 42.3,
    p1: 43.3,
    p3: 43.9,
    p5: 44.2,
    p10: 44.7,
    p15: 45,
    p25: 45.5,
    p50: 46.3,
    p75: 47.2,
    p85: 47.7,
    p90: 48,
    p95: 48.5,
    p97: 48.8,
    p99: 49.3,
    p999: 50.3
  },
  {
    month: 14,
    l: 1,
    m: 46.5844,
    s: 0.02791,
    sd: 1.30017,
    p01: 42.6,
    p1: 43.6,
    p3: 44.1,
    p5: 44.4,
    p10: 44.9,
    p15: 45.2,
    p25: 45.7,
    p50: 46.6,
    p75: 47.5,
    p85: 47.9,
    p90: 48.3,
    p95: 48.7,
    p97: 49,
    p99: 49.6,
    p999: 50.6
  },
  {
    month: 15,
    l: 1,
    m: 46.806,
    s: 0.02792,
    sd: 1.30682,
    p01: 42.8,
    p1: 43.8,
    p3: 44.3,
    p5: 44.7,
    p10: 45.1,
    p15: 45.5,
    p25: 45.9,
    p50: 46.8,
    p75: 47.7,
    p85: 48.2,
    p90: 48.5,
    p95: 49,
    p97: 49.3,
    p99: 49.8,
    p999: 50.8
  },
  {
    month: 16,
    l: 1,
    m: 47.0088,
    s: 0.02795,
    sd: 1.3139,
    p01: 42.9,
    p1: 44,
    p3: 44.5,
    p5: 44.8,
    p10: 45.3,
    p15: 45.6,
    p25: 46.1,
    p50: 47,
    p75: 47.9,
    p85: 48.4,
    p90: 48.7,
    p95: 49.2,
    p97: 49.5,
    p99: 50.1,
    p999: 51.1
  },
  {
    month: 17,
    l: 1,
    m: 47.1962,
    s: 0.02797,
    sd: 1.32008,
    p01: 43.1,
    p1: 44.1,
    p3: 44.7,
    p5: 45,
    p10: 45.5,
    p15: 45.8,
    p25: 46.3,
    p50: 47.2,
    p75: 48.1,
    p85: 48.6,
    p90: 48.9,
    p95: 49.4,
    p97: 49.7,
    p99: 50.3,
    p999: 51.3
  },
  {
    month: 18,
    l: 1,
    m: 47.3711,
    s: 0.028,
    sd: 1.32639,
    p01: 43.3,
    p1: 44.3,
    p3: 44.9,
    p5: 45.2,
    p10: 45.7,
    p15: 46,
    p25: 46.5,
    p50: 47.4,
    p75: 48.3,
    p85: 48.7,
    p90: 49.1,
    p95: 49.6,
    p97: 49.9,
    p99: 50.5,
    p999: 51.5
  },
  {
    month: 19,
    l: 1,
    m: 47.5357,
    s: 0.02803,
    sd: 1.33243,
    p01: 43.4,
    p1: 44.4,
    p3: 45,
    p5: 45.3,
    p10: 45.8,
    p15: 46.2,
    p25: 46.6,
    p50: 47.5,
    p75: 48.4,
    p85: 48.9,
    p90: 49.2,
    p95: 49.7,
    p97: 50,
    p99: 50.6,
    p999: 51.7
  },
  {
    month: 20,
    l: 1,
    m: 47.6919,
    s: 0.02806,
    sd: 1.33823,
    p01: 43.6,
    p1: 44.6,
    p3: 45.2,
    p5: 45.5,
    p10: 46,
    p15: 46.3,
    p25: 46.8,
    p50: 47.7,
    p75: 48.6,
    p85: 49.1,
    p90: 49.4,
    p95: 49.9,
    p97: 50.2,
    p99: 50.8,
    p999: 51.8
  },
  {
    month: 21,
    l: 1,
    m: 47.8408,
    s: 0.0281,
    sd: 1.34433,
    p01: 43.7,
    p1: 44.7,
    p3: 45.3,
    p5: 45.6,
    p10: 46.1,
    p15: 46.4,
    p25: 46.9,
    p50: 47.8,
    p75: 48.7,
    p85: 49.2,
    p90: 49.6,
    p95: 50.1,
    p97: 50.4,
    p99: 51,
    p999: 52
  },
  {
    month: 22,
    l: 1,
    m: 47.9833,
    s: 0.02813,
    sd: 1.34977,
    p01: 43.8,
    p1: 44.8,
    p3: 45.4,
    p5: 45.8,
    p10: 46.3,
    p15: 46.6,
    p25: 47.1,
    p50: 48,
    p75: 48.9,
    p85: 49.4,
    p90: 49.7,
    p95: 50.2,
    p97: 50.5,
    p99: 51.1,
    p999: 52.2
  },
  {
    month: 23,
    l: 1,
    m: 48.1201,
    s: 0.02817,
    sd: 1.35554,
    p01: 43.9,
    p1: 45,
    p3: 45.6,
    p5: 45.9,
    p10: 46.4,
    p15: 46.7,
    p25: 47.2,
    p50: 48.1,
    p75: 49,
    p85: 49.5,
    p90: 49.9,
    p95: 50.3,
    p97: 50.7,
    p99: 51.3,
    p999: 52.3
  },
  {
    month: 24,
    l: 1,
    m: 48.2515,
    s: 0.02821,
    sd: 1.36117,
    p01: 44,
    p1: 45.1,
    p3: 45.7,
    p5: 46,
    p10: 46.5,
    p15: 46.8,
    p25: 47.3,
    p50: 48.3,
    p75: 49.2,
    p85: 49.7,
    p90: 50,
    p95: 50.5,
    p97: 50.8,
    p99: 51.4,
    p999: 52.5
  },
  {
    month: 25,
    l: 1,
    m: 48.3777,
    s: 0.02825,
    sd: 1.36667,
    p01: 44.2,
    p1: 45.2,
    p3: 45.8,
    p5: 46.1,
    p10: 46.6,
    p15: 47,
    p25: 47.5,
    p50: 48.4,
    p75: 49.3,
    p85: 49.8,
    p90: 50.1,
    p95: 50.6,
    p97: 50.9,
    p99: 51.6,
    p999: 52.6
  },
  {
    month: 26,
    l: 1,
    m: 48.4989,
    s: 0.0283,
    sd: 1.37252,
    p01: 44.3,
    p1: 45.3,
    p3: 45.9,
    p5: 46.2,
    p10: 46.7,
    p15: 47.1,
    p25: 47.6,
    p50: 48.5,
    p75: 49.4,
    p85: 49.9,
    p90: 50.3,
    p95: 50.8,
    p97: 51.1,
    p99: 51.7,
    p999: 52.7
  },
  {
    month: 27,
    l: 1,
    m: 48.6151,
    s: 0.02834,
    sd: 1.37775,
    p01: 44.4,
    p1: 45.4,
    p3: 46,
    p5: 46.3,
    p10: 46.8,
    p15: 47.2,
    p25: 47.7,
    p50: 48.6,
    p75: 49.5,
    p85: 50,
    p90: 50.4,
    p95: 50.9,
    p97: 51.2,
    p99: 51.8,
    p999: 52.9
  },
  {
    month: 28,
    l: 1,
    m: 48.7264,
    s: 0.02838,
    sd: 1.38286,
    p01: 44.5,
    p1: 45.5,
    p3: 46.1,
    p5: 46.5,
    p10: 47,
    p15: 47.3,
    p25: 47.8,
    p50: 48.7,
    p75: 49.7,
    p85: 50.2,
    p90: 50.5,
    p95: 51,
    p97: 51.3,
    p99: 51.9,
    p999: 53
  },
  {
    month: 29,
    l: 1,
    m: 48.8331,
    s: 0.02842,
    sd: 1.38784,
    p01: 44.5,
    p1: 45.6,
    p3: 46.2,
    p5: 46.6,
    p10: 47.1,
    p15: 47.4,
    p25: 47.9,
    p50: 48.8,
    p75: 49.8,
    p85: 50.3,
    p90: 50.6,
    p95: 51.1,
    p97: 51.4,
    p99: 52.1,
    p999: 53.1
  },
  {
    month: 30,
    l: 1,
    m: 48.9351,
    s: 0.02847,
    sd: 1.39318,
    p01: 44.6,
    p1: 45.7,
    p3: 46.3,
    p5: 46.6,
    p10: 47.1,
    p15: 47.5,
    p25: 48,
    p50: 48.9,
    p75: 49.9,
    p85: 50.4,
    p90: 50.7,
    p95: 51.2,
    p97: 51.6,
    p99: 52.2,
    p999: 53.2
  },
  {
    month: 31,
    l: 1,
    m: 49.0327,
    s: 0.02851,
    sd: 1.39792,
    p01: 44.7,
    p1: 45.8,
    p3: 46.4,
    p5: 46.7,
    p10: 47.2,
    p15: 47.6,
    p25: 48.1,
    p50: 49,
    p75: 50,
    p85: 50.5,
    p90: 50.8,
    p95: 51.3,
    p97: 51.7,
    p99: 52.3,
    p999: 53.4
  },
  {
    month: 32,
    l: 1,
    m: 49.126,
    s: 0.02855,
    sd: 1.40255,
    p01: 44.8,
    p1: 45.9,
    p3: 46.5,
    p5: 46.8,
    p10: 47.3,
    p15: 47.7,
    p25: 48.2,
    p50: 49.1,
    p75: 50.1,
    p85: 50.6,
    p90: 50.9,
    p95: 51.4,
    p97: 51.8,
    p99: 52.4,
    p999: 53.5
  },
  {
    month: 33,
    l: 1,
    m: 49.2153,
    s: 0.02859,
    sd: 1.40707,
    p01: 44.9,
    p1: 45.9,
    p3: 46.6,
    p5: 46.9,
    p10: 47.4,
    p15: 47.8,
    p25: 48.3,
    p50: 49.2,
    p75: 50.2,
    p85: 50.7,
    p90: 51,
    p95: 51.5,
    p97: 51.9,
    p99: 52.5,
    p999: 53.6
  },
  {
    month: 34,
    l: 1,
    m: 49.3007,
    s: 0.02863,
    sd: 1.41148,
    p01: 44.9,
    p1: 46,
    p3: 46.6,
    p5: 47,
    p10: 47.5,
    p15: 47.8,
    p25: 48.3,
    p50: 49.3,
    p75: 50.3,
    p85: 50.8,
    p90: 51.1,
    p95: 51.6,
    p97: 52,
    p99: 52.6,
    p999: 53.7
  },
  {
    month: 35,
    l: 1,
    m: 49.3826,
    s: 0.02867,
    sd: 1.4158,
    p01: 45,
    p1: 46.1,
    p3: 46.7,
    p5: 47.1,
    p10: 47.6,
    p15: 47.9,
    p25: 48.4,
    p50: 49.4,
    p75: 50.3,
    p85: 50.8,
    p90: 51.2,
    p95: 51.7,
    p97: 52,
    p99: 52.7,
    p999: 53.8
  },
  {
    month: 36,
    l: 1,
    m: 49.4612,
    s: 0.02871,
    sd: 1.42003,
    p01: 45.1,
    p1: 46.2,
    p3: 46.8,
    p5: 47.1,
    p10: 47.6,
    p15: 48,
    p25: 48.5,
    p50: 49.5,
    p75: 50.4,
    p85: 50.9,
    p90: 51.3,
    p95: 51.8,
    p97: 52.1,
    p99: 52.8,
    p999: 53.8
  },
  {
    month: 37,
    l: 1,
    m: 49.5367,
    s: 0.02875,
    sd: 1.42418,
    p01: 45.1,
    p1: 46.2,
    p3: 46.9,
    p5: 47.2,
    p10: 47.7,
    p15: 48.1,
    p25: 48.6,
    p50: 49.5,
    p75: 50.5,
    p85: 51,
    p90: 51.4,
    p95: 51.9,
    p97: 52.2,
    p99: 52.8,
    p999: 53.9
  },
  {
    month: 38,
    l: 1,
    m: 49.6093,
    s: 0.02878,
    sd: 1.42776,
    p01: 45.2,
    p1: 46.3,
    p3: 46.9,
    p5: 47.3,
    p10: 47.8,
    p15: 48.1,
    p25: 48.6,
    p50: 49.6,
    p75: 50.6,
    p85: 51.1,
    p90: 51.4,
    p95: 52,
    p97: 52.3,
    p99: 52.9,
    p999: 54
  },
  {
    month: 39,
    l: 1,
    m: 49.6791,
    s: 0.02882,
    sd: 1.43175,
    p01: 45.3,
    p1: 46.3,
    p3: 47,
    p5: 47.3,
    p10: 47.8,
    p15: 48.2,
    p25: 48.7,
    p50: 49.7,
    p75: 50.6,
    p85: 51.2,
    p90: 51.5,
    p95: 52,
    p97: 52.4,
    p99: 53,
    p999: 54.1
  },
  {
    month: 40,
    l: 1,
    m: 49.7465,
    s: 0.02886,
    sd: 1.43568,
    p01: 45.3,
    p1: 46.4,
    p3: 47,
    p5: 47.4,
    p10: 47.9,
    p15: 48.3,
    p25: 48.8,
    p50: 49.7,
    p75: 50.7,
    p85: 51.2,
    p90: 51.6,
    p95: 52.1,
    p97: 52.4,
    p99: 53.1,
    p999: 54.2
  },
  {
    month: 41,
    l: 1,
    m: 49.8116,
    s: 0.02889,
    sd: 1.43906,
    p01: 45.4,
    p1: 46.5,
    p3: 47.1,
    p5: 47.4,
    p10: 48,
    p15: 48.3,
    p25: 48.8,
    p50: 49.8,
    p75: 50.8,
    p85: 51.3,
    p90: 51.7,
    p95: 52.2,
    p97: 52.5,
    p99: 53.2,
    p999: 54.3
  },
  {
    month: 42,
    l: 1,
    m: 49.8745,
    s: 0.02893,
    sd: 1.44287,
    p01: 45.4,
    p1: 46.5,
    p3: 47.2,
    p5: 47.5,
    p10: 48,
    p15: 48.4,
    p25: 48.9,
    p50: 49.9,
    p75: 50.8,
    p85: 51.4,
    p90: 51.7,
    p95: 52.2,
    p97: 52.6,
    p99: 53.2,
    p999: 54.3
  },
  {
    month: 43,
    l: 1,
    m: 49.9354,
    s: 0.02896,
    sd: 1.44613,
    p01: 45.5,
    p1: 46.6,
    p3: 47.2,
    p5: 47.6,
    p10: 48.1,
    p15: 48.4,
    p25: 49,
    p50: 49.9,
    p75: 50.9,
    p85: 51.4,
    p90: 51.8,
    p95: 52.3,
    p97: 52.7,
    p99: 53.3,
    p999: 54.4
  },
  {
    month: 44,
    l: 1,
    m: 49.9942,
    s: 0.02899,
    sd: 1.44933,
    p01: 45.5,
    p1: 46.6,
    p3: 47.3,
    p5: 47.6,
    p10: 48.1,
    p15: 48.5,
    p25: 49,
    p50: 50,
    p75: 51,
    p85: 51.5,
    p90: 51.9,
    p95: 52.4,
    p97: 52.7,
    p99: 53.4,
    p999: 54.5
  },
  {
    month: 45,
    l: 1,
    m: 50.0512,
    s: 0.02903,
    sd: 1.45299,
    p01: 45.6,
    p1: 46.7,
    p3: 47.3,
    p5: 47.7,
    p10: 48.2,
    p15: 48.5,
    p25: 49.1,
    p50: 50.1,
    p75: 51,
    p85: 51.6,
    p90: 51.9,
    p95: 52.4,
    p97: 52.8,
    p99: 53.4,
    p999: 54.5
  },
  {
    month: 46,
    l: 1,
    m: 50.1064,
    s: 0.02906,
    sd: 1.45609,
    p01: 45.6,
    p1: 46.7,
    p3: 47.4,
    p5: 47.7,
    p10: 48.2,
    p15: 48.6,
    p25: 49.1,
    p50: 50.1,
    p75: 51.1,
    p85: 51.6,
    p90: 52,
    p95: 52.5,
    p97: 52.8,
    p99: 53.5,
    p999: 54.6
  },
  {
    month: 47,
    l: 1,
    m: 50.1598,
    s: 0.02909,
    sd: 1.45915,
    p01: 45.7,
    p1: 46.8,
    p3: 47.4,
    p5: 47.8,
    p10: 48.3,
    p15: 48.6,
    p25: 49.2,
    p50: 50.2,
    p75: 51.1,
    p85: 51.7,
    p90: 52,
    p95: 52.6,
    p97: 52.9,
    p99: 53.6,
    p999: 54.7
  },
  {
    month: 48,
    l: 1,
    m: 50.2115,
    s: 0.02912,
    sd: 1.46216,
    p01: 45.7,
    p1: 46.8,
    p3: 47.5,
    p5: 47.8,
    p10: 48.3,
    p15: 48.7,
    p25: 49.2,
    p50: 50.2,
    p75: 51.2,
    p85: 51.7,
    p90: 52.1,
    p95: 52.6,
    p97: 53,
    p99: 53.6,
    p999: 54.7
  },
  {
    month: 49,
    l: 1,
    m: 50.2617,
    s: 0.02915,
    sd: 1.46513,
    p01: 45.7,
    p1: 46.9,
    p3: 47.5,
    p5: 47.9,
    p10: 48.4,
    p15: 48.7,
    p25: 49.3,
    p50: 50.3,
    p75: 51.2,
    p85: 51.8,
    p90: 52.1,
    p95: 52.7,
    p97: 53,
    p99: 53.7,
    p999: 54.8
  },
  {
    month: 50,
    l: 1,
    m: 50.3105,
    s: 0.02918,
    sd: 1.46806,
    p01: 45.8,
    p1: 46.9,
    p3: 47.5,
    p5: 47.9,
    p10: 48.4,
    p15: 48.8,
    p25: 49.3,
    p50: 50.3,
    p75: 51.3,
    p85: 51.8,
    p90: 52.2,
    p95: 52.7,
    p97: 53.1,
    p99: 53.7,
    p999: 54.8
  },
  {
    month: 51,
    l: 1,
    m: 50.3578,
    s: 0.02921,
    sd: 1.47095,
    p01: 45.8,
    p1: 46.9,
    p3: 47.6,
    p5: 47.9,
    p10: 48.5,
    p15: 48.8,
    p25: 49.4,
    p50: 50.4,
    p75: 51.3,
    p85: 51.9,
    p90: 52.2,
    p95: 52.8,
    p97: 53.1,
    p99: 53.8,
    p999: 54.9
  },
  {
    month: 52,
    l: 1,
    m: 50.4039,
    s: 0.02924,
    sd: 1.47381,
    p01: 45.8,
    p1: 47,
    p3: 47.6,
    p5: 48,
    p10: 48.5,
    p15: 48.9,
    p25: 49.4,
    p50: 50.4,
    p75: 51.4,
    p85: 51.9,
    p90: 52.3,
    p95: 52.8,
    p97: 53.2,
    p99: 53.8,
    p999: 55
  },
  {
    month: 53,
    l: 1,
    m: 50.4488,
    s: 0.02927,
    sd: 1.47664,
    p01: 45.9,
    p1: 47,
    p3: 47.7,
    p5: 48,
    p10: 48.6,
    p15: 48.9,
    p25: 49.5,
    p50: 50.4,
    p75: 51.4,
    p85: 52,
    p90: 52.3,
    p95: 52.9,
    p97: 53.2,
    p99: 53.9,
    p999: 55
  },
  {
    month: 54,
    l: 1,
    m: 50.4926,
    s: 0.02929,
    sd: 1.47893,
    p01: 45.9,
    p1: 47.1,
    p3: 47.7,
    p5: 48.1,
    p10: 48.6,
    p15: 49,
    p25: 49.5,
    p50: 50.5,
    p75: 51.5,
    p85: 52,
    p90: 52.4,
    p95: 52.9,
    p97: 53.3,
    p99: 53.9,
    p999: 55.1
  },
  {
    month: 55,
    l: 1,
    m: 50.5354,
    s: 0.02932,
    sd: 1.4817,
    p01: 46,
    p1: 47.1,
    p3: 47.7,
    p5: 48.1,
    p10: 48.6,
    p15: 49,
    p25: 49.5,
    p50: 50.5,
    p75: 51.5,
    p85: 52.1,
    p90: 52.4,
    p95: 53,
    p97: 53.3,
    p99: 54,
    p999: 55.1
  },
  {
    month: 56,
    l: 1,
    m: 50.5772,
    s: 0.02935,
    sd: 1.48444,
    p01: 46,
    p1: 47.1,
    p3: 47.8,
    p5: 48.1,
    p10: 48.7,
    p15: 49,
    p25: 49.6,
    p50: 50.6,
    p75: 51.6,
    p85: 52.1,
    p90: 52.5,
    p95: 53,
    p97: 53.4,
    p99: 54,
    p999: 55.2
  },
  {
    month: 57,
    l: 1,
    m: 50.6183,
    s: 0.02938,
    sd: 1.48717,
    p01: 46,
    p1: 47.2,
    p3: 47.8,
    p5: 48.2,
    p10: 48.7,
    p15: 49.1,
    p25: 49.6,
    p50: 50.6,
    p75: 51.6,
    p85: 52.2,
    p90: 52.5,
    p95: 53.1,
    p97: 53.4,
    p99: 54.1,
    p999: 55.2
  },
  {
    month: 58,
    l: 1,
    m: 50.6587,
    s: 0.0294,
    sd: 1.48937,
    p01: 46.1,
    p1: 47.2,
    p3: 47.9,
    p5: 48.2,
    p10: 48.8,
    p15: 49.1,
    p25: 49.7,
    p50: 50.7,
    p75: 51.7,
    p85: 52.2,
    p90: 52.6,
    p95: 53.1,
    p97: 53.5,
    p99: 54.1,
    p999: 55.3
  },
  {
    month: 59,
    l: 1,
    m: 50.6984,
    s: 0.02943,
    sd: 1.49205,
    p01: 46.1,
    p1: 47.2,
    p3: 47.9,
    p5: 48.2,
    p10: 48.8,
    p15: 49.2,
    p25: 49.7,
    p50: 50.7,
    p75: 51.7,
    p85: 52.2,
    p90: 52.6,
    p95: 53.2,
    p97: 53.5,
    p99: 54.2,
    p999: 55.3
  },
  {
    month: 60,
    l: 1,
    m: 50.7375,
    s: 0.02946,
    sd: 1.49473,
    p01: 46.1,
    p1: 47.3,
    p3: 47.9,
    p5: 48.3,
    p10: 48.8,
    p15: 49.2,
    p25: 49.7,
    p50: 50.7,
    p75: 51.7,
    p85: 52.3,
    p90: 52.7,
    p95: 53.2,
    p97: 53.5,
    p99: 54.2,
    p999: 55.4
  }
]

export default hcValues
