import {
  Flex,
  FormLabel,
  HTMLChakraProps,
  PinInput,
  PinInputField
} from '@chakra-ui/react'
import React from 'react'
import { useField, useForm } from 'react-final-form'
import { useDebounceExecute } from '../useDebounceExecute'
import { Control, Error } from './utils'

type OTPFieldProps = HTMLChakraProps<"input"> & {
  name: string
  label?: string
  length?: number
  autoSubmit?: boolean
}

export const OTPField: React.FC<OTPFieldProps> = props => {
  const { name, length = 4, autoSubmit } = props
  const { input, meta } = useField(name)

  const form = useForm()
  const { execute } = useDebounceExecute(300)

  return <Control name={name}  >
    {
      props.label &&
      <FormLabel htmlFor={name}>{props.label}</FormLabel>
    }
    <Flex>
      <PinInput
        {...input}
        onChange={value => {
          if (autoSubmit) {
            input.onChange(value)

            if (length === value.length) {
              execute(() => form.submit())
            }

          } else {
            input.onChange(value)
          }
        }}
        otp
        type="number"
        id={name}
        isInvalid={meta.error && meta.touched}
        size='md'
        autoFocus
        manageFocus
      >
        {
          Array.from(
            { length },
            (value, index) => {
              const key = `input-field-${name}-${index}`
              return <PinInputField key={key} mx='auto' />
            }
          )
        }
      </PinInput>
    </Flex>
    <Error name={name} />
  </Control>
}
