import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit, Disposition } from '../../api/interfaces'
import { UPDATE_DISPOSITION } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  invalidSignatureError: {
    id: 'StatusAndDisposition.start_exam_invalid_signature_error',
    defaultMessage: 'We could not validate your identity. Refresh the page and try again.',
  },
  invalidVisitError: {
    id: 'StatusAndDisposition.start_exam_invalid_visit_error',
    defaultMessage: 'This visit id is not valid. Refresh the page and try again.',
  },
  invalidDisposition: {
    id: 'StatusAndDisposition.invalid_disposition_value',
    defaultMessage: 'Please, select a valid disposition value.',
  },
  errorUpdatingDisposition: {
    id: 'StatusAndDisposition.error_updating_disposition',
    defaultMessage: 'Could not update the disposition. Please, try again.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface NewDisposition {
  disposition: Disposition
  newVisitType?: string
  externalReferralLocation?: string
  externalReferralReason?: string
}

interface UpdateDispositionVariables {
  patientId: string
  visitId: string
  disposition: NewDisposition
}

interface UpdateDispositionMutationData {
  updateDisposition: FullPatientWithActiveVisit
}

type Params = MutationHookOptions<UpdateDispositionMutationData, UpdateDispositionVariables> & {
  patientId: string
  visitId: string
}

export const useUpdateDisposition = (params: Params) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'error_invalid_signature':
        return setError(intl.formatMessage(messages.invalidSignatureError))

      case 'patient_not_found':
      case 'visit_not_found':
        return setError(intl.formatMessage(messages.invalidVisitError))

      case 'invalid_follow_up':
        return setError(intl.formatMessage(messages.invalidDisposition))

      case 'error_updating_follow_up':
        return setError(intl.formatMessage(messages.errorUpdatingDisposition))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [updateDisposition, { loading: isLoading }] = useMutation(UPDATE_DISPOSITION, {
    onError,
    ...restParams,
  })

  const onChange = (value: NewDisposition) => {
    const { disposition, externalReferralLocation, externalReferralReason } = value

    setError(null)

    const variables = {
      patientId,
      visitId,
      disposition: {
        disposition,
        externalReferralLocation,
        externalReferralReason,
      },
    }

    updateDisposition({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onChange,
    isLoading,
    error,
    onErrorClose,
  }
}
