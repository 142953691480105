import { gql } from '@apollo/client'

export const FullVisitMetrics = gql`
  fragment FullVisitMetrics on VisitMetrics {
    visits
    usedChecklists
    visitsUsingChecklists
    checklistsWithQEquation
    visitTypes {
      name
      total
    }
    commonDiagnoses {
      checklistId
      checklist
      visitCount
      QEResults {
        diagnosis
        education
        treatment
      }
    }
    QEResults {
      diagnosis
      education
      treatment
    }
    deaths {
      visitId
      diagnosis
      start
      patient {
        birthdate
        sex
      }
    }
    transfers {
      id
      clinical_status {
        priority
      }
      patient {
        birthdate
        sex
      }
      diagnosis
      start
      toLocation
      fromLocation
    }
    sexBreakdown {
      name
      total
    }
    ageBreakdown {
      ageRange
      total
    }
    timeline {
      registration {
        min
        avg
        max
      }
      triage {
        min
        avg
        max
      }
      wait {
        min
        avg
        max
      }
      exam {
        min
        avg
        max
      }
      pharmacy {
        min
        avg
        max
      }
    }
  }
`
