import flagUSA from '../../assets/images/flags/USA.png'
import flagID from '../../assets/images/flags/Indonezia.png'
import flagMX from '../../assets/images/flags/Mexico.png'
import flagFR from '../../assets/images/flags/France.png'

export enum Locales {
  EN_US = 'en-us',
  ID_ID = 'id-id',
  ES_MX = 'es-mx',
  FR_FR = 'fr-fr',
}

export interface LocaleInfo {
  country: string
  language: string
  lang: string
  flag: string
  countryCode: string
}

export const SUPPORTED_LOCALES: Record<Locales, LocaleInfo> = {
  [Locales.EN_US]: {
    countryCode: 'US',
    country: 'United States',
    flag: flagUSA,
    language: 'English',
    lang: 'en',
  },
  [Locales.ID_ID]: {
    countryCode: 'ID',
    country: 'Indonesia',
    flag: flagID,
    language: 'Bahasa Indonesia',
    lang: 'id',
  },
  [Locales.ES_MX]: {
    countryCode: 'MX',
    country: 'México',
    flag: flagMX,
    language: 'Español',
    lang: 'es',
  },
  [Locales.FR_FR]: {
    countryCode: 'FR',
    country: 'La France',
    flag: flagFR,
    language: 'French',
    lang: 'fr',
  },
}

export const isValidLocale = (value: string): value is Locales => {
  return Object.values(Locales).includes(value.toLowerCase() as Locales)
}
