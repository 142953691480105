import { gql } from '@apollo/client'
import { ActiveVisit, BasicBill, BasicPatient, FullBill, UserSignature } from '../fragments'

export const GET_BILL_WITH_VISIT = gql`
  ${ActiveVisit}
  ${BasicPatient}
  ${FullBill}
  query billAndVisit($visitId: ID!) {
    bill(visitId: $visitId) {
      ...FullBill
    }
    visit(id: $visitId) {
      ...ActiveVisit
      patient {
        ...BasicPatient
      }
    }
  }
`

export const GET_BILLABLE_VISITS = gql`
  ${BasicPatient}
  ${BasicBill}
  ${UserSignature}
  query ($q: String, $offset: Int, $limit: Int, $location: String!, $status: String) {
    bills(q: $q, offset: $offset, limit: $limit, location: $location, status: $status) {
      docs {
        visitId
        visitType
        location
        start
        end
        exam_started_by {
          ...UserSignature
        }
        patient {
          ...BasicPatient
        }
        bill {
          ...BasicBill
        }
      }
      count
    }
  }
`

export const CREATE_BILL = gql`
  ${FullBill}
  mutation ($bill: NewBill!) {
    createBill(bill: $bill) {
      ...FullBill
    }
  }
`

export const ADD_BILLING_ITEM = gql`
  ${FullBill}
  mutation ($billId: String!, $item: NewBillingItem!) {
    addBillingItem(billId: $billId, item: $item) {
      ...FullBill
    }
  }
`

export const UPDATE_BILLING_ITEM = gql`
  ${FullBill}
  mutation ($billId: String!, $itemId: String!, $item: NewBillingItem!) {
    updateBillingItem(item: $item, itemId: $itemId, billId: $billId) {
      ...FullBill
    }
  }
`

export const DELETE_BILLING_ITEM = gql`
  ${FullBill}
  mutation ($billId: String!, $itemId: String!) {
    deleteBillingItem(billId: $billId, itemId: $itemId) {
      ...FullBill
    }
  }
`

export const ADD_INVOICE_TO_BILL = gql`
  ${FullBill}
  mutation ($billId: String!, $amount: Money!, $payerEmail: String, $description: String!) {
    addInvoiceToBill(
      billId: $billId
      amount: $amount
      payerEmail: $payerEmail
      description: $description
    ) {
      ...FullBill
    }
  }
`
export const CANCEL_INVOICE = gql`
  ${FullBill}
  mutation ($billId: String!) {
    cancelInvoice(billId: $billId) {
      ...FullBill
    }
  }
`

export const MARK_AS_PAID = gql`
  ${FullBill}
  mutation ($billId: String!) {
    paidByCash(billId: $billId) {
      ...FullBill
    }
  }
`
