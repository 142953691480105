import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  UPDATE_CHECKLIST_HISTORY_IN_ACTIVE_VISIT,
  UPDATE_CHECKLIST_PHYSICAL_EXAM_IN_ACTIVE_VISIT,
  UPDATE_CHECKLIST_TREATMENT_IN_ACTIVE_VISIT,
  UPDATE_CHECKLIST_STUDY_IN_ACTIVE_VISIT,
  UPDATE_CHECKLIST_EDUCATION_IN_ACTIVE_VISIT,
} from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { useDebounceExecute } from '../useDebounceExecute'

const messages = defineMessages({
  checklistNotFound: {
    id: 'Checklist.checklist_not_found',
    defaultMessage: 'Could not add this checklist. Please, retry',
  },
  patientNotFound: {
    id: 'Checklist.patient_not_found',
    defaultMessage: 'Could not add this patient. Please, retry',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface NewItemValue {
  checked: boolean
  key: number
}

type UseChecklistItemActionsParams = MutationHookOptions & {
  patientId: string
  visitId: string
  checklistId: string
}

export const useChecklistItemActions = (params: UseChecklistItemActionsParams) => {
  const { patientId, visitId, checklistId } = params

  const intl = useIntl()

  const { execute } = useDebounceExecute(300)

  const [error, setError] = React.useState<Nullable<string>>(null)
  const translateError = (code) => {
    switch (code) {
      case 'checklist_not_found':
        return setError(intl.formatMessage(messages.checklistNotFound))

      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [updateHistory, { loading: updatingHistory }] = useMutation(
    UPDATE_CHECKLIST_HISTORY_IN_ACTIVE_VISIT,
    {
      onError,
    }
  )

  const [updatePhysicalExam, { loading: updatingPhysicalExam }] = useMutation(
    UPDATE_CHECKLIST_PHYSICAL_EXAM_IN_ACTIVE_VISIT,
    {
      onError,
    }
  )

  const [updateStudyExam, { loading: updatingStudies }] = useMutation(
    UPDATE_CHECKLIST_STUDY_IN_ACTIVE_VISIT,
    {
      onError,
    }
  )

  const [updateTreatment, { loading: updatingTreatment }] = useMutation(
    UPDATE_CHECKLIST_TREATMENT_IN_ACTIVE_VISIT,
    {
      onError,
    }
  )

  const [updateEducation, { loading: updatingEducation }] = useMutation(
    UPDATE_CHECKLIST_EDUCATION_IN_ACTIVE_VISIT,
    {
      onError,
    }
  )

  const onHistoryChange = (item: NewItemValue) => {
    setError(null)

    const { key, checked } = item

    const variables = {
      patientId,
      visitId,
      checklistId,
      checklistItem: {
        key,
        checked,
      },
    }

    execute(() => updateHistory({ variables }))
  }

  const onPhisicalExamChange = (item: NewItemValue) => {
    setError(null)

    const { key, checked } = item

    const variables = {
      patientId,
      visitId,
      checklistId,
      checklistItem: {
        key,
        checked,
      },
    }

    execute(() => updatePhysicalExam({ variables }))
  }

  const onStudyChange = (item: NewItemValue) => {
    setError(null)

    const { key, checked } = item

    const variables = {
      patientId,
      visitId,
      checklistId,
      checklistItem: {
        key,
        checked,
      },
    }

    // we need to wait for the modal
    updateStudyExam({ variables })
  }

  const onTreatmentChange = (item: NewItemValue) => {
    setError(null)

    const { key, checked } = item

    const variables = {
      patientId,
      visitId,
      checklistId,
      checklistItem: {
        key,
        checked,
      },
    }

    execute(() => updateTreatment({ variables }))
  }

  const onEducationChange = (item: NewItemValue) => {
    setError(null)

    const { key, checked } = item

    const variables = {
      patientId,
      visitId,
      checklistId,
      checklistItem: {
        key,
        checked,
      },
    }

    execute(() => updateEducation({ variables }))
  }
  const onErrorClose = () => {
    setError(null)
  }

  return {
    onHistoryChange,
    onPhisicalExamChange,
    onStudyChange,
    onTreatmentChange,
    onEducationChange,
    isLoading: {
      updatingHistory,
      updatingPhysicalExam,
      updatingTreatment,
      updatingStudies,
      updatingEducation,
    },
    error,
    onErrorClose,
  }
}
