import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { ErrorViewer } from '../'
import {
  Icon,
  Button,
  ButtonProps,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { useAddProgressNote } from '../Visit'
import { TextField } from '../Form'
import { RiAddLine as AddIcon } from 'react-icons/ri'

const messages = defineMessages({
  addProgressNoteButton: {
    id: 'UI.button_add_progress_note',
    defaultMessage: 'Progress Note'
  },
  saveButton: {
    id: 'UI.save_button',
    defaultMessage: 'Save'
  },
  cancelButton: {
    id: 'UI.cancel_button',
    defaultMessage: 'Cancel'
  },
  modalTitle: {
    id: 'AddProgressNoteButton.modal_title',
    defaultMessage: 'New Progress Note'
  },
  noteLabel: {
    id: 'UI.note_label',
    defaultMessage: 'Note'
  },

})

type AddProgressNoteButtonProps = ButtonProps & {
  patientId: string
  visitId: string
  preventModalFromClosing?: boolean
}
export const AddProgressNoteButton: React.FC<AddProgressNoteButtonProps> = (props) => {
  const { patientId, visitId, isDisabled, preventModalFromClosing, ...buttonProps } = props

  const intl = useIntl()

  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Button
      onClick={onOpen}
      disabled={isDisabled || isOpen}
      colorScheme='brand'
      size='sm'
      {...buttonProps}
    >
      <ProgressNoteModal
        patientId={patientId}
        visitId={visitId}
        onClose={onClose}
        isOpen={isOpen}
        preventModalFromClosing={preventModalFromClosing}
      />
      <Icon as={AddIcon} />
      <Text ml={1}>
        {intl.formatMessage(messages.addProgressNoteButton)}
      </Text>
    </Button>
  )
}


interface ProgressNoteModalProps {
  patientId: string
  visitId: string
  isOpen: boolean
  onClose: () => void
  preventModalFromClosing?: boolean
}

const ProgressNoteModal: React.FC<ProgressNoteModalProps> = props => {
  const { patientId, visitId, isOpen, onClose, preventModalFromClosing } = props

  const intl = useIntl()

  const params = {
    patientId,
    visitId,
    onCompleted: preventModalFromClosing ? undefined : onClose
  }
  const {
    initialValues,
    validate,
    onSubmit,
    isLoading,
    error,
    onErrorClose
  } = useAddProgressNote(params)

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => {

          return (
            <form onSubmit={handleSubmit}>
              <ModalContent>
                <ModalHeader>{intl.formatMessage(messages.modalTitle)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <TextField
                    name="note"
                    label={intl.formatMessage(messages.noteLabel)}
                    autoFocus
                  />

                  {error && <ErrorViewer title={error} onClose={onErrorClose} />}
                </ModalBody>

                <ModalFooter>
                  <Button mr={3} onClick={props.onClose} isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.cancelButton)}
                  </Button>
                  <Button colorScheme={'brand'} isLoading={isLoading} type="submit" isFullWidth={isFullWidth}>
                    {intl.formatMessage(messages.saveButton)}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )
        }}
      />
    </Modal>
  )
}
