import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  ChecklistReference,
  ChecklistTextEntry,
  ChecklistDivider,
  ChecklistProcedure,
  ChecklistStudy,
} from '../'

import {
  ChecklistEntryType,
  FullChecklistEntry,
  // FullProcedureOrder,
  // FullStudyOrder
} from '../../../api/interfaces'
import {
  Box,
  BoxProps,
  Heading,
  HStack,
  List,
  ListItem,
} from '@chakra-ui/react'

const messages = defineMessages({
  titleStudies: {
    id: 'Checklist.title_studies',
    defaultMessage: 'Studies'
  },
})

interface OnItemChangeParams {
  key: number
  checked: boolean
}




/**
 * Studies
 */
type StudiesProps = Omit<BoxProps, 'onChange'> & {
  items: Array<FullChecklistEntry>
  onChange: (params: OnItemChangeParams) => void
  // patientId: string
  // visitId: string
  // studyOrders: Array<FullStudyOrder>
  // procedureOrders: Array<FullProcedureOrder>
  isDisabled?: boolean
}

export const Studies: React.FC<StudiesProps> = props => {
  const {
    items,
    isDisabled,
    onChange,
    // patientId,
    // visitId,
    // studyOrders,
    // procedureOrders,
    ...boxProps
  } = props

  const intl = useIntl()
  const citations = items.filter(({ type }) => type === ChecklistEntryType.CITATION)

  const onCheckChange = index => checked => {
    const key = items[index].key
    if (key !== null) {
      onChange({ key, checked })
    }
  }


  return <Box {...boxProps}>
    <HStack alignItems={'center'} >
      <Heading size={'md'} >
        {intl.formatMessage(messages.titleStudies)}
      </Heading>
      <ChecklistReference items={citations} />
    </HStack>

    <List ml={4} my={2}>
      {
        items.map((item, index) => {
          if (item.type === ChecklistEntryType.DIVIDER && item.name) {
            return (
              <ListItem key={`${ChecklistEntryType.DIVIDER}-${index}`}>
                <ChecklistDivider
                  name={item.name}
                  reference={item.reference}
                />
              </ListItem>
            )
          }
          if (item.type === ChecklistEntryType.TEXT) {
            return (
              <ListItem key={`${ChecklistEntryType.TEXT}-${index}`}>
                <ChecklistTextEntry entry={item} isDisabled={isDisabled} onCheckChange={onCheckChange(index)} />
              </ListItem>
            )
          }
          if (item.type === ChecklistEntryType.PROCEDURE) {
            return (
              <ListItem key={`${ChecklistEntryType.PROCEDURE}-${index}`}>
                <ChecklistProcedure
                  // patientId={patientId}
                  // visitId={visitId}
                  // procedureOrders={procedureOrders}
                  entry={item}
                  isDisabled={isDisabled}
                  onCheckChange={onCheckChange(index)}
                />
              </ListItem>
            )
          }
          if (item.type === ChecklistEntryType.STUDY) {
            return (
              <ListItem key={`${ChecklistEntryType.STUDY}-${index}`}>
                <ChecklistStudy
                  // patientId={patientId}
                  // visitId={visitId}
                  // studyOrders={studyOrders}
                  entry={item}
                  isDisabled={isDisabled}
                  onCheckChange={onCheckChange(index)}
                />
              </ListItem>
            )
          }
          return null
        })
      }
    </List>
  </Box>
}
