import { gql } from '@apollo/client'
import { FullPatientWithActiveVisit, FullSystem, FullScheduledVisit } from '../fragments'

export const CREATE_ROOM_EVENT = gql`
  ${FullSystem}
  mutation createRoomEvent($roomId: String!, $event: NewRoomEvent!) {
    createRoomEvent(roomId: $roomId, event: $event) {
      ...FullSystem
    }
  }
`
export const UPDATE_ROOM_EVENT = gql`
  ${FullSystem}
  mutation updateRoomEvent($roomId: String!, $eventId: String!, $event: NewRoomEvent!) {
    updateRoomEvent(roomId: $roomId, eventId: $eventId, event: $event) {
      ...FullSystem
    }
  }
`

export const DELETE_ROOM_EVENT = gql`
  ${FullSystem}
  mutation deleteRoomEvent($roomId: String!, $eventId: String!) {
    deleteRoomEvent(roomId: $roomId, eventId: $eventId) {
      ...FullSystem
    }
  }
`

// get the list
export const GET_SCHEDULED_VISITS = gql`
  ${FullScheduledVisit}
  query scheduledVisits($offset: Int, $limit: Int, $from: String, $to: String, $location: String, $room: String) {
    scheduledVisits(offset: $offset, limit: $limit, from: $from, to: $to, location: $location, room: $room) {
      docs {
        ...FullScheduledVisit
      }
      count
    }
  }
`

// get one by id
export const GET_SCHEDULED_VISIT_BY_ID = gql`
  ${FullScheduledVisit}
  query scheduledVisit($id: String!) {
    scheduledVisit(id: $id) {
      ...FullScheduledVisit
    }
  }
`
export const CREATE_SCHEDULED_VISIT = gql`
  ${FullScheduledVisit}
  mutation createScheduledVisit($newScheduledVisit: NewRegistrationData!) {
    createScheduledVisit(newScheduledVisit: $newScheduledVisit) {
      visit {
        ...FullScheduledVisit
      }
      warning
    }
  }
`

export const DELETE_SCHEDULED_VISIT = gql`
  mutation deleteScheduledVisit($scheduledVisitId: String!) {
    deleteScheduledVisit(id: $scheduledVisitId) {
      done
    }
  }
`

export const UPDATE_SCHEDULED_VISIT = gql`
  ${FullScheduledVisit}
  mutation updateScheduledVisit($id: ID!, $registrationData: NewRegistrationData!) {
    updateScheduledVisit(id: $id, registrationData: $registrationData) {
      visit {
        ...FullScheduledVisit
      }
      warning
    }
  }
`

export const START_SCHEDULED_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation startScheduledVisit($id: String!) {
    startScheduledVisit(id: $id) {
      ...FullPatientWithActiveVisit
    }
  }
`

export const AVAILABLE_SLOTS = gql`
  query availableSlots($room: String!, $from: String!, $to: String!, $ignoreVisit: String) {
    availableSlots(room: $room, from: $from, to: $to, ignoreVisit: $ignoreVisit) {
      start
      end
    }
  }
`
