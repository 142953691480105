import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ButtonProps, Icon } from '@chakra-ui/react'
import { ConfirmButton, ConfirmButtonProps } from '..'
import { useAddAdminPermissions, useRemoveAdminPermissions } from './useAdminPermissions'
import {
  RiKey2Fill as AdminIcon,
  RiForbid2Line as NonAdminIcon
} from 'react-icons/ri'

const messages = defineMessages({
  makeAnAdmin: {
    id: 'UI.button_make_an_admin',
    defaultMessage: 'Make an admin'
  },
  removeAdminRole: {
    id: 'UI.button_revoke_admin_access',
    defaultMessage: 'Remove admin access'
  },
  confirmationMessage: {
    id: 'StaffAdmin.toggle_account_status_warning',
    defaultMessage: 'You can allow or denied the user access by switching the account status. Do you want to continue?'
  },
})

type ToggleAdminPermissionsButtonProps = ButtonProps & {
  username: string
  isAdmin: boolean
}

export const ToggleAdminPermissionsButton: React.FC<ToggleAdminPermissionsButtonProps> = props => {
  const { username, isAdmin, ...buttonProps } = props

  if (isAdmin) {
    return <RemoveAdminPermissions username={username} {...buttonProps} />
  }

  return <AddAdminPermissions username={username} {...buttonProps} />
}

type Props = ButtonProps & {
  username: string
}

const AddAdminPermissions: React.FC<Props> = props => {
  const { username, ...buttonProps } = props

  const intl = useIntl()

  const { addAdminPermissions, isLoading, error, onErrorClose } = useAddAdminPermissions()

  const onConfirm = () => {
    const variables = {
      username
    }

    addAdminPermissions({ variables })
  }


  const buttonStyles = {
    variant: 'ghost',
    isFullWidth: true,
    justifyContent: 'start',
    borderRadius: 'sm',
    size: 'sm',
    ...buttonProps
  }

  const enableButtonProps: ConfirmButtonProps = {
    onConfirm,
    message: intl.formatMessage(messages.confirmationMessage),
    buttonProps: {
      label: <>
        <Icon as={AdminIcon} mr={2} />
        {intl.formatMessage(messages.makeAnAdmin)}
      </>,
      colorScheme: 'brand',
      ...buttonStyles
    },
    confirmButtonProps: {
      isLoading,
      colorScheme: 'brand',
      label: intl.formatMessage(messages.makeAnAdmin)
    },
    error,
    onErrorClose
  }

  return <ConfirmButton {...enableButtonProps} />
}


const RemoveAdminPermissions: React.FC<Props> = props => {
  const { username, ...buttonProps } = props

  const intl = useIntl()

  const { removeAdminPermissions, isLoading, error, onErrorClose } = useRemoveAdminPermissions()

  const onConfirm = () => {
    const variables = {
      username
    }

    removeAdminPermissions({ variables })
  }


  const buttonStyles = {
    variant: 'ghost',
    isFullWidth: true,
    justifyContent: 'start',
    borderRadius: 'sm',
    size: 'sm',
    ...buttonProps
  }

  const disableButtonProps: ConfirmButtonProps = {
    onConfirm,
    message: intl.formatMessage(messages.confirmationMessage),
    buttonProps: {
      label: <>
        <Icon as={NonAdminIcon} mr={2} />
        {intl.formatMessage(messages.removeAdminRole)}
      </>
      ,
      colorScheme: 'red',
      ...buttonStyles
    },
    confirmButtonProps: {
      isLoading,
      label: intl.formatMessage(messages.removeAdminRole)
    },
    error,
    onErrorClose
  }

  return <ConfirmButton {...disableButtonProps} />
}
