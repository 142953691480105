import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { BasePatient, FullAddress } from '../../api/interfaces'
import { Box, BoxProps, Icon, Heading, HStack, Text, VStack, StackProps } from '@chakra-ui/react'
import { useAgeTranslator } from '../DateAndTime'
import { isValidSex, Nullable } from '../../utils'
import { sexTranslations } from '../Selector'
import { DateTime } from 'luxon'
import { RedirectButton, sitemap } from '../Routing'
import { RiPencilLine as InfoIcon } from 'react-icons/ri'
import { getPatientName } from './utils'

const messages = defineMessages({
  name: {
    id: 'PatientStatus.name_label',
    defaultMessage: 'Name',
  },
  sex: {
    id: 'PatientStatus.sex_label',
    defaultMessage: 'Sex',
  },
  age: {
    id: 'PatientStatus.age_label',
    defaultMessage: 'Age',
  },
  visitType: {
    id: 'PatientStatus.visit_type_label',
    defaultMessage: 'Visit type',
  },
  weight: {
    id: 'PatientStatus.weight_label',
    defaultMessage: 'Weight',
  },
  allergies: {
    id: 'PatientStatus.allergies_label',
    defaultMessage: 'Allergies',
  },
  examinedByHeader: {
    id: 'PatientStatus.exam_by_header',
    defaultMessage: 'Exam by',
  },
  patientNotFound: {
    id: 'PatientStatus.patient_not_found_label',
    defaultMessage: 'Patient Not Found.',
  },
  patientStatus: {
    id: 'PatientStatus.patient_status_label',
    defaultMessage: 'Status',
  },
  dob: {
    id: 'InpatientSearch.day_of_born_header',
    defaultMessage: 'DOB',
  },
  phonenumberLabel: {
    id: 'UI.phonenumber_label',
    defaultMessage: 'Phone',
  },
  addressLabel: {
    id: 'UI.address_label',
    defaultMessage: 'Address',
  },
})

type BasePatientInfoProps = BoxProps & {
  patient: BasePatient
}

export const BasePatientInfo: React.FC<BasePatientInfoProps> = (props) => {
  const { patient, ...boxProps } = props

  const intl = useIntl()

  const { translateAge } = useAgeTranslator()

  const patientName = getPatientName(patient)
  const sex = isValidSex(patient.sex) ? intl.formatMessage(sexTranslations[patient.sex]) : patient.sex

  const dob = DateTime.fromJSDate(new Date(patient.birthdate)).toLocaleString(DateTime.DATE_SHORT)
  const age = translateAge(new Date(patient.birthdate))

  return (
    <Box {...boxProps}>
      <HStack justifyContent={'space-between'} mt={1} alignItems={'center'}>
        <Heading size={'lg'} cursor="pointer">
          {patientName}
        </Heading>
        <RedirectButton
          to={sitemap.patients.routes.profile.pathBuilder(patient.id)}
          label={<Icon as={InfoIcon} fontSize={'lg'} />}
          size={'sm'}
          colorScheme={'brand'}
        />
      </HStack>

      <HStack wrap={'wrap'} justifyContent={'space-between'} spacing={0} py={3}>
        <HStack>
          <Text fontWeight={'bold'}>{intl.formatMessage(messages.age)}:</Text>
          <Text>{age}</Text>
        </HStack>

        <HStack>
          <Text fontWeight={'bold'}>{intl.formatMessage(messages.sex)}:</Text>
          <Text>{sex}</Text>
        </HStack>

        <HStack>
          <Text fontWeight={'bold'}>{intl.formatMessage(messages.dob)}:</Text>
          <Text>{dob}</Text>
        </HStack>
      </HStack>
    </Box>
  )
}

type PrintablePatientInfoProps = StackProps & {
  patient: BasePatient
  hideDemographics?: boolean
}

export const PrintablePatientInfo: React.FC<PrintablePatientInfoProps> = (props) => {
  const { patient, hideDemographics, ...stackProps } = props

  const intl = useIntl()

  const { translateAge } = useAgeTranslator()

  const patientName = getPatientName(patient)
  const sex = isValidSex(patient.sex) ? intl.formatMessage(sexTranslations[patient.sex]) : patient.sex
  const age = translateAge(new Date(patient.birthdate))
  const dob = DateTime.fromJSDate(new Date(patient.birthdate)).toLocaleString(DateTime.DATE_SHORT)
  const address = serializeAddress(patient.address)

  return (
    <VStack spacing={0} alignItems={'start'} {...stackProps} className="pdfPatientInfo">
      <HStack spacing={2}>
        <Text fontWeight={'bold'}>{intl.formatMessage(messages.name)}:</Text>
        <Text>{patientName}</Text>
      </HStack>

      {!hideDemographics && (
        <>
          <HStack spacing={2}>
            <Text fontWeight={'bold'}>{intl.formatMessage(messages.age)}:</Text>
            <Text>{age}</Text>
          </HStack>

          <HStack spacing={2}>
            <Text fontWeight={'bold'}>{intl.formatMessage(messages.sex)}:</Text>
            <Text>{sex}</Text>
          </HStack>
          <HStack spacing={2}>
            <Text fontWeight={'bold'}>{intl.formatMessage(messages.dob)}:</Text>
            <Text>{dob}</Text>
          </HStack>
        </>
      )}
      {patient?.phone_number_e164 && (
        <HStack alignItems={'start'} spacing={2}>
          <Text fontWeight={'bold'}>{intl.formatMessage(messages.phonenumberLabel)}:</Text>
          <Text>{patient.phone_number_e164}</Text>
        </HStack>
      )}
      {address && (
        <HStack alignItems={'start'} spacing={2}>
          <Text fontWeight={'bold'}>{intl.formatMessage(messages.addressLabel)}:</Text>
          <Text>{address}</Text>
        </HStack>
      )}
    </VStack>
  )
}

export const serializeAddress = (address: Nullable<FullAddress & { __typename?: string }>): string => {
  if (address === null) {
    return ''
  }

  const {
    locality,
    postal_code, // eslint-disable-line
    country, // eslint-disable-line
    __typename, // eslint-disable-line
    ...lines
  } = address

  const serializableArray = Object.values(lines).filter((value) => value)

  if (locality) {
    serializableArray.push(locality)
  }

  return serializableArray.join(', ')
}
