import { Alert, AlertTitle, Box, BoxProps, Button, HStack, Icon, } from '@chakra-ui/react'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ErrorViewer } from '..'
import { Signature } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { RiInformationLine as InfoIcon } from 'react-icons/ri'
import { useStartExam } from '.'
import { DateTime } from 'luxon'


const messages = defineMessages({
  description: {
    id: 'StartExam.button_description',
    defaultMessage: 'This exam has not been started yet.'
  },
  buttonStartExam: {
    id: 'UI.button_start_exam',
    defaultMessage: 'Start Exam'
  },
  examStarted: {
    id: 'StartExam.exam_started_at',
    defaultMessage: 'Exam started: {date}'
  },
})


type StartExamProps = BoxProps & {
  patientId: string
  visitId: string
  examStartedBy: Nullable<Signature>
}
export const StartExam: React.FC<StartExamProps> = (props) => {
  const { patientId, visitId, examStartedBy, ...boxProps } = props

  const intl = useIntl()

  const params = {
    patientId,
    visitId
  }
  const { onSubmit, isLoading, error, onErrorClose } = useStartExam(params)


  if (examStartedBy && examStartedBy.timestamp !== null) {
    const date = new Date(examStartedBy.timestamp)
    const formattedDate = DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_MED)

    return <Box display={'flex'} {...boxProps}>
      <Alert py={2} borderRadius={'md'} >
        <AlertTitle mr={2}>

          {intl.formatMessage(messages.examStarted, { date: formattedDate })}
        </AlertTitle>
      </Alert>
    </Box>
  }

  return <Box>
    <Alert py={2} colorScheme={'yellow'} justifyContent={'space-between'} borderRadius={'md'} {...boxProps} >
      <HStack>
        <Icon as={InfoIcon} h={6} w={6} />
        <AlertTitle mr={2}>
          {intl.formatMessage(messages.description)}
        </AlertTitle>
      </HStack>
      <Button onClick={onSubmit} isLoading={isLoading} size={'sm'}>
        {intl.formatMessage(messages.buttonStartExam)}
      </Button>
    </Alert>
    {
      error && <ErrorViewer title={error} onClose={onErrorClose} />
    }
  </Box>
}

