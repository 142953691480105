import React from 'react'
import { SignUpParams, useSession } from '../../components/Auth'
import { useIntl, defineMessages } from 'react-intl'
import {
  ValidateFn,
  ValidationError,
  isSecure,
  isValidEmail,
  isValidPhoneNumber,
  sanitizePhone,
} from '../../components/Form'
import { Nullable } from '../../utils'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { sitemap } from '../Routing/sitemap'
import phone from 'phone'

const messages = defineMessages({
  required: {
    id: 'StaffRegister.this_field_is_required',
    defaultMessage: 'This field is required',
  },
  invalidMail: {
    id: 'StaffRegister.invalid_email_address',
    defaultMessage: 'Invalid e-mail address.',
  },
  invalidPhone: {
    id: 'StaffRegister.invalid_phone_number',
    defaultMessage: 'Invalid phone number.',
  },
  invalidPassword: {
    id: 'StaffRegister.insecure_password',
    defaultMessage: 'Choose a more secure password',
  },
  invalidRePassword: {
    id: 'StaffRegister.invalid_re_password',
    defaultMessage: 'The passwords do not match',
  },
  userAlreadyExistsError: {
    id: 'StaffRegister.user_already_exists',
    defaultMessage: 'User already exists',
  },
  invalidParameterError: {
    id: 'StaffRegister.invalid_parameters',
    defaultMessage: 'Invalid parameters',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

type SignUpFormFields = SignUpParams & { rePassword: string }

export const useSignUp = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const { signUp } = useSession()

  const onError = (code) => {
    switch (code) {
      case 'UsernameExistsException':
        setError(intl.formatMessage(messages.userAlreadyExistsError))
        break

      case 'InvalidParameterException':
        setError(intl.formatMessage(messages.invalidParameterError))
        break

      case 'TooManyRequestsException':
      case 'CodeDeliveryFailureException':
      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
    setIsLoading(false)
  }

  const validate: ValidateFn<SignUpFormFields> = async (values) => {
    const errors: ValidationError<SignUpFormFields> = {}
    if (!values.username) {
      errors.username = intl.formatMessage(messages.required)
    }

    if (!values.firstName) {
      errors.firstName = intl.formatMessage(messages.required)
    }

    if (!values.lastName) {
      errors.lastName = intl.formatMessage(messages.required)
    }

    if (!values.email) {
      errors.email = intl.formatMessage(messages.required)
    } else if (!isValidEmail(values.email)) {
      errors.email = intl.formatMessage(messages.invalidMail)
    }

    if (typeof values.phoneNumber === 'string' && !isValidPhoneNumber(values.phoneNumber)) {
      errors.phoneNumber = intl.formatMessage(messages.invalidPhone)
    }

    if (!values.password) {
      errors.password = intl.formatMessage(messages.required)
    } else {
      const otherUserInputs = [values.username]
      const isPasswordSecure = await isSecure(values.password, otherUserInputs)

      if (!isPasswordSecure) {
        errors.password = intl.formatMessage(messages.invalidPassword)
      }
    }

    if (values.password !== values.rePassword) {
      errors.rePassword = intl.formatMessage(messages.invalidRePassword)
    }

    return errors
  }

  const onSubmit = async (values: SignUpFormFields) => {
    const { phoneNumber, ...rest } = values
    const params: SignUpParams = {
      ...rest,
    }

    if (typeof phoneNumber === 'string') {
      const e164 = phone(sanitizePhone(phoneNumber))
      params.phoneNumber = e164.phoneNumber ?? phoneNumber
    }

    try {
      await signUp(params)

      const qParams = { email: params.email }
      navigate({
        pathname: sitemap.unauth.routes.signUpConfirm.absolutePath,
        search: `?${createSearchParams(qParams)}`,
      })
    } catch (e) {
      onError(e)
      setIsLoading(false)
    }
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
  }
}
