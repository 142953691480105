import React from 'react'
import {
  DispositionSelector,
  StatusAssessmentSelector,
  FollowUpSelector,
  statusAssessmentTranslation,
  dispositionTranslation,
  followUpTranslation,
} from '../Selector'
import {
  Disposition as DispositionType,
  FollowUp as FollowUpType,
  StatusAssesment as StatusAssesmentType,
} from '../../api/interfaces'
import { Nullable } from '../../utils'
import {
  isValidDisposition,
  isValidFollowUp,
  isValidStatusAssesment,
  useUpdateDisposition,
  useUpdateFollowUp,
  useUpdateStatusAssessment,
} from '.'
import { ErrorViewer, useDebounceExecute } from '..'
import {
  Stack,
  Text,
  Flex,
  useBreakpointValue,
  StackProps,
  VStack,
  HStack,
  FormLabel,
  Input,
  FormControl,
} from '@chakra-ui/react'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  disposition: {
    id: 'DispositionSelector.disposition',
    defaultMessage: 'Disposition',
  },
  followUp: {
    id: 'FollowUpSelector.follow_up_label',
    defaultMessage: 'Follow up',
  },
  statusAssessment: {
    id: 'StatusAssessmentSelector.status_assessment',
    defaultMessage: 'Status assessment',
  },
  externalReferralLocation: {
    id: 'DispositionSelector.external_referral_location',
    defaultMessage: 'Location',
  },
  externalReferralReason: {
    id: 'DispositionSelector.external_referral_reason',
    defaultMessage: 'Reason',
  },
})

type AssessmentAndDispositionProps = StackProps & {
  patientId: string
  visitId: string
  statusAssesment: Nullable<StatusAssesmentType>
  disposition: Nullable<DispositionType>
  followUp: Nullable<FollowUpType>
  externalReferralLocation?: string
  externalReferralReason?: string
  isDisabled?: boolean
}
export const AssessmentAndDisposition: React.FC<AssessmentAndDispositionProps> = (props) => {
  const {
    patientId,
    visitId,
    isDisabled,
    statusAssesment,
    disposition,
    followUp,
    externalReferralLocation,
    externalReferralReason,
    ...stackProps
  } = props

  const isInline = useBreakpointValue({ base: false, md: true })

  return (
    <Stack isInline={isInline} spacing={3} {...stackProps}>
      <StatusAssessment
        patientId={patientId}
        visitId={visitId}
        statusAssesment={statusAssesment ?? ('' as StatusAssesmentType)}
        isDisabled={isDisabled}
      />

      <Disposition
        patientId={patientId}
        visitId={visitId}
        disposition={disposition ?? ('' as DispositionType)}
        isDisabled={isDisabled}
        externalReferralLocation={externalReferralLocation}
        externalReferralReason={externalReferralReason}
      />

      <FollowUp
        patientId={patientId}
        visitId={visitId}
        followUp={followUp ?? ('' as FollowUpType)}
        isDisabled={isDisabled}
      />
    </Stack>
  )
}
type DispositionProps = {
  patientId: string
  visitId: string
  disposition: DispositionType
  externalReferralLocation?: string
  externalReferralReason?: string
  isDisabled?: boolean
}

const Disposition: React.FC<DispositionProps> = (props) => {
  const {
    patientId,
    visitId,
    disposition,
    externalReferralLocation,
    externalReferralReason,
    isDisabled,
  } = props

  const intl = useIntl()

  const params = {
    patientId,
    visitId,
  }
  const { onChange, isLoading, error, onErrorClose } = useUpdateDisposition(params)

  const onDispositionChange = (value: DispositionType) => {
    const newValue = {
      disposition: value,
      externalReferralLocation,
      externalReferralReason,
    }

    onChange(newValue)
  }

  const onLocationChange = (value: string) => {
    const newValue = {
      disposition,
      externalReferralLocation: value,
      externalReferralReason,
    }

    onChange(newValue)
  }

  const onReasonChange = (value: string) => {
    const newValue = {
      disposition,
      externalReferralLocation,
      externalReferralReason: value,
    }

    onChange(newValue)
  }

  return (
    <Flex direction={'column'} flex={1}>
      <Text>{intl.formatMessage(messages.disposition)}</Text>
      <DispositionSelector
        value={disposition}
        onChange={onDispositionChange}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={false}
      />
      {disposition === DispositionType.EXTERNAL_REFERRAL && (
        <ExternalReferralInfo
          isDisabled={isLoading || isDisabled}
          location={externalReferralLocation}
          reason={externalReferralReason}
          onLocationChange={onLocationChange}
          onReasonChange={onReasonChange}
        />
      )}
      {error && <ErrorViewer title={error} onClose={onErrorClose} />}
    </Flex>
  )
}

type StatusAssessmentProps = {
  patientId: string
  visitId: string
  statusAssesment: StatusAssesmentType
  isDisabled?: boolean
}

const StatusAssessment: React.FC<StatusAssessmentProps> = (props) => {
  const { patientId, visitId, statusAssesment, isDisabled } = props

  const intl = useIntl()

  const params = {
    patientId,
    visitId,
  }
  const { onStatusAssessmentChange, isLoading, error, onErrorClose } =
    useUpdateStatusAssessment(params)

  return (
    <Flex direction={'column'} flex={1}>
      <Text>{intl.formatMessage(messages.statusAssessment)}</Text>
      <StatusAssessmentSelector
        value={statusAssesment}
        onChange={onStatusAssessmentChange}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={false}
      />
      {error && <ErrorViewer title={error} onClose={onErrorClose} />}
    </Flex>
  )
}

type FollowUpProps = {
  patientId: string
  visitId: string
  followUp: FollowUpType
  isDisabled?: boolean
}

const FollowUp: React.FC<FollowUpProps> = (props) => {
  const { patientId, visitId, followUp, isDisabled } = props

  const intl = useIntl()

  const params = {
    patientId,
    visitId,
  }
  const { onFollowUpChange, isLoading, error, onErrorClose } = useUpdateFollowUp(params)

  return (
    <Flex direction={'column'} flex={1}>
      <Text>{intl.formatMessage(messages.followUp)}</Text>
      <FollowUpSelector
        value={followUp}
        onChange={onFollowUpChange}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={false}
      />
      {error && <ErrorViewer title={error} onClose={onErrorClose} />}
    </Flex>
  )
}

type PrintableAssessmentAndDispositionProps = StackProps & {
  statusAssesment: Nullable<StatusAssesmentType>
  disposition: Nullable<DispositionType>
  followUp: Nullable<FollowUpType>
  externalReferralLocation: Nullable<string>
  externalReferralReason: Nullable<string>
}

export const PrintableAssessmentAndDisposition: React.FC<PrintableAssessmentAndDispositionProps> = (
  props
) => {
  const {
    statusAssesment,
    disposition,
    followUp,
    externalReferralLocation,
    externalReferralReason,
    ...stackProps
  } = props

  const intl = useIntl()

  return (
    <VStack alignItems={'start'} spacing={2} {...stackProps}>
      {statusAssesment && isValidStatusAssesment(statusAssesment) && (
        <HStack>
          <Text fontWeight={'bold'}>{intl.formatMessage(messages.statusAssessment)}:</Text>
          <Text>{intl.formatMessage(statusAssessmentTranslation[statusAssesment])}</Text>
        </HStack>
      )}

      {disposition && isValidDisposition(disposition) && (
        <VStack alignItems={'start'} spacing={0}>
          <HStack>
            <Text fontWeight={'bold'}>{intl.formatMessage(messages.disposition)}:</Text>
            <Text>{intl.formatMessage(dispositionTranslation[disposition])}</Text>
          </HStack>
          {disposition === DispositionType.EXTERNAL_REFERRAL && externalReferralLocation && (
            <HStack>
              <Text fontWeight={'bold'}>
                {intl.formatMessage(messages.externalReferralLocation)}:
              </Text>
              <Text>{externalReferralLocation}</Text>
            </HStack>
          )}
          {disposition === DispositionType.EXTERNAL_REFERRAL && externalReferralReason && (
            <HStack>
              <Text fontWeight={'bold'}>
                {intl.formatMessage(messages.externalReferralReason)}:
              </Text>
              <Text>{externalReferralReason}</Text>
            </HStack>
          )}
        </VStack>
      )}
      {followUp && isValidFollowUp(followUp) && (
        <HStack>
          <Text fontWeight={'bold'}>{intl.formatMessage(messages.followUp)}:</Text>
          <Text>{intl.formatMessage(followUpTranslation[followUp])}</Text>
        </HStack>
      )}
    </VStack>
  )
}

interface ExternalReferralInfoProps {
  location?: string
  reason?: string
  onReasonChange: (reason: string) => void
  onLocationChange: (location: string) => void
  isDisabled?: boolean
}

const ExternalReferralInfo: React.FC<ExternalReferralInfoProps> = (props) => {
  const { location, reason, isDisabled } = props

  const intl = useIntl()
  const { execute } = useDebounceExecute(400)

  const onReasonChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value: reason } = event.target
    execute(() => {
      props.onReasonChange(reason)
    })
  }

  const onLocationChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value: location } = event.target
    execute(() => {
      props.onLocationChange(location)
    })
  }

  return (
    <VStack mt={2}>
      <FormControl>
        <FormLabel>
          <Text>{intl.formatMessage(messages.externalReferralReason)}</Text>
        </FormLabel>
        <Input defaultValue={reason} onChange={onReasonChange} disabled={isDisabled} />
      </FormControl>
      <FormControl>
        <FormLabel>
          <Text>{intl.formatMessage(messages.externalReferralLocation)}</Text>
        </FormLabel>
        <Input defaultValue={location} onChange={onLocationChange} isDisabled={isDisabled} />
      </FormControl>
    </VStack>
  )
}
