import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { GET_CHECKLISTS } from '../../api/requests/checklists'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { BasicChecklist, PaginatedResult } from '../../api/interfaces'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface ChecklistsVariables {
  q?: string
  offset?: number
  limit?: number
}

interface ChecklistsQueryData {
  checklists: PaginatedResult<BasicChecklist>
}

export type UseChecklistsParams = QueryHookOptions<ChecklistsQueryData, ChecklistsVariables>

interface ReturnedValue {
  checklists: Array<BasicChecklist>
  count: number
  isLoading: boolean
  error: any
  getChecklist: (checklistId: string) => Nullable<BasicChecklist>
  refetch: (newArgs?: ChecklistsVariables) => void
  onErrorClose: () => void
}

export const useChecklists = (params?: UseChecklistsParams): ReturnedValue => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery(GET_CHECKLISTS, {
    onError,
    ...params,
  })

  const getChecklist = React.useCallback(
    (checklistId: string): Nullable<BasicChecklist> => {
      if (!data?.checklists) {
        return null
      }

      const { docs } = data.checklists
      const found = docs.find((checklist) => checklist.id === checklistId)

      return found ?? null
    },
    [data]
  )

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading,
    checklists: data?.checklists.docs ?? [],
    count: data?.checklists.count ?? 0,
    error,
    getChecklist,
    refetch,
    onErrorClose,
  }
}
