import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import {
  getNormalVitalsByAge,
  isInRange,
  calculatePercentileAndZScore,
  getHeightStandards,
} from 'wd-common/src/logic/triage/utils'
import { Height as HeightType } from '../../api/interfaces'
import {
  Box,
  Button,
  ButtonProps,
  Collapse,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagProps,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { TextField } from '../Form'
import { PatientAgeCategory } from '../Patients'
import { NewHeight, useCreateHeight, DeleteMeasurementButton } from './'
import { ErrorViewer, PrintableSignatureTag } from '..'
import { getAge } from '../DateAndTime'
import { PercentileWarning } from './PercentileWarning'
import {
  RiAddLine as AddIcon,
  RiAlertLine as WarningIcon,
  RiArrowRightSLine as ExpandButton,
} from 'react-icons/ri'
import { DateTime } from 'luxon'
import { MetricUnits, UnitTypes, useUnitConversion } from '../useUnitConversion'

const messages = defineMessages({
  title: {
    id: 'Vitals.height_title',
    defaultMessage: 'Height',
  },
  shortTitle: {
    id: 'Vitals.height_abbreviation',
    defaultMessage: 'HT',
  },
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?',
  },
  cm: {
    id: 'Vitals.cm_label',
    defaultMessage: 'cm',
  },
  reference: {
    id: 'Vitals.reference_title',
    defaultMessage: 'Reference',
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  submitButton: {
    id: 'NonStaffUI.button_submit',
    defaultMessage: 'Submit',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
  values: {
    id: 'UI.values_label',
    defaultMessage: 'Values',
  },
})

type MeasurementListProps = {
  values: Array<HeightType>
  patientId: string
  visitId: string
  birthdate: string
  sex: string
}

type HeightProps = ButtonProps & MeasurementListProps

export const Height: React.FC<HeightProps> = (props) => {
  const { values, patientId, visitId, birthdate, sex, ...buttonProps } = props

  const { onToggle, isOpen } = useDisclosure()

  const noValues = values.length === 0
  const intl = useIntl()

  return (
    <>
      <Button mr="-px" {...buttonProps} onClick={onToggle}>
        {noValues ? (
          <>
            <Icon as={AddIcon} />
            <Text ml={2}>{intl.formatMessage(messages.shortTitle)}</Text>
          </>
        ) : (
          <ValueWithWarning value={values[0]} birthdate={birthdate} sex={sex} />
        )}
      </Button>

      <HeightModal
        values={values}
        isOpen={isOpen}
        onToggle={onToggle}
        birthdate={birthdate}
        patientId={patientId}
        visitId={visitId}
        sex={sex}
      />
    </>
  )
}

const MeasurementList: React.FC<MeasurementListProps> = (props) => {
  const { values, patientId, visitId, birthdate, sex } = props

  const intl = useIntl()
  const { onToggle, isOpen } = useDisclosure({ defaultIsOpen: true })
  return (
    <Box px={4} py={2} my={2} borderWidth={1} rounded={'lg'}>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Heading size={'sm'} mb={1} onClick={onToggle} cursor="pointer">
          {intl.formatMessage(messages.values)}
        </Heading>
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant="ghost"
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}
        />
      </HStack>
      <Collapse startingHeight={0} in={isOpen} animateOpacity>
        {values.map((value, index) => {
          const key = `ht-${value.id}${index}`

          return (
            <HStack my={2} justifyContent={'space-between'} alignItems={'center'} key={key}>
              <VStack alignItems={'flex-start'} spacing={0}>
                <ValueWithWarning value={value} birthdate={birthdate} sex={sex} />
                <PrintableSignatureTag
                  signature={value.updated}
                  dateFormat={DateTime.DATETIME_SHORT}
                  fontSize="sm"
                  m={0}
                  color="GrayText"
                />
              </VStack>
              <DeleteMeasurementButton
                patientId={patientId}
                visitId={visitId}
                measurementId={value.id}
                size="sm"
              />
            </HStack>
          )
        })}
      </Collapse>
    </Box>
  )
}

type HeightModalProps = MeasurementListProps & {
  isOpen: boolean
  onToggle: () => void
}

const HeightModal: React.FC<HeightModalProps> = (props) => {
  const { patientId, visitId, values, birthdate, sex, isOpen, onToggle } = props

  const intl = useIntl()
  const { convertUnitFromInput, getUnitTranslation } = useUnitConversion()

  const params = {
    patientId,
    visitId,
  }
  const { onCreate, onErrorClose, error, isLoading, validate } = useCreateHeight(params)

  const onSubmit = (values) => {
    const newHeight: NewHeight = {
      cm: convertUnitFromInput(Number(values?.cm), UnitTypes.LENGTH),
    }

    onCreate(newHeight)
  }

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <Form
        onSubmit={onSubmit}
        validate={validate}
        keepDirtyOnReinitialize
        render={({ form, handleSubmit, values: formValues }) => {
          const { isWarningEnabled, percentile } = checkWarnings({
            birthdate,
            sex,
            height: convertUnitFromInput(formValues.cm, UnitTypes.LENGTH),
          })

          return (
            <form
              onSubmit={async (event) => {
                await handleSubmit(event)
                const { valid } = form.getState()
                if (valid) {
                  form.resetFieldState('cm')
                  form.reset()
                }
              }}
            >
              <ModalContent>
                <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <TextField
                    name="cm"
                    label={getUnitTranslation(MetricUnits.CM)}
                    type={'number'}
                    step={0.1}
                    after={
                      isWarningEnabled && !isNaN(percentile) ? (
                        <PercentileWarning percentile={percentile} />
                      ) : undefined
                    }
                    autoFocus={Boolean(form.getFieldState('cm')?.value)}
                  />

                  {error && <ErrorViewer title={error} onClose={onErrorClose} />}

                  <MeasurementList
                    patientId={patientId}
                    visitId={visitId}
                    birthdate={birthdate}
                    sex={sex}
                    values={values}
                  />

                  <VitalReference birthdate={birthdate} sex={sex} />
                </ModalBody>

                <ModalFooter>
                  <Button isFullWidth={isFullWidth} onClick={onToggle}>
                    {intl.formatMessage(messages.cancelButton)}
                  </Button>
                  <Button
                    colorScheme={'brand'}
                    isLoading={isLoading}
                    type="submit"
                    isFullWidth={isFullWidth}
                    ml={2}
                  >
                    {intl.formatMessage(messages.submitButton)}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )
        }}
      />
    </Modal>
  )
}

interface CheckWarningsParams {
  birthdate: string
  sex: string
  height: number
}

const checkWarnings = (params: CheckWarningsParams) => {
  const { height, sex, birthdate } = params

  const calcualtion = calculatePercentileAndZScore(height, getHeightStandards({ sex, birthdate }))
  const percentile = calcualtion?.percentile

  const reference = getNormalVitalsByAge({ sex, birthdate })
  const htReference = (reference || {}).ht

  const inRange =
    !htReference ||
    !height ||
    isInRange((htReference || {}).min, (htReference || {}).max, percentile)

  // only show warning for patients < 19y
  const ageInYears = getAge(new Date(birthdate), 'years')
  const isWarningEnabled = ageInYears !== null && ageInYears < 19

  return {
    inRange,
    reference: htReference,
    isWarningEnabled,
    percentile,
  }
}

type ValueWithWarningProps = {
  value: HeightType
  birthdate: string
  sex: string
}

const ValueWithWarning: React.FC<ValueWithWarningProps> = (props) => {
  const { value, sex, birthdate } = props

  const intl = useIntl()
  const { convertUnitFromDb, getUnitTranslation } = useUnitConversion()

  const { isWarningEnabled, inRange, percentile } = checkWarnings({
    birthdate,
    sex,
    height: value?.cm,
  })

  return (
    <HStack alignItems={'center'}>
      {!inRange && <Icon as={WarningIcon} color="red.400" />}
      <Text mr={1}>{intl.formatMessage(messages.shortTitle)}:</Text>
      <Text fontWeight={'bold'} color={!inRange ? 'red.400' : undefined}>
        {intl.formatNumber(convertUnitFromDb(value?.cm, UnitTypes.LENGTH)) ?? '--'}
        {getUnitTranslation(MetricUnits.CM)}
      </Text>
      {isWarningEnabled && <PercentileWarning percentile={percentile} />}
    </HStack>
  )
}

type VitalReferenceProps = {
  birthdate: string
  sex: string
}

const VitalReference: React.FC<VitalReferenceProps> = (props) => {
  const { birthdate, sex } = props

  const intl = useIntl()

  const reference = getNormalVitalsByAge({ sex, birthdate })
  const htReference = (reference || {}).ht

  if (!htReference) {
    return null
  }

  return (
    <Box px={4} py={2} my={2} borderWidth={1} rounded={'lg'}>
      <PatientAgeCategory birthdate={birthdate} mb={1} fontWeight={'bold'} />
      <HStack>
        <Text>{intl.formatMessage(messages.reference)}</Text>
        <Text fontWeight={'bold'} ml={2}>
          {`${htReference.min} - ${htReference.max} %`}
        </Text>
      </HStack>
    </Box>
  )
}

type PrintableHeightProps = TagProps & {
  birthdate: string
  sex: string
  value: HeightType
}

export const PrintableHeight: React.FC<PrintableHeightProps> = (props) => {
  const { value, birthdate, sex, ...tagProps } = props

  return (
    <Tag {...tagProps}>
      <ValueWithWarning value={value} birthdate={birthdate} sex={sex} />
    </Tag>
  )
}

type PdfHeightProps = {
  birthdate: string
  sex: string
  value: HeightType
}

export const PdfHeight: React.FC<PdfHeightProps> = (props) => {
  const { value, birthdate, sex } = props
  return <ValueWithWarning value={value} birthdate={birthdate} sex={sex} />
}
