import React from 'react'
import { evaluateQualityEquation } from 'wd-common/src/logic/checklists/utils'
import { FullChecklist, QualityCheck, QualityEquationValue } from '../../api/interfaces'
import { RiCloseCircleLine as UnCheckIcon, RiCheckboxCircleLine as CheckIcon } from 'react-icons/ri'
import { DynamicTranslation } from '../i18n'
import { Box, BoxProps, HStack, Icon, Tag, TagLabel, Text, useDisclosure } from '@chakra-ui/react'
import { QualityTreeModal } from './QualityTreeModal'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  explainButton: {
    id: 'UI.button_explain',
    defaultMessage: 'Explain',
  },
})

type QualityEquationProps = BoxProps & {
  name: string
  result: QualityEquationValue
  onClick?: () => void
}

export const QualityEquationResultBox: React.FC<QualityEquationProps> = (props) => {
  const { name, result, onClick, ...restBoxProps } = props

  const intl = useIntl()
  const value = !!result?.val
  const ResultIcon = value ? CheckIcon : UnCheckIcon

  // it needs to be translated
  const nameMessage = {
    text: name,
    type: 'translatable_text',
  }

  const boxProps = {
    bg: value ? 'green.400' : 'red.400',
    padding: 2,
    flex: '1',
    cursor: 'pointer',
    borderRadius: 'md',
    _hover: {
      bg: value ? 'green.500' : 'red.500',
    },
    onClick,
    ...restBoxProps,
  }

  return (
    <Box {...boxProps}>
      <HStack spacing={1} flexWrap="wrap" justifyContent={'center'} alignItems={'center'}>
        <Icon as={ResultIcon} fontSize="lg" />
        <DynamicTranslation message={nameMessage} fontSize="md" fontWeight={'bold'} />
      </HStack>
      <Text fontSize={'xs'} flex={1} textAlign="center">
        {intl.formatMessage(messages.explainButton)}
      </Text>
    </Box>
  )
}

export const QualityEquationResultTag: React.FC<QualityEquationProps> = (props) => {
  const { name, result } = props

  const value = !!result?.val
  const ResultIcon = value ? CheckIcon : UnCheckIcon

  // it needs to be translated
  const message = {
    text: name,
    type: 'translatable_text',
  }

  return (
    <Tag size="md" colorScheme={value ? 'green' : 'red'} borderRadius="full">
      <Icon as={ResultIcon} />
      <TagLabel ml={1}>
        <DynamicTranslation message={message} />
      </TagLabel>
    </Tag>
  )
}

type QualityCheckProps = {
  item: QualityCheck
  checklist: FullChecklist
}

export const QualityCheckResult: React.FC<QualityCheckProps> = (props) => {
  const { item, checklist } = props

  const { name, equation } = item
  const { isOpen, onClose, onOpen } = useDisclosure()

  if (!name) {
    return null
  }

  const result = evaluateQualityEquation(equation, checklist)
  return (
    <>
      <QualityEquationResultBox onClick={onOpen} name={name} result={result} />
      {isOpen && (
        <QualityTreeModal
          checklist={checklist}
          isOpen={isOpen}
          onClose={onClose}
          qualityCheck={item}
        />
      )}
    </>
  )
}
