import { ApolloError } from '@apollo/client'
import { FullChecklist, Sex, TranslatableText } from './api/interfaces'
export type Nullable<T> = null | T

/**
 * validations
 */
export const isValidSex = (value: string) => {
  return (Object.values(Sex) as Array<string>).includes(value)
}

export const isValidObjectId = (value: string) => {
  const validObjectId = /^[0-9a-fA-F]{24}$/
  return validObjectId.test(value)
}

/**
 * assertions
 */
export function validateNotNil<T>(value: T | null | undefined): asserts value is T {
  if (value === null || value === undefined) {
    throw new TypeError('Value is nil')
  }
}

export function validateString(value: unknown): asserts value is string {
  validateNotNil(value)
  if (typeof value !== 'string') {
    throw new TypeError('Value is not a string')
  }
}

export function validateArray(value: unknown): asserts value is Array<any> {
  validateNotNil<unknown>(value)

  if (!Array.isArray(value)) {
    throw new TypeError('Value is not an array')
  }
}

export function validateNonEmptyObject(value: unknown): asserts value is { [k: string]: any } {
  validateNotNil(value)

  if (typeof value !== 'object') {
    throw new TypeError('Value is not an object')
  }

  if (Object.keys(value as object).length === 0) {
    throw new TypeError('Value is an empty object')
  }
}

/**
 * extract graphql errors
 */
export const extractFirstErrorCode = (error: ApolloError): string => {
  const [firstError] = error.graphQLErrors

  return firstError?.extensions?.code as string
}

/**
 * Use this in your `switch` default cases so typescript yells
 * when you miss a possible case. Example:
 *
 * enum Things {
 *     first,
 *     second,
 * }
 *
 * function doSomething(case: Things): void {
 *     switch(case) {
 *         case Things.first:
 *             console.log('first!')
 *
 *         case Things.second:
 *             console.log('second!')
 *
 *         default:
 *             assertUnreachable('Invalida case', case)
 *     }
 * }
 *
 * If you add a `third` value to the `Things` enum, and forget to add the
 * case to the switch, typescript will show a compile error at the
 * `assertUnreachable` line.
 *
 */
export function assertUnreachable(message: string, value: never): never {
  throw new Error(`${message} ${value}`)
}

/**
 * Cleaning codes from checklists
 */

export type ClearingCodesWithText = Map<string, Array<TranslatableText>>

export const getPhysicalExamClearingCodesFromChecklists = (
  checklists: Array<FullChecklist>
): ClearingCodesWithText => {
  const checklistItems = checklists.map((checklist) => checklist.physical_exam ?? []).flat()

  const initialValue = new Map<string, Array<TranslatableText>>()

  // cleaning codes from physical exam section to be use inside the exam
  const clearingCodes = checklistItems
    .filter((entry) => {
      const isChecked = entry.checked
      const hasCodes =
        Array.isArray(entry.physical_exam_codes) && entry.physical_exam_codes.length > 0

      return isChecked && hasCodes
    })
    .reduce((map, entry) => {
      const { physical_exam_codes, name } = entry

      if (!name || !Array.isArray(physical_exam_codes)) {
        return map
      }

      physical_exam_codes.forEach((code) => {
        const prev = map.get(code)
        // exist any other item with same cleaning code
        if (prev) {
          // if the item we are about to add doesn't already exist we add it
          if (!prev.some((p) => p.text === name.text)) {
            const newValue = [...prev, name]
            map.set(code, newValue)
          }
        } else {
          // if not, add a new array
          map.set(code, [name])
        }
      })

      return map
    }, initialValue)

  return clearingCodes
}

export const getHistoryClearingCodesFromChecklists = (
  checklists: Array<FullChecklist>
): ClearingCodesWithText => {
  const checklistItems = checklists.map((checklist) => checklist.history ?? []).flat()

  const initialValue = new Map<string, Array<TranslatableText>>()

  // cleaning codes from physical exam section to be use inside the exam
  const clearingCodes = checklistItems
    .filter((entry) => {
      const isChecked = entry.checked
      const hasCodes = Array.isArray(entry.history_codes) && entry.history_codes.length > 0

      return isChecked && hasCodes
    })
    .reduce((map, entry) => {
      const { history_codes, name } = entry

      if (!name || !Array.isArray(history_codes)) {
        return map
      }

      history_codes.forEach((code) => {
        const prev = map.get(code)
        // exist any other item with same cleaning code
        if (prev) {
          // if the item we are about to add doesn't already exist we add it
          if (!prev.some((p) => p.text === name.text)) {
            const newValue = [...prev, name]
            map.set(code, newValue)
          }
        } else {
          // if not, add a new array
          map.set(code, [name])
        }
      })

      return map
    }, initialValue)

  return clearingCodes
}
