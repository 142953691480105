import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import {
  getNormalVitalsByAge,
  isInRange,
} from 'wd-common/src/logic/triage/utils'
import { Nullable } from '../../utils'
import { RespiratoryRate as RespiratoryRateType } from '../../api/interfaces'
import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagProps,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { TextField, ValidateFn } from '../Form'
import { PatientAgeCategory } from '../Patients'
import { NewRespiratoryRate, useCreateRespiratoryRate, useUpdateRespiratoryRate } from '.'
import { ConfirmButton, ErrorViewer } from '..'
import { useRemoveMeasurement } from './useRemoveMeasurement'
import {
  RiAddLine as AddIcon,
  RiDeleteBin2Line as DeleteIcon,
  RiAlertLine as WarningIcon
} from 'react-icons/ri'

const messages = defineMessages({
  title: {
    id: 'Vitals.respiratory_rate_title',
    defaultMessage: 'Respiratory Rate'
  },
  shortTitle: {
    id: 'Vitals.respiratory_rate_abbreviation',
    defaultMessage: 'RR'
  },
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?'
  },
  breathsPerMinute: {
    id: 'Vitals.breaths_per_minute',
    defaultMessage: '/min'
  },
  reference: {
    id: 'Vitals.reference_title',
    defaultMessage: 'Reference'
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  submitButton: {
    id: 'NonStaffUI.button_submit',
    defaultMessage: 'Submit',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
})

type RespiratoryRateProps = ButtonGroupProps & {
  patientId: string
  visitId: string
  birthdate: string
  value: Nullable<RespiratoryRateType>
  isDisabled?: boolean
}

export const RespiratoryRate: React.FC<RespiratoryRateProps> = (props) => {
  const { value, patientId, visitId, birthdate, isDisabled, ...buttonGroupProps } = props

  if (value) {
    return (
      <Editor
        value={value}
        patientId={patientId}
        visitId={visitId}
        birthdate={birthdate}
        isDisabled={isDisabled}
        {...buttonGroupProps}
      />
    )
  }

  return (
    <Creator
      patientId={patientId}
      visitId={visitId}
      birthdate={birthdate}
      isDisabled={isDisabled}
      {...buttonGroupProps}
    />
  )
}

type CreatorProps = ButtonGroupProps & {
  patientId: string
  visitId: string
  birthdate: string
  isDisabled?: boolean
}

const Creator: React.FC<CreatorProps> = (props) => {
  const { patientId, visitId, birthdate, isDisabled, ...buttonGroupProps } = props

  const intl = useIntl()

  const { onToggle, isOpen } = useDisclosure()

  const params = {
    patientId,
    visitId,
  }
  const { onCreate, onErrorClose, error, isLoading, validate } = useCreateRespiratoryRate(params)

  return (
    <>
      <ButtonGroup size="sm" isAttached isDisabled={isDisabled} {...buttonGroupProps}>
        <Button mr="-px" onClick={onToggle}>
          <Icon as={AddIcon} />
          <Text ml={2}>{intl.formatMessage(messages.shortTitle)}</Text>
        </Button>
      </ButtonGroup>

      <RespiratoryRateModal
        rr={null}
        isOpen={isOpen}
        onToggle={onToggle}
        birthdate={birthdate}
        onSubmit={onCreate}
        onErrorClose={onErrorClose}
        error={error}
        isLoading={isLoading}
        validate={validate}
      />

    </>
  )
}

type EditorProps = ButtonGroupProps & {
  patientId: string
  visitId: string
  value: Nullable<RespiratoryRateType>
  birthdate: string
  isDisabled?: boolean
}

const Editor: React.FC<EditorProps> = (props) => {
  const { patientId, visitId, birthdate, isDisabled, value, ...buttonGroupProps } = props

  const intl = useIntl()

  const { onToggle, isOpen } = useDisclosure()

  const isLegacy = !value?.id
  const removeParams = {
    patientId,
    visitId,
  }

  const { onDelete, isLoading, error, onErrorClose } = useRemoveMeasurement(removeParams)

  const updateParams = {
    ...removeParams,
    onCompleted: onToggle,
  }

  const {
    onUpdate,
    onErrorClose: onUpdatingErrorClose,
    error: updatingError,
    isLoading: isUpdating,
    validate,
  } = useUpdateRespiratoryRate(updateParams)

  const onSubmit = (values: NewRespiratoryRate) => {
    if (isLegacy) {
      return null
    }

    const measurementId = value.id
    onUpdate(measurementId, values)
  }

  return (
    <>
      <ButtonGroup size="sm" isAttached isDisabled={isDisabled} {...buttonGroupProps}>
        <Button mr="-px" onClick={onToggle}>
          <ValueWithWarning rr={value} birthdate={birthdate} />
        </Button>
        {!isLegacy && (
          <ConfirmButton
            onConfirm={() => onDelete(value.id)}
            message={intl.formatMessage(messages.confirmationMessage)}
            buttonProps={{
              color: 'red.400',
              label: <Icon as={DeleteIcon} />,
              isDisabled,
              isLoading,
            }}
            error={error}
            onErrorClose={onErrorClose}
          />
        )}
      </ButtonGroup>

      <RespiratoryRateModal
        rr={value}
        isOpen={isOpen}
        onToggle={onToggle}
        birthdate={birthdate}
        onSubmit={onSubmit}
        onErrorClose={onUpdatingErrorClose}
        error={updatingError}
        isLoading={isUpdating}
        validate={validate}
      />

    </>
  )
}

type RespiratoryRateModalProps = {
  birthdate: string
  rr: Nullable<RespiratoryRateType>
  isOpen: boolean
  onToggle: () => void
  onSubmit: (values: NewRespiratoryRate) => void
  validate: ValidateFn<NewRespiratoryRate>
  error: Nullable<string>
  onErrorClose: () => void
  isLoading: boolean
  isDisabled?: boolean
}

const RespiratoryRateModal: React.FC<RespiratoryRateModalProps> = (props) => {
  const { validate, onSubmit, error, onErrorClose, isLoading, rr, birthdate, isDisabled, isOpen, onToggle } = props

  const intl = useIntl()

  const _onSubmit = (values) => {
    const newRespiratoryRate: NewRespiratoryRate = {
      breaths_per_minute: parseFloat(values?.breaths_per_minute),
    }

    onSubmit(newRespiratoryRate)
  }

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <Form
        onSubmit={_onSubmit}
        validate={validate}
        initialValues={rr}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TextField
                  name="breaths_per_minute"
                  label={intl.formatMessage(messages.breathsPerMinute)}
                  type={'number'}
                  autoFocus
                />

                <VitalReference birthdate={birthdate} rr={rr} />
                {error && <ErrorViewer title={error} onClose={onErrorClose} />}
              </ModalBody>

              <ModalFooter>
                <Button isFullWidth={isFullWidth} onClick={onToggle}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  colorScheme={'brand'}
                  isLoading={isLoading}
                  type="submit"
                  isDisabled={isDisabled}
                  isFullWidth={isFullWidth}
                  ml={2}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        )}
      />
    </Modal>
  )
}

const checkWarnings = (patient, rr) => {
  const reference = getNormalVitalsByAge(patient)
  const rrReference = (reference || {}).rr

  const inRange = !rrReference || !rr || isInRange((rrReference || {}).min, (rrReference || {}).max, rr)

  return {
    inRange,
    reference: rrReference
  }
}

type ValueWithWarningProps = {
  rr: Nullable<RespiratoryRateType>
  birthdate: string
}

const ValueWithWarning: React.FC<ValueWithWarningProps> = (props) => {
  const { rr, birthdate } = props

  const intl = useIntl()

  const { inRange } = checkWarnings({ birthdate }, rr?.breaths_per_minute)

  return (
    <HStack alignItems={'center'}>
      {!inRange && <Icon as={WarningIcon} color="red.400" />}
      <Text mx={1}>{intl.formatMessage(messages.shortTitle)}:</Text>
      <Text fontWeight={'bold'} color={!inRange ? 'red.400' : undefined}>
        {rr?.breaths_per_minute ?? '--'}
        {intl.formatMessage(messages.breathsPerMinute)}
      </Text>
    </HStack>
  )
}

type VitalReferenceProps = {
  rr: Nullable<RespiratoryRateType>
  birthdate: string
}

const VitalReference: React.FC<VitalReferenceProps> = (props) => {
  const { rr, birthdate } = props

  const intl = useIntl()

  const { inRange, reference } = checkWarnings({ birthdate }, rr?.breaths_per_minute)

  if (!reference) {
    return null
  }

  return (
    <Box p={4} borderWidth={1} rounded={'lg'}>
      <PatientAgeCategory birthdate={birthdate} mb={1} fontWeight={'bold'} />
      <HStack>
        <Text>{intl.formatMessage(messages.reference)}</Text>
        <Text fontWeight={'bold'} ml={2}>
          {`${reference.min} - ${reference.max} ${intl.formatMessage(messages.breathsPerMinute)}`}
        </Text>
        {!inRange && <Icon as={WarningIcon} ml={2} color="red.400" />}
      </HStack>
    </Box>
  )
}

type PrintableRespiratoryRateProps = TagProps & {
  birthdate: string
  value: Nullable<RespiratoryRateType>
}

export const PrintableRespiratoryRate: React.FC<PrintableRespiratoryRateProps> = (props) => {
  const { value, birthdate, ...tagProps } = props

  return <Tag {...tagProps}>
    <ValueWithWarning
      rr={value}
      birthdate={birthdate}
    />
  </Tag>
}