import React from 'react'
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react'
import { sitemap } from '../Routing'
import { defineMessages, useIntl } from 'react-intl'
import { useSession } from '../Auth'

import {
  RiSearch2Line as SearchIcon,
  RiTimeLine as WaitingIcon,
  RiUserAddLine as RegisterPatientIcon,
  RiHome4Line as HomeIcon,
  RiHistoryLine as HistoryIcon,
  RiMoneyDollarCircleLine as PaymentIcon,
  RiDashboard3Line as MetricsIcon,
  RiPriceTag3Line as PriceIcon,
  RiFileList3Line as BillingIcon,
  RiAdminLine as AdminIcon,
  RiLayout5Line as ContentIcon,
  RiCalendarEventFill as CalendarIcon,
} from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { AccountStatus } from '../Auth/subscription'
import { useSystems } from '../Systems'
const messages = defineMessages({
  patientSearch: {
    id: 'Nav.patient_search',
    defaultMessage: 'Patient Search',
  },
  waitingPatients: {
    id: 'Nav.waiting_patients',
    defaultMessage: 'Waiting Patients',
  },
  pastVisits: {
    id: 'Nav.past_visits',
    defaultMessage: 'Past Visits',
  },
  registerPatient: {
    id: 'Nav.register_patient',
    defaultMessage: 'Register Patient',
  },
  metrics: {
    id: 'Nav.metrics',
    defaultMessage: 'Metrics',
  },
  billing: {
    id: 'Nav.billing',
    defaultMessage: 'Billing',
  },
  paymentTypes: {
    id: 'Nav.paymentTypes',
    defaultMessage: 'Payment Types',
  },
  priceEditor: {
    id: 'Nav.priceeditor',
    defaultMessage: 'Price Editor',
  },
  scheduler: {
    id: 'HealthSystemViewer.tab_scheduler',
    defaultMessage: 'Scheduler',
  },
  // admins
  staff: {
    id: 'Nav.staff_admin',
    defaultMessage: 'Manage Staff',
  },
  content: {
    id: 'Nav.content_admin',
    defaultMessage: 'App Content',
  },
  // available during the account setup
  accountSetup: {
    id: 'Nav.account_setup',
    defaultMessage: 'Account setup',
  },
})

interface AppDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const AppDrawer: React.FC<AppDrawerProps> = (props) => {
  const { isOpen, onClose } = props

  const { getAccountStatus } = useSession()
  const accountStatus = getAccountStatus()

  if (
    accountStatus === AccountStatus.READ_ONLY_ACCESS ||
    accountStatus === AccountStatus.FULL_ACCESS
  ) {
    return <FullDrawer isOpen={isOpen} onClose={onClose} />
  }

  return <SetupDrawer isOpen={isOpen} onClose={onClose} />
}

const SetupDrawer: React.FC<AppDrawerProps> = (props) => {
  const { isOpen, onClose } = props
  const intl = useIntl()

  return (
    <Drawer placement={'left'} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">Walking Docs</DrawerHeader>
        <DrawerCloseButton size={'lg'} />
        <DrawerBody>
          <VStack spacing={2}>
            <DrawerItem
              navigateTo={sitemap.setup.route}
              label={intl.formatMessage(messages.accountSetup)}
              icon={<Icon as={HomeIcon} />}
            />
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

const FullDrawer: React.FC<AppDrawerProps> = (props) => {
  const { isOpen, onClose } = props
  const intl = useIntl()

  const { isAdmin } = useSession()
  const { getMyLocation } = useSystems()
  const { name } = getMyLocation()

  return (
    <Drawer placement={'left'} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">{name}</DrawerHeader>
        <DrawerCloseButton size={'lg'} />
        <DrawerBody>
          <VStack spacing={2}>
            <DrawerItem
              navigateTo={sitemap.patients.routes.list.absolutePath}
              label={intl.formatMessage(messages.patientSearch)}
              icon={<Icon as={SearchIcon} />}
            />
            <DrawerItem
              navigateTo={sitemap.patients.routes.waiting.absolutePath}
              label={intl.formatMessage(messages.waitingPatients)}
              icon={<Icon as={WaitingIcon} />}
            />
            <DrawerItem
              navigateTo={sitemap.patients.routes.registration.absolutePath}
              label={intl.formatMessage(messages.registerPatient)}
              icon={<Icon as={RegisterPatientIcon} />}
            />
            <DrawerItem
              navigateTo={sitemap.visits.routes.past.absolutePath}
              label={intl.formatMessage(messages.pastVisits)}
              icon={<Icon as={HistoryIcon} />}
            />
            <DrawerItem
              navigateTo={sitemap.billing.routes.list.absolutePath}
              label={intl.formatMessage(messages.billing)}
              icon={<Icon as={BillingIcon} />}
            />

            <Divider />

            <DrawerItem
              navigateTo={sitemap.metrics.routes.dashboard.absolutePath}
              label={intl.formatMessage(messages.metrics)}
              icon={<Icon as={MetricsIcon} />}
            />
            <DrawerItem
              navigateTo={sitemap.pricing.routes.pricelists.absolutePath}
              label={intl.formatMessage(messages.paymentTypes)}
              icon={<Icon as={PaymentIcon} />}
            />
            <DrawerItem
              navigateTo={sitemap.pricing.routes.prices.absolutePath}
              label={intl.formatMessage(messages.priceEditor)}
              icon={<Icon as={PriceIcon} />}
            />
            <DrawerItem
              navigateTo={sitemap.scheduler.routes.calendar.absolutePath}
              label={intl.formatMessage(messages.scheduler)}
              icon={<Icon as={CalendarIcon} />}
            />

            {isAdmin() && <Divider />}
            {isAdmin() && (
              <DrawerItem
                navigateTo={sitemap.staff.routes.list.absolutePath}
                label={intl.formatMessage(messages.staff)}
                icon={<Icon as={AdminIcon} />}
              />
            )}
            {isAdmin() && (
              <DrawerItem
                navigateTo={sitemap.appContent.route}
                label={intl.formatMessage(messages.content)}
                icon={<Icon as={ContentIcon} />}
              />
            )}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

interface DrawerItemProps {
  navigateTo: string
  label: string
  icon?: React.ReactElement
}

const DrawerItem: React.FC<DrawerItemProps> = (props) => {
  const { navigateTo, label, icon } = props
  const navigate = useNavigate()

  const onClick = () => {
    navigate(navigateTo)
  }
  return (
    <Button
      onClick={onClick}
      justifyContent={'start'}
      alignItems={'center'}
      variant={'ghost'}
      isFullWidth
    >
      {icon}
      <Text ml={4}>{label}</Text>
    </Button>
  )
}
