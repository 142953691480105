import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { isSecure, ValidateFn, ValidationError } from '../Form'
import { Nullable } from '../../utils'
import { useSession } from '.'
import { sitemap } from '../Routing/sitemap'
import { ChangePasswordParams } from './session'

const messages = defineMessages({
  required: {
    id: 'ForgotPassword.this_field_is_required',
    defaultMessage: 'This field is required',
  },
  limitExceededException: {
    id: 'ForgotPassword.LimitExceededException',
    defaultMessage: 'Attempt limit exceeded. Please, try again in a few minutes',
  },

  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  invalidPassword: {
    id: 'StaffRegister.insecure_password',
    defaultMessage: 'Choose a more secure password',
  },
  invalidRePassword: {
    id: 'StaffRegister.invalid_re_password',
    defaultMessage: 'The passwords do not match',
  },
})

interface ChangePasswordForm extends ChangePasswordParams{
  reNewPassword: string
}

export const useChangePassword = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const { changePassword, getUsername } = useSession()

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'LimitExceededException':
        setError(intl.formatMessage(messages.limitExceededException))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const validate: ValidateFn<ChangePasswordForm> = async (values) => {
    const errors: ValidationError<ChangePasswordForm> = {}

    if (!values.oldPassword) {
      errors.oldPassword = intl.formatMessage(messages.required)
    }

    if (!values.newPassword) {
      errors.newPassword = intl.formatMessage(messages.required)
    } else {
      const username = getUsername() ?? ''
      const isPasswordSecure = await isSecure(values.newPassword, [username])

      if (!isPasswordSecure) {
        errors.newPassword = intl.formatMessage(messages.invalidPassword)
      }
    }

    if (values.newPassword !== values.reNewPassword) {
      errors.reNewPassword = intl.formatMessage(messages.invalidRePassword)
    }


    return errors
  }

  const onSubmit = async (values: ChangePasswordForm) => {
    setIsLoading(true)
    setError(null)
    try {
      await changePassword(values)
      
      navigate(sitemap.preferences.routes.account.absolutePath)
    } catch (e) {
      setIsLoading(false)
      translateError(e)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }
  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}
