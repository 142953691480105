import { API, BlockTool, BlockToolData } from '@editorjs/editorjs'
import React from 'react'
import { SelectorIcon } from './icons'

interface Data {
  value: string
  label: string
  options: Array<string>
}

interface Config {
  isFillable: boolean
  onInputChange: (blockId: string, value: string) => void
  styles: Pick<React.CSSProperties, 'background' | 'color'>
}

const DEFAULT_VALUE: Data = {
  label: 'Label',
  value: '',
  options: [],
}

class Selector implements BlockTool {
  private data: Data
  private api: API
  private config: Config
  private select: HTMLSelectElement
  private optionInput: HTMLInputElement
  private optionList: HTMLUListElement
  private uniqueId: string

  constructor(params) {
    this.api = params.api
    this.config = params.config
    this.data = {
      label: params?.data?.label ?? DEFAULT_VALUE.label,
      value: params?.data?.value ?? DEFAULT_VALUE.value,
      options: params?.data?.options ?? DEFAULT_VALUE.options,
    }

    this.uniqueId = params.block.id
    this.select = document.createElement('select')
    this.optionInput = document.createElement('input')
    this.optionList = document.createElement('ul')

    /**
     * This work outside the editor js api
     * because the read only flag does not
     * allow us to save data.
     */
    if (this.config.isFillable) {
      this.select.addEventListener('change', (event: Event) => {
        const value = (<HTMLInputElement>event.target).value

        this.config.onInputChange(this.uniqueId, value)
      })
    }
  }

  render(): HTMLElement {
    const view = document.createElement('div')
    const label = document.createElement('label')

    label.contentEditable = this.api.readOnly.isEnabled ? 'false' : 'true'
    label.innerHTML = this.data.label
    label.htmlFor = this.uniqueId

    this.data.options.forEach((optionName) => {
      const isSelected = optionName === this.data.value

      const option = document.createElement('option')
      option.style.background = this.config.styles.background as string
      option.style.color = this.config.styles.color as string
      option.style.fontSize = '1rem'
      option.style.height = '2.5rem'
      option.style.padding = '1rem'
      option.style.boxSizing = 'border-box'
      option.value = optionName
      option.text = optionName

      if (isSelected) {
        option.selected = true
      }

      this.select.appendChild(option)
    })

    this.select.disabled = !this.config.isFillable
    this.select.id = this.uniqueId

    this.select.style.background = 'inherit'
    this.select.style.border = '1px solid'
    this.select.style.borderRadius = '0.375rem'
    this.select.style.padding = ' .5rem'
    this.select.style.fontSize = '1rem'
    this.select.style.height = '2.5rem'

    view.style.display = 'flex'
    view.style.alignItems = 'center'
    view.style.justifyContent = 'space-between'
    view.style.gap = '5px'
    view.style.marginBottom = '10px'
    view.style.marginTop = '10px'
    view.style.flexWrap = 'wrap'

    view.appendChild(label)
    view.appendChild(this.select)

    return view
  }

  validate?(blockData: BlockToolData): boolean {
    return blockData.label && Array.isArray(this.data.options) && this.data.options.length >= 2
  }

  save(blockContent: HTMLElement): BlockToolData {
    const label = blockContent.querySelector('[contenteditable]')
    const selector = blockContent.querySelector(`#${this.uniqueId}`)

    if (selector) {
      const value = (selector as HTMLSelectElement).value

      return {
        label: label?.innerHTML,
        options: this.data.options,
        value,
      }
    }

    return {
      label: label?.innerHTML,
      value: '',
      options: this.data.options,
    }
  }

  renderSettings() {
    const wrapper = document.createElement('div')
    wrapper.style.padding = '0.25rem'
    wrapper.style.minWidth = '200px'

    const inputGroup = document.createElement('div')
    const addButton = document.createElement('button')

    this.optionList.style.padding = '0'
    this.optionList.style.marginBottom = '.5rem'
    this.optionList.style.maxHeight = '150px'
    this.optionList.style.overflowY = 'auto'
    this.data.options.forEach((optionName, index) => {
      this.addItemToList(optionName, index)
    })

    inputGroup.style.display = 'flex'
    inputGroup.style.alignItems = 'center'
    inputGroup.style.justifyContent = 'flex-start'
    inputGroup.style.width = '100%'

    this.optionInput.type = 'text'
    this.optionInput.name = 'option-input'
    this.optionInput.id = 'option-input'
    this.optionInput.style.height = '2rem'
    this.optionInput.style.borderRadius = '3px'
    this.optionInput.style.borderWidth = '1px'
    this.optionInput.style.padding = '0 .25rem'
    this.optionInput.style.flex = '1'
    this.optionInput.placeholder = 'Add an option'

    this.optionInput.classList.add(this.api.styles.input)

    addButton.classList.add(this.api.styles.settingsButton)
    addButton.innerText = '+'

    addButton.addEventListener('click', (event) => {
      event.preventDefault()
      event.stopPropagation()

      this.addOption(this.optionInput.value)
    })

    inputGroup.appendChild(this.optionInput)
    inputGroup.appendChild(addButton)

    wrapper.appendChild(this.optionList)
    wrapper.appendChild(inputGroup)

    return wrapper
  }

  addItemToList(newOption: string, index?: number) {
    const wrapper = document.createElement('li')
    const option = document.createElement('span')
    const deleteButton = document.createElement('button')

    deleteButton.classList.add(this.api.styles.settingsButton)
    deleteButton.innerText = 'x'
    deleteButton.style.color = 'red'
    deleteButton.addEventListener('click', () => {
      this.removeOption(index ?? this.data.options.length - 1)
    })

    option.innerText = newOption

    wrapper.style.padding = '.25rem'
    wrapper.style.display = 'flex'
    wrapper.style.alignItems = 'center'
    wrapper.style.justifyContent = 'space-between'

    wrapper.appendChild(option)
    wrapper.appendChild(deleteButton)

    this.optionList.appendChild(wrapper)
  }

  addOption(newOption: string) {
    if (newOption === '') return

    this.data.options = [...this.data.options, newOption]

    this.addItemToList(newOption)

    const option = document.createElement('option')
    option.value = newOption
    option.innerText = newOption

    this.select.appendChild(option)
    this.optionInput.value = ''
  }

  removeOption(index: number) {
    this.data.options = [...this.data.options.slice(0, index), ...this.data.options.slice(index + 1)]

    this.optionList.removeChild(this.optionList.childNodes[index])
    this.select.removeChild(this.select.childNodes[index])
  }

  /**
   * Displaying at the toolbox
   */
  static get toolbox() {
    return {
      title: 'Option list',
      icon: SelectorIcon,
    }
  }

  /**
   * Notify core that the read-only mode is supported
   *
   * @returns {boolean}
   * @public
   */
  static get isReadOnlySupported() {
    return true
  }
}

export default Selector
