import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { ADD_CHECKLIST_TO_ACTIVE_VISIT } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  addChecklist: {
    id: 'DiagnosisChecklists.add_checklist',
    defaultMessage: 'Add a checklist',
  },
  checklistNotFound: {
    id: 'DiagnosisChecklists.checklist_not_found',
    defaultMessage: 'Checklist not found',
  },
  patientNotFound: {
    id: 'DiagnosisChecklists.patient_not_found',
    defaultMessage: 'Patient not found',
  },
  errorAddingChecklist: {
    id: 'DiagnosisChecklists.error_adding_checklist',
    defaultMessage: 'Could not add the checklist. Please, try again',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface AddChecklistVariables {
  patientId: string
  visitId: string
  checklistId: string
}

interface AddChecklistMutationData {
  addChecklistToActiveVisit: FullPatientWithActiveVisit
}

type Params = MutationHookOptions<AddChecklistMutationData, AddChecklistVariables> & {
  patientId: string
  visitId: string
}

export const useAddChecklistToVisit = (params: Params) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)
  const translateError = (code) => {
    switch (code) {
      case 'checklist_not_found':
        return setError(intl.formatMessage(messages.checklistNotFound))

      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_checklist':
        return setError(intl.formatMessage(messages.errorAddingChecklist))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [addChecklist, { loading: isLoading }] = useMutation(ADD_CHECKLIST_TO_ACTIVE_VISIT, {
    onError,
    ...restParams,
  })

  const onAdd = (checklistId) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      checklistId,
    }

    addChecklist({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onAdd,
    addChecklist,
    isLoading,
    error,
    onErrorClose,
  }
}
