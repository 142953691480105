import React from 'react'
import { Alert, Button, Text, useToast } from '@chakra-ui/react'
import { defineMessages, useIntl } from 'react-intl'
import { useUpdatePaymentMethodCheckout } from './useUpdatePaymentMethodCheckout'
import { useSearchParams } from 'react-router-dom'

const messages = defineMessages({
  manageButton: {
    id: 'UI.manage_button',
    defaultMessage: 'Manage',
  },
  redirection: {
    id: 'UI.redirection_label',
    defaultMessage: 'Redirection',
  },
  willBeRedirected: {
    id: 'StripeCheckout.redirection_message',
    defaultMessage: 'You will be redirected to Stripe to update your preferences',
  },
  preferencesUpdated: {
    id: 'StripeCheckout.payment_method_update_success_message',
    defaultMessage: 'Your Stripe preferences where updated successfully',
  },
})

export const PaymentMethodCheckoutButton: React.VFC = () => {
  const intl = useIntl()
  const toast = useToast()

  const onError = (translatedError: string) => {
    toast({
      title: translatedError,
      description: translatedError,
      status: 'error',
      duration: 12000,
      isClosable: true,
    })
  }

  const { checkout, isLoading } = useUpdatePaymentMethodCheckout({ onError })

  const onClick = async () => {
    checkout()

    toast({
      title: intl.formatMessage(messages.redirection),
      description: intl.formatMessage(messages.willBeRedirected),
      status: 'info',
      duration: 8000,
      isClosable: true,
    })
  }

  return <Button variant="ghost" colorScheme="brand" onClick={onClick} isLoading={isLoading}>
    {intl.formatMessage(messages.manageButton)}
  </Button>
}



export const UpdatePaymentMethodResult: React.VFC = () => {
  const intl = useIntl()

  const [searchParams, setSearchParams] = useSearchParams();
  const paymentMethodUpdated = searchParams.get('paymentMethodUpdated')

  const [isVisible] = React.useState(paymentMethodUpdated === 'true')

  React.useEffect(() => {
    setSearchParams({})
  }, [setSearchParams])

  if (!isVisible) {
    return null
  }

  return <Alert status='success' variant='top-accent' >
    <Text>{intl.formatMessage(messages.preferencesUpdated)}</Text>
  </Alert>
}