import { gql } from '@apollo/client'
import { BasicPatient, UserSignature } from './'

export const FullScheduledVisit = gql`
  fragment FullScheduledVisit on ScheduledVisit {
    id
    updated {
      ...UserSignature
    }
    registrationData {
      location
      room
      start
      end
      type
      telehealth
      chiefComplaint {
        complaint
        howLong
      }
      patient {
        ...BasicPatient
      }
    }
  }
  ${UserSignature}
  ${BasicPatient}
`
