import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { FullHistory, Measurement } from '../../api/interfaces'
import { GET_VISIT_HISTORY } from '../../api/requests/activeVisit'
import { isAValidVital } from 'wd-common/src/logic/triage/validations'

const messages = defineMessages({
  errorGetPatientesHistoryDefault: {
    id: 'MedicalHistory.error_get_patientes_history_default',
    defaultMessage: 'Something went wrong while trying to get patient history. Please, try again',
  },
})

interface PatientHistoryParams {
  patientId: string
}

interface PatientHistoryData {
  patientHistory: FullHistory
}

export type UsePatientHistoryParams = QueryHookOptions<PatientHistoryData, PatientHistoryParams> & {
  patientId: string
}

export function usePatientHistory(params: UsePatientHistoryParams) {
  const { patientId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)
  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.errorGetPatientesHistoryDefault))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const { loading: isLoading, data } = useQuery(GET_VISIT_HISTORY, {
    variables: {
      patientId,
    },
    ...rest,
    onError,
  })

  const onErrorClose = () => {
    setError(null)
  }

  const getLastVital = (measurementType): Nullable<Measurement> => {
    if (!isAValidVital(measurementType)) {
      return null
    }

    if (!data || !Array.isArray(data?.patientHistory?.vitals)) {
      return null
    }

    return data.patientHistory.vitals.reduce((lastMeasurement: Nullable<Measurement>, visit) => {
      if (visit?.vitals && Array.isArray(visit.vitals?.measurements)) {
        const measurement = visit.vitals.measurements.find(
          (measurement) => measurement.type === measurementType
        )

        // sort them
        // if (measurement && lastMeasurement) {
        // }

        return measurement ?? lastMeasurement
      }

      return lastMeasurement
    }, null)
  }

  const getAllergies = () => {
    return ((data || {}).patientHistory || {}).allergies || []
  }

  return {
    isLoading,
    patientHistory: data ? data.patientHistory : null,
    error,
    onErrorClose,
    getLastVital,
    getAllergies,
  }
}
