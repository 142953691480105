import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { useNavigate } from 'react-router-dom'
import { START_NEW_VISIT } from '../../api/requests/activeVisit'
import { isValidVisitType, StartVisitFormValues } from '.'
import { sitemap } from '../Routing'
import { ValidateFn, ValidationError } from '../Form'
import { isValidDate } from '../DateAndTime'
import { VisitType } from '../../api/interfaces'

const messages = defineMessages({
  invalidDateTime: {
    id: 'NewVisitModal.invalid_date_time',
    defaultMessage: 'Invalid date/time',
  },
  requiredError: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  newVisitError: {
    id: 'NewVisitModal.error_starting_new_visit',
    defaultMessage: 'Could not start the new visit. Try again',
  },
  patientNotFound: {
    id: 'NewVisitModal.patient_not_found',
    defaultMessage: 'Patient not found',
  },
  examNotFound: {
    id: 'NewVisitModal.exam_not_found',
    defaultMessage: 'Default exam not found. Try again',
  },
  invalidVisitType: {
    id: 'NewVisitModal.invalid_visit_type',
    defaultMessage: 'Invalid visit type. Try again',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface NewVisit {
  type: VisitType
  date: string
  backdated?: boolean
}

interface StartVisitVariables {
  patientId: string
  newVisit: NewVisit
}

interface StartVisitMutationData {
  done: boolean
}

export type UseStartVisitParams = MutationHookOptions<
  StartVisitMutationData,
  StartVisitVariables
> & {
  patientId: string
}

export const useStartVisit = (params: UseStartVisitParams) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'error_starting_new_visit':
        return intl.formatMessage(messages.newVisitError)

      case 'patient_not_found':
        return intl.formatMessage(messages.patientNotFound)

      case 'exam_not_found':
        return intl.formatMessage(messages.examNotFound)

      case 'invalid_visit_type':
        return intl.formatMessage(messages.invalidVisitType)

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const validate: ValidateFn<StartVisitFormValues> = async (values) => {
    const errors: ValidationError<StartVisitFormValues> = {}

    if (!values.date) {
      errors.date = intl.formatMessage(messages.requiredError)
    } else if (!isValidDate(values.date)) {
      errors.date = intl.formatMessage(messages.invalidDateTime)
    }

    if (!values.type) {
      errors.type = intl.formatMessage(messages.requiredError)
    } else if (!isValidVisitType(values.type)) {
      errors.type = intl.formatMessage(messages.invalidVisitType)
    }

    return errors
  }

  const onCompleted = () => {
    setError(null)

    const url = sitemap.activeVisit.routes.exam.pathBuilder(params.patientId)
    navigate(url)
  }

  const [startNewVisit, { loading: isLoading }] = useMutation(START_NEW_VISIT, {
    onError,
    onCompleted,
  })

  const initialValues: StartVisitFormValues = {
    date: new Date(),
    backdated: false,
    type: VisitType.LACTATION_COUNSELING,
  }

  const onSubmit = (values) => {
    const variables = {
      patientId: params.patientId,
      newVisit: values,
    }

    startNewVisit({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    initialValues,
    onSubmit,
    isLoading,
    validate,
    error,
    onErrorClose,
  }
}
