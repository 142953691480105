import { ClinicalStatusPriority, Disposition, FollowUp, HowLong, StatusAssesment } from '../../api/interfaces'
import { assertUnreachable } from '../../utils'

export interface NewClinicalStatus {
  priority: string
}

export interface UpdateClinicalStatusVariables {
  patientId: string
  visitId: string
  clinicalStatus: NewClinicalStatus
}

export const isValidClinicalStatusPriority = (value: string): boolean => {
  return (Object.values(ClinicalStatusPriority) as Array<string>).includes(value)
}

export const isValidHowLong = (value: string): boolean => {
  return (Object.values(HowLong) as Array<string>).includes(value)
}

export const isValidStatusAssesment = (value: string): boolean => {
  return (Object.values(StatusAssesment) as Array<string>).includes(value)
}

export const isValidDisposition = (value: string): boolean => {
  return (Object.values(Disposition) as Array<string>).includes(value)
}

export const isValidFollowUp = (value: string): boolean => {
  return (Object.values(FollowUp) as Array<string>).includes(value)
}

export const getClinicalStatusColor = (status: ClinicalStatusPriority): string => {
  switch (status) {
    case ClinicalStatusPriority.GUARDED:
      return 'yellow.500'

    case ClinicalStatusPriority.ROUTINE:
      return 'green.400'

    case ClinicalStatusPriority.DECEASED:
    case ClinicalStatusPriority.EMERGENCY:
      return 'red.600'

    default:
      assertUnreachable('Invalid case', status)
  }
}

export const getClinicalStatusColorScheme = (status: ClinicalStatusPriority): string => {
  switch (status) {
    case ClinicalStatusPriority.GUARDED:
      return 'yellow'

    case ClinicalStatusPriority.ROUTINE:
      return 'green'

    case ClinicalStatusPriority.DECEASED:
    case ClinicalStatusPriority.EMERGENCY:
      return 'red'

    default:
      assertUnreachable('Invalid case', status)
  }
}
