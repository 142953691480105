import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { ADD_MANUAL_ASSESSMENT, REMOVE_ASSESSMENT } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ValidateFn, ValidationError } from '../Form'

const messages = defineMessages({
  requiredError: {
    id: 'UI.required_error',
    defaultMessage: 'You must enter a value for this field.',
  },
  patientNotFoundError: {
    id: 'AddAssessment.patient_not_found_error',
    defaultMessage: 'This patient was not found, refresh the page and try again.',
  },
  visitNotFoundError: {
    id: 'AddAssessment.visit_not_found_error',
    defaultMessage: 'This visit was not found, refresh the page and try again.',
  },
  removeAssessmentError: {
    id: 'AddAssessment.remove_assessment__error',
    defaultMessage: 'Something went wrong while trying to remove the assessment, try again later.',
  },
  addAssessmentError: {
    id: 'AddAssessment.add_assessment__error',
    defaultMessage: 'Something went wrong while trying to add a assessment, try again later.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface AssessmentsFormValues {
  assessment: string
}

interface AddAssessmentsVariables {
  patientId: string
  visitId: string
  assessment: string
}

interface AddAssessmentsData {
  addNewAssessmentsToActiveVisit: FullPatientWithActiveVisit
}

type AddManualAssessmentParams = MutationHookOptions<
  AddAssessmentsData,
  AddAssessmentsVariables
> & {
  patientId: string
  visitId: string
}

export const useAddAssessment = (params: AddManualAssessmentParams) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFoundError))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFoundError))

      case 'error_adding_new_assessment':
        return setError(intl.formatMessage(messages.addAssessmentError))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [addAssessment, { loading: isLoading }] = useMutation(ADD_MANUAL_ASSESSMENT, {
    onError,
    ...restParams,
  })

  const validate: ValidateFn<AssessmentsFormValues> = async (values) => {
    const errors: ValidationError<AssessmentsFormValues> = {}

    if (!values.assessment) {
      errors.assessment = intl.formatMessage(messages.requiredError)
    }

    return errors
  }

  const onSubmit = (values: AssessmentsFormValues) => {
    setError(null)

    const { assessment } = values
    const variables = {
      patientId,
      visitId,
      assessment,
    }

    addAssessment({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    validate,
    error,
    onErrorClose,
  }
}

interface RemoveAssessmentVariables {
  patientId: string
  visitId: string
  assessmentId: string
}

interface RemoveAssessmentData {
  removeAssessmentFromActiveVisit: FullPatientWithActiveVisit
}

type RemoveAssessmentParams = MutationHookOptions<
  RemoveAssessmentData,
  RemoveAssessmentVariables
> & {
  patientId: string
  visitId: string
}

export const useRemoveAssessment = (params: RemoveAssessmentParams) => {
  const { patientId, visitId, ...restParams } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFoundError))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFoundError))

      case 'error_removing_assessment':
        return setError(intl.formatMessage(messages.removeAssessmentError))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const [removeAssessment, { loading: isLoading }] = useMutation(REMOVE_ASSESSMENT, {
    onError,
    ...restParams,
  })

  const onDelete = (assessmentId: string) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      assessmentId,
    }

    removeAssessment({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}
