import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  TranslatableText,
} from '../../../api/interfaces'
import { DynamicTranslation } from '../../i18n'
import {
  Box,
  BoxProps,
  Heading,
} from '@chakra-ui/react'

const messages = defineMessages({
  titleBackground: {
    id: 'Checklist.label_background',
    defaultMessage: 'Background'
  }
})


/**
 * History section
 */
type BackgroundProps = Omit<BoxProps, 'background'> & {
  background: TranslatableText
}

export const Background: React.FC<BackgroundProps> = props => {
  const { background, ...boxProps } = props

  const intl = useIntl()

  if (!background?.text) {
    return null
  }

  return <Box {...boxProps}>
    <Heading size={'md'} fontWeight={'bold'}>
      {intl.formatMessage(messages.titleBackground)}
    </Heading>
    <DynamicTranslation message={background} />
  </Box>
}
