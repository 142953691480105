import { gql } from '@apollo/client'

export const GET_TRANSLATION_STATS = gql`
  query stats {
    stats {
      total
      translated {
        count
        locale
      }
    }
  }
`
