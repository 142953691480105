import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { CLEAN_ACTIVE_VISIT } from '../../api/requests/patients'
import { FullPatientWithActiveVisit } from '../../api/interfaces'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface CleanActiveVisitVariables {
  patientId: string
}

interface CleanActiveVisitMutationData {
  cleanActiveVisit: FullPatientWithActiveVisit
}

export type UseCleanActiveVisitParams = MutationHookOptions<CleanActiveVisitMutationData, CleanActiveVisitVariables>
export const useCleanActiveVisit = (params?: UseCleanActiveVisitParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [cleanActiveVisit, { loading: isLoading }] = useMutation<
    CleanActiveVisitMutationData,
    CleanActiveVisitVariables
  >(CLEAN_ACTIVE_VISIT, {
    onError,
    ...params,
  })

  const onSubmit = (params: CleanActiveVisitVariables) => {
    setError(null)

    const variables = params
    cleanActiveVisit({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    onErrorClose,
  }
}
