import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ButtonProps, Icon } from '@chakra-ui/react'
import { ConfirmButton, ConfirmButtonProps } from '..'
import { useDisableUserAccount, useEnableUserAccount } from './useAccountStatusActions'
import {
  RiUserFollowLine as EnableUser,
  RiUserUnfollowLine as DisableUser
} from 'react-icons/ri'

const messages = defineMessages({
  enableAccount: {
    id: 'UI.button_enable_account',
    defaultMessage: 'Enable account'
  },
  disableAccount: {
    id: 'UI.button_disable_account',
    defaultMessage: 'Disable account'
  },
  confirmationMessage: {
    id: 'StaffAdmin.toggle_account_status_warning',
    defaultMessage: 'You can allow or denied the user access by switching the account status. Do you want to continue?'
  },
})

type ToggleAccountStatusButtonProps = ButtonProps & {
  username: string
  isEnabled: boolean
}

export const ToggleAccountStatusButton: React.FC<ToggleAccountStatusButtonProps> = props => {
  const { username, isEnabled, ...buttonProps } = props

  if (isEnabled) {
    return <DisableAccountsButton username={username} {...buttonProps} />
  }

  return <EnableAccountsButton username={username} {...buttonProps} />
}

type Props = ButtonProps & {
  username: string
}

const EnableAccountsButton: React.FC<Props> = props => {
  const { username, ...buttonProps } = props

  const intl = useIntl()

  const { enableAccount, isLoading, error, onErrorClose } = useEnableUserAccount()

  const onConfirm = () => {
    const variables = {
      username
    }

    enableAccount({ variables })
  }


  const buttonStyles = {
    variant: 'ghost',
    isFullWidth: true,
    justifyContent: 'start',
    borderRadius: 'sm',
    size: 'sm',
    ...buttonProps
  }

  const enableButtonProps: ConfirmButtonProps = {
    onConfirm,
    message: intl.formatMessage(messages.confirmationMessage),
    buttonProps: {
      label: <>
        <Icon as={EnableUser} mr={2} />
        {intl.formatMessage(messages.enableAccount)}
      </>,
      colorScheme: 'brand',
      ...buttonStyles
    },
    confirmButtonProps: {
      isLoading,
      colorScheme: 'brand',
      label: intl.formatMessage(messages.enableAccount)
    },
    error,
    onErrorClose
  }

  return <ConfirmButton {...enableButtonProps} />
}


const DisableAccountsButton: React.FC<Props> = props => {
  const { username, ...buttonProps } = props

  const intl = useIntl()

  const { disableAccount, isLoading, error, onErrorClose } = useDisableUserAccount()

  const onConfirm = () => {
    const variables = {
      username
    }

    disableAccount({ variables })
  }


  const buttonStyles = {
    variant: 'ghost',
    isFullWidth: true,
    justifyContent: 'start',
    borderRadius: 'sm',
    size: 'sm',
    ...buttonProps
  }

  const disableButtonProps: ConfirmButtonProps = {
    onConfirm,
    message: intl.formatMessage(messages.confirmationMessage),
    buttonProps: {
      label: <>
        <Icon as={DisableUser} mr={2} />
        {intl.formatMessage(messages.disableAccount)}
      </>
      ,
      colorScheme: 'red',
      ...buttonStyles
    },
    confirmButtonProps: {
      isLoading,
      label: intl.formatMessage(messages.disableAccount)
    },
    error,
    onErrorClose
  }

  return <ConfirmButton {...disableButtonProps} />
}
