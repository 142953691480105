/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import EditorJS, { OutputData } from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Input from './plugins/Input'
import Range from './plugins/Range'
import Checkbox from './plugins/Checkbox'
import Selector from './plugins/Selector'
import { assertUnreachable, Nullable } from '../../utils'
import { BlockType, SurveyBlock, SurveyBody } from '../../api/interfaces'
import { Box, useColorMode, useTheme } from '@chakra-ui/react'
import { useDynamicTranslation } from '../i18n'

const usePluginStyles = () => {
  const { colorMode } = useColorMode()
  const { colors } = useTheme()

  const background = colorMode === 'light' ? colors.gray['100'] : colors.gray['600']
  const color = colorMode === 'light' ? colors.black : colors.white

  return {
    background,
    color,
  }
}

type FillableBodyProps = {
  holderId: string
  value: SurveyBody
  isFillable: true
  onInputChange: (blockId: string, value: string) => void
  onCheckboxChange: (blockId: string, checked: boolean) => void
}

/**
 * this is the actual readonly behavior
 */
type ReadOnlyBodyProps = {
  holderId: string
  value: SurveyBody
  isFillable: false
}

type Props = ReadOnlyBodyProps | FillableBodyProps

export const BodyViewer: React.FC<Props> = (props) => {
  const { holderId, value, isFillable } = props

  const ref = React.useRef<Nullable<EditorJS>>(null)

  const translatedBody = useTranslatedSurveyBody(value) as OutputData

  const selectorStyles = usePluginStyles()

  React.useEffect(() => {
    async function initEditor() {
      ref.current = new EditorJS({
        holder: holderId,
        data: translatedBody,
        readOnly: true,
        autofocus: true,
        tools: {
          header: {
            class: Header,
          },
          input: {
            class: Input,
            config: {
              isFillable,
              onInputChange: isFillable ? props.onInputChange : null,
            },
          },
          range: {
            class: Range,
            config: {
              isFillable,
              onInputChange: isFillable ? props.onInputChange : null,
            },
          },
          checkbox: {
            class: Checkbox,
            config: {
              isFillable,
              onCheckboxChange: isFillable ? props.onCheckboxChange : null,
            },
          },
          selector: {
            class: Selector,
            config: {
              isFillable,
              onInputChange: isFillable ? props.onInputChange : null,
              styles: selectorStyles,
            },
          },
        },
      })
    }

    if (!ref.current) {
      initEditor()
    }

    return () => {
      if (ref.current) {
        ref.current?.destroy()
      }
    }
  }, [])

  return (
    <Box
      id={holderId}
      sx={{
        '.codex-editor__redactor': {
          paddingBottom: '0!important',
        },
      }}
    />
  )
}

const useTranslatedSurveyBody = (body: SurveyBody) => {
  const { version, blocks: translatedBlocks } = body

  const { translate } = useDynamicTranslation()

  const blocks = translatedBlocks.map((block: SurveyBlock) => {
    const { type } = block

    switch (type) {
      case BlockType.HEADER:
        return {
          id: block.id,
          type: type,
          data: {
            text: translate(block.data.text),
            level: block.data.level,
          },
        }

      case BlockType.INPUT:
        return {
          id: block.id,
          type: type,
          data: {
            label: translate(block.data.label),
            type: block.data.type,
            value: block.data.value,
          },
        }

      case BlockType.PARAGRAPH:
        return {
          id: block.id,
          type: type,
          data: {
            text: translate(block.data.text),
          },
        }

      case BlockType.RANGE:
        return {
          id: block.id,
          type: type,
          data: {
            label: translate(block.data.label),
            value: block.data.value,
            min: block.data.min,
            max: block.data.max,
            step: block.data.step,
          },
        }

      case BlockType.CHECKBOX:
        return {
          id: block.id,
          type: type,
          data: {
            label: translate(block.data.label),
            checked: block.data.checked,
          },
        }

      case BlockType.SELECTOR:
        return {
          id: block.id,
          type: type,
          data: {
            label: translate(block.data.label),
            value: block.data.value,
            options: block.data.options.map(translate),
          },
        }

      default:
        assertUnreachable('Invalida block type', type)
    }
  })

  return {
    version,
    blocks,
  }
}
