import React from 'react'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'
import Select from 'react-select'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SelectorProps,
  useChakraThemeParser,
} from '.'
import { Sex } from '../../api/interfaces'
import { isValidSex, Nullable } from '../../utils'

/**
 * Record will force us to add the translation
 * every time we updated the sex list
 */
export const sexTranslations: Record<Sex, MessageDescriptor> = {
  [Sex.FEMALE]: {
    id: 'Sex.Female',
    defaultMessage: 'Female'
  },
  [Sex.MALE]: {
    id: 'Sex.Male',
    defaultMessage: 'Male'
  }
}

/**
 * See the IntlDefineMessage definition
 */
const translationMessages = defineMessages(sexTranslations)

export type SexSelectorProps = SelectorProps<Sex>
export const SexSelector: React.FC<SexSelectorProps> = props => {
  const {
    value,
  } = props

  const intl = useIntl()
  const theme = useChakraThemeParser()

  const formatOption = (sex: Sex): ReactSelectInternalSingleValue => {
    return {
      label: intl.formatMessage(translationMessages[sex]),
      value: sex
    }
  }

  const options = Object.values(Sex).map(formatOption)

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return null
      }

      const formattedValues = value
        .reduce((prev: Array<ReactSelectInternalSingleValue>, value: string) => {
          if (isValidSex(value)) {
            return [
              ...prev,
              formatOption(value as Sex)
            ]
          }

          return prev
        }, [])

      return formattedValues
    } else {
      if (isValidSex(value as string)) {
        return formatOption(value as Sex)
      }

      return null
    }
  }

  const _onChange = value => {
    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return props.onChange([])
      }

      const selectedValues = value
      const newValues = selectedValues.map(item => item.value)

      props.onChange(newValues as Array<Sex>)
    } else {
      const selectedValue = value

      props.onChange(selectedValue.value as Sex)
    }
  }

  return <Select
    options={options}
    value={parseValue(value)}
    onChange={_onChange}
    isLoading={props.isLoading}
    isMulti={props.isMulti}
    isClearable={props.isClearable}
    isDisabled={props.isDisabled}
    isSearchable={props.isSearchable}
    aria-invalid={props.isInvalid}
    placeholder={props.placeholder}
    blurInputOnSelect={props.blurInputOnSelect}
    theme={theme}
  />
}

