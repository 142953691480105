import { gql } from '@apollo/client'
import { FullProcedure } from '../fragments'

export const GET_PROCEDURE_SUBSCRIPTIONS = gql`
  ${FullProcedure}
  query procedureSubscriptions {
    procedureSubscriptions {
      docs {
        ...FullProcedure
      }
      count
    }
  }
`
