import React from 'react'
import { useSession } from '../../components/Auth'
import { useSystems } from '../../components/Systems'
import {
  RiHome4Line as AddressIcon,
  RiPhoneLine as PhoneIcon,
  RiHospitalLine as ClinicIcon,
} from 'react-icons/ri'

import { Box, BoxProps, Heading, HStack, Icon, Text } from '@chakra-ui/react'

export const PrintableClinicInfo = () => {
  const { getUserLocation, getUserSystem } = useSession()
  const { id: systemId } = getUserSystem()
  const { id: locationId } = getUserLocation()

  const { getLocation } = useSystems()
  const clinic = getLocation(systemId, locationId)

  if (clinic === null) {
    return null
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '8px 0' }}>
      <b>{clinic.name}</b>
      {clinic.phoneNumber && <p>{clinic.phoneNumber}</p>}
      {clinic.address && <p>{clinic.address}</p>}
    </div>
  )
}

export const ClinicInfo: React.FC<BoxProps> = (props) => {
  const { ...boxProps } = props

  const { getUserLocation, getUserSystem } = useSession()
  const { id: systemId } = getUserSystem()
  const { id: locationId } = getUserLocation()

  const { getLocation } = useSystems()
  const clinic = getLocation(systemId, locationId)

  if (clinic === null) {
    return null
  }

  return (
    <Box {...boxProps}>
      <HStack>
        <Icon as={ClinicIcon} />
        <Heading size={'sm'}>{clinic.name}</Heading>
      </HStack>
      {clinic.phoneNumber && (
        <HStack>
          <Icon as={PhoneIcon} />
          <Text>{clinic.phoneNumber}</Text>
        </HStack>
      )}
      {clinic.address && (
        <HStack>
          <Icon as={AddressIcon} />
          <Text>{clinic.address}</Text>
        </HStack>
      )}
    </Box>
  )
}
