import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { CalendarApi } from '@fullcalendar/react'
import { CalendarViewType } from '../utils'
import {
  Button,
  useDisclosure,
  Stack,
  ButtonGroup,
  IconButton,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react'
import { UpdateTelehealthSettingsModal } from '..'
import {
  MdCalendarViewMonth as MonthlyViewIcon,
  MdCalendarViewWeek as WeeklyViewIcon,
  MdCalendarViewDay as DailyViewIcon,
  MdFormatListBulleted as ListViewIcon,
  MdKeyboardArrowRight as NextIcon,
  MdKeyboardArrowLeft as PrevIcon,
  MdOutlineSettings as SettingIcon,
} from 'react-icons/md'
import { useSystems } from '../../Systems'
import './styles.css'

const messages = defineMessages({
  newEvent: {
    id: 'Calendar.click_to_create',
    defaultMessage: 'Click to create',
  },
  settingNeeded: {
    id: 'SettingsNotFound.you_need_to_configure_telehealth',
    defaultMessage: 'You can configure the duration of the slots to improve the calendar visualization',
  },
  configButton: {
    id: 'UI.config_button',
    defaultMessage: 'Config',
  },
  listView: {
    id: 'UI.list_view_button',
    defaultMessage: 'List',
  },
  dailyView: {
    id: 'UI.daily_view_button',
    defaultMessage: 'Daily',
  },
  weeklyView: {
    id: 'UI.weekly_view_button',
    defaultMessage: 'Weekly',
  },
  monthlyView: {
    id: 'UI.monthly_view_button',
    defaultMessage: 'Monthly',
  },
  today: {
    id: 'UI.today_button',
    defaultMessage: 'Today',
  },
  next: {
    id: 'UI.next_button',
    defaultMessage: 'Next',
  },
  previous: {
    id: 'UI.previous_button',
    defaultMessage: 'Previous',
  },
  settings: {
    id: 'UI.settings_button',
    defaultMessage: 'Settings',
  },
})

interface ToolBarProps {
  api: CalendarApi
  isDisabled?: boolean
  onCurrentDateChange: (current: Date) => void
}

export const ToolBar: React.FC<ToolBarProps> = (props) => {
  const { api, isDisabled, onCurrentDateChange } = props
  const intl = useIntl()

  const [view, setView] = React.useState<string>(api.view.type)

  const onPrev = () => {
    api.prev()

    const currentDate = api.getDate()
    onCurrentDateChange(currentDate)
  }

  const onNext = () => {
    api.next()

    const currentDate = api.getDate()
    onCurrentDateChange(currentDate)
  }

  const onToday = () => {
    api.today()

    const currentDate = api.getDate()
    onCurrentDateChange(currentDate)
  }

  const onDailyView = () => {
    setView(CalendarViewType.DAY)
    api.changeView(CalendarViewType.DAY)
  }

  const onWeeklyView = () => {
    setView(CalendarViewType.WEEK)
    api.changeView(CalendarViewType.WEEK)
  }

  const onMonthlyView = () => {
    setView(CalendarViewType.MONTH)
    api.changeView(CalendarViewType.MONTH)
  }

  const onListView = () => {
    setView(CalendarViewType.LIST)
    api.changeView(CalendarViewType.LIST)
  }

  return (
    <Stack isInline={true} justifyContent={'space-between'}>
      <HStack spacing={2}>
        <ButtonGroup size={'sm'} isAttached>
          <IconButton
            aria-label={intl.formatMessage(messages.monthlyView)}
            icon={<MonthlyViewIcon />}
            onClick={onMonthlyView}
            isActive={view === CalendarViewType.MONTH}
            isDisabled={isDisabled}
          />
          <IconButton
            aria-label={intl.formatMessage(messages.weeklyView)}
            icon={<WeeklyViewIcon />}
            onClick={onWeeklyView}
            isActive={view === CalendarViewType.WEEK}
            isDisabled={isDisabled}
          />
          <IconButton
            aria-label={intl.formatMessage(messages.dailyView)}
            icon={<DailyViewIcon />}
            onClick={onDailyView}
            isActive={view === CalendarViewType.DAY}
            isDisabled={isDisabled}
          />

          <IconButton
            aria-label={intl.formatMessage(messages.listView)}
            icon={<ListViewIcon />}
            onClick={onListView}
            isActive={view === CalendarViewType.LIST}
            isDisabled={isDisabled}
          />
        </ButtonGroup>

        <TelehealthSettings isDisabled={isDisabled} />
      </HStack>
      <ButtonGroup size={'sm'} isAttached>
        <IconButton
          aria-label={intl.formatMessage(messages.previous)}
          icon={<PrevIcon />}
          onClick={onPrev}
          isDisabled={isDisabled}
        />
        <Button onClick={onToday} isDisabled={isDisabled}>
          {intl.formatMessage(messages.today)}
        </Button>
        <IconButton
          aria-label={intl.formatMessage(messages.next)}
          icon={<NextIcon />}
          onClick={onNext}
          isDisabled={isDisabled}
        />
      </ButtonGroup>
    </Stack>
  )
}

interface TelehealthSettingsProps {
  isDisabled?: boolean
}

const TelehealthSettings: React.FC<TelehealthSettingsProps> = (props) => {
  const { isDisabled } = props

  const intl = useIntl()

  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isOpen: isPopoverOpen, onClose: onPopoverClose, onOpen: onPopoverOpen } = useDisclosure()

  const { getMyLocation, isLoading } = useSystems()
  const { telehealth } = getMyLocation()

  React.useEffect(() => {
    if (!isLoading && !telehealth && !isDisabled) {
      onPopoverOpen()
    }
  }, [isLoading, telehealth, onPopoverOpen, isDisabled])

  return (
    <>
      <Popover isOpen={isPopoverOpen} onClose={onPopoverClose} closeOnBlur={false}>
        <PopoverTrigger>
          <IconButton
            aria-label={intl.formatMessage(messages.settings)}
            icon={<SettingIcon />}
            onClick={onOpen}
            size={'sm'}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">{intl.formatMessage(messages.settings)}</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>{intl.formatMessage(messages.settingNeeded)}</PopoverBody>
        </PopoverContent>
      </Popover>

      {isOpen && <UpdateTelehealthSettingsModal onClose={onClose} onCompleted={onClose} />}
    </>
  )
}
