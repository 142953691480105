import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Nullable } from '../../utils'
import { SendMFATokenParams, useSession } from '.'
import { ValidateFn, ValidationError } from '../Form'
import { sitemap } from '../Routing'
import { useNavigate } from 'react-router-dom'
import { FormApi } from 'final-form'

const messages = defineMessages({
  required: {
    id: 'StaffLogin.username_required_error',
    defaultMessage: 'Username is required',
  },
  invalidCodeError: {
    id: 'StaffRegisterConfirm.invalid_code_provided',
    defaultMessage: 'Invalid code provided. Please try again.',
  },
  expiredCodeError: {
    id: 'StaffRegisterConfirm.expired_code',
    defaultMessage: 'The entered code is expired. Please request a new one.',
  },
  limitExceededException: {
    id: 'StaffRegisterConfirm.attempt_limit_exceeded',
    defaultMessage: 'Attempt limit exceeded. Please, try again later.',
  },
  requiredError: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export const useMFAAuthentication = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Nullable<string>>(null)

  const { sendMFACode, getUsername } = useSession()
  const username = getUsername()

  const translateError = (err) => {
    const { code } = err

    switch (code) {
      case 'ExpiredCodeException':
        setError(intl.formatMessage(messages.expiredCodeError))
        break
      case 'LimitExceededException':
        setError(intl.formatMessage(messages.limitExceededException))
        break
      case 'CodeMismatchException':
        setError(intl.formatMessage(messages.invalidCodeError))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const validate: ValidateFn<SendMFATokenParams> = (values) => {
    const errors: ValidationError<SendMFATokenParams> = {}

    if (!values.confirmationCode) {
      errors.confirmationCode = intl.formatMessage(messages.requiredError)
    }

    return errors
  }

  const onSubmit = async (
    values: SendMFATokenParams,
    form: FormApi<SendMFATokenParams, Partial<SendMFATokenParams>>
  ) => {
    setIsLoading(true)

    try {
      await sendMFACode(values)
    } catch (error) {
      translateError(error)
      setIsLoading(false)

      form.reset()
    }
  }

  const onCancel = () => {
    navigate(sitemap.unauth.routes.login.absolutePath)
  }

  React.useEffect(() => {
    if (username === null) {
      navigate(sitemap.unauth.routes.login.absolutePath)
    }
  }, [username, navigate])

  return {
    onCancel,
    onSubmit,
    isLoading,
    error,
    validate,
  }
}
