import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ActiveVisitHistory, FullChecklist } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { Box, BoxProps, Heading, HStack } from '@chakra-ui/react'
import { HistoryComments } from '../History'
import { PrintableComments } from '..'

const messages = defineMessages({
  title: {
    id: 'AddComplaint.title',
    defaultMessage: 'History',
  },
  searchComplaintsPlaceholder: {
    id: 'AddComplaint.search_complaints_placeholder',
    defaultMessage: 'Search complaints',
  },
  errorGetComplaintListsDefault: {
    id: 'AddComplaint.error_get_complaint_lists_default',
    defaultMessage: 'Something went wrong while getting complaint lists, try again later.',
  },
  addComplaintButton: {
    id: 'UI.add_complaint_button',
    defaultMessage: 'Add Complaint',
  },
  commentsLabel: {
    id: 'UI.comments_label',
    defaultMessage: 'Comments',
  },
})

type HistoryOfPresentIllnessProps = BoxProps & {
  patientId: string
  visitId: string
  history: Nullable<ActiveVisitHistory>
  isDisabled?: boolean
}

export const HistoryOfPresentIllness: React.FC<HistoryOfPresentIllnessProps> = (props) => {
  const { patientId, visitId, history, isDisabled, ...boxProps } = props

  const intl = useIntl()

  return (
    <Box {...boxProps}>
      <HStack justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <Heading size="md">{intl.formatMessage(messages.title)}</Heading>
      </HStack>
      <HistoryComments
        patientId={patientId}
        visitId={visitId}
        items={history?.comments ?? []}
        mt={2}
        isDisabled={isDisabled}
      />
    </Box>
  )
}

type PrintableHistoryOfPresentIllnessProps = BoxProps & {
  history: Nullable<ActiveVisitHistory>
  checklists: Nullable<Array<FullChecklist>>
}
export const PrintableHistoryOfPresentIllness: React.FC<PrintableHistoryOfPresentIllnessProps> = (
  props
) => {
  const { history, ...boxProps } = props

  const intl = useIntl()

  return (
    <Box w={'100%'} {...boxProps}>
      <Heading
        size="md"
        borderBottomWidth={1}
        mb={1}
        sx={{
          '@media print': {
            margin: '0 0 .5rem 0',
            borderBottom: '1px solid black',
          },
        }}
      >
        {intl.formatMessage(messages.title)}
      </Heading>

      {history?.comments && (
        <>
          <Heading size={'sm'} mb={1} mt={2}>
            {intl.formatMessage(messages.commentsLabel)}
          </Heading>
          <PrintableComments items={history?.comments ?? []} />
        </>
      )}
    </Box>
  )
}
