import { COMMON_ROUTE_TYPES, DOSE_UNITS, FREQUENCY_SHOW, RX_VALIDATION_ERROR } from './definitions'

export const isAValidUnit = unit => Object.values(DOSE_UNITS).includes(unit)

export const isAValidFrequencyShow = frequencyShow => Object.values(FREQUENCY_SHOW).includes(frequencyShow)

export const isACommonRouteType = route => {
  typeof route === 'string' && Object.values(COMMON_ROUTE_TYPES).includes(route.toLowerCase())
}

export const validateRXForm = values => {
  const { days, doseNumber, doseUnits, unitsPerDose, dispense, dosesPerDay, route, frequencyShow } = values || {}

  if (!(doseNumber > 0)) return RX_VALIDATION_ERROR.INVALID_DOSE

  if (!doseUnits) return RX_VALIDATION_ERROR.INVALID_DOSE_UNIT

  if (!(unitsPerDose > 0)) return RX_VALIDATION_ERROR.INVALID_UNITS_PER_DOSE

  if (!route) return RX_VALIDATION_ERROR.INVALID_ROUTE

  if (!isAValidFrequencyShow(frequencyShow)) return RX_VALIDATION_ERROR.INVALID_FREUQUECY_SHOW

  if (!(dosesPerDay > 0)) return RX_VALIDATION_ERROR.INVALID_DOSES_PER_DAY

  if (frequencyShow !== FREQUENCY_SHOW.DOSES && !(days > 0)) return RX_VALIDATION_ERROR.INVALID_DAYS

  if (!(dispense > 0)) return RX_VALIDATION_ERROR.INVALID_DISPENSE

  return null
}
