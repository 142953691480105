import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { FullVisit } from '../../api/interfaces'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { FullBill } from '../../api/interfaces/billing'
import { GET_BILL_WITH_VISIT } from '../../api/requests/billing'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface BillByIdVariables {
  visitId: string
}

interface BillByIdData {
  bill: FullBill
  visit: FullVisit
}

export type useBillByIdParams = QueryHookOptions<BillByIdData, BillByIdVariables>

export const useBillById = (params?: useBillByIdParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery<BillByIdData, BillByIdVariables>(GET_BILL_WITH_VISIT, {
    onError,
    ...params,
  })

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading,
    bill: data?.bill ?? null,
    visit: data?.visit ?? null,
    error,
    refetch,
    onErrorClose,
  }
}
