import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ChecklistReference } from '../'
import {
  ChecklistEntryType,
  FullChecklistEntry,
  TypedName
} from '../../../api/interfaces'
import {
  Box,
  BoxProps,
  Heading,
  Text,
} from '@chakra-ui/react'

const messages = defineMessages({
  titleDifferentialDiagnosis: {
    id: 'Checklist.title_differential_diagnosis',
    defaultMessage: 'Differential Diagnosis'
  },
})

/**
 * Differential diagnosis section
 */
type DifferentialDiagnosisProps = BoxProps & {
  differentialDiagnosis: Array<FullChecklistEntry | TypedName>
}

export const DifferentialDiagnosis: React.FC<DifferentialDiagnosisProps> = props => {
  const { differentialDiagnosis, ...boxProps } = props

  const intl = useIntl()

  const citations = differentialDiagnosis.filter(({ type }) => type === ChecklistEntryType.CITATION) as Array<FullChecklistEntry>
  const items = differentialDiagnosis.filter(item => item?.type === ChecklistEntryType.DIFFERENTIAL_DIAGNOSIS)

  return <Box {...boxProps}>
    <Heading size={'md'} fontWeight={'bold'}>
      {intl.formatMessage(messages.titleDifferentialDiagnosis)}
      <ChecklistReference items={citations} />
    </Heading>
    <Text>
      {
        items
          .filter(item => item.name?.text)
          .map(item => {
            if(typeof item?.name?.text !== 'string' || item.name.text.length === 0){
              return ''
            }

            return intl.formatMessage({
              id: 'Dynamic.' + item.name.text,
              defaultMessage: item.name.text
            })
          }).join(', ')
      }
    </Text>
  </Box>
}
