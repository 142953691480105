import { CustomField, RegistrationGroup } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { useCustomFields } from '../CustomFields'
import { useSystems } from '../Systems'

interface Params {
  id: string
}
export const useCustomGroup = (params: Params): Nullable<RegistrationGroup> => {
  const { id } = params

  const { getMySystem } = useSystems()
  const { registration } = getMySystem()

  const group = (registration?.groups ?? []).find((group) => group.id === id)
  if (!group) {
    return null
  }

  return group ?? null
}

export const useCustomFieldLookUp = (customFields: Array<string>): Array<CustomField> => {
  const { getCustomField } = useCustomFields()

  const reducer = (fields: Array<CustomField>, current: string) => {
    const found = getCustomField(current)
    if (found) {
      fields.push(found)
    }

    return fields
  }

  return customFields.reduce(reducer, [])
}
