import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { AVAILABLE_SLOTS } from '../../api/requests/scheduler'
import { useSystems } from '../Systems'
import { AvailableSlot, getSlotDurationFromSettings } from './utils'
import { DateTime } from 'luxon'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface AvailableSlotsVariables {
  room: string
  from: string
  to: string
  ignoreVisit?: string
}

interface AvailableSlotsData {
  availableSlots: Array<AvailableSlot>
}

export type UseAvailableSlotsParams = QueryHookOptions<AvailableSlotsData, AvailableSlotsVariables> & {
  roomId?: string
  date: string
  ignoreVisit?: string
}

export const useAvailableSlots = (params: UseAvailableSlotsParams) => {
  const { date, roomId, ignoreVisit, ...rest } = params
  const intl = useIntl()

  const { getMyLocation } = useSystems()
  const { telehealth } = getMyLocation()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)

    if (typeof rest?.onError === 'function') {
      rest.onError(errors)
    }
  }

  const fromIsoDate = DateTime.fromISO(date).startOf('day').toISO()
  const toIsoDate = DateTime.fromISO(date).endOf('day').toISO()
  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery<AvailableSlotsData, AvailableSlotsVariables>(AVAILABLE_SLOTS, {
    ...rest,
    variables: {
      room: roomId ?? '',
      from: fromIsoDate,
      to: toIsoDate,
      ignoreVisit,
    },
    fetchPolicy: 'no-cache',
    skip: !date || !roomId,
    onError,
  })

  const onErrorClose = () => {
    setError(null)
  }

  /**
   * Our datetime picker needs the slot duration for time selection
   * If we export the duration object, we are spreading luxon for everywhere
   * as we did with moment in the past. So, using minutes is enough now
   */
  const slotDuration = getSlotDurationFromSettings(telehealth)
  const slotDurationInMinutes = slotDuration.minutes

  /**
   * in case of room events, we don't need to ask for available slots,
   * any time is allowed during the same day
   */

  const availableSlots = roomId
    ? data?.availableSlots ?? []
    : [
        {
          start: fromIsoDate,
          end: toIsoDate,
        },
      ]

  return {
    isLoading,
    availableSlots,
    slotDurationInMinutes,
    error,
    refetch,
    onErrorClose,
  }
}
