import { Badge, BadgeProps, ThemeTypings } from '@chakra-ui/react'
import React from 'react'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'
import { SubscriptionStatus as SubscriptionStatusEnum } from '../../api/interfaces'
import { assertUnreachable } from '../../utils'

export const statusTranslation: Record<SubscriptionStatusEnum, MessageDescriptor> = {
  [SubscriptionStatusEnum.ACTIVE]: {
    id: 'SubscriptionStatus.active',
    defaultMessage: 'Active'
  },
  [SubscriptionStatusEnum.CANCELED]: {
    id: 'SubscriptionStatus.canceled',
    defaultMessage: 'Canceled'
  },
  [SubscriptionStatusEnum.INCOMPLETE]: {
    id: 'SubscriptionStatus.incomplete',
    defaultMessage: 'Incompleted'
  },
  [SubscriptionStatusEnum.INCOMPLETE_EXPIRED]: {
    id: 'SubscriptionStatus.incompleted_expired',
    defaultMessage: 'Incompleted expired'
  },
  [SubscriptionStatusEnum.PAST_DUE]: {
    id: 'SubscriptionStatus.past_due',
    defaultMessage: 'Past due'
  },
  [SubscriptionStatusEnum.TRIALING]: {
    id: 'SubscriptionStatus.trialing',
    defaultMessage: 'Trial'
  },
  [SubscriptionStatusEnum.UNPAID]: {
    id: 'SubscriptionStatus.unpaid',
    defaultMessage: 'Unpaid'
  },
}

const statusMessages = defineMessages(statusTranslation)


interface Props extends BadgeProps {
  status: SubscriptionStatusEnum
}

export const SubscriptionStatus: React.FC<Props> = props => {
  const { status, ...badgeProps } = props

  const intl = useIntl()

  const colorScheme = getColorScheme(status)
  const text = intl.formatMessage(statusMessages[status])

  return <Badge colorScheme={colorScheme} {...badgeProps} >
    {text}
  </Badge>

}

const getColorScheme = (status: SubscriptionStatusEnum): ThemeTypings["colorSchemes"] => {
  switch (status) {
    case SubscriptionStatusEnum.ACTIVE:
      return 'green'

    case SubscriptionStatusEnum.CANCELED:
    case SubscriptionStatusEnum.INCOMPLETE:
    case SubscriptionStatusEnum.INCOMPLETE_EXPIRED:
    case SubscriptionStatusEnum.UNPAID:
      return 'red'

    case SubscriptionStatusEnum.PAST_DUE:
      return 'orange'

    case SubscriptionStatusEnum.TRIALING:
      return 'purple'

    default:
      assertUnreachable('Invalid subscription status', status)
  }
}