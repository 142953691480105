import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import {
  Box,
  BoxProps,
  Button,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  Skeleton,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useDebounceExecute } from '../useDebounceExecute'
import {
  RiArrowRightSLine as RightArrowIcon,
  RiArrowLeftSLine as LeftArrowIcon,
  RiSearchLine as SearchIcon,
} from 'react-icons/ri'

const messages = defineMessages({
  search: {
    id: 'UI.label_search',
    defaultMessage: 'Search',
  },
  noResultsFound: {
    id: 'UI.no_items_found',
    defaultMessage: 'No results found',
  },
  resultCount: {
    id: 'UI.showing_result_count',
    defaultMessage: 'Showing {start}-{end} out of {total} results.',
  },
})

interface Props<T> extends BoxProps {
  items: Array<T>
  total: number
  page: number
  itemsPerPage: number
  renderItem: (item: T) => React.ReactNode
  onSearch: (q: string) => void
  onPageChange: (page: number) => void
  defaultSearch?: string
  isLoading?: boolean
  isDisabled?: boolean
  debounce?: number
  placeholder?: string
  initialFocus?: boolean
}

export function PaginatedList<T>(props: Props<T>) {
  const {
    items,
    total,
    page,
    itemsPerPage,
    renderItem,
    onSearch,
    onPageChange,
    defaultSearch,
    isLoading,
    isDisabled,
    debounce,
    placeholder,
    initialFocus,
    ...boxProps
  } = props

  const intl = useIntl()

  const { execute } = useDebounceExecute(debounce ?? 300)
  const debouncedOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    execute(() => {
      if (value !== defaultSearch) {
        onSearch(value)
      }
    })
  }

  const searchPlaceholder = placeholder ?? intl.formatMessage(messages.search)
  return (
    <Box {...boxProps}>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={SearchIcon} />
        </InputLeftElement>
        <Input
          placeholder={searchPlaceholder}
          defaultValue={defaultSearch ?? ''}
          onChange={debouncedOnSearch}
          autoFocus={initialFocus}
          isDisabled={isDisabled}
        />
      </InputGroup>
      <List py={2}>
        {isLoading
          ? <ListSkeleton />
          : items.length === 0
            ? <NoResults />
            : items.map(renderItem)}
      </List>
      <Paginator
        onPageChange={onPageChange}
        page={page}
        itemsPerPage={itemsPerPage}
        total={total}
        isDisabled={isDisabled}
      />
    </Box>
  )
}

type PaginatorProps = StackProps & {
  onPageChange: (page: number) => void
  page: number
  itemsPerPage: number
  total: number
  isDisabled?: boolean
}

export const Paginator: React.FC<PaginatorProps> = (props) => {
  const { page, total, itemsPerPage, isDisabled, onPageChange, ...stackProps } = props

  const intl = useIntl()

  const onIncrement = () => {
    const maxPage = Math.ceil(total / itemsPerPage)
    onPageChange(page < maxPage - 1 ? page + 1 : maxPage - 1)
  }

  const onDecrement = () => {
    onPageChange(page - 1 <= 0 ? 0 : page - 1)
  }

  const showingStart = page * itemsPerPage + 1

  const showingEnd = showingStart + itemsPerPage < total ? showingStart + itemsPerPage - 1 : total

  const currentIndex = page + 1

  if (total === 0) {
    return null
  }

  return (
    <VStack {...stackProps}>
      <HStack justify={'center'}>
        <Button onClick={onDecrement} size={'sm'} isDisabled={isDisabled}>
          <Icon as={LeftArrowIcon} />
        </Button>
        <Text fontSize="md">{currentIndex}</Text>
        <Button onClick={onIncrement} size={'sm'} isDisabled={isDisabled}>
          <Icon as={RightArrowIcon} />
        </Button>
      </HStack>
      <Text>
        {intl.formatMessage(messages.resultCount, {
          start: showingStart,
          end: showingEnd,
          total: total,
        })}
      </Text>
    </VStack>
  )
}

interface ListSkeletonProps {
  length?: number
}

const ListSkeleton: React.FC<ListSkeletonProps> = (props) => {
  const { length = 6 } = props

  return (
    <Box pt={2}>
      {Array.from({ length: Math.ceil(length) }, (v, i) => (
        <Box key={`skeleton-1-${i}`}>
          <Skeleton mx={2} w="45%" mb={3} height={'16px'} />
          <Skeleton mx={2} w="30%" mb={5} height={'16px'} />
        </Box>
      ))}
    </Box>
  )
}

const NoResults = () => {
  const intl = useIntl()

  return (
    <VStack py={10}>
      <Heading size={'sm'} colorScheme={'gray'}>
        {intl.formatMessage(messages.noResultsFound)}
      </Heading>
    </VStack>
  )
}
