import { gql } from '@apollo/client'
import { FullList } from '../fragments'
import { FullExam } from '../fragments'

export const GET_EXAMS = gql`
  query exams($q: String, $offset: Int, $limit: Int) {
    exams(q: $q, offset: $offset, limit: $limit) {
      docs {
        ...FullExam
      }
      count
    }
  }
  ${FullExam}
`

export const GET_LIST_BY_ID = gql`
  query list($id: ID!) {
    list(id: $id) {
      ...FullList
    }
  }
  ${FullList}
`

export const GET_LISTS = gql`
  query lists($q: String, $offset: Int, $limit: Int) {
    lists(q: $q, offset: $offset, limit: $limit) {
      count
      docs {
        ...FullList
      }
    }
  }
  ${FullList}
`
