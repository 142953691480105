import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useIntl, defineMessages } from 'react-intl'
import {
  Box,
  BoxProps,
  Button,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { ReferenceImage } from './'
import { DynamicTranslation } from '../i18n'
import { FullChecklistEntry, ChecklistEntryType, FullReference } from '../../api/interfaces'


const messages = defineMessages({
  labelCitation: {
    id: 'ChecklistReferenceViewer.label_citations',
    defaultMessage: '***'
  },
  labelReferences: {
    id: 'ChecklistReferenceViewer.label_references',
    defaultMessage: 'References'
  },
  closeButton: {
    id: 'UI.button_close',
    defaultMessage: 'Close'
  }
})

interface CitationProps extends BoxProps {
  value: string
}

export const ChecklistCitation: React.FC<CitationProps> = props => {
  const { value, ...boxProps } = props


  return <Box {...boxProps}>
    <ReactMarkdown >{value}</ReactMarkdown>
  </Box>
}

interface ChecklistReferenceBodyProps extends BoxProps {
  reference: FullReference
  isDisabled?: boolean
}
export const ChecklistReferenceBody: React.FC<ChecklistReferenceBodyProps> = props => {
  const { reference, isDisabled, ...boxProps } = props

  return <Box fontSize={'sm'} {...boxProps} display={'inline'} alignItems={'baseline'}>
    {
      reference.name?.text && <DynamicTranslation message={reference.name} as='span' />
    }
    {
      reference.link &&
      <Link href={reference.link} fontSize={'sm'} target='_blank' isExternal rel='noopener noreferrer'>
        {reference.link}
      </Link>
    }
    {
      reference.image &&
      <ReferenceImage url={reference.image} fontSize={'sm'} isDisabled={isDisabled} />
    }
  </Box>
}

interface ChecklistReferenceProps {
  items: Array<FullChecklistEntry>
  isDisabled?: boolean
}

export const ChecklistReference: React.FC<ChecklistReferenceProps> = props => {
  const { items, isDisabled } = props

  const intl = useIntl()
  const { isOpen, onClose, onOpen } = useDisclosure()

  if (items.length === 0) return null

  return <>
    <Button onClick={onOpen} isDisabled={isDisabled} variant={'link'} colorScheme={'brand'}>
      {intl.formatMessage(messages.labelCitation)}
    </Button>

    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {intl.formatMessage(messages.labelReferences)}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <List>
            {
              items.map((item, index) => (
                <ListItem key={index}>
                  {
                    item.type === ChecklistEntryType.CITATION && item?.name?.text &&
                    <ChecklistCitation value={item.name.text} />
                  }
                </ListItem>
              ))}
          </List>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='brand' mr={3} onClick={onClose}>
            {intl.formatMessage(messages.closeButton)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>

}
