import React from 'react'
import { FormLabel, Input, InputGroup, InputLeftAddon, InputProps, InputRightAddon } from '@chakra-ui/react'
import { useField } from 'react-final-form'
import { Control, Error } from './utils'

interface TextFieldProps extends InputProps {
  name: string
  label: string
  before?: React.ReactNode
  after?: React.ReactNode
  hideLabel?: boolean
}

export const TextField: React.FC<TextFieldProps> = (props) => {
  if (props.before || props.after) {
    return <DecoratedInput {...props} />
  }

  return <PlainInput {...props} />
}

const DecoratedInput: React.FC<TextFieldProps> = (props) => {
  const { name, label, hideLabel, before, after, ...otherInputProps } = props
  const { input, meta } = useField(name)

  return (
    <Control name={name}>
      {!hideLabel && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup size={otherInputProps?.size}>
        {before && <InputLeftAddon>{before}</InputLeftAddon>}
        <Input {...input} {...otherInputProps} isInvalid={meta.error && meta.touched} id={name} placeholder={label} />
        {after && <InputRightAddon>{after}</InputRightAddon>}
      </InputGroup>
      <Error name={name} />
    </Control>
  )
}

const PlainInput: React.FC<TextFieldProps> = (props) => {
  const { name, label, hideLabel, ...otherInputProps } = props
  const { input, meta } = useField(name)

  return (
    <Control name={name}>
      {!hideLabel && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Input {...input} {...otherInputProps} isInvalid={meta.error && meta.touched} id={name} placeholder={label} />
      <Error name={name} />
    </Control>
  )
}
