import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { getNormalVitalsByAge, isInRange } from 'wd-common/src/logic/triage/utils'
import { Temperature as TemperatureType } from '../../api/interfaces'
import {
  Box,
  Button,
  ButtonProps,
  Collapse,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagProps,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { TextField } from '../Form'
import { PatientAgeCategory } from '../Patients'
import { NewTemperature, useCreateTemperature } from '.'
import { PrintableSignatureTag, ErrorViewer } from '..'
import { DeleteMeasurementButton } from './'
import {
  RiAddLine as AddIcon,
  RiArrowRightSLine as ExpandButton,
  RiAlertLine as WarningIcon,
} from 'react-icons/ri'
import { DateTime } from 'luxon'
import { MetricUnits, UnitTypes, useUnitConversion } from '../useUnitConversion'

const messages = defineMessages({
  title: {
    id: 'Vitals.temperature_title',
    defaultMessage: 'Temperature',
  },
  shortTitle: {
    id: 'Vitals.temperature_abbreviation',
    defaultMessage: 'T',
  },
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?',
  },
  degreesC: {
    id: 'Vitals.degrees_c_label',
    defaultMessage: 'ºC',
  },
  reference: {
    id: 'Vitals.reference_title',
    defaultMessage: 'Reference',
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  submitButton: {
    id: 'NonStaffUI.button_submit',
    defaultMessage: 'Submit',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
  values: {
    id: 'UI.values_label',
    defaultMessage: 'Values',
  },
})

type MeasurementListProps = {
  values: Array<TemperatureType>
  patientId: string
  visitId: string
  birthdate: string
}

type TemperatureProps = ButtonProps & MeasurementListProps

export const Temperature: React.FC<TemperatureProps> = (props) => {
  const { values, patientId, visitId, birthdate, ...buttonProps } = props

  const { onToggle, isOpen } = useDisclosure()

  const noValues = values.length === 0
  const intl = useIntl()

  return (
    <>
      <Button mr="-px" {...buttonProps} onClick={onToggle}>
        {noValues ? (
          <>
            <Icon as={AddIcon} />
            <Text ml={2}>{intl.formatMessage(messages.shortTitle)}</Text>
          </>
        ) : (
          <ValueWithWarning value={values[0]} birthdate={birthdate} />
        )}
      </Button>

      <TemperatureModal
        values={values}
        isOpen={isOpen}
        onToggle={onToggle}
        birthdate={birthdate}
        patientId={patientId}
        visitId={visitId}
      />
    </>
  )
}

const MeasurementList: React.FC<MeasurementListProps> = (props) => {
  const { values, patientId, visitId, birthdate } = props

  const intl = useIntl()
  const { onToggle, isOpen } = useDisclosure({ defaultIsOpen: true })
  return (
    <Box px={4} py={2} my={2} borderWidth={1} rounded={'lg'}>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Heading size={'sm'} mb={1} onClick={onToggle} cursor="pointer">
          {intl.formatMessage(messages.values)}
        </Heading>
        <IconButton
          size="sm"
          aria-label={'expand all'}
          colorScheme={'brand'}
          variant="ghost"
          onClick={onToggle}
          icon={<ExpandButton />}
          sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}
        />
      </HStack>
      <Collapse startingHeight={0} in={isOpen} animateOpacity>
        {values.map((value, index) => {
          const key = `t-${value.id}${index}`

          return (
            <HStack my={2} justifyContent={'space-between'} alignItems={'center'} key={key}>
              <VStack alignItems={'flex-start'} spacing={0}>
                <ValueWithWarning value={value} birthdate={birthdate} />
                <PrintableSignatureTag
                  signature={value.updated}
                  dateFormat={DateTime.DATETIME_SHORT}
                  fontSize="sm"
                  m={0}
                  color="GrayText"
                />
              </VStack>
              <DeleteMeasurementButton
                patientId={patientId}
                visitId={visitId}
                measurementId={value.id}
                size="sm"
              />
            </HStack>
          )
        })}
      </Collapse>
    </Box>
  )
}

type TemperatureModalProps = MeasurementListProps & {
  isOpen: boolean
  onToggle: () => void
}

const TemperatureModal: React.FC<TemperatureModalProps> = (props) => {
  const { values, birthdate, patientId, visitId, isOpen, onToggle } = props

  const intl = useIntl()
  const { convertUnitFromInput, getUnitTranslation } = useUnitConversion()

  const params = {
    patientId,
    visitId,
  }

  const { onCreate, onErrorClose, error, isLoading, validate } = useCreateTemperature(params)
  const onSubmit = (values) => {
    const newTemperature: NewTemperature = {
      degrees_c: convertUnitFromInput(Number(values?.degrees_c), UnitTypes.TEMPERATURE),
    }

    onCreate(newTemperature)
  }

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <Form
        onSubmit={onSubmit}
        validate={validate}
        keepDirtyOnReinitialize
        render={({ form, handleSubmit }) => (
          <form
            onSubmit={async (event) => {
              await handleSubmit(event)
              const { valid } = form.getState()
              if (valid) {
                form.resetFieldState('degrees_c')
                form.reset()
              }
            }}
          >
            <ModalContent>
              <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TextField
                  name="degrees_c"
                  label={getUnitTranslation(MetricUnits.CELSIUS)}
                  type={'number'}
                  step={0.1}
                />

                {error && <ErrorViewer title={error} onClose={onErrorClose} />}

                <MeasurementList
                  patientId={patientId}
                  visitId={visitId}
                  birthdate={birthdate}
                  values={values}
                />

                <VitalReference birthdate={birthdate} />
              </ModalBody>

              <ModalFooter>
                <Button isFullWidth={isFullWidth} onClick={onToggle}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  colorScheme={'brand'}
                  isLoading={isLoading}
                  type="submit"
                  isFullWidth={isFullWidth}
                  ml={2}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        )}
      />
    </Modal>
  )
}

interface CheckWarningsParams {
  birthdate: string
  temperature: number
}

const checkWarnings = (params: CheckWarningsParams) => {
  const { birthdate, temperature } = params

  const reference = getNormalVitalsByAge({ birthdate })
  const tReference = (reference || {}).t

  const inRange =
    !tReference ||
    !temperature ||
    isInRange((tReference || {}).min, (tReference || {}).max, temperature)
  return {
    inRange,
    reference: tReference,
  }
}

type ValueWithWarningProps = {
  value: TemperatureType
  birthdate: string
}

const ValueWithWarning: React.FC<ValueWithWarningProps> = (props) => {
  const { value, birthdate } = props

  const intl = useIntl()
  const { getUnitTranslation, convertUnitFromDb } = useUnitConversion()

  const temperature = convertUnitFromDb(value?.degrees_c, UnitTypes.TEMPERATURE)

  const { inRange } = checkWarnings({ birthdate, temperature: value?.degrees_c })

  return (
    <HStack alignItems={'center'}>
      {!inRange && <Icon as={WarningIcon} color="red.400" />}
      <Text mx={1}>{intl.formatMessage(messages.shortTitle)}:</Text>
      <Text fontWeight={'bold'} color={!inRange ? 'red.400' : undefined}>
        {intl.formatNumber(temperature, { maximumFractionDigits: 1 }) ?? '--'}
        {getUnitTranslation(MetricUnits.CELSIUS)}
      </Text>
    </HStack>
  )
}

type VitalReferenceProps = {
  birthdate: string
}

const VitalReference: React.FC<VitalReferenceProps> = (props) => {
  const { birthdate } = props

  const intl = useIntl()
  const { convertUnitFromDb, getUnitTranslation } = useUnitConversion()

  const reference = getNormalVitalsByAge({ birthdate })
  const tReference = (reference || {}).t

  if (!tReference) {
    return null
  }

  const min = intl.formatNumber(convertUnitFromDb(tReference.min, UnitTypes.TEMPERATURE))
  const max = intl.formatNumber(convertUnitFromDb(tReference.max, UnitTypes.TEMPERATURE))

  const unit = getUnitTranslation(MetricUnits.CELSIUS)

  return (
    <Box p={4} borderWidth={1} rounded={'lg'}>
      <PatientAgeCategory birthdate={birthdate} mb={1} fontWeight={'bold'} />
      <HStack>
        <Text>{intl.formatMessage(messages.reference)}</Text>
        <Text fontWeight={'bold'} ml={2}>
          {`${min} - ${max} ${unit}`}
        </Text>
      </HStack>
    </Box>
  )
}

type PrintableTemperatureProps = TagProps & {
  birthdate: string
  value: TemperatureType
}

export const PrintableTemperature: React.FC<PrintableTemperatureProps> = (props) => {
  const { value, birthdate, ...tagProps } = props

  return (
    <Tag {...tagProps}>
      <ValueWithWarning value={value} birthdate={birthdate} />
    </Tag>
  )
}

type PdfTemperatureProps = {
  birthdate: string
  value: TemperatureType
}

export const PdfTemperature: React.FC<PdfTemperatureProps> = (props) => {
  const { value, birthdate } = props
  return <ValueWithWarning value={value} birthdate={birthdate} />
}
