import React from 'react'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'
import Select from 'react-select'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SelectorProps,
  useChakraThemeParser,
} from '.'
import { StatusAssesment } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { isValidStatusAssesment } from '../ActiveVisit'

const messages = defineMessages({
  statusAssessment: {
    id: 'StatusAssessmentSelector.status_assessment',
    defaultMessage: 'Status assessment'
  },
})

export const statusAssessmentTranslation: Record<StatusAssesment, MessageDescriptor> = {
  [StatusAssesment.STABLE]: {
    id: 'StatusAssessmentSelector.stable',
    defaultMessage: 'Stable'
  },
  [StatusAssesment.WELL]: {
    id: 'StatusAssessmentSelector.well',
    defaultMessage: 'Well'
  },
  [StatusAssesment.GUARDED]: {
    id: 'StatusAssessmentSelector.guarded',
    defaultMessage: 'Guarded'
  },
  [StatusAssesment.CRITICAL]: {
    id: 'StatusAssessmentSelector.critical',
    defaultMessage: 'Critical'
  },
  [StatusAssesment.DECEASED]: {
    id: 'StatusAssessmentSelector.deceased',
    defaultMessage: 'Deceased'
  }
}

const statusAssessmentMessages = defineMessages(statusAssessmentTranslation)

export type StatusAssessmentSelectorProps = SelectorProps<StatusAssesment> & {
  isMulti: false
}

export const StatusAssessmentSelector: React.FC<StatusAssessmentSelectorProps> = (props) => {
  const {
    value,
  } = props

  const intl = useIntl()
  const theme = useChakraThemeParser()

  const formatOption = (statusAssesment: StatusAssesment): ReactSelectInternalSingleValue => {
    return {
      label: intl.formatMessage(statusAssessmentMessages[statusAssesment]),
      value: statusAssesment
    }
  }

  /**
   * Filter statuses for lactation use 
   */
  const options = Object.values(StatusAssesment)
  .filter(status => (status !== StatusAssesment.CRITICAL && status !== StatusAssesment.DECEASED))
  .map(formatOption)

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (isValidStatusAssesment(value as string)) {
      return formatOption(value as StatusAssesment)
    }

    return null
  }

  const _onChange = value => {
    if (!value) {
      props.onChange('' as StatusAssesment)
      return
    }

    const selectedValue = value

    props.onChange(selectedValue.value as StatusAssesment)
  }

  return <Select
    options={options}
    value={parseValue(value)}
    onChange={_onChange}
    isLoading={props.isLoading}
    isMulti={props.isMulti}
    isClearable={props.isClearable}
    isDisabled={props.isDisabled}
    isSearchable={props.isSearchable}
    aria-invalid={props.isInvalid}
    placeholder={intl.formatMessage(messages.statusAssessment)}
    blurInputOnSelect={props.blurInputOnSelect}
    theme={theme}
  />
}

