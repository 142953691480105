import { MutationHookOptions, useMutation } from '@apollo/client'
import { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullExamWithoutId, FullPatientWithActiveVisit } from '../../api/interfaces'
import { UPDATE_EXAM } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  patientNotFound: {
    id: 'PhysicalExam.patient_not_found',
    defaultMessage: 'Patient not found',
  },
  errorChagingExam: {
    id: 'PhysicalExam.error_updating_exam',
    defaultMessage: 'Could not save the exam. Please, try again',
  },
  errorExamActionsDefault: {
    id: 'PhysicalExam.error_exam_actions_default',
    defaultMessage:
      'Something went wrong while trying to perform an action in the examn. Please, try again',
  },
})

interface UpdateExamVariables {
  patientId: string
  visitId: string
  exam: FullExamWithoutId
}

interface UpdateExamData {
  updateExam: FullPatientWithActiveVisit
}

type UpdateExamParams = MutationHookOptions<UpdateExamData, UpdateExamVariables> & {
  patientId: string
  visitId: string
}

export const useUpdateExam = (params: UpdateExamParams) => {
  const { patientId, visitId, ...rest } = params

  const [error, setError] = useState<Nullable<string>>(null)

  const intl = useIntl()

  const translateError = (errorCode) => {
    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_updating_exam':
        return setError(intl.formatMessage(messages.errorChagingExam))

      default:
        return setError(intl.formatMessage(messages.errorExamActionsDefault))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)

    if (typeof rest.onError === 'function') {
      rest.onError(errors)
    }
  }

  const onCompleted = (data) => {
    setError(null)
    if (typeof rest.onCompleted === 'function') {
      rest.onCompleted(data)
    }
  }

  const [updateExam, { loading: isLoading }] = useMutation<UpdateExamData, UpdateExamVariables>(
    UPDATE_EXAM,
    { onError, onCompleted }
  )

  const onSubmit = (exam: FullExamWithoutId) => {
    onErrorClose()

    const variables = {
      patientId,
      visitId,
      exam,
    }

    updateExam({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    onErrorClose,
  }
}
