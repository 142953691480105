import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  ChecklistReference,
  ChecklistTextEntry,
  ChecklistDivider,
  ChecklistProcedure,
  ChecklistStudy,
  ChecklistDrugGuideline,
} from '..'

import { QualityEquationResultTag } from '../../QualityChecks'
import {
  ChecklistEntryType,
  DrugGuideline,
  FullChecklistEntry,
  // FullProcedureOrder,
  //FullStudyOrder
  QualityEquationResult as QualityEquationResultInterface,
} from '../../../api/interfaces'
import { Box, BoxProps, Heading, HStack, List, ListItem } from '@chakra-ui/react'
import { Nullable } from '../../../utils'

const messages = defineMessages({
  titleTreatment: {
    id: 'Checklist.title_treatment',
    defaultMessage: 'Treatment',
  },
})

interface OnItemChangeParams {
  key: number
  checked: boolean
}

/**
 * Treatment
 */
type TreatmentProps = Omit<BoxProps, 'onChange'> & {
  items: Array<FullChecklistEntry | DrugGuideline>
  onChange: (params: OnItemChangeParams) => void
  // patientId: string
  // visitId: string
  // studyOrders: Array<FullStudyOrder>
  // procedureOrders: Array<FullProcedureOrder>
  qualityCheck: Nullable<QualityEquationResultInterface>
  isDisabled?: boolean
}

export const Treatment: React.FC<TreatmentProps> = (props) => {
  const { items, isDisabled, onChange, qualityCheck, ...boxProps } =
    props

  const intl = useIntl()
  const citations = items.filter(({ type }) => type === ChecklistEntryType.CITATION) as Array<FullChecklistEntry>

  const onCheckChange = (index) => (checked) => {
    const key = items[index].key
    if (key !== null) {
      onChange({ key, checked })
    }
  }

  return (
    <Box {...boxProps}>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <HStack alignItems={'center'}>
          <Heading size={'md'}>{intl.formatMessage(messages.titleTreatment)}</Heading>
          <ChecklistReference items={citations} />
        </HStack>
        {qualityCheck?.value && (
          <QualityEquationResultTag
            key={`icon-qcheck-treatment`}
            name={qualityCheck.name}
            result={qualityCheck.value}
          />
        )}
      </HStack>
      <List ml={4} my={2}>
        {items.map((item, index) => {
          if (item.type === ChecklistEntryType.DIVIDER && item.name) {
            return (
              <ListItem key={`${ChecklistEntryType.DIVIDER}-${index}`}>
                <ChecklistDivider name={item.name} reference={item.reference} />
              </ListItem>
            )
          }

          if (item.type === ChecklistEntryType.TEXT) {
            return (
              <ListItem key={`${ChecklistEntryType.TEXT}-${index}`}>
                <ChecklistTextEntry
                  entry={item as FullChecklistEntry}
                  isDisabled={isDisabled}
                  onCheckChange={onCheckChange(index)}
                />
              </ListItem>
            )
          }

          if (item.type === ChecklistEntryType.PROCEDURE) {
            return (
              <ListItem key={`${ChecklistEntryType.PROCEDURE}-${index}`}>
                <ChecklistProcedure
                  // patientId={patientId}
                  // visitId={visitId}
                  // procedureOrders={procedureOrders}
                  entry={item as FullChecklistEntry}
                  isDisabled={isDisabled}
                  onCheckChange={onCheckChange(index)}
                />
              </ListItem>
            )
          }

          if (item.type === ChecklistEntryType.STUDY) {
            return (
              <ListItem key={`${ChecklistEntryType.STUDY}-${index}`}>
                <ChecklistStudy
                  // patientId={patientId}
                  // visitId={visitId}
                  // studyOrders={studyOrders}
                  entry={item as FullChecklistEntry}
                  isDisabled={isDisabled}
                  onCheckChange={onCheckChange(index)}
                />
              </ListItem>
            )
          }

          if (item.type === ChecklistEntryType.DRUG) {
            return (
              <ListItem key={`${ChecklistEntryType.DRUG}-${index}`}>
                <ChecklistDrugGuideline
                  entry={item as DrugGuideline}
                  isDisabled={isDisabled}
                  onCheckChange={onCheckChange(index)}
                />
              </ListItem>
            )
          }

          return null
        })}
      </List>
    </Box>
  )
}
