import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import {
  ADD_SURVEY_TO_HISTORY,
  REMOVE_SURVEY_FROM_HISTORY,
  UPDATE_SURVEY_CHECKBOX_IN_HISTORY,
  UPDATE_SURVEY_INPUT_IN_HISTORY,
} from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { useDebounceExecute } from '../useDebounceExecute'

const messages = defineMessages({
  patientNotFound: {
    id: 'MedicalHistory.patient_not_found',
    defaultMessage: 'Patient not found',
  },
  errorAddingEntry: {
    id: 'MedicalHistory.error_adding_entry',
    defaultMessage: 'Could not add entry to the history. Please, try again',
  },
  errorRemovingEntry: {
    id: 'MedicalHistory.error_removing_entry',
    defaultMessage: 'Could not remove entry from the history. Please, try again',
  },
  errorHistoryActionsDefault: {
    id: 'MedicalHistory.error_history_actions_default',
    defaultMessage: 'Something went wrong while trying to perform an action in the history. Please, try again',
  },
  empty: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
})

/**
 * All the adding mutations share these interface
 */
interface AddSurveyVariables {
  patientId: string
  visitId: string
  surveyId: string
}

interface AddSurveyData {
  addSurveyToHistory: FullPatientWithActiveVisit
}
/**
 * We don't need to specify the returned value.
 * The cache will automatically updated
 */
export type UseAddSurveyToHistoryParams = MutationHookOptions<AddSurveyData, AddSurveyVariables> & {
  patientId: string
  visitId: string
}

export const useAddSurveyToHistory = (params: UseAddSurveyToHistoryParams) => {
  const { patientId, visitId, ...rest } = params
  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_content_to_history':
        return setError(intl.formatMessage(messages.errorAddingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [addSurvey, { loading: isLoading }] = useMutation(ADD_SURVEY_TO_HISTORY, {
    onError,
    ...rest,
  })

  const onSubmit = (surveyId: string) => {
    setError(null)

    const variables: AddSurveyVariables = {
      patientId,
      visitId,
      surveyId,
    }

    addSurvey({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    onErrorClose,
  }
}

interface RemoveSurveyFromHistoryVariables {
  patientId: string
  visitId: string
  historyId: string
}

type UseRemoveSurveyFromHistoryParams = MutationHookOptions<any, RemoveSurveyFromHistoryVariables> & {
  patientId: string
  visitId: string
}

export const useRemoveSurveyFromHistory = (params: UseRemoveSurveyFromHistoryParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_removing_content_from_history':
        return setError(intl.formatMessage(messages.errorRemovingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [removeSurvey, { loading: isLoading }] = useMutation(REMOVE_SURVEY_FROM_HISTORY, {
    onError,
    ...rest,
  })

  const onDelete = (historyId: string) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      historyId,
    }

    removeSurvey({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}

type UpdateSurveyCheckboxVariables = {
  patientId: string
  visitId: string
  historyId: string
  blockId: string
  checked: boolean
}

type UpdateSurveyInputVariables = {
  patientId: string
  visitId: string
  historyId: string
  blockId: string
  value: string
}

type UpdateSurveyInHistoryVariables = UpdateSurveyInputVariables | UpdateSurveyCheckboxVariables

type UseUpdateSurveyCheckboxParamsInHistory = MutationHookOptions<any, UpdateSurveyInHistoryVariables> & {
  patientId: string
  visitId: string
  historyId: string
}

export const useUpdateSurveyInHistory = (params: UseUpdateSurveyCheckboxParamsInHistory) => {
  const { patientId, visitId, historyId, ...rest } = params

  const { execute } = useDebounceExecute(300)
  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_removing_content_from_history':
        return setError(intl.formatMessage(messages.errorRemovingEntry))

      default:
        return setError(intl.formatMessage(messages.errorHistoryActionsDefault))
    }
  }

  const [updateCheckbox, { loading: isUpdatingCheckbox }] = useMutation(UPDATE_SURVEY_CHECKBOX_IN_HISTORY, {
    onError,
    ...rest,
  })

  const [updateInput, { loading: isUpdatingInput }] = useMutation(UPDATE_SURVEY_INPUT_IN_HISTORY, {
    onError,
    ...rest,
  })

  const onCheckboxChange = (blockId: string, checked: boolean) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      historyId,
      blockId,
      checked,
    }

    execute(() => updateCheckbox({ variables }))
  }

  const onInputChange = (blockId: string, value: string) => {
    setError(null)

    const variables = {
      patientId,
      visitId,
      historyId,
      blockId,
      value,
    }

    execute(() => updateInput({ variables }))
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onCheckboxChange,
    onInputChange,
    isLoading: isUpdatingCheckbox || isUpdatingInput,
    error,
    onErrorClose,
  }
}
