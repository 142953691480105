import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { REMOVE_MEASUREMENT } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  patientNotFound: {
    id: 'RXActions.patient_not_found',
    defaultMessage: 'The patient was not found.',
  },
  visitNotFound: {
    id: 'Checklist.visit_not_found',
    defaultMessage: 'Could not find the current visit. Please, reload the page',
  },
  errorCreatingMeasurement: {
    id: 'Vitals.error_adding_measurement',
    defaultMessage: 'We could not add the measurement, try again later.',
  },
  invalidMeasurementId: {
    id: 'Vitals.invalid_measurement_id',
    defaultMessage: 'You must use a valid measurement id.',
  },
  errorRemovingMeasurement: {
    id: 'Vitals.error_removing_measurement',
    defaultMessage: 'We could not remove the measurement, try again later.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface RemoveMeasurementVariables {
  patientId: string
  visitId: string
  measurementId: string
}

interface RemoveMeasurementData {
  removeMeasurement: FullPatientWithActiveVisit
}

type CreateBloodPressureParams = MutationHookOptions<RemoveMeasurementData, RemoveMeasurementVariables> & {
  patientId: string
  visitId: string
}

export const useRemoveMeasurement = (params: CreateBloodPressureParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'invalid_measurement_id':
        return setError(intl.formatMessage(messages.invalidMeasurementId))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFound))

      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_removing_measurement':
        return setError(intl.formatMessage(messages.errorRemovingMeasurement))

      case 'invalid_signature':
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [removeMeasurement, { loading: isLoading }] = useMutation(REMOVE_MEASUREMENT, {
    onError,
    ...rest,
  })

  const onDelete = (measurementId: string) => {
    const variables = {
      patientId,
      visitId,
      measurementId,
    }

    removeMeasurement({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}
