import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import {
  getNormalVitalsByAge,
  isInRange,
} from 'wd-common/src/logic/triage/utils'
import { isValidSex, Nullable } from '../../utils'
import { MidUpperArmCircumference as MidUpperArmCircumferenceType } from '../../api/interfaces'
import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagProps,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { TextField, ValidateFn } from '../Form'
import { PatientAgeCategory } from '../Patients'
import { NewMidUpperArmCircumference, useCreateMidUpperArmCircumference, useUpdateMidUpperArmCircumference } from '.'
import { ConfirmButton, ErrorViewer } from '..'
import { useRemoveMeasurement } from './useRemoveMeasurement'
import { getAge } from '../DateAndTime'
import {
  RiAddLine as AddIcon,
  RiDeleteBin2Line as DeleteIcon,
  RiAlertLine as WarningIcon
} from 'react-icons/ri'

const messages = defineMessages({
  title: {
    id: 'Vitals.mid_upper_arm_circumference_title',
    defaultMessage: 'Mid Upper Arm Circumference'
  },
  shortTitle: {
    id: 'Vitals.mid_upper_arm_circumference_abbreviation',
    defaultMessage: 'MUAC'
  },
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?'
  },
  cm: {
    id: 'Vitals.cm_label',
    defaultMessage: 'cm'
  },
  reference: {
    id: 'Vitals.reference_title',
    defaultMessage: 'Reference'
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  submitButton: {
    id: 'NonStaffUI.button_submit',
    defaultMessage: 'Submit',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
})

type MidUpperArmCircumferenceProps = ButtonGroupProps & {
  patientId: string
  visitId: string
  birthdate: string
  sex: string
  value: Nullable<MidUpperArmCircumferenceType>
  isDisabled?: boolean
}

export const MidUpperArmCircumference: React.FC<MidUpperArmCircumferenceProps> = (props) => {
  const { value, patientId, visitId, birthdate, sex, isDisabled, ...buttonGroupProps } = props

  if (value) {
    return (
      <Editor
        value={value}
        patientId={patientId}
        visitId={visitId}
        birthdate={birthdate}
        sex={sex}
        isDisabled={isDisabled}
        {...buttonGroupProps}
      />
    )
  }

  return (
    <Creator
      patientId={patientId}
      visitId={visitId}
      birthdate={birthdate}
      sex={sex}
      isDisabled={isDisabled}
      {...buttonGroupProps}
    />
  )
}

type CreatorProps = ButtonGroupProps & {
  patientId: string
  visitId: string
  birthdate: string
  sex: string
  isDisabled?: boolean
}

const Creator: React.FC<CreatorProps> = (props) => {
  const { patientId, visitId, birthdate, sex, isDisabled, ...buttonGroupProps } = props

  const intl = useIntl()

  const { onToggle, isOpen } = useDisclosure()

  const params = {
    patientId,
    visitId,
  }
  const { onCreate, onErrorClose, error, isLoading, validate } = useCreateMidUpperArmCircumference(params)

  return (
    <>
      <ButtonGroup size="sm" isAttached isDisabled={isDisabled} {...buttonGroupProps}>
        <Button mr="-px" onClick={onToggle}>
          <Icon as={AddIcon} />
          <Text ml={2}>{intl.formatMessage(messages.shortTitle)}</Text>
        </Button>
      </ButtonGroup>

      <MidUpperArmCircumferenceModal
        muac={null}
        isOpen={isOpen}
        onToggle={onToggle}
        birthdate={birthdate}
        sex={sex}
        onSubmit={onCreate}
        onErrorClose={onErrorClose}
        error={error}
        isLoading={isLoading}
        validate={validate}
      />

    </>
  )
}

type EditorProps = ButtonGroupProps & {
  patientId: string
  visitId: string
  value: Nullable<MidUpperArmCircumferenceType>
  birthdate: string
  sex: string
  isDisabled?: boolean
}

const Editor: React.FC<EditorProps> = (props) => {
  const { patientId, visitId, birthdate, sex, isDisabled, value, ...buttonGroupProps } = props

  const intl = useIntl()

  const { onToggle, isOpen } = useDisclosure()

  const isLegacy = !value?.id
  const removeParams = {
    patientId,
    visitId,
  }

  const { onDelete, isLoading, error, onErrorClose } = useRemoveMeasurement(removeParams)

  const updateParams = {
    ...removeParams,
    onCompleted: onToggle,
  }

  const {
    onUpdate,
    onErrorClose: onUpdatingErrorClose,
    error: updatingError,
    isLoading: isUpdating,
    validate,
  } = useUpdateMidUpperArmCircumference(updateParams)

  const onSubmit = (values: NewMidUpperArmCircumference) => {
    if (isLegacy) {
      return null
    }

    const measurementId = value.id
    onUpdate(measurementId, values)
  }

  return (
    <>
      <ButtonGroup size="sm" isAttached isDisabled={isDisabled} {...buttonGroupProps}>
        <Button mr="-px" onClick={onToggle}>
          <ValueWithWarning muac={value} birthdate={birthdate} sex={sex} />
        </Button>
        {!isLegacy && (
          <ConfirmButton
            onConfirm={() => onDelete(value.id)}
            message={intl.formatMessage(messages.confirmationMessage)}
            buttonProps={{
              color: 'red.400',
              label: <Icon as={DeleteIcon} />,
              isDisabled,
              isLoading,
            }}
            error={error}
            onErrorClose={onErrorClose}
          />
        )}
      </ButtonGroup>

      <MidUpperArmCircumferenceModal
        muac={value}
        isOpen={isOpen}
        onToggle={onToggle}
        birthdate={birthdate}
        sex={sex}
        onSubmit={onSubmit}
        onErrorClose={onUpdatingErrorClose}
        error={updatingError}
        isLoading={isUpdating}
        validate={validate}
      />

    </>
  )
}

type MidUpperArmCircumferenceModalProps = {
  birthdate: string
  sex: string
  muac: Nullable<MidUpperArmCircumferenceType>
  isOpen: boolean
  onToggle: () => void
  onSubmit: (values: NewMidUpperArmCircumference) => void
  validate: ValidateFn<NewMidUpperArmCircumference>
  error: Nullable<string>
  onErrorClose: () => void
  isLoading: boolean
  isDisabled?: boolean
}

const MidUpperArmCircumferenceModal: React.FC<MidUpperArmCircumferenceModalProps> = (props) => {
  const { validate, onSubmit, error, onErrorClose, isLoading, muac, birthdate, sex, isDisabled, isOpen, onToggle } = props

  const intl = useIntl()

  const _onSubmit = (values) => {
    const newMidUpperArmCircumference: NewMidUpperArmCircumference = {
      cm: parseFloat(values?.cm),
    }

    onSubmit(newMidUpperArmCircumference)
  }

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <Form
        onSubmit={_onSubmit}
        validate={validate}
        initialValues={muac}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TextField
                  name="cm"
                  label={intl.formatMessage(messages.cm)}
                  type={'number'}
                  step={0.1}
                  autoFocus
                />

                <VitalReference birthdate={birthdate} sex={sex} muac={muac} />
                {error && <ErrorViewer title={error} onClose={onErrorClose} />}
              </ModalBody>

              <ModalFooter>
                <Button isFullWidth={isFullWidth} onClick={onToggle}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  colorScheme={'brand'}
                  isLoading={isLoading}
                  type="submit"
                  isDisabled={isDisabled}
                  isFullWidth={isFullWidth}
                  ml={2}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        )}
      />
    </Modal>
  )
}

const checkWarnings = (patient, muac) => {
  const reference = getNormalVitalsByAge(patient)

  const muacReference = isValidSex((patient || {}).sex) && reference?.muac
    ? reference.muac[patient.sex.toLowerCase()]
    : null

  const inRange = !muacReference || !muac || isInRange(muacReference?.min, muacReference?.max, muac)

  // only show warning for patients > 19y or between 6m - 5y
  const ageInMonths = getAge(new Date(patient.birthdate), 'month')

  const isWarningEnabled = ageInMonths !== null && (
    (ageInMonths > 6 && ageInMonths < 5 * 12) || ageInMonths > 19 * 12)

  return {
    inRange,
    reference: muacReference,
    isWarningEnabled
  }
}

type ValueWithWarningProps = {
  muac: Nullable<MidUpperArmCircumferenceType>
  birthdate: string
  sex: string
}

const ValueWithWarning: React.FC<ValueWithWarningProps> = (props) => {
  const { muac, birthdate, sex } = props

  const intl = useIntl()

  const { isWarningEnabled, inRange } = checkWarnings({ birthdate, sex }, muac?.cm)
  const showWarning = isWarningEnabled && !inRange

  return (
    <HStack alignItems={'center'}>
      {showWarning && <Icon as={WarningIcon} color="red.400" />}
      <Text mx={1}>{intl.formatMessage(messages.shortTitle)}:</Text>
      <Text fontWeight={'bold'} color={showWarning ? 'red.400' : undefined}>
        {muac?.cm ?? '--'}
        {intl.formatMessage(messages.cm)}
      </Text>
    </HStack>
  )
}

type VitalReferenceProps = {
  muac: Nullable<MidUpperArmCircumferenceType>
  birthdate: string
  sex: string
}

const VitalReference: React.FC<VitalReferenceProps> = (props) => {
  const { muac, birthdate, sex } = props

  const intl = useIntl()

  const { inRange, reference } = checkWarnings({ birthdate, sex }, muac?.cm)

  if (!reference) {
    return null
  }

  return (
    <Box p={4} borderWidth={1} rounded={'lg'}>
      <PatientAgeCategory birthdate={birthdate} mb={1} fontWeight={'bold'} />
      <HStack>
        <Text>{intl.formatMessage(messages.reference)}</Text>
        <Text fontWeight={'bold'} ml={2}>
          {`${reference.min} - ${reference.max} ${intl.formatMessage(messages.cm)}`}
        </Text>
        {!inRange && <Icon as={WarningIcon} ml={2} color="red.400" />}
      </HStack>
    </Box>
  )
}

type PrintableMidUpperArmCircumferenceProps = TagProps & {
  birthdate: string
  sex: string
  value: Nullable<MidUpperArmCircumferenceType>
}

export const PrintableMidUpperArmCircumference: React.FC<PrintableMidUpperArmCircumferenceProps> = (props) => {
  const { value, birthdate, sex, ...tagProps } = props

  return <Tag {...tagProps}>
    <ValueWithWarning
      muac={value}
      birthdate={birthdate}
      sex={sex}
    />
  </Tag>
}
