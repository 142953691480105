import React from 'react'
import { FormLabel, InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { useField } from 'react-final-form'
import { Control, Error } from './utils'
import { DateTimeSelector, DateTimeSelectorProps } from '../Selector'

interface DateFieldProps extends Omit<DateTimeSelectorProps, 'value' | 'onChange'> {
  label: string
  name: string
  before?: React.ReactNode
  isDisabled?: boolean
  isClearable?: boolean
  autoComplete?: string
  autoFocus?: boolean
}

export const DateTimeField: React.FC<DateFieldProps> = (props) => {
  if (props.before) {
    return <DecoratedInput {...props} />
  }

  return <PlainInput {...props} />
}

const DecoratedInput: React.FC<DateFieldProps> = (props) => {
  const { name, before, label } = props

  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <InputGroup>
        <InputLeftAddon>{before}</InputLeftAddon>
        <Input {...props} />
      </InputGroup>
      <Error name={name} />
    </Control>
  )
}

const PlainInput: React.FC<DateFieldProps> = (props) => {
  const { name, label } = props

  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input {...props} />
      <Error name={name} />
    </Control>
  )
}

const Input: React.FC<DateFieldProps> = (props) => {
  const { name, ...dateTimeProps } = props

  const { input, meta } = useField(name)

  return <DateTimeSelector {...dateTimeProps} isInvalid={meta.error && meta.touched} {...input} />
}
