import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { GET_PATIENT_WITH_ACTIVE_VISIT_BY_ID } from '../../api/requests/patients'
import { Nullable } from '../../utils'
import { ActiveVisit, FullPatient, FullPatientWithActiveVisit } from '../../api/interfaces'

export interface PatientWithActiveVisitVariables {
  id: string
}

interface PatientQueryData {
  patient: FullPatientWithActiveVisit
}

export type UsePatientWithActiveVisitParams = QueryHookOptions<PatientQueryData, PatientWithActiveVisitVariables> & {
  patientId: string
}

interface ReturnedValue {
  patient: Nullable<FullPatient>
  activeVisit: Nullable<ActiveVisit>
  isLoading: boolean
  error: Nullable<string>
  onErrorClose: () => void
}

export function useActiveVisit(params: UsePatientWithActiveVisitParams): ReturnedValue {
  const { patientId, ...rest } = params
  const [error, setError] = React.useState<Nullable<string>>(null)
  const onError = () => {
    setError('error fetching patient')
  }

  const { loading: isLoading, data } = useQuery(GET_PATIENT_WITH_ACTIVE_VISIT_BY_ID, {
    variables: {
      id: patientId,
    },
    ...rest,
    onError,
  })

  const onErrorClose = () => {
    setError(null)
  }

  if (data && data.patient) {
    const { active_visit, ...patient } = data.patient
    return {
      isLoading,
      patient: patient,
      activeVisit: active_visit,
      error,
      onErrorClose,
    }
  }

  return {
    isLoading,
    patient: null,
    activeVisit: null,
    error,
    onErrorClose,
  }
}
