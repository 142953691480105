import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { ValidateFn, ValidationError } from '../../components/Form'
import { Nullable } from '../../utils'
import { ForgotPasswordParams, useSession } from '../../components/Auth'
import { useQueryParams } from '../../components'
import { sitemap } from '../../components/Routing/sitemap'

const messages = defineMessages({
  required: {
    id: 'ForgotPassword.this_field_is_required',
    defaultMessage: 'This field is required',
  },
  limitExceededException: {
    id: 'ForgotPassword.LimitExceededException',
    defaultMessage: 'Attempt limit exceeded. Please, try again in a few minutes',
  },

  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export const useForgotPassword = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const queryParams = useQueryParams()
  const { forgotPassword } = useSession()

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'LimitExceededException':
        setError(intl.formatMessage(messages.limitExceededException))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const validate: ValidateFn<ForgotPasswordParams> = (values) => {
    const errors: ValidationError<ForgotPasswordParams> = {}

    if (!values.username) errors.username = intl.formatMessage(messages.required)

    return errors
  }

  const onSubmit = async (values: ForgotPasswordParams) => {
    setIsLoading(true)
    setError(null)
    try {
      const { CodeDeliveryDetails } = await forgotPassword(values)

      const codeDestination = CodeDeliveryDetails.Destination
      if (codeDestination) {
        queryParams.set('codeDestination', codeDestination)
      }

      const url = `${sitemap.unauth.routes.passwordRestore.absolutePath}?${queryParams.toString()}`
      navigate(url)
    } catch (e) {
      setIsLoading(false)
      translateError(e)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }
  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}
