import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { CREATE_ROOM_EVENT, DELETE_ROOM_EVENT, UPDATE_ROOM_EVENT } from '../../api/requests/scheduler'
import { EventRecurrence, HealthSystem, NewRoomEvent } from '../../api/interfaces'
import { ValidateFn, ValidationError } from '../Form'
import { useSession } from '../Auth'
import { getTimezone, isBefore } from '../DateAndTime'

export const messages = defineMessages({
  roomNotAvailable: {
    id: 'ScheduledVisitsRegistrationTab.room_not_available_during_this_period',
    defaultMessage: 'The room is not available during this period',
  },
  slotOccupiedByScheduledVisit: {
    id: 'ScheduledVisitsRegistrationTab.slot_occupied_by_scheduled_visit',
    defaultMessage: 'This slot is already occupied by a Scheduled visit',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  errorDeletingEvent: {
    id: 'ErrorViewer.error_deleting_room_event',
    defaultMessage: 'Could not delete the room event. Try again',
  },
  invalidEventId: {
    id: 'ErrorViewer.invalid_event_id',
    defaultMessage: 'Invalid event id',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  invalidDate: {
    id: 'form_validation.invalid_date',
    defaultMessage: 'Invalid value',
  },
})

export interface NewRoomEventFormValues {
  name: string
  description?: string
  recurrence: EventRecurrence
  start: string
  end: string
}

export interface CreateRoomEventVariables {
  roomId: string
  timezone: string
  event: NewRoomEvent
}

interface CreateRoomEventData {
  createRoomEvent: HealthSystem
}

export type UseCreateRoomEventParams = MutationHookOptions<CreateRoomEventData, CreateRoomEventVariables> & {
  roomId: string
}

export const useCreateRoomEvent = (params: UseCreateRoomEventParams) => {
  const { roomId, ...rest } = params

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    if (typeof params?.onError === 'function') {
      params.onError(error)
    }

    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'room_not_available_during_this_period':
        return setError(intl.formatMessage(messages.roomNotAvailable))

      case 'slot_occupied_by_scheduled_visit':
        return setError(intl.formatMessage(messages.slotOccupiedByScheduledVisit))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const validate: ValidateFn<NewRoomEventFormValues> = async (values) => {
    const errors: ValidationError<NewRoomEventFormValues> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.required)
    }

    if (!values.start) {
      errors.start = intl.formatMessage(messages.required)
    }

    if (!values.end) {
      errors.end = intl.formatMessage(messages.required)
    }

    if (!isBefore(new Date(values.start), new Date(values.end))) {
      errors.end = intl.formatMessage(messages.invalidDate)
    }
    return errors
  }

  const [createRoomEvent, { loading: isLoading }] = useMutation<CreateRoomEventData, CreateRoomEventVariables>(
    CREATE_ROOM_EVENT,
    {
      ...rest,
      onError,
    }
  )

  const onSubmit = (newEvent: NewRoomEventFormValues) => {
    setError(null)

    const { name, description, start, end, recurrence } = newEvent
    const timezone = getTimezone()

    const event = {
      name,
      description,
      recurrence,
      start,
      end,
    }

    const variables = {
      roomId,
      timezone,
      event,
    }

    createRoomEvent({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    validate,
    error,
    isLoading,
    onErrorClose,
  }
}

interface UpdateRoomEventVariables extends CreateRoomEventVariables {
  eventId: string
}
interface UpdatePricelistMutationData {
  updateRoomEvent: HealthSystem
}

export type UseUpdateRoomEventParams = MutationHookOptions<UpdatePricelistMutationData, UpdateRoomEventVariables> & {
  roomId: string
  eventId: string
}

export const useUpdateRoomEvent = (params: UseUpdateRoomEventParams) => {
  const { roomId, eventId, ...rest } = params
  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    if (typeof params?.onError === 'function') {
      params.onError(error)
    }

    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'room_not_available_during_this_period':
        return setError(intl.formatMessage(messages.roomNotAvailable))

      case 'slot_occupied_by_scheduled_visit':
        return setError(intl.formatMessage(messages.slotOccupiedByScheduledVisit))

      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const validate: ValidateFn<NewRoomEventFormValues> = async (values) => {
    const errors: ValidationError<NewRoomEventFormValues> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.required)
    }

    if (!values.start) {
      errors.start = intl.formatMessage(messages.required)
    }

    if (!values.end) {
      errors.end = intl.formatMessage(messages.required)
    }

    if (!isBefore(new Date(values.start), new Date(values.end))) {
      errors.end = intl.formatMessage(messages.invalidDate)
    }

    return errors
  }

  const [updateRoomEvent, { loading: isLoading }] = useMutation<UpdatePricelistMutationData, UpdateRoomEventVariables>(
    UPDATE_ROOM_EVENT,
    {
      ...rest,
      onError,
    }
  )

  const onSubmit = (newEvent: NewRoomEventFormValues) => {
    setError(null)

    const { name, description, start, end, recurrence } = newEvent
    const timezone = getTimezone()

    const event = {
      name,
      description,
      recurrence,
      start,
      end,
    }

    const variables = {
      roomId,
      timezone,
      eventId,
      event,
    }

    updateRoomEvent({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    validate,
    error,
    isLoading,
    onErrorClose,
  }
}

interface DeleteRoomEventVariables {
  systemId: string
  locationId: string
  roomId: string
  eventId: string
}

interface DeleteRoomEventMutationData {
  deleteRoomEvent: HealthSystem
}

export type UseDeleteRoomEventParams = MutationHookOptions<DeleteRoomEventMutationData, DeleteRoomEventVariables> & {
  roomId: string
  eventId: string
}

export const useDeleteRoomEvent = (params: UseDeleteRoomEventParams) => {
  const { roomId, eventId, ...rest } = params

  // this is constant in domestic
  const { getUserLocation, getUserSystem } = useSession()
  const { id: locationId } = getUserLocation()
  const { id: systemId } = getUserSystem()

  const intl = useIntl()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'invalid_event_id':
        return setError(intl.formatMessage(messages.invalidEventId))

      case 'error_deleting_room_event':
        return setError(intl.formatMessage(messages.errorDeletingEvent))

      case 'invalid_signature':
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [deleteRoomEvent, { loading: isLoading }] = useMutation<DeleteRoomEventMutationData, DeleteRoomEventVariables>(
    DELETE_ROOM_EVENT,
    {
      ...rest,
      onError,
    }
  )

  const onDelete = () => {
    setError(null)

    const variables = {
      systemId,
      locationId,
      roomId,
      eventId,
    }

    deleteRoomEvent({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    error,
    isLoading,
    onErrorClose,
  }
}
