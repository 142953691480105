import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { START_SCHEDULED_VISIT } from '../../api/requests/scheduler'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import { sitemap } from '../Routing'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonProps } from '@chakra-ui/react'
import { useCalendarContext } from './useCalendarContext'

const messages = defineMessages({
  startVisit: {
    id: 'EventViewer.start_visit',
    defaultMessage: 'Start visit',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface StartVisitVariables {
  id: string
}

interface StartVisitData {
  startScheduledVisit: FullPatientWithActiveVisit
}

export type UseStartVisitParams = MutationHookOptions<StartVisitData, StartVisitVariables>

export const useStartVisit = (params?: UseStartVisitParams) => {
  const intl = useIntl()
  const { selectedEvent } = useCalendarContext()

  const navigate = useNavigate()
  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    if (typeof params?.onError === 'function') {
      params.onError(error)
    }

    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onCompleted = (data: StartVisitData) => {
    const patient = data.startScheduledVisit
    const chartUrl = sitemap.activeVisit.routes.exam.pathBuilder(patient.id)

    navigate(chartUrl)
  }

  const [startVisit, { loading: isLoading }] = useMutation<StartVisitData, StartVisitVariables>(START_SCHEDULED_VISIT, {
    ...params,
    onCompleted,
    onError,
  })

  const onSubmit = () => {
    setError(null)
    if (!selectedEvent) {
      return null
    }

    const variables = {
      id: selectedEvent,
    }

    startVisit({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    error,
    isLoading,
    onErrorClose,
  }
}

export const StartVisitButton: React.FC<ButtonProps> = (props) => {
  const intl = useIntl()

  const { onSubmit, isLoading } = useStartVisit()
  return (
    <Button {...props} colorScheme="green" isLoading={isLoading} onClick={onSubmit}>
      {intl.formatMessage(messages.startVisit)}
    </Button>
  )
}
