import React from 'react'
import { Box, Heading, } from '@chakra-ui/react'
import { useIntl, defineMessages } from 'react-intl'
import { BasePatientForm, BasePatientFormValues, usePatientUpdate } from '.'
import { BasePatient } from '../../api/interfaces'

const messages = defineMessages({
  personalInfoTitle: {
    id: 'PersonalInfoCard.personal_info_title',
    defaultMessage: 'Personal details'
  },
  updateButton: {
    id: 'UI.button_update',
    defaultMessage: 'Update'
  },
})

interface BasePatientEditorProps {
  patient: BasePatient
}

export const BasePatientEditor: React.FC<BasePatientEditorProps> = props => {
  const { patient } = props

  const intl = useIntl()

  const initialValues = patientToInitialFormValues(patient)
  const { onSubmit, validate, error, isLoading, onErrorClose } = usePatientUpdate({ id: patient.id })

  return <Box p={4} borderWidth="1px" my={4} rounded={'lg'}>
    <Heading size="md" >
      {intl.formatMessage(messages.personalInfoTitle)}
    </Heading>
    <BasePatientForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      action={intl.formatMessage(messages.updateButton)}
      error={error}
      onErrorClose={onErrorClose}
      isLoading={isLoading}
    />
  </Box>
}


const patientToInitialFormValues = (patient: BasePatient): BasePatientFormValues => {
  const { first_name, last_name, birthdate, sex, address, email, phone_number } = patient

  const {
    line_1,
    line_2,
    line_3,
    locality,
    postal_code,
    country
  } = address || {}

  const oneFieldAddress = [
    line_1,
    line_2,
    line_3,
  ].join('\n')

  const initialValues: BasePatientFormValues = {
    first_name,
    last_name,
    birthdate: new Date(birthdate),
    sex,
    locality: locality ?? undefined,
    postal_code: postal_code ?? undefined,
    email: email ?? undefined,
    phone_number: phone_number ?? undefined,
    country: country ?? undefined,
    address: oneFieldAddress
  }

  return initialValues
}