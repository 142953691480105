import { useQuery } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { isAValidVital } from 'wd-common/src/logic/triage/validations'
import {
  FullHistory,
  FullHistoryItem,
  Measurement,
  VitalsHistory,
  VitalType,
} from '../../api/interfaces'
import { GET_VISIT_HISTORY } from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface Data {
  patientHistory: FullHistory
}

interface Variables {
  patientId: string
}

export interface PatientHistoryContextValue {
  patientHistory: Nullable<FullHistory>
  getLastVital: (measurementType: VitalType) => Nullable<Measurement>
  getAllergies: () => Nullable<Array<FullHistoryItem>>
  isLoading: boolean
  error: Nullable<string>
  onErrorClose?: () => void
  refetch: () => void
}

interface PatientHistoryProviderProps {
  patientId: string
}
export const PatientHistoryContext = React.createContext<Nullable<PatientHistoryContextValue>>(null)
export const PatientHistoryProvider: React.FC<PatientHistoryProviderProps> = (props) => {
  const { patientId } = props

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (errorCode) => {
    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const onCompleted = () => {
    setError(null)
  }

  const { loading, data, refetch } = useQuery<Data, Variables>(GET_VISIT_HISTORY, {
    variables: { patientId },
    onCompleted,
    onError,
    skip: !patientId,
    fetchPolicy: 'cache-first',
  })

  const getLastVital = (measurementType) => {
    if (!isAValidVital(measurementType)) return null

    if (!data || !Array.isArray(data?.patientHistory?.vitals)) return null

    const allMeasurements = (data.patientHistory.vitals as Array<VitalsHistory>).reduce(
      (measurements, current) => {
        return [...measurements, ...(current.vitals?.measurements ?? [])]
      },
      [] as Array<Measurement>
    )

    return allMeasurements.find((measurement) => measurement.type === measurementType) ?? null
  }

  const getAllergies = () => {
    return data?.patientHistory?.allergies ?? null
  }

  const onErrorClose = () => {
    setError(null)
  }

  const value: PatientHistoryContextValue = {
    patientHistory: data?.patientHistory ?? null,
    getLastVital,
    getAllergies,
    error,
    onErrorClose,
    isLoading: !!loading,
    refetch,
  }

  return (
    <PatientHistoryContext.Provider value={value}>{props.children}</PatientHistoryContext.Provider>
  )
}
