import React from 'react'
import Select from 'react-select'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SelectorProps,
  useChakraThemeParser,
} from '.'
import { Nullable } from '../../utils'
import { CustomFieldType, NonPrimitiveCustomFieldType, PrimitiveCustomFieldType } from '../../api/interfaces'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'


const customFieldTypeTranslations: Record<CustomFieldType, MessageDescriptor> = {
  [PrimitiveCustomFieldType.BOOLEAN]: {
    id: 'CustomFieldTypeSelector.boolean',
    defaultMessage: 'Yes/No'
  },
  [PrimitiveCustomFieldType.TEXT]: {
    id: 'CustomFieldTypeSelector.text_type',
    defaultMessage: 'Text'
  },
  [PrimitiveCustomFieldType.NUMBER]: {
    id: 'CustomFieldTypeSelector.number_type',
    defaultMessage: 'Number'
  },
  [PrimitiveCustomFieldType.DATE]: {
    id: 'CustomFieldTypeSelector.date',
    defaultMessage: 'Date'
  },
  [NonPrimitiveCustomFieldType.TEXT_LIST]: {
    id: 'CustomFieldTypeSelector.text_list_label',
    defaultMessage: 'Option list'
  }
}

export const customFieldTypeMessages = defineMessages(customFieldTypeTranslations)

const customFieldTypes = [
  ...Object.values(PrimitiveCustomFieldType),
  ...Object.values(NonPrimitiveCustomFieldType),
]

const isValidCustomFieldType = (type: string): boolean => {
  return (customFieldTypes as Array<string>).includes(type)
}

export type CustomFieldTypeSelectorProps = SelectorProps<CustomFieldType>


export const CustomFieldTypeSelector: React.FC<CustomFieldTypeSelectorProps> = props => {
  const {
    value,
  } = props

  const theme = useChakraThemeParser()
  const intl = useIntl()

  const formatOption = (type: CustomFieldType): ReactSelectInternalSingleValue => {

    return {
      value: type,
      label: intl.formatMessage(customFieldTypeMessages[type])
    }
  }

  const options = customFieldTypes
    .map(formatOption)

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return null
      }

      const formattedValues = value
        .reduce((prev: Array<ReactSelectInternalSingleValue>, value: string) => {
          if (isValidCustomFieldType(value)) {
            return [
              ...prev,
              formatOption(value as CustomFieldType)
            ]
          }

          return prev
        }, [])

      return formattedValues
    } else {
      if (isValidCustomFieldType(value as string)) {
        return formatOption((value as CustomFieldType))
      }

      return null
    }
  }

  const _onChange = value => {
    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return props.onChange([])
      }

      const selectedValues = value
      const newValues = selectedValues.map(item => item.value)

      props.onChange(newValues as Array<CustomFieldType>)
    } else {
      const selectedValue = value

      props.onChange(selectedValue.value as CustomFieldType)
    }
  }

  return <Select
    options={options}
    value={parseValue(value)}
    onChange={_onChange}
    isLoading={props.isLoading}
    isMulti={props.isMulti}
    isClearable={props.isClearable}
    isDisabled={props.isDisabled}
    isSearchable={props.isSearchable}
    aria-invalid={props.isInvalid}
    placeholder={props.placeholder}
    blurInputOnSelect={props.blurInputOnSelect}
    theme={theme}
  />
}



