import React from 'react'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'
import {
  isValidVisitType,
} from '.'
import { Text, TextProps } from '@chakra-ui/react'
import { VisitType } from '../../api/interfaces'


/**
 * Record will force us to add the translation
 * every time we updated the visit type list
 */
export const visitTypeTranslations: Record<VisitType, MessageDescriptor> = {
  [VisitType.SICK_VISIT]: {
    id: 'VisitType.Sick Visit',
    defaultMessage: 'Sick Visit'
  },
  [VisitType.FOLLOW_UP]: {
    id: 'VisitType.Follow-up',
    defaultMessage: 'Follow-up'
  },
  [VisitType.CHIILD_HEALTH]: {
    id: 'VisitType.Child Health',
    defaultMessage: 'Child Health'
  },
  [VisitType.ANTENATAL_CARE]: {
    id: 'VisitType.Antenatal Care',
    defaultMessage: 'Antenatal Care'
  },
  [VisitType.DELIVERY]: {
    id: 'VisitType.Delivery',
    defaultMessage: 'Delivery'
  },
  [VisitType.FAMILY_PLANNING]: {
    id: 'VisitType.Family Planning',
    defaultMessage: 'Family Planning'
  },
  [VisitType.PHYSICAL_EXAM]: {
    id: 'VisitType.Physical Exam',
    defaultMessage: 'Physical Exam'
  },
  [VisitType.INMUNIZATION]: {
    id: 'VisitType.Immunization',
    defaultMessage: 'Immunization'
  },
  [VisitType.DENTAL]: {
    id: 'VisitType.Dental',
    defaultMessage: 'Dental'
  },
  [VisitType.TRANSFER]: {
    id: 'VisitType.Transfer',
    defaultMessage: 'Transfer'
  },
  [VisitType.OTHER]: {
    id: 'VisitType.Other',
    defaultMessage: 'Other'
  },
  [VisitType.MIDWIFE]: {
    id: 'VisitType.Midwife',
    defaultMessage: 'Midwife'
  },
  [VisitType.GENERAL_PRACTITIONER]: {
    id: 'VisitType.General Practitioner',
    defaultMessage: 'General Practitioner'
  },
  [VisitType.MEDICAL_CLEARANCE]: {
    id: 'VisitType.Medical Clearance',
    defaultMessage: 'Medical Clearance'
  },
  [VisitType.OBGYN]: {
    id: 'VisitType.OBGYN',
    defaultMessage: 'OB/GYN'
  },
  [VisitType.PEDIATRICIAN]: {
    id: 'VisitType.Pediatrician',
    defaultMessage: 'Pediatrician'
  },
  [VisitType.INTERNIST]: {
    id: 'VisitType.Internist',
    defaultMessage: 'Internist'
  },
  [VisitType.TESTING]: {
    id: 'VisitType.Testing',
    defaultMessage: 'Testing'
  },
  [VisitType.LACTATION_COUNSELING]: {
    id: 'VisitType.Lactation Counseling',
    defaultMessage: 'Lactation Counseling'
  }
}

/**
 * See the IntlDefineMessage definition
 */
const translationMessages = defineMessages(visitTypeTranslations)

type VisitTypeTranslationProps = TextProps & {
  type: VisitType
}
export const VisitTypeTranslation: React.FC<VisitTypeTranslationProps> = props => {
  const { type, ...textProps } = props

  const intl = useIntl()

  // this should never happen but we cannot be sure because
  // the database contains old data
  if (!isValidVisitType(type)) {
    <Text {...textProps}>
      {type}
    </Text>

  }
  return <Text {...textProps}>
    {intl.formatMessage(translationMessages[type])}
  </Text>
}

