import { Nullable } from '../../utils'

export interface Signature {
  given_name: Nullable<string>
  family_name: Nullable<string>
  user_id: Nullable<string>
  timestamp: Nullable<string>
}

export enum Sex {
  FEMALE = 'Female',
  MALE = 'Male',
}

export type PaginatedResult<T> = {
  docs: Array<T>
  count: number
}

export interface PaginatedVariables {
  q?: string
  offset?: number
  limit?: number
}

export interface VoidResponse {
  done: Nullable<boolean>
  updated: Nullable<Signature>
}

export interface TranslatableText {
  type: string
  text: Nullable<string>
}

export interface FullReference {
  name: Nullable<TranslatableText>
  link: Nullable<string>
  image: Nullable<string>
}

export interface FullComment {
  id: string
  type: string
  text: string
  created: Signature
}
