import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { COMMON_ROUTE_TYPES } from 'wd-common/src/logic/pharmacy/definitions'
import { isACommonRouteType } from 'wd-common/src/logic/pharmacy/validations'
import { Text, TextProps } from '@chakra-ui/react'
import { TranslatableText } from '../../api/interfaces'

const messages = defineMessages({
  po: {
    id: 'DrugRoute.route_po_label',
    defaultMessage: 'PO'
  },
  im: {
    id: 'DrugRoute.route_im_label',
    defaultMessage: 'IM'
  },
  iv: {
    id: 'DrugRoute.route_iv_label',
    defaultMessage: 'IV'
  }
})

type DrugRouteProps = TextProps & {
  route: TranslatableText
}

export const DrugRoute: React.FC<DrugRouteProps> = props => {
  const { route: routeProp, ...textProps } = props
  const route = routeProp?.text ?? ''

  const intl = useIntl()
  const getTranslation = _route => {
    const route = _route.toLowerCase()

    switch (route) {
      case COMMON_ROUTE_TYPES.PO:
        return intl.formatMessage(messages.po)

      case COMMON_ROUTE_TYPES.IM:
        return intl.formatMessage(messages.im)

      case COMMON_ROUTE_TYPES.IV:
        return intl.formatMessage(messages.iv)

      default:
        return ''
    }
  }

  const text = isACommonRouteType(route)
    ? getTranslation(route)
    : route

  return <Text {...textProps}>
    {text}
  </Text >
}