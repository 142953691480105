import React from 'react'
import Select from 'react-select'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SelectorProps,
  useChakraThemeParser,
} from '.'
import { Nullable } from '../../utils'
import { CustomField, CustomFieldType } from '../../api/interfaces'
import { useSystems } from '../Systems'

export type CustomFieldSelectorProps = SelectorProps<string> & {
  filter?: (value: CustomField) => boolean
}
export const CustomFieldSelector: React.FC<CustomFieldSelectorProps> = props => {
  const {
    value,
    filter
  } = props

  const theme = useChakraThemeParser()
  const { getMySystem } = useSystems()
  const { customFields } = getMySystem()

  const formatOption = (field: CustomField): ReactSelectInternalSingleValue => {

    return {
      value: field.id,
      label: field.name
    }
  }

  const options = typeof filter === 'function'
    ? (customFields ?? [])
      .filter(filter)
      .map(formatOption)
    : (customFields ?? [])
      .map(formatOption)


  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return null
      }

      const formattedValues = value
        .reduce((prev: Array<ReactSelectInternalSingleValue>, value: string) => {
          const field = (customFields ?? []).find(customField => value === customField.id)

          if (field) {
            return [
              ...prev,
              formatOption(field as CustomField)
            ]
          }

          return prev
        }, [])

      return formattedValues
    } else {
      const field = (customFields ?? []).find(customField => value === customField.id)

      if (field) {
        return formatOption((field as CustomField))
      }

      return null
    }
  }

  const _onChange = value => {
    if (props.isMulti) {
      if (!Array.isArray(value)) {
        return props.onChange([])
      }

      const selectedValues = value
      const newValues = selectedValues.map(item => item.value)

      props.onChange(newValues as Array<CustomFieldType>)
    } else {
      const selectedValue = value

      props.onChange(selectedValue.value as CustomFieldType)
    }
  }

  return <Select
    options={options}
    value={parseValue(value)}
    onChange={_onChange}
    isLoading={props.isLoading}
    isMulti={props.isMulti}
    isClearable={props.isClearable}
    isDisabled={props.isDisabled}
    isSearchable={props.isSearchable}
    aria-invalid={props.isInvalid}
    placeholder={props.placeholder}
    blurInputOnSelect={props.blurInputOnSelect}
    theme={theme}
  />
}



