import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import {
  getNormalVitalsByAge,
  isInRange,
} from 'wd-common/src/logic/triage/utils'
import { Nullable } from '../../utils'
import { O2Saturation as O2SaturationType } from '../../api/interfaces'
import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagProps,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { TextField, ValidateFn } from '../Form'
import { PatientAgeCategory } from '../Patients'
import { NewO2Saturation, useCreateO2Saturation, useUpdateO2Saturation } from '.'
import { ConfirmButton, ErrorViewer } from '..'
import { useRemoveMeasurement } from './useRemoveMeasurement'
import {
  RiAddLine as AddIcon,
  RiDeleteBin2Line as DeleteIcon,
  RiAlertLine as WarningIcon
} from 'react-icons/ri'

const messages = defineMessages({
  title: {
    id: 'Vitals.o2_saturation_title',
    defaultMessage: 'O2 Saturation'
  },
  shortTitle: {
    id: 'Vitals.o2_saturation_abbreviation',
    defaultMessage: '02'
  },
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?'
  },
  percent: {
    id: 'Vitals.percent_label',
    defaultMessage: 'percent'
  },
  reference: {
    id: 'Vitals.reference_title',
    defaultMessage: 'Reference'
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  submitButton: {
    id: 'NonStaffUI.button_submit',
    defaultMessage: 'Submit',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
})

type O2SaturationProps = ButtonGroupProps & {
  patientId: string
  visitId: string
  birthdate: string
  value: Nullable<O2SaturationType>
  isDisabled?: boolean
}

export const O2Saturation: React.FC<O2SaturationProps> = (props) => {
  const { value, patientId, visitId, birthdate, isDisabled, ...buttonGroupProps } = props

  if (value) {
    return (
      <Editor
        value={value}
        patientId={patientId}
        visitId={visitId}
        birthdate={birthdate}
        isDisabled={isDisabled}
        {...buttonGroupProps}
      />
    )
  }

  return (
    <Creator
      patientId={patientId}
      visitId={visitId}
      birthdate={birthdate}
      isDisabled={isDisabled}
      {...buttonGroupProps}
    />
  )
}

type CreatorProps = ButtonGroupProps & {
  patientId: string
  visitId: string
  birthdate: string
  isDisabled?: boolean
}

const Creator: React.FC<CreatorProps> = (props) => {
  const { patientId, visitId, birthdate, isDisabled, ...buttonGroupProps } = props

  const intl = useIntl()

  const { onToggle, isOpen } = useDisclosure()

  const params = {
    patientId,
    visitId,
  }
  const { onCreate, onErrorClose, error, isLoading, validate } = useCreateO2Saturation(params)

  return (
    <>
      <ButtonGroup size="sm" isAttached isDisabled={isDisabled} {...buttonGroupProps}>
        <Button mr="-px" onClick={onToggle}>
          <Icon as={AddIcon} />
          <Text ml={2}>{intl.formatMessage(messages.shortTitle)}</Text>
        </Button>
      </ButtonGroup>

      <O2SaturationModal
        o2Saturation={null}
        isOpen={isOpen}
        onToggle={onToggle}
        birthdate={birthdate}
        onSubmit={onCreate}
        onErrorClose={onErrorClose}
        error={error}
        isLoading={isLoading}
        validate={validate}
      />

    </>
  )
}

type EditorProps = ButtonGroupProps & {
  patientId: string
  visitId: string
  value: Nullable<O2SaturationType>
  birthdate: string
  isDisabled?: boolean
}

const Editor: React.FC<EditorProps> = (props) => {
  const { patientId, visitId, birthdate, isDisabled, value, ...buttonGroupProps } = props

  const intl = useIntl()

  const { onToggle, isOpen } = useDisclosure()

  const isLegacy = !value?.id
  const removeParams = {
    patientId,
    visitId,
  }

  const { onDelete, isLoading, error, onErrorClose } = useRemoveMeasurement(removeParams)

  const updateParams = {
    ...removeParams,
    onCompleted: onToggle,
  }

  const {
    onUpdate,
    onErrorClose: onUpdatingErrorClose,
    error: updatingError,
    isLoading: isUpdating,
    validate,
  } = useUpdateO2Saturation(updateParams)

  const onSubmit = (values: NewO2Saturation) => {
    if (isLegacy) {
      return null
    }

    const measurementId = value.id
    onUpdate(measurementId, values)
  }

  return (
    <>
      <ButtonGroup size="sm" isAttached isDisabled={isDisabled} {...buttonGroupProps}>
        <Button mr="-px" onClick={onToggle}>
          <ValueWithWarning o2Saturation={value} birthdate={birthdate} />
        </Button>
        {!isLegacy && (
          <ConfirmButton
            onConfirm={() => onDelete(value.id)}
            message={intl.formatMessage(messages.confirmationMessage)}
            buttonProps={{
              color: 'red.400',
              label: <Icon as={DeleteIcon} />,
              isDisabled,
              isLoading,
            }}
            error={error}
            onErrorClose={onErrorClose}
          />
        )}
      </ButtonGroup>

      <O2SaturationModal
        o2Saturation={value}
        isOpen={isOpen}
        onToggle={onToggle}
        birthdate={birthdate}
        onSubmit={onSubmit}
        onErrorClose={onUpdatingErrorClose}
        error={updatingError}
        isLoading={isUpdating}
        validate={validate}
      />

    </>
  )
}

type O2SaturationModalProps = {
  birthdate: string
  o2Saturation: Nullable<O2SaturationType>
  isOpen: boolean
  onToggle: () => void
  onSubmit: (values: NewO2Saturation) => void
  validate: ValidateFn<NewO2Saturation>
  error: Nullable<string>
  onErrorClose: () => void
  isLoading: boolean
  isDisabled?: boolean
}

const O2SaturationModal: React.FC<O2SaturationModalProps> = (props) => {
  const { validate, onSubmit, error, onErrorClose, isLoading, o2Saturation, birthdate, isDisabled, isOpen, onToggle } = props

  const intl = useIntl()

  const _onSubmit = (values) => {
    const newO2Saturation: NewO2Saturation = {
      percent: parseFloat(values?.percent),
    }

    onSubmit(newO2Saturation)
  }

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <Form
        onSubmit={_onSubmit}
        validate={validate}
        initialValues={o2Saturation}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <ModalHeader>{intl.formatMessage(messages.title)}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TextField
                  name="percent"
                  label={intl.formatMessage(messages.percent)}
                  type={'number'}
                  step={0.1}
                  autoFocus
                />

                <VitalReference birthdate={birthdate} o2Saturation={o2Saturation} />
                {error && <ErrorViewer title={error} onClose={onErrorClose} />}
              </ModalBody>

              <ModalFooter>
                <Button isFullWidth={isFullWidth} onClick={onToggle}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  colorScheme={'brand'}
                  isLoading={isLoading}
                  type="submit"
                  isDisabled={isDisabled}
                  isFullWidth={isFullWidth}
                  ml={2}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        )}
      />
    </Modal>
  )
}

const checkWarnings = (patient, o2) => {
  const reference = getNormalVitalsByAge(patient)

  const o2Reference = (reference || {}).o2
  const inRange = !o2Reference || isInRange((o2Reference || {}).min, (o2Reference || {}).max, o2)

  return {
    inRange,
    reference: o2Reference
  }
}

type ValueWithWarningProps = {
  o2Saturation: Nullable<O2SaturationType>
  birthdate: string
}

const ValueWithWarning: React.FC<ValueWithWarningProps> = (props) => {
  const { o2Saturation, birthdate } = props

  const intl = useIntl()

  const { inRange } = checkWarnings({ birthdate }, o2Saturation?.percent)

  return (
    <HStack alignItems={'center'}>
      {!inRange && <Icon as={WarningIcon} color="red.400" />}
      <Text mx={1}>{intl.formatMessage(messages.shortTitle)}:</Text>
      <Text fontWeight={'bold'} color={!inRange ? 'red.400' : undefined}>
        {o2Saturation?.percent ?? '--'}
        {"%"}
      </Text>
    </HStack>
  )
}

type VitalReferenceProps = {
  o2Saturation: Nullable<O2SaturationType>
  birthdate: string
}

const VitalReference: React.FC<VitalReferenceProps> = (props) => {
  const { o2Saturation, birthdate } = props

  const intl = useIntl()

  const { inRange, reference } = checkWarnings({ birthdate }, o2Saturation?.percent)

  if (!reference) {
    return null
  }

  return (
    <Box p={4} borderWidth={1} rounded={'lg'}>
      <PatientAgeCategory birthdate={birthdate} mb={1} fontWeight={'bold'} />
      <HStack>
        <Text>{intl.formatMessage(messages.reference)}</Text>
        <Text fontWeight={'bold'} ml={2}>
          {`${reference.min} - ${reference.max} %`}
        </Text>
        {!inRange && <Icon as={WarningIcon} ml={2} color="red.400" />}
      </HStack>
    </Box>
  )
}


type PrintableO2SaturationProps = TagProps & {
  birthdate: string
  value: Nullable<O2SaturationType>
}

export const PrintableO2Saturation: React.FC<PrintableO2SaturationProps> = (props) => {
  const { value, birthdate, ...tagProps } = props

  return <Tag {...tagProps}>
    <ValueWithWarning
      o2Saturation={value}
      birthdate={birthdate}
    />
  </Tag>
}