import React from 'react'
import {
  Box,
  BoxProps,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
  Heading,
  Divider,
  StackProps,
  Stack,
  SkeletonText,
  Skeleton,
  Progress,
} from '@chakra-ui/react'
import { DateTime } from 'luxon'
import { FullUserSubscription, SubscriptionStatus as SubscriptionStatusEnum, UserPaymentMethod } from '../../api/interfaces'
import { ErrorViewer, useQueryParams } from '..'
import { CancelSubscriptionButton, useSubscription, PaymentMethodCheckoutButton, UpdatePaymentMethodResult } from '.'
import { defineMessages, useIntl } from 'react-intl'
import { SubscriptionStatus } from '../Staff'
import { useActivationCheckout } from '../Setup'
import { Nullable } from '../../utils'

const messages = defineMessages({
  title: {
    id: 'SubscriptionSettings.title',
    defaultMessage: 'Subscription',
  },
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close',
  },
  manageButton: {
    id: 'UI.manage_button',
    defaultMessage: 'Manage',
  },
  updateButtton: {
    id: 'UI.update_button',
    defaultMessage: 'Update',
  },
  subscribeButton: {
    id: 'UI.subscribe_button',
    defaultMessage: 'Subscribe',
  },
  subscriptionLabel: {
    id: 'UI.subscription_label',
    defaultMessage: 'Subscription',
  },
  noSubscription: {
    id: 'SubscriptionSettings.you_dont_have_a_subscription',
    defaultMessage: "You don't have an active subscription",
  },
  nextPaymentMessage: {
    id: 'SubscriptionSettings.next_payment_information',
    defaultMessage: 'Next payment: ${amount} on {date}',
  },
  subscriberSince: {
    id: 'SubscriptionSettings.subscriber_since',
    defaultMessage: 'Subscriber since {date}',
  },
  plan: {
    id: 'SubscriptionSettings.plan_label',
    defaultMessage: 'Plan',
  },
  payment: {
    id: 'SubscriptionSettings.payment_label',
    defaultMessage: 'Payment',
  },
  trial: {
    id: 'SubscriptionSettings.trial_label',
    defaultMessage: 'Trial',
  },
  daysLeft: {
    id: 'SubscriptionSettings.days_left',
    defaultMessage: '{days} day/s left',
  },
  regular: {
    id: 'SubscriptionSettings.regular_plan_label',
    defaultMessage: 'Regular',
  },

})

export const Subscription: React.FC<BoxProps> = (props) => {
  const { ...boxProps } = props
  const { isLoading, subscription, error } = useSubscription()

  if (isLoading) {
    return (
      <HStack py={4} px={2} justifyContent="space-between">
        <SkeletonText noOfLines={2} spacing="4" w={200} />
        <Skeleton height="24px" borderRadius={'md'} w={100} />
      </HStack>
    )
  }

  if (error) {
    return <ErrorViewer title={error} />
  }

  if (!subscription) {
    return <ReActivation />
  }

  return <SubscriptionBox subscription={subscription} {...boxProps} />

}

interface Props extends BoxProps {
  subscription: FullUserSubscription
}

export const SubscriptionBox: React.FC<Props> = (props) => {
  const { subscription, ...boxProps } = props
  const intl = useIntl()

  const defaultIsOpen = useSubscriptionModalInitialState()
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen })

  const endDate = DateTime.fromMillis(Number(subscription.currentPeriodEnd) * 1000)
  const date = endDate.isValid ? endDate.toLocaleString(DateTime.DATE_MED) : '-'

  return (
    <Box {...boxProps} p={2}>
      <HStack flex={1} justifyContent={'space-between'}>
        <VStack spacing={0} alignItems="start">
          <HStack spacing={2}>
            <Heading fontSize={'md'}>{intl.formatMessage(messages.subscriptionLabel)}</Heading>
            <SubscriptionStatus status={subscription.status} />
          </HStack>
          <Text fontSize={'sm'} color="gray">
            {intl.formatMessage(messages.nextPaymentMessage, {
              amount: subscription.price,
              currency: subscription.currency,
              date,
            })}
          </Text>
        </VStack>
        <Button onClick={onOpen} variant="ghost" colorScheme="brand">
          {intl.formatMessage(messages.manageButton)}
        </Button>
      </HStack>
      <SubscriptionModal subscription={subscription} onClose={onClose} isOpen={isOpen} />
    </Box>
  )
}

const useSubscriptionModalInitialState = () => {
  const queryParams = useQueryParams()
  const paymentMethodUpdated = queryParams.get('paymentMethodUpdated')

  return paymentMethodUpdated === 'true' || paymentMethodUpdated === 'false'
}


interface ModalProps extends Props {
  onClose: () => void
  isOpen: boolean
}

const SubscriptionModal: React.FC<ModalProps> = (props) => {
  const { subscription, isOpen, onClose } = props

  const intl = useIntl()

  const createdDate = DateTime.fromMillis(Number(subscription.created) * 1000)
  const subscribedDate = createdDate.isValid ? createdDate.toLocaleString(DateTime.DATE_MED) : '-'

  const endDate = DateTime.fromMillis(Number(subscription.currentPeriodEnd) * 1000)
  const nextPaymentDate = endDate.isValid ? endDate.toLocaleString(DateTime.DATE_MED) : '-'

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {intl.formatMessage(messages.title)}
          <SubscriptionStatus status={subscription.status} ml={2} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          {
            SubscriptionStatusEnum.TRIALING === subscription.status && <>
              <TrialIndicator trialStart={subscription.trialStart} trialEnd={subscription.trialEnd} />
              <Divider my={6} />
            </>
          }


          <Box alignItems="flex-start" my={2}>
            <Text fontSize={'lg'} fontWeight={'bold'}>
              {intl.formatMessage(messages.plan)}
            </Text>

            <HStack alignItems="end" justifyContent={'space-between'}>
              <VStack spacing={0} alignItems='start'>
                <Text>{subscription?.nickname ?? intl.formatMessage(messages.regular)}</Text>
                <Text color='gray' fontSize={'sm'}>{intl.formatMessage(messages.subscriberSince, { date: subscribedDate })}</Text>
              </VStack>

              <HStack alignItems="baseline" spacing={2}>
                <Heading size={'md'}>{`$${subscription.price}`}</Heading>
                <Text>{`/${subscription.interval}`}</Text>
              </HStack>
            </HStack>
          </Box>

          <Divider my={6} />


          <Box alignItems="flex-start" my={2}>
            <Text fontSize={'lg'} fontWeight={'bold'}>
              {intl.formatMessage(messages.payment)}
            </Text>

            <HStack alignItems="end" justifyContent={'space-between'}>
              <VStack alignItems="flex-start" spacing={0}>
                <PaymentMethod method={subscription.paymentMethod} />
                <Text color='gray' fontSize={'sm'}>
                  {intl.formatMessage(messages.nextPaymentMessage, {
                    amount: subscription.price,
                    date: nextPaymentDate,
                  })}
                </Text>

              </VStack>
              <PaymentMethodCheckoutButton />
            </HStack>
          </Box>
          <Divider mt={6} />
          <UpdatePaymentMethodResult />
        </ModalBody>

        <ModalFooter>
          <Stack spacing={3} isInline={!isFullWidth} flex={1} justifyContent={'end'}>
            <Button onClick={onClose} isFullWidth={isFullWidth}>
              {intl.formatMessage(messages.closeButton)}
            </Button>
            <CancelSubscriptionButton isFullWidth={isFullWidth} />
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}


export const ReActivation: React.FC<StackProps> = (props) => {
  const { isLoading, checkout, error } = useActivationCheckout()

  const intl = useIntl()

  return (
    <>
      <HStack justifyContent="space-between" p={2} {...props}>
        <VStack spacing={0} alignItems="flex-start">
          <Heading fontSize={'md'}>{intl.formatMessage(messages.subscriptionLabel)}</Heading>
          <Text color={'red.400'}>{intl.formatMessage(messages.noSubscription)}</Text>
        </VStack>
        <Button isLoading={isLoading} variant={'ghost'} onClick={checkout} colorScheme={'brand'}>
          {intl.formatMessage(messages.subscribeButton)}
        </Button>
      </HStack>
      {error && <ErrorViewer title={error} />}
    </>
  )
}

interface TrialIndicatorProps extends BoxProps {
  trialStart: Nullable<string>
  trialEnd: Nullable<string>
}

export const TrialIndicator: React.FC<TrialIndicatorProps> = (props) => {
  const { trialStart, trialEnd, ...boxProps } = props

  const intl = useIntl()

  const startDate = DateTime.fromJSDate(new Date(Number(trialStart) * 1000))
  const endDate = DateTime.fromJSDate(new Date(Number(trialEnd) * 1000))

  if (!startDate.isValid || !endDate.isValid) {
    return null
  }

  const now = DateTime.now()

  const range = endDate.diff(startDate).as('days')
  const progress = now.diff(startDate).as('days')

  const pending = Math.ceil(range - progress)
  return (
    <Box alignItems="flex-start" my={2} {...boxProps}>
      <Text fontSize={'lg'} fontWeight={'bold'}>
        {intl.formatMessage(messages.trial)}
      </Text>
      <Text my={1}>{intl.formatMessage(messages.daysLeft, { days: pending })}</Text>
      <Progress value={progress} max={range} min={0} size="xs" colorScheme="purple" />
    </Box>
  )
}

interface PaymentMethodProps {
  method: UserPaymentMethod
}

export const PaymentMethod: React.FC<PaymentMethodProps> = (props) => {
  const { method } = props

  const { type, ...cardDetails } = method

  if (type === 'card') {
    return <Card {...cardDetails} />
  }

  return (
    <Text fontWeight={'bold'} textTransform='uppercase'>
      {type}
    </Text>
  )
}

type CardProps = Omit<UserPaymentMethod, 'type'>

export const Card: React.FC<CardProps> = (props) => {
  const { expMonth, expYear, brand, last4 } = props

  const expDate = expMonth && expYear
    ? `${expMonth}/${expYear}`
    : null

  const brandAndNumber = `${brand} **** ${last4}`
  return (
    <Box alignItems="flex-start" >
      <HStack alignItems='start' justifyContent={'space-between'}>
        <Text textTransform='uppercase'>
          {brandAndNumber}
        </Text>
        <Text>{expDate}</Text>
      </HStack>
    </Box>
  )
}

