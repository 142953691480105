import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { RiAddLine as AddIcon } from 'react-icons/ri'
import { Button, ButtonProps, Icon, Text, useDisclosure } from '@chakra-ui/react'
import { ProcedureOrderModal } from '../Procedures'

const messages = defineMessages({
  addProcedureButton: {
    id: 'UI.add_procedure_button',
    defaultMessage: 'Intervention',
  },
})

type AddProcedureButtonProps = ButtonProps & {
  patientId: string
  visitId: string
  isDisabled?: boolean
  preventModalFromClosing?: boolean
}

export const AddProcedureButton: React.FC<AddProcedureButtonProps> = (props) => {
  const { patientId, visitId, isDisabled, preventModalFromClosing, ...buttonProps } = props

  const intl = useIntl()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        onClick={onOpen}
        disabled={isDisabled || isOpen}
        colorScheme="brand"
        size="sm"
        {...buttonProps}
      >
        <Icon as={AddIcon} />
        <Text ml={1}>{intl.formatMessage(messages.addProcedureButton)}</Text>
      </Button>
      {isOpen && (
        <ProcedureOrderModal
          patientId={patientId}
          visitId={visitId}
          isOpen={isOpen}
          preventModalFromClosing={preventModalFromClosing}
          onClose={onClose}
        />
      )}
    </>
  )
}
