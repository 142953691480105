import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ValidateFn, ValidationError } from '../Form'
import { HealthSystem, PrimitiveCustomFieldType } from '../../api/interfaces'
import { isValidPrimitiveCustomFieldType } from './utils'
import {
  ADD_PRIMITIVE_CUSTOM_FIELD,
  ADD_TEXT_LIST_CUSTOM_FIELD,
  DELETE_CUSTOM_FIELD,
  UPDATE_PRIMITIVE_CUSTOM_FIELD,
  UPDATE_TEXT_LIST_CUSTOM_FIELD,
} from '../../api/requests/systems'
import { useSystems } from '../Systems'
import { isNonEmptyArray } from '@apollo/client/utilities'

const messages = defineMessages({
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  emptyArrayError: {
    id: 'form_validation.non_empty_array',
    defaultMessage: 'This list must not be empty',
  },
  errorSystemNotFound: {
    id: 'CustomFieldsTab.system_not_found',
    defaultMessage: 'System not found',
  },
  errorAddingCustomField: {
    id: 'CustomFieldsTab.error_adding_custom_field',
    defaultMessage: 'Could not add this custom field. Please, retry.',
  },
  errorUpdatingCustomField: {
    id: 'CustomFieldsTab.error_updating_custom_field',
    defaultMessage: 'Could not update this custom field. Please, retry.',
  },
  errorRemovingCustomField: {
    id: 'CustomFieldsTab.error_removing_custom_field',
    defaultMessage: 'Could not remove this custom field. Please, retry.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

interface PrimitiveCustomFieldFormValues {
  name: string
  type: PrimitiveCustomFieldType
  required: boolean
}

interface AddPrimitiveCustomFieldVariables {
  systemId: string
  newPrimitiveCustomField: PrimitiveCustomFieldFormValues
}

interface AddPrimitiveCustomFieldData {
  addPrimitiveCustomField: HealthSystem
}

export type UseAddPrimitiveCustomFieldParams = MutationHookOptions<
  AddPrimitiveCustomFieldData,
  AddPrimitiveCustomFieldVariables
> & {
  type: PrimitiveCustomFieldType
}

export const useAddPrimitiveCustomField = (params: UseAddPrimitiveCustomFieldParams) => {
  const { type } = params
  const intl = useIntl()

  const { getMySystem } = useSystems()
  const { id: systemId } = getMySystem()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const initialValues: PrimitiveCustomFieldFormValues = {
    type,
    name: '',
    required: false,
  }

  const validate: ValidateFn<PrimitiveCustomFieldFormValues> = async (values) => {
    const errors: ValidationError<PrimitiveCustomFieldFormValues> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.required)
    }

    if (!isValidPrimitiveCustomFieldType(values.type)) {
      errors.type = intl.formatMessage(messages.required)
    }

    return errors
  }

  const translateError = (code) => {
    switch (code) {
      case 'system_not_found':
        setError(intl.formatMessage(messages.errorSystemNotFound))
        break

      case 'error_adding_custom_field':
        setError(intl.formatMessage(messages.errorAddingCustomField))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    translateError(errorCode)

    if (params?.onError) {
      params.onError(error)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }

  const onCompleted = (data: AddPrimitiveCustomFieldData) => {
    onErrorClose()
    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [addPrimitiveCustomField, { loading: isLoading }] = useMutation<
    AddPrimitiveCustomFieldData,
    AddPrimitiveCustomFieldVariables
  >(ADD_PRIMITIVE_CUSTOM_FIELD, {
    ...params,
    onError,
    onCompleted,
  })

  const onSubmit = (values: PrimitiveCustomFieldFormValues) => {
    setError(null)

    const variables: AddPrimitiveCustomFieldVariables = {
      systemId,
      newPrimitiveCustomField: {
        ...values,
      },
    }

    addPrimitiveCustomField({ variables })
  }

  return {
    onSubmit,
    isLoading,
    error,
    initialValues,
    validate,
    onErrorClose,
  }
}

interface UpdatePrimitiveCustomFieldVariables {
  systemId: string
  id: string
  newPrimitiveCustomField: PrimitiveCustomFieldFormValues
}

interface UpdatePrimitiveCustomFieldData {
  updatePrimitiveCustomField: HealthSystem
}

export type UseUpdatePrimitiveCustomFieldParams = MutationHookOptions<
  UpdatePrimitiveCustomFieldData,
  UpdatePrimitiveCustomFieldVariables
> & {
  id: string
}

export const useUpdatePrimitiveCustomField = (params: UseUpdatePrimitiveCustomFieldParams) => {
  const { id, ...rest } = params

  const intl = useIntl()

  const { getMySystem } = useSystems()
  const { id: systemId } = getMySystem()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<PrimitiveCustomFieldFormValues> = async (values) => {
    const errors: ValidationError<PrimitiveCustomFieldFormValues> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.required)
    }

    if (!isValidPrimitiveCustomFieldType(values.type)) {
      errors.type = intl.formatMessage(messages.required)
    }

    return errors
  }

  const translateError = (code) => {
    switch (code) {
      case 'system_not_found':
        setError(intl.formatMessage(messages.errorSystemNotFound))
        break

      case 'error_adding_custom_field':
        setError(intl.formatMessage(messages.errorAddingCustomField))
        break

      case 'error_updating_custom_field':
        setError(intl.formatMessage(messages.errorUpdatingCustomField))
        break

      case 'error_deleting_custom_field':
        setError(intl.formatMessage(messages.errorRemovingCustomField))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    translateError(errorCode)

    if (params?.onError) {
      params.onError(error)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }

  const onCompleted = (data: UpdatePrimitiveCustomFieldData) => {
    onErrorClose()
    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [updatePrimitiveCustomField, { loading: isLoading }] = useMutation<
    UpdatePrimitiveCustomFieldData,
    UpdatePrimitiveCustomFieldVariables
  >(UPDATE_PRIMITIVE_CUSTOM_FIELD, {
    ...rest,
    onError,
    onCompleted,
  })

  const onSubmit = (values: PrimitiveCustomFieldFormValues) => {
    setError(null)

    const variables: UpdatePrimitiveCustomFieldVariables = {
      systemId,
      id,
      newPrimitiveCustomField: {
        ...values,
      },
    }

    updatePrimitiveCustomField({ variables })
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}

/**
 * Text list field
 */

interface TextListCustomFieldFormValues {
  name: string
  required: boolean
  options: Array<string>
}

interface AddTextListCustomFieldVariables {
  systemId: string
  newTextListCustomField: TextListCustomFieldFormValues
}

interface AddTextListCustomFieldData {
  addTextListCustomField: HealthSystem
}

export type UseAddTextListCustomFieldParams = MutationHookOptions<
  AddTextListCustomFieldData,
  AddTextListCustomFieldVariables
>

export const useAddTextListCustomField = (params?: UseAddTextListCustomFieldParams) => {
  const intl = useIntl()

  const { getMySystem } = useSystems()
  const { id: systemId } = getMySystem()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const initialValues: TextListCustomFieldFormValues = {
    name: '',
    required: false,
    options: [],
  }

  const validate: ValidateFn<TextListCustomFieldFormValues> = async (values) => {
    const errors: ValidationError<TextListCustomFieldFormValues> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.required)
    }

    if (!isNonEmptyArray(values.options)) {
      errors.options = intl.formatMessage(messages.required)
    }

    return errors
  }

  const translateError = (code) => {
    switch (code) {
      case 'system_not_found':
        setError(intl.formatMessage(messages.errorSystemNotFound))
        break

      case 'error_adding_custom_field':
        setError(intl.formatMessage(messages.errorAddingCustomField))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    translateError(errorCode)

    if (params?.onError) {
      params.onError(error)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }

  const onCompleted = (data: AddTextListCustomFieldData) => {
    onErrorClose()
    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [addTextListCustomField, { loading: isLoading }] = useMutation<
    AddTextListCustomFieldData,
    AddTextListCustomFieldVariables
  >(ADD_TEXT_LIST_CUSTOM_FIELD, {
    ...params,
    onError,
    onCompleted,
  })

  const onSubmit = (values: TextListCustomFieldFormValues) => {
    setError(null)

    const variables: AddTextListCustomFieldVariables = {
      systemId,
      newTextListCustomField: {
        ...values,
      },
    }

    addTextListCustomField({ variables })
  }

  return {
    onSubmit,
    isLoading,
    error,
    initialValues,
    validate,
    onErrorClose,
  }
}

interface UpdateTextListCustomFieldVariables {
  systemId: string
  id: string
  newTextListCustomField: TextListCustomFieldFormValues
}

interface UpdateTextListCustomFieldData {
  updateTextListCustomField: HealthSystem
}

export type UseUpdateTextListCustomFieldParams = MutationHookOptions<
  UpdateTextListCustomFieldData,
  UpdateTextListCustomFieldVariables
> & {
  id: string
}

export const useUpdateTextListCustomField = (params: UseUpdateTextListCustomFieldParams) => {
  const { id, ...rest } = params

  const intl = useIntl()

  const { getMySystem } = useSystems()
  const { id: systemId } = getMySystem()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate: ValidateFn<TextListCustomFieldFormValues> = async (values) => {
    const errors: ValidationError<TextListCustomFieldFormValues> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.required)
    }

    if (!isNonEmptyArray(values.options)) {
      errors.options = intl.formatMessage(messages.emptyArrayError)
    }

    return errors
  }

  const translateError = (code) => {
    switch (code) {
      case 'system_not_found':
        setError(intl.formatMessage(messages.errorSystemNotFound))
        break

      case 'error_updating_custom_field':
        setError(intl.formatMessage(messages.errorUpdatingCustomField))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    translateError(errorCode)

    if (params?.onError) {
      params.onError(error)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }

  const onCompleted = (data: UpdateTextListCustomFieldData) => {
    onErrorClose()
    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [updateTextListCustomField, { loading: isLoading }] = useMutation<
    UpdateTextListCustomFieldData,
    UpdateTextListCustomFieldVariables
  >(UPDATE_TEXT_LIST_CUSTOM_FIELD, {
    ...rest,
    onError,
    onCompleted,
  })

  const onSubmit = (values: TextListCustomFieldFormValues) => {
    setError(null)

    const variables: UpdateTextListCustomFieldVariables = {
      systemId,
      id,
      newTextListCustomField: {
        ...values,
      },
    }

    updateTextListCustomField({ variables })
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}
/**
 * Deletion
 */
interface DeleteCustomFieldVariables {
  systemId: string
  id: string
}

interface DeleteCustomFieldData {
  deleteCustomField: HealthSystem
}

export type UseDeleteCustomFieldParams = MutationHookOptions<
  DeleteCustomFieldData,
  DeleteCustomFieldVariables
> & {
  id: string
}

export const useDeleteCustomField = (params: UseDeleteCustomFieldParams) => {
  const { id, ...rest } = params

  const intl = useIntl()

  const { getMySystem } = useSystems()
  const { id: systemId } = getMySystem()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'system_not_found':
        setError(intl.formatMessage(messages.errorSystemNotFound))
        break

      case 'error_deleting_custom_field':
        setError(intl.formatMessage(messages.errorRemovingCustomField))
        break

      default:
        setError(intl.formatMessage(messages.internalServerError))
        break
    }
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    translateError(errorCode)

    if (params?.onError) {
      params.onError(error)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }

  const onCompleted = (data: DeleteCustomFieldData) => {
    onErrorClose()
    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [deleteCustomField, { loading: isLoading }] = useMutation<
    DeleteCustomFieldData,
    DeleteCustomFieldVariables
  >(DELETE_CUSTOM_FIELD, {
    ...rest,
    onError,
    onCompleted,
  })

  const onSubmit = () => {
    setError(null)

    const variables: DeleteCustomFieldVariables = {
      systemId,
      id,
    }

    deleteCustomField({ variables })
  }

  return {
    onSubmit,
    isLoading,
    error,
    onErrorClose,
  }
}
