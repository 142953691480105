import { gql } from '@apollo/client'
import { UserSignature } from '.'
import { FullPrimitiveCustomField, FullTextListCustomField } from './customFields'

export const FullRoomEvent = gql`
  fragment FullRoomEvent on RoomEvent {
    id
    name
    description
    recurrence
    start
    end
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`

export const FullRoom = gql`
  fragment FullRoom on Room {
    id
    name
    description
    available
    events {
      ...FullRoomEvent
    }
  }
  ${FullRoomEvent}
`

export const FullPaymentSettings = gql`
  fragment FullPaymentSettings on PaymentSettings {
    defaultPricelist
    currency
  }
`

export const FullSlot = gql`
  fragment FullSlot on Slot {
    length
    unit
  }
`

export const FullTelehealthSettings = gql`
  fragment FullTelehealthSettings on TelehealthSettings {
    slot {
      ...FullSlot
    }
  }
  ${FullSlot}
`

export const FullLocation = gql`
  fragment FullLocation on Location {
    id
    name
    address
    phoneNumber
    country
    paymentSettings {
      ...FullPaymentSettings
    }
    telehealth {
      ...FullTelehealthSettings
    }
    updated {
      ...UserSignature
    }
    created {
      ...UserSignature
    }
    rooms {
      ...FullRoom
    }
  }
  ${FullRoom}
  ${UserSignature}
  ${FullPaymentSettings}
  ${FullTelehealthSettings}
`

export const FullRegistrationSettings = gql`
  fragment FullRegistrationSettings on RegistrationSettings {
    groups {
      id
      name
      customFields
    }
  }
`

export const FullSubscription = gql`
  fragment FullSubscription on Subscriptions {
    items
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`

export const BasicSystem = gql`
  fragment BasicSystem on System {
    id
    name
    address
    country
    timezone
  }
`

export const FullSystem = gql`
  fragment FullSystem on System {
    ...BasicSystem
    customFields {
      ... on PrimitiveCustomField {
        ...FullPrimitiveCustomField
      }
      ... on TextListCustomField {
        ...FullTextListCustomField
      }
    }
    registration {
      ...FullRegistrationSettings
    }
    subscriptions {
      lab {
        ...FullSubscription
      }
      procedures {
        ...FullSubscription
      }
    }
    locations {
      ...FullLocation
    }
    customRXFormat
    updated {
      ...UserSignature
    }
  }
  ${FullPrimitiveCustomField}
  ${FullTextListCustomField}
  ${FullRegistrationSettings}
  ${FullSubscription}
  ${UserSignature}
  ${BasicSystem}
  ${FullLocation}
`
