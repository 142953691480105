import { gql } from '@apollo/client'

export const UserSignature = gql`
  fragment UserSignature on Signature {
    family_name
    given_name
    timestamp
    user_id
  }
`

export const FullListItem = gql`
  fragment FullListItem on ListItem {
    type
    key
    listId
    name {
      text
      type
    }
  }
`

export const FullList = gql`
  fragment FullList on List {
    id
    name {
      text
    }
    items {
      ...FullListItem
    }
  }
  ${FullListItem}
`

export const FullReference = gql`
  fragment FullReference on Reference {
    name {
      type
      text
    }
    link
    image
  }
`

export const FullComment = gql`
  fragment FullComment on Comment {
    id
    type
    text
    created {
      ...UserSignature
    }
  }
  ${UserSignature}
`
