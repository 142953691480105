import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  useBreakpointValue,
  ButtonProps,
  useDisclosure,
  Stack,
  Icon,
} from '@chakra-ui/react'
import { ErrorViewer } from '..'
import { TextField, CustomFieldListField } from '../Form'
import {
  RegistrationGroup,
} from '../../api/interfaces'
import {
  RiPencilLine as EditIcon,
} from 'react-icons/ri'
import arrayMutators from 'final-form-arrays'
import { useAddRegistrationGroup, useUpdateRegistrationGroup } from './useRegistrationGroupActions'

const messages = defineMessages({
  newGroup: {
    id: 'RegistrationTab.new_registration_group',
    defaultMessage: 'New registration group'
  },
  nameLabel: {
    id: 'RegistrationTab.group_name',
    defaultMessage: 'Group name'
  },
  updateGroup: {
    id: 'RegistrationTab.update_registration_group',
    defaultMessage: 'Update registration group'
  },
  confirmationMessage: {
    id: 'RegistrationTab.remove_registration_group',
    defaultMessage: 'This group will be removed from the registration form. Are you sure?'
  },
  fields: {
    id: 'RegistrationTab.fields',
    defaultMessage: 'Fields'
  },
  noFieldsFound: {
    id: 'RegistrationTab.no_fields_found',
    defaultMessage: 'No fields found'
  },
  addField: {
    id: 'RegistrationTab.add_field',
    defaultMessage: 'Add field'
  },
  addButton: {
    id: 'UI.button_add',
    defaultMessage: 'Add'
  },
  delete: {
    id: 'UI.button_delete',
    defaultMessage: 'Delete',
  },
  edit: {
    id: 'UI.button_edit',
    defaultMessage: 'Edit',
  },
  saveButton: {
    id: 'UI.save_button',
    defaultMessage: 'Save',
  },
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close',
  },
})

type CreateRegistrationGroupProps = ButtonProps & {
  onComplete?: () => void
}

export const CreateRegistrationGroupButton: React.FC<CreateRegistrationGroupProps> = (props) => {
  const { onComplete, ...buttonProps } = props

  const intl = useIntl()
  const { isOpen, onClose, onOpen } = useDisclosure()


  const onCreate = () => {
    if (typeof onComplete === 'function') {
      onComplete()
    }

    onClose()
  }
  return (
    <>
      <Button colorScheme={'brand'} onClick={onOpen} {...buttonProps}>
        {intl.formatMessage(messages.addButton)}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb={0}>{intl.formatMessage(messages.newGroup)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CreateRegistrationGroupForm onClose={onClose} onComplete={onCreate} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

interface CreateRegistrationGroupFormProps {
  onClose: () => void
  onComplete: () => void
}

const CreateRegistrationGroupForm: React.FC<CreateRegistrationGroupFormProps> = (props) => {
  const { onClose, onComplete } = props
  const intl = useIntl()

  const params = {
    onCompleted: () => {
      onComplete()
      onClose()
    },
  }

  const {
    onSubmit,
    isLoading,
    error,
    onErrorClose,
    initialValues,
    validate
  } = useAddRegistrationGroup(params)

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators
      }}
      keepDirtyOnReinitialize
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TextField name="name" label={intl.formatMessage(messages.nameLabel)} />
            <CustomFieldListField name='customFields' label={intl.formatMessage(messages.fields)} />

            {error && <ErrorViewer title={error} onClose={onErrorClose} />}

            <Stack isInline={!isFullWidth} justifyContent={'end'}>
              <Button onClick={onClose} isFullWidth={isFullWidth}>
                {intl.formatMessage(messages.closeButton)}
              </Button>
              <Button
                colorScheme={'brand'}
                isLoading={isLoading}
                type="submit"
                isFullWidth={isFullWidth}
                isDisabled={pristine}
              >
                {intl.formatMessage(messages.saveButton)}
              </Button>
            </Stack>
          </form>
        )
      }}
    />
  )
}


type UpdateRegistrationGroupProps = ButtonProps & {
  onComplete?: () => void
  group: RegistrationGroup
}

export const UpdateRegistrationGroupButton: React.FC<UpdateRegistrationGroupProps> = (props) => {
  const { onComplete, group, ...buttonProps } = props

  const intl = useIntl()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const onUpdate = () => {
    if (typeof onComplete === 'function') {
      onComplete()
    }
    onClose()
  }
  return (
    <>
      <Button onClick={onOpen} {...buttonProps}>
        <Icon as={EditIcon} mr={2} />
        {intl.formatMessage(messages.edit)}
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader pb={0}>{intl.formatMessage(messages.updateGroup)}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <UpdateRegistrationGroupForm onClose={onClose} onComplete={onUpdate} group={group} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}


interface UpdateRegistrationGroupFormProps {
  onClose: () => void
  onComplete: () => void
  group: RegistrationGroup
}

const UpdateRegistrationGroupForm: React.FC<UpdateRegistrationGroupFormProps> = (props) => {
  const { group, onClose } = props
  const intl = useIntl()

  const params = {
    onCompleted: onClose,
    id: group.id,
  }

  const { onSubmit, isLoading, error, onErrorClose, validate } = useUpdateRegistrationGroup(params)

  const initialValues = {
    name: group.name,
    customFields: group.customFields
  }

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators
      }}
      keepDirtyOnReinitialize
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TextField autoFocus name="name" label={intl.formatMessage(messages.nameLabel)} />
            <CustomFieldListField name='customFields' label={intl.formatMessage(messages.fields)} />

            {error && <ErrorViewer title={error} onClose={onErrorClose} />}

            <Stack isInline={!isFullWidth} justifyContent={'end'} mt={2}>
              <Button onClick={onClose} isFullWidth={isFullWidth}>
                {intl.formatMessage(messages.closeButton)}
              </Button>
              <Button
                colorScheme={'brand'}
                isLoading={isLoading}
                type="submit"
                isFullWidth={isFullWidth}
                isDisabled={pristine}
              >
                {intl.formatMessage(messages.saveButton)}
              </Button>
            </Stack>
          </form>
        )
      }}
    />
  )
}