import React from 'react'
import { ChecklistReferenceBody } from './ChecklistReference'
import { Checkbox, CheckboxProps, Text, Box } from '@chakra-ui/react'
import { DynamicTranslation } from '../i18n'
import { DrugGuideline as DrugGuidelineInterface } from '../../api/interfaces/pharmacy'
import { DrugGuideline } from '../Pharmacy/DrugGuideline'

type ChecklistDrugGuidelineProps = Omit<CheckboxProps, 'onChange' | 'value' | 'defaultValue'> & {
  entry: DrugGuidelineInterface
  onCheckChange: (checked: boolean) => void
}

export const ChecklistDrugGuideline: React.FC<ChecklistDrugGuidelineProps> = (props) => {
  const { entry, onCheckChange, ...checkboxProps } = props

  const _onCheckChange = (e) => {
    const wantToBeChecked = e.target.checked
    onCheckChange(wantToBeChecked)
  }

  return (
    <>
      <Checkbox onChange={_onCheckChange} defaultChecked={!!entry.checked} my={1} {...checkboxProps}>
        <Box as="span" fontStyle={'italic'}>
          {entry.pre_text?.text && <DynamicTranslation message={entry.pre_text} as="span" />}

          <DrugGuideline guideline={entry} as="span" mx={1} />

          {entry.post_text?.text && <DynamicTranslation message={entry.post_text} as="span" />}

          {entry.reference && (
            <>
              <Text as="sup">*</Text>
              <br key="br" />
              <ChecklistReferenceBody reference={entry.reference} fontSize={'sm'} />
            </>
          )}
        </Box>
      </Checkbox>
    </>
  )
}
