
// Child growth standards
// DMI values for BOYS between 24 - 228 months (2 - 19 years)
// Data taken from World Health Organization and then parsed to JSON
// Links:
// https://www.who.int/childgrowth/standards/tab_bmi_boys_p_2_5.txt (24 - 60 months)
// https://www.who.int/growthref/bmi_boys_perc_WHO2007_exp.txt      (61 - 228 months)

const bmiValues = [
  {
    month: 24,
    l: -0.6187,
    m: 16.0189,
    s: 0.07785,
    p01: 12.8,
    p1: 13.5,
    p3: 13.9,
    p5: 14.2,
    p10: 14.5,
    p15: 14.8,
    p25: 15.2,
    p50: 16,
    p75: 16.9,
    p85: 17.4,
    p90: 17.8,
    p95: 18.3,
    p97: 18.7,
    p99: 19.4,
    p999: 20.8
  },
  {
    month: 25,
    l: -0.584,
    m: 15.98,
    s: 0.07792,
    p01: 12.8,
    p1: 13.5,
    p3: 13.9,
    p5: 14.1,
    p10: 14.5,
    p15: 14.8,
    p25: 15.2,
    p50: 16,
    p75: 16.9,
    p85: 17.4,
    p90: 17.7,
    p95: 18.3,
    p97: 18.6,
    p99: 19.4,
    p999: 20.7
  },
  {
    month: 26,
    l: -0.5497,
    m: 15.9414,
    s: 0.078,
    p01: 12.7,
    p1: 13.4,
    p3: 13.8,
    p5: 14.1,
    p10: 14.5,
    p15: 14.7,
    p25: 15.1,
    p50: 15.9,
    p75: 16.8,
    p85: 17.3,
    p90: 17.7,
    p95: 18.2,
    p97: 18.6,
    p99: 19.3,
    p999: 20.6
  },
  {
    month: 27,
    l: -0.5166,
    m: 15.9036,
    s: 0.07808,
    p01: 12.7,
    p1: 13.4,
    p3: 13.8,
    p5: 14,
    p10: 14.4,
    p15: 14.7,
    p25: 15.1,
    p50: 15.9,
    p75: 16.8,
    p85: 17.3,
    p90: 17.6,
    p95: 18.2,
    p97: 18.5,
    p99: 19.2,
    p999: 20.6
  },
  {
    month: 28,
    l: -0.485,
    m: 15.8667,
    s: 0.07818,
    p01: 12.6,
    p1: 13.3,
    p3: 13.8,
    p5: 14,
    p10: 14.4,
    p15: 14.7,
    p25: 15.1,
    p50: 15.9,
    p75: 16.7,
    p85: 17.2,
    p90: 17.6,
    p95: 18.1,
    p97: 18.5,
    p99: 19.2,
    p999: 20.5
  },
  {
    month: 29,
    l: -0.4552,
    m: 15.8306,
    s: 0.07829,
    p01: 12.6,
    p1: 13.3,
    p3: 13.7,
    p5: 14,
    p10: 14.4,
    p15: 14.6,
    p25: 15,
    p50: 15.8,
    p75: 16.7,
    p85: 17.2,
    p90: 17.5,
    p95: 18.1,
    p97: 18.4,
    p99: 19.1,
    p999: 20.5
  },
  {
    month: 30,
    l: -0.4274,
    m: 15.7953,
    s: 0.07841,
    p01: 12.5,
    p1: 13.3,
    p3: 13.7,
    p5: 13.9,
    p10: 14.3,
    p15: 14.6,
    p25: 15,
    p50: 15.8,
    p75: 16.7,
    p85: 17.2,
    p90: 17.5,
    p95: 18,
    p97: 18.4,
    p99: 19.1,
    p999: 20.4
  },
  {
    month: 31,
    l: -0.4016,
    m: 15.7606,
    s: 0.07854,
    p01: 12.5,
    p1: 13.2,
    p3: 13.7,
    p5: 13.9,
    p10: 14.3,
    p15: 14.5,
    p25: 15,
    p50: 15.8,
    p75: 16.6,
    p85: 17.1,
    p90: 17.5,
    p95: 18,
    p97: 18.4,
    p99: 19.1,
    p999: 20.3
  },
  {
    month: 32,
    l: -0.3782,
    m: 15.7267,
    s: 0.07867,
    p01: 12.5,
    p1: 13.2,
    p3: 13.6,
    p5: 13.9,
    p10: 14.2,
    p15: 14.5,
    p25: 14.9,
    p50: 15.7,
    p75: 16.6,
    p85: 17.1,
    p90: 17.4,
    p95: 18,
    p97: 18.3,
    p99: 19,
    p999: 20.3
  },
  {
    month: 33,
    l: -0.3572,
    m: 15.6934,
    s: 0.07882,
    p01: 12.4,
    p1: 13.1,
    p3: 13.6,
    p5: 13.8,
    p10: 14.2,
    p15: 14.5,
    p25: 14.9,
    p50: 15.7,
    p75: 16.6,
    p85: 17,
    p90: 17.4,
    p95: 17.9,
    p97: 18.3,
    p99: 19,
    p999: 20.2
  },
  {
    month: 34,
    l: -0.3388,
    m: 15.661,
    s: 0.07897,
    p01: 12.4,
    p1: 13.1,
    p3: 13.5,
    p5: 13.8,
    p10: 14.2,
    p15: 14.4,
    p25: 14.9,
    p50: 15.7,
    p75: 16.5,
    p85: 17,
    p90: 17.4,
    p95: 17.9,
    p97: 18.2,
    p99: 18.9,
    p999: 20.2
  },
  {
    month: 35,
    l: -0.3231,
    m: 15.6294,
    s: 0.07914,
    p01: 12.4,
    p1: 13.1,
    p3: 13.5,
    p5: 13.8,
    p10: 14.1,
    p15: 14.4,
    p25: 14.8,
    p50: 15.6,
    p75: 16.5,
    p85: 17,
    p90: 17.3,
    p95: 17.9,
    p97: 18.2,
    p99: 18.9,
    p999: 20.2
  },
  {
    month: 36,
    l: -0.3101,
    m: 15.5988,
    s: 0.07931,
    p01: 12.3,
    p1: 13,
    p3: 13.5,
    p5: 13.7,
    p10: 14.1,
    p15: 14.4,
    p25: 14.8,
    p50: 15.6,
    p75: 16.5,
    p85: 17,
    p90: 17.3,
    p95: 17.8,
    p97: 18.2,
    p99: 18.9,
    p999: 20.1
  },
  {
    month: 37,
    l: -0.3,
    m: 15.5693,
    s: 0.0795,
    p01: 12.3,
    p1: 13,
    p3: 13.5,
    p5: 13.7,
    p10: 14.1,
    p15: 14.4,
    p25: 14.8,
    p50: 15.6,
    p75: 16.4,
    p85: 16.9,
    p90: 17.3,
    p95: 17.8,
    p97: 18.1,
    p99: 18.8,
    p999: 20.1
  },
  {
    month: 38,
    l: -0.2927,
    m: 15.541,
    s: 0.07969,
    p01: 12.3,
    p1: 13,
    p3: 13.4,
    p5: 13.7,
    p10: 14.1,
    p15: 14.3,
    p25: 14.7,
    p50: 15.5,
    p75: 16.4,
    p85: 16.9,
    p90: 17.2,
    p95: 17.8,
    p97: 18.1,
    p99: 18.8,
    p999: 20.1
  },
  {
    month: 39,
    l: -0.2884,
    m: 15.514,
    s: 0.0799,
    p01: 12.2,
    p1: 12.9,
    p3: 13.4,
    p5: 13.6,
    p10: 14,
    p15: 14.3,
    p25: 14.7,
    p50: 15.5,
    p75: 16.4,
    p85: 16.9,
    p90: 17.2,
    p95: 17.7,
    p97: 18.1,
    p99: 18.8,
    p999: 20
  },
  {
    month: 40,
    l: -0.2869,
    m: 15.4885,
    s: 0.08012,
    p01: 12.2,
    p1: 12.9,
    p3: 13.4,
    p5: 13.6,
    p10: 14,
    p15: 14.3,
    p25: 14.7,
    p50: 15.5,
    p75: 16.4,
    p85: 16.8,
    p90: 17.2,
    p95: 17.7,
    p97: 18.1,
    p99: 18.8,
    p999: 20
  },
  {
    month: 41,
    l: -0.2881,
    m: 15.4645,
    s: 0.08036,
    p01: 12.2,
    p1: 12.9,
    p3: 13.3,
    p5: 13.6,
    p10: 14,
    p15: 14.2,
    p25: 14.7,
    p50: 15.5,
    p75: 16.3,
    p85: 16.8,
    p90: 17.2,
    p95: 17.7,
    p97: 18,
    p99: 18.7,
    p999: 20
  },
  {
    month: 42,
    l: -0.2919,
    m: 15.442,
    s: 0.08061,
    p01: 12.1,
    p1: 12.9,
    p3: 13.3,
    p5: 13.6,
    p10: 13.9,
    p15: 14.2,
    p25: 14.6,
    p50: 15.4,
    p75: 16.3,
    p85: 16.8,
    p90: 17.1,
    p95: 17.7,
    p97: 18,
    p99: 18.7,
    p999: 20
  },
  {
    month: 43,
    l: -0.2981,
    m: 15.421,
    s: 0.08087,
    p01: 12.1,
    p1: 12.8,
    p3: 13.3,
    p5: 13.5,
    p10: 13.9,
    p15: 14.2,
    p25: 14.6,
    p50: 15.4,
    p75: 16.3,
    p85: 16.8,
    p90: 17.1,
    p95: 17.7,
    p97: 18,
    p99: 18.7,
    p999: 20
  },
  {
    month: 44,
    l: -0.3067,
    m: 15.4013,
    s: 0.08115,
    p01: 12.1,
    p1: 12.8,
    p3: 13.3,
    p5: 13.5,
    p10: 13.9,
    p15: 14.2,
    p25: 14.6,
    p50: 15.4,
    p75: 16.3,
    p85: 16.8,
    p90: 17.1,
    p95: 17.7,
    p97: 18,
    p99: 18.7,
    p999: 20
  },
  {
    month: 45,
    l: -0.3174,
    m: 15.3827,
    s: 0.08144,
    p01: 12.1,
    p1: 12.8,
    p3: 13.2,
    p5: 13.5,
    p10: 13.9,
    p15: 14.2,
    p25: 14.6,
    p50: 15.4,
    p75: 16.3,
    p85: 16.8,
    p90: 17.1,
    p95: 17.6,
    p97: 18,
    p99: 18.7,
    p999: 20
  },
  {
    month: 46,
    l: -0.3303,
    m: 15.3652,
    s: 0.08174,
    p01: 12.1,
    p1: 12.8,
    p3: 13.2,
    p5: 13.5,
    p10: 13.9,
    p15: 14.1,
    p25: 14.5,
    p50: 15.4,
    p75: 16.2,
    p85: 16.7,
    p90: 17.1,
    p95: 17.6,
    p97: 18,
    p99: 18.7,
    p999: 20
  },
  {
    month: 47,
    l: -0.3452,
    m: 15.3485,
    s: 0.08205,
    p01: 12,
    p1: 12.8,
    p3: 13.2,
    p5: 13.5,
    p10: 13.8,
    p15: 14.1,
    p25: 14.5,
    p50: 15.3,
    p75: 16.2,
    p85: 16.7,
    p90: 17.1,
    p95: 17.6,
    p97: 18,
    p99: 18.7,
    p999: 20
  },
  {
    month: 48,
    l: -0.3622,
    m: 15.3326,
    s: 0.08238,
    p01: 12,
    p1: 12.7,
    p3: 13.2,
    p5: 13.4,
    p10: 13.8,
    p15: 14.1,
    p25: 14.5,
    p50: 15.3,
    p75: 16.2,
    p85: 16.7,
    p90: 17.1,
    p95: 17.6,
    p97: 18,
    p99: 18.7,
    p999: 20
  },
  {
    month: 49,
    l: -0.3811,
    m: 15.3174,
    s: 0.08272,
    p01: 12,
    p1: 12.7,
    p3: 13.2,
    p5: 13.4,
    p10: 13.8,
    p15: 14.1,
    p25: 14.5,
    p50: 15.3,
    p75: 16.2,
    p85: 16.7,
    p90: 17.1,
    p95: 17.6,
    p97: 18,
    p99: 18.7,
    p999: 20
  },
  {
    month: 50,
    l: -0.4019,
    m: 15.3029,
    s: 0.08307,
    p01: 12,
    p1: 12.7,
    p3: 13.2,
    p5: 13.4,
    p10: 13.8,
    p15: 14.1,
    p25: 14.5,
    p50: 15.3,
    p75: 16.2,
    p85: 16.7,
    p90: 17.1,
    p95: 17.6,
    p97: 18,
    p99: 18.7,
    p999: 20.1
  },
  {
    month: 51,
    l: -0.4245,
    m: 15.2891,
    s: 0.08343,
    p01: 12,
    p1: 12.7,
    p3: 13.1,
    p5: 13.4,
    p10: 13.8,
    p15: 14,
    p25: 14.5,
    p50: 15.3,
    p75: 16.2,
    p85: 16.7,
    p90: 17.1,
    p95: 17.6,
    p97: 18,
    p99: 18.7,
    p999: 20.1
  },
  {
    month: 52,
    l: -0.4488,
    m: 15.2759,
    s: 0.0838,
    p01: 12,
    p1: 12.7,
    p3: 13.1,
    p5: 13.4,
    p10: 13.8,
    p15: 14,
    p25: 14.4,
    p50: 15.3,
    p75: 16.2,
    p85: 16.7,
    p90: 17.1,
    p95: 17.6,
    p97: 18,
    p99: 18.7,
    p999: 20.1
  },
  {
    month: 53,
    l: -0.4747,
    m: 15.2633,
    s: 0.08418,
    p01: 11.9,
    p1: 12.7,
    p3: 13.1,
    p5: 13.3,
    p10: 13.7,
    p15: 14,
    p25: 14.4,
    p50: 15.3,
    p75: 16.2,
    p85: 16.7,
    p90: 17.1,
    p95: 17.6,
    p97: 18,
    p99: 18.7,
    p999: 20.1
  },
  {
    month: 54,
    l: -0.5019,
    m: 15.2514,
    s: 0.08457,
    p01: 11.9,
    p1: 12.6,
    p3: 13.1,
    p5: 13.3,
    p10: 13.7,
    p15: 14,
    p25: 14.4,
    p50: 15.3,
    p75: 16.2,
    p85: 16.7,
    p90: 17,
    p95: 17.6,
    p97: 18,
    p99: 18.8,
    p999: 20.2
  },
  {
    month: 55,
    l: -0.5303,
    m: 15.24,
    s: 0.08496,
    p01: 11.9,
    p1: 12.6,
    p3: 13.1,
    p5: 13.3,
    p10: 13.7,
    p15: 14,
    p25: 14.4,
    p50: 15.2,
    p75: 16.2,
    p85: 16.7,
    p90: 17,
    p95: 17.6,
    p97: 18,
    p99: 18.8,
    p999: 20.2
  },
  {
    month: 56,
    l: -0.5599,
    m: 15.2291,
    s: 0.08536,
    p01: 11.9,
    p1: 12.6,
    p3: 13.1,
    p5: 13.3,
    p10: 13.7,
    p15: 14,
    p25: 14.4,
    p50: 15.2,
    p75: 16.1,
    p85: 16.7,
    p90: 17,
    p95: 17.6,
    p97: 18,
    p99: 18.8,
    p999: 20.3
  },
  {
    month: 57,
    l: -0.5905,
    m: 15.2188,
    s: 0.08577,
    p01: 11.9,
    p1: 12.6,
    p3: 13,
    p5: 13.3,
    p10: 13.7,
    p15: 14,
    p25: 14.4,
    p50: 15.2,
    p75: 16.1,
    p85: 16.7,
    p90: 17.1,
    p95: 17.6,
    p97: 18,
    p99: 18.8,
    p999: 20.3
  },
  {
    month: 58,
    l: -0.6223,
    m: 15.2091,
    s: 0.08617,
    p01: 11.9,
    p1: 12.6,
    p3: 13,
    p5: 13.3,
    p10: 13.7,
    p15: 13.9,
    p25: 14.4,
    p50: 15.2,
    p75: 16.1,
    p85: 16.7,
    p90: 17.1,
    p95: 17.6,
    p97: 18,
    p99: 18.8,
    p999: 20.3
  },
  {
    month: 59,
    l: -0.6552,
    m: 15.2,
    s: 0.08659,
    p01: 11.9,
    p1: 12.6,
    p3: 13,
    p5: 13.3,
    p10: 13.7,
    p15: 13.9,
    p25: 14.4,
    p50: 15.2,
    p75: 16.1,
    p85: 16.7,
    p90: 17.1,
    p95: 17.7,
    p97: 18.1,
    p99: 18.9,
    p999: 20.4
  },
  {
    month: 60,
    l: -0.6892,
    m: 15.1916,
    s: 0.087,
    p01: 11.9,
    p1: 12.6,
    p3: 13,
    p5: 13.3,
    p10: 13.6,
    p15: 13.9,
    p25: 14.3,
    p50: 15.2,
    p75: 16.1,
    p85: 16.7,
    p90: 17.1,
    p95: 17.7,
    p97: 18.1,
    p99: 18.9,
    p999: 20.5
  },
  {
    month: 61,
    l: -0.7387,
    m: 15.2641,
    s: 0.0839,
    p01: 12.041,
    p1: 12.72,
    p3: 13.148,
    p5: 13.384,
    p10: 13.764,
    p15: 14.03,
    p25: 14.441,
    p50: 15.264,
    p75: 16.172,
    p85: 16.7,
    p90: 17.074,
    p95: 17.656,
    p97: 18.052,
    p99: 18.845,
    p999: 20.355
  },
  {
    month: 62,
    l: -0.7621,
    m: 15.2616,
    s: 0.08414,
    p01: 12.039,
    p1: 12.716,
    p3: 13.144,
    p5: 13.38,
    p10: 13.759,
    p15: 14.026,
    p25: 14.437,
    p50: 15.262,
    p75: 16.173,
    p85: 16.703,
    p90: 17.079,
    p95: 17.665,
    p97: 18.065,
    p99: 18.865,
    p999: 20.392
  },
  {
    month: 63,
    l: -0.7856,
    m: 15.2604,
    s: 0.08439,
    p01: 12.037,
    p1: 12.714,
    p3: 13.14,
    p5: 13.377,
    p10: 13.756,
    p15: 14.023,
    p25: 14.434,
    p50: 15.26,
    p75: 16.175,
    p85: 16.708,
    p90: 17.086,
    p95: 17.677,
    p97: 18.08,
    p99: 18.887,
    p999: 20.432
  },
  {
    month: 64,
    l: -0.8089,
    m: 15.2605,
    s: 0.08464,
    p01: 12.037,
    p1: 12.712,
    p3: 13.138,
    p5: 13.374,
    p10: 13.754,
    p15: 14.02,
    p25: 14.432,
    p50: 15.26,
    p75: 16.179,
    p85: 16.714,
    p90: 17.095,
    p95: 17.689,
    p97: 18.096,
    p99: 18.911,
    p999: 20.474
  },
  {
    month: 65,
    l: -0.8322,
    m: 15.2619,
    s: 0.0849,
    p01: 12.038,
    p1: 12.711,
    p3: 13.137,
    p5: 13.373,
    p10: 13.752,
    p15: 14.019,
    p25: 14.432,
    p50: 15.262,
    p75: 16.184,
    p85: 16.722,
    p90: 17.106,
    p95: 17.704,
    p97: 18.114,
    p99: 18.937,
    p999: 20.519
  },
  {
    month: 66,
    l: -0.8554,
    m: 15.2645,
    s: 0.08516,
    p01: 12.039,
    p1: 12.712,
    p3: 13.137,
    p5: 13.373,
    p10: 13.752,
    p15: 14.019,
    p25: 14.432,
    p50: 15.264,
    p75: 16.191,
    p85: 16.732,
    p90: 17.118,
    p95: 17.721,
    p97: 18.134,
    p99: 18.965,
    p999: 20.567
  },
  {
    month: 67,
    l: -0.8785,
    m: 15.2684,
    s: 0.08543,
    p01: 12.042,
    p1: 12.713,
    p3: 13.138,
    p5: 13.374,
    p10: 13.753,
    p15: 14.02,
    p25: 14.434,
    p50: 15.268,
    p75: 16.198,
    p85: 16.743,
    p90: 17.131,
    p95: 17.739,
    p97: 18.156,
    p99: 18.995,
    p999: 20.618
  },
  {
    month: 68,
    l: -0.9015,
    m: 15.2737,
    s: 0.0857,
    p01: 12.045,
    p1: 12.715,
    p3: 13.14,
    p5: 13.376,
    p10: 13.755,
    p15: 14.023,
    p25: 14.437,
    p50: 15.274,
    p75: 16.208,
    p85: 16.755,
    p90: 17.146,
    p95: 17.759,
    p97: 18.18,
    p99: 19.028,
    p999: 20.671
  },
  {
    month: 69,
    l: -0.9243,
    m: 15.2801,
    s: 0.08597,
    p01: 12.049,
    p1: 12.718,
    p3: 13.143,
    p5: 13.379,
    p10: 13.758,
    p15: 14.026,
    p25: 14.441,
    p50: 15.28,
    p75: 16.218,
    p85: 16.769,
    p90: 17.163,
    p95: 17.78,
    p97: 18.205,
    p99: 19.062,
    p999: 20.726
  },
  {
    month: 70,
    l: -0.9471,
    m: 15.2877,
    s: 0.08625,
    p01: 12.054,
    p1: 12.722,
    p3: 13.146,
    p5: 13.382,
    p10: 13.762,
    p15: 14.031,
    p25: 14.446,
    p50: 15.288,
    p75: 16.23,
    p85: 16.784,
    p90: 17.181,
    p95: 17.804,
    p97: 18.232,
    p99: 19.098,
    p999: 20.785
  },
  {
    month: 71,
    l: -0.9697,
    m: 15.2965,
    s: 0.08653,
    p01: 12.06,
    p1: 12.727,
    p3: 13.151,
    p5: 13.387,
    p10: 13.767,
    p15: 14.036,
    p25: 14.452,
    p50: 15.296,
    p75: 16.244,
    p85: 16.801,
    p90: 17.201,
    p95: 17.828,
    p97: 18.261,
    p99: 19.136,
    p999: 20.846
  },
  {
    month: 72,
    l: -0.9921,
    m: 15.3062,
    s: 0.08682,
    p01: 12.066,
    p1: 12.733,
    p3: 13.157,
    p5: 13.393,
    p10: 13.773,
    p15: 14.042,
    p25: 14.459,
    p50: 15.306,
    p75: 16.258,
    p85: 16.819,
    p90: 17.221,
    p95: 17.854,
    p97: 18.291,
    p99: 19.176,
    p999: 20.91
  },
  {
    month: 73,
    l: -1.0144,
    m: 15.3169,
    s: 0.08711,
    p01: 12.073,
    p1: 12.739,
    p3: 13.163,
    p5: 13.399,
    p10: 13.78,
    p15: 14.049,
    p25: 14.467,
    p50: 15.317,
    p75: 16.273,
    p85: 16.838,
    p90: 17.244,
    p95: 17.882,
    p97: 18.323,
    p99: 19.217,
    p999: 20.975
  },
  {
    month: 74,
    l: -1.0365,
    m: 15.3285,
    s: 0.08741,
    p01: 12.08,
    p1: 12.746,
    p3: 13.17,
    p5: 13.406,
    p10: 13.787,
    p15: 14.057,
    p25: 14.476,
    p50: 15.328,
    p75: 16.29,
    p85: 16.858,
    p90: 17.267,
    p95: 17.911,
    p97: 18.356,
    p99: 19.261,
    p999: 21.044
  },
  {
    month: 75,
    l: -1.0584,
    m: 15.3408,
    s: 0.08771,
    p01: 12.088,
    p1: 12.753,
    p3: 13.177,
    p5: 13.413,
    p10: 13.795,
    p15: 14.065,
    p25: 14.485,
    p50: 15.341,
    p75: 16.307,
    p85: 16.879,
    p90: 17.291,
    p95: 17.941,
    p97: 18.39,
    p99: 19.305,
    p999: 21.114
  },
  {
    month: 76,
    l: -1.0801,
    m: 15.354,
    s: 0.08802,
    p01: 12.096,
    p1: 12.761,
    p3: 13.185,
    p5: 13.422,
    p10: 13.804,
    p15: 14.075,
    p25: 14.495,
    p50: 15.354,
    p75: 16.326,
    p85: 16.902,
    p90: 17.317,
    p95: 17.972,
    p97: 18.426,
    p99: 19.352,
    p999: 21.188
  },
  {
    month: 77,
    l: -1.1017,
    m: 15.3679,
    s: 0.08833,
    p01: 12.105,
    p1: 12.769,
    p3: 13.193,
    p5: 13.43,
    p10: 13.813,
    p15: 14.084,
    p25: 14.506,
    p50: 15.368,
    p75: 16.345,
    p85: 16.925,
    p90: 17.343,
    p95: 18.004,
    p97: 18.463,
    p99: 19.4,
    p999: 21.263
  },
  {
    month: 78,
    l: -1.123,
    m: 15.3825,
    s: 0.08865,
    p01: 12.114,
    p1: 12.778,
    p3: 13.202,
    p5: 13.439,
    p10: 13.823,
    p15: 14.095,
    p25: 14.518,
    p50: 15.382,
    p75: 16.365,
    p85: 16.949,
    p90: 17.37,
    p95: 18.038,
    p97: 18.501,
    p99: 19.449,
    p999: 21.341
  },
  {
    month: 79,
    l: -1.1441,
    m: 15.3978,
    s: 0.08898,
    p01: 12.124,
    p1: 12.787,
    p3: 13.212,
    p5: 13.449,
    p10: 13.833,
    p15: 14.105,
    p25: 14.529,
    p50: 15.398,
    p75: 16.386,
    p85: 16.974,
    p90: 17.399,
    p95: 18.073,
    p97: 18.541,
    p99: 19.501,
    p999: 21.421
  },
  {
    month: 80,
    l: -1.1649,
    m: 15.4137,
    s: 0.08931,
    p01: 12.133,
    p1: 12.797,
    p3: 13.222,
    p5: 13.459,
    p10: 13.843,
    p15: 14.117,
    p25: 14.542,
    p50: 15.414,
    p75: 16.407,
    p85: 17,
    p90: 17.428,
    p95: 18.108,
    p97: 18.582,
    p99: 19.553,
    p999: 21.504
  },
  {
    month: 81,
    l: -1.1856,
    m: 15.4302,
    s: 0.08964,
    p01: 12.143,
    p1: 12.807,
    p3: 13.232,
    p5: 13.47,
    p10: 13.855,
    p15: 14.128,
    p25: 14.555,
    p50: 15.43,
    p75: 16.429,
    p85: 17.026,
    p90: 17.458,
    p95: 18.145,
    p97: 18.623,
    p99: 19.607,
    p999: 21.588
  },
  {
    month: 82,
    l: -1.206,
    m: 15.4473,
    s: 0.08998,
    p01: 12.154,
    p1: 12.817,
    p3: 13.242,
    p5: 13.481,
    p10: 13.866,
    p15: 14.141,
    p25: 14.569,
    p50: 15.447,
    p75: 16.452,
    p85: 17.054,
    p90: 17.489,
    p95: 18.183,
    p97: 18.666,
    p99: 19.662,
    p999: 21.675
  },
  {
    month: 83,
    l: -1.2261,
    m: 15.465,
    s: 0.09033,
    p01: 12.164,
    p1: 12.828,
    p3: 13.253,
    p5: 13.492,
    p10: 13.878,
    p15: 14.153,
    p25: 14.582,
    p50: 15.465,
    p75: 16.476,
    p85: 17.082,
    p90: 17.521,
    p95: 18.221,
    p97: 18.71,
    p99: 19.719,
    p999: 21.764
  },
  {
    month: 84,
    l: -1.246,
    m: 15.4832,
    s: 0.09068,
    p01: 12.175,
    p1: 12.839,
    p3: 13.265,
    p5: 13.504,
    p10: 13.891,
    p15: 14.166,
    p25: 14.597,
    p50: 15.483,
    p75: 16.5,
    p85: 17.111,
    p90: 17.554,
    p95: 18.261,
    p97: 18.756,
    p99: 19.777,
    p999: 21.855
  },
  {
    month: 85,
    l: -1.2656,
    m: 15.5019,
    s: 0.09103,
    p01: 12.186,
    p1: 12.85,
    p3: 13.276,
    p5: 13.516,
    p10: 13.904,
    p15: 14.18,
    p25: 14.612,
    p50: 15.502,
    p75: 16.525,
    p85: 17.14,
    p90: 17.588,
    p95: 18.301,
    p97: 18.801,
    p99: 19.836,
    p999: 21.948
  },
  {
    month: 86,
    l: -1.2849,
    m: 15.521,
    s: 0.09139,
    p01: 12.198,
    p1: 12.861,
    p3: 13.288,
    p5: 13.528,
    p10: 13.917,
    p15: 14.194,
    p25: 14.627,
    p50: 15.521,
    p75: 16.55,
    p85: 17.17,
    p90: 17.622,
    p95: 18.343,
    p97: 18.848,
    p99: 19.896,
    p999: 22.044
  },
  {
    month: 87,
    l: -1.304,
    m: 15.5407,
    s: 0.09176,
    p01: 12.209,
    p1: 12.873,
    p3: 13.301,
    p5: 13.54,
    p10: 13.93,
    p15: 14.208,
    p25: 14.643,
    p50: 15.541,
    p75: 16.577,
    p85: 17.202,
    p90: 17.657,
    p95: 18.385,
    p97: 18.897,
    p99: 19.958,
    p999: 22.142
  },
  {
    month: 88,
    l: -1.3228,
    m: 15.5608,
    s: 0.09213,
    p01: 12.221,
    p1: 12.885,
    p3: 13.313,
    p5: 13.553,
    p10: 13.944,
    p15: 14.223,
    p25: 14.659,
    p50: 15.561,
    p75: 16.603,
    p85: 17.233,
    p90: 17.692,
    p95: 18.428,
    p97: 18.945,
    p99: 20.021,
    p999: 22.243
  },
  {
    month: 89,
    l: -1.3414,
    m: 15.5814,
    s: 0.09251,
    p01: 12.232,
    p1: 12.897,
    p3: 13.326,
    p5: 13.567,
    p10: 13.958,
    p15: 14.238,
    p25: 14.675,
    p50: 15.581,
    p75: 16.631,
    p85: 17.265,
    p90: 17.729,
    p95: 18.472,
    p97: 18.996,
    p99: 20.086,
    p999: 22.346
  },
  {
    month: 90,
    l: -1.3596,
    m: 15.6023,
    s: 0.09289,
    p01: 12.244,
    p1: 12.909,
    p3: 13.339,
    p5: 13.58,
    p10: 13.972,
    p15: 14.253,
    p25: 14.692,
    p50: 15.602,
    p75: 16.658,
    p85: 17.298,
    p90: 17.766,
    p95: 18.517,
    p97: 19.047,
    p99: 20.151,
    p999: 22.45
  },
  {
    month: 91,
    l: -1.3776,
    m: 15.6237,
    s: 0.09327,
    p01: 12.257,
    p1: 12.922,
    p3: 13.352,
    p5: 13.594,
    p10: 13.987,
    p15: 14.268,
    p25: 14.709,
    p50: 15.624,
    p75: 16.686,
    p85: 17.332,
    p90: 17.803,
    p95: 18.562,
    p97: 19.098,
    p99: 20.218,
    p999: 22.557
  },
  {
    month: 92,
    l: -1.3953,
    m: 15.6455,
    s: 0.09366,
    p01: 12.269,
    p1: 12.935,
    p3: 13.365,
    p5: 13.608,
    p10: 14.002,
    p15: 14.284,
    p25: 14.726,
    p50: 15.646,
    p75: 16.715,
    p85: 17.366,
    p90: 17.842,
    p95: 18.609,
    p97: 19.151,
    p99: 20.286,
    p999: 22.667
  },
  {
    month: 93,
    l: -1.4126,
    m: 15.6677,
    s: 0.09406,
    p01: 12.281,
    p1: 12.948,
    p3: 13.379,
    p5: 13.622,
    p10: 14.017,
    p15: 14.3,
    p25: 14.744,
    p50: 15.668,
    p75: 16.744,
    p85: 17.4,
    p90: 17.881,
    p95: 18.656,
    p97: 19.205,
    p99: 20.356,
    p999: 22.779
  },
  {
    month: 94,
    l: -1.4297,
    m: 15.6903,
    s: 0.09445,
    p01: 12.294,
    p1: 12.961,
    p3: 13.393,
    p5: 13.636,
    p10: 14.033,
    p15: 14.317,
    p25: 14.762,
    p50: 15.69,
    p75: 16.774,
    p85: 17.435,
    p90: 17.921,
    p95: 18.704,
    p97: 19.259,
    p99: 20.426,
    p999: 22.892
  },
  {
    month: 95,
    l: -1.4464,
    m: 15.7133,
    s: 0.09486,
    p01: 12.307,
    p1: 12.975,
    p3: 13.407,
    p5: 13.651,
    p10: 14.049,
    p15: 14.333,
    p25: 14.781,
    p50: 15.713,
    p75: 16.804,
    p85: 17.471,
    p90: 17.961,
    p95: 18.753,
    p97: 19.315,
    p99: 20.498,
    p999: 23.009
  },
  {
    month: 96,
    l: -1.4629,
    m: 15.7368,
    s: 0.09526,
    p01: 12.32,
    p1: 12.988,
    p3: 13.422,
    p5: 13.666,
    p10: 14.065,
    p15: 14.35,
    p25: 14.799,
    p50: 15.737,
    p75: 16.835,
    p85: 17.508,
    p90: 18.002,
    p95: 18.802,
    p97: 19.371,
    p99: 20.57,
    p999: 23.127
  },
  {
    month: 97,
    l: -1.479,
    m: 15.7606,
    s: 0.09567,
    p01: 12.333,
    p1: 13.002,
    p3: 13.436,
    p5: 13.681,
    p10: 14.081,
    p15: 14.368,
    p25: 14.819,
    p50: 15.761,
    p75: 16.867,
    p85: 17.544,
    p90: 18.044,
    p95: 18.852,
    p97: 19.428,
    p99: 20.645,
    p999: 23.249
  },
  {
    month: 98,
    l: -1.4947,
    m: 15.7848,
    s: 0.09609,
    p01: 12.346,
    p1: 13.016,
    p3: 13.451,
    p5: 13.697,
    p10: 14.098,
    p15: 14.385,
    p25: 14.838,
    p50: 15.785,
    p75: 16.898,
    p85: 17.582,
    p90: 18.086,
    p95: 18.904,
    p97: 19.486,
    p99: 20.72,
    p999: 23.373
  },
  {
    month: 99,
    l: -1.5101,
    m: 15.8094,
    s: 0.09651,
    p01: 12.359,
    p1: 13.03,
    p3: 13.466,
    p5: 13.712,
    p10: 14.115,
    p15: 14.403,
    p25: 14.858,
    p50: 15.809,
    p75: 16.931,
    p85: 17.62,
    p90: 18.129,
    p95: 18.956,
    p97: 19.545,
    p99: 20.797,
    p999: 23.499
  },
  {
    month: 100,
    l: -1.5252,
    m: 15.8344,
    s: 0.09693,
    p01: 12.373,
    p1: 13.045,
    p3: 13.482,
    p5: 13.728,
    p10: 14.132,
    p15: 14.421,
    p25: 14.878,
    p50: 15.834,
    p75: 16.963,
    p85: 17.659,
    p90: 18.172,
    p95: 19.008,
    p97: 19.605,
    p99: 20.874,
    p999: 23.627
  },
  {
    month: 101,
    l: -1.5399,
    m: 15.8597,
    s: 0.09735,
    p01: 12.386,
    p1: 13.059,
    p3: 13.497,
    p5: 13.745,
    p10: 14.149,
    p15: 14.44,
    p25: 14.898,
    p50: 15.86,
    p75: 16.996,
    p85: 17.698,
    p90: 18.216,
    p95: 19.061,
    p97: 19.666,
    p99: 20.953,
    p999: 23.756
  },
  {
    month: 102,
    l: -1.5542,
    m: 15.8855,
    s: 0.09778,
    p01: 12.4,
    p1: 13.074,
    p3: 13.513,
    p5: 13.761,
    p10: 14.167,
    p15: 14.459,
    p25: 14.919,
    p50: 15.886,
    p75: 17.03,
    p85: 17.738,
    p90: 18.261,
    p95: 19.115,
    p97: 19.727,
    p99: 21.033,
    p999: 23.89
  },
  {
    month: 103,
    l: -1.5681,
    m: 15.9116,
    s: 0.09821,
    p01: 12.414,
    p1: 13.089,
    p3: 13.529,
    p5: 13.778,
    p10: 14.185,
    p15: 14.478,
    p25: 14.94,
    p50: 15.912,
    p75: 17.064,
    p85: 17.778,
    p90: 18.306,
    p95: 19.17,
    p97: 19.789,
    p99: 21.114,
    p999: 24.024
  },
  {
    month: 104,
    l: -1.5817,
    m: 15.9381,
    s: 0.09864,
    p01: 12.428,
    p1: 13.104,
    p3: 13.545,
    p5: 13.795,
    p10: 14.203,
    p15: 14.497,
    p25: 14.961,
    p50: 15.938,
    p75: 17.099,
    p85: 17.818,
    p90: 18.352,
    p95: 19.225,
    p97: 19.852,
    p99: 21.196,
    p999: 24.161
  },
  {
    month: 105,
    l: -1.5948,
    m: 15.9651,
    s: 0.09907,
    p01: 12.442,
    p1: 13.12,
    p3: 13.562,
    p5: 13.812,
    p10: 14.222,
    p15: 14.517,
    p25: 14.983,
    p50: 15.965,
    p75: 17.134,
    p85: 17.86,
    p90: 18.399,
    p95: 19.281,
    p97: 19.916,
    p99: 21.278,
    p999: 24.3
  },
  {
    month: 106,
    l: -1.6076,
    m: 15.9925,
    s: 0.09951,
    p01: 12.457,
    p1: 13.135,
    p3: 13.579,
    p5: 13.83,
    p10: 14.241,
    p15: 14.537,
    p25: 15.005,
    p50: 15.992,
    p75: 17.17,
    p85: 17.902,
    p90: 18.446,
    p95: 19.338,
    p97: 19.981,
    p99: 21.363,
    p999: 24.442
  },
  {
    month: 107,
    l: -1.6199,
    m: 16.0205,
    s: 0.09994,
    p01: 12.472,
    p1: 13.152,
    p3: 13.596,
    p5: 13.848,
    p10: 14.26,
    p15: 14.558,
    p25: 15.028,
    p50: 16.02,
    p75: 17.206,
    p85: 17.944,
    p90: 18.493,
    p95: 19.395,
    p97: 20.046,
    p99: 21.448,
    p999: 24.585
  },
  {
    month: 108,
    l: -1.6318,
    m: 16.049,
    s: 0.10038,
    p01: 12.487,
    p1: 13.168,
    p3: 13.614,
    p5: 13.866,
    p10: 14.28,
    p15: 14.579,
    p25: 15.051,
    p50: 16.049,
    p75: 17.243,
    p85: 17.987,
    p90: 18.542,
    p95: 19.453,
    p97: 20.112,
    p99: 21.534,
    p999: 24.731
  },
  {
    month: 109,
    l: -1.6433,
    m: 16.0781,
    s: 0.10082,
    p01: 12.502,
    p1: 13.185,
    p3: 13.632,
    p5: 13.885,
    p10: 14.3,
    p15: 14.6,
    p25: 15.074,
    p50: 16.078,
    p75: 17.28,
    p85: 18.031,
    p90: 18.591,
    p95: 19.513,
    p97: 20.18,
    p99: 21.622,
    p999: 24.879
  },
  {
    month: 110,
    l: -1.6544,
    m: 16.1078,
    s: 0.10126,
    p01: 12.518,
    p1: 13.202,
    p3: 13.65,
    p5: 13.904,
    p10: 14.321,
    p15: 14.622,
    p25: 15.098,
    p50: 16.108,
    p75: 17.319,
    p85: 18.076,
    p90: 18.641,
    p95: 19.573,
    p97: 20.248,
    p99: 21.711,
    p999: 25.029
  },
  {
    month: 111,
    l: -1.6651,
    m: 16.1381,
    s: 0.1017,
    p01: 12.534,
    p1: 13.22,
    p3: 13.669,
    p5: 13.924,
    p10: 14.343,
    p15: 14.645,
    p25: 15.123,
    p50: 16.138,
    p75: 17.357,
    p85: 18.121,
    p90: 18.692,
    p95: 19.634,
    p97: 20.317,
    p99: 21.801,
    p999: 25.182
  },
  {
    month: 112,
    l: -1.6753,
    m: 16.1692,
    s: 0.10214,
    p01: 12.55,
    p1: 13.238,
    p3: 13.689,
    p5: 13.944,
    p10: 14.364,
    p15: 14.668,
    p25: 15.149,
    p50: 16.169,
    p75: 17.397,
    p85: 18.168,
    p90: 18.744,
    p95: 19.696,
    p97: 20.388,
    p99: 21.892,
    p999: 25.337
  },
  {
    month: 113,
    l: -1.6851,
    m: 16.2009,
    s: 0.10259,
    p01: 12.567,
    p1: 13.256,
    p3: 13.708,
    p5: 13.965,
    p10: 14.387,
    p15: 14.691,
    p25: 15.175,
    p50: 16.201,
    p75: 17.438,
    p85: 18.215,
    p90: 18.797,
    p95: 19.759,
    p97: 20.459,
    p99: 21.985,
    p999: 25.495
  },
  {
    month: 114,
    l: -1.6944,
    m: 16.2333,
    s: 0.10303,
    p01: 12.584,
    p1: 13.275,
    p3: 13.729,
    p5: 13.986,
    p10: 14.41,
    p15: 14.716,
    p25: 15.201,
    p50: 16.233,
    p75: 17.479,
    p85: 18.263,
    p90: 18.85,
    p95: 19.823,
    p97: 20.531,
    p99: 22.078,
    p999: 25.654
  },
  {
    month: 115,
    l: -1.7032,
    m: 16.2665,
    s: 0.10347,
    p01: 12.602,
    p1: 13.295,
    p3: 13.75,
    p5: 14.008,
    p10: 14.433,
    p15: 14.741,
    p25: 15.228,
    p50: 16.266,
    p75: 17.521,
    p85: 18.311,
    p90: 18.905,
    p95: 19.887,
    p97: 20.605,
    p99: 22.173,
    p999: 25.814
  },
  {
    month: 116,
    l: -1.7116,
    m: 16.3004,
    s: 0.10391,
    p01: 12.62,
    p1: 13.315,
    p3: 13.771,
    p5: 14.031,
    p10: 14.458,
    p15: 14.766,
    p25: 15.256,
    p50: 16.3,
    p75: 17.564,
    p85: 18.361,
    p90: 18.96,
    p95: 19.953,
    p97: 20.679,
    p99: 22.269,
    p999: 25.977
  },
  {
    month: 117,
    l: -1.7196,
    m: 16.3351,
    s: 0.10435,
    p01: 12.639,
    p1: 13.336,
    p3: 13.794,
    p5: 14.054,
    p10: 14.483,
    p15: 14.793,
    p25: 15.285,
    p50: 16.335,
    p75: 17.608,
    p85: 18.412,
    p90: 19.016,
    p95: 20.02,
    p97: 20.754,
    p99: 22.366,
    p999: 26.142
  },
  {
    month: 118,
    l: -1.7271,
    m: 16.3704,
    s: 0.10478,
    p01: 12.658,
    p1: 13.357,
    p3: 13.816,
    p5: 14.078,
    p10: 14.508,
    p15: 14.82,
    p25: 15.314,
    p50: 16.37,
    p75: 17.652,
    p85: 18.462,
    p90: 19.073,
    p95: 20.087,
    p97: 20.83,
    p99: 22.463,
    p999: 26.306
  },
  {
    month: 119,
    l: -1.7341,
    m: 16.4065,
    s: 0.10522,
    p01: 12.677,
    p1: 13.379,
    p3: 13.84,
    p5: 14.102,
    p10: 14.534,
    p15: 14.847,
    p25: 15.344,
    p50: 16.406,
    p75: 17.697,
    p85: 18.515,
    p90: 19.13,
    p95: 20.155,
    p97: 20.907,
    p99: 22.562,
    p999: 26.474
  },
  {
    month: 120,
    l: -1.7407,
    m: 16.4433,
    s: 0.10566,
    p01: 12.697,
    p1: 13.401,
    p3: 13.863,
    p5: 14.127,
    p10: 14.561,
    p15: 14.875,
    p25: 15.375,
    p50: 16.443,
    p75: 17.743,
    p85: 18.568,
    p90: 19.189,
    p95: 20.225,
    p97: 20.985,
    p99: 22.662,
    p999: 26.644
  },
  {
    month: 121,
    l: -1.7468,
    m: 16.4807,
    s: 0.10609,
    p01: 12.718,
    p1: 13.423,
    p3: 13.888,
    p5: 14.152,
    p10: 14.588,
    p15: 14.904,
    p25: 15.406,
    p50: 16.481,
    p75: 17.79,
    p85: 18.621,
    p90: 19.248,
    p95: 20.294,
    p97: 21.063,
    p99: 22.762,
    p999: 26.814
  },
  {
    month: 122,
    l: -1.7525,
    m: 16.5189,
    s: 0.10652,
    p01: 12.738,
    p1: 13.446,
    p3: 13.913,
    p5: 14.178,
    p10: 14.616,
    p15: 14.933,
    p25: 15.438,
    p50: 16.519,
    p75: 17.837,
    p85: 18.675,
    p90: 19.308,
    p95: 20.365,
    p97: 21.142,
    p99: 22.864,
    p999: 26.985
  },
  {
    month: 123,
    l: -1.7578,
    m: 16.5578,
    s: 0.10695,
    p01: 12.76,
    p1: 13.47,
    p3: 13.938,
    p5: 14.205,
    p10: 14.644,
    p15: 14.963,
    p25: 15.471,
    p50: 16.558,
    p75: 17.886,
    p85: 18.73,
    p90: 19.369,
    p95: 20.436,
    p97: 21.223,
    p99: 22.966,
    p999: 27.158
  },
  {
    month: 124,
    l: -1.7626,
    m: 16.5974,
    s: 0.10738,
    p01: 12.781,
    p1: 13.494,
    p3: 13.964,
    p5: 14.232,
    p10: 14.673,
    p15: 14.994,
    p25: 15.504,
    p50: 16.597,
    p75: 17.935,
    p85: 18.786,
    p90: 19.431,
    p95: 20.509,
    p97: 21.304,
    p99: 23.069,
    p999: 27.332
  },
  {
    month: 125,
    l: -1.767,
    m: 16.6376,
    s: 0.1078,
    p01: 12.803,
    p1: 13.519,
    p3: 13.99,
    p5: 14.259,
    p10: 14.703,
    p15: 15.025,
    p25: 15.538,
    p50: 16.638,
    p75: 17.984,
    p85: 18.843,
    p90: 19.493,
    p95: 20.581,
    p97: 21.385,
    p99: 23.173,
    p999: 27.505
  },
  {
    month: 126,
    l: -1.771,
    m: 16.6786,
    s: 0.10823,
    p01: 12.826,
    p1: 13.544,
    p3: 14.017,
    p5: 14.287,
    p10: 14.733,
    p15: 15.056,
    p25: 15.572,
    p50: 16.679,
    p75: 18.035,
    p85: 18.9,
    p90: 19.556,
    p95: 20.656,
    p97: 21.468,
    p99: 23.278,
    p999: 27.682
  },
  {
    month: 127,
    l: -1.7745,
    m: 16.7203,
    s: 0.10865,
    p01: 12.849,
    p1: 13.569,
    p3: 14.045,
    p5: 14.316,
    p10: 14.764,
    p15: 15.089,
    p25: 15.607,
    p50: 16.72,
    p75: 18.086,
    p85: 18.958,
    p90: 19.62,
    p95: 20.73,
    p97: 21.551,
    p99: 23.383,
    p999: 27.857
  },
  {
    month: 128,
    l: -1.7777,
    m: 16.7628,
    s: 0.10906,
    p01: 12.872,
    p1: 13.596,
    p3: 14.073,
    p5: 14.346,
    p10: 14.796,
    p15: 15.122,
    p25: 15.643,
    p50: 16.763,
    p75: 18.138,
    p85: 19.017,
    p90: 19.685,
    p95: 20.805,
    p97: 21.635,
    p99: 23.488,
    p999: 28.032
  },
  {
    month: 129,
    l: -1.7804,
    m: 16.8059,
    s: 0.10948,
    p01: 12.896,
    p1: 13.622,
    p3: 14.102,
    p5: 14.376,
    p10: 14.828,
    p15: 15.156,
    p25: 15.679,
    p50: 16.806,
    p75: 18.191,
    p85: 19.077,
    p90: 19.75,
    p95: 20.881,
    p97: 21.72,
    p99: 23.595,
    p999: 28.21
  },
  {
    month: 130,
    l: -1.7828,
    m: 16.8497,
    s: 0.10989,
    p01: 12.92,
    p1: 13.649,
    p3: 14.131,
    p5: 14.406,
    p10: 14.86,
    p15: 15.19,
    p25: 15.717,
    p50: 16.85,
    p75: 18.244,
    p85: 19.137,
    p90: 19.816,
    p95: 20.958,
    p97: 21.805,
    p99: 23.702,
    p999: 28.387
  },
  {
    month: 131,
    l: -1.7847,
    m: 16.8941,
    s: 0.1103,
    p01: 12.945,
    p1: 13.677,
    p3: 14.161,
    p5: 14.437,
    p10: 14.893,
    p15: 15.225,
    p25: 15.754,
    p50: 16.894,
    p75: 18.298,
    p85: 19.198,
    p90: 19.883,
    p95: 21.035,
    p97: 21.891,
    p99: 23.81,
    p999: 28.564
  },
  {
    month: 132,
    l: -1.7862,
    m: 16.9392,
    s: 0.1107,
    p01: 12.97,
    p1: 13.705,
    p3: 14.191,
    p5: 14.469,
    p10: 14.927,
    p15: 15.26,
    p25: 15.793,
    p50: 16.939,
    p75: 18.353,
    p85: 19.26,
    p90: 19.95,
    p95: 21.113,
    p97: 21.977,
    p99: 23.917,
    p999: 28.74
  },
  {
    month: 133,
    l: -1.7873,
    m: 16.985,
    s: 0.1111,
    p01: 12.996,
    p1: 13.734,
    p3: 14.222,
    p5: 14.501,
    p10: 14.962,
    p15: 15.296,
    p25: 15.832,
    p50: 16.985,
    p75: 18.408,
    p85: 19.322,
    p90: 20.018,
    p95: 21.191,
    p97: 22.064,
    p99: 24.025,
    p999: 28.916
  },
  {
    month: 134,
    l: -1.7881,
    m: 17.0314,
    s: 0.1115,
    p01: 13.022,
    p1: 13.763,
    p3: 14.253,
    p5: 14.533,
    p10: 14.996,
    p15: 15.333,
    p25: 15.871,
    p50: 17.031,
    p75: 18.464,
    p85: 19.385,
    p90: 20.087,
    p95: 21.27,
    p97: 22.151,
    p99: 24.134,
    p999: 29.093
  },
  {
    month: 135,
    l: -1.7884,
    m: 17.0784,
    s: 0.11189,
    p01: 13.048,
    p1: 13.792,
    p3: 14.285,
    p5: 14.566,
    p10: 15.032,
    p15: 15.37,
    p25: 15.911,
    p50: 17.078,
    p75: 18.521,
    p85: 19.449,
    p90: 20.156,
    p95: 21.35,
    p97: 22.239,
    p99: 24.242,
    p999: 29.267
  },
  {
    month: 136,
    l: -1.7884,
    m: 17.1262,
    s: 0.11228,
    p01: 13.075,
    p1: 13.822,
    p3: 14.317,
    p5: 14.6,
    p10: 15.068,
    p15: 15.408,
    p25: 15.952,
    p50: 17.126,
    p75: 18.578,
    p85: 19.513,
    p90: 20.226,
    p95: 21.43,
    p97: 22.327,
    p99: 24.351,
    p999: 29.442
  },
  {
    month: 137,
    l: -1.788,
    m: 17.1746,
    s: 0.11266,
    p01: 13.102,
    p1: 13.853,
    p3: 14.35,
    p5: 14.634,
    p10: 15.105,
    p15: 15.447,
    p25: 15.993,
    p50: 17.175,
    p75: 18.636,
    p85: 19.578,
    p90: 20.296,
    p95: 21.51,
    p97: 22.416,
    p99: 24.46,
    p999: 29.614
  },
  {
    month: 138,
    l: -1.7873,
    m: 17.2236,
    s: 0.11304,
    p01: 13.13,
    p1: 13.884,
    p3: 14.383,
    p5: 14.669,
    p10: 15.142,
    p15: 15.486,
    p25: 16.035,
    p50: 17.224,
    p75: 18.695,
    p85: 19.643,
    p90: 20.367,
    p95: 21.591,
    p97: 22.505,
    p99: 24.569,
    p999: 29.787
  },
  {
    month: 139,
    l: -1.7861,
    m: 17.2734,
    s: 0.11342,
    p01: 13.157,
    p1: 13.915,
    p3: 14.417,
    p5: 14.704,
    p10: 15.179,
    p15: 15.525,
    p25: 16.078,
    p50: 17.273,
    p75: 18.754,
    p85: 19.71,
    p90: 20.439,
    p95: 21.673,
    p97: 22.595,
    p99: 24.679,
    p999: 29.959
  },
  {
    month: 140,
    l: -1.7846,
    m: 17.324,
    s: 0.11379,
    p01: 13.186,
    p1: 13.947,
    p3: 14.452,
    p5: 14.741,
    p10: 15.218,
    p15: 15.566,
    p25: 16.122,
    p50: 17.324,
    p75: 18.815,
    p85: 19.777,
    p90: 20.512,
    p95: 21.756,
    p97: 22.685,
    p99: 24.789,
    p999: 30.129
  },
  {
    month: 141,
    l: -1.7828,
    m: 17.3752,
    s: 0.11415,
    p01: 13.215,
    p1: 13.98,
    p3: 14.487,
    p5: 14.777,
    p10: 15.257,
    p15: 15.607,
    p25: 16.166,
    p50: 17.375,
    p75: 18.875,
    p85: 19.844,
    p90: 20.585,
    p95: 21.838,
    p97: 22.775,
    p99: 24.898,
    p999: 30.297
  },
  {
    month: 142,
    l: -1.7806,
    m: 17.4272,
    s: 0.11451,
    p01: 13.245,
    p1: 14.013,
    p3: 14.523,
    p5: 14.814,
    p10: 15.297,
    p15: 15.648,
    p25: 16.211,
    p50: 17.427,
    p75: 18.937,
    p85: 19.913,
    p90: 20.658,
    p95: 21.922,
    p97: 22.866,
    p99: 25.008,
    p999: 30.464
  },
  {
    month: 143,
    l: -1.778,
    m: 17.4799,
    s: 0.11487,
    p01: 13.275,
    p1: 14.047,
    p3: 14.559,
    p5: 14.852,
    p10: 15.337,
    p15: 15.691,
    p25: 16.256,
    p50: 17.48,
    p75: 19,
    p85: 19.982,
    p90: 20.733,
    p95: 22.006,
    p97: 22.958,
    p99: 25.118,
    p999: 30.63
  },
  {
    month: 144,
    l: -1.7751,
    m: 17.5334,
    s: 0.11522,
    p01: 13.305,
    p1: 14.081,
    p3: 14.596,
    p5: 14.891,
    p10: 15.378,
    p15: 15.734,
    p25: 16.302,
    p50: 17.533,
    p75: 19.063,
    p85: 20.052,
    p90: 20.808,
    p95: 22.09,
    p97: 23.05,
    p99: 25.228,
    p999: 30.794
  },
  {
    month: 145,
    l: -1.7719,
    m: 17.5877,
    s: 0.11556,
    p01: 13.337,
    p1: 14.116,
    p3: 14.634,
    p5: 14.93,
    p10: 15.42,
    p15: 15.778,
    p25: 16.349,
    p50: 17.588,
    p75: 19.127,
    p85: 20.122,
    p90: 20.884,
    p95: 22.175,
    p97: 23.142,
    p99: 25.338,
    p999: 30.955
  },
  {
    month: 146,
    l: -1.7684,
    m: 17.6427,
    s: 0.1159,
    p01: 13.368,
    p1: 14.152,
    p3: 14.672,
    p5: 14.97,
    p10: 15.463,
    p15: 15.822,
    p25: 16.397,
    p50: 17.643,
    p75: 19.191,
    p85: 20.193,
    p90: 20.96,
    p95: 22.261,
    p97: 23.235,
    p99: 25.448,
    p999: 31.116
  },
  {
    month: 147,
    l: -1.7645,
    m: 17.6985,
    s: 0.11623,
    p01: 13.4,
    p1: 14.188,
    p3: 14.711,
    p5: 15.01,
    p10: 15.506,
    p15: 15.867,
    p25: 16.446,
    p50: 17.698,
    p75: 19.257,
    p85: 20.265,
    p90: 21.037,
    p95: 22.347,
    p97: 23.328,
    p99: 25.558,
    p999: 31.273
  },
  {
    month: 148,
    l: -1.7604,
    m: 17.7551,
    s: 0.11656,
    p01: 13.433,
    p1: 14.225,
    p3: 14.751,
    p5: 15.051,
    p10: 15.55,
    p15: 15.913,
    p25: 16.495,
    p50: 17.755,
    p75: 19.323,
    p85: 20.338,
    p90: 21.115,
    p95: 22.434,
    p97: 23.422,
    p99: 25.668,
    p999: 31.43
  },
  {
    month: 149,
    l: -1.7559,
    m: 17.8124,
    s: 0.11688,
    p01: 13.466,
    p1: 14.262,
    p3: 14.791,
    p5: 15.093,
    p10: 15.594,
    p15: 15.96,
    p25: 16.545,
    p50: 17.812,
    p75: 19.39,
    p85: 20.411,
    p90: 21.194,
    p95: 22.521,
    p97: 23.516,
    p99: 25.778,
    p999: 31.584
  },
  {
    month: 150,
    l: -1.7511,
    m: 17.8704,
    s: 0.1172,
    p01: 13.5,
    p1: 14.3,
    p3: 14.831,
    p5: 15.136,
    p10: 15.64,
    p15: 16.007,
    p25: 16.595,
    p50: 17.87,
    p75: 19.457,
    p85: 20.486,
    p90: 21.273,
    p95: 22.609,
    p97: 23.61,
    p99: 25.888,
    p999: 31.736
  },
  {
    month: 151,
    l: -1.7461,
    m: 17.9292,
    s: 0.11751,
    p01: 13.534,
    p1: 14.338,
    p3: 14.873,
    p5: 15.179,
    p10: 15.685,
    p15: 16.055,
    p25: 16.646,
    p50: 17.929,
    p75: 19.526,
    p85: 20.56,
    p90: 21.352,
    p95: 22.697,
    p97: 23.705,
    p99: 25.998,
    p999: 31.886
  },
  {
    month: 152,
    l: -1.7408,
    m: 17.9887,
    s: 0.11781,
    p01: 13.569,
    p1: 14.377,
    p3: 14.915,
    p5: 15.222,
    p10: 15.732,
    p15: 16.103,
    p25: 16.698,
    p50: 17.989,
    p75: 19.595,
    p85: 20.636,
    p90: 21.433,
    p95: 22.785,
    p97: 23.8,
    p99: 26.107,
    p999: 32.033
  },
  {
    month: 153,
    l: -1.7352,
    m: 18.0488,
    s: 0.11811,
    p01: 13.604,
    p1: 14.417,
    p3: 14.957,
    p5: 15.266,
    p10: 15.779,
    p15: 16.153,
    p25: 16.751,
    p50: 18.049,
    p75: 19.665,
    p85: 20.711,
    p90: 21.513,
    p95: 22.874,
    p97: 23.895,
    p99: 26.216,
    p999: 32.178
  },
  {
    month: 154,
    l: -1.7293,
    m: 18.1096,
    s: 0.11841,
    p01: 13.639,
    p1: 14.457,
    p3: 15,
    p5: 15.311,
    p10: 15.827,
    p15: 16.202,
    p25: 16.804,
    p50: 18.11,
    p75: 19.735,
    p85: 20.788,
    p90: 21.595,
    p95: 22.964,
    p97: 23.991,
    p99: 26.326,
    p999: 32.322
  },
  {
    month: 155,
    l: -1.7232,
    m: 18.171,
    s: 0.11869,
    p01: 13.675,
    p1: 14.497,
    p3: 15.044,
    p5: 15.357,
    p10: 15.875,
    p15: 16.253,
    p25: 16.858,
    p50: 18.171,
    p75: 19.806,
    p85: 20.865,
    p90: 21.676,
    p95: 23.053,
    p97: 24.086,
    p99: 26.434,
    p999: 32.46
  },
  {
    month: 156,
    l: -1.7168,
    m: 18.233,
    s: 0.11898,
    p01: 13.711,
    p1: 14.538,
    p3: 15.087,
    p5: 15.402,
    p10: 15.923,
    p15: 16.303,
    p25: 16.912,
    p50: 18.233,
    p75: 19.877,
    p85: 20.943,
    p90: 21.759,
    p95: 23.144,
    p97: 24.182,
    p99: 26.543,
    p999: 32.6
  },
  {
    month: 157,
    l: -1.7102,
    m: 18.2955,
    s: 0.11925,
    p01: 13.748,
    p1: 14.58,
    p3: 15.132,
    p5: 15.448,
    p10: 15.973,
    p15: 16.355,
    p25: 16.967,
    p50: 18.296,
    p75: 19.949,
    p85: 21.021,
    p90: 21.841,
    p95: 23.234,
    p97: 24.278,
    p99: 26.65,
    p999: 32.733
  },
  {
    month: 158,
    l: -1.7033,
    m: 18.3586,
    s: 0.11952,
    p01: 13.785,
    p1: 14.621,
    p3: 15.177,
    p5: 15.495,
    p10: 16.023,
    p15: 16.407,
    p25: 17.023,
    p50: 18.359,
    p75: 20.022,
    p85: 21.099,
    p90: 21.924,
    p95: 23.324,
    p97: 24.373,
    p99: 26.758,
    p999: 32.865
  },
  {
    month: 159,
    l: -1.6962,
    m: 18.4221,
    s: 0.11979,
    p01: 13.822,
    p1: 14.663,
    p3: 15.222,
    p5: 15.542,
    p10: 16.073,
    p15: 16.459,
    p25: 17.079,
    p50: 18.422,
    p75: 20.095,
    p85: 21.178,
    p90: 22.008,
    p95: 23.415,
    p97: 24.47,
    p99: 26.865,
    p999: 32.996
  },
  {
    month: 160,
    l: -1.6888,
    m: 18.486,
    s: 0.12005,
    p01: 13.86,
    p1: 14.706,
    p3: 15.268,
    p5: 15.59,
    p10: 16.123,
    p15: 16.512,
    p25: 17.135,
    p50: 18.486,
    p75: 20.168,
    p85: 21.257,
    p90: 22.091,
    p95: 23.505,
    p97: 24.565,
    p99: 26.972,
    p999: 33.122
  },
  {
    month: 161,
    l: -1.6811,
    m: 18.5502,
    s: 0.1203,
    p01: 13.897,
    p1: 14.748,
    p3: 15.313,
    p5: 15.637,
    p10: 16.174,
    p15: 16.565,
    p25: 17.192,
    p50: 18.55,
    p75: 20.241,
    p85: 21.336,
    p90: 22.174,
    p95: 23.596,
    p97: 24.66,
    p99: 27.077,
    p999: 33.243
  },
  {
    month: 162,
    l: -1.6732,
    m: 18.6148,
    s: 0.12055,
    p01: 13.935,
    p1: 14.791,
    p3: 15.36,
    p5: 15.685,
    p10: 16.225,
    p15: 16.618,
    p25: 17.248,
    p50: 18.615,
    p75: 20.315,
    p85: 21.416,
    p90: 22.258,
    p95: 23.686,
    p97: 24.755,
    p99: 27.181,
    p999: 33.364
  },
  {
    month: 163,
    l: -1.6651,
    m: 18.6795,
    s: 0.12079,
    p01: 13.973,
    p1: 14.834,
    p3: 15.406,
    p5: 15.733,
    p10: 16.276,
    p15: 16.672,
    p25: 17.306,
    p50: 18.68,
    p75: 20.389,
    p85: 21.495,
    p90: 22.341,
    p95: 23.776,
    p97: 24.85,
    p99: 27.285,
    p999: 33.48
  },
  {
    month: 164,
    l: -1.6568,
    m: 18.7445,
    s: 0.12102,
    p01: 14.011,
    p1: 14.877,
    p3: 15.452,
    p5: 15.782,
    p10: 16.328,
    p15: 16.726,
    p25: 17.363,
    p50: 18.744,
    p75: 20.463,
    p85: 21.574,
    p90: 22.425,
    p95: 23.866,
    p97: 24.944,
    p99: 27.387,
    p999: 33.592
  },
  {
    month: 165,
    l: -1.6482,
    m: 18.8095,
    s: 0.12125,
    p01: 14.049,
    p1: 14.92,
    p3: 15.499,
    p5: 15.83,
    p10: 16.379,
    p15: 16.779,
    p25: 17.42,
    p50: 18.81,
    p75: 20.537,
    p85: 21.654,
    p90: 22.508,
    p95: 23.955,
    p97: 25.037,
    p99: 27.488,
    p999: 33.701
  },
  {
    month: 166,
    l: -1.6394,
    m: 18.8746,
    s: 0.12148,
    p01: 14.087,
    p1: 14.963,
    p3: 15.545,
    p5: 15.879,
    p10: 16.431,
    p15: 16.833,
    p25: 17.478,
    p50: 18.875,
    p75: 20.611,
    p85: 21.733,
    p90: 22.591,
    p95: 24.044,
    p97: 25.131,
    p99: 27.589,
    p999: 33.809
  },
  {
    month: 167,
    l: -1.6304,
    m: 18.9398,
    s: 0.1217,
    p01: 14.125,
    p1: 15.007,
    p3: 15.592,
    p5: 15.927,
    p10: 16.483,
    p15: 16.887,
    p25: 17.535,
    p50: 18.94,
    p75: 20.685,
    p85: 21.812,
    p90: 22.674,
    p95: 24.133,
    p97: 25.223,
    p99: 27.688,
    p999: 33.913
  },
  {
    month: 168,
    l: -1.6211,
    m: 19.005,
    s: 0.12191,
    p01: 14.163,
    p1: 15.05,
    p3: 15.639,
    p5: 15.976,
    p10: 16.534,
    p15: 16.941,
    p25: 17.593,
    p50: 19.005,
    p75: 20.758,
    p85: 21.891,
    p90: 22.757,
    p95: 24.221,
    p97: 25.315,
    p99: 27.786,
    p999: 34.011
  },
  {
    month: 169,
    l: -1.6116,
    m: 19.0701,
    s: 0.12212,
    p01: 14.201,
    p1: 15.093,
    p3: 15.685,
    p5: 16.024,
    p10: 16.586,
    p15: 16.995,
    p25: 17.651,
    p50: 19.07,
    p75: 20.832,
    p85: 21.97,
    p90: 22.839,
    p95: 24.309,
    p97: 25.406,
    p99: 27.882,
    p999: 34.107
  },
  {
    month: 170,
    l: -1.602,
    m: 19.1351,
    s: 0.12233,
    p01: 14.239,
    p1: 15.136,
    p3: 15.731,
    p5: 16.073,
    p10: 16.638,
    p15: 17.049,
    p25: 17.708,
    p50: 19.135,
    p75: 20.906,
    p85: 22.049,
    p90: 22.921,
    p95: 24.396,
    p97: 25.496,
    p99: 27.979,
    p999: 34.202
  },
  {
    month: 171,
    l: -1.5921,
    m: 19.2,
    s: 0.12253,
    p01: 14.276,
    p1: 15.178,
    p3: 15.778,
    p5: 16.121,
    p10: 16.689,
    p15: 17.103,
    p25: 17.766,
    p50: 19.2,
    p75: 20.979,
    p85: 22.127,
    p90: 23.003,
    p95: 24.482,
    p97: 25.586,
    p99: 28.073,
    p999: 34.292
  },
  {
    month: 172,
    l: -1.5821,
    m: 19.2648,
    s: 0.12272,
    p01: 14.314,
    p1: 15.221,
    p3: 15.824,
    p5: 16.169,
    p10: 16.741,
    p15: 17.157,
    p25: 17.823,
    p50: 19.265,
    p75: 21.052,
    p85: 22.204,
    p90: 23.084,
    p95: 24.568,
    p97: 25.674,
    p99: 28.165,
    p999: 34.378
  },
  {
    month: 173,
    l: -1.5719,
    m: 19.3294,
    s: 0.12291,
    p01: 14.351,
    p1: 15.264,
    p3: 15.87,
    p5: 16.217,
    p10: 16.792,
    p15: 17.21,
    p25: 17.88,
    p50: 19.329,
    p75: 21.125,
    p85: 22.282,
    p90: 23.164,
    p95: 24.653,
    p97: 25.762,
    p99: 28.257,
    p999: 34.462
  },
  {
    month: 174,
    l: -1.5615,
    m: 19.3937,
    s: 0.1231,
    p01: 14.387,
    p1: 15.306,
    p3: 15.916,
    p5: 16.265,
    p10: 16.843,
    p15: 17.264,
    p25: 17.937,
    p50: 19.394,
    p75: 21.197,
    p85: 22.359,
    p90: 23.244,
    p95: 24.738,
    p97: 25.849,
    p99: 28.347,
    p999: 34.544
  },
  {
    month: 175,
    l: -1.551,
    m: 19.4578,
    s: 0.12328,
    p01: 14.424,
    p1: 15.348,
    p3: 15.961,
    p5: 16.313,
    p10: 16.894,
    p15: 17.317,
    p25: 17.994,
    p50: 19.458,
    p75: 21.269,
    p85: 22.436,
    p90: 23.324,
    p95: 24.822,
    p97: 25.935,
    p99: 28.436,
    p999: 34.622
  },
  {
    month: 176,
    l: -1.5403,
    m: 19.5217,
    s: 0.12346,
    p01: 14.46,
    p1: 15.39,
    p3: 16.007,
    p5: 16.36,
    p10: 16.944,
    p15: 17.37,
    p25: 18.051,
    p50: 19.522,
    p75: 21.341,
    p85: 22.512,
    p90: 23.403,
    p95: 24.905,
    p97: 26.021,
    p99: 28.524,
    p999: 34.698
  },
  {
    month: 177,
    l: -1.5294,
    m: 19.5853,
    s: 0.12363,
    p01: 14.497,
    p1: 15.432,
    p3: 16.052,
    p5: 16.407,
    p10: 16.995,
    p15: 17.423,
    p25: 18.107,
    p50: 19.585,
    p75: 21.413,
    p85: 22.587,
    p90: 23.482,
    p95: 24.987,
    p97: 26.105,
    p99: 28.609,
    p999: 34.769
  },
  {
    month: 178,
    l: -1.5185,
    m: 19.6486,
    s: 0.1238,
    p01: 14.532,
    p1: 15.473,
    p3: 16.097,
    p5: 16.454,
    p10: 17.045,
    p15: 17.475,
    p25: 18.163,
    p50: 19.649,
    p75: 21.484,
    p85: 22.663,
    p90: 23.56,
    p95: 25.069,
    p97: 26.189,
    p99: 28.695,
    p999: 34.84
  },
  {
    month: 179,
    l: -1.5074,
    m: 19.7117,
    s: 0.12396,
    p01: 14.568,
    p1: 15.514,
    p3: 16.142,
    p5: 16.501,
    p10: 17.095,
    p15: 17.528,
    p25: 18.219,
    p50: 19.712,
    p75: 21.554,
    p85: 22.737,
    p90: 23.637,
    p95: 25.149,
    p97: 26.271,
    p99: 28.778,
    p999: 34.906
  },
  {
    month: 180,
    l: -1.4961,
    m: 19.7744,
    s: 0.12412,
    p01: 14.603,
    p1: 15.555,
    p3: 16.186,
    p5: 16.547,
    p10: 17.145,
    p15: 17.58,
    p25: 18.275,
    p50: 19.774,
    p75: 21.625,
    p85: 22.812,
    p90: 23.714,
    p95: 25.229,
    p97: 26.352,
    p99: 28.86,
    p999: 34.97
  },
  {
    month: 181,
    l: -1.4848,
    m: 19.8367,
    s: 0.12428,
    p01: 14.638,
    p1: 15.595,
    p3: 16.23,
    p5: 16.593,
    p10: 17.194,
    p15: 17.631,
    p25: 18.33,
    p50: 19.837,
    p75: 21.694,
    p85: 22.885,
    p90: 23.79,
    p95: 25.309,
    p97: 26.433,
    p99: 28.941,
    p999: 35.034
  },
  {
    month: 182,
    l: -1.4733,
    m: 19.8987,
    s: 0.12443,
    p01: 14.673,
    p1: 15.636,
    p3: 16.274,
    p5: 16.639,
    p10: 17.243,
    p15: 17.683,
    p25: 18.385,
    p50: 19.899,
    p75: 21.764,
    p85: 22.958,
    p90: 23.865,
    p95: 25.387,
    p97: 26.513,
    p99: 29.021,
    p999: 35.093
  },
  {
    month: 183,
    l: -1.4617,
    m: 19.9603,
    s: 0.12458,
    p01: 14.707,
    p1: 15.676,
    p3: 16.318,
    p5: 16.685,
    p10: 17.292,
    p15: 17.734,
    p25: 18.439,
    p50: 19.96,
    p75: 21.832,
    p85: 23.031,
    p90: 23.94,
    p95: 25.465,
    p97: 26.592,
    p99: 29.099,
    p999: 35.151
  },
  {
    month: 184,
    l: -1.45,
    m: 20.0215,
    s: 0.12473,
    p01: 14.741,
    p1: 15.715,
    p3: 16.361,
    p5: 16.73,
    p10: 17.34,
    p15: 17.784,
    p25: 18.494,
    p50: 20.022,
    p75: 21.901,
    p85: 23.103,
    p90: 24.014,
    p95: 25.542,
    p97: 26.67,
    p99: 29.176,
    p999: 35.208
  },
  {
    month: 185,
    l: -1.4382,
    m: 20.0823,
    s: 0.12487,
    p01: 14.774,
    p1: 15.754,
    p3: 16.404,
    p5: 16.775,
    p10: 17.389,
    p15: 17.835,
    p25: 18.548,
    p50: 20.082,
    p75: 21.969,
    p85: 23.174,
    p90: 24.088,
    p95: 25.618,
    p97: 26.747,
    p99: 29.252,
    p999: 35.261
  },
  {
    month: 186,
    l: -1.4263,
    m: 20.1427,
    s: 0.12501,
    p01: 14.807,
    p1: 15.793,
    p3: 16.446,
    p5: 16.819,
    p10: 17.436,
    p15: 17.885,
    p25: 18.601,
    p50: 20.143,
    p75: 22.036,
    p85: 23.245,
    p90: 24.161,
    p95: 25.693,
    p97: 26.823,
    p99: 29.326,
    p999: 35.313
  },
  {
    month: 187,
    l: -1.4143,
    m: 20.2026,
    s: 0.12514,
    p01: 14.84,
    p1: 15.832,
    p3: 16.488,
    p5: 16.864,
    p10: 17.484,
    p15: 17.935,
    p25: 18.654,
    p50: 20.203,
    p75: 22.103,
    p85: 23.315,
    p90: 24.233,
    p95: 25.767,
    p97: 26.897,
    p99: 29.399,
    p999: 35.36
  },
  {
    month: 188,
    l: -1.4022,
    m: 20.2621,
    s: 0.12528,
    p01: 14.872,
    p1: 15.869,
    p3: 16.53,
    p5: 16.907,
    p10: 17.531,
    p15: 17.984,
    p25: 18.707,
    p50: 20.262,
    p75: 22.169,
    p85: 23.385,
    p90: 24.304,
    p95: 25.841,
    p97: 26.971,
    p99: 29.472,
    p999: 35.41
  },
  {
    month: 189,
    l: -1.39,
    m: 20.3211,
    s: 0.12541,
    p01: 14.904,
    p1: 15.907,
    p3: 16.571,
    p5: 16.95,
    p10: 17.577,
    p15: 18.032,
    p25: 18.759,
    p50: 20.321,
    p75: 22.235,
    p85: 23.453,
    p90: 24.375,
    p95: 25.913,
    p97: 27.044,
    p99: 29.542,
    p999: 35.455
  },
  {
    month: 190,
    l: -1.3777,
    m: 20.3796,
    s: 0.12554,
    p01: 14.935,
    p1: 15.944,
    p3: 16.612,
    p5: 16.993,
    p10: 17.623,
    p15: 18.081,
    p25: 18.811,
    p50: 20.38,
    p75: 22.3,
    p85: 23.522,
    p90: 24.445,
    p95: 25.985,
    p97: 27.116,
    p99: 29.612,
    p999: 35.499
  },
  {
    month: 191,
    l: -1.3653,
    m: 20.4376,
    s: 0.12567,
    p01: 14.966,
    p1: 15.981,
    p3: 16.652,
    p5: 17.035,
    p10: 17.669,
    p15: 18.129,
    p25: 18.862,
    p50: 20.438,
    p75: 22.364,
    p85: 23.589,
    p90: 24.514,
    p95: 26.056,
    p97: 27.188,
    p99: 29.68,
    p999: 35.542
  },
  {
    month: 192,
    l: -1.3529,
    m: 20.4951,
    s: 0.12579,
    p01: 14.997,
    p1: 16.017,
    p3: 16.692,
    p5: 17.078,
    p10: 17.714,
    p15: 18.176,
    p25: 18.913,
    p50: 20.495,
    p75: 22.428,
    p85: 23.656,
    p90: 24.582,
    p95: 26.126,
    p97: 27.258,
    p99: 29.747,
    p999: 35.582
  },
  {
    month: 193,
    l: -1.3403,
    m: 20.5521,
    s: 0.12591,
    p01: 15.027,
    p1: 16.053,
    p3: 16.732,
    p5: 17.119,
    p10: 17.759,
    p15: 18.223,
    p25: 18.964,
    p50: 20.552,
    p75: 22.491,
    p85: 23.722,
    p90: 24.65,
    p95: 26.195,
    p97: 27.326,
    p99: 29.813,
    p999: 35.62
  },
  {
    month: 194,
    l: -1.3277,
    m: 20.6085,
    s: 0.12603,
    p01: 15.056,
    p1: 16.088,
    p3: 16.77,
    p5: 17.16,
    p10: 17.803,
    p15: 18.27,
    p25: 19.014,
    p50: 20.608,
    p75: 22.554,
    p85: 23.787,
    p90: 24.717,
    p95: 26.263,
    p97: 27.395,
    p99: 29.877,
    p999: 35.658
  },
  {
    month: 195,
    l: -1.3149,
    m: 20.6644,
    s: 0.12615,
    p01: 15.085,
    p1: 16.123,
    p3: 16.809,
    p5: 17.2,
    p10: 17.847,
    p15: 18.316,
    p25: 19.063,
    p50: 20.664,
    p75: 22.616,
    p85: 23.852,
    p90: 24.783,
    p95: 26.33,
    p97: 27.462,
    p99: 29.941,
    p999: 35.693
  },
  {
    month: 196,
    l: -1.3021,
    m: 20.7197,
    s: 0.12627,
    p01: 15.113,
    p1: 16.157,
    p3: 16.847,
    p5: 17.24,
    p10: 17.89,
    p15: 18.361,
    p25: 19.112,
    p50: 20.72,
    p75: 22.677,
    p85: 23.916,
    p90: 24.849,
    p95: 26.397,
    p97: 27.528,
    p99: 30.003,
    p999: 35.728
  },
  {
    month: 197,
    l: -1.2892,
    m: 20.7745,
    s: 0.12638,
    p01: 15.141,
    p1: 16.191,
    p3: 16.884,
    p5: 17.28,
    p10: 17.933,
    p15: 18.406,
    p25: 19.161,
    p50: 20.774,
    p75: 22.738,
    p85: 23.979,
    p90: 24.913,
    p95: 26.462,
    p97: 27.593,
    p99: 30.064,
    p999: 35.759
  },
  {
    month: 198,
    l: -1.2762,
    m: 20.8287,
    s: 0.1265,
    p01: 15.168,
    p1: 16.224,
    p3: 16.921,
    p5: 17.319,
    p10: 17.975,
    p15: 18.451,
    p25: 19.208,
    p50: 20.829,
    p75: 22.798,
    p85: 24.042,
    p90: 24.977,
    p95: 26.527,
    p97: 27.657,
    p99: 30.124,
    p999: 35.792
  },
  {
    month: 199,
    l: -1.2631,
    m: 20.8824,
    s: 0.12661,
    p01: 15.195,
    p1: 16.257,
    p3: 16.958,
    p5: 17.357,
    p10: 18.017,
    p15: 18.495,
    p25: 19.256,
    p50: 20.882,
    p75: 22.857,
    p85: 24.104,
    p90: 25.04,
    p95: 26.591,
    p97: 27.72,
    p99: 30.182,
    p999: 35.821
  },
  {
    month: 200,
    l: -1.2499,
    m: 20.9355,
    s: 0.12672,
    p01: 15.221,
    p1: 16.289,
    p3: 16.994,
    p5: 17.395,
    p10: 18.058,
    p15: 18.538,
    p25: 19.303,
    p50: 20.936,
    p75: 22.916,
    p85: 24.165,
    p90: 25.102,
    p95: 26.653,
    p97: 27.782,
    p99: 30.24,
    p999: 35.849
  },
  {
    month: 201,
    l: -1.2366,
    m: 20.9881,
    s: 0.12683,
    p01: 15.247,
    p1: 16.32,
    p3: 17.029,
    p5: 17.433,
    p10: 18.099,
    p15: 18.581,
    p25: 19.349,
    p50: 20.988,
    p75: 22.974,
    p85: 24.226,
    p90: 25.164,
    p95: 26.715,
    p97: 27.844,
    p99: 30.296,
    p999: 35.875
  },
  {
    month: 202,
    l: -1.2233,
    m: 21.04,
    s: 0.12694,
    p01: 15.272,
    p1: 16.351,
    p3: 17.064,
    p5: 17.47,
    p10: 18.139,
    p15: 18.624,
    p25: 19.395,
    p50: 21.04,
    p75: 23.032,
    p85: 24.285,
    p90: 25.225,
    p95: 26.777,
    p97: 27.904,
    p99: 30.351,
    p999: 35.901
  },
  {
    month: 203,
    l: -1.2098,
    m: 21.0914,
    s: 0.12704,
    p01: 15.297,
    p1: 16.382,
    p3: 17.098,
    p5: 17.506,
    p10: 18.179,
    p15: 18.666,
    p25: 19.44,
    p50: 21.091,
    p75: 23.088,
    p85: 24.344,
    p90: 25.284,
    p95: 26.836,
    p97: 27.963,
    p99: 30.405,
    p999: 35.924
  },
  {
    month: 204,
    l: -1.1962,
    m: 21.1423,
    s: 0.12715,
    p01: 15.321,
    p1: 16.412,
    p3: 17.132,
    p5: 17.542,
    p10: 18.218,
    p15: 18.707,
    p25: 19.485,
    p50: 21.142,
    p75: 23.145,
    p85: 24.402,
    p90: 25.344,
    p95: 26.896,
    p97: 28.021,
    p99: 30.458,
    p999: 35.947
  },
  {
    month: 205,
    l: -1.1826,
    m: 21.1925,
    s: 0.12726,
    p01: 15.344,
    p1: 16.442,
    p3: 17.165,
    p5: 17.577,
    p10: 18.256,
    p15: 18.748,
    p25: 19.529,
    p50: 21.192,
    p75: 23.2,
    p85: 24.46,
    p90: 25.402,
    p95: 26.954,
    p97: 28.079,
    p99: 30.51,
    p999: 35.97
  },
  {
    month: 206,
    l: -1.1688,
    m: 21.2423,
    s: 0.12736,
    p01: 15.367,
    p1: 16.471,
    p3: 17.198,
    p5: 17.613,
    p10: 18.295,
    p15: 18.788,
    p25: 19.573,
    p50: 21.242,
    p75: 23.255,
    p85: 24.517,
    p90: 25.46,
    p95: 27.012,
    p97: 28.135,
    p99: 30.56,
    p999: 35.989
  },
  {
    month: 207,
    l: -1.155,
    m: 21.2914,
    s: 0.12746,
    p01: 15.389,
    p1: 16.499,
    p3: 17.231,
    p5: 17.647,
    p10: 18.332,
    p15: 18.828,
    p25: 19.616,
    p50: 21.291,
    p75: 23.309,
    p85: 24.573,
    p90: 25.516,
    p95: 27.068,
    p97: 28.19,
    p99: 30.61,
    p999: 36.007
  },
  {
    month: 208,
    l: -1.141,
    m: 21.34,
    s: 0.12756,
    p01: 15.411,
    p1: 16.527,
    p3: 17.262,
    p5: 17.681,
    p10: 18.369,
    p15: 18.868,
    p25: 19.659,
    p50: 21.34,
    p75: 23.363,
    p85: 24.628,
    p90: 25.572,
    p95: 27.124,
    p97: 28.245,
    p99: 30.658,
    p999: 36.024
  },
  {
    month: 209,
    l: -1.127,
    m: 21.388,
    s: 0.12767,
    p01: 15.432,
    p1: 16.554,
    p3: 17.293,
    p5: 17.714,
    p10: 18.406,
    p15: 18.906,
    p25: 19.701,
    p50: 21.388,
    p75: 23.416,
    p85: 24.683,
    p90: 25.628,
    p95: 27.179,
    p97: 28.299,
    p99: 30.706,
    p999: 36.042
  },
  {
    month: 210,
    l: -1.1129,
    m: 21.4354,
    s: 0.12777,
    p01: 15.452,
    p1: 16.581,
    p3: 17.324,
    p5: 17.747,
    p10: 18.442,
    p15: 18.944,
    p25: 19.742,
    p50: 21.435,
    p75: 23.468,
    p85: 24.737,
    p90: 25.683,
    p95: 27.233,
    p97: 28.352,
    p99: 30.752,
    p999: 36.057
  },
  {
    month: 211,
    l: -1.0986,
    m: 21.4822,
    s: 0.12787,
    p01: 15.472,
    p1: 16.607,
    p3: 17.354,
    p5: 17.779,
    p10: 18.477,
    p15: 18.982,
    p25: 19.783,
    p50: 21.482,
    p75: 23.52,
    p85: 24.79,
    p90: 25.736,
    p95: 27.287,
    p97: 28.403,
    p99: 30.797,
    p999: 36.071
  },
  {
    month: 212,
    l: -1.0843,
    m: 21.5285,
    s: 0.12797,
    p01: 15.491,
    p1: 16.633,
    p3: 17.383,
    p5: 17.81,
    p10: 18.512,
    p15: 19.019,
    p25: 19.823,
    p50: 21.528,
    p75: 23.571,
    p85: 24.843,
    p90: 25.789,
    p95: 27.339,
    p97: 28.454,
    p99: 30.841,
    p999: 36.084
  },
  {
    month: 213,
    l: -1.0699,
    m: 21.5742,
    s: 0.12807,
    p01: 15.51,
    p1: 16.658,
    p3: 17.412,
    p5: 17.841,
    p10: 18.547,
    p15: 19.056,
    p25: 19.863,
    p50: 21.574,
    p75: 23.621,
    p85: 24.895,
    p90: 25.842,
    p95: 27.391,
    p97: 28.504,
    p99: 30.885,
    p999: 36.096
  },
  {
    month: 214,
    l: -1.0553,
    m: 21.6193,
    s: 0.12816,
    p01: 15.528,
    p1: 16.683,
    p3: 17.441,
    p5: 17.872,
    p10: 18.581,
    p15: 19.092,
    p25: 19.903,
    p50: 21.619,
    p75: 23.67,
    p85: 24.946,
    p90: 25.893,
    p95: 27.441,
    p97: 28.552,
    p99: 30.926,
    p999: 36.105
  },
  {
    month: 215,
    l: -1.0407,
    m: 21.6638,
    s: 0.12826,
    p01: 15.546,
    p1: 16.706,
    p3: 17.469,
    p5: 17.902,
    p10: 18.614,
    p15: 19.128,
    p25: 19.942,
    p50: 21.664,
    p75: 23.72,
    p85: 24.996,
    p90: 25.943,
    p95: 27.49,
    p97: 28.6,
    p99: 30.967,
    p999: 36.115
  },
  {
    month: 216,
    l: -1.026,
    m: 21.7077,
    s: 0.12836,
    p01: 15.563,
    p1: 16.73,
    p3: 17.496,
    p5: 17.931,
    p10: 18.647,
    p15: 19.163,
    p25: 19.98,
    p50: 21.708,
    p75: 23.768,
    p85: 25.046,
    p90: 25.993,
    p95: 27.539,
    p97: 28.648,
    p99: 31.007,
    p999: 36.124
  },
  {
    month: 217,
    l: -1.0112,
    m: 21.751,
    s: 0.12845,
    p01: 15.579,
    p1: 16.753,
    p3: 17.523,
    p5: 17.96,
    p10: 18.679,
    p15: 19.197,
    p25: 20.018,
    p50: 21.751,
    p75: 23.815,
    p85: 25.094,
    p90: 26.042,
    p95: 27.587,
    p97: 28.693,
    p99: 31.045,
    p999: 36.13
  },
  {
    month: 218,
    l: -0.9962,
    m: 21.7937,
    s: 0.12855,
    p01: 15.595,
    p1: 16.775,
    p3: 17.549,
    p5: 17.989,
    p10: 18.71,
    p15: 19.231,
    p25: 20.055,
    p50: 21.794,
    p75: 23.862,
    p85: 25.143,
    p90: 26.091,
    p95: 27.634,
    p97: 28.738,
    p99: 31.083,
    p999: 36.136
  },
  {
    month: 219,
    l: -0.9812,
    m: 21.8358,
    s: 0.12864,
    p01: 15.61,
    p1: 16.796,
    p3: 17.575,
    p5: 18.017,
    p10: 18.742,
    p15: 19.264,
    p25: 20.091,
    p50: 21.836,
    p75: 23.909,
    p85: 25.19,
    p90: 26.138,
    p95: 27.68,
    p97: 28.782,
    p99: 31.12,
    p999: 36.14
  },
  {
    month: 220,
    l: -0.9661,
    m: 21.8773,
    s: 0.12874,
    p01: 15.624,
    p1: 16.817,
    p3: 17.6,
    p5: 18.044,
    p10: 18.772,
    p15: 19.297,
    p25: 20.127,
    p50: 21.877,
    p75: 23.954,
    p85: 25.237,
    p90: 26.185,
    p95: 27.726,
    p97: 28.826,
    p99: 31.156,
    p999: 36.145
  },
  {
    month: 221,
    l: -0.9509,
    m: 21.9182,
    s: 0.12883,
    p01: 15.638,
    p1: 16.838,
    p3: 17.624,
    p5: 18.07,
    p10: 18.802,
    p15: 19.329,
    p25: 20.163,
    p50: 21.918,
    p75: 23.999,
    p85: 25.282,
    p90: 26.23,
    p95: 27.77,
    p97: 28.868,
    p99: 31.19,
    p999: 36.148
  },
  {
    month: 222,
    l: -0.9356,
    m: 21.9585,
    s: 0.12893,
    p01: 15.651,
    p1: 16.857,
    p3: 17.648,
    p5: 18.096,
    p10: 18.831,
    p15: 19.361,
    p25: 20.197,
    p50: 21.958,
    p75: 24.043,
    p85: 25.328,
    p90: 26.276,
    p95: 27.814,
    p97: 28.91,
    p99: 31.224,
    p999: 36.151
  },
  {
    month: 223,
    l: -0.9202,
    m: 21.9982,
    s: 0.12902,
    p01: 15.663,
    p1: 16.877,
    p3: 17.671,
    p5: 18.122,
    p10: 18.86,
    p15: 19.392,
    p25: 20.232,
    p50: 21.998,
    p75: 24.087,
    p85: 25.372,
    p90: 26.32,
    p95: 27.857,
    p97: 28.95,
    p99: 31.256,
    p999: 36.151
  },
  {
    month: 224,
    l: -0.9048,
    m: 22.0374,
    s: 0.12911,
    p01: 15.675,
    p1: 16.895,
    p3: 17.694,
    p5: 18.147,
    p10: 18.889,
    p15: 19.422,
    p25: 20.265,
    p50: 22.037,
    p75: 24.13,
    p85: 25.416,
    p90: 26.364,
    p95: 27.898,
    p97: 28.99,
    p99: 31.288,
    p999: 36.151
  },
  {
    month: 225,
    l: -0.8892,
    m: 22.076,
    s: 0.1292,
    p01: 15.687,
    p1: 16.914,
    p3: 17.716,
    p5: 18.171,
    p10: 18.916,
    p15: 19.452,
    p25: 20.299,
    p50: 22.076,
    p75: 24.172,
    p85: 25.459,
    p90: 26.406,
    p95: 27.939,
    p97: 29.028,
    p99: 31.318,
    p999: 36.15
  },
  {
    month: 226,
    l: -0.8735,
    m: 22.114,
    s: 0.1293,
    p01: 15.697,
    p1: 16.931,
    p3: 17.738,
    p5: 18.195,
    p10: 18.943,
    p15: 19.482,
    p25: 20.331,
    p50: 22.114,
    p75: 24.214,
    p85: 25.501,
    p90: 26.449,
    p95: 27.98,
    p97: 29.067,
    p99: 31.349,
    p999: 36.149
  },
  {
    month: 227,
    l: -0.8578,
    m: 22.1514,
    s: 0.12939,
    p01: 15.707,
    p1: 16.948,
    p3: 17.759,
    p5: 18.218,
    p10: 18.97,
    p15: 19.511,
    p25: 20.363,
    p50: 22.151,
    p75: 24.255,
    p85: 25.543,
    p90: 26.49,
    p95: 28.019,
    p97: 29.103,
    p99: 31.378,
    p999: 36.147
  },
  {
    month: 228,
    l: -0.8419,
    m: 22.1883,
    s: 0.12948,
    p01: 15.717,
    p1: 16.964,
    p3: 17.779,
    p5: 18.241,
    p10: 18.996,
    p15: 19.539,
    p25: 20.395,
    p50: 22.188,
    p75: 24.295,
    p85: 25.584,
    p90: 26.531,
    p95: 28.058,
    p97: 29.14,
    p99: 31.405,
    p999: 36.143
  }
]

export default bmiValues
