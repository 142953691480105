import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  ButtonProps,
  Icon
} from '@chakra-ui/react'
import { ConfirmButton, ConfirmButtonProps } from '..'
import { useNavigate } from 'react-router-dom'
import { sitemap } from '../Routing'
import { useCleanActiveVisit } from '../Patients'
import { RiDeleteBin2Line as DeleteIcon } from 'react-icons/ri'

const messages = defineMessages({
  cleanVisit: {
    id: 'UI.button_remove_visit',
    defaultMessage: 'Remove visit'
  },
  cancelConfirmationMessage: {
    id: 'InpatientSearch.clean_active_visit_warning',
    defaultMessage: 'This will permanently remove the current visit information. Are you sure?'
  },
})

type CleanActiveVisitButtonProps = ButtonProps & {
  patientId: string
}

export const CleanActiveVisitButton: React.FC<CleanActiveVisitButtonProps> = props => {
  const { patientId, ...buttonProps } = props

  const navigate = useNavigate()
  const intl = useIntl()

  const profileUrl = sitemap.patients.routes.profile.pathBuilder(patientId)

  const params = {
    onComplete: () => navigate(profileUrl),
    variables: {
      patientId
    },
  }

  const { onSubmit, isLoading, error, onErrorClose } = useCleanActiveVisit(params)

  const onConfirm = () => {
    const params = {
      patientId
    }

    onSubmit(params)
  }


  const buttonStyles = {
    variant: 'ghost',
    isFullWidth: true,
    borderRadius: 'sm',
    ...buttonProps
  }

  const cleanVisitProps: ConfirmButtonProps = {
    onConfirm,
    message: intl.formatMessage(messages.cancelConfirmationMessage),
    buttonProps: {
      label: <>
        <Icon as={DeleteIcon} mr={1} />
        {intl.formatMessage(messages.cleanVisit)}
      </>,
      colorScheme: 'red',
      ...buttonStyles
    },
    confirmButtonProps: {
      isLoading,
      label: intl.formatMessage(messages.cleanVisit),
    },
    error,
    onErrorClose
  }

  return <ConfirmButton {...cleanVisitProps} />
}

