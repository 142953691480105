import React from 'react'
import { useField } from 'react-final-form'
import { VisitTypeSelector, VisitTypeSelectorProps } from '../Selector'
import { Control, Error } from './utils'
import { FormLabel } from '@chakra-ui/react'

// allow all selector props apart from the ones related to the state
type VisitTypeFieldProps = Omit<VisitTypeSelectorProps, 'defaultValue' | 'value' | 'onChange'> & {
  name: string
  label?: string
}

export const VisitTypeField: React.FC<VisitTypeFieldProps> = (props) => {
  const { name, label, ...selectorProps } = props
  const { input, meta } = useField(name)

  return (
    <Control name={name}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <VisitTypeSelector
        isInvalid={meta.error && meta.touched}
        placeholder={label ?? undefined}
        {...input}
        {...selectorProps}
      />
      <Error name={name} />
    </Control>
  )
}
