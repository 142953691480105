import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullChecklist, FullExamWithoutId } from '../../api/interfaces'
import { getPhysicalExamClearingCodesFromChecklists, Nullable } from '../../utils'
import {
  Box,
  BoxProps,
  Collapse,
  Flex,
  Heading,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ExamSelector } from '../Selector'
import {
  classifyExamSections,
  ExamEditorProvider,
  ExamSectionModal,
  PrintableSection,
  Section,
} from '../Exam'
import { ErrorViewer, Loading } from '..'
import { EXAM_TYPES } from 'wd-common/src/logic/exams/definitions'
import { useChangeExam } from '.'
import { IconButton } from '@chakra-ui/button'
import { RiArrowRightSLine as ExpandButton } from 'react-icons/ri'
import { PertinentListByChecklist } from './PertinentListByChecklist'
// import { DynamicTranslation } from '../i18n'

const messages = defineMessages({
  title: {
    id: 'PhysicalExam.title',
    defaultMessage: 'Exam',
  },
  additionalSections: {
    id: 'PhysicalExam.additional_sections',
    defaultMessage: 'Additional Sections',
  },
  editButton: {
    id: 'PhysicalExam.edit_button',
    defaultMessage: 'Edit',
  },
  moreSections: {
    id: 'UI.label_more_sections',
    defaultMessage: '... {count} hidden section/s',
  },
})

type PhysicalExamProps = BoxProps & {
  patientId: string
  visitId: string
  exam: Nullable<FullExamWithoutId>
  checklists: Nullable<Array<FullChecklist>>
  isDisabled?: boolean
}

export const PhysicalExam: React.FC<PhysicalExamProps> = (props) => {
  const { patientId, visitId, exam, checklists, isDisabled, ...boxProps } = props

  const intl = useIntl()
  const { isOpen, onToggle } = useDisclosure()

  const [selectedSectionIndex, setSelectedExamSectionIndex] = React.useState<Nullable<number>>(null)
  const params = {
    patientId,
    visitId,
  }
  const { onSubmit, isLoading, error, onErrorClose } = useChangeExam(params)

  /**
   * The editor context uses indexes for easy access, so we need to
   * get the actual index using the key because used & unused section arrays
   * has different indexes
   */
  const onSectionSelect = (key: number) => {
    const index = exam?.sections.findIndex((section) => section.key === key)
    if (index === undefined || index < 0) {
      return null
    }

    setSelectedExamSectionIndex(index)
  }

  const onModalClose = () => setSelectedExamSectionIndex(null)

  const sections = exam?.sections ?? []

  const clearingCodes = getPhysicalExamClearingCodesFromChecklists(checklists || [])
  const { usedSections, unusedSections } = classifyExamSections(sections, clearingCodes)

  const showCollapsedOptions = unusedSections.length > 0
  return (
    <Box {...boxProps}>
      <HStack justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <HStack>
          <Heading size="md" onClick={onToggle} cursor="pointer">
            {intl.formatMessage(messages.title)}
          </Heading>
          {isLoading && <Loading size="sm" />}
        </HStack>

        {showCollapsedOptions && (
          <IconButton
            size="sm"
            aria-label={'expand all'}
            colorScheme={'brand'}
            variant="ghost"
            onClick={onToggle}
            icon={<ExpandButton />}
            sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}
          />
        )}
      </HStack>

      <ExamSelector
        value={exam?.examId ?? ''}
        onChange={onSubmit}
        isDisabled={isDisabled}
        filter={(exam) => exam.name?.text !== EXAM_TYPES.DENTAL}
      />
      {checklists && (
        <PertinentListByChecklist
          checklists={checklists ?? []}
          exam={exam || ({} as FullExamWithoutId)}
          aggregator="physical_exam"
          className="my-3"
          listStyle="initial"
        />
      )}

      {usedSections.map((section) => (
        <Section
          key={section.key}
          section={section}
          onSelect={(section) => onSectionSelect(section.key)}
          clearingCodes={clearingCodes}
        />
      ))}

      {showCollapsedOptions && (
        <Collapse startingHeight={0} in={isOpen} animateOpacity>
          {unusedSections.map((section) => (
            <Section
              key={section.key}
              section={section}
              onSelect={(section) => onSectionSelect(section.key)}
              clearingCodes={clearingCodes}
            />
          ))}
        </Collapse>
      )}
      {showCollapsedOptions && (
        <Flex justifyContent={'center'}>
          {isOpen ? (
            <IconButton
              size="sm"
              aria-label={'expand all'}
              colorScheme={'brand'}
              variant="ghost"
              onClick={onToggle}
              icon={<ExpandButton />}
              sx={{ transform: 'rotateZ(-90deg)' }}
            />
          ) : (
            <Text
              fontSize={'xs'}
              onClick={onToggle}
              cursor={'pointer'}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {intl.formatMessage(messages.moreSections, { count: unusedSections.length })}
            </Text>
          )}
        </Flex>
      )}

      {error && <ErrorViewer title={error} onClose={onErrorClose} />}

      {selectedSectionIndex !== null && (
        <ExamEditorProvider patientId={patientId} visitId={visitId} onCompleted={onModalClose}>
          <ExamSectionModal
            sectionIndex={selectedSectionIndex}
            clearingCodes={clearingCodes}
            onClose={onModalClose}
          />
        </ExamEditorProvider>
      )}
    </Box>
  )
}

type PrintablePhysicalExamProps = BoxProps & {
  exam: Nullable<FullExamWithoutId>
  checklists: Nullable<Array<FullChecklist>>
}

export const PrintablePhysicalExam: React.FC<PrintablePhysicalExamProps> = (props) => {
  const { exam, checklists, ...boxProps } = props

  const intl = useIntl()

  const sections = exam?.sections ?? []

  const clearingCodes = getPhysicalExamClearingCodesFromChecklists(checklists || [])
  const { usedSections } = classifyExamSections(sections, clearingCodes)

  return (
    <Box w={'100%'} {...boxProps}>
      <HStack
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={1}
        borderBottomWidth={1}
        sx={{
          '@media print': {
            margin: '0 0 .5rem 0',
            borderBottom: '1px solid black',
          },
        }}
      >
        <Heading size="md">{intl.formatMessage(messages.title)}</Heading>
        {/* {exam && <DynamicTranslation message={exam.name} />} */}
      </HStack>
      {checklists && (
        <PertinentListByChecklist
          checklists={checklists ?? []}
          exam={exam ?? ({} as FullExamWithoutId)}
          aggregator="physical_exam"
          className="my-3"
          listStyle="initial"
        />
      )}

      {usedSections.map((section) => (
        <PrintableSection
          key={section.key}
          section={section}
          py={1}
          px={2}
          my={1}
          className="pdfPhysicalExam-section"
          clearingCodes={clearingCodes}
        />
      ))}
    </Box>
  )
}
