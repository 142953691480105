import React from 'react'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { PaginatedResult, FullVisit } from '../../api/interfaces'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { GET_VISITS } from '../../api/requests/visits'

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
})

export interface PastVisitsVariables {
  q?: string
  offset?: number
  limit?: number
  location: string
}

interface PastVisitsData {
  visits: PaginatedResult<FullVisit>
}

export type UsePastVisitsParams = QueryHookOptions<PastVisitsData, PastVisitsVariables>

export const usePastVisits = (params?: UsePastVisitsParams) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)
    translateError(firstError)
  }

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery<PastVisitsData, PastVisitsVariables>(GET_VISITS, {
    onError,
    ...params,
  })

  const onErrorClose = () => {
    setError(null)
  }

  return {
    isLoading,
    visits: data?.visits.docs ?? [],
    count: data?.visits.count ?? 0,
    error,
    refetch,
    onErrorClose,
  }
}
