import React from 'react'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ValidationError } from '../Form'
import {
  ADD_BILLING_ITEM,
  CREATE_BILL,
  DELETE_BILLING_ITEM,
  MARK_AS_PAID,
  UPDATE_BILLING_ITEM,
} from '../../api/requests/billing'
import { BillableCategory, FullBill, FullBillingItem } from '../../api/interfaces/billing'

const messages = defineMessages({
  invalidLocation: {
    id: 'PersonalInfoCard.invalid_location',
    defaultMessage: 'Invalid location',
  },
  errorSavingPatient: {
    id: 'PersonalInfoCard.error_saving_patient_info',
    defaultMessage: 'Could not save the patient info. Please, try again',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  country: {
    id: 'PersonalInfoCard.country',
    defaultMessage: 'Country',
  },
  invalidDate: {
    id: 'PersonalInfoCard.invalid_date',
    defaultMessage: 'Invalid date',
  },
  invalidMail: {
    id: 'PersonalInfoCard.invalid_email_address',
    defaultMessage: 'Invalid e-mail address',
  },
  invalidPhone: {
    id: 'PersonalInfoCard.invalid_phone_number',
    defaultMessage: 'Invalid phone number',
  },
})

export interface BillingItemFormValues {
  name: string
  category: BillableCategory
  quantity: number
  amount?: number
  unitPrice?: number
  note?: string
  userAdded?: boolean
  billingCode?: string
  pricelistId?: string
}

interface CreateBillingItemHookVariables {
  billId: string
  item: BillingItemFormValues
}

interface CreateBillingItemData {
  addBillingItem: FullBill
}

export type UseCreateBillingItemParams = MutationHookOptions<
  CreateBillingItemData,
  CreateBillingItemHookVariables
> & {
  billId: string
}

export const useCreateBillingItem = (params: UseCreateBillingItemParams) => {
  const { billId, ...rest } = params
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate = async (values) => {
    const errors: ValidationError<BillingItemFormValues> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.required)
    }

    if (!values.category) {
      errors.category = intl.formatMessage(messages.required)
    }

    if (!values.quantity) {
      errors.quantity = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [addBillingItem, { loading: isLoading }] = useMutation<
    CreateBillingItemData,
    CreateBillingItemHookVariables
  >(ADD_BILLING_ITEM, {
    onError,
    ...rest,
  })

  const onSubmit = (values) => {
    setError(null)

    const updatedItem = {
      ...values,
      quantity: parseFloat(values.quantity),
      amount: parseFloat(values.amount),
      unitPrice: parseFloat(values.unitPrice),
    }

    const variables = {
      billId: billId,
      item: updatedItem,
    }

    addBillingItem({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}

interface UpdateBillingItemHookVariables {
  billId: string
  item: BillingItemFormValues
  itemId: string
}

interface UpdateBillingItemData {
  updateBillingItem: FullBill
}

export type UseUpdateBillingItemParams = MutationHookOptions<
  UpdateBillingItemData,
  UpdateBillingItemHookVariables
> & {
  billId: string
  itemId: string
}

export const useUpdateBillingItem = (params: UseUpdateBillingItemParams) => {
  const { billId, itemId, ...rest } = params
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const validate = async (values) => {
    const errors: ValidationError<BillingItemFormValues> = {}

    if (!values.name) {
      errors.name = intl.formatMessage(messages.required)
    }

    if (!values.category) {
      errors.category = intl.formatMessage(messages.required)
    }

    if (!values.quantity) {
      errors.quantity = intl.formatMessage(messages.required)
    }

    return errors
  }

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [updateBillingItem, { loading: isLoading }] = useMutation<
    UpdateBillingItemData,
    UpdateBillingItemHookVariables
  >(UPDATE_BILLING_ITEM, {
    onError,
    ...rest,
  })

  const onSubmit = (values) => {
    setError(null)

    const updatedItem = {
      ...values,
      quantity: parseFloat(values.quantity),
      amount: parseFloat(values.amount),
      unitPrice: parseFloat(values.unitPrice),
    }
    const variables: UpdateBillingItemHookVariables = {
      billId: billId,
      item: updatedItem,
      itemId,
    }

    updateBillingItem({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onSubmit,
    isLoading,
    error,
    validate,
    onErrorClose,
  }
}

interface DeleteBillingItemHookVariables {
  billId: string
  itemId: string
}

interface DeleteBillingItemData {
  deleteBillingItem: FullBill
}

export type UseDeleteBillingItemParams = MutationHookOptions<
  DeleteBillingItemData,
  DeleteBillingItemHookVariables
> & {
  billId: string
}

export const useDeleteBillingItem = (params: UseDeleteBillingItemParams) => {
  const { billId, ...rest } = params
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [updateBillingItem, { loading: isLoading }] = useMutation<
    DeleteBillingItemData,
    DeleteBillingItemHookVariables
  >(DELETE_BILLING_ITEM, {
    onError,
    ...rest,
  })

  const onDelete = (itemId: string) => {
    setError(null)

    const variables: DeleteBillingItemHookVariables = {
      billId,
      itemId,
    }

    updateBillingItem({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onDelete,
    isLoading,
    error,
    onErrorClose,
  }
}

/**
 * update this pls
 *
 * This use the bill preview and creates a new one
 */

interface NewBill {
  id: string
  visitId: string
  currencyString?: string
  total: number
  note?: string
  location?: string
  items: Array<FullBillingItem>
}

interface CreateBillHookVariables {
  bill: NewBill
}

interface CreateBillData {
  createBill: FullBill
}

export type UseCreateBillParams = MutationHookOptions<CreateBillData, CreateBillHookVariables> & {
  bill: FullBill
}

export const useCreateBill = (params: UseCreateBillParams) => {
  const { bill, ...rest } = params
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [createBill, { loading: isLoading }] = useMutation<CreateBillData, CreateBillHookVariables>(
    CREATE_BILL,
    {
      onError,
      ...rest,
    }
  )

  const onCreate = () => {
    const { id, visitId, location, items } = bill
    const total = bill.items.reduce((total, item) => total + (item ? item.amount ?? 0 : 0), 0)

    const variables: CreateBillHookVariables = {
      bill: {
        id,
        visitId,
        location,
        items,
        total,
      },
    }
    createBill({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onCreate,
    isLoading,
    error,
    onErrorClose,
  }
}

/**
 * mark as paid
 */

interface MarkAsPaidVariables {
  billId: string
}

interface MarkAsPaidData {
  paidByCash: FullBill
}

export type UseMarkAsPaidParams = MutationHookOptions<MarkAsPaidData, MarkAsPaidVariables> & {
  billId: string
}

export const useMarkAsPaid = (params: UseMarkAsPaidParams) => {
  const { billId, ...rest } = params
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [markAsPaid, { loading: isLoading }] = useMutation<MarkAsPaidData, MarkAsPaidVariables>(
    MARK_AS_PAID,
    {
      onError,
      ...rest,
    }
  )

  const onPaid = () => {
    const variables: MarkAsPaidVariables = {
      billId,
    }
    markAsPaid({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onPaid,
    isLoading,
    error,
    onErrorClose,
  }
}
