import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { SeparatedList } from '../../components'
import { FullChecklist, FullExamWithoutId, TranslatableText } from '../../api/interfaces'
import { useDynamicTranslation } from '../i18n'

const messages = defineMessages({
  pertinentLabel: {
    id: 'UI.pertinent_label',
    defaultMessage: 'Pertinent +/-',
  },
})

/**
 * This needs to be rewritten
 * usePertinentByAggregator is to complex.
 * Texts don't need to be translated in the aggregator
 * because we have to use the <T> wrapper when is possible to
 * make the translators work easier.
 * No need of an aggregator. Use enum for history and physical-exam
 * Create 2 different component if is needed. this conditional mutation is not easy to read
 * and understand
 */

type Pertinents = { [key: string]: string[] }

type Property = 'physical_exam' | 'history'

type PropType = {
  className: string
  checklists: FullChecklist[]
  aggregator: Property
  showChecklistTitle?: boolean
  listStyle?: string
  exam: FullExamWithoutId
}

const getPhysicalExamPertinents = (
  checklist: FullChecklist,
  exam: FullExamWithoutId
): Array<TranslatableText> => {
  const checklistItems = checklist.physical_exam ?? []

  return checklistItems
    .filter((item) => item.checked)
    .filter((item) => {
      const atLeastSomethingCleaned = exam.sections.some((section) => {
        const atLeastOneGroupCleaned = section.groups.some((group) => {
          if (!Array.isArray(group.physical_exam_codes)) {
            return false
          }

          return group.physical_exam_codes.some(
            (code) =>
              Array.isArray(item.physical_exam_codes) && item.physical_exam_codes.includes(code)
          )
        })

        return atLeastOneGroupCleaned
      })

      return !atLeastSomethingCleaned
    })
    .map((item) => item.name as TranslatableText)
}

const getHistoryPertinents = (
  checklist: FullChecklist,
  exam: FullExamWithoutId
): Array<TranslatableText> => {
  const checklistItems = checklist.history ?? []

  return checklistItems
    .filter((item) => item.checked)
    .filter((item) => {
      const atLeastSomethingCleaned = exam.sections.some((section) => {
        const atLeastOneGroupCleaned = section.groups.some((group) => {
          if (!Array.isArray(group.physical_exam_codes)) {
            return false
          }

          return group.physical_exam_codes.some(
            (code) => Array.isArray(item.history_codes) && item.history_codes.includes(code)
          )
        })

        return atLeastOneGroupCleaned
      })

      return !atLeastSomethingCleaned
    })
    .map((item) => item.name as TranslatableText)
}

const usePertinentByAggregator = (
  checklists: Array<FullChecklist>,
  exam: FullExamWithoutId,
  aggregator: Property
) => {
  const { translate } = useDynamicTranslation()

  let filteredChecklist = checklists

  if (aggregator === 'physical_exam') {
    filteredChecklist = checklists.filter((checklist) =>
      (checklist.general_tags || []).includes('Diagnosis')
    )
  }

  return filteredChecklist.reduce((acc, checklist, index) => {
    let pertinents: Array<TranslatableText> = []

    if (aggregator === 'physical_exam') {
      pertinents = getPhysicalExamPertinents(checklist, exam)
    }

    if (aggregator === 'history') {
      pertinents = getHistoryPertinents(checklist, exam)
    }

    const checklistName = checklist.name.text
    const key = checklistName ? (translate(checklist.name) as string) : index

    if (pertinents.length) {
      return {
        ...acc,
        [key]: pertinents
          .map((pertinent) => {
            const pertinentName = pertinent?.text
            return pertinentName ? translate(pertinent) : ''
          })
          .filter((i) => i),
      }
    } else {
      return acc
    }
  }, {}) as Pertinents
}

export const PertinentListByChecklist: React.FC<PropType> = (props) => {
  const { className, checklists, aggregator, showChecklistTitle, listStyle, exam } = props

  const intl = useIntl()

  const pertinents = usePertinentByAggregator(checklists, exam, aggregator)

  if (!pertinents || Object.keys(pertinents).length === 0) {
    return null
  }

  return (
    <div className={className}>
      <small className="text-dark d-block">{intl.formatMessage(messages.pertinentLabel)}</small>
      {Object.keys(pertinents).map((pertinentKey) => (
        <div className="d-flex flex-wrap my-2" key={pertinentKey}>
          {showChecklistTitle && (
            <div className="d-flex w-100 mb-2">
              <b>{pertinentKey}</b>
            </div>
          )}
          <SeparatedList
            listStyle={listStyle}
            list={pertinents[pertinentKey].map((detail) => (
              <>{detail}</>
            ))}
          />
        </div>
      ))}
    </div>
  )
}
