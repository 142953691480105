import React from 'react'
import {
  Avatar,
  Box,
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  StackProps,
  Text,
  useBreakpointValue,
  useColorMode,
  useTheme,
} from '@chakra-ui/react'
import { useSession } from '../Auth'
import { defineMessages, useIntl } from 'react-intl'
import {
  RiLogoutCircleLine as LogoutIcon,
  RiSettings4Line as SettingsIcon,
  RiAccountCircleLine as AccountIcon,
} from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { sitemap } from '../Routing'
import { Sidebar } from './Sidebar'
import { SubscriptionStatus as SubscriptionStatusEnum } from '../../api/interfaces'
import { SubscriptionStatus } from '../Staff'
import { HelpReferences } from '../Help'

const messages = defineMessages({
  // from v2
  preferences: {
    id: 'Router.preferences',
    defaultMessage: 'Preferences',
  },
  system: {
    id: 'Preferences.system_label',
    defaultMessage: 'System',
  },
  account: {
    id: 'Preferences.account_label',
    defaultMessage: 'Account',
  },
  logOut: {
    id: 'Router.logout',
    defaultMessage: 'Logout',
  },
})

export const HEADER_HEIGHT = 60
export const Header: React.FC<StackProps> = (props) => {
  const { colorMode } = useColorMode()
  const { colors } = useTheme()

  const background = colorMode === 'light' ? colors.white : colors.gray['800']
  const color = colorMode === 'light' ? colors.black : colors.white
  const borderColor = colorMode === 'light' ? colors.gray['300'] : colors.gray['700']

  const stackProps = {
    ...props,
    height: `${HEADER_HEIGHT}px`,
    background,
    color,
    justifyContent: 'space-between',
    py: 2,
    px: 4,
    borderBottom: '1px',
    borderColor,
  }

  return (
    <HStack {...stackProps}>
      <HStack justifyContent={'flex-start'}>
        <Sidebar />
        <HelpReferences size="md" variant="ghost" colorScheme={'brand'} />
      </HStack>
      <AccountMenu />
    </HStack>
  )
}

const AccountMenu: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const { getUserFullName, getSubscriptionStatus, logOut } = useSession()

  const subscriptionStatus = getSubscriptionStatus()

  const userFullName = getUserFullName()
  const isSmallScreen = useBreakpointValue({ base: true, md: false })
  const showStatus =
    subscriptionStatus === SubscriptionStatusEnum.TRIALING || subscriptionStatus === SubscriptionStatusEnum.PAST_DUE

  const getInitials = (name: string): string => {
    const words = name.split(' ')

    const initials = words.reduce((initials, word) => {
      if (!word) {
        return initials
      }

      return initials.concat(word[0])
    }, '')

    return initials.toUpperCase()
  }

  return (
    <Box>
      <Menu>
        <MenuButton as={Button} variant="ghost">
          <HStack alignItems="center" spacing={3}>
            <Avatar name={userFullName} getInitials={getInitials} size={'sm'} />
            {!isSmallScreen && <Text>{userFullName}</Text>}
            {showStatus && <SubscriptionStatus status={subscriptionStatus} />}
          </HStack>
        </MenuButton>
        <MenuList zIndex={2}>
          <MenuGroup title={intl.formatMessage(messages.preferences)}>
            <MenuItem onClick={() => navigate(sitemap.preferences.routes.account.absolutePath)}>
              <Icon as={AccountIcon} />
              <Text ml={4}>{intl.formatMessage(messages.account)}</Text>
            </MenuItem>
            <MenuItem onClick={() => navigate(sitemap.preferences.routes.system.absolutePath)}>
              <Icon as={SettingsIcon} />
              <Text ml={4}>{intl.formatMessage(messages.system)}</Text>
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuItem onClick={logOut}>
            <Icon as={LogoutIcon} />
            <Text ml={4}>{intl.formatMessage(messages.logOut)}</Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  )
}
