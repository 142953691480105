import React from 'react'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'
import Select from 'react-select'
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SingleSelectorProps,
  useChakraThemeParser,
} from '.'
import { CalendarSlotUnit } from '../../api/interfaces'
import { Nullable } from '../../utils'
import { isValidSlotUnit } from '../Scheduler'

const messages = defineMessages({
  timeUnit: {
    id: 'TimeUnitSelector.unit',
    defaultMessage: 'Unit',
  },
})

export const timeUnitTranslation: Record<CalendarSlotUnit, MessageDescriptor> = {
  [CalendarSlotUnit.DAYS]: {
    id: 'TimeUnits.days',
    defaultMessage: 'Days',
  },
  [CalendarSlotUnit.HOURS]: {
    id: 'TimeUnits.hours',
    defaultMessage: 'Hours',
  },
  [CalendarSlotUnit.MINUTES]: {
    id: 'TimeUnits.minutes',
    defaultMessage: 'Minutes',
  },
}

const timeUnitMessages = defineMessages(timeUnitTranslation)

export type TimeUnitSelectorProps = SingleSelectorProps<CalendarSlotUnit>

export const TimeUnitSelector: React.FC<TimeUnitSelectorProps> = (props) => {
  const { value } = props

  const intl = useIntl()
  const theme = useChakraThemeParser()

  const formatOption = (statusAssesment: CalendarSlotUnit): ReactSelectInternalSingleValue => {
    return {
      label: intl.formatMessage(timeUnitMessages[statusAssesment]),
      value: statusAssesment,
    }
  }

  /**
   * Filter statuses for lactation use
   */
  const options = Object.values(CalendarSlotUnit).map(formatOption)

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null
    }

    if (isValidSlotUnit(value as string)) {
      return formatOption(value as CalendarSlotUnit)
    }

    return null
  }

  const _onChange = (value) => {
    if (!value) {
      props.onChange('' as CalendarSlotUnit)
      return
    }

    const selectedValue = value

    props.onChange(selectedValue.value as CalendarSlotUnit)
  }

  return (
    <Select
      options={options}
      value={parseValue(value)}
      onChange={_onChange}
      isLoading={props.isLoading}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      aria-invalid={props.isInvalid}
      placeholder={intl.formatMessage(messages.timeUnit)}
      blurInputOnSelect={props.blurInputOnSelect}
      theme={theme}
    />
  )
}
