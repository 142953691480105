import React from 'react'
import { Button, Stack, useBreakpointValue } from '@chakra-ui/react'
import { defineMessages } from '@formatjs/intl'
import { Form } from 'react-final-form'
import { useIntl } from 'react-intl'
import { Nullable } from '../../utils'
import { ErrorViewer } from '../ErrorViewer'
import { DateTimeField, RecurrenceField, TextField, ValidateFn } from '../Form'
import { NewRoomEventFormValues, useDeleteRoomEvent } from './useRoomEventActions'
import { ConfirmButton, ConfirmButtonProps } from '../ConfirmButton'
import { useCalendarContext } from './useCalendarContext'
import { AvailableSlot, formatNormalizedSlotsForEndTimeSelector, normalizeSlots } from './utils'
const messages = defineMessages({
  roomEvent: {
    id: 'RoomEventEditor.room_event',
    defaultMessage: 'Room event',
  },
  name: {
    id: 'RoomEventEditor.label_name',
    defaultMessage: 'Event name',
  },
  description: {
    id: 'RoomEventEditor.label_description',
    defaultMessage: 'Event description',
  },
  fromLabel: {
    id: 'RoomEventEditor.from_label',
    defaultMessage: 'From',
  },
  toLabel: {
    id: 'RoomEventEditor.to_label',
    defaultMessage: 'To',
  },
  recurrenceLabel: {
    id: 'RoomEventEditor.recurrence_label',
    defaultMessage: 'Recurrence',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
  deleteButton: {
    id: 'UI.button_delete',
    defaultMessage: 'Delete',
  },
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?',
  },
})

interface RoomEventFormProps {
  slotDurationInMinutes: number
  availableSlots: Array<AvailableSlot>
  initialValues?: NewRoomEventFormValues
  onSubmit: (values: NewRoomEventFormValues) => void
  isLoading?: boolean
  validate: ValidateFn<NewRoomEventFormValues>
  error: Nullable<string>
  onErrorClose: () => void
  onCancel: () => void
  isDeletable?: boolean
}

export const RoomEventForm: React.FC<RoomEventFormProps> = (props) => {
  const {
    availableSlots,
    slotDurationInMinutes,
    initialValues,
    validate,
    error,
    onErrorClose,
    onSubmit,
    isLoading,
    onCancel,
    isDeletable,
  } = props

  const intl = useIntl()

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, pristine, values, initialValues }) => {
        const selectedDate = values.start ?? initialValues.start
        const allowedStartTimes = normalizeSlots(availableSlots, slotDurationInMinutes)
        const allowedEndTimes = formatNormalizedSlotsForEndTimeSelector(
          availableSlots,
          slotDurationInMinutes,
          selectedDate ? new Date(selectedDate) : undefined
        )

        return (
          <form onSubmit={handleSubmit}>
            <TextField name="name" label={intl.formatMessage(messages.name)} />
            <TextField name="description" label={intl.formatMessage(messages.description)} />
            <RecurrenceField name="recurrence" label={intl.formatMessage(messages.recurrenceLabel)} />
            <DateTimeField
              name="start"
              availableSlots={allowedStartTimes}
              label={intl.formatMessage(messages.fromLabel)}
            />
            <DateTimeField
              isDisabled={!values.start}
              availableSlots={allowedEndTimes}
              name="end"
              label={intl.formatMessage(messages.toLabel)}
            />

            {error && <ErrorViewer title={error} onClose={onErrorClose} />}

            <Stack isInline={!isFullWidth} justifyContent={'end'} my="3">
              {isDeletable && <DeleteRoomEventButton />}
              <Button onClick={onCancel} isFullWidth={isFullWidth}>
                {intl.formatMessage(messages.cancelButton)}
              </Button>
              <Button
                colorScheme={'brand'}
                isLoading={isLoading}
                type="submit"
                isFullWidth={isFullWidth}
                isDisabled={pristine && !isDeletable}
              >
                {intl.formatMessage(messages.saveButton)}
              </Button>
            </Stack>
          </form>
        )
      }}
    />
  )
}

const DeleteRoomEventButton: React.VFC = () => {
  const intl = useIntl()

  const { selectedEvent, roomId, onReset } = useCalendarContext()

  const { onDelete, onErrorClose, error, isLoading } = useDeleteRoomEvent({
    eventId: selectedEvent ?? '',
    roomId: roomId ?? '',
    onCompleted: onReset,
  })

  const deleteRoomProps: ConfirmButtonProps = {
    onConfirm: onDelete,
    message: intl.formatMessage(messages.confirmationMessage),
    buttonProps: {
      label: <>{intl.formatMessage(messages.deleteButton)}</>,
      colorScheme: 'red',
    },
    confirmButtonProps: {
      isLoading: isLoading,
      label: intl.formatMessage(messages.deleteButton),
    },
    error,
    onErrorClose,
  }

  if (!roomId || !selectedEvent) {
    return null
  }

  return <ConfirmButton {...deleteRoomProps} />
}
