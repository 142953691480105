import { MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullPatientWithActiveVisit } from '../../api/interfaces'
import {
  ADD_HEAD_CIRCUMFERENCE_MEASUREMENT,
  UPDATE_HEAD_CIRCUMFERENCE_MEASUREMENT,
} from '../../api/requests/activeVisit'
import { extractFirstErrorCode, Nullable } from '../../utils'
import { ValidateFn, ValidationError } from '../Form'

const messages = defineMessages({
  patientNotFound: {
    id: 'RXActions.patient_not_found',
    defaultMessage: 'The patient was not found.',
  },
  visitNotFound: {
    id: 'Checklist.visit_not_found',
    defaultMessage: 'Could not find the current visit. Please, reload the page',
  },
  errorCreatingMeasurement: {
    id: 'Vitals.error_adding_measurement',
    defaultMessage: 'We could not add the measurement, try again later.',
  },
  invalidMeasurementId: {
    id: 'Vitals.invalid_measurement_id',
    defaultMessage: 'You must use a valid measurement id.',
  },
  errorRemovingMeasurement: {
    id: 'Vitals.error_removing_measurement',
    defaultMessage: 'We could not remove the measurement, try again later.',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  minValueError: {
    id: 'form_validation.min_value',
    defaultMessage: 'This field must be greater than: {value}',
  },
})

export interface NewHeadCircumference {
  cm: number
}

interface CreateHeadCircumferenceVariables {
  patientId: string
  visitId: string
  measurement: NewHeadCircumference
}

interface CreateHeadCircumferenceData {
  addHeadCircumferenceMeasurement: FullPatientWithActiveVisit
}

type CreateHeadCircumferenceParams = MutationHookOptions<
  CreateHeadCircumferenceData,
  CreateHeadCircumferenceVariables
> & {
  patientId: string
  visitId: string
}

export const useCreateHeadCircumference = (params: CreateHeadCircumferenceParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'invalid_measurement_id':
        return setError(intl.formatMessage(messages.invalidMeasurementId))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFound))

      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_measurement':
        return setError(intl.formatMessage(messages.errorCreatingMeasurement))

      case 'error_removing_measurement':
        return setError(intl.formatMessage(messages.errorRemovingMeasurement))

      case 'invalid_signature':
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [addHeadCircumferenceMeasurement, { loading: isLoading }] = useMutation(ADD_HEAD_CIRCUMFERENCE_MEASUREMENT, {
    onError,
    ...rest,
  })

  const validate: ValidateFn<NewHeadCircumference> = async (values) => {
    const errors: ValidationError<NewHeadCircumference> = {}

    if (!values.cm) {
      errors.cm = intl.formatMessage(messages.minValueError, { value: 0 })
    }

    return errors
  }

  const onCreate = (bloodPressure: NewHeadCircumference) => {
    const variables = {
      patientId,
      visitId,
      measurement: bloodPressure,
    }

    addHeadCircumferenceMeasurement({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onCreate,
    validate,
    isLoading,
    error,
    onErrorClose,
  }
}

interface UpdateHeadCircumferenceVariables {
  patientId: string
  visitId: string
  measurementId: string
  measurement: NewHeadCircumference
}
interface UpdateHeadCircumferenceData {
  updateHeadCircumferenceMeasurement: FullPatientWithActiveVisit
}

type UpdateHeadCircumferenceParams = MutationHookOptions<
  UpdateHeadCircumferenceData,
  UpdateHeadCircumferenceVariables
> & {
  patientId: string
  visitId: string
}

export const useUpdateHeadCircumference = (params: UpdateHeadCircumferenceParams) => {
  const { patientId, visitId, ...rest } = params

  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error)

    switch (errorCode) {
      case 'invalid_measurement_id':
        return setError(intl.formatMessage(messages.invalidMeasurementId))

      case 'visit_not_found':
        return setError(intl.formatMessage(messages.visitNotFound))

      case 'patient_not_found':
        return setError(intl.formatMessage(messages.patientNotFound))

      case 'error_adding_measurement':
        return setError(intl.formatMessage(messages.errorCreatingMeasurement))

      case 'error_removing_measurement':
        return setError(intl.formatMessage(messages.errorRemovingMeasurement))

      case 'invalid_signature':
      default:
        return setError(intl.formatMessage(messages.internalServerError))
    }
  }

  const [updateHeadCircumferenceMeasurement, { loading: isLoading }] = useMutation(
    UPDATE_HEAD_CIRCUMFERENCE_MEASUREMENT,
    {
      onError,
      ...rest,
    }
  )

  const validate: ValidateFn<NewHeadCircumference> = async (values) => {
    const errors: ValidationError<NewHeadCircumference> = {}

    if (!values.cm) {
      errors.cm = intl.formatMessage(messages.minValueError, { value: 0 })
    }

    return errors
  }

  const onUpdate = (measurementId: string, bloodPressure: NewHeadCircumference) => {
    const variables = {
      patientId,
      visitId,
      measurementId,
      measurement: bloodPressure,
    }

    updateHeadCircumferenceMeasurement({ variables })
  }

  const onErrorClose = () => {
    setError(null)
  }

  return {
    onUpdate,
    isLoading,
    validate,
    error,
    onErrorClose,
  }
}
