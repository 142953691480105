// Child growth standards
// Length/height reference values for GIRLS between 0 - 228 months (19 years)
// Data taken from World Health Organization and then parsed to JSON
// Links:
// https://www.who.int/childgrowth/standards/tab_lhfa_girls_p_0_13.txt (0 - 13 weeks)
// https://www.who.int/childgrowth/standards/tab_lhfa_girls_p_0_2.txt  (0 - 24 months)
// https://www.who.int/childgrowth/standards/tab_lhfa_girls_p_2_5.txt  (25 - 60 months)
// https://www.who.int/growthref/hfa_girls_perc_WHO2007_exp.txt        (61 - 228 months)

const lengthValues = [
  {
    week: 0,
    l: 1,
    m: 49.1477,
    s: 0.0379,
    sd: 1.8627,
    p01: 43.4,
    p1: 44.8,
    p3: 45.6,
    p5: 46.1,
    p10: 46.8,
    p15: 47.2,
    p25: 47.9,
    p50: 49.1,
    p75: 50.4,
    p85: 51.1,
    p90: 51.5,
    p95: 52.2,
    p97: 52.7,
    p99: 53.5,
    p999: 54.9
  },
  {
    week: 1,
    l: 1,
    m: 50.3298,
    s: 0.03742,
    sd: 1.8833,
    p01: 44.5,
    p1: 45.9,
    p3: 46.8,
    p5: 47.2,
    p10: 47.9,
    p15: 48.4,
    p25: 49.1,
    p50: 50.3,
    p75: 51.6,
    p85: 52.3,
    p90: 52.7,
    p95: 53.4,
    p97: 53.9,
    p99: 54.7,
    p999: 56.1
  },
  {
    week: 2,
    l: 1,
    m: 51.512,
    s: 0.03694,
    sd: 1.9029,
    p01: 45.6,
    p1: 47.1,
    p3: 47.9,
    p5: 48.4,
    p10: 49.1,
    p15: 49.5,
    p25: 50.2,
    p50: 51.5,
    p75: 52.8,
    p85: 53.5,
    p90: 54,
    p95: 54.6,
    p97: 55.1,
    p99: 55.9,
    p999: 57.4
  },
  {
    week: 3,
    l: 1,
    m: 52.4695,
    s: 0.03669,
    sd: 1.9251,
    p01: 46.5,
    p1: 48,
    p3: 48.8,
    p5: 49.3,
    p10: 50,
    p15: 50.5,
    p25: 51.2,
    p50: 52.5,
    p75: 53.8,
    p85: 54.5,
    p90: 54.9,
    p95: 55.6,
    p97: 56.1,
    p99: 56.9,
    p999: 58.4
  },
  {
    week: 4,
    l: 1,
    m: 53.3809,
    s: 0.03647,
    sd: 1.9468,
    p01: 47.4,
    p1: 48.9,
    p3: 49.7,
    p5: 50.2,
    p10: 50.9,
    p15: 51.4,
    p25: 52.1,
    p50: 53.4,
    p75: 54.7,
    p85: 55.4,
    p90: 55.9,
    p95: 56.6,
    p97: 57,
    p99: 57.9,
    p999: 59.4
  },
  {
    week: 5,
    l: 1,
    m: 54.2454,
    s: 0.03627,
    sd: 1.9675,
    p01: 48.2,
    p1: 49.7,
    p3: 50.5,
    p5: 51,
    p10: 51.7,
    p15: 52.2,
    p25: 52.9,
    p50: 54.2,
    p75: 55.6,
    p85: 56.3,
    p90: 56.8,
    p95: 57.5,
    p97: 57.9,
    p99: 58.8,
    p999: 60.3
  },
  {
    week: 6,
    l: 1,
    m: 55.0642,
    s: 0.03609,
    sd: 1.9873,
    p01: 48.9,
    p1: 50.4,
    p3: 51.3,
    p5: 51.8,
    p10: 52.5,
    p15: 53,
    p25: 53.7,
    p50: 55.1,
    p75: 56.4,
    p85: 57.1,
    p90: 57.6,
    p95: 58.3,
    p97: 58.8,
    p99: 59.7,
    p999: 61.2
  },
  {
    week: 7,
    l: 1,
    m: 55.8406,
    s: 0.03593,
    sd: 2.0064,
    p01: 49.6,
    p1: 51.2,
    p3: 52.1,
    p5: 52.5,
    p10: 53.3,
    p15: 53.8,
    p25: 54.5,
    p50: 55.8,
    p75: 57.2,
    p85: 57.9,
    p90: 58.4,
    p95: 59.1,
    p97: 59.6,
    p99: 60.5,
    p999: 62
  },
  {
    week: 8,
    l: 1,
    m: 56.5767,
    s: 0.03578,
    sd: 2.0243,
    p01: 50.3,
    p1: 51.9,
    p3: 52.8,
    p5: 53.2,
    p10: 54,
    p15: 54.5,
    p25: 55.2,
    p50: 56.6,
    p75: 57.9,
    p85: 58.7,
    p90: 59.2,
    p95: 59.9,
    p97: 60.4,
    p99: 61.3,
    p999: 62.8
  },
  {
    week: 9,
    l: 1,
    m: 57.2761,
    s: 0.03564,
    sd: 2.0413,
    p01: 51,
    p1: 52.5,
    p3: 53.4,
    p5: 53.9,
    p10: 54.7,
    p15: 55.2,
    p25: 55.9,
    p50: 57.3,
    p75: 58.7,
    p85: 59.4,
    p90: 59.9,
    p95: 60.6,
    p97: 61.1,
    p99: 62,
    p999: 63.6
  },
  {
    week: 10,
    l: 1,
    m: 57.9436,
    s: 0.03552,
    sd: 2.0582,
    p01: 51.6,
    p1: 53.2,
    p3: 54.1,
    p5: 54.6,
    p10: 55.3,
    p15: 55.8,
    p25: 56.6,
    p50: 57.9,
    p75: 59.3,
    p85: 60.1,
    p90: 60.6,
    p95: 61.3,
    p97: 61.8,
    p99: 62.7,
    p999: 64.3
  },
  {
    week: 11,
    l: 1,
    m: 58.5816,
    s: 0.0354,
    sd: 2.0738,
    p01: 52.2,
    p1: 53.8,
    p3: 54.7,
    p5: 55.2,
    p10: 55.9,
    p15: 56.4,
    p25: 57.2,
    p50: 58.6,
    p75: 60,
    p85: 60.7,
    p90: 61.2,
    p95: 62,
    p97: 62.5,
    p99: 63.4,
    p999: 65
  },
  {
    week: 12,
    l: 1,
    m: 59.1922,
    s: 0.0353,
    sd: 2.0895,
    p01: 52.7,
    p1: 54.3,
    p3: 55.3,
    p5: 55.8,
    p10: 56.5,
    p15: 57,
    p25: 57.8,
    p50: 59.2,
    p75: 60.6,
    p85: 61.4,
    p90: 61.9,
    p95: 62.6,
    p97: 63.1,
    p99: 64.1,
    p999: 65.6
  },
  {
    week: 13,
    l: 1,
    m: 59.7773,
    s: 0.0352,
    sd: 2.1042,
    p01: 53.3,
    p1: 54.9,
    p3: 55.8,
    p5: 56.3,
    p10: 57.1,
    p15: 57.6,
    p25: 58.4,
    p50: 59.8,
    p75: 61.2,
    p85: 62,
    p90: 62.5,
    p95: 63.2,
    p97: 63.7,
    p99: 64.7,
    p999: 66.3
  },
  {
    month: 0,
    l: 1,
    m: 49.1477,
    s: 0.0379,
    sd: 1.8627,
    p01: 43.4,
    p1: 44.8,
    p3: 45.6,
    p5: 46.1,
    p10: 46.8,
    p15: 47.2,
    p25: 47.9,
    p50: 49.1,
    p75: 50.4,
    p85: 51.1,
    p90: 51.5,
    p95: 52.2,
    p97: 52.7,
    p99: 53.5,
    p999: 54.9
  },
  {
    month: 1,
    l: 1,
    m: 53.6872,
    s: 0.0364,
    sd: 1.9542,
    p01: 47.6,
    p1: 49.1,
    p3: 50,
    p5: 50.5,
    p10: 51.2,
    p15: 51.7,
    p25: 52.4,
    p50: 53.7,
    p75: 55,
    p85: 55.7,
    p90: 56.2,
    p95: 56.9,
    p97: 57.4,
    p99: 58.2,
    p999: 59.7
  },
  {
    month: 2,
    l: 1,
    m: 57.0673,
    s: 0.03568,
    sd: 2.0362,
    p01: 50.8,
    p1: 52.3,
    p3: 53.2,
    p5: 53.7,
    p10: 54.5,
    p15: 55,
    p25: 55.7,
    p50: 57.1,
    p75: 58.4,
    p85: 59.2,
    p90: 59.7,
    p95: 60.4,
    p97: 60.9,
    p99: 61.8,
    p999: 63.4
  },
  {
    month: 3,
    l: 1,
    m: 59.8029,
    s: 0.0352,
    sd: 2.1051,
    p01: 53.3,
    p1: 54.9,
    p3: 55.8,
    p5: 56.3,
    p10: 57.1,
    p15: 57.6,
    p25: 58.4,
    p50: 59.8,
    p75: 61.2,
    p85: 62,
    p90: 62.5,
    p95: 63.3,
    p97: 63.8,
    p99: 64.7,
    p999: 66.3
  },
  {
    month: 4,
    l: 1,
    m: 62.0899,
    s: 0.03486,
    sd: 2.1645,
    p01: 55.4,
    p1: 57.1,
    p3: 58,
    p5: 58.5,
    p10: 59.3,
    p15: 59.8,
    p25: 60.6,
    p50: 62.1,
    p75: 63.5,
    p85: 64.3,
    p90: 64.9,
    p95: 65.7,
    p97: 66.2,
    p99: 67.1,
    p999: 68.8
  },
  {
    month: 5,
    l: 1,
    m: 64.0301,
    s: 0.03463,
    sd: 2.2174,
    p01: 57.2,
    p1: 58.9,
    p3: 59.9,
    p5: 60.4,
    p10: 61.2,
    p15: 61.7,
    p25: 62.5,
    p50: 64,
    p75: 65.5,
    p85: 66.3,
    p90: 66.9,
    p95: 67.7,
    p97: 68.2,
    p99: 69.2,
    p999: 70.9
  },
  {
    month: 6,
    l: 1,
    m: 65.7311,
    s: 0.03448,
    sd: 2.2664,
    p01: 58.7,
    p1: 60.5,
    p3: 61.5,
    p5: 62,
    p10: 62.8,
    p15: 63.4,
    p25: 64.2,
    p50: 65.7,
    p75: 67.3,
    p85: 68.1,
    p90: 68.6,
    p95: 69.5,
    p97: 70,
    p99: 71,
    p999: 72.7
  },
  {
    month: 7,
    l: 1,
    m: 67.2873,
    s: 0.03441,
    sd: 2.3154,
    p01: 60.1,
    p1: 61.9,
    p3: 62.9,
    p5: 63.5,
    p10: 64.3,
    p15: 64.9,
    p25: 65.7,
    p50: 67.3,
    p75: 68.8,
    p85: 69.7,
    p90: 70.3,
    p95: 71.1,
    p97: 71.6,
    p99: 72.7,
    p999: 74.4
  },
  {
    month: 8,
    l: 1,
    m: 68.7498,
    s: 0.0344,
    sd: 2.365,
    p01: 61.4,
    p1: 63.2,
    p3: 64.3,
    p5: 64.9,
    p10: 65.7,
    p15: 66.3,
    p25: 67.2,
    p50: 68.7,
    p75: 70.3,
    p85: 71.2,
    p90: 71.8,
    p95: 72.6,
    p97: 73.2,
    p99: 74.3,
    p999: 76.1
  },
  {
    month: 9,
    l: 1,
    m: 70.1435,
    s: 0.03444,
    sd: 2.4157,
    p01: 62.7,
    p1: 64.5,
    p3: 65.6,
    p5: 66.2,
    p10: 67,
    p15: 67.6,
    p25: 68.5,
    p50: 70.1,
    p75: 71.8,
    p85: 72.6,
    p90: 73.2,
    p95: 74.1,
    p97: 74.7,
    p99: 75.8,
    p999: 77.6
  },
  {
    month: 10,
    l: 1,
    m: 71.4818,
    s: 0.03452,
    sd: 2.4676,
    p01: 63.9,
    p1: 65.7,
    p3: 66.8,
    p5: 67.4,
    p10: 68.3,
    p15: 68.9,
    p25: 69.8,
    p50: 71.5,
    p75: 73.1,
    p85: 74,
    p90: 74.6,
    p95: 75.5,
    p97: 76.1,
    p99: 77.2,
    p999: 79.1
  },
  {
    month: 11,
    l: 1,
    m: 72.771,
    s: 0.03464,
    sd: 2.5208,
    p01: 65,
    p1: 66.9,
    p3: 68,
    p5: 68.6,
    p10: 69.5,
    p15: 70.2,
    p25: 71.1,
    p50: 72.8,
    p75: 74.5,
    p85: 75.4,
    p90: 76,
    p95: 76.9,
    p97: 77.5,
    p99: 78.6,
    p999: 80.6
  },
  {
    month: 12,
    l: 1,
    m: 74.015,
    s: 0.03479,
    sd: 2.575,
    p01: 66.1,
    p1: 68,
    p3: 69.2,
    p5: 69.8,
    p10: 70.7,
    p15: 71.3,
    p25: 72.3,
    p50: 74,
    p75: 75.8,
    p85: 76.7,
    p90: 77.3,
    p95: 78.3,
    p97: 78.9,
    p99: 80,
    p999: 82
  },
  {
    month: 13,
    l: 1,
    m: 75.2176,
    s: 0.03496,
    sd: 2.6296,
    p01: 67.1,
    p1: 69.1,
    p3: 70.3,
    p5: 70.9,
    p10: 71.8,
    p15: 72.5,
    p25: 73.4,
    p50: 75.2,
    p75: 77,
    p85: 77.9,
    p90: 78.6,
    p95: 79.5,
    p97: 80.2,
    p99: 81.3,
    p999: 83.3
  },
  {
    month: 14,
    l: 1,
    m: 76.3817,
    s: 0.03514,
    sd: 2.6841,
    p01: 68.1,
    p1: 70.1,
    p3: 71.3,
    p5: 72,
    p10: 72.9,
    p15: 73.6,
    p25: 74.6,
    p50: 76.4,
    p75: 78.2,
    p85: 79.2,
    p90: 79.8,
    p95: 80.8,
    p97: 81.4,
    p99: 82.6,
    p999: 84.7
  },
  {
    month: 15,
    l: 1,
    m: 77.5099,
    s: 0.03534,
    sd: 2.7392,
    p01: 69,
    p1: 71.1,
    p3: 72.4,
    p5: 73,
    p10: 74,
    p15: 74.7,
    p25: 75.7,
    p50: 77.5,
    p75: 79.4,
    p85: 80.3,
    p90: 81,
    p95: 82,
    p97: 82.7,
    p99: 83.9,
    p999: 86
  },
  {
    month: 16,
    l: 1,
    m: 78.6055,
    s: 0.03555,
    sd: 2.7944,
    p01: 70,
    p1: 72.1,
    p3: 73.3,
    p5: 74,
    p10: 75,
    p15: 75.7,
    p25: 76.7,
    p50: 78.6,
    p75: 80.5,
    p85: 81.5,
    p90: 82.2,
    p95: 83.2,
    p97: 83.9,
    p99: 85.1,
    p999: 87.2
  },
  {
    month: 17,
    l: 1,
    m: 79.671,
    s: 0.03576,
    sd: 2.849,
    p01: 70.9,
    p1: 73,
    p3: 74.3,
    p5: 75,
    p10: 76,
    p15: 76.7,
    p25: 77.7,
    p50: 79.7,
    p75: 81.6,
    p85: 82.6,
    p90: 83.3,
    p95: 84.4,
    p97: 85,
    p99: 86.3,
    p999: 88.5
  },
  {
    month: 18,
    l: 1,
    m: 80.7079,
    s: 0.03598,
    sd: 2.9039,
    p01: 71.7,
    p1: 74,
    p3: 75.2,
    p5: 75.9,
    p10: 77,
    p15: 77.7,
    p25: 78.7,
    p50: 80.7,
    p75: 82.7,
    p85: 83.7,
    p90: 84.4,
    p95: 85.5,
    p97: 86.2,
    p99: 87.5,
    p999: 89.7
  },
  {
    month: 19,
    l: 1,
    m: 81.7182,
    s: 0.0362,
    sd: 2.9582,
    p01: 72.6,
    p1: 74.8,
    p3: 76.2,
    p5: 76.9,
    p10: 77.9,
    p15: 78.7,
    p25: 79.7,
    p50: 81.7,
    p75: 83.7,
    p85: 84.8,
    p90: 85.5,
    p95: 86.6,
    p97: 87.3,
    p99: 88.6,
    p999: 90.9
  },
  {
    month: 20,
    l: 1,
    m: 82.7036,
    s: 0.03643,
    sd: 3.0129,
    p01: 73.4,
    p1: 75.7,
    p3: 77,
    p5: 77.7,
    p10: 78.8,
    p15: 79.6,
    p25: 80.7,
    p50: 82.7,
    p75: 84.7,
    p85: 85.8,
    p90: 86.6,
    p95: 87.7,
    p97: 88.4,
    p99: 89.7,
    p999: 92
  },
  {
    month: 21,
    l: 1,
    m: 83.6654,
    s: 0.03666,
    sd: 3.0672,
    p01: 74.2,
    p1: 76.5,
    p3: 77.9,
    p5: 78.6,
    p10: 79.7,
    p15: 80.5,
    p25: 81.6,
    p50: 83.7,
    p75: 85.7,
    p85: 86.8,
    p90: 87.6,
    p95: 88.7,
    p97: 89.4,
    p99: 90.8,
    p999: 93.1
  },
  {
    month: 22,
    l: 1,
    m: 84.604,
    s: 0.03688,
    sd: 3.1202,
    p01: 75,
    p1: 77.3,
    p3: 78.7,
    p5: 79.5,
    p10: 80.6,
    p15: 81.4,
    p25: 82.5,
    p50: 84.6,
    p75: 86.7,
    p85: 87.8,
    p90: 88.6,
    p95: 89.7,
    p97: 90.5,
    p99: 91.9,
    p999: 94.2
  },
  {
    month: 23,
    l: 1,
    m: 85.5202,
    s: 0.03711,
    sd: 3.1737,
    p01: 75.7,
    p1: 78.1,
    p3: 79.6,
    p5: 80.3,
    p10: 81.5,
    p15: 82.2,
    p25: 83.4,
    p50: 85.5,
    p75: 87.7,
    p85: 88.8,
    p90: 89.6,
    p95: 90.7,
    p97: 91.5,
    p99: 92.9,
    p999: 95.3
  },
  {
    month: 24,
    l: 1,
    m: 86.4153,
    s: 0.03734,
    sd: 3.2267,
    p01: 76.4,
    p1: 78.9,
    p3: 80.3,
    p5: 81.1,
    p10: 82.3,
    p15: 83.1,
    p25: 84.2,
    p50: 86.4,
    p75: 88.6,
    p85: 89.8,
    p90: 90.6,
    p95: 91.7,
    p97: 92.5,
    p99: 93.9,
    p999: 96.4
  },
  {
    month: 25,
    l: 1,
    m: 86.5904,
    s: 0.03786,
    sd: 3.2783,
    p01: 76.5,
    p1: 79,
    p3: 80.4,
    p5: 81.2,
    p10: 82.4,
    p15: 83.2,
    p25: 84.4,
    p50: 86.6,
    p75: 88.8,
    p85: 90,
    p90: 90.8,
    p95: 92,
    p97: 92.8,
    p99: 94.2,
    p999: 96.7
  },
  {
    month: 26,
    l: 1,
    m: 87.4462,
    s: 0.03808,
    sd: 3.33,
    p01: 77.2,
    p1: 79.7,
    p3: 81.2,
    p5: 82,
    p10: 83.2,
    p15: 84,
    p25: 85.2,
    p50: 87.4,
    p75: 89.7,
    p85: 90.9,
    p90: 91.7,
    p95: 92.9,
    p97: 93.7,
    p99: 95.2,
    p999: 97.7
  },
  {
    month: 27,
    l: 1,
    m: 88.283,
    s: 0.0383,
    sd: 3.3812,
    p01: 77.8,
    p1: 80.4,
    p3: 81.9,
    p5: 82.7,
    p10: 83.9,
    p15: 84.8,
    p25: 86,
    p50: 88.3,
    p75: 90.6,
    p85: 91.8,
    p90: 92.6,
    p95: 93.8,
    p97: 94.6,
    p99: 96.1,
    p999: 98.7
  },
  {
    month: 28,
    l: 1,
    m: 89.1004,
    s: 0.03851,
    sd: 3.4313,
    p01: 78.5,
    p1: 81.1,
    p3: 82.6,
    p5: 83.5,
    p10: 84.7,
    p15: 85.5,
    p25: 86.8,
    p50: 89.1,
    p75: 91.4,
    p85: 92.7,
    p90: 93.5,
    p95: 94.7,
    p97: 95.6,
    p99: 97.1,
    p999: 99.7
  },
  {
    month: 29,
    l: 1,
    m: 89.8991,
    s: 0.03872,
    sd: 3.4809,
    p01: 79.1,
    p1: 81.8,
    p3: 83.4,
    p5: 84.2,
    p10: 85.4,
    p15: 86.3,
    p25: 87.6,
    p50: 89.9,
    p75: 92.2,
    p85: 93.5,
    p90: 94.4,
    p95: 95.6,
    p97: 96.4,
    p99: 98,
    p999: 100.7
  },
  {
    month: 30,
    l: 1,
    m: 90.6797,
    s: 0.03893,
    sd: 3.5302,
    p01: 79.8,
    p1: 82.5,
    p3: 84,
    p5: 84.9,
    p10: 86.2,
    p15: 87,
    p25: 88.3,
    p50: 90.7,
    p75: 93.1,
    p85: 94.3,
    p90: 95.2,
    p95: 96.5,
    p97: 97.3,
    p99: 98.9,
    p999: 101.6
  },
  {
    month: 31,
    l: 1,
    m: 91.443,
    s: 0.03913,
    sd: 3.5782,
    p01: 80.4,
    p1: 83.1,
    p3: 84.7,
    p5: 85.6,
    p10: 86.9,
    p15: 87.7,
    p25: 89,
    p50: 91.4,
    p75: 93.9,
    p85: 95.2,
    p90: 96,
    p95: 97.3,
    p97: 98.2,
    p99: 99.8,
    p999: 102.5
  },
  {
    month: 32,
    l: 1,
    m: 92.1906,
    s: 0.03933,
    sd: 3.6259,
    p01: 81,
    p1: 83.8,
    p3: 85.4,
    p5: 86.2,
    p10: 87.5,
    p15: 88.4,
    p25: 89.7,
    p50: 92.2,
    p75: 94.6,
    p85: 95.9,
    p90: 96.8,
    p95: 98.2,
    p97: 99,
    p99: 100.6,
    p999: 103.4
  },
  {
    month: 33,
    l: 1,
    m: 92.9239,
    s: 0.03952,
    sd: 3.6724,
    p01: 81.6,
    p1: 84.4,
    p3: 86,
    p5: 86.9,
    p10: 88.2,
    p15: 89.1,
    p25: 90.4,
    p50: 92.9,
    p75: 95.4,
    p85: 96.7,
    p90: 97.6,
    p95: 99,
    p97: 99.8,
    p99: 101.5,
    p999: 104.3
  },
  {
    month: 34,
    l: 1,
    m: 93.6444,
    s: 0.03971,
    sd: 3.7186,
    p01: 82.2,
    p1: 85,
    p3: 86.7,
    p5: 87.5,
    p10: 88.9,
    p15: 89.8,
    p25: 91.1,
    p50: 93.6,
    p75: 96.2,
    p85: 97.5,
    p90: 98.4,
    p95: 99.8,
    p97: 100.6,
    p99: 102.3,
    p999: 105.1
  },
  {
    month: 35,
    l: 1,
    m: 94.3533,
    s: 0.03989,
    sd: 3.7638,
    p01: 82.7,
    p1: 85.6,
    p3: 87.3,
    p5: 88.2,
    p10: 89.5,
    p15: 90.5,
    p25: 91.8,
    p50: 94.4,
    p75: 96.9,
    p85: 98.3,
    p90: 99.2,
    p95: 100.5,
    p97: 101.4,
    p99: 103.1,
    p999: 106
  },
  {
    month: 36,
    l: 1,
    m: 95.0515,
    s: 0.04006,
    sd: 3.8078,
    p01: 83.3,
    p1: 86.2,
    p3: 87.9,
    p5: 88.8,
    p10: 90.2,
    p15: 91.1,
    p25: 92.5,
    p50: 95.1,
    p75: 97.6,
    p85: 99,
    p90: 99.9,
    p95: 101.3,
    p97: 102.2,
    p99: 103.9,
    p999: 106.8
  },
  {
    month: 37,
    l: 1,
    m: 95.7399,
    s: 0.04024,
    sd: 3.8526,
    p01: 83.8,
    p1: 86.8,
    p3: 88.5,
    p5: 89.4,
    p10: 90.8,
    p15: 91.7,
    p25: 93.1,
    p50: 95.7,
    p75: 98.3,
    p85: 99.7,
    p90: 100.7,
    p95: 102.1,
    p97: 103,
    p99: 104.7,
    p999: 107.6
  },
  {
    month: 38,
    l: 1,
    m: 96.4187,
    s: 0.04041,
    sd: 3.8963,
    p01: 84.4,
    p1: 87.4,
    p3: 89.1,
    p5: 90,
    p10: 91.4,
    p15: 92.4,
    p25: 93.8,
    p50: 96.4,
    p75: 99,
    p85: 100.5,
    p90: 101.4,
    p95: 102.8,
    p97: 103.7,
    p99: 105.5,
    p999: 108.5
  },
  {
    month: 39,
    l: 1,
    m: 97.0885,
    s: 0.04057,
    sd: 3.9389,
    p01: 84.9,
    p1: 87.9,
    p3: 89.7,
    p5: 90.6,
    p10: 92,
    p15: 93,
    p25: 94.4,
    p50: 97.1,
    p75: 99.7,
    p85: 101.2,
    p90: 102.1,
    p95: 103.6,
    p97: 104.5,
    p99: 106.3,
    p999: 109.3
  },
  {
    month: 40,
    l: 1,
    m: 97.7493,
    s: 0.04073,
    sd: 3.9813,
    p01: 85.4,
    p1: 88.5,
    p3: 90.3,
    p5: 91.2,
    p10: 92.6,
    p15: 93.6,
    p25: 95.1,
    p50: 97.7,
    p75: 100.4,
    p85: 101.9,
    p90: 102.9,
    p95: 104.3,
    p97: 105.2,
    p99: 107,
    p999: 110.1
  },
  {
    month: 41,
    l: 1,
    m: 98.4015,
    s: 0.04089,
    sd: 4.0236,
    p01: 86,
    p1: 89,
    p3: 90.8,
    p5: 91.8,
    p10: 93.2,
    p15: 94.2,
    p25: 95.7,
    p50: 98.4,
    p75: 101.1,
    p85: 102.6,
    p90: 103.6,
    p95: 105,
    p97: 106,
    p99: 107.8,
    p999: 110.8
  },
  {
    month: 42,
    l: 1,
    m: 99.0448,
    s: 0.04105,
    sd: 4.0658,
    p01: 86.5,
    p1: 89.6,
    p3: 91.4,
    p5: 92.4,
    p10: 93.8,
    p15: 94.8,
    p25: 96.3,
    p50: 99,
    p75: 101.8,
    p85: 103.3,
    p90: 104.3,
    p95: 105.7,
    p97: 106.7,
    p99: 108.5,
    p999: 111.6
  },
  {
    month: 43,
    l: 1,
    m: 99.6795,
    s: 0.0412,
    sd: 4.1068,
    p01: 87,
    p1: 90.1,
    p3: 92,
    p5: 92.9,
    p10: 94.4,
    p15: 95.4,
    p25: 96.9,
    p50: 99.7,
    p75: 102.4,
    p85: 103.9,
    p90: 104.9,
    p95: 106.4,
    p97: 107.4,
    p99: 109.2,
    p999: 112.4
  },
  {
    month: 44,
    l: 1,
    m: 100.3058,
    s: 0.04135,
    sd: 4.1476,
    p01: 87.5,
    p1: 90.7,
    p3: 92.5,
    p5: 93.5,
    p10: 95,
    p15: 96,
    p25: 97.5,
    p50: 100.3,
    p75: 103.1,
    p85: 104.6,
    p90: 105.6,
    p95: 107.1,
    p97: 108.1,
    p99: 110,
    p999: 113.1
  },
  {
    month: 45,
    l: 1,
    m: 100.9238,
    s: 0.0415,
    sd: 4.1883,
    p01: 88,
    p1: 91.2,
    p3: 93,
    p5: 94,
    p10: 95.6,
    p15: 96.6,
    p25: 98.1,
    p50: 100.9,
    p75: 103.7,
    p85: 105.3,
    p90: 106.3,
    p95: 107.8,
    p97: 108.8,
    p99: 110.7,
    p999: 113.9
  },
  {
    month: 46,
    l: 1,
    m: 101.5337,
    s: 0.04164,
    sd: 4.2279,
    p01: 88.5,
    p1: 91.7,
    p3: 93.6,
    p5: 94.6,
    p10: 96.1,
    p15: 97.2,
    p25: 98.7,
    p50: 101.5,
    p75: 104.4,
    p85: 105.9,
    p90: 107,
    p95: 108.5,
    p97: 109.5,
    p99: 111.4,
    p999: 114.6
  },
  {
    month: 47,
    l: 1,
    m: 102.136,
    s: 0.04179,
    sd: 4.2683,
    p01: 88.9,
    p1: 92.2,
    p3: 94.1,
    p5: 95.1,
    p10: 96.7,
    p15: 97.7,
    p25: 99.3,
    p50: 102.1,
    p75: 105,
    p85: 106.6,
    p90: 107.6,
    p95: 109.2,
    p97: 110.2,
    p99: 112.1,
    p999: 115.3
  },
  {
    month: 48,
    l: 1,
    m: 102.7312,
    s: 0.04193,
    sd: 4.3075,
    p01: 89.4,
    p1: 92.7,
    p3: 94.6,
    p5: 95.6,
    p10: 97.2,
    p15: 98.3,
    p25: 99.8,
    p50: 102.7,
    p75: 105.6,
    p85: 107.2,
    p90: 108.3,
    p95: 109.8,
    p97: 110.8,
    p99: 112.8,
    p999: 116
  },
  {
    month: 49,
    l: 1,
    m: 103.3197,
    s: 0.04206,
    sd: 4.3456,
    p01: 89.9,
    p1: 93.2,
    p3: 95.1,
    p5: 96.2,
    p10: 97.8,
    p15: 98.8,
    p25: 100.4,
    p50: 103.3,
    p75: 106.3,
    p85: 107.8,
    p90: 108.9,
    p95: 110.5,
    p97: 111.5,
    p99: 113.4,
    p999: 116.7
  },
  {
    month: 50,
    l: 1,
    m: 103.9021,
    s: 0.0422,
    sd: 4.3847,
    p01: 90.4,
    p1: 93.7,
    p3: 95.7,
    p5: 96.7,
    p10: 98.3,
    p15: 99.4,
    p25: 100.9,
    p50: 103.9,
    p75: 106.9,
    p85: 108.4,
    p90: 109.5,
    p95: 111.1,
    p97: 112.1,
    p99: 114.1,
    p999: 117.5
  },
  {
    month: 51,
    l: 1,
    m: 104.4786,
    s: 0.04233,
    sd: 4.4226,
    p01: 90.8,
    p1: 94.2,
    p3: 96.2,
    p5: 97.2,
    p10: 98.8,
    p15: 99.9,
    p25: 101.5,
    p50: 104.5,
    p75: 107.5,
    p85: 109.1,
    p90: 110.1,
    p95: 111.8,
    p97: 112.8,
    p99: 114.8,
    p999: 118.1
  },
  {
    month: 52,
    l: 1,
    m: 105.0494,
    s: 0.04246,
    sd: 4.4604,
    p01: 91.3,
    p1: 94.7,
    p3: 96.7,
    p5: 97.7,
    p10: 99.3,
    p15: 100.4,
    p25: 102,
    p50: 105,
    p75: 108.1,
    p85: 109.7,
    p90: 110.8,
    p95: 112.4,
    p97: 113.4,
    p99: 115.4,
    p999: 118.8
  },
  {
    month: 53,
    l: 1,
    m: 105.6148,
    s: 0.04259,
    sd: 4.4981,
    p01: 91.7,
    p1: 95.2,
    p3: 97.2,
    p5: 98.2,
    p10: 99.9,
    p15: 101,
    p25: 102.6,
    p50: 105.6,
    p75: 108.6,
    p85: 110.3,
    p90: 111.4,
    p95: 113,
    p97: 114.1,
    p99: 116.1,
    p999: 119.5
  },
  {
    month: 54,
    l: 1,
    m: 106.1748,
    s: 0.04272,
    sd: 4.5358,
    p01: 92.2,
    p1: 95.6,
    p3: 97.6,
    p5: 98.7,
    p10: 100.4,
    p15: 101.5,
    p25: 103.1,
    p50: 106.2,
    p75: 109.2,
    p85: 110.9,
    p90: 112,
    p95: 113.6,
    p97: 114.7,
    p99: 116.7,
    p999: 120.2
  },
  {
    month: 55,
    l: 1,
    m: 106.7295,
    s: 0.04285,
    sd: 4.5734,
    p01: 92.6,
    p1: 96.1,
    p3: 98.1,
    p5: 99.2,
    p10: 100.9,
    p15: 102,
    p25: 103.6,
    p50: 106.7,
    p75: 109.8,
    p85: 111.5,
    p90: 112.6,
    p95: 114.3,
    p97: 115.3,
    p99: 117.4,
    p999: 120.9
  },
  {
    month: 56,
    l: 1,
    m: 107.2788,
    s: 0.04298,
    sd: 4.6108,
    p01: 93,
    p1: 96.6,
    p3: 98.6,
    p5: 99.7,
    p10: 101.4,
    p15: 102.5,
    p25: 104.2,
    p50: 107.3,
    p75: 110.4,
    p85: 112.1,
    p90: 113.2,
    p95: 114.9,
    p97: 116,
    p99: 118,
    p999: 121.5
  },
  {
    month: 57,
    l: 1,
    m: 107.8227,
    s: 0.0431,
    sd: 4.6472,
    p01: 93.5,
    p1: 97,
    p3: 99.1,
    p5: 100.2,
    p10: 101.9,
    p15: 103,
    p25: 104.7,
    p50: 107.8,
    p75: 111,
    p85: 112.6,
    p90: 113.8,
    p95: 115.5,
    p97: 116.6,
    p99: 118.6,
    p999: 122.2
  },
  {
    month: 58,
    l: 1,
    m: 108.3613,
    s: 0.04322,
    sd: 4.6834,
    p01: 93.9,
    p1: 97.5,
    p3: 99.6,
    p5: 100.7,
    p10: 102.4,
    p15: 103.5,
    p25: 105.2,
    p50: 108.4,
    p75: 111.5,
    p85: 113.2,
    p90: 114.4,
    p95: 116.1,
    p97: 117.2,
    p99: 119.3,
    p999: 122.8
  },
  {
    month: 59,
    l: 1,
    m: 108.8948,
    s: 0.04334,
    sd: 4.7195,
    p01: 94.3,
    p1: 97.9,
    p3: 100,
    p5: 101.1,
    p10: 102.8,
    p15: 104,
    p25: 105.7,
    p50: 108.9,
    p75: 112.1,
    p85: 113.8,
    p90: 114.9,
    p95: 116.7,
    p97: 117.8,
    p99: 119.9,
    p999: 123.5
  },
  {
    month: 60,
    l: 1,
    m: 109.4233,
    s: 0.04347,
    sd: 4.7566,
    p01: 94.7,
    p1: 98.4,
    p3: 100.5,
    p5: 101.6,
    p10: 103.3,
    p15: 104.5,
    p25: 106.2,
    p50: 109.4,
    p75: 112.6,
    p85: 114.4,
    p90: 115.5,
    p95: 117.2,
    p97: 118.4,
    p99: 120.5,
    p999: 124.1
  },
  {
    month: 61,
    l: 1,
    m: 109.6016,
    s: 0.04355,
    sd: 4.7731,
    p01: 94.851,
    p1: 98.498,
    p3: 100.624,
    p5: 101.75,
    p10: 103.485,
    p15: 104.655,
    p25: 106.382,
    p50: 109.602,
    p75: 112.821,
    p85: 114.549,
    p90: 115.719,
    p95: 117.453,
    p97: 118.579,
    p99: 120.706,
    p999: 124.352
  },
  {
    month: 62,
    l: 1,
    m: 110.1258,
    s: 0.04364,
    sd: 4.8059,
    p01: 95.274,
    p1: 98.946,
    p3: 101.087,
    p5: 102.221,
    p10: 103.967,
    p15: 105.145,
    p25: 106.884,
    p50: 110.126,
    p75: 113.367,
    p85: 115.107,
    p90: 116.285,
    p95: 118.031,
    p97: 119.165,
    p99: 121.306,
    p999: 124.977
  },
  {
    month: 63,
    l: 1,
    m: 110.6451,
    s: 0.04373,
    sd: 4.8385,
    p01: 95.693,
    p1: 99.389,
    p3: 101.545,
    p5: 102.686,
    p10: 104.444,
    p15: 105.63,
    p25: 107.382,
    p50: 110.645,
    p75: 113.909,
    p85: 115.66,
    p90: 116.846,
    p95: 118.604,
    p97: 119.745,
    p99: 121.901,
    p999: 125.597
  },
  {
    month: 64,
    l: 1,
    m: 111.1596,
    s: 0.04382,
    sd: 4.871,
    p01: 96.107,
    p1: 99.828,
    p3: 101.998,
    p5: 103.147,
    p10: 104.917,
    p15: 106.111,
    p25: 107.874,
    p50: 111.16,
    p75: 114.445,
    p85: 116.208,
    p90: 117.402,
    p95: 119.172,
    p97: 120.321,
    p99: 122.491,
    p999: 126.212
  },
  {
    month: 65,
    l: 1,
    m: 111.6696,
    s: 0.0439,
    sd: 4.9023,
    p01: 96.52,
    p1: 100.265,
    p3: 102.449,
    p5: 103.606,
    p10: 105.387,
    p15: 106.589,
    p25: 108.363,
    p50: 111.67,
    p75: 114.976,
    p85: 116.751,
    p90: 117.952,
    p95: 119.733,
    p97: 120.89,
    p99: 123.074,
    p999: 126.819
  },
  {
    month: 66,
    l: 1,
    m: 112.1753,
    s: 0.04399,
    sd: 4.9346,
    p01: 96.926,
    p1: 100.696,
    p3: 102.894,
    p5: 104.059,
    p10: 105.851,
    p15: 107.061,
    p25: 108.847,
    p50: 112.175,
    p75: 115.504,
    p85: 117.29,
    p90: 118.499,
    p95: 120.292,
    p97: 121.456,
    p99: 123.655,
    p999: 127.424
  },
  {
    month: 67,
    l: 1,
    m: 112.6767,
    s: 0.04407,
    sd: 4.9657,
    p01: 97.332,
    p1: 101.125,
    p3: 103.337,
    p5: 104.509,
    p10: 106.313,
    p15: 107.53,
    p25: 109.327,
    p50: 112.677,
    p75: 116.026,
    p85: 117.823,
    p90: 119.04,
    p95: 120.844,
    p97: 122.016,
    p99: 124.229,
    p999: 128.022
  },
  {
    month: 68,
    l: 1,
    m: 113.174,
    s: 0.04415,
    sd: 4.9966,
    p01: 97.733,
    p1: 101.55,
    p3: 103.776,
    p5: 104.955,
    p10: 106.771,
    p15: 107.995,
    p25: 109.804,
    p50: 113.174,
    p75: 116.544,
    p85: 118.353,
    p90: 119.577,
    p95: 121.393,
    p97: 122.572,
    p99: 124.798,
    p999: 128.615
  },
  {
    month: 69,
    l: 1,
    m: 113.6672,
    s: 0.04423,
    sd: 5.0275,
    p01: 98.131,
    p1: 101.971,
    p3: 104.212,
    p5: 105.398,
    p10: 107.224,
    p15: 108.457,
    p25: 110.276,
    p50: 113.667,
    p75: 117.058,
    p85: 118.878,
    p90: 120.11,
    p95: 121.937,
    p97: 123.123,
    p99: 125.363,
    p999: 129.203
  },
  {
    month: 70,
    l: 1,
    m: 114.1565,
    s: 0.04431,
    sd: 5.0583,
    p01: 98.525,
    p1: 102.389,
    p3: 104.643,
    p5: 105.836,
    p10: 107.674,
    p15: 108.914,
    p25: 110.745,
    p50: 114.156,
    p75: 117.568,
    p85: 119.399,
    p90: 120.639,
    p95: 122.477,
    p97: 123.67,
    p99: 125.924,
    p999: 129.788
  },
  {
    month: 71,
    l: 1,
    m: 114.6421,
    s: 0.04439,
    sd: 5.089,
    p01: 98.916,
    p1: 102.803,
    p3: 105.071,
    p5: 106.272,
    p10: 108.12,
    p15: 109.368,
    p25: 111.21,
    p50: 114.642,
    p75: 118.075,
    p85: 119.916,
    p90: 121.164,
    p95: 123.013,
    p97: 124.213,
    p99: 126.481,
    p999: 130.368
  },
  {
    month: 72,
    l: 1,
    m: 115.1244,
    s: 0.04447,
    sd: 5.1196,
    p01: 99.304,
    p1: 103.214,
    p3: 105.496,
    p5: 106.703,
    p10: 108.563,
    p15: 109.818,
    p25: 111.671,
    p50: 115.124,
    p75: 118.578,
    p85: 120.431,
    p90: 121.685,
    p95: 123.545,
    p97: 124.753,
    p99: 127.034,
    p999: 130.945
  },
  {
    month: 73,
    l: 1,
    m: 115.6039,
    s: 0.04454,
    sd: 5.149,
    p01: 99.692,
    p1: 103.626,
    p3: 105.92,
    p5: 107.135,
    p10: 109.005,
    p15: 110.267,
    p25: 112.131,
    p50: 115.604,
    p75: 119.077,
    p85: 120.94,
    p90: 122.203,
    p95: 124.073,
    p97: 125.288,
    p99: 127.582,
    p999: 131.515
  },
  {
    month: 74,
    l: 1,
    m: 116.0812,
    s: 0.04461,
    sd: 5.1784,
    p01: 100.079,
    p1: 104.034,
    p3: 106.342,
    p5: 107.564,
    p10: 109.445,
    p15: 110.714,
    p25: 112.588,
    p50: 116.081,
    p75: 119.574,
    p85: 121.448,
    p90: 122.718,
    p95: 124.599,
    p97: 125.821,
    p99: 128.128,
    p999: 132.084
  },
  {
    month: 75,
    l: 1,
    m: 116.5568,
    s: 0.04469,
    sd: 5.2089,
    p01: 100.46,
    p1: 104.439,
    p3: 106.76,
    p5: 107.989,
    p10: 109.881,
    p15: 111.158,
    p25: 113.043,
    p50: 116.557,
    p75: 120.07,
    p85: 121.956,
    p90: 123.232,
    p95: 125.125,
    p97: 126.354,
    p99: 128.675,
    p999: 132.654
  },
  {
    month: 76,
    l: 1,
    m: 117.0311,
    s: 0.04475,
    sd: 5.2371,
    p01: 100.847,
    p1: 104.848,
    p3: 107.181,
    p5: 108.417,
    p10: 110.319,
    p15: 111.603,
    p25: 113.499,
    p50: 117.031,
    p75: 120.563,
    p85: 122.459,
    p90: 123.743,
    p95: 125.645,
    p97: 126.881,
    p99: 129.215,
    p999: 133.215
  },
  {
    month: 77,
    l: 1,
    m: 117.5044,
    s: 0.04482,
    sd: 5.2665,
    p01: 101.23,
    p1: 105.253,
    p3: 107.599,
    p5: 108.842,
    p10: 110.755,
    p15: 112.046,
    p25: 113.952,
    p50: 117.504,
    p75: 121.057,
    p85: 122.963,
    p90: 124.254,
    p95: 126.167,
    p97: 127.41,
    p99: 129.756,
    p999: 133.779
  },
  {
    month: 78,
    l: 1,
    m: 117.9769,
    s: 0.04489,
    sd: 5.296,
    p01: 101.611,
    p1: 105.657,
    p3: 108.016,
    p5: 109.266,
    p10: 111.19,
    p15: 112.488,
    p25: 114.405,
    p50: 117.977,
    p75: 121.549,
    p85: 123.466,
    p90: 124.764,
    p95: 126.688,
    p97: 127.938,
    p99: 130.297,
    p999: 134.343
  },
  {
    month: 79,
    l: 1,
    m: 118.4489,
    s: 0.04495,
    sd: 5.3243,
    p01: 101.996,
    p1: 106.063,
    p3: 108.435,
    p5: 109.691,
    p10: 111.626,
    p15: 112.931,
    p25: 114.858,
    p50: 118.449,
    p75: 122.04,
    p85: 123.967,
    p90: 125.272,
    p95: 127.207,
    p97: 128.463,
    p99: 130.835,
    p999: 134.902
  },
  {
    month: 80,
    l: 1,
    m: 118.9208,
    s: 0.04502,
    sd: 5.3538,
    p01: 102.376,
    p1: 106.466,
    p3: 108.851,
    p5: 110.115,
    p10: 112.06,
    p15: 113.372,
    p25: 115.31,
    p50: 118.921,
    p75: 122.532,
    p85: 124.47,
    p90: 125.782,
    p95: 127.727,
    p97: 128.99,
    p99: 131.376,
    p999: 135.465
  },
  {
    month: 81,
    l: 1,
    m: 119.3926,
    s: 0.04508,
    sd: 5.3822,
    p01: 102.76,
    p1: 106.872,
    p3: 109.27,
    p5: 110.54,
    p10: 112.495,
    p15: 113.814,
    p25: 115.762,
    p50: 119.393,
    p75: 123.023,
    p85: 124.971,
    p90: 126.29,
    p95: 128.246,
    p97: 129.515,
    p99: 131.914,
    p999: 136.025
  },
  {
    month: 82,
    l: 1,
    m: 119.8648,
    s: 0.04514,
    sd: 5.4107,
    p01: 103.144,
    p1: 107.278,
    p3: 109.688,
    p5: 110.965,
    p10: 112.931,
    p15: 114.257,
    p25: 116.215,
    p50: 119.865,
    p75: 123.514,
    p85: 125.473,
    p90: 126.799,
    p95: 128.765,
    p97: 130.041,
    p99: 132.452,
    p999: 136.585
  },
  {
    month: 83,
    l: 1,
    m: 120.3374,
    s: 0.0452,
    sd: 5.4393,
    p01: 103.529,
    p1: 107.684,
    p3: 110.107,
    p5: 111.391,
    p10: 113.367,
    p15: 114.7,
    p25: 116.669,
    p50: 120.337,
    p75: 124.006,
    p85: 125.975,
    p90: 127.308,
    p95: 129.284,
    p97: 130.568,
    p99: 132.991,
    p999: 137.146
  },
  {
    month: 84,
    l: 1,
    m: 120.8105,
    s: 0.04525,
    sd: 5.4667,
    p01: 103.917,
    p1: 108.093,
    p3: 110.529,
    p5: 111.819,
    p10: 113.805,
    p15: 115.145,
    p25: 117.123,
    p50: 120.81,
    p75: 124.498,
    p85: 126.476,
    p90: 127.816,
    p95: 129.802,
    p97: 131.092,
    p99: 133.528,
    p999: 137.704
  },
  {
    month: 85,
    l: 1,
    m: 121.2843,
    s: 0.04531,
    sd: 5.4954,
    p01: 104.302,
    p1: 108.5,
    p3: 110.949,
    p5: 112.245,
    p10: 114.242,
    p15: 115.589,
    p25: 117.578,
    p50: 121.284,
    p75: 124.991,
    p85: 126.98,
    p90: 128.327,
    p95: 130.323,
    p97: 131.62,
    p99: 134.068,
    p999: 138.266
  },
  {
    month: 86,
    l: 1,
    m: 121.7587,
    s: 0.04536,
    sd: 5.523,
    p01: 104.691,
    p1: 108.91,
    p3: 111.371,
    p5: 112.674,
    p10: 114.681,
    p15: 116.035,
    p25: 118.034,
    p50: 121.759,
    p75: 125.484,
    p85: 127.483,
    p90: 128.837,
    p95: 130.843,
    p97: 132.146,
    p99: 134.607,
    p999: 138.826
  },
  {
    month: 87,
    l: 1,
    m: 122.2338,
    s: 0.04542,
    sd: 5.5519,
    p01: 105.077,
    p1: 109.318,
    p3: 111.792,
    p5: 113.102,
    p10: 115.119,
    p15: 116.48,
    p25: 118.489,
    p50: 122.234,
    p75: 125.978,
    p85: 127.988,
    p90: 129.349,
    p95: 131.366,
    p97: 132.676,
    p99: 135.149,
    p999: 139.39
  },
  {
    month: 88,
    l: 1,
    m: 122.7098,
    s: 0.04547,
    sd: 5.5796,
    p01: 105.467,
    p1: 109.73,
    p3: 112.216,
    p5: 113.532,
    p10: 115.559,
    p15: 116.927,
    p25: 118.946,
    p50: 122.71,
    p75: 126.473,
    p85: 128.493,
    p90: 129.86,
    p95: 131.887,
    p97: 133.204,
    p99: 135.69,
    p999: 139.952
  },
  {
    month: 89,
    l: 1,
    m: 123.1868,
    s: 0.04551,
    sd: 5.6062,
    p01: 105.862,
    p1: 110.145,
    p3: 112.643,
    p5: 113.965,
    p10: 116.002,
    p15: 117.376,
    p25: 119.405,
    p50: 123.187,
    p75: 126.968,
    p85: 128.997,
    p90: 130.371,
    p95: 132.408,
    p97: 133.731,
    p99: 136.229,
    p999: 140.511
  },
  {
    month: 90,
    l: 1,
    m: 123.6646,
    s: 0.04556,
    sd: 5.6342,
    p01: 106.254,
    p1: 110.558,
    p3: 113.068,
    p5: 114.397,
    p10: 116.444,
    p15: 117.825,
    p25: 119.864,
    p50: 123.665,
    p75: 127.465,
    p85: 129.504,
    p90: 130.885,
    p95: 132.932,
    p97: 134.261,
    p99: 136.772,
    p999: 141.075
  },
  {
    month: 91,
    l: 1,
    m: 124.1435,
    s: 0.04561,
    sd: 5.6622,
    p01: 106.646,
    p1: 110.971,
    p3: 113.494,
    p5: 114.83,
    p10: 116.887,
    p15: 118.275,
    p25: 120.324,
    p50: 124.144,
    p75: 127.963,
    p85: 130.012,
    p90: 131.4,
    p95: 133.457,
    p97: 134.793,
    p99: 137.316,
    p999: 141.641
  },
  {
    month: 92,
    l: 1,
    m: 124.6234,
    s: 0.04565,
    sd: 5.6891,
    p01: 107.043,
    p1: 111.389,
    p3: 113.923,
    p5: 115.266,
    p10: 117.333,
    p15: 118.727,
    p25: 120.786,
    p50: 124.623,
    p75: 128.461,
    p85: 130.52,
    p90: 131.914,
    p95: 133.981,
    p97: 135.323,
    p99: 137.858,
    p999: 142.204
  },
  {
    month: 93,
    l: 1,
    m: 125.1045,
    s: 0.04569,
    sd: 5.716,
    p01: 107.441,
    p1: 111.807,
    p3: 114.354,
    p5: 115.702,
    p10: 117.779,
    p15: 119.18,
    p25: 121.249,
    p50: 125.104,
    p75: 128.96,
    p85: 131.029,
    p90: 132.43,
    p95: 134.507,
    p97: 135.855,
    p99: 138.402,
    p999: 142.768
  },
  {
    month: 94,
    l: 1,
    m: 125.5869,
    s: 0.04573,
    sd: 5.7431,
    p01: 107.839,
    p1: 112.226,
    p3: 114.785,
    p5: 116.14,
    p10: 118.227,
    p15: 119.635,
    p25: 121.713,
    p50: 125.587,
    p75: 129.461,
    p85: 131.539,
    p90: 132.947,
    p95: 135.033,
    p97: 136.388,
    p99: 138.947,
    p999: 143.334
  },
  {
    month: 95,
    l: 1,
    m: 126.0706,
    s: 0.04577,
    sd: 5.7703,
    p01: 108.239,
    p1: 112.647,
    p3: 115.218,
    p5: 116.579,
    p10: 118.676,
    p15: 120.09,
    p25: 122.179,
    p50: 126.071,
    p75: 129.963,
    p85: 132.051,
    p90: 133.465,
    p95: 135.562,
    p97: 136.923,
    p99: 139.494,
    p999: 143.902
  },
  {
    month: 96,
    l: 1,
    m: 126.5558,
    s: 0.04581,
    sd: 5.7975,
    p01: 108.64,
    p1: 113.069,
    p3: 115.652,
    p5: 117.02,
    p10: 119.126,
    p15: 120.547,
    p25: 122.645,
    p50: 126.556,
    p75: 130.466,
    p85: 132.565,
    p90: 133.986,
    p95: 136.092,
    p97: 137.46,
    p99: 140.043,
    p999: 144.471
  },
  {
    month: 97,
    l: 1,
    m: 127.0424,
    s: 0.04585,
    sd: 5.8249,
    p01: 109.042,
    p1: 113.492,
    p3: 116.087,
    p5: 117.461,
    p10: 119.577,
    p15: 121.005,
    p25: 123.114,
    p50: 127.042,
    p75: 130.971,
    p85: 133.08,
    p90: 134.507,
    p95: 136.623,
    p97: 137.998,
    p99: 140.593,
    p999: 145.043
  },
  {
    month: 98,
    l: 1,
    m: 127.5304,
    s: 0.04588,
    sd: 5.8511,
    p01: 109.449,
    p1: 113.919,
    p3: 116.526,
    p5: 117.906,
    p10: 120.032,
    p15: 121.466,
    p25: 123.584,
    p50: 127.53,
    p75: 131.477,
    p85: 133.595,
    p90: 135.029,
    p95: 137.155,
    p97: 138.535,
    p99: 141.142,
    p999: 145.612
  },
  {
    month: 99,
    l: 1,
    m: 128.0199,
    s: 0.04591,
    sd: 5.8774,
    p01: 109.857,
    p1: 114.347,
    p3: 116.966,
    p5: 118.352,
    p10: 120.488,
    p15: 121.928,
    p25: 124.056,
    p50: 128.02,
    p75: 131.984,
    p85: 134.111,
    p90: 135.552,
    p95: 137.687,
    p97: 139.074,
    p99: 141.693,
    p999: 146.182
  },
  {
    month: 100,
    l: 1,
    m: 128.5109,
    s: 0.04594,
    sd: 5.9038,
    p01: 110.267,
    p1: 114.777,
    p3: 117.407,
    p5: 118.8,
    p10: 120.945,
    p15: 122.392,
    p25: 124.529,
    p50: 128.511,
    p75: 132.493,
    p85: 134.63,
    p90: 136.077,
    p95: 138.222,
    p97: 139.615,
    p99: 142.245,
    p999: 146.755
  },
  {
    month: 101,
    l: 1,
    m: 129.0035,
    s: 0.04597,
    sd: 5.9303,
    p01: 110.678,
    p1: 115.208,
    p3: 117.85,
    p5: 119.249,
    p10: 121.404,
    p15: 122.857,
    p25: 125.004,
    p50: 129.004,
    p75: 133.003,
    p85: 135.15,
    p90: 136.603,
    p95: 138.758,
    p97: 140.157,
    p99: 142.799,
    p999: 147.329
  },
  {
    month: 102,
    l: 1,
    m: 129.4975,
    s: 0.046,
    sd: 5.9569,
    p01: 111.089,
    p1: 115.64,
    p3: 118.294,
    p5: 119.699,
    p10: 121.863,
    p15: 123.324,
    p25: 125.48,
    p50: 129.498,
    p75: 133.515,
    p85: 135.671,
    p90: 137.132,
    p95: 139.296,
    p97: 140.701,
    p99: 143.355,
    p999: 147.906
  },
  {
    month: 103,
    l: 1,
    m: 129.9932,
    s: 0.04602,
    sd: 5.9823,
    p01: 111.507,
    p1: 116.076,
    p3: 118.742,
    p5: 120.153,
    p10: 122.327,
    p15: 123.793,
    p25: 125.958,
    p50: 129.993,
    p75: 134.028,
    p85: 136.193,
    p90: 137.66,
    p95: 139.833,
    p97: 141.245,
    p99: 143.91,
    p999: 148.48
  },
  {
    month: 104,
    l: 1,
    m: 130.4904,
    s: 0.04604,
    sd: 6.0078,
    p01: 111.925,
    p1: 116.514,
    p3: 119.191,
    p5: 120.608,
    p10: 122.791,
    p15: 124.264,
    p25: 126.438,
    p50: 130.49,
    p75: 134.543,
    p85: 136.717,
    p90: 138.19,
    p95: 140.372,
    p97: 141.79,
    p99: 144.467,
    p999: 149.056
  },
  {
    month: 105,
    l: 1,
    m: 130.9891,
    s: 0.04607,
    sd: 6.0347,
    p01: 112.341,
    p1: 116.95,
    p3: 119.639,
    p5: 121.063,
    p10: 123.255,
    p15: 124.735,
    p25: 126.919,
    p50: 130.989,
    p75: 135.059,
    p85: 137.244,
    p90: 138.723,
    p95: 140.915,
    p97: 142.339,
    p99: 145.028,
    p999: 149.638
  },
  {
    month: 106,
    l: 1,
    m: 131.4895,
    s: 0.04608,
    sd: 6.059,
    p01: 112.766,
    p1: 117.394,
    p3: 120.094,
    p5: 121.523,
    p10: 123.725,
    p15: 125.21,
    p25: 127.403,
    p50: 131.49,
    p75: 135.576,
    p85: 137.769,
    p90: 139.254,
    p95: 141.456,
    p97: 142.885,
    p99: 145.585,
    p999: 150.213
  },
  {
    month: 107,
    l: 1,
    m: 131.9912,
    s: 0.0461,
    sd: 6.0848,
    p01: 113.188,
    p1: 117.836,
    p3: 120.547,
    p5: 121.983,
    p10: 124.193,
    p15: 125.685,
    p25: 127.887,
    p50: 131.991,
    p75: 136.095,
    p85: 138.298,
    p90: 139.789,
    p95: 142,
    p97: 143.435,
    p99: 146.147,
    p999: 150.795
  },
  {
    month: 108,
    l: 1,
    m: 132.4944,
    s: 0.04612,
    sd: 6.1106,
    p01: 113.611,
    p1: 118.279,
    p3: 121.002,
    p5: 122.443,
    p10: 124.663,
    p15: 126.161,
    p25: 128.373,
    p50: 132.494,
    p75: 136.616,
    p85: 138.828,
    p90: 140.326,
    p95: 142.546,
    p97: 143.987,
    p99: 146.71,
    p999: 151.378
  },
  {
    month: 109,
    l: 1,
    m: 132.9989,
    s: 0.04613,
    sd: 6.1352,
    p01: 114.04,
    p1: 118.726,
    p3: 121.46,
    p5: 122.907,
    p10: 125.136,
    p15: 126.64,
    p25: 128.861,
    p50: 132.999,
    p75: 137.137,
    p85: 139.358,
    p90: 140.862,
    p95: 143.09,
    p97: 144.538,
    p99: 147.272,
    p999: 151.958
  },
  {
    month: 110,
    l: 1,
    m: 133.5046,
    s: 0.04614,
    sd: 6.1599,
    p01: 114.469,
    p1: 119.175,
    p3: 121.919,
    p5: 123.372,
    p10: 125.61,
    p15: 127.12,
    p25: 129.35,
    p50: 133.505,
    p75: 137.659,
    p85: 139.889,
    p90: 141.399,
    p95: 143.637,
    p97: 145.09,
    p99: 147.835,
    p999: 152.54
  },
  {
    month: 111,
    l: 1,
    m: 134.0118,
    s: 0.04615,
    sd: 6.1846,
    p01: 114.9,
    p1: 119.624,
    p3: 122.38,
    p5: 123.839,
    p10: 126.086,
    p15: 127.602,
    p25: 129.84,
    p50: 134.012,
    p75: 138.183,
    p85: 140.422,
    p90: 141.938,
    p95: 144.185,
    p97: 145.644,
    p99: 148.399,
    p999: 153.124
  },
  {
    month: 112,
    l: 1,
    m: 134.5202,
    s: 0.04616,
    sd: 6.2095,
    p01: 115.332,
    p1: 120.075,
    p3: 122.842,
    p5: 124.307,
    p10: 126.562,
    p15: 128.085,
    p25: 130.332,
    p50: 134.52,
    p75: 138.708,
    p85: 140.956,
    p90: 142.478,
    p95: 144.734,
    p97: 146.199,
    p99: 148.966,
    p999: 153.709
  },
  {
    month: 113,
    l: 1,
    m: 135.0299,
    s: 0.04616,
    sd: 6.233,
    p01: 115.769,
    p1: 120.53,
    p3: 123.307,
    p5: 124.778,
    p10: 127.042,
    p15: 128.57,
    p25: 130.826,
    p50: 135.03,
    p75: 139.234,
    p85: 141.49,
    p90: 143.018,
    p95: 145.282,
    p97: 146.753,
    p99: 149.53,
    p999: 154.291
  },
  {
    month: 114,
    l: 1,
    m: 135.541,
    s: 0.04617,
    sd: 6.2579,
    p01: 116.203,
    p1: 120.983,
    p3: 123.771,
    p5: 125.248,
    p10: 127.521,
    p15: 129.055,
    p25: 131.32,
    p50: 135.541,
    p75: 139.762,
    p85: 142.027,
    p90: 143.561,
    p95: 145.834,
    p97: 147.311,
    p99: 150.099,
    p999: 154.879
  },
  {
    month: 115,
    l: 1,
    m: 136.0533,
    s: 0.04617,
    sd: 6.2816,
    p01: 116.642,
    p1: 121.44,
    p3: 124.239,
    p5: 125.721,
    p10: 128.003,
    p15: 129.543,
    p25: 131.816,
    p50: 136.053,
    p75: 140.29,
    p85: 142.564,
    p90: 144.103,
    p95: 146.386,
    p97: 147.868,
    p99: 150.666,
    p999: 155.465
  },
  {
    month: 116,
    l: 1,
    m: 136.567,
    s: 0.04616,
    sd: 6.3039,
    p01: 117.086,
    p1: 121.902,
    p3: 124.711,
    p5: 126.198,
    p10: 128.488,
    p15: 130.033,
    p25: 132.315,
    p50: 136.567,
    p75: 140.819,
    p85: 143.101,
    p90: 144.646,
    p95: 146.936,
    p97: 148.423,
    p99: 151.232,
    p999: 156.048
  },
  {
    month: 117,
    l: 1,
    m: 137.0821,
    s: 0.04616,
    sd: 6.3277,
    p01: 117.528,
    p1: 122.362,
    p3: 125.181,
    p5: 126.674,
    p10: 128.973,
    p15: 130.524,
    p25: 132.814,
    p50: 137.082,
    p75: 141.35,
    p85: 143.64,
    p90: 145.191,
    p95: 147.49,
    p97: 148.983,
    p99: 151.803,
    p999: 156.636
  },
  {
    month: 118,
    l: 1,
    m: 137.5987,
    s: 0.04616,
    sd: 6.3516,
    p01: 117.971,
    p1: 122.823,
    p3: 125.653,
    p5: 127.151,
    p10: 129.459,
    p15: 131.016,
    p25: 133.315,
    p50: 137.599,
    p75: 141.883,
    p85: 144.182,
    p90: 145.739,
    p95: 148.046,
    p97: 149.545,
    p99: 152.375,
    p999: 157.226
  },
  {
    month: 119,
    l: 1,
    m: 138.1167,
    s: 0.04615,
    sd: 6.3741,
    p01: 118.419,
    p1: 123.288,
    p3: 126.128,
    p5: 127.632,
    p10: 129.948,
    p15: 131.51,
    p25: 133.817,
    p50: 138.117,
    p75: 142.416,
    p85: 144.723,
    p90: 146.285,
    p95: 148.601,
    p97: 150.105,
    p99: 152.945,
    p999: 157.814
  },
  {
    month: 120,
    l: 1,
    m: 138.6363,
    s: 0.04614,
    sd: 6.3967,
    p01: 118.869,
    p1: 123.755,
    p3: 126.605,
    p5: 128.115,
    p10: 130.439,
    p15: 132.007,
    p25: 134.322,
    p50: 138.636,
    p75: 142.951,
    p85: 145.266,
    p90: 146.834,
    p95: 149.158,
    p97: 150.667,
    p99: 153.517,
    p999: 158.404
  },
  {
    month: 121,
    l: 1,
    m: 139.1575,
    s: 0.04612,
    sd: 6.4179,
    p01: 119.325,
    p1: 124.227,
    p3: 127.087,
    p5: 128.601,
    p10: 130.933,
    p15: 132.506,
    p25: 134.829,
    p50: 139.158,
    p75: 143.486,
    p85: 145.809,
    p90: 147.382,
    p95: 149.714,
    p97: 151.228,
    p99: 154.088,
    p999: 158.99
  },
  {
    month: 122,
    l: 1,
    m: 139.6803,
    s: 0.04611,
    sd: 6.4407,
    p01: 119.777,
    p1: 124.697,
    p3: 127.567,
    p5: 129.086,
    p10: 131.426,
    p15: 133.005,
    p25: 135.336,
    p50: 139.68,
    p75: 144.024,
    p85: 146.356,
    p90: 147.934,
    p95: 150.274,
    p97: 151.794,
    p99: 154.664,
    p999: 159.583
  },
  {
    month: 123,
    l: 1,
    m: 140.2049,
    s: 0.04609,
    sd: 6.462,
    p01: 120.236,
    p1: 125.172,
    p3: 128.051,
    p5: 129.576,
    p10: 131.923,
    p15: 133.507,
    p25: 135.846,
    p50: 140.205,
    p75: 144.563,
    p85: 146.902,
    p90: 148.486,
    p95: 150.834,
    p97: 152.359,
    p99: 155.238,
    p999: 160.174
  },
  {
    month: 124,
    l: 1,
    m: 140.7313,
    s: 0.04607,
    sd: 6.4835,
    p01: 120.696,
    p1: 125.648,
    p3: 128.537,
    p5: 130.067,
    p10: 132.422,
    p15: 134.012,
    p25: 136.358,
    p50: 140.731,
    p75: 145.104,
    p85: 147.451,
    p90: 149.04,
    p95: 151.396,
    p97: 152.925,
    p99: 155.814,
    p999: 160.767
  },
  {
    month: 125,
    l: 1,
    m: 141.2594,
    s: 0.04605,
    sd: 6.505,
    p01: 121.157,
    p1: 126.127,
    p3: 129.025,
    p5: 130.56,
    p10: 132.923,
    p15: 134.517,
    p25: 136.872,
    p50: 141.259,
    p75: 145.647,
    p85: 148.001,
    p90: 149.596,
    p95: 151.959,
    p97: 153.494,
    p99: 156.392,
    p999: 161.361
  },
  {
    month: 126,
    l: 1,
    m: 141.7892,
    s: 0.04603,
    sd: 6.5266,
    p01: 121.621,
    p1: 126.606,
    p3: 129.514,
    p5: 131.054,
    p10: 133.425,
    p15: 135.025,
    p25: 137.387,
    p50: 141.789,
    p75: 146.191,
    p85: 148.554,
    p90: 150.153,
    p95: 152.524,
    p97: 154.064,
    p99: 156.972,
    p999: 161.958
  },
  {
    month: 127,
    l: 1,
    m: 142.3206,
    s: 0.046,
    sd: 6.5467,
    p01: 122.09,
    p1: 127.091,
    p3: 130.008,
    p5: 131.552,
    p10: 133.931,
    p15: 135.535,
    p25: 137.905,
    p50: 142.321,
    p75: 146.736,
    p85: 149.106,
    p90: 150.711,
    p95: 153.089,
    p97: 154.634,
    p99: 157.551,
    p999: 162.552
  },
  {
    month: 128,
    l: 1,
    m: 142.8534,
    s: 0.04597,
    sd: 6.567,
    p01: 122.56,
    p1: 127.576,
    p3: 130.502,
    p5: 132.052,
    p10: 134.437,
    p15: 136.047,
    p25: 138.424,
    p50: 142.853,
    p75: 147.283,
    p85: 149.66,
    p90: 151.269,
    p95: 153.655,
    p97: 155.205,
    p99: 158.13,
    p999: 163.147
  },
  {
    month: 129,
    l: 1,
    m: 143.3874,
    s: 0.04594,
    sd: 6.5872,
    p01: 123.031,
    p1: 128.063,
    p3: 130.998,
    p5: 132.552,
    p10: 134.946,
    p15: 136.56,
    p25: 138.944,
    p50: 143.387,
    p75: 147.83,
    p85: 150.215,
    p90: 151.829,
    p95: 154.222,
    p97: 155.777,
    p99: 158.712,
    p999: 163.743
  },
  {
    month: 130,
    l: 1,
    m: 143.9222,
    s: 0.04591,
    sd: 6.6075,
    p01: 123.504,
    p1: 128.551,
    p3: 131.495,
    p5: 133.054,
    p10: 135.454,
    p15: 137.074,
    p25: 139.466,
    p50: 143.922,
    p75: 148.379,
    p85: 150.77,
    p90: 152.39,
    p95: 154.791,
    p97: 156.349,
    p99: 159.293,
    p999: 164.341
  },
  {
    month: 131,
    l: 1,
    m: 144.4575,
    s: 0.04588,
    sd: 6.6277,
    p01: 123.976,
    p1: 129.039,
    p3: 131.992,
    p5: 133.556,
    p10: 135.964,
    p15: 137.588,
    p25: 139.987,
    p50: 144.458,
    p75: 148.928,
    p85: 151.327,
    p90: 152.951,
    p95: 155.359,
    p97: 156.923,
    p99: 159.876,
    p999: 164.939
  },
  {
    month: 132,
    l: 1,
    m: 144.9929,
    s: 0.04584,
    sd: 6.6465,
    p01: 124.454,
    p1: 129.531,
    p3: 132.492,
    p5: 134.06,
    p10: 136.475,
    p15: 138.104,
    p25: 140.51,
    p50: 144.993,
    p75: 149.476,
    p85: 151.882,
    p90: 153.511,
    p95: 155.925,
    p97: 157.494,
    p99: 160.455,
    p999: 165.532
  },
  {
    month: 133,
    l: 1,
    m: 145.528,
    s: 0.0458,
    sd: 6.6652,
    p01: 124.931,
    p1: 130.022,
    p3: 132.992,
    p5: 134.565,
    p10: 136.986,
    p15: 138.62,
    p25: 141.032,
    p50: 145.528,
    p75: 150.024,
    p85: 152.436,
    p90: 154.07,
    p95: 156.491,
    p97: 158.064,
    p99: 161.034,
    p999: 166.125
  },
  {
    month: 134,
    l: 1,
    m: 146.0622,
    s: 0.04576,
    sd: 6.6838,
    p01: 125.408,
    p1: 130.513,
    p3: 133.491,
    p5: 135.068,
    p10: 137.497,
    p15: 139.135,
    p25: 141.554,
    p50: 146.062,
    p75: 150.57,
    p85: 152.99,
    p90: 154.628,
    p95: 157.056,
    p97: 158.633,
    p99: 161.611,
    p999: 166.717
  },
  {
    month: 135,
    l: 1,
    m: 146.5951,
    s: 0.04571,
    sd: 6.7009,
    p01: 125.888,
    p1: 131.007,
    p3: 133.992,
    p5: 135.573,
    p10: 138.008,
    p15: 139.65,
    p25: 142.075,
    p50: 146.595,
    p75: 151.115,
    p85: 153.54,
    p90: 155.183,
    p95: 157.617,
    p97: 159.198,
    p99: 162.184,
    p999: 167.302
  },
  {
    month: 136,
    l: 1,
    m: 147.1262,
    s: 0.04567,
    sd: 6.7193,
    p01: 126.362,
    p1: 131.495,
    p3: 134.489,
    p5: 136.074,
    p10: 138.515,
    p15: 140.162,
    p25: 142.594,
    p50: 147.126,
    p75: 151.658,
    p85: 154.09,
    p90: 155.737,
    p95: 158.178,
    p97: 159.764,
    p99: 162.758,
    p999: 167.89
  },
  {
    month: 137,
    l: 1,
    m: 147.6548,
    s: 0.04562,
    sd: 6.736,
    p01: 126.839,
    p1: 131.984,
    p3: 134.986,
    p5: 136.575,
    p10: 139.022,
    p15: 140.673,
    p25: 143.111,
    p50: 147.655,
    p75: 152.198,
    p85: 154.636,
    p90: 156.287,
    p95: 158.735,
    p97: 160.324,
    p99: 163.325,
    p999: 168.471
  },
  {
    month: 138,
    l: 1,
    m: 148.1804,
    s: 0.04557,
    sd: 6.7526,
    p01: 127.313,
    p1: 132.472,
    p3: 135.48,
    p5: 137.073,
    p10: 139.527,
    p15: 141.182,
    p25: 143.626,
    p50: 148.18,
    p75: 152.735,
    p85: 155.179,
    p90: 156.834,
    p95: 159.287,
    p97: 160.881,
    p99: 163.889,
    p999: 169.047
  },
  {
    month: 139,
    l: 1,
    m: 148.7023,
    s: 0.04552,
    sd: 6.7689,
    p01: 127.785,
    p1: 132.955,
    p3: 135.971,
    p5: 137.568,
    p10: 140.028,
    p15: 141.687,
    p25: 144.137,
    p50: 148.702,
    p75: 153.268,
    p85: 155.718,
    p90: 157.377,
    p95: 159.836,
    p97: 161.433,
    p99: 164.449,
    p999: 169.62
  },
  {
    month: 140,
    l: 1,
    m: 149.2197,
    s: 0.04546,
    sd: 6.7835,
    p01: 128.257,
    p1: 133.439,
    p3: 136.461,
    p5: 138.062,
    p10: 140.526,
    p15: 142.189,
    p25: 144.644,
    p50: 149.22,
    p75: 153.795,
    p85: 156.25,
    p90: 157.913,
    p95: 160.378,
    p97: 161.978,
    p99: 165.001,
    p999: 170.182
  },
  {
    month: 141,
    l: 1,
    m: 149.7322,
    s: 0.04541,
    sd: 6.7993,
    p01: 128.721,
    p1: 133.915,
    p3: 136.944,
    p5: 138.548,
    p10: 141.018,
    p15: 142.685,
    p25: 145.146,
    p50: 149.732,
    p75: 154.318,
    p85: 156.779,
    p90: 158.446,
    p95: 160.916,
    p97: 162.52,
    p99: 165.55,
    p999: 170.744
  },
  {
    month: 142,
    l: 1,
    m: 150.239,
    s: 0.04535,
    sd: 6.8133,
    p01: 129.184,
    p1: 134.389,
    p3: 137.425,
    p5: 139.032,
    p10: 141.507,
    p15: 143.177,
    p25: 145.643,
    p50: 150.239,
    p75: 154.835,
    p85: 157.301,
    p90: 158.971,
    p95: 161.446,
    p97: 163.053,
    p99: 166.089,
    p999: 171.294
  },
  {
    month: 143,
    l: 1,
    m: 150.7394,
    s: 0.04529,
    sd: 6.827,
    p01: 129.642,
    p1: 134.857,
    p3: 137.899,
    p5: 139.51,
    p10: 141.99,
    p15: 143.664,
    p25: 146.135,
    p50: 150.739,
    p75: 155.344,
    p85: 157.815,
    p90: 159.489,
    p95: 161.969,
    p97: 163.58,
    p99: 166.621,
    p999: 171.836
  },
  {
    month: 144,
    l: 1,
    m: 151.2327,
    s: 0.04523,
    sd: 6.8403,
    p01: 130.095,
    p1: 135.32,
    p3: 138.368,
    p5: 139.981,
    p10: 142.467,
    p15: 144.143,
    p25: 146.619,
    p50: 151.233,
    p75: 155.846,
    p85: 158.322,
    p90: 159.999,
    p95: 162.484,
    p97: 164.098,
    p99: 167.146,
    p999: 172.371
  },
  {
    month: 145,
    l: 1,
    m: 151.7182,
    s: 0.04516,
    sd: 6.8516,
    p01: 130.545,
    p1: 135.779,
    p3: 138.832,
    p5: 140.448,
    p10: 142.938,
    p15: 144.617,
    p25: 147.097,
    p50: 151.718,
    p75: 156.34,
    p85: 158.819,
    p90: 160.499,
    p95: 162.988,
    p97: 164.605,
    p99: 167.657,
    p999: 172.891
  },
  {
    month: 146,
    l: 1,
    m: 152.1951,
    s: 0.0451,
    sd: 6.864,
    p01: 130.984,
    p1: 136.227,
    p3: 139.285,
    p5: 140.905,
    p10: 143.399,
    p15: 145.081,
    p25: 147.565,
    p50: 152.195,
    p75: 156.825,
    p85: 159.309,
    p90: 160.992,
    p95: 163.485,
    p97: 165.105,
    p99: 168.163,
    p999: 173.406
  },
  {
    month: 147,
    l: 1,
    m: 152.6628,
    s: 0.04503,
    sd: 6.8744,
    p01: 131.419,
    p1: 136.671,
    p3: 139.733,
    p5: 141.355,
    p10: 143.853,
    p15: 145.538,
    p25: 148.026,
    p50: 152.663,
    p75: 157.3,
    p85: 159.788,
    p90: 161.473,
    p95: 163.97,
    p97: 165.592,
    p99: 168.655,
    p999: 173.906
  },
  {
    month: 148,
    l: 1,
    m: 153.1206,
    s: 0.04497,
    sd: 6.8858,
    p01: 131.842,
    p1: 137.102,
    p3: 140.17,
    p5: 141.794,
    p10: 144.296,
    p15: 145.984,
    p25: 148.476,
    p50: 153.121,
    p75: 157.765,
    p85: 160.257,
    p90: 161.945,
    p95: 164.447,
    p97: 166.071,
    p99: 169.139,
    p999: 174.399
  },
  {
    month: 149,
    l: 1,
    m: 153.5678,
    s: 0.0449,
    sd: 6.8952,
    p01: 132.26,
    p1: 137.527,
    p3: 140.599,
    p5: 142.226,
    p10: 144.731,
    p15: 146.421,
    p25: 148.917,
    p50: 153.568,
    p75: 158.219,
    p85: 160.714,
    p90: 162.404,
    p95: 164.909,
    p97: 166.536,
    p99: 169.608,
    p999: 174.876
  },
  {
    month: 150,
    l: 1,
    m: 154.0041,
    s: 0.04483,
    sd: 6.904,
    p01: 132.669,
    p1: 137.943,
    p3: 141.019,
    p5: 142.648,
    p10: 145.156,
    p15: 146.849,
    p25: 149.347,
    p50: 154.004,
    p75: 158.661,
    p85: 161.16,
    p90: 162.852,
    p95: 165.36,
    p97: 166.989,
    p99: 170.065,
    p999: 175.339
  },
  {
    month: 151,
    l: 1,
    m: 154.429,
    s: 0.04476,
    sd: 6.9122,
    p01: 133.069,
    p1: 138.349,
    p3: 141.428,
    p5: 143.059,
    p10: 145.571,
    p15: 147.265,
    p25: 149.767,
    p50: 154.429,
    p75: 159.091,
    p85: 161.593,
    p90: 163.287,
    p95: 165.799,
    p97: 167.43,
    p99: 170.509,
    p999: 175.789
  },
  {
    month: 152,
    l: 1,
    m: 154.8423,
    s: 0.04468,
    sd: 6.9184,
    p01: 133.463,
    p1: 138.748,
    p3: 141.83,
    p5: 143.463,
    p10: 145.976,
    p15: 147.672,
    p25: 150.176,
    p50: 154.842,
    p75: 159.509,
    p85: 162.013,
    p90: 163.709,
    p95: 166.222,
    p97: 167.854,
    p99: 170.937,
    p999: 176.222
  },
  {
    month: 153,
    l: 1,
    m: 155.2437,
    s: 0.04461,
    sd: 6.9254,
    p01: 133.843,
    p1: 139.133,
    p3: 142.218,
    p5: 143.852,
    p10: 146.368,
    p15: 148.066,
    p25: 150.573,
    p50: 155.244,
    p75: 159.915,
    p85: 162.421,
    p90: 164.119,
    p95: 166.635,
    p97: 168.269,
    p99: 171.355,
    p999: 176.645
  },
  {
    month: 154,
    l: 1,
    m: 155.633,
    s: 0.04454,
    sd: 6.9319,
    p01: 134.212,
    p1: 139.507,
    p3: 142.596,
    p5: 144.231,
    p10: 146.749,
    p15: 148.449,
    p25: 150.958,
    p50: 155.633,
    p75: 160.308,
    p85: 162.817,
    p90: 164.517,
    p95: 167.035,
    p97: 168.67,
    p99: 171.759,
    p999: 177.054
  },
  {
    month: 155,
    l: 1,
    m: 156.0101,
    s: 0.04446,
    sd: 6.9362,
    p01: 134.576,
    p1: 139.874,
    p3: 142.965,
    p5: 144.601,
    p10: 147.121,
    p15: 148.821,
    p25: 151.332,
    p50: 156.01,
    p75: 160.689,
    p85: 163.199,
    p90: 164.899,
    p95: 167.419,
    p97: 169.056,
    p99: 172.146,
    p999: 177.445
  },
  {
    month: 156,
    l: 1,
    m: 156.3748,
    s: 0.04439,
    sd: 6.9415,
    p01: 134.924,
    p1: 140.227,
    p3: 143.319,
    p5: 144.957,
    p10: 147.479,
    p15: 149.18,
    p25: 151.693,
    p50: 156.375,
    p75: 161.057,
    p85: 163.569,
    p90: 165.271,
    p95: 167.793,
    p97: 169.43,
    p99: 172.523,
    p999: 177.826
  },
  {
    month: 157,
    l: 1,
    m: 156.7269,
    s: 0.04431,
    sd: 6.9446,
    p01: 135.267,
    p1: 140.571,
    p3: 143.666,
    p5: 145.304,
    p10: 147.827,
    p15: 149.529,
    p25: 152.043,
    p50: 156.727,
    p75: 161.411,
    p85: 163.924,
    p90: 165.627,
    p95: 168.15,
    p97: 169.788,
    p99: 172.882,
    p999: 178.187
  },
  {
    month: 158,
    l: 1,
    m: 157.0666,
    s: 0.04423,
    sd: 6.9471,
    p01: 135.599,
    p1: 140.905,
    p3: 144.001,
    p5: 145.64,
    p10: 148.164,
    p15: 149.866,
    p25: 152.381,
    p50: 157.067,
    p75: 161.752,
    p85: 164.267,
    p90: 165.97,
    p95: 168.493,
    p97: 170.133,
    p99: 173.228,
    p999: 178.535
  },
  {
    month: 159,
    l: 1,
    m: 157.3936,
    s: 0.04415,
    sd: 6.9489,
    p01: 135.92,
    p1: 141.228,
    p3: 144.324,
    p5: 145.964,
    p10: 148.488,
    p15: 150.191,
    p25: 152.707,
    p50: 157.394,
    p75: 162.081,
    p85: 164.596,
    p90: 166.299,
    p95: 168.824,
    p97: 170.463,
    p99: 173.559,
    p999: 178.867
  },
  {
    month: 160,
    l: 1,
    m: 157.7082,
    s: 0.04408,
    sd: 6.9518,
    p01: 136.226,
    p1: 141.536,
    p3: 144.633,
    p5: 146.274,
    p10: 148.799,
    p15: 150.503,
    p25: 153.019,
    p50: 157.708,
    p75: 162.397,
    p85: 164.913,
    p90: 166.617,
    p95: 169.143,
    p97: 170.783,
    p99: 173.88,
    p999: 179.191
  },
  {
    month: 161,
    l: 1,
    m: 158.0102,
    s: 0.044,
    sd: 6.9524,
    p01: 136.526,
    p1: 141.836,
    p3: 144.934,
    p5: 146.574,
    p10: 149.1,
    p15: 150.804,
    p25: 153.321,
    p50: 158.01,
    p75: 162.7,
    p85: 165.216,
    p90: 166.92,
    p95: 169.446,
    p97: 171.086,
    p99: 174.184,
    p999: 179.495
  },
  {
    month: 162,
    l: 1,
    m: 158.2997,
    s: 0.04392,
    sd: 6.9525,
    p01: 136.815,
    p1: 142.126,
    p3: 145.223,
    p5: 146.864,
    p10: 149.39,
    p15: 151.094,
    p25: 153.61,
    p50: 158.3,
    p75: 162.989,
    p85: 165.506,
    p90: 167.21,
    p95: 169.736,
    p97: 171.376,
    p99: 174.474,
    p999: 179.785
  },
  {
    month: 163,
    l: 1,
    m: 158.5771,
    s: 0.04384,
    sd: 6.952,
    p01: 137.094,
    p1: 142.404,
    p3: 145.502,
    p5: 147.142,
    p10: 149.668,
    p15: 151.372,
    p25: 153.888,
    p50: 158.577,
    p75: 163.266,
    p85: 165.782,
    p90: 167.486,
    p95: 170.012,
    p97: 171.652,
    p99: 174.75,
    p999: 180.06
  },
  {
    month: 164,
    l: 1,
    m: 158.8425,
    s: 0.04376,
    sd: 6.9509,
    p01: 137.362,
    p1: 142.672,
    p3: 145.769,
    p5: 147.409,
    p10: 149.935,
    p15: 151.638,
    p25: 154.154,
    p50: 158.842,
    p75: 163.531,
    p85: 166.047,
    p90: 167.75,
    p95: 170.276,
    p97: 171.916,
    p99: 175.013,
    p999: 180.323
  },
  {
    month: 165,
    l: 1,
    m: 159.0961,
    s: 0.04369,
    sd: 6.9509,
    p01: 137.616,
    p1: 142.926,
    p3: 146.023,
    p5: 147.663,
    p10: 150.188,
    p15: 151.892,
    p25: 154.408,
    p50: 159.096,
    p75: 163.784,
    p85: 166.3,
    p90: 168.004,
    p95: 170.529,
    p97: 172.169,
    p99: 175.266,
    p999: 180.576
  },
  {
    month: 166,
    l: 1,
    m: 159.3382,
    s: 0.04361,
    sd: 6.9487,
    p01: 137.865,
    p1: 143.173,
    p3: 146.269,
    p5: 147.909,
    p10: 150.433,
    p15: 152.136,
    p25: 154.651,
    p50: 159.338,
    p75: 164.025,
    p85: 166.54,
    p90: 168.243,
    p95: 170.768,
    p97: 172.407,
    p99: 175.503,
    p999: 180.811
  },
  {
    month: 167,
    l: 1,
    m: 159.5691,
    s: 0.04353,
    sd: 6.946,
    p01: 138.104,
    p1: 143.41,
    p3: 146.505,
    p5: 148.144,
    p10: 150.667,
    p15: 152.37,
    p25: 154.884,
    p50: 159.569,
    p75: 164.254,
    p85: 166.768,
    p90: 168.471,
    p95: 170.994,
    p97: 172.633,
    p99: 175.728,
    p999: 181.034
  },
  {
    month: 168,
    l: 1,
    m: 159.789,
    s: 0.04345,
    sd: 6.9428,
    p01: 138.334,
    p1: 143.638,
    p3: 146.731,
    p5: 148.369,
    p10: 150.891,
    p15: 152.593,
    p25: 155.106,
    p50: 159.789,
    p75: 164.472,
    p85: 166.985,
    p90: 168.687,
    p95: 171.209,
    p97: 172.847,
    p99: 175.94,
    p999: 181.244
  },
  {
    month: 169,
    l: 1,
    m: 159.9983,
    s: 0.04337,
    sd: 6.9391,
    p01: 138.555,
    p1: 143.855,
    p3: 146.947,
    p5: 148.584,
    p10: 151.105,
    p15: 152.806,
    p25: 155.318,
    p50: 159.998,
    p75: 164.679,
    p85: 167.19,
    p90: 168.891,
    p95: 171.412,
    p97: 173.049,
    p99: 176.141,
    p999: 181.442
  },
  {
    month: 170,
    l: 1,
    m: 160.1971,
    s: 0.0433,
    sd: 6.9365,
    p01: 138.762,
    p1: 144.06,
    p3: 147.151,
    p5: 148.788,
    p10: 151.308,
    p15: 153.008,
    p25: 155.518,
    p50: 160.197,
    p75: 164.876,
    p85: 167.386,
    p90: 169.087,
    p95: 171.607,
    p97: 173.243,
    p99: 176.334,
    p999: 181.633
  },
  {
    month: 171,
    l: 1,
    m: 160.3857,
    s: 0.04322,
    sd: 6.9319,
    p01: 138.965,
    p1: 144.26,
    p3: 147.348,
    p5: 148.984,
    p10: 151.502,
    p15: 153.201,
    p25: 155.71,
    p50: 160.386,
    p75: 165.061,
    p85: 167.57,
    p90: 169.269,
    p95: 171.788,
    p97: 173.423,
    p99: 176.512,
    p999: 181.807
  },
  {
    month: 172,
    l: 1,
    m: 160.5643,
    s: 0.04314,
    sd: 6.9267,
    p01: 139.159,
    p1: 144.45,
    p3: 147.537,
    p5: 149.171,
    p10: 151.687,
    p15: 153.385,
    p25: 155.892,
    p50: 160.564,
    p75: 165.236,
    p85: 167.743,
    p90: 169.441,
    p95: 171.958,
    p97: 173.592,
    p99: 176.678,
    p999: 181.97
  },
  {
    month: 173,
    l: 1,
    m: 160.7332,
    s: 0.04307,
    sd: 6.9228,
    p01: 139.34,
    p1: 144.628,
    p3: 147.713,
    p5: 149.346,
    p10: 151.861,
    p15: 153.558,
    p25: 156.064,
    p50: 160.733,
    p75: 165.403,
    p85: 167.908,
    p90: 169.605,
    p95: 172.12,
    p97: 173.754,
    p99: 176.838,
    p999: 182.126
  },
  {
    month: 174,
    l: 1,
    m: 160.8927,
    s: 0.04299,
    sd: 6.9168,
    p01: 139.518,
    p1: 144.802,
    p3: 147.884,
    p5: 149.516,
    p10: 152.028,
    p15: 153.724,
    p25: 156.227,
    p50: 160.893,
    p75: 165.558,
    p85: 168.061,
    p90: 169.757,
    p95: 172.27,
    p97: 173.902,
    p99: 176.984,
    p999: 182.267
  },
  {
    month: 175,
    l: 1,
    m: 161.043,
    s: 0.04292,
    sd: 6.912,
    p01: 139.683,
    p1: 144.963,
    p3: 148.043,
    p5: 149.674,
    p10: 152.185,
    p15: 153.879,
    p25: 156.381,
    p50: 161.043,
    p75: 165.705,
    p85: 168.207,
    p90: 169.901,
    p95: 172.412,
    p97: 174.043,
    p99: 177.123,
    p999: 182.403
  },
  {
    month: 176,
    l: 1,
    m: 161.1845,
    s: 0.04284,
    sd: 6.9051,
    p01: 139.846,
    p1: 145.121,
    p3: 148.197,
    p5: 149.827,
    p10: 152.335,
    p15: 154.028,
    p25: 156.527,
    p50: 161.184,
    p75: 165.842,
    p85: 168.341,
    p90: 170.034,
    p95: 172.542,
    p97: 174.172,
    p99: 177.248,
    p999: 182.523
  },
  {
    month: 177,
    l: 1,
    m: 161.3176,
    s: 0.04277,
    sd: 6.8996,
    p01: 139.996,
    p1: 145.267,
    p3: 148.341,
    p5: 149.969,
    p10: 152.475,
    p15: 154.167,
    p25: 156.664,
    p50: 161.318,
    p75: 165.971,
    p85: 168.469,
    p90: 170.16,
    p95: 172.666,
    p97: 174.294,
    p99: 177.368,
    p999: 182.639
  },
  {
    month: 178,
    l: 1,
    m: 161.4425,
    s: 0.0427,
    sd: 6.8936,
    p01: 140.14,
    p1: 145.406,
    p3: 148.477,
    p5: 150.104,
    p10: 152.608,
    p15: 154.298,
    p25: 156.793,
    p50: 161.442,
    p75: 166.092,
    p85: 168.587,
    p90: 170.277,
    p95: 172.781,
    p97: 174.408,
    p99: 177.479,
    p999: 182.745
  },
  {
    month: 179,
    l: 1,
    m: 161.5596,
    s: 0.04263,
    sd: 6.8873,
    p01: 140.276,
    p1: 145.537,
    p3: 148.606,
    p5: 150.231,
    p10: 152.733,
    p15: 154.421,
    p25: 156.914,
    p50: 161.56,
    p75: 166.205,
    p85: 168.698,
    p90: 170.386,
    p95: 172.888,
    p97: 174.513,
    p99: 177.582,
    p999: 182.843
  },
  {
    month: 180,
    l: 1,
    m: 161.6692,
    s: 0.04255,
    sd: 6.879,
    p01: 140.411,
    p1: 145.666,
    p3: 148.731,
    p5: 150.354,
    p10: 152.853,
    p15: 154.54,
    p25: 157.029,
    p50: 161.669,
    p75: 166.309,
    p85: 168.799,
    p90: 170.485,
    p95: 172.984,
    p97: 174.607,
    p99: 177.672,
    p999: 182.927
  },
  {
    month: 181,
    l: 1,
    m: 161.7717,
    s: 0.04248,
    sd: 6.8721,
    p01: 140.535,
    p1: 145.785,
    p3: 148.847,
    p5: 150.468,
    p10: 152.965,
    p15: 154.649,
    p25: 157.137,
    p50: 161.772,
    p75: 166.407,
    p85: 168.894,
    p90: 170.579,
    p95: 173.075,
    p97: 174.697,
    p99: 177.759,
    p999: 183.008
  },
  {
    month: 182,
    l: 1,
    m: 161.8673,
    s: 0.04241,
    sd: 6.8648,
    p01: 140.653,
    p1: 145.897,
    p3: 148.956,
    p5: 150.576,
    p10: 153.07,
    p15: 154.752,
    p25: 157.237,
    p50: 161.867,
    p75: 166.498,
    p85: 168.982,
    p90: 170.665,
    p95: 173.159,
    p97: 174.779,
    p99: 177.837,
    p999: 183.081
  },
  {
    month: 183,
    l: 1,
    m: 161.9564,
    s: 0.04235,
    sd: 6.8589,
    p01: 140.761,
    p1: 146,
    p3: 149.056,
    p5: 150.675,
    p10: 153.166,
    p15: 154.848,
    p25: 157.33,
    p50: 161.956,
    p75: 166.583,
    p85: 169.065,
    p90: 170.746,
    p95: 173.238,
    p97: 174.856,
    p99: 177.912,
    p999: 183.152
  },
  {
    month: 184,
    l: 1,
    m: 162.0393,
    s: 0.04228,
    sd: 6.851,
    p01: 140.868,
    p1: 146.101,
    p3: 149.154,
    p5: 150.77,
    p10: 153.259,
    p15: 154.939,
    p25: 157.418,
    p50: 162.039,
    p75: 166.66,
    p85: 169.14,
    p90: 170.819,
    p95: 173.308,
    p97: 174.925,
    p99: 177.977,
    p999: 183.211
  },
  {
    month: 185,
    l: 1,
    m: 162.1164,
    s: 0.04221,
    sd: 6.8429,
    p01: 140.97,
    p1: 146.197,
    p3: 149.246,
    p5: 150.861,
    p10: 153.347,
    p15: 155.024,
    p25: 157.501,
    p50: 162.116,
    p75: 166.732,
    p85: 169.209,
    p90: 170.886,
    p95: 173.372,
    p97: 174.987,
    p99: 178.035,
    p999: 183.263
  },
  {
    month: 186,
    l: 1,
    m: 162.188,
    s: 0.04214,
    sd: 6.8346,
    p01: 141.067,
    p1: 146.288,
    p3: 149.334,
    p5: 150.946,
    p10: 153.429,
    p15: 155.104,
    p25: 157.578,
    p50: 162.188,
    p75: 166.798,
    p85: 169.272,
    p90: 170.947,
    p95: 173.43,
    p97: 175.042,
    p99: 178.088,
    p999: 183.309
  },
  {
    month: 187,
    l: 1,
    m: 162.2542,
    s: 0.04208,
    sd: 6.8277,
    p01: 141.155,
    p1: 146.371,
    p3: 149.413,
    p5: 151.024,
    p10: 153.504,
    p15: 155.178,
    p25: 157.649,
    p50: 162.254,
    p75: 166.859,
    p85: 169.331,
    p90: 171.004,
    p95: 173.485,
    p97: 175.096,
    p99: 178.138,
    p999: 183.353
  },
  {
    month: 188,
    l: 1,
    m: 162.3154,
    s: 0.04201,
    sd: 6.8189,
    p01: 141.244,
    p1: 146.452,
    p3: 149.491,
    p5: 151.099,
    p10: 153.577,
    p15: 155.248,
    p25: 157.716,
    p50: 162.315,
    p75: 166.915,
    p85: 169.383,
    p90: 171.054,
    p95: 173.531,
    p97: 175.14,
    p99: 178.178,
    p999: 183.387
  },
  {
    month: 189,
    l: 1,
    m: 162.3719,
    s: 0.04195,
    sd: 6.8115,
    p01: 141.323,
    p1: 146.526,
    p3: 149.561,
    p5: 151.168,
    p10: 153.643,
    p15: 155.312,
    p25: 157.778,
    p50: 162.372,
    p75: 166.966,
    p85: 169.432,
    p90: 171.101,
    p95: 173.576,
    p97: 175.183,
    p99: 178.218,
    p999: 183.421
  },
  {
    month: 190,
    l: 1,
    m: 162.4239,
    s: 0.04189,
    sd: 6.8039,
    p01: 141.398,
    p1: 146.596,
    p3: 149.627,
    p5: 151.232,
    p10: 153.704,
    p15: 155.372,
    p25: 157.835,
    p50: 162.424,
    p75: 167.013,
    p85: 169.476,
    p90: 171.143,
    p95: 173.615,
    p97: 175.221,
    p99: 178.252,
    p999: 183.45
  },
  {
    month: 191,
    l: 1,
    m: 162.4717,
    s: 0.04182,
    sd: 6.7946,
    p01: 141.475,
    p1: 146.665,
    p3: 149.693,
    p5: 151.296,
    p10: 153.764,
    p15: 155.43,
    p25: 157.889,
    p50: 162.472,
    p75: 167.055,
    p85: 169.514,
    p90: 171.179,
    p95: 173.648,
    p97: 175.251,
    p99: 178.278,
    p999: 183.468
  },
  {
    month: 192,
    l: 1,
    m: 162.5156,
    s: 0.04176,
    sd: 6.7867,
    p01: 141.543,
    p1: 146.727,
    p3: 149.751,
    p5: 151.353,
    p10: 153.818,
    p15: 155.482,
    p25: 157.938,
    p50: 162.516,
    p75: 167.093,
    p85: 169.55,
    p90: 171.213,
    p95: 173.679,
    p97: 175.28,
    p99: 178.304,
    p999: 183.488
  },
  {
    month: 193,
    l: 1,
    m: 162.556,
    s: 0.0417,
    sd: 6.7786,
    p01: 141.609,
    p1: 146.787,
    p3: 149.807,
    p5: 151.406,
    p10: 153.869,
    p15: 155.53,
    p25: 157.984,
    p50: 162.556,
    p75: 167.128,
    p85: 169.582,
    p90: 171.243,
    p95: 173.706,
    p97: 175.305,
    p99: 178.325,
    p999: 183.503
  },
  {
    month: 194,
    l: 1,
    m: 162.5933,
    s: 0.04164,
    sd: 6.7704,
    p01: 141.671,
    p1: 146.843,
    p3: 149.86,
    p5: 151.457,
    p10: 153.917,
    p15: 155.576,
    p25: 158.027,
    p50: 162.593,
    p75: 167.16,
    p85: 169.61,
    p90: 171.27,
    p95: 173.73,
    p97: 175.327,
    p99: 178.344,
    p999: 183.515
  },
  {
    month: 195,
    l: 1,
    m: 162.6276,
    s: 0.04158,
    sd: 6.7621,
    p01: 141.731,
    p1: 146.897,
    p3: 149.91,
    p5: 151.505,
    p10: 153.962,
    p15: 155.619,
    p25: 158.067,
    p50: 162.628,
    p75: 167.189,
    p85: 169.636,
    p90: 171.294,
    p95: 173.75,
    p97: 175.346,
    p99: 178.358,
    p999: 183.524
  },
  {
    month: 196,
    l: 1,
    m: 162.6594,
    s: 0.04152,
    sd: 6.7536,
    p01: 141.789,
    p1: 146.948,
    p3: 149.957,
    p5: 151.551,
    p10: 154.004,
    p15: 155.66,
    p25: 158.104,
    p50: 162.659,
    p75: 167.215,
    p85: 169.659,
    p90: 171.315,
    p95: 173.768,
    p97: 175.362,
    p99: 178.371,
    p999: 183.53
  },
  {
    month: 197,
    l: 1,
    m: 162.689,
    s: 0.04147,
    sd: 6.7467,
    p01: 141.84,
    p1: 146.994,
    p3: 150,
    p5: 151.592,
    p10: 154.043,
    p15: 155.696,
    p25: 158.138,
    p50: 162.689,
    p75: 167.24,
    p85: 169.682,
    p90: 171.335,
    p95: 173.786,
    p97: 175.378,
    p99: 178.384,
    p999: 183.538
  },
  {
    month: 198,
    l: 1,
    m: 162.7165,
    s: 0.04141,
    sd: 6.7381,
    p01: 141.894,
    p1: 147.041,
    p3: 150.044,
    p5: 151.633,
    p10: 154.081,
    p15: 155.733,
    p25: 158.172,
    p50: 162.716,
    p75: 167.261,
    p85: 169.7,
    p90: 171.352,
    p95: 173.8,
    p97: 175.389,
    p99: 178.392,
    p999: 183.539
  },
  {
    month: 199,
    l: 1,
    m: 162.7425,
    s: 0.04136,
    sd: 6.731,
    p01: 141.942,
    p1: 147.084,
    p3: 150.083,
    p5: 151.671,
    p10: 154.116,
    p15: 155.766,
    p25: 158.202,
    p50: 162.742,
    p75: 167.283,
    p85: 169.719,
    p90: 171.369,
    p95: 173.814,
    p97: 175.402,
    p99: 178.401,
    p999: 183.543
  },
  {
    month: 200,
    l: 1,
    m: 162.767,
    s: 0.0413,
    sd: 6.7223,
    p01: 141.994,
    p1: 147.129,
    p3: 150.124,
    p5: 151.71,
    p10: 154.152,
    p15: 155.8,
    p25: 158.233,
    p50: 162.767,
    p75: 167.301,
    p85: 169.734,
    p90: 171.382,
    p95: 173.824,
    p97: 175.41,
    p99: 178.405,
    p999: 183.54
  },
  {
    month: 201,
    l: 1,
    m: 162.7904,
    s: 0.04125,
    sd: 6.7151,
    p01: 142.039,
    p1: 147.169,
    p3: 150.161,
    p5: 151.745,
    p10: 154.185,
    p15: 155.831,
    p25: 158.261,
    p50: 162.79,
    p75: 167.32,
    p85: 169.75,
    p90: 171.396,
    p95: 173.836,
    p97: 175.42,
    p99: 178.412,
    p999: 183.542
  },
  {
    month: 202,
    l: 1,
    m: 162.8126,
    s: 0.04119,
    sd: 6.7063,
    p01: 142.089,
    p1: 147.212,
    p3: 150.2,
    p5: 151.782,
    p10: 154.218,
    p15: 155.862,
    p25: 158.289,
    p50: 162.813,
    p75: 167.336,
    p85: 169.763,
    p90: 171.407,
    p95: 173.843,
    p97: 175.426,
    p99: 178.414,
    p999: 183.536
  },
  {
    month: 203,
    l: 1,
    m: 162.834,
    s: 0.04114,
    sd: 6.699,
    p01: 142.133,
    p1: 147.25,
    p3: 150.235,
    p5: 151.815,
    p10: 154.249,
    p15: 155.891,
    p25: 158.316,
    p50: 162.834,
    p75: 167.352,
    p85: 169.777,
    p90: 171.419,
    p95: 173.853,
    p97: 175.433,
    p99: 178.418,
    p999: 183.535
  },
  {
    month: 204,
    l: 1,
    m: 162.8545,
    s: 0.04109,
    sd: 6.6917,
    p01: 142.176,
    p1: 147.287,
    p3: 150.269,
    p5: 151.848,
    p10: 154.279,
    p15: 155.919,
    p25: 158.341,
    p50: 162.854,
    p75: 167.368,
    p85: 169.79,
    p90: 171.43,
    p95: 173.861,
    p97: 175.44,
    p99: 178.422,
    p999: 183.533
  },
  {
    month: 205,
    l: 1,
    m: 162.8743,
    s: 0.04104,
    sd: 6.6844,
    p01: 142.218,
    p1: 147.324,
    p3: 150.302,
    p5: 151.88,
    p10: 154.308,
    p15: 155.946,
    p25: 158.366,
    p50: 162.874,
    p75: 167.383,
    p85: 169.802,
    p90: 171.441,
    p95: 173.869,
    p97: 175.446,
    p99: 178.424,
    p999: 183.531
  },
  {
    month: 206,
    l: 1,
    m: 162.8935,
    s: 0.04099,
    sd: 6.677,
    p01: 142.26,
    p1: 147.36,
    p3: 150.335,
    p5: 151.911,
    p10: 154.337,
    p15: 155.973,
    p25: 158.39,
    p50: 162.894,
    p75: 167.397,
    p85: 169.814,
    p90: 171.45,
    p95: 173.876,
    p97: 175.452,
    p99: 178.427,
    p999: 183.527
  },
  {
    month: 207,
    l: 1,
    m: 162.912,
    s: 0.04094,
    sd: 6.6696,
    p01: 142.301,
    p1: 147.396,
    p3: 150.368,
    p5: 151.941,
    p10: 154.365,
    p15: 155.999,
    p25: 158.413,
    p50: 162.912,
    p75: 167.411,
    p85: 169.825,
    p90: 171.459,
    p95: 173.883,
    p97: 175.456,
    p99: 178.428,
    p999: 183.523
  },
  {
    month: 208,
    l: 1,
    m: 162.93,
    s: 0.04089,
    sd: 6.6622,
    p01: 142.342,
    p1: 147.431,
    p3: 150.4,
    p5: 151.972,
    p10: 154.392,
    p15: 156.025,
    p25: 158.436,
    p50: 162.93,
    p75: 167.424,
    p85: 169.835,
    p90: 171.468,
    p95: 173.888,
    p97: 175.46,
    p99: 178.429,
    p999: 183.518
  },
  {
    month: 209,
    l: 1,
    m: 162.9476,
    s: 0.04084,
    sd: 6.6548,
    p01: 142.383,
    p1: 147.466,
    p3: 150.431,
    p5: 152.001,
    p10: 154.419,
    p15: 156.05,
    p25: 158.459,
    p50: 162.948,
    p75: 167.436,
    p85: 169.845,
    p90: 171.476,
    p95: 173.894,
    p97: 175.464,
    p99: 178.429,
    p999: 183.512
  },
  {
    month: 210,
    l: 1,
    m: 162.9649,
    s: 0.0408,
    sd: 6.649,
    p01: 142.418,
    p1: 147.497,
    p3: 150.46,
    p5: 152.028,
    p10: 154.444,
    p15: 156.074,
    p25: 158.48,
    p50: 162.965,
    p75: 167.45,
    p85: 169.856,
    p90: 171.486,
    p95: 173.901,
    p97: 175.47,
    p99: 178.433,
    p999: 183.512
  },
  {
    month: 211,
    l: 1,
    m: 162.9817,
    s: 0.04075,
    sd: 6.6415,
    p01: 142.458,
    p1: 147.531,
    p3: 150.49,
    p5: 152.057,
    p10: 154.47,
    p15: 156.098,
    p25: 158.502,
    p50: 162.982,
    p75: 167.461,
    p85: 169.865,
    p90: 171.493,
    p95: 173.906,
    p97: 175.473,
    p99: 178.432,
    p999: 183.505
  },
  {
    month: 212,
    l: 1,
    m: 162.9983,
    s: 0.04071,
    sd: 6.6357,
    p01: 142.493,
    p1: 147.561,
    p3: 150.518,
    p5: 152.084,
    p10: 154.494,
    p15: 156.121,
    p25: 158.523,
    p50: 162.998,
    p75: 167.474,
    p85: 169.876,
    p90: 171.502,
    p95: 173.913,
    p97: 175.479,
    p99: 178.435,
    p999: 183.504
  },
  {
    month: 213,
    l: 1,
    m: 163.0144,
    s: 0.04066,
    sd: 6.6282,
    p01: 142.532,
    p1: 147.595,
    p3: 150.548,
    p5: 152.112,
    p10: 154.52,
    p15: 156.145,
    p25: 158.544,
    p50: 163.014,
    p75: 167.485,
    p85: 169.884,
    p90: 171.509,
    p95: 173.917,
    p97: 175.481,
    p99: 178.434,
    p999: 183.497
  },
  {
    month: 214,
    l: 1,
    m: 163.03,
    s: 0.04062,
    sd: 6.6223,
    p01: 142.566,
    p1: 147.624,
    p3: 150.575,
    p5: 152.137,
    p10: 154.543,
    p15: 156.166,
    p25: 158.563,
    p50: 163.03,
    p75: 167.497,
    p85: 169.894,
    p90: 171.517,
    p95: 173.923,
    p97: 175.485,
    p99: 178.436,
    p999: 183.494
  },
  {
    month: 215,
    l: 1,
    m: 163.0451,
    s: 0.04058,
    sd: 6.6164,
    p01: 142.599,
    p1: 147.653,
    p3: 150.601,
    p5: 152.162,
    p10: 154.566,
    p15: 156.188,
    p25: 158.582,
    p50: 163.045,
    p75: 167.508,
    p85: 169.903,
    p90: 171.524,
    p95: 173.928,
    p97: 175.489,
    p99: 178.437,
    p999: 183.491
  },
  {
    month: 216,
    l: 1,
    m: 163.0595,
    s: 0.04053,
    sd: 6.6088,
    p01: 142.637,
    p1: 147.685,
    p3: 150.63,
    p5: 152.189,
    p10: 154.59,
    p15: 156.21,
    p25: 158.602,
    p50: 163.06,
    p75: 167.517,
    p85: 169.909,
    p90: 171.529,
    p95: 173.93,
    p97: 175.489,
    p99: 178.434,
    p999: 183.482
  },
  {
    month: 217,
    l: 1,
    m: 163.0733,
    s: 0.04049,
    sd: 6.6028,
    p01: 142.669,
    p1: 147.713,
    p3: 150.655,
    p5: 152.213,
    p10: 154.611,
    p15: 156.23,
    p25: 158.62,
    p50: 163.073,
    p75: 167.527,
    p85: 169.917,
    p90: 171.535,
    p95: 173.934,
    p97: 175.492,
    p99: 178.434,
    p999: 183.478
  },
  {
    month: 218,
    l: 1,
    m: 163.0862,
    s: 0.04045,
    sd: 6.5968,
    p01: 142.7,
    p1: 147.74,
    p3: 150.679,
    p5: 152.235,
    p10: 154.632,
    p15: 156.249,
    p25: 158.637,
    p50: 163.086,
    p75: 167.536,
    p85: 169.923,
    p90: 171.54,
    p95: 173.937,
    p97: 175.493,
    p99: 178.433,
    p999: 183.472
  },
  {
    month: 219,
    l: 1,
    m: 163.0982,
    s: 0.04041,
    sd: 6.5908,
    p01: 142.731,
    p1: 147.766,
    p3: 150.702,
    p5: 152.257,
    p10: 154.652,
    p15: 156.267,
    p25: 158.653,
    p50: 163.098,
    p75: 167.544,
    p85: 169.929,
    p90: 171.545,
    p95: 173.939,
    p97: 175.494,
    p99: 178.431,
    p999: 183.465
  },
  {
    month: 220,
    l: 1,
    m: 163.1092,
    s: 0.04037,
    sd: 6.5847,
    p01: 142.761,
    p1: 147.791,
    p3: 150.725,
    p5: 152.278,
    p10: 154.671,
    p15: 156.285,
    p25: 158.668,
    p50: 163.109,
    p75: 167.551,
    p85: 169.934,
    p90: 171.548,
    p95: 173.94,
    p97: 175.494,
    p99: 178.428,
    p999: 183.458
  },
  {
    month: 221,
    l: 1,
    m: 163.1192,
    s: 0.04034,
    sd: 6.5802,
    p01: 142.785,
    p1: 147.811,
    p3: 150.743,
    p5: 152.296,
    p10: 154.686,
    p15: 156.299,
    p25: 158.681,
    p50: 163.119,
    p75: 167.557,
    p85: 169.939,
    p90: 171.552,
    p95: 173.943,
    p97: 175.495,
    p99: 178.427,
    p999: 183.454
  },
  {
    month: 222,
    l: 1,
    m: 163.1279,
    s: 0.0403,
    sd: 6.5741,
    p01: 142.813,
    p1: 147.834,
    p3: 150.763,
    p5: 152.315,
    p10: 154.703,
    p15: 156.314,
    p25: 158.694,
    p50: 163.128,
    p75: 167.562,
    p85: 169.941,
    p90: 171.553,
    p95: 173.941,
    p97: 175.492,
    p99: 178.421,
    p999: 183.443
  },
  {
    month: 223,
    l: 1,
    m: 163.1355,
    s: 0.04026,
    sd: 6.5678,
    p01: 142.839,
    p1: 147.856,
    p3: 150.783,
    p5: 152.332,
    p10: 154.718,
    p15: 156.328,
    p25: 158.706,
    p50: 163.136,
    p75: 167.565,
    p85: 169.943,
    p90: 171.553,
    p95: 173.939,
    p97: 175.488,
    p99: 178.415,
    p999: 183.432
  },
  {
    month: 224,
    l: 1,
    m: 163.1418,
    s: 0.04023,
    sd: 6.5632,
    p01: 142.86,
    p1: 147.874,
    p3: 150.798,
    p5: 152.346,
    p10: 154.731,
    p15: 156.339,
    p25: 158.715,
    p50: 163.142,
    p75: 167.569,
    p85: 169.944,
    p90: 171.553,
    p95: 173.937,
    p97: 175.486,
    p99: 178.41,
    p999: 183.424
  },
  {
    month: 225,
    l: 1,
    m: 163.1469,
    s: 0.04019,
    sd: 6.5569,
    p01: 142.885,
    p1: 147.893,
    p3: 150.815,
    p5: 152.362,
    p10: 154.744,
    p15: 156.351,
    p25: 158.724,
    p50: 163.147,
    p75: 167.569,
    p85: 169.943,
    p90: 171.55,
    p95: 173.932,
    p97: 175.479,
    p99: 178.4,
    p999: 183.409
  },
  {
    month: 226,
    l: 1,
    m: 163.1508,
    s: 0.04016,
    sd: 6.5521,
    p01: 142.903,
    p1: 147.908,
    p3: 150.828,
    p5: 152.373,
    p10: 154.754,
    p15: 156.36,
    p25: 158.731,
    p50: 163.151,
    p75: 167.57,
    p85: 169.942,
    p90: 171.548,
    p95: 173.928,
    p97: 175.474,
    p99: 178.393,
    p999: 183.398
  },
  {
    month: 227,
    l: 1,
    m: 163.1534,
    s: 0.04012,
    sd: 6.5457,
    p01: 142.926,
    p1: 147.926,
    p3: 150.842,
    p5: 152.387,
    p10: 154.765,
    p15: 156.369,
    p25: 158.738,
    p50: 163.153,
    p75: 167.568,
    p85: 169.938,
    p90: 171.542,
    p95: 173.92,
    p97: 175.465,
    p99: 178.381,
    p999: 183.381
  },
  {
    month: 228,
    l: 1,
    m: 163.1548,
    s: 0.04009,
    sd: 6.5409,
    p01: 142.942,
    p1: 147.938,
    p3: 150.853,
    p5: 152.396,
    p10: 154.772,
    p15: 156.376,
    p25: 158.743,
    p50: 163.155,
    p75: 167.567,
    p85: 169.934,
    p90: 171.537,
    p95: 173.914,
    p97: 175.457,
    p99: 178.371,
    p999: 183.368
  }
]

export default lengthValues
