import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { RiAddLine as AddIcon } from 'react-icons/ri'
import {
  Button,
  ButtonProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useBreakpointValue,
  useDisclosure,
  Text,
  Icon,
} from '@chakra-ui/react'
import { ErrorViewer } from '..'
import { TextField } from '../Form'
import { Form } from 'react-final-form'
import { useAddHistoryComment } from '.'

const messages = defineMessages({
  commentLabel: {
    id: 'UI.comment_button',
    defaultMessage: 'Comment'
  },
  saveButton: {
    id: 'UI.save_button',
    defaultMessage: 'Save'
  },
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close'
  },
  modalTitle: {
    id: 'AddHistoryCommentButton.modal_title',
    defaultMessage: 'New comment'
  },
})

type AddHistoryCommentButtonProps = ButtonProps & {
  patientId: string
  visitId: string
  isDisabled?: boolean
  preventModalFromClosing?: boolean
}

export const AddHistoryCommentButton: React.FC<AddHistoryCommentButtonProps> = props => {
  const { patientId, visitId, isDisabled, preventModalFromClosing, ...buttonProps } = props

  const intl = useIntl()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return <>
    <Button
      onClick={onOpen}
      disabled={isDisabled || isOpen}
      colorScheme='brand'
      size='sm'
      {...buttonProps}
    >
      <Icon as={AddIcon} />
      <Text ml={1}>
        {intl.formatMessage(messages.commentLabel)}
      </Text>
    </Button>

    {
      isOpen &&
      <ManualDiagnoseModal
        patientId={patientId}
        visitId={visitId}
        isOpen={isOpen}
        onClose={onClose}
        preventModalFromClosing={preventModalFromClosing}
        initialFocus
      />
    }
  </>
}

interface ManualDiagnoseModalProps {
  patientId: string
  visitId: string
  isOpen: boolean
  onClose: () => void
  initialFocus?: boolean
  preventModalFromClosing?: boolean
}

const ManualDiagnoseModal: React.FC<ManualDiagnoseModalProps> = props => {
  const { patientId, visitId, isOpen, onClose, initialFocus } = props

  const intl = useIntl()

  const params = {
    patientId,
    visitId,
    onCompleted: onClose
  }

  const { onSubmit, isLoading, validate, error, onErrorClose } = useAddHistoryComment(params)

  const isFullWidth = useBreakpointValue({ base: true, sm: false })
  return <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <Form
      onSubmit={onSubmit}
      validate={validate}
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => {

        return (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <ModalHeader>{intl.formatMessage(messages.modalTitle)}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TextField
                  name="comment"
                  label={intl.formatMessage(messages.commentLabel)}
                  autoFocus={initialFocus}
                />

                {error && <ErrorViewer title={error} onClose={onErrorClose} />}
              </ModalBody>

              <ModalFooter>
                <Button mr={3} onClick={onClose} isFullWidth={isFullWidth}>
                  {intl.formatMessage(messages.closeButton)}
                </Button>
                <Button colorScheme={'brand'} isLoading={isLoading} type="submit" isFullWidth={isFullWidth}>
                  {intl.formatMessage(messages.saveButton)}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        )
      }}
    />
  </Modal>
}