import { ApolloError, MutationHookOptions, useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FullUserSubscription } from '../../api/interfaces'
import { CANCEL_USER_SUBSCRIPTION, CANCEL_STAFF_SUBSCRIPTION } from '../../api/requests/staff'
import { extractFirstErrorCode, Nullable } from '../../utils'

const messages = defineMessages({
  genericErrorMessage: {
    id: 'SubscriptionCancelation.error_cancelling_subscription',
    defaultMessage: 'Something went wrong while trying to cancel your subscription. Please, retry',
  },
})

type CancelSubscriptionData = {
  cancelSubscription: FullUserSubscription
}

type UseCancelSubscription = MutationHookOptions<CancelSubscriptionData, void>

export const useCancelSubscription = (params?: UseCancelSubscription) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'error_cancelling_subscriptions':
      default:
        return setError(intl.formatMessage(messages.genericErrorMessage))
    }
  }

  const onError = (errors: ApolloError) => {
    const firstError = extractFirstErrorCode(errors)

    translateError(firstError)

    if (params?.onError) {
      params.onError(errors)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }

  const onCompleted = (data: CancelSubscriptionData) => {
    onErrorClose()
    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [cancelSubscription, { loading: isLoading }] = useMutation<CancelSubscriptionData, void>(
    CANCEL_USER_SUBSCRIPTION,
    {
      ...params,
      onError,
      onCompleted,
    }
  )

  return {
    cancelSubscription,
    isLoading,
    error,
    onErrorClose,
  }
}

type CancelStaffSubscriptionVariables = {
  customerId: string
}

type UseCancelStaffSubscription = MutationHookOptions<
  CancelSubscriptionData,
  CancelStaffSubscriptionVariables
>
export const useCancelStaffSubscription = (params?: UseCancelStaffSubscription) => {
  const intl = useIntl()

  const [error, setError] = React.useState<Nullable<string>>(null)

  const translateError = (code) => {
    switch (code) {
      case 'error_cancelling_subscriptions':
      default:
        return setError(intl.formatMessage(messages.genericErrorMessage))
    }
  }

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors)

    translateError(firstError)

    if (params?.onError) {
      params.onError(errors)
    }
  }

  const onErrorClose = () => {
    setError(null)
  }

  const onCompleted = (data: CancelSubscriptionData) => {
    onErrorClose()
    if (params?.onCompleted) {
      params.onCompleted(data)
    }
  }

  const [cancelSubscription, { loading: isLoading }] = useMutation<
    CancelSubscriptionData,
    CancelStaffSubscriptionVariables
  >(CANCEL_STAFF_SUBSCRIPTION, {
    ...params,
    onError,
    onCompleted,
  })

  return {
    cancelSubscription,
    isLoading,
    error,
    onErrorClose,
  }
}
