import { Box, Center, FormLabel, HStack, IconButton, Input, List, ListItem, Text } from '@chakra-ui/react'
import React from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import { defineMessages, useIntl } from 'react-intl'
import {
  RiAddLine as AddIcon,
  RiDeleteBin2Line as DeleteIcon,
} from 'react-icons/ri'

const messages = defineMessages({
  options: {
    id: 'CustomFieldsTab.options',
    defaultMessage: 'Options'
  },
  noOptionsFound: {
    id: 'CustomFieldsTab.no_options_found',
    defaultMessage: 'No options found'
  },
  addOption: {
    id: 'CustomFieldsTab.add_options',
    defaultMessage: 'Add a new option'
  }
})

interface TextListFieldProps {
  name: string
  label: string
  initialValue?: Array<string>
  hideLabel?: boolean
}

export const TextListField: React.FC<TextListFieldProps> = props => {
  const { name, hideLabel, label, initialValue } = props

  const intl = useIntl()

  const { fields, meta } = useFieldArray(name, {
    subscription: { error: true, touched: true, value: true },
    initialValue
  })

  const [option, setOption] = React.useState<string>('')

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onSubmit()
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setOption(value)
  }

  const onSubmit = () => {
    if (option.length) {
      fields.push(option)
      setOption('')
    }
  }

  const onRemove = (index: number) => {
    fields.remove(index)
  }

  const items = fields.value ?? []

  const controlProps = {
    p: 2,
    borderRadius: 'md',
    borderWidth: 1,
    borderColor: (meta.error && meta.touched) ? 'red.400' : 'inherit'
  }
  return <>
    {!hideLabel && <FormLabel htmlFor={name}>{label}</FormLabel>}
    <Box {...controlProps}  >


      {
        items.length === 0
          ? <Center my={2}>
            <Text color={(meta.error && meta.touched) ? 'red.400' : 'inherit'}
            >{intl.formatMessage(messages.noOptionsFound)}</Text>
          </Center>
          : <List >
            {
              items.map((item, i) => {
                const key = `${item}-${i}`

                const onClick = () => {
                  onRemove(i)
                }
                return <ListItem
                  key={key}
                  borderBottomWidth={1}
                  p={1}
                  display='flex'
                  justifyContent={'space-between'}
                  alignItems='center'
                >
                  <Text>{item}</Text>
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={onClick}
                    type='button'
                    aria-label={'expand all'}
                    size='sm'
                    variant={'ghost'}
                    colorScheme={'red'}
                  />
                </ListItem>
              })
            }
          </List>
      }
      <HStack mt={3}>
        <Input
          name={'add-option'}
          placeholder={intl.formatMessage(messages.addOption)}
          maxLength={50}
          value={option}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
        <IconButton
          icon={<AddIcon />}
          onClick={onSubmit}
          type='button'
          aria-label={'expand all'}
        />
      </HStack>
    </Box >
  </>
}