import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Nullable } from '../../utils'
import { FullVitals, VitalType } from '../../api/interfaces'
import { Box, BoxProps, Heading } from '@chakra-ui/react'
import {
  BMI,
  Measurement,
  PdfBMI,
  PdfMeasurement,
  PdfZScore,
  PrintableBMI,
  PrintableZScore,
  ReadOnlyMeasurement as PrintableMeasurement,
  ZScore,
} from '../Triage'
import { domesticFilter, sortVitals } from '../Triage/utils'
import { isBefore, isValidDate } from '../DateAndTime'

const messages = defineMessages({
  title: {
    id: 'Vitals.title',
    defaultMessage: 'Vitals',
  },
})

type VitalsProps = BoxProps & {
  patientId: string
  visitId: string
  birthdate: string
  sex: string
  vitals: Nullable<FullVitals>
  isDisabled?: boolean
}

const formatItems = (vitals: Nullable<FullVitals>) => {
  const all = Object.values(VitalType)
    .filter(domesticFilter)
    .map((type) => {
      if (!vitals?.measurements) {
        return {
          type,
          measurements: [],
        }
      }

      const measurements = vitals.measurements
        .filter((item) => item.type === type)
        .sort((a, b) => {
          if (isValidDate(a.updated?.timestamp ?? '') && isValidDate(b.updated?.timestamp ?? '')) {
            const dateA = new Date(a.updated?.timestamp as string)
            const dateB = new Date(b.updated?.timestamp as string)

            return isBefore(dateA, dateB) ? 1 : -1
          }

          return 0
        })
      return {
        type,
        measurements,
      }
    })

  const sorted = sortVitals(all)

  return sorted
}

export const Vitals: React.FC<VitalsProps> = (props) => {
  const { patientId, visitId, birthdate, sex, vitals, isDisabled, ...boxProps } = props

  const intl = useIntl()

  const items = React.useMemo(() => formatItems(vitals), [vitals])

  const height = items.find((item) => item.type === VitalType.HEIGHT)
  const weight = items.find((item) => item.type === VitalType.WEIGHT)

  const showStandards =
    height && height.measurements.length > 0 && weight && weight.measurements.length > 0

  const commonProps = {
    birthdate: birthdate,
    sex: sex,
    my: '1',
    mr: '1',
    size: 'sm',
    isDisabled: isDisabled,
  }
  return (
    <Box {...boxProps}>
      <Heading size={'md'}>{intl.formatMessage(messages.title)}</Heading>

      <Box display={'flex'} flexWrap={'wrap'}>
        {items.map((item) => (
          <Measurement
            key={`measurement-${item.type}`}
            values={item.measurements}
            type={item.type}
            visitId={visitId}
            patientId={patientId}
            {...commonProps}
          />
        ))}
        {showStandards && (
          <BMI height={height.measurements[0]} weight={weight.measurements[0]} {...commonProps} />
        )}
        {showStandards && (
          <ZScore
            height={height.measurements[0]}
            weight={weight.measurements[0]}
            {...commonProps}
          />
        )}
      </Box>
    </Box>
  )
}

type PrintableVitalsProps = BoxProps & {
  birthdate: string
  sex: string
  vitals: Nullable<FullVitals>
}

export const PrintableVitals: React.FC<PrintableVitalsProps> = (props) => {
  const { birthdate, sex, vitals, ...boxProps } = props

  const intl = useIntl()

  const items = React.useMemo(() => formatItems(vitals), [vitals])

  const height = items.find((item) => item.type === VitalType.HEIGHT)
  const weight = items.find((item) => item.type === VitalType.WEIGHT)

  const showStandards =
    height && height.measurements.length > 0 && weight && weight.measurements.length > 0

  const commonProps = {
    birthdate: birthdate,
    sex: sex,
    my: '1',
    mr: '1',
    size: 'sm',
    sx: {
      '@media print': {
        color: 'black',
        borderColor: 'black',
        borderWidth: '1px',
      },
    },
  }
  return (
    <Box w={'100%'} {...boxProps}>
      <Heading
        size="md"
        borderBottomWidth={1}
        mb={1}
        sx={{
          '@media print': {
            margin: '0 0 .5rem 0',
            borderBottom: '1px solid black',
          },
        }}
      >
        {intl.formatMessage(messages.title)}
      </Heading>

      <Box display={'flex'} flexWrap={'wrap'}>
        {items.map((item) => (
          <PrintableMeasurement
            key={`measurement-${item.type}`}
            values={item.measurements}
            type={item.type}
            {...commonProps}
          />
        ))}
        {showStandards && (
          <PrintableBMI
            height={height.measurements[0]}
            weight={weight.measurements[0]}
            {...commonProps}
          />
        )}
        {showStandards && (
          <PrintableZScore
            height={height.measurements[0]}
            weight={weight.measurements[0]}
            {...commonProps}
          />
        )}
      </Box>
    </Box>
  )
}

type PdfVitalsProps = {
  birthdate: string
  sex: string
  vitals: Nullable<FullVitals>
}

export const PdfVitals: React.FC<PdfVitalsProps> = (props) => {
  const { birthdate, sex, vitals } = props

  const intl = useIntl()

  const items = React.useMemo(() => formatItems(vitals), [vitals])

  if (items.every((i) => !i.measurements.length)) {
    return null
  }

  const height = items.find((item) => item.type === VitalType.HEIGHT)
  const weight = items.find((item) => item.type === VitalType.WEIGHT)

  const showStandards =
    height && height.measurements.length > 0 && weight && weight.measurements.length > 0

  const commonProps = {
    birthdate: birthdate,
    sex: sex,
  }

  return (
    <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
      <span style={{ width: '100%', marginBottom: '6px', fontSize: '20px', fontWeight: 'bold' }}>
        {intl.formatMessage(messages.title)}
      </span>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {items.map((item) => (
          <PdfMeasurement
            key={`measurement-${item.type}`}
            values={item.measurements}
            type={item.type}
            {...commonProps}
          />
        ))}
        {showStandards && (
          <div style={{ margin: '0 6px' }}>
            <PdfBMI
              height={height.measurements[0]}
              weight={weight.measurements[0]}
              {...commonProps}
            />
          </div>
        )}
        {showStandards && (
          <div style={{ margin: '0 6px' }}>
            <PdfZScore
              height={height.measurements[0]}
              weight={weight.measurements[0]}
              {...commonProps}
            />
          </div>
        )}
      </div>
    </div>
  )
}
