import React from 'react'
import { Box, Heading, HStack, List, ListItem } from '@chakra-ui/react'
import { FullChecklist, ChecklistEntryType } from '../../api/interfaces'
import { ChecklistReference, ChecklistTextEntry } from '../Checklist'
import { defineMessages, useIntl } from 'react-intl'
import { useDynamicTranslation } from '../i18n'

const messages = defineMessages({
  titleEducation: {
    id: 'PatientEducation.title_education',
    defaultMessage: 'Education',
  },
})

type PatientEducationProps = {
  checklists: FullChecklist[]
}

const PatientEducation: React.FC<PatientEducationProps> = (props) => {
  const { checklists } = props

  const intl = useIntl()

  const educationEntries = checklists.map((c) => c.education)
  const citations = educationEntries
    .map((ed) => (ed || []).filter(({ type }) => type === ChecklistEntryType.CITATION))
    .flat()

  return (
    <Box>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <HStack alignItems={'center'}>
          <Heading size={'md'}>{intl.formatMessage(messages.titleEducation)}</Heading>
          <ChecklistReference items={citations} />
        </HStack>
      </HStack>
      <List ml={4} my={2}>
        {educationEntries.flat().map((item, index) => {
          if (item?.type === ChecklistEntryType.TEXT && item.checked) {
            return (
              <ListItem className="pl-4" key={`${ChecklistEntryType.TEXT}-${index}`}>
                <ChecklistTextEntry entry={item} isDisabled onCheckChange={() => false} />
              </ListItem>
            )
          }

          return null
        })}
      </List>
    </Box>
  )
}

type PdfPatientEducationProps = {
  checklists: FullChecklist[]
}

const PdfPatientEducation: React.FC<PdfPatientEducationProps> = (props) => {
  const { checklists } = props

  const intl = useIntl()
  const { translate } = useDynamicTranslation()

  const educationEntries = checklists.map((c) => c.education).flat()

  return (
    <div>
      <Heading size="md">{intl.formatMessage(messages.titleEducation)}</Heading>
      <ul style={{ marginTop: '8px' }}>
        {educationEntries.map((item, index) => {
          if (item?.type === ChecklistEntryType.TEXT && item?.name && item.checked) {
            return <li key={`${ChecklistEntryType.TEXT}-${index}`}>{translate(item.name)}</li>
          }

          return null
        })}
      </ul>
    </div>
  )
}

export { PatientEducation, PdfPatientEducation }
