import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { RiDeleteBin2Line as DeleteIcon } from 'react-icons/ri'
import { ConfirmButton, SignatureTag } from '..'
import { Box, BoxProps, Flex, Heading, HStack, Text, VStack } from '@chakra-ui/layout'
import Icon from '@chakra-ui/icon'
import { Collapse, Fade } from '@chakra-ui/transition'
import { useDisclosure } from '@chakra-ui/hooks'
import { IconButton } from '@chakra-ui/button'
import { RiArrowRightSLine as ExpandButton } from 'react-icons/ri'
import { useRemoveAssessment } from '.'
import { Assessment as AssessmentInterface, FullChecklist } from '../../api/interfaces'
import { DynamicTranslation } from '../i18n'

const messages = defineMessages({
  assessmentsTitle: {
    id: 'Assessments.title',
    defaultMessage: 'Assessment',
  },
  additionalAssessments: {
    id: 'Assessments.additional_assessments',
    defaultMessage: 'Other Diagnoses',
  },
  removeButton: {
    id: 'UI.button_remove',
    defaultMessage: 'Remove',
  },
  confirmationMessage: {
    id: 'Assessments.removing_confirmation_message',
    defaultMessage: 'This item will permanently delete this diagnosis. Are you sure?',
  },
  moreItems: {
    id: 'UI.label_more_items',
    defaultMessage: '... {count} hidden item/s',
  },
})

type AssessmentsProps = BoxProps & {
  patientId: string
  visitId: string
  checklists: Array<FullChecklist>
  items: Array<AssessmentInterface>
  isDisabled?: boolean
}

export const Assessments: React.FC<AssessmentsProps> = (props) => {
  const { items, patientId, visitId, isDisabled, checklists, ...boxProps } = props

  const intl = useIntl()

  const { isOpen, onToggle } = useDisclosure()

  const firstTwoItems = items.slice(0, 2)
  const restItems = items.length > 2 ? items.slice(2) : []
  const showExpandButton = restItems.length > 0

  return (
    <Box {...boxProps}>
      <Heading size={'md'} mb={1} onClick={onToggle} cursor="pointer">
        {intl.formatMessage(messages.assessmentsTitle)}
      </Heading>
      {checklists.length > 0 && (
        <HStack alignItems={'start'} justifyContent={'space-between'} mt={1} p={2} borderWidth={1} borderRadius="lg">
          {checklists.map((c) => (
            <Heading key={c.id} size="sm" fontWeight={'bold'} lineHeight={1.5}>
              <DynamicTranslation message={c.name} />
            </Heading>
          ))}
        </HStack>
      )}
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <Heading size={'sm'} mt={2} mb={1} onClick={onToggle} cursor="pointer">
          {intl.formatMessage(messages.additionalAssessments)}
        </Heading>
        {showExpandButton && (
          <IconButton
            size="sm"
            aria-label={'expand all'}
            colorScheme={'brand'}
            variant="ghost"
            onClick={onToggle}
            icon={<ExpandButton />}
            sx={{ transform: isOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}
          />
        )}
      </HStack>
      {firstTwoItems.map((assessment) => (
        <Assessment
          key={assessment.id}
          patientId={patientId}
          visitId={visitId}
          assessment={assessment}
          isDisabled={isDisabled}
        />
      ))}
      {showExpandButton && (
        <Collapse startingHeight={0} in={isOpen} animateOpacity>
          {restItems.map((assessment) => (
            <Assessment
              key={assessment.id}
              patientId={patientId}
              visitId={visitId}
              assessment={assessment}
              isDisabled={isDisabled}
            />
          ))}
        </Collapse>
      )}
      {showExpandButton && (
        <Fade in={!isOpen}>
          <Flex justifyContent={'center'}>
            <Text
              fontSize={'xs'}
              onClick={onToggle}
              cursor={'pointer'}
              _hover={{
                textDecoration: 'underline',
              }}
            >
              {intl.formatMessage(messages.moreItems, { count: items.length - 2 })}
            </Text>
          </Flex>
        </Fade>
      )}
    </Box>
  )
}

interface AssessmentProps {
  patientId: string
  visitId: string
  assessment: AssessmentInterface
  isDisabled?: boolean
}

const Assessment: React.FC<AssessmentProps> = (props) => {
  const { patientId, visitId, assessment, isDisabled } = props

  const intl = useIntl()

  const { id, description, created } = assessment

  const params = {
    visitId,
    patientId,
  }

  const { onDelete, isLoading, error, onErrorClose } = useRemoveAssessment(params)

  const isLegacy = id === null

  return (
    <HStack alignItems={'start'} justifyContent={'space-between'} mt={1} p={2} borderWidth={1} borderRadius="lg">
      <VStack alignItems={'start'}>
        <Heading size="sm" fontWeight={'bold'} lineHeight={1.5}>
          <SignatureTag signature={created} mr={1} />
          {description}
        </Heading>
      </VStack>
      {!isLegacy && (
        <ConfirmButton
          onConfirm={() => onDelete(id)}
          message={intl.formatMessage(messages.confirmationMessage)}
          buttonProps={{
            size: 'sm',
            color: 'red.400',
            label: <Icon as={DeleteIcon} />,
            isDisabled,
            isLoading,
          }}
          error={error}
          onErrorClose={onErrorClose}
        />
      )}
    </HStack>
  )
}

type PrintableAssessmentsProps = BoxProps & {
  items: Array<AssessmentInterface>
}

export const PrintableAssessments: React.FC<PrintableAssessmentsProps> = (props) => {
  const { items, ...boxProps } = props

  if (items.length === 0) {
    return null
  }

  return (
    <Box w={'100%'} {...boxProps}>
      {items.map((assessment, index) => {
        const { id, description } = assessment
        const key = `assessment-${id}-${index}`

        return (
          <VStack
            alignItems={'start'}
            spacing={0}
            mt={1}
            p={2}
            borderWidth={1}
            borderRadius="lg"
            key={key}
            sx={{
              '@media print': {
                margin: '0 0 .2rem 0',
                padding: 0,
                border: 0,
              },
            }}
            className="pdfManualDiagnose"
          >
            <Heading size="sm" display={'flex'}>
              {description}
            </Heading>
          </VStack>
        )
      })}
    </Box>
  )
}
