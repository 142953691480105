import { gql } from '@apollo/client'
import { FullSystem, FullLocation } from '../fragments'

export const GET_SYSTEMS = gql`
  ${FullSystem}
  query systems {
    systems {
      docs {
        ...FullSystem
      }
      count
    }
  }
`

export const UPDATE_LOCATION = gql`
  ${FullLocation}
  mutation updateLocation($systemId: String!, $location: NewLocation) {
    updateLocation(systemId: $systemId, location: $location) {
      ...FullLocation
    }
  }
`

export const UPDATE_PAYMENT_SETTINGS = gql`
  ${FullLocation}
  mutation updatePaymentSettings(
    $systemId: String!
    $locationId: String
    $paymentSettings: NewPaymentSettings
  ) {
    updatePaymentSettings(
      systemId: $systemId
      locationId: $locationId
      paymentSettings: $paymentSettings
    ) {
      ...FullLocation
    }
  }
`

export const UPDATE_TELEHEALTH = gql`
  ${FullLocation}
  mutation updateTelehealth(
    $systemId: String!
    $locationId: String
    $settings: NewTelehealthSettings
  ) {
    updateTelehealthSettings(systemId: $systemId, locationId: $locationId, settings: $settings) {
      ...FullLocation
    }
  }
`

/**
 * Rooms
 */

export const CREATE_ROOM = gql`
  ${FullSystem}
  mutation createRoom($systemId: String!, $locationId: String!, $room: NewRoom!) {
    createRoom(systemId: $systemId, locationId: $locationId, room: $room) {
      ...FullSystem
    }
  }
`

export const UPDATE_ROOM = gql`
  ${FullSystem}
  mutation updateRoom($systemId: String!, $locationId: String!, $roomId: String!, $room: NewRoom!) {
    updateRoom(systemId: $systemId, locationId: $locationId, roomId: $roomId, room: $room) {
      ...FullSystem
    }
  }
`

export const DELETE_ROOM = gql`
  ${FullSystem}
  mutation deleteRoom($systemId: String!, $locationId: String!, $roomId: String!) {
    deleteRoom(systemId: $systemId, locationId: $locationId, roomId: $roomId) {
      ...FullSystem
    }
  }
`

/**
 * Custom fields
 */
export const ADD_PRIMITIVE_CUSTOM_FIELD = gql`
  ${FullSystem}
  mutation addPrimitiveCustomField(
    $systemId: String!
    $newPrimitiveCustomField: NewPrimitiveCustomField!
  ) {
    addPrimitiveCustomField(
      systemId: $systemId
      newPrimitiveCustomField: $newPrimitiveCustomField
    ) {
      ...FullSystem
    }
  }
`

export const UPDATE_PRIMITIVE_CUSTOM_FIELD = gql`
  ${FullSystem}
  mutation addPrimitiveCustomField(
    $systemId: String!
    $id: ID!
    $newPrimitiveCustomField: NewPrimitiveCustomField!
  ) {
    updatePrimitiveCustomField(
      systemId: $systemId
      id: $id
      newPrimitiveCustomField: $newPrimitiveCustomField
    ) {
      ...FullSystem
    }
  }
`

export const ADD_TEXT_LIST_CUSTOM_FIELD = gql`
  ${FullSystem}
  mutation addTextListCustomField(
    $systemId: String!
    $newTextListCustomField: NewTextListCustomField!
  ) {
    addTextListCustomField(systemId: $systemId, newTextListCustomField: $newTextListCustomField) {
      ...FullSystem
    }
  }
`
export const UPDATE_TEXT_LIST_CUSTOM_FIELD = gql`
  ${FullSystem}
  mutation updateTextListCustomField(
    $systemId: String!
    $id: ID!
    $newTextListCustomField: NewTextListCustomField!
  ) {
    updateTextListCustomField(
      systemId: $systemId
      id: $id
      newTextListCustomField: $newTextListCustomField
    ) {
      ...FullSystem
    }
  }
`
export const DELETE_CUSTOM_FIELD = gql`
  ${FullSystem}
  mutation deleteCustomField($systemId: String!, $id: ID!) {
    deleteCustomField(systemId: $systemId, id: $id) {
      ...FullSystem
    }
  }
`
/**
 * Registration groups
 */
export const ADD_REGISTRATION_GROUP = gql`
  ${FullSystem}
  mutation addRegistrationGroup($systemId: String!, $newRegistrationGroup: NewRegistrationGroup!) {
    addRegistrationGroup(systemId: $systemId, newRegistrationGroup: $newRegistrationGroup) {
      ...FullSystem
    }
  }
`
export const UPDATE_REGISTRATION_GROUP = gql`
  ${FullSystem}
  mutation updateRegistrationGroup(
    $systemId: String!
    $id: ID!
    $newRegistrationGroup: NewRegistrationGroup!
  ) {
    updateRegistrationGroup(
      systemId: $systemId
      id: $id
      newRegistrationGroup: $newRegistrationGroup
    ) {
      ...FullSystem
    }
  }
`
export const DELETE_REGISTRATION_GROUP = gql`
  ${FullSystem}
  mutation deleteRegistrationGroup($systemId: String!, $id: ID!) {
    deleteRegistrationGroup(systemId: $systemId, id: $id) {
      ...FullSystem
    }
  }
`
